import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import isEventAtCoordinates from '@mapbox/mapbox-gl-draw/src/lib/is_event_at_coordinates';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import createVertex from '@mapbox/mapbox-gl-draw/src/lib/create_vertex';
import DrawLineString from '@mapbox/mapbox-gl-draw/src/modes/draw_line_string';

import showSnappingFeature, { snapFeatureLayerId, getSiteMapMVTLayerIdsById } from './mode_utils';
import {  sitemapDrawLineStringId } from './editing_utils';


function siteMapDrawLineString() {

  const SiteMapDrawLineString ={ ...DrawLineString };

  // Snapping feature - handled in main app for now
  let snapFeatureCoordinates = undefined;

  SiteMapDrawLineString.onSetup = function(opts) {
    opts = opts || {};
    const featureId = opts.featureId;

    this.mapLayerId = opts.mapLayerId;
    this.isSnappingToLine = opts.isSnappingToLine;
    this.isSnappingToVertex = opts.isSnappingToVertex;
    this.isSnapping = this.isSnappingToLine || this.isSnappingToVertex;
    this.navigatedFeatures = opts.navigatedFeatures;

    if (this.mapLayerId !== undefined) {
      this.siteMapMVTLayerIds = getSiteMapMVTLayerIdsById(this.map, this.mapLayerId);
    }

    let line, currentVertexPosition;
    let direction = 'forward';
    if (featureId) {
      line = this.getFeature(featureId);
      if (!line) {
        throw new Error('Could not find a feature with the provided featureId');
      }
      let from = opts.from;
      if (from && from.type === 'Feature' && from.geometry && from.geometry.type === 'Point') {
        from = from.geometry;
      }
      if (from && from.type === 'Point' && from.coordinates && from.coordinates.length === 2) {
        from = from.coordinates;
      }
      if (!from || !Array.isArray(from)) {
        throw new Error('Please use the `from` property to indicate which point to continue the line from');
      }
      const lastCoord = line.coordinates.length - 1;
      // Incoming coords in "from" do NOT match the feature here ???
      if (line.coordinates[lastCoord][0] === from[0] && line.coordinates[lastCoord][1] === from[1]) {
        currentVertexPosition = lastCoord + 1;
        // add one new coordinate to continue from
        line.addCoordinate(currentVertexPosition, ...line.coordinates[lastCoord]);
      } else if (line.coordinates[0][0] === from[0] && line.coordinates[0][1] === from[1]) {
        direction = 'backwards';
        currentVertexPosition = 0;
        // add one new coordinate to continue from
        line.addCoordinate(currentVertexPosition, ...line.coordinates[0]);
      } else {
        throw new Error('`from` should match the point at either the start or the end of the provided LineString');
      }
    } else {
      let from = opts.from;
      let coords = [];
      if (from !== undefined) {
        if (from && from.type === 'Feature' && from.geometry && from.geometry.type === 'Point') {
          coords.push([from.geometry]);

        }
        if (from && from.type === 'Point' && from.coordinates && from.coordinates.length === 2) {
          coords.push([from.coordinates]);
        }
      }

      line = this.newFeature({
        id: sitemapDrawLineStringId,
        type: Constants.geojsonTypes.FEATURE,
        properties: {},
        geometry: {
          type: Constants.geojsonTypes.LINE_STRING,
          coordinates: coords
        }
      });

      currentVertexPosition = 0;
      this.addFeature(line);
    }

    this.clearSelectedFeatures();
    doubleClickZoom.disable(this);
    this.updateUIClasses({ mouse: Constants.cursors.ADD });
    this.activateUIButton(Constants.types.LINE);
    this.setActionableState({
      trash: true
    });

    return {
      line,
      currentVertexPosition,
      direction
    };
  };

  SiteMapDrawLineString.onMouseMove = function(state, e) {
    state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
    if (CommonSelectors.isVertex(e)) {
      this.updateUIClasses({ mouse: Constants.cursors.POINTER });
    }

    if (this.isSnapping) {
      // Mouse coords
      let lat = e.lngLat.lat;
      let lng = e.lngLat.lng;

      let geographicalCoordinates = [lng, lat];

      let movingFeature = {
        id: 'sitemap_draw_line_string_id',
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: geographicalCoordinates
        }
      }

      showSnappingFeature.call(
        this,
        geographicalCoordinates,
        movingFeature,
        'draw_line_string_feature',
        'drawline_string');
    } else {
      if (this.getFeature(snapFeatureLayerId) !== undefined) {
        this.deleteFeature(snapFeatureLayerId);
      }
    }
  };

  SiteMapDrawLineString.onMouseDown = function(state, e) {
    console.log('onMouseDown', 'state', state,  'e', e);
    // Get the snap feature from the map, or undefined if it does not exist
    let snapFeature = this.getFeature(snapFeatureLayerId);
    if (snapFeature === undefined) {
      snapFeatureCoordinates = undefined;
    } else {
      snapFeatureCoordinates = snapFeature.coordinates;
      this.deleteFeature(snapFeatureLayerId);
    }
  };

  SiteMapDrawLineString.clickAnywhere = function(state, e) {
    if (state.currentVertexPosition > 0 && isEventAtCoordinates(e, state.line.coordinates[state.currentVertexPosition - 1]) ||
        state.direction === 'backwards' && isEventAtCoordinates(e, state.line.coordinates[state.currentVertexPosition + 1])) {
      return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.line.id] });
    }

    let finalLng, finalLat;
    this.updateUIClasses({ mouse: Constants.cursors.ADD });
    if (this.isSnapping) {
      // Get the snap feature coordinates
      if (snapFeatureCoordinates !== undefined) {
        finalLng = snapFeatureCoordinates[0];
        finalLat = snapFeatureCoordinates[1];
      } else {
        finalLng = e.lngLat.lng;
        finalLat = e.lngLat.lat;
      }
    } else {
      finalLng = e.lngLat.lng;
      finalLat = e.lngLat.lat;
    }
    state.line.updateCoordinate(state.currentVertexPosition, finalLng, finalLat);

    if (state.direction === 'forward') {
      state.currentVertexPosition++;
      state.line.updateCoordinate(state.currentVertexPosition, finalLng, finalLat);
    } else {
      state.line.addCoordinate(0, finalLng, finalLat);
    }
  };

  SiteMapDrawLineString.onTap = SiteMapDrawLineString.onClick = function(state, e) {
    if (CommonSelectors.isVertex(e)) return this.clickOnVertex(state, e);
    this.clickAnywhere(state, e);
  };

  SiteMapDrawLineString.onStop = function(state) {
    // if (state.line.id === sitemapDrawLineStringId) {
    //   return
    // }
    doubleClickZoom.enable(this);
    this.activateUIButton();

    // check to see if we've deleted this feature
    if (this.getFeature(state.line.id) === undefined) return;

    //remove last added coordinate
    state.line.removeCoordinate(`${state.currentVertexPosition}`);
    if (state.line.isValid()) {
      this.map.fire(Constants.events.CREATE, {
        features: [state.line.toGeoJSON()]
      });
    } else {
      this.deleteFeature([state.line.id], { silent: true });
      this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true });
    }

    if (this.getFeature(snapFeatureLayerId) !== undefined) {
      this.deleteFeature(snapFeatureLayerId);
    }
  };

  SiteMapDrawLineString.toDisplayFeatures = function(state, geojson, display) {
    const isActiveLine = geojson.properties.id === state.line.id;
    geojson.properties.active = (isActiveLine) ? Constants.activeStates.ACTIVE : Constants.activeStates.INACTIVE;
    if (!isActiveLine) return display(geojson);
    // Only render the line if it has at least one real coordinate
    if (geojson.geometry.coordinates.length < 2) return;
    geojson.properties.meta = Constants.meta.FEATURE;
    display(createVertex(
      state.line.id,
      geojson.geometry.coordinates[state.direction === 'forward' ? geojson.geometry.coordinates.length - 2 : 1],
      `${state.direction === 'forward' ? geojson.geometry.coordinates.length - 2 : 1}`,
      false
    ));

    display(geojson);
  };
  return SiteMapDrawLineString
}


export default siteMapDrawLineString;
