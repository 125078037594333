import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { of, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonMapService } from 'src/app/common-component/common-map-service';
import { PortalAPI } from 'src/app/constants/api.constant';
import { SubscriptionEditAccess } from 'src/app/constants/subscriptionEditAccess';
import { permissionEnum, UserPermissionConstant } from 'src/app/constants/userPermissionAccess';
import { ConnectionService } from 'src/app/modules/sitemapadmin/services/connection-service';
import { ApiService } from 'src/app/services/api.service';
import { CommonFunctionService } from 'src/app/services/common-function-service';
import { PermissionService } from 'src/app/services/common/permission.service';
import { MapViewerJobFeaturesService } from 'src/app/services/mapviewerJobFeatures/map-viewer-job-features.service';
import { DEFAULT_MAX_VERSION } from 'tls';
import { MapEditService } from '../../services/mapedit.service';
import { MapSiteService } from '../../services/mapsite.service';
import { MapSubSiteService } from '../../services/mapsubsite.service';
import { MapViewerService } from '../../services/mapviewer.service';

@Component({
  selector: 'app-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['../mapalljoblist/mapalljoblist.component.css']
})
export class SiteDetailsComponent implements OnInit, OnChanges {
  @Input() siteAddForm!: FormGroup;
  @Input() map!: mapboxgl.Map;
  @Input() slideMenuOpenTools: boolean = false;
  @Input() slideMenuOpenLayerDetails: boolean = false;
  @Input() slideMenuOpenJobDescription: boolean = false;
  @Input() slideMenuOpenSiteDetails: boolean = false;
  @Input() slideMenuOpenFolder: boolean = false;
  @Input() slideMenuOpenSiteAdminDetails :boolean = false;
  @Input() siteData: any = [];
  @Input() draw: any = null;
  @Input() loginUserId: any;
  @Input() loginUserEmailId: any;
  @Input() multipleJobsIdByPoligonDraw: any = [];
  @Input() subSiteModalData: any = {
    siteSelectedFromSubSiteDropdown: Number(0),
    subSiteName: "",
    description: "",
  };
  @Input() loginUserTierId: any;
  @Input() loginUserRole: any;
  @Input() currentZoomLevel: any;
  @Input() zoomOnMapParamVal: any;
  @Output() ontoggleClicked = new EventEmitter<any>();
  @Output() onAddSite = new EventEmitter<any>();
  @Output() drawPolygonOnMap = new EventEmitter<any>();
  @Output() onGetSubSiteByID = new EventEmitter<any>();
  @Output() editSubSiteData = new EventEmitter<any>();
  @Output() editSitedata = new EventEmitter<any>();
  @Output() mapEditor = new EventEmitter<any>();
  @Output() getCountry = new EventEmitter<any>();
  @Output() openSubSitePopup = new EventEmitter<any>();

  siteEditId: any;
  isSiteSubEditProperties: any;
  isSiteEditingModeIsOn: boolean = false;
  isSiteEditClick: any;
  isSubSiteEditClick: any;
  featureData: any = {};
  siteOrSubSiteDetailsByClick: any;
  clickSiteOrSubSiteID: any;
  viewSiteOrSubSiteStatus: string = "site";
  editSiteData: any;
  editSiteForm: boolean = false;
  country: any = [];
  dropdownState: any = [];
  dropdownCity: any = [];
  associateJobIds: any = [];
  isSearchAppliedOnJobData: boolean = false;
  deassociateJobIds: any = [];
  featureGroupMap: any = new Map();
  zoomedJobIds: any = [];
  zoomedFeaturesObservableArray: any[] = [];
  zoomedDocumentsObservableArray: any[] = [];
  showToggleSideBar: boolean = false;
  featureGroupToggleStateMap: any = new Map();
  featureGroupTypeToggleStateMap: any = new Map();
  featureGroupTypeFeatureNameToggleStateMap: any = new Map();
  //for print start
  printForm!: FormGroup;
  isPrintSession: boolean = false;
  printState: boolean = false;
  counter: number = 0;
  //for print end
  tempGroupJobIds: any[] = [];
  // for External Layer Tab
  externalLayerjobs: any = [];
  externalLayerData: any = [];
  externalLayerDataForSubsite: any = []
  externalLayerJobsForSubsite: any = []
  allZoomedJobs: any = [];
  externalContentTemplateData = {
    siteId: '',
    woNo: '',
    siteName: '',
    featureName: '',
    featureLink: '',
  };
  modalHeader: any;
  subSiteModalHeader: any = 'Add';
  editCheck = new SubscriptionEditAccess(this.commonService).getEditAccess();
  permissionEnumList:any = permissionEnum;
  permissionListObj:any = new UserPermissionConstant().UserRoleAssignPermission();
  assignExternallayerData = true;


  constructor(
    private spinner: NgxSpinnerService,
    public subSiteServices: MapSubSiteService,
    public siteServices: MapSiteService,
    private toastr: ToastrService,
    private apiService: ApiService,
    private mapEditService: MapEditService,
    private restService: ConnectionService,
    private mapViewerJobFeatureService: MapViewerJobFeaturesService,
    private mapViewerService: MapViewerService,
    private commonService: CommonFunctionService,
    private commonMapService: CommonMapService,
    private permissionService: PermissionService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.siteAddForm.valueChanges.subscribe((item:any)=>{
      if(this.siteAddForm?.value?.searchedUserId?.userId){
        this.siteData = [];
      }
    })
  }

  ngOnInit(): void {
  }


  ontoggle(
    slideMenuOpenSiteAdminDetails: boolean,
    slideMenuOpenSiteDetails: boolean,
    slideMenuOpenLayerDetails: boolean,
    slideMenuOpenTools: boolean,
    slideMenuOpenJobDescription: boolean,
    slideMenuOpenFolder: boolean
  ) {
    this.slideMenuOpenSiteAdminDetails = slideMenuOpenSiteAdminDetails
    this.slideMenuOpenSiteDetails = slideMenuOpenSiteDetails
    this.slideMenuOpenLayerDetails = slideMenuOpenLayerDetails
    this.slideMenuOpenTools = slideMenuOpenTools
    this.slideMenuOpenJobDescription = slideMenuOpenJobDescription
    this.slideMenuOpenFolder = slideMenuOpenFolder

    this.ontoggleClicked.emit({
      slideMenuOpenSiteAdminDetails,
      slideMenuOpenSiteDetails,
      slideMenuOpenLayerDetails,
      slideMenuOpenTools,
      slideMenuOpenJobDescription,
      slideMenuOpenFolder,
    });
  }

  /**get site job list start */
  getSiteJObBySiteId(siteId: any, data: any) {
    this.siteServices.getJobsBySiteId(siteId, data, this.loginUserId);
    this.getDocumentBySiteId(siteId, data);
    setTimeout(() => {
      this.assocateJobOnZoomIn(this.zoomOnMapParamVal, false);
    }, 0);
  }
  /**************************************************get site job list end */

  /**start assocate/deassociate on checkbox click */
  associateJobsCheckbox(jobid: any, event: any) {
    if (event.target.checked) {
      this.associateJobIds.push(jobid);
      while (this.deassociateJobIds.indexOf(jobid) !== -1) {
        this.deassociateJobIds.splice(this.deassociateJobIds.indexOf(jobid), 1);
      }
    } else {
      while (this.associateJobIds.indexOf(jobid) !== -1) {
        this.associateJobIds.splice(this.associateJobIds.indexOf(jobid), 1);
      }
      this.deassociateJobIds.push(jobid);
    }
  }
  /**end assocate/deassociate on checkbox click */

  /**get site document list start */
  getDocumentBySiteId(siteId: any, data: any) {
    this.siteServices.getDocumentSiteId(siteId, data, this.loginUserId);
  }
  /****************************************get site document list end */

  //#region getJobsBySubSiteId method is used to get jobs by subsiteid
  getJobsBySubSiteId(siteId: any, subSiteId: any) {
    this.subSiteServices.getJobsBySubSiteId(siteId, subSiteId, this.siteData, this.loginUserId);
    this.subSiteServices.getDocumentBySubSiteId(
      siteId,
      subSiteId,
      this.siteData,
      this.loginUserId
    );
  }
  //#endregion

  //#region saveSubSite method is used to add subsite under the selected site
  saveSubSite(pointSave: any, siteDatas: any, subSiteDatas: any) {
    let msgForSubsiteUpdate = 'Are you sure you want to update?';
    if(pointSave === "add"? confirm('Are you sure you want to add the subsite?'): confirm(msgForSubsiteUpdate)){
    const data = pointSave === "add" ? this.subSiteModalData : subSiteDatas;
    this.subSiteServices.saveSubSite(
      data,
      this.draw.getAll().features[0]?.geometry?.coordinates[0],
      this.siteData,
      data,
      pointSave,
      this.multipleJobsIdByPoligonDraw
    );
    this.subSiteServices.isSaveSubSiteObservable.subscribe((it: any) => {
      if (it) {
        this.siteData.forEach((element: any) => {
          if (element.subSiteData && element.subSiteData.length > 0)
            // this.drawPolygonOnMap(
            //   element.subSiteData,
            //   "orange",
            //   "subSiteName",
            //   "subSiteId",
            //   "subsite",
            //   "description",
            //   "address",
            //   "city",
            //   "state",
            //   "country"
            // );
            this.drawPolygonOnMap.emit({
              data: element.subSiteData,
              "color": "orange",
              "key": "subSiteName",
              "idKey": "subSiteId",
              "Type": "subsite",
              "desc": "description",
              "address": "address",
              "city": "city",
              "state": "state",
              "country": "country"
            })
        });
        this.draw.delete(this.draw.getAll().features[0]?.id);
        this.subSiteServices.setSaveSubSiteBehavior(false);
      }
    });
  }
  }
  //#endregion

  /**add site API call  start*/
  addSiteOnSave(data: any) {
    let str = this.draw.getAll().features[0]?.geometry?.coordinates[0].map((a: any) => a.join(" ")).join(",");

    if (this.draw.getAll().features[0]?.geometry?.coordinates[0] === undefined) {
      this.toastr.error("Please add a polygon in map for site creation");
      return;
    }
    if (this.draw.getAll()?.features?.length > 1) {
      this.toastr.error("In map multiple polygons presents, please use single for site creation");
      return;
    }

    let dataGEOM = 'POLYGON((' + str + '))';
    let userInfo = {
      mapSiteName: this.siteAddForm.value.siteName,
      geom: dataGEOM,
      contactPerson: "",
      customerName: "",
      email: "",
      contactNo: "",
      description: this.siteAddForm.value.description === null ? "" : this.siteAddForm.value.description,
      userId: parseInt(this.loginUserId),
      address: this.siteAddForm.value.address === null ? "" : this.siteAddForm.value.address,
      jobIds: this.multipleJobsIdByPoligonDraw,
      city: this.siteAddForm.value.city === null ? "" : this.siteAddForm.value.city,
      state: this.siteAddForm.value.state === null ? "" : this.siteAddForm.value.state,
      country: this.siteAddForm.value.country === null ? "" : this.siteAddForm.value.country,
      assignedToEmailId: this.loginUserEmailId,
      operationBy: parseInt(this.loginUserId)
    };

    if (confirm('Are you sure you want to add the site?')) {
      let PolygonTool = document.querySelector(".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon") as HTMLDivElement;
      this.spinner.show();
      this.apiService.addSite(userInfo).subscribe((response: any) => {

        if (response && response.processingStatus?.status.includes('Success')) {
          this.toastr.success("Site added successfully");
          this.draw.delete(this.draw.getAll().features[0].id);
          // this.getSiteList(true);
          this.onAddSite.emit(true);
          this.siteAddForm.reset();
          PolygonTool.removeAttribute('style');
        } else {
          this.toastr.error(response.processingStatus?.message);

        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
    }
  }
  /**add site API call  end*/

  //#region cancelNewlyAddedSubSite method is used to delete newly added subsite from the site
  cancelNewlyAddedSubSite(siteId: any, subSiteId: any) {
    this.subSiteServices.cancelNewlyAddedSubSite(
      siteId,
      subSiteId,
      this.siteData,
      this.draw
    );
  }
  //#endregion

  //#region saveSubSiteJobs method is used to save associate or deassociate jobs for subsite
  saveSubSiteJobsDoc(
    selectedSiteData: any,
    selectedSubSiteData: any,
    dataKey: any,
    idKey: any,
    type: any
  ) {
   const jobDetails =  this.subSiteServices.saveSubSiteJobsDoc(
      selectedSiteData,
      selectedSubSiteData,
      this.siteData,
      dataKey,
      idKey,
      type,
      this.loginUserId
    );
    this.siteData = this.siteData.map((data: any) => {

      if (data.docData) {
       data.docData = data.docData.map((doc: any) => {
          if (jobDetails.selectedJobs.find((jobData: any) => doc.jobId === jobData.jobId)) {
            doc.isAssociated = true;
            data.isAssociated = true;
          }
          if (jobDetails.deSelectedJobs.find((jobData: any) => doc.jobId === jobData.jobId)) {
            doc.isAssociated = false;
            data.isAssociated = false;
          }
          return doc;
        })
      }
      return data;
    })
  this.cd.detectChanges();

    this.externalLayerDataForSubsite.forEach((externallayer: any) => {
      if (externallayer.externalContent.length) {
        externallayer.externalContent = externallayer.externalContent.map((job: any) => {
          if (jobDetails.selectedJobs.find((data: any) => job.mapJobId === data.jobId)) {
            job.isAssociated = true;
            externallayer.isAssociated = true;
            this.assignExternallayerData = false
          }
          if (jobDetails.deSelectedJobs.find((data: any) => job.mapJobId === data.jobId)) {
            job.isAssociated = false;
            externallayer.isAssociated = false;
            this.assignExternallayerData = false
          }
          return job;
        })
      }
    })
   // console.log(selectedSubSiteData, jobDetails, 'sitedetails');
    this.subSiteServices.isSaveJobAssociateSiteObservable.subscribe(
      (it: any) => {
        if (it) {
          if (this.isSearchAppliedOnJobData) {
            this.map.setLayoutProperty(
              "search-unclustered-point",
              "visibility",
              "visible"
            );
            this.map.setFilter("search-unclustered-point", true);
          } else {
            this.map.setLayoutProperty(
              "unclustered-point",
              "visibility",
              "visible"
            );
            this.map.setFilter("unclustered-point", true);
          }
          this.assocateJobOnZoomIn(this.zoomOnMapParamVal);
          this.subSiteServices.setSaveAssociateBehavior(false);
        }
      }
    );
  }
  //#endregion

  removeAllSpaceAndSpecialChar(val: any) {
    const noSpecialChars = val.replace(/[^a-zA-Z0-9_-]/g, '');
    return noSpecialChars.replaceAll(' ', '');
  }

  /**
   * Polygon draw end
   */

  //# open modal for site or subsite details
  openModalforSiteSubSiteDetails() {
    let button = document.getElementById("btnModalSiteSubSiteDetails") as HTMLButtonElement;
    button.click();
  }

  /**reset site data  start*/
  resetSiteData(siteName: any, siteId: any) {
    if (confirm('Are you sure you want to cancel?')) {
      if (siteId == undefined) {
        this.siteData = this.siteData.filter((item: any) => item.siteId !== siteName && item.siteId !== undefined);
        this.draw.delete(this.draw.getAll().features[0].id);
        this.siteAddForm.reset();
      }
    }
  }
  /**reset site data  end*/

  editSiteInfo(data: any) {
    this.editSitedata.emit(data)
  }

  updateSite(data: any) {
    if (this.draw.getAll().features[0]?.geometry?.coordinates[0] && this.draw.getAll().features[0]?.geometry?.coordinates[0].length > 0) {
      let tempSiteData = this.siteData.find((item: any) => item.siteId === data.siteId);
      let flag = false;
      if (tempSiteData && tempSiteData.subSiteData && tempSiteData.subSiteData.length > 0) {
        for (let element of tempSiteData.subSiteData) {
          if (!this.subSiteServices.isSubSiteInsideSite(this.siteData, element, JSON.parse(element.geom).coordinates[0], this.draw.getAll().features[0]?.geometry?.coordinates[0])) {
            this.toastr.error("Sub-site boundary out of bounds of Site. Please adjust the boundary to continue.");
            flag = true;
            return;
          }
        };
      }
      let dataGEOM = 'POLYGON((' + this.draw.getAll().features[0]?.geometry?.coordinates[0].map((a: any) => a.join(" ")).join(",") + '))';
      let userInfo = {
        mapSiteID: data.siteId,
        mapSiteName: data.siteName,
        geom: dataGEOM,
        contactPerson: data.contactPerson === null ? "" : data.contactPerson,
        customerName: data.customerName === null ? "" : data.customerName,
        email: data.email === null ? "" : data.email,
        contactNo: data.contactNo === null ? "" : data.contactNo,
        description: data.description === null ? "" : data.description,
        userId: parseInt(this.loginUserId),
        address: data.address === null ? "" : data.address,
        city: data.city === null ? "" : data.city,
        jobIds: this.multipleJobsIdByPoligonDraw,
        state: data.state === null ? "" : data.state,
        country: data.country === null ? "" : data.country,
        operationBy: parseInt(this.loginUserId)
      };

      if (confirm('Are you sure you want to update?')) {
        this.spinner.show();
        this.restService.post(PortalAPI.UPDATE_SITE, userInfo).subscribe((response: any) => {
          if (response && response.processingStatus?.status.includes('Success')) {
            let filterSiteData = this.siteData.find((it: any) => it.siteId === data.siteId);
            if (filterSiteData?.subSiteData && filterSiteData.subSiteData.length > 0) {
              filterSiteData.subSiteData.forEach((element: any) => {
                if (flag) {
                  this.deleteSubSite(this.siteData, data.siteId, element.subSiteId, element.subSiteName);
                }
                this.toastr.success("Site updated successfully");
                if (this.draw.getAll().features && this.draw.getAll().features.length > 0) {
                  this.draw.delete(this.draw.getAll().features[0].id);
                }
                // this.getSiteList(true);
                this.onAddSite.emit(true);
              });
            }
            else {
              this.toastr.success("Site updated successfully");
              if (this.draw.getAll().features && this.draw.getAll().features.length > 0) {
                this.draw.delete(this.draw.getAll().features[0].id);
              }
              // this.getSiteList(true);
              this.onAddSite.emit(true);
            }
          } else {
            this.toastr.error(response.processingStatus?.message);
          }
          this.spinner.hide();
        }, (err) => {
          console.log(err);
        });
      }
    } else {
      this.spinner.hide();
      this.toastr.error("Before save please modify the site");
    }
  }

  /*delete subsite*/
  deleteSubSite(siteData: any, siteId: any, subSiteId: any, name: any) {
    let filterData = siteData.find((it: any) => it.siteId === siteId);
    const data = {
      userId: this.loginUserId,
      subSiteId: parseInt(subSiteId),
    };
    const api = PortalAPI.DELETE_SUB_SITE + '/' + parseInt(subSiteId) + '/' + this.loginUserId;
    this.restService.delete(api, data).subscribe(
      (data: any) => {
        if (data.processingStatus.status == 'Success') {
          const siteInfo = this.removeAllSpaceAndSpecialChar(name) + subSiteId;
          const index = filterData.subSiteData.findIndex((it: any) => it.subSiteId === subSiteId);
          filterData.subSiteData.splice(index, 1);
          this.map.removeLayer(siteInfo);
          this.map.removeSource(siteInfo);
        } else {
          this.toastr.error(data.processingStatus.status + ' : ' + data.processingStatus.message);
        }
      });
  }

  cancelSiteSubEditMode(data: any, siteProperties: any, sourceKey: any, siteParamData?: any) {
    const filterSiteData = this.siteData.find((it: any) => it.siteId === (siteProperties.toUpperCase() === 'SITE' ? data.siteId : siteParamData.siteId));
    const sourceIndex = siteProperties.toUpperCase() === 'SITE' ? this.siteData.find((it: any) => it.siteId === data.siteId).siteId : filterSiteData?.subSiteData.findIndex((it: any) => it.subSiteId === data.subSiteId).subSiteId;
    const sourceId = this.removeAllSpaceAndSpecialChar(data[sourceKey]) + sourceIndex;
    if (this.map.getSource(sourceId) != undefined) {
      const index = this.draw.getAll().features.findIndex((it: any) => it.id === (siteProperties.toUpperCase() === 'SITE' ? data.siteId : data.subSiteId));
      if (index > -1) {
        this.draw.delete(this.draw.getAll().features[index].id);
        let geom = data.geom;
        let siteInfo = this.removeAllSpaceAndSpecialChar(sourceId);
        if (geom !== null) {
          const feature: any = {
            type: 'Feature',
            geometry: JSON.parse(geom),
            properties: {
              siteName: siteInfo,
              id: siteProperties.toUpperCase() === 'SITE' ? data.siteId : data.subSiteId,
              name: siteProperties.toLowerCase(),
              description: data.description,
              address: data.address,
              city: data.city,
              state: data.state,
              country: data.country,
              siteSubsiteName: data[sourceKey],
            }
          }

          const geojsonSource = this.map.getSource(sourceId) as mapboxgl.GeoJSONSource;
          if (geojsonSource) {
            geojsonSource.setData({
              type: "FeatureCollection",
              features: [feature]
            })
          }
        }
      }
    }
    let mapTool = document.getElementsByClassName("mapboxgl-ctrl-group mapboxgl-ctrl");
    mapTool[0].classList.remove('disabled');
    this.isSiteEditClick = "";
    this.isSubSiteEditClick = "";
    this.siteEditId = "";
    this.isSiteEditingModeIsOn = false;
  }

  //#region deleteSiteSubSite method is used to delete site and subsite under the site properties layer
  deleteSiteSubSite(siteId: any, subSiteId: any, type: any, name: any) {
    let confirmMessage: any;
    let api: any;
    if (type.toLowerCase() == 'site') {
      api = PortalAPI.DELETE_SITE + '/' + parseInt(siteId) + '/' + this.loginUserId;
      confirmMessage = 'Are you sure you want to delete the site? Any associated sub-sites or added digital content will also be deleted.'
    } else {
      api = PortalAPI.DELETE_SUB_SITE + '/' + parseInt(subSiteId) + '/' + this.loginUserId;
      confirmMessage = 'Are you sure you want to delete the sub-site? Any associated added digital content will also be deleted.';
    }

    if (confirm(confirmMessage)) {
      this.spinner.show()
      let filterData = this.siteData.find((it: any) => it.siteId === siteId);
      const data = {
        userId: this.loginUserId,
        subSiteId: parseInt(subSiteId),
      };

      this.restService.delete(api, data).subscribe(
        (data: any) => {
          if (data.processingStatus.status == 'Success') {
            if (type.toLowerCase() == 'site') {
              const siteIndex = this.siteData.findIndex((it: any) => it.siteId === siteId);
              if (filterData.subSiteData && filterData.subSiteData.length > 0) {
                filterData.subSiteData.forEach((element: any, index: any) => {
                  const subSiteInfo = this.removeAllSpaceAndSpecialChar(element.subSiteName) + '-subsite-' + filterData.subSiteData[index].subSiteId;
                  this.map.removeLayer(subSiteInfo);
                  this.map.removeSource(subSiteInfo);
                });
              }
              const siteInfo = this.removeAllSpaceAndSpecialChar(name) + '-site-' + this.siteData[siteIndex].siteId;
              this.map.removeLayer(siteInfo);
              this.map.removeSource(siteInfo);
              this.siteData.splice(siteIndex, 1);
              this.isSiteEditClick = "";
            } else {
              const index = filterData.subSiteData.findIndex((it: any) => it.subSiteId === subSiteId);
              const siteInfo = this.removeAllSpaceAndSpecialChar(name) + '-' + type.toLowerCase() + '-' + filterData.subSiteData[index].subSiteId;
              this.map.removeLayer(siteInfo);
              this.map.removeSource(siteInfo);
              filterData.subSiteData.splice(index, 1);
              this.isSubSiteEditClick = "";
            }
            this.toastr.success('Deleted Successfully');
            // this.isSiteEditClick = false;
            // this.isSubSiteEditClick = false;
            this.isSiteEditClick = "";
            this.isSubSiteEditClick = "";
          } else {
            this.toastr.error(data.processingStatus.status + ' : ' + data.processingStatus.message);
          }
          this.spinner.hide();
        },
        (err: any) => {
          this.toastr.error('Error');
          this.spinner.hide();
        }
      );
    }
  }
  //#endregion

  gotoMapEditor(data: any, layer: any) {
    this.deleteAllDrawFeatures();
    this.isSiteEditingModeIsOn = true;
    this.isSiteSubEditProperties = layer;
    let mapTool = document.getElementsByClassName("mapboxgl-ctrl-group mapboxgl-ctrl");
    mapTool[0].classList.add('disabled');
    if (layer === 'SITE') {
      this.siteEditId = data.siteId;
      this.isSiteEditClick = data.siteId;
    }
    else if (layer === 'SUBSITE') {
      this.siteEditId = data.subSiteId;
      this.isSubSiteEditClick = data.subSiteId;
    }
    this.mapEditor.emit({ data, layer })
  }

  //#region deleteAllDrawFeatures method is use to delete the draw or edit feature whenever you zoomedout below 1000
  deleteAllDrawFeatures() {
    if (this.draw.getAll().features.length > 0) {
      this.draw.getAll().features.forEach((element: any, index: any) => {
        if (this.draw.getAll().features[index]) {
          if (this.draw.getAll().features[index].properties.name?.toLowerCase() === 'site') {
            const siteProperties = this.draw.getAll().features[index].properties;
            const data = this.siteData.find((it: any) => it.siteId === this.draw.getAll().features[index].id);
            let geom = data.geom;
            let siteInfo = this.draw.getAll().features[index].properties.siteName;
            if (geom !== null) {
              const feature: any = {
                type: 'Feature',
                geometry: JSON.parse(geom),
                properties: {
                  siteName: siteInfo,
                  id: data.siteId,
                  name: siteProperties.name.toLowerCase(),
                  description: data.description,
                  address: data.address,
                  city: data.city,
                  state: data.state,
                  country: data.country,
                  siteSubsiteName: data['siteName'],
                }
              }
              const geojsonSource = this.map.getSource(siteInfo) as mapboxgl.GeoJSONSource;
              if (geojsonSource) {
                geojsonSource.setData({
                  type: "FeatureCollection",
                  features: [feature]
                })
              }
            }
          }
          if (this.draw.getAll().features[index].properties.name?.toLowerCase() === 'subsite') {
            const siteProperties = this.draw.getAll().features[index].properties;
            let data: any;
            for (let element of this.siteData) {
              if (element.subSiteData && element.subSiteData.length > 0) {
                const findData = element.subSiteData.find((it: any) => it.subSiteId === this.draw.getAll().features[index].id);
                if (findData) {
                  data = findData;
                  break;
                }
              }
            }
            let geom = data.geom;
            let siteInfo = this.draw.getAll().features[index].properties.siteName;
            if (geom !== null) {
              const feature: any = {
                type: 'Feature',
                geometry: JSON.parse(geom),
                properties: {
                  siteName: siteInfo,
                  id: data.subSiteId,
                  name: siteProperties.name.toLowerCase(),
                  description: data.description,
                  address: data.address,
                  city: data.city,
                  state: data.state,
                  country: data.country,
                  siteSubsiteName: data['subSiteName'],
                }
              }
              const geojsonSource = this.map.getSource(siteInfo) as mapboxgl.GeoJSONSource;
              if (geojsonSource) {
                geojsonSource.setData({
                  type: "FeatureCollection",
                  features: [feature]
                })
              }
            }
          }
          this.draw.delete(this.draw.getAll().features[index].id);
        }
      });
    }
  }
  //#endregion

  /**Start API call for associate/deassociate */
  associateJObBySiteId(siteId: any, data: any, type: any) {
    if (this.associateJobIds.length > 0) {
      if(!(this.permissionListObj.CAN_ASSOCIATE_JOB_TO_SITE) && type == 1){
        this.toastr.error("You do not have access to the following feature");
        return;
      }
      if(!(this.permissionListObj.CAN_ASSOCIATE_DOCUMENT_TO_SITE) && type == 2){
        this.toastr.error("You do not have access to the following feature");
        return;
      }
      this.siteServices.associateJobsBySiteId(siteId, data, this.associateJobIds, type, this.loginUserId);
      this.siteData.forEach((data:any)=>{
        if(data.docData){
          data.docData.forEach((doc:any)=>{
            if(this.associateJobIds.find((id:any)=>doc.jobId === id)){
            doc.isAssociated = true;
            data.isAssociated = true;
              console.log(doc,'gis1243');
            }
          })
        }
      })
      this.externalLayerData.forEach((externallayer : any)=>{
        if( externallayer.externalContent.length){
         externallayer.externalContent =  externallayer.externalContent.map((job: any)=>{
           if(this.associateJobIds.find((id:any)=>job.mapJobId === id)){
             job.isAssociated = true;
             externallayer.isAssociated = true;
             this.assignExternallayerData = false
           }
           return job;
         })
        }
       })
      this.associateJobIds = [];
      this.siteServices.isSaveJobAssociateSiteObservable.subscribe((it: any) => {
        if (it) {
          if (this.isSearchAppliedOnJobData) {
            this.map.setLayoutProperty('search-unclustered-point', 'visibility', 'visible');
            this.map.setFilter('search-unclustered-point', true);
          } else {
            this.map.setLayoutProperty('unclustered-point', 'visibility', 'visible');
            this.map.setFilter('unclustered-point', true);
          }
          this.assocateJobOnZoomIn(this.zoomOnMapParamVal);
          this.siteServices.setSaveAssociateBehavior(false);
        }
      });
    }
    if (this.deassociateJobIds.length > 0) {
      if(!(this.permissionListObj.CAN_DISASSOCIATE_JOB_TO_SITE) && type == 1){
        this.toastr.error("You do not have access to the following feature");
        return;
      }
      if(!(this.permissionListObj.CAN_DISSOCIATE_DOCUMENT_TO_SITE) && type == 2){
        this.toastr.error("You do not have access to the following feature");
        return;
      }
      this.siteServices.deassociateJobsBySiteId(siteId, data, this.deassociateJobIds, type, this.loginUserId);
      this.siteData.forEach((data:any)=>{
        if(data.docData){
          data.docData.forEach((doc:any)=>{
            if(this.deassociateJobIds.find((id:any)=>doc.jobId === id)){
            doc.isAssociated = false;
            data.isAssociated = false;
                 console.log(doc,'gis124');
            }
          })
        }
      })
      this.externalLayerData.forEach((externallayer : any)=>{
        if( externallayer.externalContent.length){
         externallayer.externalContent =  externallayer.externalContent.map((job: any)=>{
           if(this.deassociateJobIds.find((id:any)=>job.mapJobId === id)){
             job.isAssociated = false;
             externallayer.isAssociated = false;
             this.assignExternallayerData = false
           }
           return job;
         })
        }
       })
    
      console.log(this.externalLayerData,this.deassociateJobIds,'GIMMI');
      this.deassociateJobIds = [];
      this.siteServices.isSaveJobDeAssociateSiteObservable.subscribe((it: any) => {
        if (it) {
          if (this.isSearchAppliedOnJobData) {
            this.map.setLayoutProperty('search-unclustered-point', 'visibility', 'visible');
            this.map.setFilter('search-unclustered-point', true);
          } else {
            this.map.setLayoutProperty('unclustered-point', 'visibility', 'visible');
            this.map.setFilter('unclustered-point', true);
          }
          this.assocateJobOnZoomIn(this.zoomOnMapParamVal);
          this.siteServices.setSaveDeAssociateBehavior(false);
        }
      });
    }

  }
  /**end API call for associate/deassociate */

  assocateJobOnZoomIn(e: any,status : boolean = true) {
    //start code is used for get associate and deassociate zoom job ids
    let siteArray: any[] = [];
    this.spinner.show();
    if (this.siteData.length > 0) {
      this.siteData.forEach((element: any) => {
        let param: any;
        param = {
          objectId: element.siteId,
          objectTypeId: 4, //4 is use for site id in objectTypeId
          userId: parseInt(this.loginUserId)
        };
        siteArray.push(this.restService.post(PortalAPI.GET_JOBS_BY_SUBSITEID, param).pipe(catchError((err) => {
          return of([]);
        })));
        if (element && element.subSiteData && element.subSiteData.length > 0) {
          element.subSiteData.forEach((subSite: any) => {
            param = {
              objectId: subSite.subSiteId,
              objectTypeId: 5, //5 is use for subsite id in objectTypeId
              userId: parseInt(this.loginUserId)
            };
            siteArray.push(this.restService.post(PortalAPI.GET_JOBS_BY_SUBSITEID, param).pipe(catchError((err) => {
              return of([]);
            })));
          });
        }
        // else {
        // param = {
        //   objectId: element.siteId,
        //   objectTypeId: 4, //4 is use for site id in objectTypeId
        //   userId: parseInt(this.loginUserId)
        // };
        // siteArray.push(this.restService.post(PortalAPI.GET_JOBS_BY_SUBSITEID, param).pipe(catchError((err) => {
        
        //   return of([]);
        // })));
        // }
      });
    }
    let siteAssociatedJobIds: any[] = [];
    if (siteArray.length > 0) {
      forkJoin(siteArray)
        .subscribe(
          (result: any) => {
            for (let i = 0; i < result.length; i++) {
              if (result[i].length != 0) {
                for (let j = 0; j < result[i]?.length; j++) {
                  // if (result[i][j]?.isAssociated) {
                  siteAssociatedJobIds.push(result[i][j]);
                  // }
                }
              }
            }
            //end for associate and deassociate

            this.resetMapsData();
            this.slideMenuOpenLayerDetails = false;
            let zoomedJObIdsFeatures:any = [];
            this.zoomedJobIds = [];
            if (this.isSearchAppliedOnJobData) {
              zoomedJObIdsFeatures = this.map.queryRenderedFeatures(e.point,
                { layers: ['search-unclustered-point'] }
              );
              // this.map.setLayoutProperty('search-unclustered-point', 'visibility', 'none');
            } else {
              zoomedJObIdsFeatures = this.map.queryRenderedFeatures(e.point,
                { layers: ['unclustered-point'] }
              );
              // this.map.setLayoutProperty('unclustered-point', 'visibility', 'none');
            }
            this.spinner.show();
            this.zoomedFeaturesObservableArray = [];
            this.zoomedDocumentsObservableArray = [];
            const getSiteAsso = siteAssociatedJobIds.filter((it:any)=> it.isAssociated);
            getSiteAsso.filter(function(obj:any) { return zoomedJObIdsFeatures.indexOf((item:any) => item.properties.jobId != obj.jobId) })?.forEach ((it:any)=> {
              zoomedJObIdsFeatures.push({properties:it.jobId});
            });
            for (let i = 0; i < zoomedJObIdsFeatures.length; i++) {
              const zoomedJobId = zoomedJObIdsFeatures[i].properties!.jobId;
              const tempJobId = siteAssociatedJobIds.filter((it: any) => it.jobId === zoomedJobId);
              let flag = true;
              if (tempJobId && tempJobId.length > 0) {
                if (tempJobId.length > 1 && tempJobId[1].isAssociated === false)
                  flag = false;
                else if (tempJobId.length === 1 && tempJobId[0].isAssociated === false)
                  flag = false;
              }
              if (zoomedJobId && !this.zoomedJobIds.find((it: any) => it === zoomedJobId) && flag) {
                this.zoomedJobIds.push(zoomedJobId);
                this.zoomedFeaturesObservableArray.push(this.mapViewerJobFeatureService.fetchFeaturesByJobId(zoomedJobId, this.loginUserId).pipe(catchError((err) => {
                  //passing empty array to prevent this from execution in forkjoin
                  return of([]);
                })));

                // this.zoomedDocumentsObservableArray.push(this.mapViewerService.fetchJobDocumentsDetails({
                //   jobId: zoomedJobId,
                //   userId: this.loginUserId,
                // }).pipe(catchError((err) => {
                //   
                //   //passing empty array to prevent this from execution in forkjoin
                //   return of([]);
                // })));



              }
            }
            this.zoomedJobIds.forEach((zoomedJobId: any) => {
              this.zoomedFeaturesObservableArray.push(this.commonMapService.getExternalContent(zoomedJobId, this.loginUserId).pipe(catchError((err) => {
                
                //passing empty array to prevent this from execution in forkjoin
                return of([]);
              })));
            });

            const params = {
              "jobIds": this.zoomedJobIds,
              "userId": this.loginUserId
            };
            this.zoomedFeaturesObservableArray.push(this.commonMapService.getSiteContent(params).pipe(catchError((err) => {
              return of([]);
            })));
            const filter: any = ['all', ['!', ['has', 'point_count']]];
            filter.push(['any']);
            this.zoomedJobIds.forEach((jobId: any) => {
              if(jobId)
              filter[2].push(['==', ['get', 'jobId'], jobId]);
            });
            if (this.isSearchAppliedOnJobData) {
              this.map.setFilter('search-unclustered-point', filter);
            } else {
              this.map.setFilter('unclustered-point', filter);
            }
            if (this.allZoomedJobs.length === 0) {
              this.allZoomedJobs = this.zoomedJobIds;
              // this.getSiteList(true);
              if(status)
              this.onAddSite.emit(true);
            }

            this.commonMapService.filterMVTOnZoomIn(this.zoomedJobIds, this.map);
            this.spinner.hide();
          });
    }
    else {
      this.splitZoomOnMap(e);
    }
  }

  resetMapsData() {
    this.featureGroupMap.clear();
    this.featureGroupToggleStateMap.clear();
    this.featureGroupTypeToggleStateMap.clear();
    this.featureGroupTypeFeatureNameToggleStateMap.clear();
    this.commonMapService.featureGroupMap.clear();
    this.commonMapService.featureGroupToggleStateMap.clear();
    this.commonMapService.featureGroupTypeToggleStateMap.clear();
    this.commonMapService.featureGroupTypeFeatureNameToggleStateMap.clear();
  }

  splitZoomOnMap(e: any) {
    // Moving code here does not help
    this.showToggleSideBar = true;
    this.resetMapsData();
    this.slideMenuOpenLayerDetails = false;
    let zoomedJObIdsFeatures = [];
    this.zoomedJobIds = [];
    if (this.isSearchAppliedOnJobData) {
      zoomedJObIdsFeatures = this.map.queryRenderedFeatures(e.point,
        { layers: ['search-unclustered-point'] }
      );
      this.map.setLayoutProperty('search-unclustered-point', 'visibility', 'none');
    } else {
      zoomedJObIdsFeatures = this.map.queryRenderedFeatures(e.point,
        { layers: ['unclustered-point'] }
      );
      // this.map.setLayoutProperty('unclustered-point', 'visibility', 'none');
    }

    this.spinner.show();
    this.zoomedFeaturesObservableArray = [];
    this.zoomedDocumentsObservableArray = [];
    for (let i = 0; i < zoomedJObIdsFeatures.length; i++) {
      const zoomedJobId = zoomedJObIdsFeatures[i].properties!.jobId;
      if (!this.zoomedJobIds.find((it: any) => it === zoomedJobId)) {
        this.zoomedJobIds.push(zoomedJobId);
        this.zoomedFeaturesObservableArray.push(this.mapViewerJobFeatureService.fetchFeaturesByJobId(zoomedJobId, this.loginUserId).pipe(catchError((err) => {
          //passing empty array to prevent this from execution in forkjoin
          return of([]);
        })));
        this.zoomedFeaturesObservableArray.push(this.commonMapService.getExternalContent(zoomedJobId, this.loginUserId).pipe(catchError((err) => {
          //passing empty array to prevent this from execution in forkjoin
          return of([]);
        })));
        // this.zoomedDocumentsObservableArray.push(this.mapViewerService.fetchJobDocumentsDetails({
        //   jobId: zoomedJobId,
        //   userId: this.loginUserId,
        // }).pipe(catchError((err) => {
        
        //   
        //   //passing empty array to prevent this from execution in forkjoin
        //   return of([]);
        // })));
      }
    }
    const params = {
      "jobIds": this.zoomedJobIds,
      "userId": this.loginUserId
    };
    this.zoomedFeaturesObservableArray.push(this.commonMapService.getSiteContent(params).pipe(catchError((err) => {
      return of([]);
    })));
    const filter: any = ['all', ['!', ['has', 'point_count']]];
    filter.push(['any']);
    this.zoomedJobIds.forEach((jobId: any) => {
      filter[2].push(['==', ['get', 'jobId'], jobId]);
    });
    this.map.setFilter('unclustered-point', filter);
    if (this.allZoomedJobs.length === 0) {
      this.allZoomedJobs = this.zoomedJobIds;
      // this.getSiteList(true);
      this.onAddSite.emit(true);
    }
    this.commonMapService.filterMVTOnZoomIn(this.zoomedJobIds, this.map);
    this.spinner.hide();
  }

  // Api for external content
  externalTabSelected(siteId: any) {
    if(!this.assignExternallayerData){
      return;
    }
    this.siteServices.fetchExternalContentBySiteid(siteId, 4, this.loginUserId).subscribe((data: any) => {
      this.externalLayerData = data;
      this.externalLayerData.forEach((element:any) => {
       // element.isAssociated = false;
        if (element.externalContent.length === element.externalContent.filter((it: any) => it.isAssociated).length) {
          element.isAssociated = true;
        } else {
          element.isAssociated = false;
        }
      });
    });
  }

  buttonClicked(siteid: any, datas: any, siteType: any) {
    if (siteType === 'site') {
      const tempAssociateJobIds = this.associateJobIds;
      const tempDeAssociateJobIds = this.deassociateJobIds;
      this.externalLayerjobs.forEach((data: any) => {
        this.associateJobIds = data.associateJobIds;
        this.deassociateJobIds = data.deassociateJobIds;
        this.associateJObBySiteId(siteid, datas, data.typeId)
      })
      this.associateJobIds = tempAssociateJobIds;
      this.deassociateJobIds = tempDeAssociateJobIds;
      this.externalLayerjobs = [];
    } else {
      const tempAssociateJobIds = this.associateJobIds;
      const tempDeAssociateJobIds = this.deassociateJobIds;
      this.externalLayerJobsForSubsite.forEach((data: any) => {
        this.associateJobIds = data.associateJobIds;
        this.deassociateJobIds = data.deassociateJobIds;
        this.associateJObBySubSiteId(siteid, datas, data.typeId)
      })
      this.associateJobIds = tempAssociateJobIds;
      this.deassociateJobIds = tempDeAssociateJobIds;
      this.externalLayerJobsForSubsite = [];
    }

  }

  associateJObBySubSiteId(siteId: any, data: any, type: any) {
    if (this.associateJobIds.length > 0) {
      this.subSiteServices.associateJobsBySubSiteId(siteId, data, this.associateJobIds, type, this.loginUserId);
      this.associateJobIds = [];
      this.subSiteServices.isSaveJobAssociateSiteObservable.subscribe((it: any) => {
        if (it) {
          if (this.isSearchAppliedOnJobData) {
            this.map.setLayoutProperty('search-unclustered-point', 'visibility', 'visible');
            this.map.setFilter('search-unclustered-point', true);
          } else {
            this.map.setLayoutProperty('unclustered-point', 'visibility', 'visible');
            this.map.setFilter('unclustered-point', true);
          }
          this.assocateJobOnZoomIn(this.zoomOnMapParamVal);
          this.subSiteServices.setSaveAssociateBehavior(false);
        }
      });
    }
    if (this.deassociateJobIds.length > 0) {
      this.subSiteServices.deassociateJobsBySubSiteId(siteId, data, this.deassociateJobIds, type, this.loginUserId);
      this.deassociateJobIds = [];
      this.subSiteServices.isSaveJobAssociateSiteObservable.subscribe((it: any) => {
        if (it) {
          if (this.isSearchAppliedOnJobData) {
            this.map.setLayoutProperty('search-unclustered-point', 'visibility', 'visible');
            this.map.setFilter('search-unclustered-point', true);
          } else {
            this.map.setLayoutProperty('unclustered-point', 'visibility', 'visible');
            this.map.setFilter('unclustered-point', true);
          }
          this.assocateJobOnZoomIn(this.zoomOnMapParamVal);
          this.subSiteServices.setSaveAssociateBehavior(false);
        }
      });
    }
  }

  externalLayerAssociateSitesCheckbox(jobid: any, typeId: any, event: any, siteType: any, nestedlevel?: any) {
    const extData = siteType === 'site' ? this.externalLayerData.map((data: any) => {
      return {
        typeId: data.externalContentTypeId, associateJobIds: data.externalContent.filter((job: any) => job.isAssociated
        ).map((subdata: any) => subdata.id), deassociateJobIds: data.externalContent.filter((job: any) => !job.isAssociated
        ).map((subdata: any) => subdata.id)
      }
    }
    ) : this.externalLayerDataForSubsite.map((data: any) => {
      return {
        typeId: data.externalContentTypeId, associateJobIds: data.externalContent.filter((job: any) => job.isAssociated
        ).map((subdata: any) => subdata.id), deassociateJobIds: data.externalContent.filter((job: any) => !job.isAssociated
        ).map((subdata: any) => subdata.id)
      }
    }
    )
    const isTypeIdPresent = siteType === 'site' ? this.externalLayerjobs.find((data: any) => data.typeId === typeId) : this.externalLayerJobsForSubsite.find((data: any) => data.typeId === typeId)
    if (!isTypeIdPresent && !nestedlevel && jobid !== '') {
      if (siteType === 'site') {
        this.externalLayerjobs.push({ typeId, associateJobIds: [], deassociateJobIds: [] });
      }
      else {
        this.externalLayerJobsForSubsite.push({ typeId, associateJobIds: [], deassociateJobIds: [] });
      }

    }
    else if (nestedlevel) {
      if (siteType === 'site') {
        this.externalLayerData = this.externalLayerData.map((data: any) => {
          if (data.externalContentTypeId === typeId) {
            // data.externalContent = data.externalContent.map((subdata: any) => {
            //   subdata.isAssociated = event.target.checked;
            //   return subdata;
            // })
            if (isTypeIdPresent) {
              this.externalLayerjobs = this.externalLayerjobs.map((extjobs: any) => {
                if (event.target.checked) {
                  extjobs.associateJobIds = data.externalContent.map((job: any) => job.id);
                  extjobs.deassociateJobIds = [];
                }
                else {
                  extjobs.deassociateJobIds = data.externalContent.map((job: any) => job.id);
                  extjobs.associateJobIds = [];
                }
                return extjobs;
              })
            }
            else {
              if (event.target.checked) {
                this.externalLayerjobs.push({ typeId, associateJobIds: data.externalContent.map((job: any) => job.id), deassociateJobIds: [] })
              }
              else {
                this.externalLayerjobs.push({ typeId, deassociateJobIds: data.externalContent.map((job: any) => job.id), associateJobIds: [] })
              }
            }
          }
          return data;
        })
      }
      else {
        this.externalLayerDataForSubsite = this.externalLayerDataForSubsite.map((data: any) => {
          if (data.externalContentTypeId === typeId) {
            // data.externalContent = data.externalContent.map((subdata: any) => {
            //   subdata.isAssociated = event.target.checked;
            //   return subdata;
            // })
            if (isTypeIdPresent) {
              this.externalLayerJobsForSubsite = this.externalLayerJobsForSubsite.map((extjobs: any) => {
                if (event.target.checked) {
                  extjobs.associateJobIds = data.externalContent.map((job: any) => job.id);
                  extjobs.deassociateJobIds = [];
                }
                else {
                  extjobs.deassociateJobIds = data.externalContent.map((job: any) => job.id);
                  extjobs.associateJobIds = [];
                }
                return extjobs;
              })
            }
            else {
              if (event.target.checked) {
                this.externalLayerJobsForSubsite.push({ typeId, associateJobIds: data.externalContent.map((job: any) => job.id), deassociateJobIds: [] })
              }
              else {
                this.externalLayerJobsForSubsite.push({ typeId, deassociateJobIds: data.externalContent.map((job: any) => job.id), associateJobIds: [] })
              }
            }
          }
          return data;
        })
      }

    }
    if (siteType === 'site') {
      this.externalLayerjobs.forEach((job: any) => {
        if (job.typeId === typeId && jobid !== '') {
          const initialdata = extData.find((data: any) => data.typeId === typeId);
          if (event.target.checked) {
            job.associateJobIds.push(jobid);
            job.associateJobIds = [...new Set([...initialdata.associateJobIds, ...job.associateJobIds])];
            while (job.deassociateJobIds.indexOf(jobid) !== -1) {
              job.deassociateJobIds.splice(job.deassociateJobIds.indexOf(jobid), 1)
            }
          } else {
            while (job.associateJobIds.indexOf(jobid) !== -1) {
              job.associateJobIds.splice(job.associateJobIds.indexOf(jobid), 1)
            }
            job.deassociateJobIds.push(jobid);
          }
          job.associateJobIds = job.associateJobIds.filter((x: any) => !job.deassociateJobIds.find((y: any) => x === y));
        }
      }
      )
      const listOftypeId = this.externalLayerjobs.filter((data:any) => data.deassociateJobIds.length > 0).map((job:any)=> job.typeId);
      this.externalLayerData.forEach((data:any) => {
        if(data.externalContentTypeId === typeId){
          if(listOftypeId.includes(data.externalContentTypeId)){
            // data = {...data,checked:false};
            data.checked = false;
            }
     else {
       data.checked = true;
     }
        }
       
      })
    }
    else {
      this.externalLayerJobsForSubsite.forEach((job: any) => {
        if (job.typeId === typeId && jobid !== '') {
          const initialdata = extData.find((data: any) => data.typeId === typeId);
          if (event.target.checked) {
            job.associateJobIds.push(jobid);
            job.associateJobIds = [...new Set([...initialdata.associateJobIds, ...job.associateJobIds])];
            while (job.deassociateJobIds.indexOf(jobid) !== -1) {
              job.deassociateJobIds.splice(job.deassociateJobIds.indexOf(jobid), 1)
            }
          } else {
            while (job.associateJobIds.indexOf(jobid) !== -1) {
              job.associateJobIds.splice(job.associateJobIds.indexOf(jobid), 1)
            }
            job.deassociateJobIds.push(jobid);
          }
          job.associateJobIds = job.associateJobIds.filter((x: any) => !job.deassociateJobIds.find((y: any) => x === y));
        }
      }
      )
      const listOftypeId = this.externalLayerJobsForSubsite.filter((data:any) => data.deassociateJobIds.length > 0).map((job:any)=> job.typeId);
      this.externalLayerDataForSubsite.forEach((data:any) => {
        if(data.externalContentTypeId === typeId){
          if(listOftypeId.includes(data.externalContentTypeId)){
            // data = {...data,checked:false};
            data.checked = false;
            }
     else {
       data.checked = true;
     }
        }
       
      })
    }
    
    this.externalLayerData.forEach((element: any) => {
      if (element.externalContentTypeId == typeId) {
        if(jobid) {
          if (element.externalContent.length === element.externalContent.filter((it: any) => it.isAssociated).length) {
            element.isAssociated = true;
          } else {
            element.isAssociated = false;
          }
        } else {
          element.externalContent.forEach((item:any)=> { item.isAssociated = element.isAssociated});
        }
      }
    });

    this.externalLayerDataForSubsite.forEach((element: any) => {
      if (element.externalContentTypeId == typeId) {
        if(jobid) {
          if (element.externalContent.length === element.externalContent.filter((it: any) => it.isAssociated).length) {
            element.isAssociated = true;
          } else {
            element.isAssociated = false;
          }
        } else {
          element.externalContent.forEach((item:any)=> { item.isAssociated = element.isAssociated});
        }
      }
    });

  }


  externalTabSelectedforSubSiteId(siteId: any) {
    if(!this.assignExternallayerData){
      return;
    }
    this.siteServices.fetchExternalContentBySiteid(siteId, 5, this.loginUserId).subscribe((data: any) => {
      //console.log(data,'qwer')
      this.externalLayerDataForSubsite = data;
      this.externalLayerDataForSubsite.forEach((element:any) => {
        // element.isAssociated = false;
         if (element.externalContent.length === element.externalContent.filter((it: any) => it.isAssociated).length) {
           element.isAssociated = true;
         } else {
           element.isAssociated = false;
         }
       });

    });
  }

  isAllInternalCheckboxesChecked(jobs: any, ischecked?:any) {
    if(ischecked != undefined){
      return ischecked;
    }
    else{
    const isAllChecked = jobs.filter((data: any) => !data.isAssociated);
    return !isAllChecked.length;
  }
  }
  editSubSiteInfo(data: any, subSiteLoopData: any) {
    this.editSubSiteData.emit({
      data, subSiteLoopData
    })
  }
  

  /** start API call for associate/diassociate subsite */
  associateDisassociateSubsiteBySiteId(siteId:any,sitedata:any){
    if (this.associateJobIds.length > 0) {
      this.subSiteServices.associateDisassociateSubsiteBySiteID(this.associateJobIds,siteId,sitedata,'associate', this.loginUserId)
      .subscribe((response: any) => {
        if (response && response.processingStatus?.status.includes('Success')) {
          this.toastr.success("Subsite(s) associate successfully");
            const associateSubsiteData = sitedata.subSiteData.filter((item:any)=> 
            (this.associateJobIds.find((it:any)=> it === item.subSiteId)));

          this.drawPolygonOnMap.emit({
            data: associateSubsiteData,
            "color": "orange",
            "key": "subSiteName",
            "idKey": "subSiteId",
            "Type": "subsite",
            "desc": "description",
            "address": "address",
            "city": "city",
            "state": "state",
            "country": "country"
          })

        }else{
          this.toastr.error(response.processingStatus?.message);

        }

      });

    }
    if (this.deassociateJobIds.length > 0) {
      this.subSiteServices.associateDisassociateSubsiteBySiteID(this.deassociateJobIds,siteId,sitedata, 'deassociate', this.loginUserId)
      .subscribe((response: any) => {
        if (response && response.processingStatus?.status.includes('Success')) {
          this.toastr.success("Subsite(s) deassociate successfully");
          let deassociatesubsitedata = sitedata.subSiteData.filter((item:any)=> 
          (this.deassociateJobIds.find((it:any)=> it === item.subSiteId)));
          
          deassociatesubsitedata.forEach((subsite: any) => {

           const siteInfo = this.removeAllSpaceAndSpecialChar(subsite.subSiteName)  + '-subsite-' + (subsite.subSiteId);

           this.map.removeLayer(siteInfo);
           this.map.removeSource(siteInfo);
          });

        } else {
          this.toastr.error(response.processingStatus?.message);

        }
      });

    }

  }

  /** end API call for associate/diassociate subsite */

addSitePopup(){
  if (!this.editCheck) {
    this.permissionService.showErrormessageForPersonal();
    return;
  }
  this.getCountry.emit();
  const addSitebtn = document.getElementById("btnAddSite") as HTMLButtonElement;
  addSitebtn.click();
}
addSubsitePopup(){
  if (!this.editCheck) {
    this.permissionService.showErrormessageForPersonal();
    return;
  }
  this.openSubSitePopup.emit();
  const span = document.getElementById('liAddSubsite');
  span?.setAttribute('data-bs-toggle',this.subSiteServices.addAttrForSubSiteModal ? '' : 'modal');
  span?.setAttribute('data-bs-target',this.subSiteServices.addAttrForSubSiteModal ? '' : '#addSubSite');
  span?.click();

}
}
