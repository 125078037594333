<div class="container-flud">
  <div class="row">
    <div class="col-lg-12">
      <div class="card colored-card">
        <h2 class="card-header">Getting Started</h2>
        <div class="card-body">
          
          <!-- <h2 class="sub-heading">Welcome to SiteMap! We are happy you’re here.</h2> -->
          <p>Welcome to SiteMap! We are happy you’re here.</p>          
          <p>SiteMap allows you to capture, correlate, and contain ALL your facility data, which is something that has never been perfected before.</p>
          <p>Feel free to check out our Training Videos tab on the side of the screen, along with our FAQ tab. You can use the Search function on each of these tabs to search for specific content!</p>
          <p>Welcome, again, to SiteMap, and thank you for trusting GPRS with your facility data needs!</p>

          <!-- <p>Coming soon!</p> -->
          <!-- <h2 class="sub-heading">What are the available roles and primary responsibilities within the
            SiteMap tool
            based on my assigned role?
          </h2>
          <p><strong>Functional Lead (PL)</strong> – Maintains the staffing plan: initiates requisitions,
            initiates
            change control
            process,
            adds positions, maintains release dates.
          </p>
          <p><strong>Project Controls (PC) – </strong>Provides the initial staffing plan. Approver in the
            control
            process and manages
            monthly FTE. Exports data to use in their cost staffing plans.
          </p>
          <p><strong>Functional Manager (FM)</strong> – Maintain Resource Planning module. For some projects
            -
            maintains the staffing
            plan: initiates
            requisitions, initiates change control process, adds positions, maintains release dates.
          </p>
          <p><strong> Resources (HR) </strong> – Maintains resource data and approver in change control
            process.</p>
          <p><strong> Management (PM) </strong> – Visibility of staffing plan & approver in the change
            control
            process.
          </p>
          <p><strong> Only (VW)</strong> – Visibility of staffing plan & ability to participate in the
            change control
            process.
          </p>
          <p><strong>Project/GBU Administrator (PA)</strong> – Manages user access, project configuration,
            mass update loads. Provides
            training for
            new Users and Tier 1 tech support.
          </p>
          <p><strong> Administrator (AD) </strong> – Project creation and configuration, assist Project
            Administrators
            as needed,
            provide Tier 2
            support, maintain User Guides.
          </p>
          <h2 class="sub-heading">Can a user have more than one role?</h2>
          <p>Yes, users can be assigned one of each of the roles. The view and access in each role are
            different.
            Users
            can change
            roles on the top right corner on the SiteMap page to change their views and abilities within the
            tool.
          </p> -->
        </div>
      </div>
    </div>
  </div>
</div>
