<div>
  <nav aria-label="Page navigation example" *ngIf="isPagination">
    <ul *ngIf="pager.pages && pager.pages.length" class="pagination justify-content-end custom-table-pagination">
      <li class="page-item" [ngClass]="{ disabled: pager.currentPage === 1 }">
        <a (click)="setPage(1)">First</a>
      </li>
      <li class="page-item" [ngClass]="{ disabled: pager.currentPage === 1 }">
        <a (click)="setPage(pager.currentPage - 1)">&#60;</a>
      </li>
      <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{ active: pager.currentPage === page }">
        <a (click)="setPage(page)">{{ page }}</a>
      </li>
      <li class="page-item" [ngClass]="{ disabled: pager.currentPage == pager.totalPages }">
        <a (click)="setPage(pager.currentPage + 1)">&#62;</a>
      </li>
      <li class="page-item" [ngClass]="{ disabled: pager.currentPage == pager.totalPages }">
        <a (click)="setPage(pager.totalPages)">Last</a>
      </li>
    </ul>
  </nav>

  <div class="table-responsive">
    <table class="table table-hover" [class]="tblClass">
      <thead scope="colgroup">
        <tr>
          <ng-container *ngFor="let header of tableHeader">
            <th [id]="header.columnName" scope="col" [class]="header.class" aria-hidden="true" *ngIf="header.visible; else notVisible">
              <span aria-label="Heading">{{
                header.displayName
                }}<span *ngIf="header.sort" class="fa fa-sort pointer float-end"
                [appSort]="tableDataWithPage" data-order="desc" [columnName]="header.columnName"
                  (click)="sort(header.columnName)"></span></span>
            </th>
            <ng-template #notVisible>
              <td></td>
            </ng-template>
          </ng-container>
        </tr>
      </thead>
      <tbody *ngIf="tableDataApiLoaded">
        <ng-container *ngIf="tableDataWithPage && tableDataWithPage.length > 0; else noData">
          <tr *ngFor="let data of tableDataWithPage; let ind = index">
            <ng-container *ngFor="let header of tableHeader">
              <td *ngIf="header.span" (click)="tblBodyRowClick(data)">
                <span *ngIf="header.icon && data[header.columnName]" class="pe-1">
                  <i class="fa {{ data[header.columnName + 'Icon'] }}"></i> </span>{{ data[header.columnName] }}
              </td>
              <td *ngIf="header.date" (click)="tblBodyRowClick(data)">
                {{ data[header.columnName] | date:'medium': 'EST'}}
              </td>
              <td *ngIf="header.link">
                <span *ngIf="isArray(data[header.columnName]); else linkSingle">
                  <ng-container *ngFor="let linkBtn of data[header.columnName]">
                    <a href="#" [attr.data-bs-toggle]="linkBtn.dataBsToggle"
                      [attr.data-bs-target]="linkBtn.dataBsTarget" [id]="data[idColumnName] + '_' + header.columnName"
                      (click)="onLinkClick(data, header)">{{ linkBtn[header.columnName] }}</a>
                  </ng-container>
                </span>
                <ng-template #linkSingle>
                  <a [id]="data[idColumnName] + '_' + header.columnName" (click)="onLinkClick(data, header)"
                    class="cursor-pointer">
                    {{ data[header.columnName] }}</a>
                </ng-template>
              </td>
              <td *ngIf="header.text" (click)="tblBodyRowClick(data)">
                <input type="text" class="form-control" />
              </td>
              <td *ngIf="header.textWithDefault" (click)="tblBodyRowClick(data)">
                <input type="text" class="form-control" [value]="data[header.columnName]" [(ngModel)]="data[header.columnName]"/>
              </td>
              <td *ngIf="header.checkBox">
                <input type="checkbox" [id]="data[idColumnName]" name="chkBox" [(ngModel)]="data[header.columnName]"
                  [value]="data[header.columnName]" (change)="onCheckboxClick(data, header)" />
                <label class="visually-hidden" [for]="data[idColumnName]" aria-label="chkb">Checkbox
                  <small>{{ data.checkBoxName }}</small>
                </label>
                <!-- By Avdhesh <label *ngIf="data['checkBoxName']" class="visually-hidden" [for]="data[idColumnName]" aria-label="chkb">Checkbox
                  <small>{{ data.checkBoxName }}</small>
                </label> -->
              </td>
              <td *ngIf="header.button">
                <div *ngIf="isArray(data[header.columnName]); else singleButton">
                  <ng-container *ngFor="let btn of data[header.columnName]">
                    <button type="button" class="btn btn-outline-dark btn-sm me-2" *ngIf="btn.tooltip != 'More'; else moreOption"
                      [attr.data-bs-toggle]="btn.dataBsToggle" [attr.data-bs-target]="btn.dataBsTarget"
                      (click)="onButtonClick(data, btn.dataBsTarget, btn.tooltip)" aria-label="Action">
                      <i *ngIf="header.icon; else notIcon" [title]="btn.tooltip" class="fa" [class]="btn.icon"
                        aria-hidden="true"></i>
                      <ng-template #notIcon>{{ btn.btnName }}</ng-template>
                    </button>
                  </ng-container>
                  <ng-template #moreOption>
                    <div class="custom-card-menu-list dropup">
                      <button type="button" class="btn btn-outline-dark btn-sm me-2 dropdown-toggle"
                        data-bs-toggle="dropdown" aria-expanded="false" aria-label="menu">
                        <span class="fa-solid fa-ellipsis-vertical" aria-hidden="true"></span>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                          <a class="dropdown-item" [ngClass]="{'disabled':isEnable}">
                            <!-- <img src="../../assets/images/ico_delete.svg" /> -->
                            <i class="fa-regular fa-copy pe-2 pt-1" title="Copy"></i>
                            Copy
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" [ngClass]="{'disabled':isEnable}">
                            <!-- <img src="../../assets/images/ico_move.svg" alt="Move"/> -->
                            <i class="fa fa-arrows pe-2 pt-1" title="Move"></i>
                            Move
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" [ngClass]="{'disabled':isEnable}">
                            <!-- <img src="../../assets/images/ico_delete.svg" /> -->
                            <i class="fa-solid fa-down-left-and-up-right-to-center pe-2 pt-1" title="Merge"></i>
                            Merge
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" [ngClass]="{'disabled':isEnable}" (click)="deleteSingleObject(data)">
                            <!-- <img src="../../assets/images/ico_delete.svg" alt="Delete"/> -->
                            <i class="fa-solid fa-trash-can pe-2 pt-1" title="Delete"></i>
                            Delete
                          </a>
                        </li>
                        <li (click)="onButtonClick(data, header,'Properties')"  data-Bs-Toggle="modal"
                        data-Bs-Target="#docInformation">
                          <a class="dropdown-item">
                            <!-- <img src="../../assets/images/ico_delete.svg" /> -->
                            <i class="fa-brands fa-product-hunt pe-2 pt-1" title="Properties"></i>
                            Properties
                          </a>
                        </li>
                        <!-- (click)="delegateDataAssign(data)" -->
                        <li (click)="onButtonClick(data, header,'Delegate')" *ngIf="userRole == 'Admin'">
                          <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#delegateFilesModal">
                            <i
                              class="fa-brands fa-product-hunt pe-2 pt-1"
                              title="Properties"
                            ></i>
                            Delegate
                          </a>
                        </li>
                        <li>
                          <a data-bs-toggle="modal" data-bs-target="#viewGeoLocationModal" #viewGeoLocationRef></a>
                          <a class="dropdown-item" [ngClass]="{'disabled':isEnable}" (click)="viewGeoLocationOpen(data.latitude, data.longitude, data.objectTypeId)">
                            <!-- <img src="../../assets/images/ico_delete.svg" /> -->
                            <i class="fa fa-eye pe-2 pt-1" title="View Geolocation"></i>
                            View Geolocation
                          </a>
                        </li>
                        <li>
                          <a data-bs-toggle="modal" data-bs-target="#editGeoLocationModal" #editGeoLocationRef></a>
                          <a class="dropdown-item" [ngClass]="{'disabled':isEnable}" (click)="editGeoLocationOpen(data)">
                            <!-- <img src="../../assets/images/ico_delete.svg" /> -->
                            <i class="fa fa-edit pe-2 pt-1" title="Edit Geolocation"></i>
                            Edit Geolocation
                          </a>
                        </li>
                      </ul>
                    </div>
                  </ng-template>
                </div>
                <ng-template #singleButton>
                  <button type="button" class="btn btn-outline-dark btn-sm" (click)="onButtonClick(data, header,'')">
                    {{ data[header.columnName] }}
                  </button>
                </ng-template>
              </td>
              <td *ngIf="header.dropDown">
                <select #dropDown [(ngModel)]="data[header.columnName]"
                  name="data[idColumnName] + '_' + header.columnName" id="data[idColumnName] + '_' + header.columnName"
                  (change)="onOptionsSelected(dropDown.value)">
                  <ng-container *ngFor="let options of dropDownVal">
                    <option [value]="options.value">{{ options.name }}</option>
                  </ng-container>
                </select>
              </td>
              <td *ngIf="header.dropDownButton">
                <div class="d-inline me-3">
                  <select #mySelect [(ngModel)]="data[header.columnName]"
                    name="data[idColumnName] + '_' + header.columnName"
                    id="data[idColumnName] + '_' + header.columnName" (change)="onOptionsSelected(mySelect.value)">
                    <ng-container *ngFor="let options of dropDownVal">
                      <option [value]="options.value">{{ options.name }}</option>
                    </ng-container>
                  </select>
                </div>
                <button type="button" class="btn btn-outline-dark btn-sm" (click)="onButtonClick(data, header,'')">
                  save
                </button>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<ng-template #noData>
  <div>
    <p class="text-center text-danger">No Data Found</p>
  </div>
</ng-template>

<!------ View Geo Loaction --------->

<div class="modal fade" id="viewGeoLocationModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="viewGeoLocationModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable w-100" style="max-width: 1100px;">
      <div class="modal-header visually-hidden">
        <h5 class="modal-title" id="viewGeoLocationModalLabel">Map</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
        <div class="modal-content">
            <div class="modal-body geolocate-mapbox">
                <div id="mapViewGeoLocation" style="height: 100%;" [ngClass]="{'dprViewMapVisible' : isMapLoading}"></div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="map!.remove();marker.remove()">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

<!------ Edit Geo Loaction --------->

<div class="modal fade" id="editGeoLocationModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="editGeoLocationModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable w-100" style="max-width: 1100px;">
        <div class="modal-content">
          <h5 id="editGeoLocationModalLabel" class="visually-hidden">Map</h5>
            <div class="modal-body geolocate-mapbox">
                <div id="mapEditGeoLocation" style="height: 100%;" [ngClass]="{'dprViewMapVisible' : isMapLoading}"></div>
            </div>
            <div class="modal-footer justify-content-start">
              <ul *ngIf="marker.getLngLat()" class="list-inline">
                <li class="list-inline-item"><b>Latitude</b> :{{marker.getLngLat().lat}}</li>
                <li class="list-inline-item"><b>Longitude</b> :{{marker.getLngLat().lng}}</li>
              </ul>
              <button type="button" class="btn btn-secondary ms-auto" data-bs-dismiss="modal" (click)="map!.remove();marker.remove()">
                Close
              </button>
              <button type="button" class="btn btn-success" (click)="addFileToGeolocate()">
                Add Geolocation
              </button>
            </div>
        </div>
    </div>
</div>

<!-- Delegate File/Folders -->

<!-- <div class="modal fade" id="delegateFilesModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="delegateFilesLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="delegateFilesLabel"><span>Delegate</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <app-delegate-files [delegateDataId]="delegateData"></app-delegate-files>
    </div>
  </div>
</div> -->