import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as turf from '@turf/turf';
import { feature } from '@turf/turf';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import { MapViewerService } from 'src/app/modules/map/services/mapviewer.service';

@Component({
  selector: 'app-revision-history',
  templateUrl: './revision-history.component.html',
  styleUrls: ['./revision-history.component.css']
})
export class RevisionHistoryComponent implements OnInit, OnDestroy {

  @Input() map:any;
  @Input() draw:any;
  @Input() loginUserId:any;
  @Input() jobDetailsForGPRS:any[] = [];
  @Input() historyMetaData:any[] = [];
  @Input() featuresForRevision:any[] = [];
  @Input() isHistoryFeatureClicked : boolean = false;
  @Input() isHistoryTypeClicked : boolean = false;
  @Input() isHistoryGroupClicked : boolean = false;
  @Input() isEditingModeIsOn:boolean = false;
  @Output() historyOpend = new EventEmitter();
  @Output() revertFeatureToRevision = new EventEmitter();

  revisionContentHeight = window.innerHeight - 256;
  revisionsList:any[] = []
  currentChanges:any[] = [
    {
      id:1,
      text:"coordinate change",
      value:"lat/long value"
    },
    {
      id:2,
      text:"coordinate change",
      value:"lat/long value"
    },
    {
      id:3,
      text:"coordinate change",
      value:"lat/long value"
    },
    {
      id:4,
      text:"coordinate change",
      value:"lat/long value"
    },
    {
      id:5,
      text:"coordinate change",
      value:"lat/long value"
    },
    {
      id:6,
      text:"coordinate change",
      value:"lat/long value"
    }
  ]
  selectedRevisionForRevert:any;
  currentSelectedFeature:any

  historyReq = {
    "request": {
      "pageNumber": 1,
      "pageSize": 10
    },
    "featureId": '',
    "userId": ''
  }

  historyChangesSub:Subscription | undefined

  constructor(private mapViewerService: MapViewerService,
    private spinner: NgxSpinnerService,
    private toaster:ToastrService
    ) { }
 

  ngOnInit(): void {
    this.spinner.show();
    this.revisionsList = [];
    this.historyReq.userId = this.loginUserId;
    const obs:any[] = [];
    this.featuresForRevision.forEach((feature)=> {
      this.historyReq.featureId = feature.featureId;
      this.revisionsList.push({ featureId:feature.featureId, data:[]});
      const type = feature?.featureGeometryType ? feature.featureGeometryType : feature.type
      obs.push(this.mapViewerService.getHistoryData(type, {...this.historyReq}));
    })

    this.historyChangesSub = forkJoin(obs).subscribe((res:any[])=>{
      res.forEach((results:any[], index:any)=>{
        this.revisionsList[index].data = results;
      })
      this.spinner.hide();
    }, (error)=>{
      this.spinner.hide();
    })
  }

  ngOnDestroy(): void {
    this.historyChangesSub?.unsubscribe();
    this.revisionsList = [];
    this.selectedRevisionForRevert = undefined;
    this.currentSelectedFeature = undefined;
  }

  getRevisions(featureId:any) {
    const res = this.revisionsList.find((revision)=> revision.featureId === featureId);
    return res ? res?.data : [];
  }

  onSaveHistoryFeatureClick(){
    this.revertFeatureToRevision.emit({feature: this.currentSelectedFeature, revision: this.selectedRevisionForRevert, mapJobId:this.currentSelectedFeature.jobId});
  }

  onCancelHistoryFeature(){
    this.historyOpend.emit(false);
  }

  onRevertHistoryClick(feature:any, revision:any) {
    if(confirm("Are you sure You want to Revert?")){
      this.spinner.show();
      // Code required if we need feature metadata for processing. 
      let currentFeature:any = {};
      this.jobDetailsForGPRS.forEach((job)=>{
        Object.assign(currentFeature, job);
        delete currentFeature.features;
        currentFeature["feature"] = this._getFeatureFromJobDetails(job.features, feature);
      })

      // to get Metadata of feature if required
      const layers = {layers:[
        currentFeature.layerId+'-point',
        currentFeature.layerId+'-line'
      ]}
      // const features = this.map.queryRenderedFeatures(layers);
      // const currentFt = features.find((ft:any)=> ft.properties.featureId === currentFeature.feature.featureId);

      
      // setTimeout(() => {
      //   this.draw.delete(currentFt.id);
      // }, 100);
      const type = feature?.featureGeometryType ? feature.featureGeometryType : feature.type

      this.mapViewerService.setHistory(type, {
          "featureId": feature.featureId,
          "auditId": revision.auditId,
          "userId": this.loginUserId
      }).subscribe((res:any)=>{
        this.selectedRevisionForRevert = revision;
        this.currentSelectedFeature = currentFeature;
        this.historyReq.featureId = feature.featureId;
        this.mapViewerService.getHistoryData(type, {...this.historyReq}).subscribe((revisions)=>{
          const index = this.revisionsList.findIndex((revision)=> revision.featureId === feature.featureId);
          this.revisionsList[index].data = revisions;
          this.toaster.success("Changes are reverted, Please click on Save to see.");
          this.spinner.hide();
        },
        (error:any)=>{
          this.spinner.hide();
        })
      },
      (error:any)=>{
        this.spinner.hide();
        this.toaster.error("Revert Revision : ", error);
      })
    }
  }

  private _getFeatureFromJobDetails(features:any[], feature:any) {
    return features.find((ft)=> ft.featureId === +feature.featureId);
  }

}
