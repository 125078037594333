import { FileGroup } from "../feature-tree.component.types";

export default function getTypeIndexFromFiles(files: FileGroup[], typeId: number) {
  let fileIndex = -1;
  let groupIndex = -1;
  let typeIndex = -1;

  files.some((file, fileIdx) => {
    fileIndex = fileIdx;
    return file.groups.some((group, groupIdx) => {
      groupIndex = groupIdx;
      return group.types.some((type, typeIdx) => {
        typeIndex = typeIdx;
        return parseInt(type.typeId as string) === typeId;
      });
    });
  });

  return {
    fileIndex,
    groupIndex,
    typeIndex
  };
}
