import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LayerMenuItemData } from './map-layer-menu-item.types';

@Component({
  selector: 'app-map-layer-menu-item',
  templateUrl: './map-layer-menu-item.component.html',
  styleUrls: ['./map-layer-menu-item.component.css'],
})
export class MapLayerMenuItemComponent {
  @Input()
  public itemData?: LayerMenuItemData;

  @Input()
  public editMode?: boolean;

  @Output()
  public onDeleteItem = new EventEmitter<LayerMenuItemData>();

  @Output()
  public onToggleItem = new EventEmitter<LayerMenuItemData>();


  public defaultIconUrls: { [geomType: string]: string } = {
    point: '/assets/images/GPRS_PNG/REFERENCE_POINT.png',
    polygon: '/assets/images/GPRS_PNG/REFERENCE_POLYGON.png',
    multipolygon: '/assets/images/GPRS_PNG/REFERENCE_POLYGON.png',
  };

  constructor() {

  }

  onImgError(evt: ErrorEvent, item: LayerMenuItemData) {
    const geomType = (item.geomType || "").toLocaleLowerCase();
    const target = evt.target as HTMLImageElement;
    if(target && this.defaultIconUrls[geomType]){
        target.src = this.defaultIconUrls[geomType];
    }
  }

  setIsEyeVisibleAttribute(item: LayerMenuItemData, value: boolean | null = null) {
    if(value == null){
      value = !item.isEyeVisible;
    }
    item.isEyeVisible = value;
    if(item.children && item.children.length > 0){
      item.children.forEach(child => {
        this.setIsEyeVisibleAttribute(child, item.isEyeVisible);
      });
    }
  }

  toggleItem(item: LayerMenuItemData) {
    this.setIsEyeVisibleAttribute(item);
    this.onToggleItem.emit(item);
  }

  deleteItem(item: LayerMenuItemData) {
    item.isEyeVisible = !item.isEyeVisible;
    this.onDeleteItem.emit(item);
    // if(item.children && item.children.length > 0){
    //   item.children.forEach(child => {
    //     this.deleteItem(child);
    //   });
    // }
  }


}
