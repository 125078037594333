<div *ngIf="itemData" class="accordion-item custom-accordion-item">
  <div class="mt-4">
    <h2 class="file-title">
      <span class="file-name">{{ itemData.name }}</span>
      <span class="sub-page-menu-action">
        <i [ngClass]="itemData.isEyeVisible ? 'eye-visible' : 'eye-lash'" (click)="toggleItem(itemData)"></i>
        <i class="map-delete-icon" *ngIf="editMode" (click)="deleteItem(itemData)"></i>
      </span>
    </h2>
  </div>
  <!-- <div id="featureType.typeContentElementId" class="accordion-collapse collapse" aria-labelledby="featureType.typeContentElementId"> -->
  <div class="accordion-body custom-accordion-body">
    <ul id="featureType.typeLabelElementId">
      <li
        *ngFor="let item of itemData?.children"
        class="position-relative"
        #featureItemElement
      >
        <img
          *ngIf="!item.isLine"
          [src]="item.iconUrl"
          (error)="onImgError($event, item)"
        />
        <span
          *ngIf="item.isLine"
          [ngStyle]="{ 'background-color': item.color }"
          class="line-feature"
        ></span>
        <i class="feature-style">{{ item.name }}</i>
        <span class="sub-page-menu-action">
          <i
            class="group-feature.featureName inner-group feature-name feature.featureName feature-group-margin"
            [ngClass]="item.isEyeVisible ? 'eye-visible' : 'eye-lash'"
            (click)="toggleItem(item)"
          ></i>
          <i class="map-delete-icon" *ngIf="editMode" (click)="deleteItem(item)"></i>
        </span>
      </li>
    </ul>
  </div>
</div>
