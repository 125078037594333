<div class="row border-bottom mb-3">
  <div class="col-lg-9 col-md-9 col-sm-8">
    <div class="page-title">
      <h1><a (click)="navigatePage()"><img src="../../assets/images/ico_back_circle.svg" alt="Back"></a>Access Control
        - {{userName}}</h1>
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-4">
    <div class="input-group custom-input-group mb-3">
      <input type="text" class="form-control" placeholder="Search by job" aria-label="Search"
        aria-describedby="basic-addon1">
      <span class="input-group-text" id="basic-addon1"><img src="../../../../../assets/images/ico_search.svg" /></span>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <app-common-table 
      [tableHeader]="accessCntrlTblHeader"  
      [tableData]="accessCntrlTblData" 
      [dropDownVal]="dropDownVal"
      (buttonClickEmitter)="btnClick($event)"
      (linkClickEmitter)="linkClick($event)"
      [tblClass]="pageTable">
    </app-common-table>
  </div>
</div>


<!-- Permissions Modal Starts-->
<div class="modal fade" id="permissionsEdit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="permissionsEditLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="permissionsEditLabel"><span>Edit Permissions for York Leng
          </span>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="modal_title_view_cb">
            <label class="form-check-label" for="modal_title_view_cb" style="font-size: 20px; padding-top: 5px;">
              Select all
            </label>
          </div>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <!-- VIEW Modal -->
        <div class="row">
          <div class="col-lg-12">
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card mb-3">
                    <h5 class="card-header card-header-bold">
                      VIEW
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="modal_view_cb">
                        <label class="form-check-label" for="modal_view_cb">
                          Select all
                        </label>
                      </div>
                    </h5>
                    <div class="card-body py-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-check px-0 custom-form-check">
                            <i class="float-start" style="min-width: 152px;">Digital Plan Room</i>
                            <input class="form-check-input" type="checkbox" id="modal_view_dpr_select_all">
                            <label class="form-check-label" for="modal_view_dpr_select_all">Select All</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_view_cb1">
                            <label class="form-check-label" for="modal_view_cb1">Files of a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_view_cb2">
                            <label class="form-check-label" for="modal_view_cb2">Files of a Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_view_cb3">
                            <label class="form-check-label" for="modal_view_cb3">Files of a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_view_cb4">
                            <label class="form-check-label" for="modal_view_cb4">Folders of a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_view_cb5">
                            <label class="form-check-label" for="modal_view_cb5">Folders of a
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_view_cb6">
                            <label class="form-check-label" for="modal_view_cb6">Folders of a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_view_cb7">
                            <label class="form-check-label" for="modal_view_cb7">Only the shared
                              files</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_view_cb8">
                            <label class="form-check-label" for="modal_view_cb8">Only the shared
                              folders</label>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="form-check px-0 custom-form-check mt-2">
                            <i class="float-start" style="min-width: 75px;">Shared</i>
                            <input class="form-check-input" type="checkbox" id="modal_shared_select_all"
                              value="option1">
                            <label class="form-check-label" for="modal_shared_select_all">Select All</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_shared_cb1">
                            <label class="form-check-label" for="modal_shared_cb1">All the files of the
                              Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_shared_cb2">
                            <label class="form-check-label" for="modal_shared_cb2">All the files of the
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_shared_cb3">
                            <label class="form-check-label" for="modal_shared_cb3">All the files of the
                              Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_shared_cb4">
                            <label class="form-check-label" for="modal_shared_cb4">All the folders of the
                              Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_shared_cb5">
                            <label class="form-check-label" for="modal_shared_cb5">All the folders of the
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_shared_cb6">
                            <label class="form-check-label" for="modal_shared_cb6">All the folders of the
                              Job</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>



          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card mb-3">
                    <h5 class="card-header card-header-bold">
                      EDIT
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="modal_flexCheckChecked">
                        <label class="form-check-label" for="modal_flexCheckChecked">
                          Select all
                        </label>
                      </div>
                    </h5>
                    <div class="card-body py-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-check px-0 custom-form-check">
                            <i class="float-start" style="min-width: 152px;">Digital Plan Room</i>
                            <input class="form-check-input" type="checkbox" id="modal_edit_dpr_cb" value="option1">
                            <label class="form-check-label" for="modal_edit_dpr_cb">Select All</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_edit_cb1" value="option1">
                            <label class="form-check-label" for="modal_edit_cb1">Files of a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_edit_cb2" value="option2">
                            <label class="form-check-label" for="modal_edit_cb2">Files of a Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_edit_cb3" value="option3">
                            <label class="form-check-label" for="modal_edit_cb3">Files of a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_edit_cb4" value="option3">
                            <label class="form-check-label" for="modal_edit_cb4">Folders of a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_edit_cb5" value="option3">
                            <label class="form-check-label" for="modal_edit_cb5">Folders of a Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_edit_cb6" value="option3">
                            <label class="form-check-label" for="modal_edit_cb6">Folders of a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_edit_cb7" value="option3">
                            <label class="form-check-label" for="modal_edit_cb7">Only the shared files</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_edit_cb8" value="option3">
                            <label class="form-check-label" for="modal_edit_cb8">Only the shared folders</label>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="form-check px-0 custom-form-check mt-2">
                            <i class="float-start" style="min-width: 75px;">Shared</i>
                            <input class="form-check-input" type="checkbox" id="modal_edit_shared_select_all"
                              value="option1">
                            <label class="form-check-label" for="modal_edit_shared_select_all">Select All</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_edit_shared_cb1" value="option1">
                            <label class="form-check-label" for="modal_edit_shared_cb1">All the files of the
                              Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_edit_shared_cb2" value="option2">
                            <label class="form-check-label" for="modal_edit_shared_cb2">All the files of the
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_edit_shared_cb3" value="option3">
                            <label class="form-check-label" for="modal_edit_shared_cb3">All the files of the
                              Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_edit_shared_cb4" value="option3">
                            <label class="form-check-label" for="modal_edit_shared_cb4">All the folders of the
                              Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_edit_shared_cb5" value="option3">
                            <label class="form-check-label" for="modal_edit_shared_cb5">All the folders of the
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_edit_shared_cb6" value="option3">
                            <label class="form-check-label" for="modal_edit_shared_cb6">All the folders of the
                              Job</label>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="form-check px-0 custom-form-check mt-2">
                            <i class="float-start" style="min-width: 140px;">Editing Privileges</i>
                            <input class="form-check-input" type="checkbox" id="modal_ep_select_all" value="option1">
                            <label class="form-check-label" for="modal_ep_select_all">Select All</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ep_cb1" value="option1">
                            <label class="form-check-label" for="modal_ep_cb1">Point in a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ep_cb2" value="option2">
                            <label class="form-check-label" for="modal_ep_cb2">Point in a
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ep_cb3" value="option3">
                            <label class="form-check-label" for="modal_ep_cb3">Point in a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ep_cb4" value="option3">
                            <label class="form-check-label" for="modal_ep_cb4">Line in a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ep_cb5" value="option3">
                            <label class="form-check-label" for="modal_ep_cb5">Line in a Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ep_cb6" value="option3">
                            <label class="form-check-label" for="modal_ep_cb6">Line in a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ep_cb7" value="option3">
                            <label class="form-check-label" for="modal_ep_cb7">Layers in a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ep_cb8" value="option3">
                            <label class="form-check-label" for="modal_ep_cb8">Layers in a Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ep_cb9" value="option3">
                            <label class="form-check-label" for="modal_ep_cb9">Layers in a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ep_cb10" value="option3">
                            <label class="form-check-label" for="modal_ep_cb10">Features in a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ep_cb11" value="option3">
                            <label class="form-check-label" for="modal_ep_cb11">Features in a
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ep_cb12" value="option3">
                            <label class="form-check-label" for="modal_ep_cb12">Features in a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ep_cb13" value="option3">
                            <label class="form-check-label" for="modal_ep_cb13">Categories in a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ep_cb14" value="option3">
                            <label class="form-check-label" for="modal_ep_cb14">Categories in a
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ep_cb15" value="option3">
                            <label class="form-check-label" for="modal_ep_cb15">Categories in a Job</label>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card mb-3">
                    <h5 class="card-header card-header-bold">
                      ADD
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="modal_add_flexCheckChecked">
                        <label class="form-check-label" for="modal_add_flexCheckChecked">
                          Select all
                        </label>
                      </div>
                    </h5>
                    <div class="card-body py-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_add_inlineCheckbox1"
                              value="option1">
                            <label class="form-check-label" for="modal_add_inlineCheckbox1">Point in a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_add_inlineCheckbox2"
                              value="option2">
                            <label class="form-check-label" for="modal_add_inlineCheckbox2">Point in a
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_add_inlineCheckbox3"
                              value="option3">
                            <label class="form-check-label" for="modal_add_inlineCheckbox3">Point in a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_add_inlineCheckbox4"
                              value="option3">
                            <label class="form-check-label" for="modal_add_inlineCheckbox4">Line in a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_add_inlineCheckbox5"
                              value="option3">
                            <label class="form-check-label" for="modal_add_inlineCheckbox5">Line in a Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_add_inlineCheckbox6"
                              value="option3">
                            <label class="form-check-label" for="modal_add_inlineCheckbox6">Line in a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_add_inlineCheckbox7"
                              value="option3">
                            <label class="form-check-label" for="modal_add_inlineCheckbox7">Layers in a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_add_inlineCheckbox8"
                              value="option3">
                            <label class="form-check-label" for="modal_add_inlineCheckbox8">Layers in a
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_add_inlineCheckbox9"
                              value="option3">
                            <label class="form-check-label" for="modal_add_inlineCheckbox9">Layers in a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_add_inlineCheckbox10"
                              value="option3">
                            <label class="form-check-label" for="modal_add_inlineCheckbox10">Features in a
                              Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_add_inlineCheckbox11"
                              value="option3">
                            <label class="form-check-label" for="modal_add_inlineCheckbox11">Features in a
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_add_inlineCheckbox12"
                              value="option3">
                            <label class="form-check-label" for="modal_add_inlineCheckbox12">Features in a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_add_inlineCheckbox13"
                              value="option3">
                            <label class="form-check-label" for="modal_add_inlineCheckbox13">Categories in a
                              Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_add_inlineCheckbox14"
                              value="option3">
                            <label class="form-check-label" for="modal_add_inlineCheckbox14">Categories in a
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_add_inlineCheckbox15"
                              value="option3">
                            <label class="form-check-label" for="modal_add_inlineCheckbox15">Categories in a
                              Job</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card mb-3">
                    <h5 class="card-header card-header-bold">
                      DELETE <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="modal_del_select_all">
                        <label class="form-check-label" for="modal_del_select_all">
                          Select all
                        </label>
                      </div>
                    </h5>
                    <div class="card-body py-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_del_cb1">
                            <label class="form-check-label" for="modal_del_cb1">Point in a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_del_cb2">
                            <label class="form-check-label" for="modal_del_cb2">Point in a
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_del_cb3">
                            <label class="form-check-label" for="modal_del_cb3">Point in a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_del_cb4">
                            <label class="form-check-label" for="modal_del_cb4">Line in a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_del_cb5">
                            <label class="form-check-label" for="modal_del_cb5">Line in a Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_del_cb6">
                            <label class="form-check-label" for="modal_del_cb6">Line in a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_del_cb7">
                            <label class="form-check-label" for="modal_del_cb7">Layers in a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_del_cb8">
                            <label class="form-check-label" for="modal_del_cb8">Layers in a Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_del_cb9">
                            <label class="form-check-label" for="modal_del_cb9">Layers in a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_del_cb10">
                            <label class="form-check-label" for="modal_del_cb10">Features in a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_del_cb11">
                            <label class="form-check-label" for="modal_del_cb11">Features in a
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_del_cb12">
                            <label class="form-check-label" for="modal_del_cb12">Features in a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_del_cb13">
                            <label class="form-check-label" for="modal_del_cb13">Categories in a
                              Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_del_cb14">
                            <label class="form-check-label" for="modal_del_cb14">Categories in a
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_del_cb15">
                            <label class="form-check-label" for="modal_del_cb15">Categories in a Job</label>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ACCESS CONTROL Modal -->
        <div class="row">
          <div class="col-lg-12">
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card mb-3">
                    <h5 class="card-header card-header-bold">
                      ACCESS CONTROL <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="modal_ac_select_all">
                        <label class="form-check-label" for="modal_ac_select_all">
                          Select all
                        </label>
                      </div>
                    </h5>
                    <div class="card-body py-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ac_cb1">
                            <label class="form-check-label" for="modal_ac_cb1">Certain Sites or Subsite
                              in a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ac_cb2" value="option2">
                            <label class="form-check-label" for="modal_ac_cb2">Certain Subsite in a
                              Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ac_cb3" value="option3">
                            <label class="form-check-label" for="modal_ac_cb3">All Jobs in a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ac_cb4" value="option3">
                            <label class="form-check-label" for="modal_ac_cb4">All Jobs in a Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ac_cb5" value="option3">
                            <label class="form-check-label" for="modal_ac_cb5">Can provide access to read or
                              edit access to a certain File</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ac_cb6" value="option3">
                            <label class="form-check-label" for="modal_ac_cb6">CanpProvide access to read or
                              edit access to a certain folder</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ac_cb7" value="option3">
                            <label class="form-check-label" for="modal_ac_cb7">Can Access files by drawing a
                              boundary on a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_ac_cb8" value="option3">
                            <label class="form-check-label" for="modal_ac_cb8">Can access folders by drawing
                              a boundary on Site</label>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- SHARED Modal -->
        <div class="row">
          <div class="col-lg-12">
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card mb-3">
                    <h5 class="card-header card-header-bold">
                      SHARED <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="modal_upload_flexCheckChecked">
                        <label class="form-check-label" for="modal_upload_flexCheckChecked">
                          Select all
                        </label>
                      </div>
                    </h5>
                    <div class="card-body py-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_upload_inlineCheckbox1">
                            <label class="form-check-label" for="modal_upload_inlineCheckbox1">Can share a File with
                              View access</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_upload_inlineCheckbox2">
                            <label class="form-check-label" for="modal_upload_inlineCheckbox2">Can share a File with
                              Edit
                              access</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_upload_inlineCheckbox3">
                            <label class="form-check-label" for="modal_upload_inlineCheckbox3">Can share a Folder with
                              View access</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_upload_inlineCheckbox4">
                            <label class="form-check-label" for="modal_upload_inlineCheckbox4">Can share a folder with
                              Edit access</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Print Modal -->
        <div class="row">
          <div class="col-lg-12">
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card mb-3">
                    <h5 class="card-header card-header-bold">
                      PRINT <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="modal_print_cb">
                        <label class="form-check-label" for="modal_print_cb">Select all</label>
                      </div>
                    </h5>
                    <div class="card-body py-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_print_cb1">
                            <label class="form-check-label" for="modal_print_cb1">Can share a File with Can print
                              files
                              in a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_print_cb2">
                            <label class="form-check-label" for="modal_print_cb2">Can print files in a Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_print_cb3">
                            <label class="form-check-label" for="modal_print_cb3">Can print files in a Job</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Upload Modal -->
        <div class="row">
          <div class="col-lg-12">
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card mb-3">
                    <h5 class="card-header card-header-bold">
                      UPLOAD <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="modal_upload_select_all">
                        <label class="form-check-label" for="modal_upload_select_all">
                          Select all
                        </label>
                      </div>
                    </h5>
                    <div class="card-body py-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_upload_cb1">
                            <label class="form-check-label" for="modal_upload_cb1">Files fo a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_upload_cb2">
                            <label class="form-check-label" for="modal_upload_cb2">Files to a
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_upload_cb3">
                            <label class="form-check-label" for="modal_upload_cb3">Files to a Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_upload_cb4">
                            <label class="form-check-label" for="modal_upload_cb4">Folders fo a Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_upload_cb5">
                            <label class="form-check-label" for="modal_upload_cb5">Folders fo a
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_upload_cb6">
                            <label class="form-check-label" for="modal_upload_cb6">Folders fo a Job</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Download Modal -->
        <div class="row">
          <div class="col-lg-12">
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card mb-3">
                    <h5 class="card-header card-header-bold">
                      DOWNLOAD <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="modal_download_flexCheckChecked">
                        <label class="form-check-label" for="modal_download_flexCheckChecked">
                          Select all
                        </label>
                      </div>
                    </h5>
                    <div class="card-body py-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_download_inlineCheckbox1">
                            <label class="form-check-label" for="modal_download_inlineCheckbox1">Files fo a
                              Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_download_inlineCheckbox2">
                            <label class="form-check-label" for="modal_download_inlineCheckbox2">Files to a
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_download_inlineCheckbox3">
                            <label class="form-check-label" for="modal_download_inlineCheckbox3">Files to a
                              Job</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_download_inlineCheckbox4">
                            <label class="form-check-label" for="modal_download_inlineCheckbox4">Folders fo a
                              Site</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_download_inlineCheckbox5">
                            <label class="form-check-label" for="modal_download_inlineCheckbox5">Folders fo a
                              Subsite</label>
                          </div>
                          <div class="form-check form-check-inline custom-form-check-inline">
                            <input class="form-check-input" type="checkbox" id="modal_download_inlineCheckbox6">
                            <label class="form-check-label" for="modal_download_inlineCheckbox6">Folders fo a
                              Job</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-success" data-bs-dismiss="modal">Save</button>
      </div>
    </div>
  </div>
</div>
<!-- Permissions Modal Ends-->
