import { Injectable } from '@angular/core';
import { HttpRequest,HttpResponse,HttpHeaders,HttpClient } from '@angular/common/http';
import {PortalAPI } from '../../../../constants/api.constant';
import { Observable, Subscription } from 'rxjs';
import { BehaviorSubjectService } from 'src/app/services/behaviorsubject.service';
import { CredentialessLogin } from 'src/app/common-component/credentialess-login/credentialess-login';

@Injectable({
  providedIn: 'root' 
})
export class DashboardService {
  public options :any =  {};
  isCredentialessLogin:boolean=false;
  credentialessLogin:CredentialessLogin | null =null;
  
  constructor(
    private http:HttpClient,
    private behaviourSubjectService: BehaviorSubjectService
  ) {
    let reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    reqHeader.append('Access-Control-Allow-Origin', '*');
    reqHeader.append('accept', 'text/plain');
    reqHeader.append('Access-Control-Allow-Headers','Origin, X-Requested-With, Content-Type, Accept');
    reqHeader.append('Access-Control-Allow-Methods','GET,POST,OPTIONS,DELETE,PUT');
    this.options = { headers: reqHeader };

    this.behaviourSubjectService.credentialessLoginObservable.subscribe((credentialessLogin:CredentialessLogin) => {
      this.credentialessLogin = credentialessLogin;
      
        if (this.credentialessLogin?.guid) {
          this.isCredentialessLogin = true;
        } else {
          this.isCredentialessLogin=false;
        }
    }); 
  }

  getRecentJobList(data:any){       
    return this.http.post(PortalAPI.RECENT_JOBS,data ,this.options);
  }

  getRecentDocumentList(data:any){
    return this.http.post(PortalAPI.RECENT_DOCUMENTS, data, this.options);
  }

  shareObject(data:any){
    return this.http.post(PortalAPI.SHARE_OBJECT,data, this.options);
  }


  getJobLogByJobId(data:any){
    return this.http.post(PortalAPI.GET_JOBS_LOGS_JOB_ID, data, this.options);
  }

  getDocumentByJobId(data:any){
    return this.http.post(PortalAPI.GET_DOCUMENT_LOGS_JOB,data, this.options);
  }

  fetchEmails(inputString: string): Observable<string[]>{
    return this.http.get<string[]>(`${PortalAPI.SEARCH_EMAIL}/${inputString}`);
  }


  fetchEditEmails(inputString: string): Observable<string[]>{
   return this.http.get<string[]>(`${PortalAPI.SEARCH_EMAIL_EDIT}/${inputString}`);
  }
  

  fetchSharedWithUserList(objectId: number, objectTypeId: number){
    return this.http.get(`${PortalAPI.FETCH_SHARED_WITH_USER_LIST}/${objectId}/${objectTypeId}`);
  }

  getAllJobLatLong(dataObj:any){
    if ((this.isCredentialessLogin && !this.credentialessLogin?.userLoggedIn) || (this.isCredentialessLogin && !this.credentialessLogin?.userHasAccessToJob)) {
      return this.http.get(`${PortalAPI.GET_ALL_JOBS_LAT_LONG_GUID}/${this.credentialessLogin?.guid}`);
    } else {
      return this.http.get(`${PortalAPI.GET_ALL_JOBS_LAT_LONG}/${dataObj.userId}`);
    }  
  }

  getShareJobAtLogin(data:any){
	return this.http.post(PortalAPI.SHARE_JOB_AT_LOGIN, data, this.options);
  }

  ngOnDestroy(){  }
}
