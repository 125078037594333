import { Injectable } from '@angular/core';
import { HttpHeaders,HttpClient } from '@angular/common/http';
import { PortalAPI } from '../constants/api.constant';
import { BehaviorSubjectService } from './behaviorsubject.service';
import { Observable, Subscription } from 'rxjs';
import { CredentialessLogin } from '../common-component/credentialess-login/credentialess-login';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public options: { headers?: HttpHeaders } = {};
  isCredentialessLogin:boolean=false;
  credentialessLogin:CredentialessLogin | null =null;

  constructor(
    private http:HttpClient,
    private behaviourSubjectService: BehaviorSubjectService
  ) { 
    let reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    reqHeader.append('Access-Control-Allow-Origin', '*');
    reqHeader.append('accept', 'text/plain');
    reqHeader.append('Access-Control-Allow-Headers','Origin, X-Requested-With, Content-Type, Accept');
    reqHeader.append('Access-Control-Allow-Methods','GET,POST,OPTIONS,DELETE,PUT');
    this.options = { headers: reqHeader };

    this.behaviourSubjectService.credentialessLoginObservable.subscribe((credentialessLogin:CredentialessLogin) => {
      this.credentialessLogin = credentialessLogin;
      
        if (this.credentialessLogin?.guid) {
          this.isCredentialessLogin = true;
        } else {
          this.isCredentialessLogin=false;
        }
    }); 
  }


  updateUserDetails(data:any){        
    return this.http.post(PortalAPI.UPDATE_USER_DETAILS, data ,this.options);
  }

  updateUserRenderScale(data:any) {
    return this.http.post(PortalAPI.UPDATE_USER_RENDER_SCALE, data, this.options);
  }
  
  getProfileDropDownData(){  
    return this.http.get(PortalAPI.PROFILE_DRODOWN_DATA, this.options);
  }

  //setAcceptTNC(payload : any){

  //  return this.http.post(PortalAPI.ACCEPT_TNC, payload, this.options);
  //}

  getJobsSharedByUser(payload : any){
	return this.http.post(PortalAPI.JOBS_SHARED_COLLABORATION,payload, this.options);
  }

  getDocumentsSharedByUser(payload : any){
  	return this.http.post(PortalAPI.DOCUMENTS_SHARED_COLLABORATION,payload, this.options);
      }

  changeAccessTypeInObject(data:any){
    return this.http.post(PortalAPI.SHARE_OBJECT,data, this.options);
      }

  getUserDetails(userAzureADId:any,EmailID:any){
    return this.http.get(PortalAPI.FETCH_USER_DETAILS + `/${userAzureADId}` + `/${EmailID}`, this.options);  }

   getSiteList(userId: any, data: any) {     
    return this.http.post(PortalAPI.GET_SITE + `?userId=${userId}`, data, this.options);
  }
  
  userSyncByEmailMobileNo(data:any){
    return this.http.post(PortalAPI.SYNC_USER,data, this.options);
  }
  
  addSite(data: any) {    
    return this.http.post(PortalAPI.ADD_SITE, data, this.options);
  }
  getAllNotoficationList(data:any){
    return this.http.post(PortalAPI.GET_ALL_NOTIFICATION_LIST,data, this.options);
  }

  markNotoficationMessageRead(notificationId:any){
	return this.http.put(PortalAPI.MARK_NOTIFICATION_MESSAGE_READ + `/${notificationId}`, this.options);
  }

  markExportFileDownload(userId:any,importExportId:any){
    return this.http.put(PortalAPI.MARK_EXPORT_FILEDOWNLOAD + `/${userId}` + `/${importExportId}`, this.options);
  }


  // fetchJobDetailsByJobId(){
  //   return this.http.get("https://mapscreenqa.gprssitemap.com/api/MapScreen/RetrieveJobFullDetailsByJobId/36185", this.options);
  // }

  fetchLineDetailsByLineId(featureId: number){
    if (this.isCredentialessLogin && !this.credentialessLogin?.userLoggedIn) {
      return this.http.get(PortalAPI.RETRIEVE_LINE_DETAILS_BY_LINEID_GUID + `/${featureId}` + `/${this.credentialessLogin?.guid}`, this.options);
    } else {
      return this.http.get(PortalAPI.RETRIEVE_LINE_DETAILS_BY_LINEID + `/${featureId}`, this.options);
    }  
  }

  getAllJObsJson(){
    return this.http.get("./../")
  }

  getSubSiteList(userId: any, data: any) {     
    return this.http.post(PortalAPI.GET_SUBSITE + `?userId=${userId}`, data, this.options);
  }

  acceptTNC(userId: number, tncTypeId: number) {
    const data = { userId, tncTypeId };
    //const { BASE_URL, ACCEPT_TNC } = getConfigByEnvironment(this.isQAenv);

    return this.http.post(PortalAPI.ACCEPT_TNC, data, this.options);
  }

  getLatestTnc(typeId: number): Observable<{ version: number, url: string }> {
    //const { BASE_URL, FETCH_LATEST_TNC } = getConfigByEnvironment(this.isQAenv);

    const options = { ...this.options, params: { typeId: typeId.toString() } };
    return this.http.get<{ version: number, url: string }>(PortalAPI.FETCH_LATEST_TNC, options);
  }

}

