import { Feature } from "src/app/constants/common-model";
import { FeatureItem } from "../feature-tree.component.types";

export default function toFeatureItem(feature: Feature, icon?: string, color?: string): FeatureItem {
  const isEyeVisible = feature.isEyeVisible === undefined ? true : feature.isEyeVisible;
  const featureType = feature.type?.toLowerCase() || '';
  const isPoint = featureType === 'point';
  const isLine = featureType === 'line' || featureType === 'linestring';
  const commonFeatureProps = {...feature, isPoint, isLine, isEyeVisible }

  if (isPoint) {
    const src = '../../assets/images/GPRS_PNG/' + (icon?.replace(' ', '')) + '.png';
    const errorSrc = '../../assets/images/GPRS_PNG/GENERIC_BRIAN.png';
    return {...commonFeatureProps, src, errorSrc};
  }

  return {...commonFeatureProps, color: color || '#808080'};
}
