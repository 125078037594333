<!-- Message Board Starts-->
<div class="row">
  <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let message of messageListToDisplay">
    <div class="card custom-card" >
      <img [src]="message.imageMessage" class="card-img-top" alt="...">
      <div class="card-body">
        <div class="board-content">
        <p
          data-toggle="tooltip"
          [title]="message.textMessage"
          class="title-tip-up"
        >
          {{ message.textMessage | textSlice: 50 }}
        </p>
      </div>
      </div>
    </div>

    <div class="board" style="display: none;">
      <div class="board-image">
        <!-- <img src="../../../assets/images/board-message-img.jpg" alt="" /> -->
        <img [src]="message.imageMessage" alt="" class="d-block w-100 h-160" />
      </div>
      <div class="board-content">
        <p
          data-toggle="tooltip"
          [title]="message.textMessage"
          class="title-tip-up"
        >
          {{ message.textMessage | textSlice: 50 }}
        </p>
      </div>
    </div>
  </div>
</div>
<!-- Message Board End-->
