import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FreeDraggingDirective } from './free-dragging.directive';
import { PermissionDirective } from '../common-directives/permission.directive';



@NgModule({
  declarations: [FreeDraggingDirective, PermissionDirective],
  imports: [
    CommonModule
  ],
  exports: [FreeDraggingDirective,PermissionDirective], // Added

})
export class SharedModule { }
