import { Component, HostListener, Input, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { PortalAPI } from 'src/app/constants/api.constant';
import { IJobList, jobAssignedUserTableData, jobListTableData } from '../../constants/manage-jobs-data';
import { ConnectionService } from '../../services/connection-service';
import { FormGroup, FormBuilder, Validators, FormControl, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonFunctionService } from 'src/app/services/common-function-service';
import { ApiService } from 'src/app/services/api.service';
import { BehaviorSubjectService } from 'src/app/services/behaviorsubject.service';
import { DashboardService } from 'src/app/modules/dashboards/services/dashboard/dashboard.service';



@Component({
  selector: 'app-manage-jobs',
  templateUrl: './manage-jobs.component.html',
  styleUrls: ['./manage-jobs.component.css']
})
export class ManageJobsComponent implements OnInit {

  @Input() searchValue = "";
  @Output() selectedJobDataEmitter = new EventEmitter();

  pageTable: string = 'table-striped table-bordered custom-table table-colored';
  jobListData: IJobList[] = [];
  jobListTblHeader = new jobListTableData().tblHeader;
  isDivVisible: boolean = false;
  assignedUserList = [];
  assignedTblHeader = new jobAssignedUserTableData().tblHeader;
  detailForm = {
    jobId: null,
    customerName: null,
    siteName: null,
    jobContact: null,
    email: null,
    phone: null,
    CAD: null,
    description: null,
    workOrderNumber: null,
    name: null
  };
  sharedObjResponse: any = {};
  public AssignUserForm: any;
  actionButton = [
    {
      icon: 'fa-trash',
      tooltip: 'Edit User Information',
      dataBsToggle: 'modal',
      dataBsTarget: '#deleteAssignUser',
    }];
  jobIdByJobDetail: any;
  inputCheckClick: boolean = false;
  apiJobData: IJobList[] = [];
  tenantId: string = "";
  userDetailsFromDb: any;
  checkedData: any[] = [];
  formDisable: boolean = false;
  btn = (document.getElementById('btn001') as HTMLInputElement).disabled = true;
  noOfCheckedbox: any = 0
  tierIDData: any=[];

  @HostListener('click', ['$event'])
  onClick(event: any) {
    // get the clicked element
    if (event.target?.name && event.target?.name?.includes("chkBox")) {
      this.inputCheckClick = true;
      if (event.target.checked) {
        this.btn = (document.getElementById('btn001') as HTMLInputElement).disabled = false;
        this.noOfCheckedbox += 1;
      }
      else {
        if (this.noOfCheckedbox > 0) {
          this.btn = (document.getElementById('btn001') as HTMLInputElement).disabled = false;
          this.noOfCheckedbox -= 1;
        }
        if (this.noOfCheckedbox == 0) {
          this.btn = (document.getElementById('btn001') as HTMLInputElement).disabled = true;

        }
      }
    }
    else {
      this.inputCheckClick = false;
      // this.btn = (document.getElementById('btn001') as HTMLInputElement).disabled = true;
    }
  }

  constructor(
    private restService: ConnectionService,
    private behaviourSubjectService: BehaviorSubjectService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private commonService: CommonFunctionService,
    private dashboardservice: DashboardService,
    private apiService: ApiService
  ) {
    this.getTenantID();
  }

  ngOnInit(): void {
    this.AssignUserForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      accessFormControl: [1, [Validators.required]],
    })
    this.behaviourSubjectService.currentUserDetailsFromDB.subscribe(userData => {
      this.userDetailsFromDb = userData
      this.getJobList();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchValue) {
      this.isDivVisible = false;
      if (this.searchValue) {
        this.jobListData = [];
        //this.jobListData = this.commonService.searchDataWithoutDB(this.searchValue,'name',this.apiJobData);
        const tempData = this.apiJobData?.filter(item => item.workOrderNumber === this.searchValue);
        if (tempData.length > 0) {
          this.jobListData = tempData;
        }
        this.spinner.show();
        const restServiceBody = {
          "request": {
            "pageNumber": 1,
            "pageSize": 200
          },
          "searchCategoryID": 1,
          "searchValue": this.searchValue,
          "userId": this.userDetailsFromDb.userId,
          "securableObjectTypeId": 1
        }
        this.commonService.searchDataWithDb(this.searchValue, 'name', this.apiJobData, PortalAPI.SEARCH_JOBS, restServiceBody)
          .subscribe(response => {
            if (response && response.allJobs && response.totalRecord > 0) {
              response.allJobs.forEach((it: any) => {
                it['action'] = this.actionButton;
                it['checkBox'] = false;
              });
              this.jobListData = [...this.jobListData, ...response.allJobs];
              this.spinner.hide();
            }
            else {
              this.jobListData = [];
              this.isDivVisible = false;
              this.jobIdByJobDetail = "";
              this.spinner.hide();
            }
          }, err => {
            console.log("search job error: ", err.status);
            this.spinner.hide();
          });
      }
      else {
        if (this.userDetailsFromDb?.userId)
          this.getJobList();
      }
    }
  }

  //#region getTenantID method is used to get azure details from MsalService
  getTenantID() {
    const accountDetail = this.commonService.getAccountDetail()[0];
    if (accountDetail.tenantId) {
      this.tenantId = accountDetail.tenantId;
    }
    else {
      this.tenantId = '1';
    }
  }
  //#endregion


  //#region getJobList method is use to get all the job list through the GET_ALL_JOBS_BY_USERID api
  getJobList() {
    this.spinner.show();
    this.jobListData = [];
    this.restService.tenantId = this.tenantId;
    const restServiceBody = {
      "request": {
        "pageNumber": 1,
        "pageSize": 200
      },
      "userId": this.userDetailsFromDb.userId,
      "securableObjectTypeId": 1
    }
    this.restService.post(PortalAPI.GET_ALL_JOBS_BY_USERID, restServiceBody).subscribe(response => {
      if (response && response.allJobs && response.totalRecord > 0) {
        this.jobListData = response.allJobs.filter((it: any) => it.name || it.workOrderNumber);
        this.jobListData.forEach((it) => {
          it['action'] = this.actionButton;
          it['checkBox'] = false;
        });
        this.apiJobData = [...this.jobListData];
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, err => {
      this.spinner.hide();
      this.toastr.error("Jobs Error: " + err.status, "", { timeOut: 3000 });
    });
  }
  //#endregion

  //#region listClick method is use for click on the job list item
  listClick(listData: any) {
    this.checkedData = [];
    this.formDisable = false;
    // if (!this.inputCheckClick) {
    this.jobListData.forEach((ele: any) => {
      ele.checkBox = false;
    });
    // }
    this.isDivVisible = true;
    this.detailForm = listData;
    if (listData && listData.jobId > 0) {
      this.getAssignedUser(listData.jobId);
      this.jobIdByJobDetail = listData.jobId;
      listData.checkBox = true;
      this.checkedData.push({ data: listData });
      this.btn = (document.getElementById('btn001') as HTMLInputElement).disabled = false;
    }
    this.selectedJobDataEmitter.emit(listData);
  }
  //#endregion

  //#region getAssignedUser method is use to get all the Assigned user for particular job id through the GetAssignedUsers api
  getAssignedUser(jobId: number) {
    this.assignedUserList = [];
    this.spinner.show();
    this.restService.tenantId = '1';
    const restServiceBody = {
      "request": {
        "pageNumber": 1,
        "pageSize": 100
      },
      "jobId": jobId
    }
    this.restService.post(PortalAPI.Get_Assigned_Users, restServiceBody).subscribe(response => {
      if (response && response.assignedUsers && response.totalRecord > 0) {
        const actionButton = [{
          icon: 'fa-trash',
          tooltip: 'Edit User Information',
          dataBsToggle: 'modal',
          dataBsTarget: '#deleteAssignUser'
        }];
        response.assignedUsers.forEach((it: any) => {
          it["action"] = actionButton;
          it["displayNameIcon"] = 'fa-user';
        });
        this.assignedUserList = response.assignedUsers;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, err => {
      this.spinner.hide();
    })
  }
  //#endregion

  //#region getAssignedUser method is use to get all the Assigned user for particular job id through the GetAssignedUsers api
  saveJob() {
    this.restService.tenantId = '1';
    const restServiceBody = {
      "jobId": 0,
      "jobName": "string",
      "workorderNumber": "string",
      "customerName": "string",
      "siteName": "string",
      "siteContact": "string",
      "email": "string",
      "phone": "string",
      "jobDescription": "string"
    }
    this.restService.post(PortalAPI.SAVE_JOB, restServiceBody).subscribe(response => {
      if (response && response.processingStatus && response.processingStatus.status === "Success") {
        console.log("assigned user: ", response);
      }
    })
  }
  //#endregion

  //#region onSubmitDetailForm method is use for submit detail form data or update the data
  onSubmitDetailForm() {
    // R Start
    console.log('detailForm', this.detailForm);
    this.spinner.show();
    this.restService.tenantId = '1';
    const restServiceBody = {
      "jobId": this.detailForm.jobId,
      "jobName": this.detailForm.name,
      "workorderNumber": this.detailForm.workOrderNumber,
      "customerName": this.detailForm.customerName,
      "siteName": this.detailForm.siteName,
      "siteContact": this.detailForm.jobContact,
      "email": this.detailForm.email,
      // "phone": '9898989898',
      "phone": String(this.detailForm.phone),
      "jobDescription": this.detailForm.description,
      "cadRequired": this.detailForm.CAD,
      "isSiteMapRestricted": true,
      "userId": this.userDetailsFromDb.userId,
      "geom" : '',

    }
    this.restService.post(PortalAPI.UPDATE_JOB, restServiceBody).subscribe(response => {
      if (response && response?.processingStatus?.status.includes("Success")) {
        this.isDivVisible = false;
        this.uncheckOnSaveAndCancel();
        this.toastr.success("Job details updated successfully", "", {
          timeOut: 2000
        });
        this.spinner.hide();
      }
      else {
        const message = response?.processingStatus?.status ? "Error to update job details!" : response?.processingStatus?.status;
        this.toastr.error(message, "", {
          timeOut: 2000
        });
        this.spinner.hide();
      }
    })
    // R End
  }
  //#endregion

  btnCancelDetails() {
    this.isDivVisible = false;
    this.uncheckOnSaveAndCancel();
  }

  //#region uncheckOnSaveAndCancel method is used to unchecked selected job from the name of job list
  uncheckOnSaveAndCancel() {
    if(this.jobListData.filter((it:any)=> it.jobId === this.checkedData[0].data.jobId).length > 0) {
      this.jobListData.filter((it:any)=> it.jobId === this.checkedData[0].data.jobId)[0].checkBox = false;
    }
    this.checkedData = [];
    this.btn = (document.getElementById('btn001') as HTMLInputElement).disabled = true;
  }
  //#endregion

  //table button click
  //Remove User Edit/View permission for Jobs
  tblBtnClick(event: any) {

    let payLoad = {
      "userObjectPermissionEntity": {
        "userId": event.data.userId,
        "securableObjectTypeId": 1,
        "securableObjectId": this.jobIdByJobDetail,
        "securableObjectPermissionTypeId": event.data.objectPermisionTypeId,
      }

    }

    this.restService.RemoveAssignUserForJob(payLoad).subscribe((response) => {
      this.sharedObjResponse = response;
      if (this.sharedObjResponse.processingStatus.status === "Failure") {
        this.toastr.error("Unable to remove User");
        this.spinner.hide();
      } else {
        this.toastr.success("User is unassigned to a job");
        this.getAssignedUser(this.jobIdByJobDetail);
      }
    }, err => {
      this.spinner.hide();
    });

  }
  //#Assign User to Job by Email address with read/edit access.
  AssignUserToJob() {
    this.spinner.show();
    this.tierIDData = [];
    this.dashboardservice.fetchEditEmails(this.AssignUserForm.value.email).subscribe((data) => {
      console.log('888888', data) 
      this.spinner.hide();
       this.tierIDData = data;
    let userTierID = "";
    let loginUserRole = "";
    if(this.tierIDData.length > 0){
      console.log(this.AssignUserForm.value.accessFormControl, this.tierIDData[0].tierId)
      userTierID = this.tierIDData[0].tierId;
      loginUserRole = this.tierIDData[0].roleName;
    }
    if(this.tierIDData.length === 0 && this.AssignUserForm.value.accessFormControl =='2'){
      userTierID = "";
      this.toastr.error("User Id entered does not have edit access. Only view access can be shared");
      return;
    }
    let tierId = sessionStorage.getItem('tierId');
    if(loginUserRole !="Project Manager" && loginUserRole!="Admin" ){
      
      if(tierId =='2' && this.AssignUserForm.value.accessFormControl =='2' && userTierID == '1'){
        this.toastr.error("Free account User does not have access to edit the content");
        return;
        }
        if(tierId =='1' && this.AssignUserForm.value.accessFormControl =='2' && userTierID == '2'){
          this.toastr.error("Free account User does not have access to edit the content");
          return;
        }
        if(tierId =='1' && this.AssignUserForm.value.accessFormControl =='2' && userTierID == '1'){
          this.toastr.error("Free account User does not have access to edit the content");
          return;
        }
    }

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(this.AssignUserForm.value.email)) {
      this.spinner.show();
      let payLoad =
      {
        "assignToEmailId": this.AssignUserForm.value.email,
        "assignByUserId": this.userDetailsFromDb.userId,
        "objectId": this.jobIdByJobDetail,
        "objectTypeId": 1,
        "securableObjectPermissionTypeId": this.AssignUserForm.value.accessFormControl
      }

      this.restService.AssignJobToUserByAdmin(payLoad).subscribe((response) => {
        this.sharedObjResponse = response;
        this.AssignUserForm.value.email = '';
        if (this.sharedObjResponse.processingStatus.status === "Failure") {
          this.spinner.hide();
          this.toastr.error("The job has already been assigned with same access with User");
        } else {
          this.toastr.success("Job is assigned successfully to the user");
          this.getAssignedUser(this.jobIdByJobDetail);
        }
      }, err => {
        this.spinner.hide();
      });

    } else {
      this.toastr.error("Please enter a valid Email Address.");
    }

  })
  }

  btnAssignUserClick() {
    this.AssignUserForm.get('email').reset();
  }

  //#region onCheckBoxClick method is used to click on any checkBox in the table then function will occure
  onCheckBoxClick(event: any) {
    if (this.checkedData.length > 0) {
      const index = this.checkedData.findIndex(
        (item) => item.data.jobId === event.data.jobId
      );
      if (index != -1) {
        if (event.data.checkBox === false || index > -1) {
          this.checkedData.splice(index, 1);
        }
        else {
          this.checkedData.push(event);
        }
      }
      else {
        this.checkedData.push(event);
      }
    }
    else {
      this.checkedData.push(event);
    }

    if (this.checkedData.length > 1) {
      this.formDisable = true;
      this.isDivVisible = false;
      this.btn = (document.getElementById('btn001') as HTMLInputElement).disabled = true;
    }
    else {
      this.btn = (document.getElementById('btn001') as HTMLInputElement).disabled = false;
      if (this.checkedData.length === 0) {
        this.isDivVisible = false;
        this.btn = (document.getElementById('btn001') as HTMLInputElement).disabled = true;
      }
      else {
        this.isDivVisible = true;
        this.formDisable = false;
        this.detailForm = this.checkedData[0]?.data;
        if (this.checkedData[0]?.data && this.checkedData[0]?.data?.jobId > 0) {
          this.getAssignedUser(this.checkedData[0].data.jobId);
          this.jobIdByJobDetail = this.detailForm.jobId;
          this.checkedData[0].data.checkBox = true;
          this.selectedJobDataEmitter.emit(this.checkedData[0]?.data);
        }
      }
    }
  }
  //#endregion

}
