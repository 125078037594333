<!-- Slide Tools Starts -->
<div
  class="side-main-menu tools right"
  [ngClass]="{
    open:
      slideMenuOpenTools &&
      !slideMenuOpenSiteDetails &&
      !slideMenuOpenSiteAdminDetails &&
      !slideMenuOpenLayerDetails &&
      !slideMenuOpenFolder &&
      !slideMenuOpenJobDescription,
    'end-0':
      slideMenuOpenSiteAdminDetails ||
      slideMenuOpenSiteDetails ||
      slideMenuOpenLayerDetails ||
      slideMenuOpenJobDescription ||
      slideMenuOpenFolder ||
      slideMenuOpenTools
  }"
>
  <div
    class="menu-first"
    (click)="ontoggle(false,false, false, !slideMenuOpenTools, false, false)"
    title="Tools"
  >
    <!-- <span class="fa fa-angle-left"></span> -->
    <img
      class="right-toggle"
      src="../../../../../assets/images/ico_tools.svg"
      alt="Tools"
    />
  </div>
  <div class="flex-shrink-0 p-0 bg-white sub-page-menu">
    <div class="sub-page-menu-header">
      <div class="role">
        <h2>TOOLS</h2>
      </div>
    </div>
    <div class="sub-page-menu-body" id="divTools">
      <ul class="list-unstyled tools ps-0">
        <li class="mb-1">
          <a href="javascript:void(0)" (click)="addActiveClass($event);handleSelect()"
            ><img
              src="../../assets/images/ico_selection.svg"
              alt="Selection"
            />Selection</a
          >
        </li>
        <!-- <li class="mb-1">
          <a (click)="measureLength()" style="cursor: pointer" id="measureLengthId"
            ><img
              src="../../assets/images/ico_measure_length.svg"
              alt="Measure Length"
            />Measure Length</a
          >
        </li>
        <li class="mb-1">
          <a (click)="measureArea()" style="cursor: pointer" id="measureAreaId"
            ><img
              src="../../assets/images/ico_measure_area.svg"
              alt="Measure Area"
            />Measure Area</a
          >
        </li> -->
        <!-- <li class="mb-1">
          <a href="javascript:void(0)"
            ><img
              src="../../assets/images/ico_create_site.svg"
              alt="Create Site"
            />Create Site</a
          >
        </li> -->
        <div class="sub-page-menu-header">
          <div class="role">
            <h2>Editing Tools</h2>
          </div>
        </div>
        <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="addActiveClass($event);handleMoveVertex()"
            [ngClass]="{disabled: !isEditingModeIsOn, active: isEditingModeIsOn && this.editingState.editingModes.isMovingVertex}"
          >
            <img
              src="../../assets/images/ico_move_vertex.svg"
              alt="Move Vertex"
            />Move Vertex</a
          >
        </li>
        <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="addActiveClass($event);handleAddVertex()"
            [ngClass]="{disabled: !isEditingModeIsOn, active: isEditingModeIsOn && this.editingState.editingModes.isAddingVertex}"
          >
            <img
              src="../../assets/images/ico_add_vertex.svg"
              alt="Add Vertex"
            />Add Vertex</a
          >
        </li>
        <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="addActiveClass($event);handleDeleteVertex()"
            [ngClass]="{disabled: !isEditingModeIsOn, active: isEditingModeIsOn && this.editingState.editingModes.isDeletingVertex}"
          >
            <img
              src="../../assets/images/ico_delete_vertex.svg"
              alt="Delete Vertex"
            />Delete Vertex</a
          >
        </li>
        <!-- <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="handleUpdatePoint()"
            [ngClass]="!isEditingModeIsOn ? 'disabled' : ''"
          >
            <img
              src="../../assets/images/ico_edit_vertex.svg"
              alt="Edit Vertex"
            />Edit Point</a
          >
        </li> -->
        <!-- <li class="mb-1">
          <a href="javascript:void(0)" (click)="handleDeletePoint()">
            <img src="../../assets/images/ico_delete_vertex.svg" alt="Delete Vertex" />Delete
            Point</a>
        </li> -->
        <!-- <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="handleAddLine()"
            [ngClass]="!isEditingModeIsOn ? 'disabled' : ''"
          >
            <img
              src="../../assets/images/ico_add_vertex.svg"
              alt="Add Vertex"
            />Add Line</a
          >
        </li> -->

        <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="addActiveClass($event);handleEndLine()"
            [ngClass]="!isEditingModeIsOn ? 'disabled' : ''"

          >
            <img src="../../assets/images/ico_end_line.svg" alt="End Line" />End
            Line</a
          >
        </li>
        <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="addActiveClass($event);handleMergeLine()"
            [ngClass]="{disabled: !isEditingModeIsOn, active: isEditingModeIsOn && this.editingState.editingModes.isMergingLine}"
          >
            <img
              src="../../assets/images/ico_merge_line.svg"
              alt="Merge Line"
            />Merge Line</a
          >
        </li>
        <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="addActiveClass($event);handleSnappingToVertex($event)"
            [ngClass]="{disabled: !isEditingModeIsOn, active: isEditingModeIsOn && isSnappingToVertex}"
          >
            <img
              src="../../assets/images/ico_snap_to_vertex.svg"
              alt="Snap To Vertex"
            />Snap To Vertex</a
          >
        </li>
        <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="addActiveClass($event);handleSnappingToLine()"
            [ngClass]="{disabled: !isEditingModeIsOn, active: isEditingModeIsOn && isSnappingToLine}"
          >
            <img src="../../assets/images/ico_snap_to_line.svg" alt="Snap To Line" />Snap
            To Line</a
          >
        </li>
        <!-- <li class="mb-1" (click)="handleDeleteLine()">
          <a href="javascript:void(0)"><img src="../../assets/images/ico_delete_vertex.svg" alt="Delete Vertex" />Delete
            Line</a>
        </li> -->
        <!-- <li class="mb-1">
          <a href="javascript:void(0)" [ngClass]="!isEditingModeIsOn ? 'disabled' : ''"
            ><img
              src="../../assets/images/ico_freehand_polygon.svg"
              alt="Freehand Polyline"
            />Freehand Polyline</a
          >
        </li> -->
        <!-- <li class="mb-1">
          <a href="javascript:void(0)" (click)="handleUndo()" [ngClass]="!isEditingModeIsOn ? 'disabled' : ''"
            ><img
              src="../../assets/images/ico_draw_circle.svg"
              alt="Draw Circle"
            />Undo</a
          >
        </li> -->
        <!-- <li class="mb-1">
          <a href="javascript:void(0)" (click)="handleRedo()" [ngClass]="!isEditingModeIsOn ? 'disabled' : ''"
            ><img
              src="../../assets/images/ico_add_vertex.svg"
              alt="Split Line"
            />Redo</a
          >
        </li> -->
      </ul>
    </div>
  </div>
</div>
<!-- Slide Tools Ends -->
