import * as turf from '@turf/turf';

// Layer Ids
const siteMapDrawPointLayerIds = ['sitemap-points-symbol-inactive.hot', 'sitemap-points-symbol-inactive.cold', 'sitemap-points-symbol-active.hot', 'sitemap-points-symbol-active.cold'];
const siteMapDrawPointHighlightLayerIds = ['sitemap-points-highlight-inactive.hot', 'sitemap-points-highlight-inactive.cold', 'sitemap-points-highlight-active.hot', 'sitemap-points-highlight-active.cold'];
const siteMapDrawLineLayerIds = ['sitemap-lines-inactive.hot', 'sitemap-lines-inactive.cold', 'sitemap-lines-active.hot', 'sitemap-lines-active.cold'];
const siteMapDrawLineHighlightLayerIds = ['sitemap-lines-highlight-inactive.hot', 'sitemap-lines-highlight-inactive.cold', 'sitemap-lines-highlight-active.hot', 'sitemap-lines-highlight-active.cold'];
const siteMapDrawVertexLayerIds = ['sitemap-vertexes-active.hot', 'sitemap-vertexes-active.cold', 'sitemap-vertexes-inactive.hot', 'sitemap-vertexes-inactive.cold'];
const siteMapDrawMidpointLayerIds = ['sitemap-midpoints.hot', 'sitemap-midpoints.cold'];
const snapPointLayerIds = ['snap-point-inactive.cold', 'snap-point-active.cold', 'snap-point-inactive.hot', 'snap-point-active.hot'];
// Special temporary features for editing
// A Point feature to show snap location on map
export const snapFeatureLayerId =  'snap-feature';
const snapPointFeature = {
  id: snapFeatureLayerId,
  type: 'Feature',
  properties: {
    isSnapFeature: true
  },
  geometry: {
    type: 'Point',
    coordinates: []
  }
};

// Helper functions for editing
const snapTolerancePixels = 20;
// The snap tolerance equilvalent in miles (used with TurJS distance calculations)
const snapToleranceMiles = function(latitude, zoomLevel) {
  // console.log('Running snapToleranceMiles', latitude, zoomLevel)
  let milesPerPixel = (Math.cos(latitude * Math.PI / 180) * 2 * Math.PI * 6378137) / (512 * Math.pow(2, zoomLevel)) * 0.000621371;
  return milesPerPixel * snapTolerancePixels;
};

const fixMicroServicesGeometry = (geoJson) => {
  if (geoJson.geometry.type === 'LineString') {
    let coordinates = geoJson.geometry.coordinates;
    if (typeof(coordinates) === 'string') {
      geoJson.geometry.coordinates = JSON.parse(coordinates);
    }
  }
  return geoJson;
}

// Add the snapping feature to the map
const showSnappingFeature = function(geographicalCoordinates, movingFeature, selectedFeatureId, drawMode) {
  // Convert geographical space to pixel space
  let pixelCoordinates = this.map.project(movingFeature.geometry.coordinates);
  // Create a bounding box around subject point +/- snapping tolerance
  let bbox = [[pixelCoordinates.x - snapTolerancePixels, pixelCoordinates.y + snapTolerancePixels], [pixelCoordinates.x + snapTolerancePixels, pixelCoordinates.y - snapTolerancePixels]];

  let nearbyPointCoords = [];
  if (this.isSnappingToVertex) {
    // Find all nearby Point features and coords from MVT and SiteMap Draw - filter the subject feature's id so we don't select it
    // From these features we can extract vertices for snapping
    let nearbyPointFeatures = this.map.queryRenderedFeatures(
      bbox,
      { layers: [...this.siteMapMVTLayerIds.siteMapMVTPointLayerIds, ...siteMapDrawPointLayerIds],
        filter: ['all',
          ['!=', ['get', 'id'], movingFeature.id],
          ['!=', ['get', 'id'], selectedFeatureId]
    ]});
    nearbyPointCoords = nearbyPointFeatures.map((point) => ({ coords: point.geometry.coordinates, rank: 1 }));
  }

  // Find all nearby Line feature vertices/coords from MVT and SiteMap Draw - filter the subject feature's id so we don't select it
  // From these features we can extract vertices and/or nearest point on line for snapping
  let nearbySiteMapMVTLineFeaturesQueried = this.map.queryRenderedFeatures(
    bbox,
    { layers: [...this.siteMapMVTLayerIds.siteMapMVTLineLayerIds],
      filter: ['all',
        ['!=', ['get', 'id'], movingFeature.id],
        ['!=', ['get', 'id'], selectedFeatureId]
  ]});
  let nearbySiteMapMVTLineFeatureIds = [];
  let nearbySiteMapMVTLineFeaturesGeoJson = [];
  let nearbySiteMapDrawLineFeaturesQueried = this.map.queryRenderedFeatures(
    bbox,
    { layers: [...siteMapDrawLineLayerIds],
      filter: ['all',
        ['!=', ['get', 'id'], movingFeature.id],
        ['!=', ['get', 'id'], selectedFeatureId]
  ]});
  let nearbySiteMapDrawLineFeatureIds = [];
  let nearbySiteMapDrawLineFeaturesGeoJson = [];

  // Don't use line snapping when in "Add Vertex" mode and mouse over the selected Line
  // The problem - this clicks on the snapping feature instead of the line and results in an
  // extension of the line from nearest start/end point, rather than adding vertex to line itself
  // But this still allows line snapping to other line features, which could be desirable.
  // if (drawMode === 'add_vertex') {
  //   nearbySiteMapMVTLineFeaturesQueried = nearbySiteMapMVTLineFeaturesQueried.filter(feature => feature.id !== selectedFeatureId && feature.properties.id !== selectedFeatureId);
  //   nearbySiteMapDrawLineFeaturesQueried = nearbySiteMapDrawLineFeaturesQueried.filter(feature => feature.id !== selectedFeatureId && feature.properties.id !== selectedFeatureId);
  // }

  // We'll keep all vertices found nearby in an Array
  let nearbyVertexCoords = [];
  let nearbyPointsOnLines = [];
  // Get MVT Ids of Lines and then find coords in GeoJson
  // We need to get coords from GeoJSON because the coords returned from queryRenderedFeatures is inaccurate
  nearbySiteMapMVTLineFeaturesQueried.forEach((layer) => {
    if (nearbySiteMapMVTLineFeatureIds.indexOf(layer.properties.feature) === -1) {
      nearbySiteMapMVTLineFeatureIds.push(layer.properties.feature);
      let geom = JSON.parse(layer.properties.geometry)
      if (this.isSnappingToLine) {
        nearbySiteMapMVTLineFeaturesGeoJson.push(geom);
      }
      if (this.isSnappingToVertex) {
        geom.coordinates.forEach((coord) => {
          nearbyVertexCoords.push({ coords: coord, rank: 1 });
        });
      }
    }
  });
  // // Get the coords for MVT features from GeoJson
  // this.navigatedFeatures.filter((layer) => layer.layerId === parseInt(this.mapLayerId)).forEach((feature) => {
  //   feature.features.filter((feature) => nearbySiteMapMVTLineFeatureIds.indexOf(feature.feature) !== -1).forEach((element) => {
  //   let coordinates;
  //   let geoJson = fixMicroServicesGeometry(element);
  //   if (geoJson.geometry.type === 'LineString') {
  //     if (this.isSnappingToLine) {
  //       nearbySiteMapMVTLineFeaturesGeoJson.push(geoJson);
  //     }
  //     if (this.isSnappingToVertex) {
  //       geoJson.geometry.coordinates.forEach((coord) => {
  //         nearbyVertexCoords.push({ coords: coord, rank: 1 });
  //       });
  //     }
  //   }
  // })
  // })

  // Get the feature Draw Ids
  // We need to get coords from Draw directly because the coords returned from queryRenderedFeatures is inaccurate
  nearbySiteMapDrawLineFeaturesQueried.forEach((layer) => {
    if (nearbySiteMapDrawLineFeatureIds.indexOf(layer.properties.id) === -1) {
      nearbySiteMapDrawLineFeatureIds.push(layer.properties.id);
    }
  });
  // Then grab coords from Draw geometry
  nearbySiteMapDrawLineFeatureIds.forEach((featureId) => {
    let drawLineFeature = this._ctx.api.get(featureId);
    if (drawLineFeature !== undefined) {
      if (this.isSnappingToLine) {
        nearbySiteMapDrawLineFeaturesGeoJson.push(drawLineFeature.geometry);
      }
      if (this.isSnappingToVertex) {
        turf.coordEach(drawLineFeature, function (currentCoord, coordIndex, featureIndex, multiFeatureIndex, geometryIndex) {
          nearbyVertexCoords.push({ coords: currentCoord, rank: 1 });
        });
      }
    }
  });

  // When in "Delete Vertex" mode, disable snapping to line even if it is enabled by user
  if (this.isSnappingToLine && drawMode !== 'delete_vertex') {
    // let nearbyPointsOnLines = [];
    let mousePoint = turf.point(movingFeature.geometry.coordinates);
    // nearbySiteMapMVTLineFeaturesGeoJson = nearbySiteMapMVTLineFeaturesGeoJson.map((feature) => fixMicroServicesGeometry(feature));
    [...nearbySiteMapMVTLineFeaturesGeoJson, ...nearbySiteMapDrawLineFeaturesGeoJson].forEach((line) => {
      let nearestPointOnLine = turf.nearestPointOnLine(turf.lineString(line.coordinates), mousePoint, { units: 'miles' });
      // nearbyPointsOnLines.push(nearestPointOnLine);
      nearbyPointsOnLines.push({ coords: nearestPointOnLine.geometry.coordinates, rank: 2 });
    });
  }

  let allCoords = [...nearbyPointCoords, ...nearbyVertexCoords, ...nearbyPointsOnLines];
  // console.log('nearbyPoint', nearbyPointFeatures, nearbyPointCoords);
  // console.log('nearbySiteMapMVTLine', nearbySiteMapMVTLineFeaturesQueried, nearbyVertexCoords);
  // console.log('nearbySiteMapDrawLine', nearbySiteMapDrawLineFeaturesQueried, []);
  // // console.log('nearbyMultiLineFeatures', nearbyMultiLineFeatures);
  // // console.log('nearbyPointsOnLines', nearbyPointsOnLines);
  // console.log('allCoords', allCoords);

  // If we have at least one coord, we might be able to snap
  if (allCoords.length > 0) {
    // Find the distance from the "subject" point to each candidate "object" point
    let allCoordsDistances = allCoords.map(coords => {
      let from = turf.point(movingFeature.geometry.coordinates);
      let to = turf.point(coords.coords);
      let options = { units: 'miles' };
      let distance = turf.distance(from, to, options);
      return { coords: coords.coords, distance, rank: coords.rank }
    });

    // Calculate the max distance in miles allowed for a snap - based on latitude, zoom and snapping tolerance
    let maxDistance = snapToleranceMiles(movingFeature.geometry.coordinates[1], this.map.getZoom());

    // Filter any "object" points too far awat, and sort by distance ascending
    let allCoordsDistancesBelowMax = allCoordsDistances.filter(coord => coord.distance < maxDistance).sort((a, b) => {
      if (a.distance === b.distance) {
        return a.rank < b.rank ? -1 : 1
      } else {
        return a.distance < b.distance ? -1 : 1
      }
    });

    // If there is at least one candidate "object" point, we have a snapping point
    if (allCoordsDistancesBelowMax.length > 0) {

      // The nearest is the first in the array
      let nearestFeature = allCoordsDistancesBelowMax[0];
      let rank = nearestFeature.rank;
      let nearestPoint = nearestFeature.coords;
      // Set the coordinate for the snapping point feature
      if (this.getFeature(snapFeatureLayerId) === undefined || snapPointFeature.geometry.coordinates !== nearestPoint) {
        snapPointFeature.geometry.coordinates = nearestPoint;
        let circleColor;
        if (rank === 1) {
          circleColor = 'red';
        } else {
          circleColor = 'green';
        }

        // and add it to the map
        this.addFeature(this.newFeature(snapPointFeature));
        snapPointLayerIds.forEach((layerId) => {
          this.map.setPaintProperty(layerId, 'circle-color', circleColor);
          this.map.moveLayer(layerId)
        });
        }
    } else {
      this.deleteFeature(snapFeatureLayerId);
    }
  }
}

const findNearbyVertices = function(geographicalCoordinates, movingFeature) {
  // Convert geographical space to pixel space
  let pixelCoordinates = this.map.project(geographicalCoordinates)
  // Create a bounding box around subject point +/- snapping tolerance
  let bbox = [[pixelCoordinates.x - this.editingState.snapTolerancePixels, pixelCoordinates.y + this.editingState.snapTolerancePixels], [pixelCoordinates.x + this.editingState.snapTolerancePixels, pixelCoordinates.y - this.editingState.snapTolerancePixels]];
  // Find all nearby point features/coords from MVT and SiteMap Draw - filter the subject feature's id so we don't select it
  let nearbyVertexFeatures = this.map.queryRenderedFeatures(bbox, { layers: [...siteMapDrawVertexLayerIds], filter: ['!=', ['get', 'id'], movingFeature.id] });
  let nearbyVertexCoords = nearbyVertexFeatures.map((point) => ({ coords: point.geometry.coordinates, rank: 1 }));

  // TODO:

}

export const getSiteMapMVTLayerIdsById = function(map, mapLayerId) {
  let siteMapMVTLineLayerIds = map.getStyle().layers.filter(layer => layer.id.startsWith(`${mapLayerId}-`) && layer.id.endsWith('line')).map(layer => layer.id);
  let siteMapMVTPointLayerIds = map.getStyle().layers.filter(layer => layer.id.startsWith(`${mapLayerId}-`) && layer.id.endsWith('point')).map(layer => layer.id);
  return {
    siteMapMVTLineLayerIds,
    siteMapMVTPointLayerIds
  };
}


export default showSnappingFeature;
