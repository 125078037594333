<form
    class="row g-3"
    name="form"
    #f="ngForm"
    id="userForm"
    (ngSubmit)="f.form.valid && onSubmit()"
    novalidate
  >
    <div class="col-md-6">
      <label for="txtName" class="form-label">Name<span class="custom-form-label-star">*</span></label>
      <input
        type="text"
        class="form-control"
        name="txtName"
        [(ngModel)]="informationModalData.displayName"
        [ngModelOptions]="{ standalone: true }"
        required
        #txtName="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && txtName.errors }"
        id="txtName"
      />
      <div *ngIf="f.submitted && txtName.errors" class="invalid-feedback">
        <div *ngIf="txtName.errors['required']">Name is required</div>
      </div>
    </div>
    <div class="col-md-6">
      <label for="txtEmail" class="form-label">Email Address<span class="custom-form-label-star">*</span></label>
      <input
        type="email"
        class="form-control"
        name="email"
        [(ngModel)]="informationModalData.email"
        [ngModelOptions]="{ standalone: true }"
        email
        required
        #email="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && email.errors }"
        id="txtEmail"
      />
      <div *ngIf="f.submitted && email.errors" class="invalid-feedback">
        <div *ngIf="email.errors['required']">Email Address is required</div>
      </div>
      <div *ngIf="f.submitted && email.errors" class="invalid-feedback">
        <div *ngIf="email.errors['email']">Email Address is invalid</div>
      </div>
    </div>
    <div class="col-6">
      <label for="txtPhone" class="form-label">Contact Number</label>
      <input
        type="text"
        class="form-control"
        id="txtPhone"
        placeholder="1234"
        [(ngModel)]="informationModalData.phoneNumber"
        [ngModelOptions]="{ standalone: true }"
      />
    </div>
    <div class="col-6">
      <label for="dropDownRole" class="form-label">Role<span class="custom-form-label-star">*</span> </label>
      <select
        class="form-select"
        name="dropDownRole"
        [(ngModel)]="selectedRole"
        [ngModelOptions]="{ standalone: true }"
        (change)="onRoleSelected(dropDownRole.value)"
        required
        #dropDownRole="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && dropDownRole.errors }"
        id="dropDownRole"
      >
        <ng-container *ngFor="let options of dropDownRoles">
          <option [value]="options.roleId">{{ options.name }}</option>
        </ng-container>
      </select>
      <div *ngIf="f.submitted && dropDownRole.errors" class="invalid-feedback">
        <div *ngIf="dropDownRole.errors['required']">Role is required</div>
      </div>
    </div>
    <div class="col-6">
      <label for="dropDownTier" class="form-label">Subscription Tier</label>
      <select
        #dropDownTier
        name="dropDownTier"
        class="form-select"
        id="dropDownTier"
        [(ngModel)]="subTier"
        [ngModelOptions]="{ standalone: true }"
        (change)="onTierSelected(dropDownTier.value)"
        id="dropDownTier"
      >
        <ng-container *ngFor="let options of dropDownTiers">
          <option [value]="options.value">{{ options.name }}</option>
        </ng-container>
      </select>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="onCancelUserClick()">
        Cancel
      </button>
      <button type="submit" class="btn btn-success">{{submitBtnName}}</button>
    </div>
  </form>