import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ICommonTableHeader, IDropDown } from '../../../constants/common-table-data';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CommonFunctionService } from 'src/app/services/common-function-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConnectionService } from 'src/app/modules/sitemapadmin/services/connection-service';
import { ToastrService } from 'ngx-toastr';
import { PortalAPI } from 'src/app/constants/api.constant';
import { fileListTableData } from 'src/app/modules/digitalplanroom/constant/file-list-data';
import { BehaviorSubjectService } from 'src/app/services/behaviorsubject.service';
import { CredentialessLogin } from 'src/app/common-component/credentialess-login/credentialess-login';

@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.css']
})
export class CommonTableComponent implements OnInit {
  @Input() tableHeader: ICommonTableHeader[] = [];
  @Input() tableData: any[] = [];
  @Input() isPagination = false;
  @Input() dropDownVal: IDropDown[] = [];
  @Input() tblClass = '';
  @Input() idColumnName:string = 'id';
  @Input() tableDataApiLoaded: boolean = true;
  tenantId: string = "";
  @Output() dropDownChange = new EventEmitter();
  @Output() buttonClickEmitter = new EventEmitter();
  @Output() linkClickEmitter = new EventEmitter();
  @Output() checkBoxEmitter = new EventEmitter();
  @Output() tblBodyRowEmitter = new EventEmitter();
  @Output() singleObjectDeleteListEvent = new EventEmitter<string>();
  @Input() delegateData = '';
  userRole: string | null;
  isEnable: boolean = false;
  folderClickedData: any = null;
  userId : any;
  userTierId: any;
  isDPRContentOwner:boolean=false;
  credentialessLogin: CredentialessLogin | null = null;

  @ViewChild('viewGeoLocationRef') viewGeoLocationRef?:ElementRef;
  @ViewChild('editGeoLocationRef') editGeoLocationRef?:ElementRef;

  pager: any = {};
  tableDataWithPage: any[] = [];
  isAscendic: boolean = true;

  constructor(
    private spinner: NgxSpinnerService,
    private connectionService: ConnectionService,
    private commonService: CommonFunctionService,
    private toastr: ToastrService,
    private restService: ConnectionService,
    private behaviourSubjectService: BehaviorSubjectService
  ) {
    this.behaviourSubjectService.credentialessLoginObservable.subscribe((credentialessLogin:CredentialessLogin) => {
      this.credentialessLogin = credentialessLogin;
      this.getTenantID();
      this.userRole = sessionStorage.getItem("loginUserRole");
      this.userId = sessionStorage.getItem("loginUserId");
    });
    this.getTenantID();
    this.userRole = sessionStorage.getItem("loginUserRole");
    this.userId = sessionStorage.getItem("loginUserId");
    this.userTierId = sessionStorage.getItem("tierId");
  }

  ngOnInit() {
    if (this.isPagination) {
      this.pager = this.getPager(this.tableData.length, 1, 5);
      this.setPage(1);
    } else {
      this.tableDataWithPage = this.tableData;
    }
    
  }
  getTenantID() {
    if (this.credentialessLogin) {
      this.tenantId = '1';
    } else {
      const accountDetail = this.commonService.getAccountDetail()[0];
      if (accountDetail.tenantId) {
        this.tenantId = accountDetail.tenantId;
      }
      else {
        this.tenantId = '1';
      }
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.tableData) {
      this.tableDataWithPage = this.tableData;
    }
  }

  onOptionsSelected(value: any) {
    this.dropDownChange.emit(value);
  }

  onButtonClick(event: any, header: any, type?:any) {
    console.log(event, header)
    const value = { data: event, header: header, type: type };
    this.buttonClickEmitter.emit(value);
  }

  onLinkClick(event: any, header: any) {
    console.log(event, header)
    const value = { data: event, header: header };
    this.linkClickEmitter.emit(value);
  }

  onCheckboxClick(event:any, header:any) {
    const value = { data: event, header: header };
    this.checkBoxEmitter.emit(value);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 5) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);
    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 > totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  setPage(page: number) {
    this.pager = this.getPager(this.tableData.length, page, 5);
    this.tableDataWithPage = this.tableData.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
  }

  isArray(obj:any) {
    if (Array.isArray(obj)) return true;
    else return false;
  }

  tblBodyRowClick(data:any)
  {
    this.tblBodyRowEmitter.emit(data);
  }

  sort(columnName:string){
    this.tableData = this.tableData.sort((n1,n2) => {
      return n1[columnName] - n2[columnName];
    });
  }

  isDisable() {
    if (this.userRole?.toLowerCase().includes('admin') || this.userRole?.toLowerCase().includes('project manager')) {
      this.isEnable = false;
    }
    else {
      if (this.folderClickedData?.name?.includes('GPRS Deliverables') || !this.folderClickedData) {
        this.isEnable = true;
      }
      else {
        this.isEnable = false;
      }
    }
  }

  map!: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/satellite-streets-v11';
  centerCoordinatesForMap: any = [];
  currentEditMap : any = null;
  marker = new mapboxgl.Marker({ color: 'blue', rotation: 10, draggable: false });
  isEditDisabled : boolean = false;
  isMapLoading : boolean = true;

  objectTypeId : number = 0;

  //#region viewGeoLocationOpen method is use to get current Latitude/Longitude and open a modal
  viewGeoLocationOpen(latitude:number = 0, longitude:number = 0, objectTypeId : number = 0){
    latitude = (latitude != null && latitude.toString() != "") ? latitude : 0;
    longitude = (longitude != null && longitude.toString() != "") ? longitude : 0;
    this.centerCoordinatesForMap = [longitude, latitude];
    this.objectTypeId = objectTypeId;
    this.createMap("view","mapViewGeoLocation");
    this.viewGeoLocationRef?.nativeElement.click();
    this.isMapLoading = true;
    this.spinner.show();
  }
  //#endregion


  //#region editGeoLocationOpen method is use to get current Latitude/Longitude and open a modal or can edit Latitude/Longitude

  editGeoLocationOpen(data:any){
    this.currentEditMap = data;
    this.objectTypeId = data.objectTypeId;
    if (data && (data.latitude != null && data.latitude.toString() != '') && (data.longitude != null && data.longitude.toString() != '')) {
      this.centerCoordinatesForMap = [data.longitude, data.latitude];
      this.createMap("edit","mapEditGeoLocation");
    }else{
      this.centerCoordinatesForMap = [-103.5917, 40.6699];
      this.createMap("edit","mapEditGeoLocation");
    }
    this.editGeoLocationRef?.nativeElement.click();
    this.isMapLoading = true;
    this.spinner.show();
  }

  //#endregion


  //#region createMap method is used to create new mapboxgl

  createMap(mapType:string, mapId:string, mapZoom:number = 13) {
    /**
     * @desc Building map
     */
    (mapboxgl as any).accessToken = environment.mapbox.accessToken;
    this.map = new mapboxgl.Map({
        container: mapId,
        style: this.style,
        zoom: mapZoom,
        center: this.centerCoordinatesForMap as any,
        renderWorldCopies: false,
        preserveDrawingBuffer: true,
        interactive: true
    });

    this.map.addControl(new mapboxgl.NavigationControl(), 'top-left');

    const scale = new mapboxgl.ScaleControl({
      unit: 'imperial'
    });
    this.map.addControl(scale, 'bottom-left');
    scale.setUnit('imperial');

    const markerEl = document.createElement('div');
    const markerElwidth = 45;
    const markerElheight = 45;
    if(this.objectTypeId == 2){
      markerEl.style.backgroundImage = `url(../../../../../assets/images/temp_geo_file.svg)`;
    }else{
      markerEl.style.backgroundImage = `url(../../../../../assets/images/temp_geo_folder.svg)`;
    }
    markerEl.style.width = `${markerElwidth}px`;
    markerEl.style.height = `${markerElheight}px`;
    markerEl.style.backgroundSize = '100%';
    this.marker = new mapboxgl.Marker(markerEl);

    //this.marker = new mapboxgl.Marker({ color: 'blue', rotation: 10, draggable: false });

    this.marker
    .setLngLat(this.centerCoordinatesForMap)
    .addTo(this.map);

    if(mapType == "edit"){
      this.marker.setDraggable(true);
    }else{
      this.marker.setDraggable(false);
    }

    this.map.on('click', (e) => {
      if(mapType == "edit"){
        this.marker.remove();      
        this.marker.setLngLat([e.lngLat.lng, e.lngLat.lat])
        .addTo(this.map);
      }
    });

    var self = this;

    this.map.on('load',function(event){
      self.isMapLoading = false;
      self.spinner.hide();
      event.target.resize();
    })
    
  }
  //#endregion

  //#region getUpdatedData method is used to get updated data

  getUpdatedData(userId:number, folderId:number):Observable<any>{
    if(folderId == -1){
      return this.commonService.getAllDocumentsByUserId(userId);
    }else{
      return this.commonService.getFolderChildren(folderId, userId);
    }
  }

  //#endregion

  //#region addFileToGeolocate method is used to add new Geolocate for existing file/folder

  addFileToGeolocate(){
    if(!confirm('Are you sure you want to change the Geo location of the file/folder?')){
      return;      
    }
    this.editGeoLocationRef?.nativeElement.click();
    this.spinner.show();
    const lngLat = this.marker.getLngLat();
    const GeolocatePayload = {
      objectId: this.currentEditMap.id,
      objecTypetId: this.currentEditMap.objectTypeId,
      latitude: lngLat.lat.toString(),
      longitude: lngLat.lng.toString(),
      userId: this.userId
    }
    this.connectionService.post(PortalAPI.ADD_GEOLOCATE_FILE, GeolocatePayload).subscribe(response => {
      if (response?.processingStatus?.status?.includes('Success')) {
        const folderId = (this.tableDataWithPage.length > 0 && this.tableDataWithPage[0]) ? this.tableDataWithPage[0].parentFolderId : -1;
        this.getUpdatedData(this.userId, folderId).subscribe(response => {
          if (response && response.dprObjects && response.dprObjects.length > 0) {
            this.tableDataWithPage = response.dprObjects;
            this.tableDataWithPage.forEach((it) => {
              it['action'] = new fileListTableData().actionButton;
              if (it.objectTypeName === 'Folder') {
                it['nameIcon'] = 'fa-solid fa-folder';
              } else {
                it['nameIcon'] = 'fa-sharp fa-solid fa-file';
              }
            });
          }else if(response && response.length > 0){
            this.tableDataWithPage = response;
          }
        }, err => {
          this.toastr.error("FindDocumentsbyUserId error: " + err.status, "", { timeOut: 3000 });
        });
        this.toastr.success("Geolocation has been successfully updated.", "", { timeOut: 2000 });
      }else{
        this.toastr.error("Geolocation not updated.", "", { timeOut: 2000 });
      }
      this.spinner.hide();
    }, err => {
      this.toastr.error("Add Geolocation  error: " + err.status, "", { timeOut: 2000 });
      this.spinner.hide();
    });
    
  }

  //#endregion

  deleteSingleObject(data: any) {
    if (this.userRole == 'Client' && this.userTierId == 1) {
      this.toastr.error("You do not have access to delete the selected files or folders.");
      return;
    }else if (this.userRole == 'Client' && this.userTierId != 1 && !data.isAssigned) {
      this.toastr.error("You do not have access to delete the selected files or folders.");
      return;
    }  else if (this.userRole == 'Project Manager') {
      if (data.createdById == this.userId) {
        this.isDPRContentOwner = true;
      } else if (data.isAssigned == false) {
        this.toastr.error("You do not have access to delete the selected files or folders.");
        return;
      }
    }else{
      this.isDPRContentOwner = true;
    }

  if (this.isDPRContentOwner) {
    if (confirm('Are you sure you want to delete the selected file/folder?')) {
      this.spinner.show();
      this.connectionService.tenantId = this.tenantId;

      this.commonService.deleteObject(data, this.userId).subscribe((response: any) => {
        if (response?.processingStatus?.status?.includes('Success')) {

          this.singleObjectDeleteListEvent.emit(data);
          this.spinner.hide();

          this.toastr.success("Successfully deleted the selected file/folder");
          //this.getUpdatedData(this.userId,data.parentFolderId);
        }
        else {
          this.toastr.error("Could not delete the selected file/folder.");
          this.spinner.hide();
        }

      }, err => {
        this.toastr.error("DeleteObject error: " + err.status, "", { timeOut: 3000 });
        this.spinner.hide();
      });

    }
    else {
      return;
    }

  }
  else { this.toastr.error("Only Owner of this file/folder can delete it."); }
}

  delegateDataAssign(data:any){
    this.delegateData = data;
  }

}
