import { FileGroup } from "../feature-tree.component.types";

export default function getFeatureIndexFromFiles(files: FileGroup[], featureId: number) {
  let fileIndex = -1;
  let groupIndex = -1;
  let typeIndex = -1;
  let featureIndex = -1;

  files.some((file, fileIdx) => {
    fileIndex = fileIdx;
    return file.groups.some((group, groupIdx) => {
      groupIndex = groupIdx;
      return group.types.some((type, typeIdx) => {
        typeIndex = typeIdx;
        return type.features.some((feature, featureIdx) => {
          featureIndex = featureIdx;
          return parseInt(feature.featureId as string) === featureId;
        });
      });
    });
  });

  return {
    fileIndex,
    groupIndex,
    typeIndex,
    featureIndex
  };
}
