<!-- <nav
  class="navbar navbar-expand-md mb-4 navbar-dark custom-navbar"
  *ngIf="loginDisplay ? false : false"
  style="background-color: #3da53f"
>
  <div class="container-fluid">
    <a class="navbar-brand custom-navbar-brand" href="javascript:void(0)"
      ><img src="../assets/images/logo.svg" alt="SiteMap"
    /></a>
    <div>
      <button
        class="btn btn-sm custom-login-button me-3"
        type="button"
        *ngIf="!loginDisplay"
        (click)="login()"
      >
        Login
      </button>
      <button
        class="btn btn-sm custom-login-button"
        type="button"
        *ngIf="!loginDisplay"
        (click)="login('gprs')"
      >
        GPRS Login
      </button>
    </div>
  </div>
</nav> -->
<nav
  *ngIf="loginDisplay || isCredentialessLogin"
  class="navbar navbar-expand-md mb-4 navbar-dark custom-navbar p-0"
  style="
    background-color: var(--primaryColor);
    box-shadow: 0px 5px 3px #00000080;
    min-height: 66px;
  "
  id="header-print"
>
  <div class="container-fluid">
    <a
      class="navbar-brand custom-navbar-brand cursor-pointer"
      (click)="logoClick()"
      ><img src="../assets/images/logo.svg" alt="SiteMap"
    /></a>

    <div class="nav-menu-left">
      <ul class="navbar-nav mb-md-0 custom-navbar">
        <li class="nav-item">
          <a *ngIf="!isCredentialessLogin"
            class="nav-link custom-nav-link custom-tooltip"
            data-tooltip="Dashboard"
            aria-current="page"
            routerLink="/dashboards"
            routerLinkActive="active"
            (click)="showMapIcons = false"
          >
            <img
              src="../../assets/images/ico_anc_dashboard.svg"
              alt="Dashboard"
              style="filter: invert(1)"
            />
          </a>
          <a *ngIf="isCredentialessLogin"
            class="nav-link custom-nav-link custom-tooltip"
            data-tooltip="Please create an account to access Dashboard."
            aria-current="page"            
            (click)="showMapIcons = false"
          >
            <img
              src="../../assets/images/ico_anc_dashboard.svg"
              alt="Dashboard"
              style="filter: invert(1)"
            />
          </a>
        </li>
        <li class="nav-item">
          <a *ngIf="!isCredentialessLogin"
            class="nav-link custom-nav-link custom-tooltip"
            data-tooltip="Map Viewer"
            aria-current="page"
            routerLink="/mapviewer/null/null/null"
            routerLinkActive="active"
            (click)="showMapIcons = false"
          >
            <img
              src="../../assets/images/ico_anc_map_viewer.svg"
              alt="Map Viewer"
              style="filter: invert(1)"
            />
          </a>
          <a *ngIf="isCredentialessLogin"
            class="nav-link custom-nav-link custom-tooltip"
            data-tooltip="Please create an account to access the Map Viewer"
            aria-current="page"            
            (click)="showMapIcons = false"
          >
            <img
              src="../../assets/images/ico_anc_map_viewer.svg"
              alt="Map Viewer"
              style="filter: invert(1)"
            />
          </a>
        </li>
        <li class="nav-item">
          <a *ngIf="!isCredentialessLogin"
            class="nav-link custom-nav-link custom-tooltip"
            routerLink="/digitalplanmf"
            routerLinkActive="active"
            data-tooltip="Digital Plan Room"
            (click)="showMapIcons = false"
          >
            <img
              src="../../assets/images/ico_anc_digital_plan_room.svg"
              alt="Digital Plan Room"
              style="filter: invert(1)"
            />
          </a>
          <a *ngIf="isCredentialessLogin"
            class="nav-link custom-nav-link custom-tooltip"
            data-tooltip="Please create an account to access the Digital Plan Room"
            (click)="showMapIcons = false"
          >
            <img
              src="../../assets/images/ico_anc_digital_plan_room.svg"
              alt="Digital Plan Room"
              style="filter: invert(1)"
            />
          </a>
        </li>
        <li class="nav-item">
          <a *ngIf="!isCredentialessLogin"
            class="nav-link custom-nav-link custom-tooltip"
            data-tooltip="Collaboration"
            aria-current="page"
            routerLink="/collaboration"
            routerLinkActive="active"
            (click)="showMapIcons = false"
          >
            <img
              src="../../assets/images/ico_collaboration_mgmt.svg"
              alt="Collaboration"
              style="filter: invert(1)"
            />
          </a>
          <a *ngIf="isCredentialessLogin"
            class="nav-link custom-nav-link custom-tooltip"
            data-tooltip="Please create an account to access the Collaboration options"
            aria-current="page"            
            (click)="showMapIcons = false"
          >
            <img
              src="../../assets/images/ico_collaboration_mgmt.svg"
              alt="Collaboration"
              style="filter: invert(1)"
            />
          </a>
        </li>
        <li class="nav-item" *ngIf="userDataObj?.role == 'Admin'">
          <a
            class="nav-link custom-nav-link custom-tooltip"
            data-tooltip="GPRS Admin"
            aria-current="page"
            routerLink="/sitemapadmin"
            routerLinkActive="active"
            (click)="showMapIcons = false"
          >
            <img
              src="../../assets/images/ico_anc_system_mgmt.svg"
              alt="GPRS Admin"
              style="filter: invert(1)"
            />
          </a>
        </li>
      </ul>
    </div>

    <div class="collapsed1 navbar-collapse" id="navbarCollapse1">
      <ul
        class="navbar-nav ms-auto mb-2 mb-md-0 custom-navbar custom-navbar-out"
      >
        <!-- <li class="nav-item nav-item-hide">
          <a class="nav-link custom-nav-link  custom-tooltip" data-tooltip="Save" aria-current="page" href="javascript:void(0)">
            <img src="../../assets/images/ico_save.svg" alt="Save" style="filter: invert(1)" />
          </a>
        </li> -->

        <li class="nav-item dropdown" *ngIf="!isCredentialessLogin" >
          <a class="nav-link custom-tooltip" href="javascript:void(0)" id="exportNavbarDropdownMenuLink" 
          role="button" data-bs-toggle="dropdown" aria-expanded="false" data-tooltip="Export">
            <img
              src="../../assets/images/ico_file_export_solid.svg"
              alt="Download"
              style="filter: invert(1)"
            />
            <span *ngIf="showRedDot" class="export-dot"></span>
          </a>
          <ul class="dropdown-menu custom-dropdown-menu" aria-labelledby="exportNavbarDropdownMenuLink">
            <li 
              *ngIf="
                iconHeader.url.includes('jobs') ||
                iconHeader.url.includes('mapviewer')
              "
            >
              <a 
                  class="dropdown-item export-dropdown-item" 
                  href="javascript:void(0)"
                  data-bs-toggle="modal"
                  data-bs-target="#printModal">
                  Start New Data Export
              </a>
            </li>
            <li>
              <a class="dropdown-item export-dropdown-item"
                  href="javascript:void(0)"
                  data-bs-toggle="modal"
                  data-bs-target="#fileDownloadModal"
                  (click)="exportLinkCall('button')"
              >
              Look at previous download
            </a>
            <span *ngIf="showRedDot" class="export-dot"></span>
            </li>
          </ul>
        </li>

        <li class="nav-item dropdown" *ngIf="isCredentialessLogin">
          <a class="nav-link custom-tooltip" href="javascript:void(0)" id="exportNavbarDropdownMenuLink" 
          role="button" data-bs-toggle="dropdown" aria-expanded="false" data-tooltip="Please create an account to access Export capability.">
            <img
              src="../../assets/images/ico_file_export_solid.svg"
              alt="Please create an account to access Export capability."
              style="filter: invert(1)"
            />           
          </a>          
        </li>

        <!-- OLD -->
        <li class="nav-item nav-item-hide position-relative" id="exportMenu" style="display: none;">
          <a
            class="nav-link custom-nav-link custom-tooltip"
            data-tooltip="Export"
            aria-current="page"
            href="javascript:void(0)"
            (click)="exportMenu = !exportMenu"
          >
            <img
              src="../../assets/images/ico_file_export_solid.svg"
              alt="Download"
              style="filter: invert(1)"
            />
            <span *ngIf="showRedDot" class="export-dot"></span>
          </a>
          <div class="layers-parent">
            <ul
              class="dropdown-menu export-menu"
              aria-labelledby="exportMenu"
              [ngClass]="{ open: !exportMenu }"
            >
              <button
                type="button"
                class="btn-close custom-btn-close-layer"
                aria-label="Close"
                (click)="exportMenu = !exportMenu"
              ></button>
              <div class="pomargin"></div>
              <li
                *ngIf="
                  iconHeader.url.includes('jobs') ||
                  iconHeader.url.includes('mapviewer')
                "
              >
                <a
                  href="javascript:void(0)"
                  data-bs-toggle="modal"
                  data-bs-target="#printModal"
                >
                  Start New Data Export</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  data-bs-toggle="modal"
                  data-bs-target="#fileDownloadModal"
                  (click)="exportLinkCall('button')"
                  >Look at previous download</a
                >
                <span *ngIf="showRedDot" class="export-dot"></span>
              </li>
            </ul>
          </div>
        </li>
        <!-- OLD -->

        <!-- <li class="nav-item nav-item-hide">
          <a
            class="nav-link custom-nav-link custom-tooltip"
            data-tooltip="Download"
            aria-current="page"
            href="javascript:void(0)"
            data-bs-toggle="modal"
            data-bs-target="#fileDownloadModal"
            (click)="exportLinkCall('button')"
          >
            <img
              src="../../assets/images/ico_download.svg"
              alt="Download"
              style="filter: invert(1)"
            />
            <span *ngIf="showRedDot" class="export-dot"></span>
          </a>
        </li> -->

        <li
          class="nav-item nav-item-hide"
          *ngIf="
            !iconHeader.url.includes('jobs') && !iconHeader.url.includes('help')
          "
        >
          <a
            class="nav-link custom-nav-link custom-tooltip"
            data-tooltip="Share"
            aria-current="page"
            href="javascript:void(0)"
            (click)="multipleJobShare()"
          >
            <img
              src="../../assets/images/ico_share.svg"
              alt="Share"
              style="filter: invert(1)"
            />
          </a>
        </li>
        <!-- <li
          class="nav-item nav-item-hide"
          *ngIf="iconHeader.url.includes('mapviewer')"
        >
          <a
            class="nav-link custom-nav-link dropdown-toggle custom-tooltip"
            data-tooltip="Folder"
            aria-current="page"
            href="javascript:void(0)"
            role="button"
            (click)="onFolderButtonClick()"
          >
            <img
              src="../../assets/images/ico_folder.svg"
              alt="Folder"
              style="filter: invert(1)"
            />
          </a>
        </li>
        <li
          class="nav-item nav-item-hide"
          *ngIf="iconHeader.url.includes('mapviewer') || iconHeader.url.includes('mapeditor')"
        >
          <a
            class="nav-link custom-nav-link custom-tooltip"
            data-tooltip="Tools"
            aria-current="page"
            href="javascript:void(0)"
            (click)="onMapToolButtonClick()"
          >
            <img
              src="../../assets/images/ico_tools.svg"
              alt="Tools"
              style="filter: invert(1)"
            />
          </a>
        </li> -->

        <li
          class="nav-item dropdown"
          *ngIf="iconHeader.url.includes('mapviewer')"
          style="display: none;"
        >
          <a
            class="nav-link custom-nav-link dropdown-toggle custom-tooltip"
            data-tooltip="Layers"
            href="javascript:void(0)"
            id="layes"
            role="button"
            (click)="onLayerButtonClick()"
          >
            <img
              src="../../assets/images/ico_layers.svg"
              alt="Layers"
              style="filter: invert(1); cursor: pointer"
            />
          </a>
          <!-- <div class="layers-parent">
            <ul class="dropdown-menu layers" aria-labelledby="layes" [ngClass]="{ 'open': !layersModalOpen }">
              <button type="button" class="btn-close custom-btn-close-layer" aria-label="Close"
                (click)="layersModalOpen = !layersModalOpen"></button>
              <li>
                <input type="checkbox" id="layers" /><label for="layers"
                  >All Layers</label
                >
              </li>
              <li>
                <input type="checkbox" id="clientLayer" /><label
                  for="clientLayer"
                  >Client Layers</label
                >
              </li>
              <li>
                <input type="checkbox" id="GRRSLayer" /><label for="GRRSLayer"
                  >GPRS Layers</label
                >
              </li>
              <li>
                <input type="checkbox" id="ImportedLayer" /><label
                  for="ImportedLayer"
                  >Imported Layers</label
                >
                <ul class="sub-layers">
                  <li>
                    <input type="checkbox" id="option1" /><label for="option1"
                      >Option 1</label
                    >
                  </li>
                  <li>
                    <input type="checkbox" id="option2" /><label for="option2"
                      >Option 2</label
                    >
                  </li>
                  <li>
                    <input type="checkbox" id="option1" /><label for="option3"
                      >Option 3</label
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div> -->
        </li>

        <li
          class="nav-item nav-item-hide"
          *ngIf="iconHeader.url.includes('mapviewer') || isCredentialessLogin"
        >
          <a
            class="nav-link custom-nav-link custom-tooltip"
            data-tooltip="Print"
            aria-current="page"
            href="javascript:void(0)"
            
          >
            <img
              src=" ../../assets/images/ico_print.svg"
              alt="Print"
              style="filter: invert(1)"
              data-bs-toggle="modal" data-bs-target="#printModalNew"
              data-backdrop="false"
              (click)="printModalBackDrop()"
            />
          </a>
        </li>
        <!-- data-bs-toggle="modal" data-bs-target="#printModal" -->

        <!-- <li class="nav-item hide" [ngClass]="{ 'show': searchInput }">
          <input class="form-control" type="text" placeholder="Search..." aria-label="Global Search">
        </li>
        <li class="nav-item">
          <a class="nav-link custom-nav-link active" aria-current="page" (click)="searchInput = !searchInput">
            <img src="../../assets/images/ico_search.svg" alt="Glogal Search" />
          </a>
        </li> -->

        <li class="nav-item dropdown" *ngIf="!iconHeader.url.includes('jobs')">
          <a
            class="nav-link dropdown-toggle custom-tooltip"
            data-tooltip="Search"
            href="javascript:void(0)"
            routerLinkActive="active"
            id="search-box"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            (click)="searchModalOpen = !searchModalOpen; onCloseSearch()"
            ><img
              src="../../assets/images/ico_search.svg"
              alt="Glogal Search"
              style="filter: invert(1)"
          /></a>
          <ul
            class="dropdown-menu search-box"
            [ngClass]="{ open: !searchModalOpen }"
            aria-labelledby="search-box"
            style="--i:1024;"
          >
            <li>
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="modal-content">
                      <h4>Search</h4>
                      <button
                        type="button"
                        class="btn-close custom-btn-close"
                        aria-label="Close"
                        (click)="searchModalOpen = !searchModalOpen; onCloseSearch()"
                      ></button>

                      <!-- Old Design -->
                      <form [formGroup]="mapViewerSearch" role="form" (ngSubmit)="searchJobs()">
                      <div class="modal-body">
                        <div class="row g-2">
                          <div class="col-auto">
                            <select
                              class="form-select"
                                (change)="onSearchTypeChange()"
                                aria-label="search parent"
                                formControlName = "selectedSearchType"
                            >
                              <option
                                *ngFor="let searchType of searchTypes"
                                [ngValue]="searchType.num"
                              >
                                {{ searchType.name }}
                              </option>
                            </select>
                            <!-- <input [(ngModel)]="searchStringInput" type="text" class="form-control search-width"
                            placeholder="Search, Name, WA no" aria-label="search header" (keydown.enter)="searchJobs()"> -->
                          </div>
                          <div class="col-auto form-control-search-auto" *ngIf="mapViewerSearch.value.selectedSearchType != 4 && mapViewerSearch.value.selectedSearchType != 6">
                            <label for="searchStringInput" class="visually-hidden"
                              >Search</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                formControlName="searchStringInput"
                                placeholder="Search Criteria"
                              />
                          </div>
                          <div class="col-auto" *ngIf="mapViewerSearch.value.selectedSearchType == 4">
                            <label for="latitudeSearhString" class="visually-hidden"
                              >Search</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                formControlName="latitudeSearhString"
                                placeholder="Latitude"
                              />
                          </div>
                          <div class="col-auto" *ngIf="mapViewerSearch.value.selectedSearchType == 4">
                              <input
                                type="text"
                                class="form-control"
                                formControlName="longitudeSearhString"
                                placeholder="Longitude"
                              />
                            </div>
                          <div class="col-auto form-control-search-auto" *ngIf="mapViewerSearch.value.selectedSearchType == 6">
                            <label for="input_Latitude" class="visually-hidden"
                              >Search</label
                            >
                            <mapbox-address-autofill access-token="pk.eyJ1IjoiYmlwaW5tc2l0NyIsImEiOiJja3liYjNkaHcwYmt0MnJwNWE1amQyYWY2In0.ZTedpS4eCNz73mlMbJa_jg" country= "US"> 
                              <input class="form-control" formControlName="addressSearhString" placeholder="Address Search" type="text" autocomplete="address-line1" />
                              <input formControlName="addressSearhString1" type="text" autocomplete="address-line2" class="adrsSechInp" />
                              <input formControlName="addressSearhString2" type="text" autocomplete="address-line3" class="adrsSechInp" />
                              <input formControlName="addressSearhString3" type="text" autocomplete="address-level4" class="adrsSechInp" />
                              <input formControlName="addressSearhString4" type="text" autocomplete="address-level3" class="adrsSechInp" />
                              <input formControlName="addressSearhString5" type="text" autocomplete="address-level2" class="adrsSechInp" />
                              <input formControlName="addressSearhString6" type="text" autocomplete="address-level1" class="adrsSechInp" />
                              <input formControlName="countrySearhString" type="text" autocomplete="country-name" class="adrsSechInp" />
                              <input formControlName="postcodeSearhString" type="text" autocomplete="postal-code" class="adrsSechInp" />
                            </mapbox-address-autofill>
                          </div>
                          <div class="col-auto d-flex justify-content-end">
                            <button
                              type="submit"
                              class="btn btn-primary me-2"
                              (click)="searchModalOpen = !searchModalOpen"
                            >
                              Search
                            </button>

                            <button
                              type="button"
                              class="btn btn-primary"
                              (click)="resetSearchData()"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                      </form>
                      <!-- <div class="modal-footer pe-0" style="display: none;">
                        <button type="button" class="btn btn-secondary" (click)="resetSearchData()">Reset</button>
                        <button type="button" class="btn custom-btn-primary" (click)="searchJobs()"
                          (click)="searchModalOpen = !searchModalOpen">Search</button>
                      </div> -->
                      <!-- Old Design -->
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a
            class="nav-link custom-nav-link custom-tooltip"
            data-tooltip="Help"
            routerLink="/help"
            routerLinkActive="active"
            (click)="showMapIcons = false"
          >
            <img
              src="../../assets/images/ico_help.svg"
              alt="Help"
              style="filter: invert(1)"
            />
          </a>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link custom-nav-link dropdown-toggle custom-tooltip"
            data-tooltip="Notifications"
            href="javascript:void(0)"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            (click)="notificationModalOpen = !notificationModalOpen"
            (click)="showMapIcons = false"
          >
            <img
              src="../../assets/images/ico_notifications.svg"
              alt="Notifications Icons"
              style="filter: invert(1)"
            />
          </a>
          <ul
            class="dropdown-menu custom-notification-width"
            aria-labelledby="navbarDropdown"
            [ngClass]="{ open: !notificationModalOpen }"
            style="--i:1024"
          >
            <li>
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-12 custom-col-mb-none">
                    <h4>Notifications</h4>
                    <button
                      type="button"
                      class="btn-close custom-btn-close mt-1 me-2"
                      aria-label="Close"
                      (click)="notificationModalOpen = !notificationModalOpen"
                    ></button>
                  </div>
                  <div
                    class="col-lg-12 col-md-12 col-sm-12 custom-hide-scroll custom-col-border-right"
                  >
                    <!-- for testing 1475 bug -->
                    <ul class="custom-notification-list" style="display: none">
                      <li>
                        <span class="read"></span>
                        <div class="d-flex custom-media-list">
                          <div class="comment-user-name">
                            <!-- BS -->
                            hello 1
                          </div>
                          <div class="comment-user-info">
                            <h5 class="fw-bold">
                              hello 2
                              <small class="text-muted">
                                <!-- <i class="fa fa-flag info"></i> -->
                                10-12-45
                              </small>
                            </h5>
                            <p>
                              903342: Reference site about Lorem Ipsum, giving
                              information on its origins, as well as a random
                              Lipsum generator.
                              <i class="fa fa-angle-right"></i>
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>

                    <ul class="custom-notification-list">
                      <li *ngFor="let obj of messageObj">
                        <span *ngIf="!obj.isRead" class="read"></span>
                        <div class="d-flex custom-media-list">
                          <div class="comment-user-name">
                            <!-- BS -->
                            {{ obj.recipientName | slice: 0:1 }}
                          </div>
                          <div class="comment-user-info">
                            <h5 class="fw-bold">
                              {{ obj.recipientName }}
                              <small class="text-muted">
                                <!-- <i class="fa fa-flag info"></i> -->
                                10-12-45
                                {{
                                  obj.sentTimeStamp | date: "MM-dd-yyyy HH:mm"
                                }}
                              </small>
                            </h5>
                            <p class="d-inline-block">
                              903342:
                              {{ obj.body
                              }}<i
                                class="fa fa-angle-right"
                                (click)="markNotificationAsRead(obj)"
                              ></i>
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button> -->

    <div class="collapseNO navbar-collapseNO" id="navbarCollapseNO">
      <ul class="navbar-nav ms-auto mb-2 mb-md-0 custom-navbar">
        <li class="user-infomation">
          <div class="d-flex align-items-center contrast-bug">
            <div class="flex-shrink-0 dropdown" *ngIf="isCredentialessLogin">
                <a class="d-block text-decoration-none custom-current-user"
                  href="javascript:void(0)"
                  (click)="credentialessLoginClick()">
                  <img
                    src="https://i0.wp.com/civillane.com/wp-content/uploads/2017/12/avatar.jpg?ssl=1"
                    alt="Login to SiteMap"
                    class="rounded-circle logged-user-pic"
                  /> 
                Login
              </a>
            </div>
            <div class="flex-shrink-0 dropdown" *ngIf="!isCredentialessLogin">
              <a
                href="javascript:void(0)"
                class="d-block text-decoration-none dropdown-toggle custom-current-user"
                id="dropdownUser2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <!-- <img src="https://github.com/mdo.png" alt="Logged User" class="rounded-circle logged-user-pic"> -->

                <img
                  src="https://i0.wp.com/civillane.com/wp-content/uploads/2017/12/avatar.jpg?ssl=1"
                  alt="user"
                  class="rounded-circle logged-user-pic"
                  *ngIf="isBase64StringEmpty"
                  title="{{ userDataObj?.displayName }}"
                />
                <img
                  src="{{ base64textStringImage }}"
                  *ngIf="!isBase64StringEmpty"
                  alt="user"
                  class="rounded-circle logged-user-pic"
                  title="{{ userDataObj?.displayName }}"
                />
                <!-- <span> {{username | uppercase}}</span> <i>Project Manager</i> -->
                <span
                  class="username-truncate"
                  title="{{ userDataObj?.displayName }}"
                >
                  <strong>{{ userDataObj?.displayName | uppercase }}</strong>
                  <i>{{ userDataObj?.role }}</i>
                </span>
              </a>
              <ul
                class="dropdown-menu text-small shadow custom-logout-menu menu-z-index"
                aria-labelledby="dropdownUser2"
              >
                <li *ngIf="userDataObj?.role == 'Client'">
                  <a class="dropdown-item"
                    >Subscription Tier<span
                      ><b>{{ userDataObj?.tierName }}</b></span
                    ></a
                  >
                </li>
                <li class="user-role-mobile">

                  <a class="dropdown-item">

                    <span><b>{{ userDataObj?.role }}</b></span>

                  </a

                  >

                </li>



                <li class="user-role-mobile">

                  <hr

                    class="dropdown-divider"

                  />

                </li>

                <li *ngIf="userDataObj?.role == 'Client'">
                  <hr class="dropdown-divider" />
                </li>

                <li *ngIf="userDataObj?.tierName != 'Personal'">
                  <a class="dropdown-item" href="tel:1800 226 9090"
                    >Helpline<span><b>1800 226 9090</b></span></a
                  >
                </li>
                <li>
                  <hr
                    *ngIf="userDataObj?.tierName != 'Personal'"
                    class="dropdown-divider"
                  />
                </li>

                <!-- <li><a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-bullseye"></i> Raise a ticket</a></li> -->
                <li>
                  <!-- <a class="dropdown-item" routerLink="/technicalsupport">
                    <img
                      src="../../assets/images/ico_technical_support.svg"
                      alt="Technical
                    Support"
                    />
                    Technical Support</a
                    > -->
                    <a class="dropdown-item" href="javascript:void(0)">
                    <img
                      src="../../assets/images/ico_email_security_reset_info.svg"
                      alt="Support"
                    />
                    support@sitemap.com
                  </a>
                </li>
                <li>
                  <hr
                    *ngIf="userDataObj?.tierName != 'Personal'"
                    class="dropdown-divider"
                  />
                </li>

                <!-- <li><a class="dropdown-item" routerLink="/sitemapadmin"><i class="fa fa-sitemap"></i> Sitemap Admin</a> -->
                <!-- </li> -->
                <li>
                  <a class="dropdown-item" routerLink="/myprofile"
                    ><i class="fa fa-user"></i>My Profile</a
                  >
                </li>
                <!-- <li><a class="dropdown-item" routerLink="/collaboration"><img
                      src="../../assets/images/ico_collaboration_mgmt.svg" alt="CM" /> Collaboration
                    Management</a>
                  </li> -->
                <li>
                  <a class="dropdown-item" routerLink="/mypreferences"
                    ><img
                      src="../../assets/images/ico_my_preferences.svg"
                      alt=""
                    />My Preferences</a
                  >
                </li>

                <!-- <li><a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-eye"></i> Reset Password</a></li> -->
                <!-- <li> -->
                <!-- <a class="dropdown-item" routerLink="/feedback"><img src="../../assets/images/ico_feedback.svg"
                      alt="" />Feedback</a> -->
                <!-- <li><a class="dropdown-item" routerLink="/jobs"><img
                      src="../../assets/images/ico_anc_map_viewer.svg" alt="" /> Map Viewer
                    Site</a>

                </li> -->
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    (click)="logout()"
                    ><img src="../../assets/images/ico_logout.svg" alt="" /> Log
                    out</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- Header Menu Starts -->
<div class="header-edit-mode" [ngClass]="{ 'edit-mode': !headerDisplay }">
  <ul>
    <li>
      <a (click)="headerDisplay = !headerDisplay"
        ><img src="../../assets/images/ico_close.svg" alt="Close"
      /></a>
    </li>
  </ul>
</div>
<!-- Header Menu Starts -->

<!--File Download Modal-->
<button
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#fileDownloadModal"
  hidden
  id="btnModalfileDownload"
  (click)="cancelmodal()"
  aria-labelledby="fileDownloadModal"
></button>
<div
  class="modal fade"
  id="fileDownloadModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="fileDownloadModalLabel"
  aria-hidden="true"
>
  <button
    class="btn btn-primary"
    hidden
    id="showreddotindicator"
    (click)="showRedDott()"
    type="button"
    aria-labelledby="showreddotindicator"
  ></button>

  <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="fileDownloadModalLabel">
          <span>Export File</span>
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <!-- <p>Your export link is ready to download, please click to</p>
        <a id="dwnldFile"
          download
          class="btn-link cursor-pointer"
          (click)="downloadFile()"
          >download</a> -->

        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-borderless custom-table bgnone">
                <thead>
                  <tr>
                    <th scope="col">File Name</th>
                    <th scope="col">File Type</th>
                    <!-- <th scope="col">No. of days</th> -->
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="exportFileListArr.length > 0">
                    <tr *ngFor="let obj of exportFileListArr; let i = index">
                      <td>{{ obj.exportFileName }}</td>
                      <td>{{ obj.fileType }}</td>
                      <!-- <td>99999</td> -->
                      <td *ngIf="obj.isCompleted">
                        <a
                          id="dwnldFile"
                          [href]="obj.exportFileLink"
                          download
                          class="btn-link cursor-pointer"
                          (click)="downloadFile(obj.importExportJobId)"
                          >download</a
                        >
                      </td>
                      <td *ngIf="!obj.isCompleted">link not generated</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="false">
                    <tr>
                      <td colspan="3">No export files found</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
