<div class="row">
  <div class="col-lg-12 mt-0 p-0">
    <main class="page-main">
      <div class="container-fluid page-main-content">
        <div class="row">
          <div class="col-lg-12">
            <div class="map-option" style="display: none1;">
              <ul>               
                <li>
                  <img src="../../../../../assets/images/ico_current_location.svg" alt="Current Location"
                    (click)="navigateToCurrentLocation()" title="Current Location" />
                  <!-- ico_current_location.svg -->
                </li>
                <li>
                  <img src="../../../../../assets/images/ico_background_map.svg" alt="Map Style" title="Map Style" />
                  <form [formGroup]="MapviewerSiteForm">
                    <ul>
                      <li>
                        <label for="satellite-v9">
                          <input id="satellite-v9" type="radio" value="satellite-v9" formControlName="mapType"
                            (change)="changeMaptypeEvent($event)" />
                          <img src="../../../../../assets/images/mapbox-satellite.jpg" alt=""
                            (change)="changeMaptypeEvent($event)" />
                          <span>Satellite</span>
                        </label>
                      </li>

                      <li>
                        <label for="light-v10">
                          <input id="light-v10" type="radio" value="light-v10" formControlName="mapType"
                            (change)="changeMaptypeEvent($event)" />
                          <img src="../../../../../assets/images/mapbox-light.jpg" alt=""
                            (change)="changeMaptypeEvent($event)" />
                          <span>Light</span>
                        </label>
                      </li>

                      <li>
                        <label for="dark-v10">
                          <input id="dark-v10" type="radio" value="dark-v10" formControlName="mapType"
                            (change)="changeMaptypeEvent($event)" />
                          <img src="../../../../../assets/images/mapbox-dark.jpg" alt=""
                            (change)="changeMaptypeEvent($event)" />
                          <span>Dark</span>
                        </label>
                      </li>

                      <li>
                        <label for="streets-v11">
                          <input id="streets-v11" type="radio" value="streets-v11" formControlName="mapType"
                            (change)="changeMaptypeEvent($event)" />
                          <img src="../../../../../assets/images/mapbox-streets.jpg" alt=""
                            (change)="changeMaptypeEvent($event)" />
                          <span>Streets</span>
                        </label>
                      </li>

                      <li>
                        <label for="satellite-streets-v11">
                          <input id="satellite-streets-v11" type="radio" value="satellite-streets-v11"
                            formControlName="mapType" (change)="changeMaptypeEvent($event)" />
                          <img src="../../../../../assets/images/mapbox-satellite-streets.jpg" alt=""
                            (change)="changeMaptypeEvent($event)" />
                          <span>Satellite Streets</span>
                        </label>
                      </li>

                      <li>
                        <label for="outdoors-v11">
                          <input id="outdoors-v11" type="radio" value="outdoors-v11" formControlName="mapType"
                            (change)="changeMaptypeEvent($event)" />
                          <img src="../../../../../assets/images/mapbox-outdoors.jpg" alt=""
                            (change)="changeMaptypeEvent($event)" />
                          <span>Outdoors</span>
                        </label>
                      </li>

                      <li>
                        <label for="navigation-day-v1">
                          <input id="navigation-day-v1" type="radio" value="navigation-day-v1" formControlName="mapType"
                            (change)="changeMaptypeEvent($event)" />
                          <img src="../../../../../assets/images/mapbox-navigation-day.jpg" alt="Hybrid"
                            (change)="changeMaptypeEvent($event)" />
                          <span>Navigation Day</span>
                        </label>
                      </li>

                      <li>
                        <label for="navigation-night-v1">
                          <input formControlName="mapType" id="navigation-night-v1" type="radio"
                            value="navigation-night-v1" (change)="changeMaptypeEvent($event)" />
                          <img src="../../../../../assets/images/mapbox-navigation-night.jpg" alt=""
                            (change)="changeMaptypeEvent($event)" />
                          <span>Navigation Night</span>
                        </label>
                      </li>
                    </ul>
                  </form>
                </li>
                <li>                 
                  <img
                    src="../../assets/images/ico_my_preferences.svg"
                    alt="Choose Default Render Scale"                   
                    title="Choose Default Render Scale"
                  />
                  <form [formGroup]="RenderScaleForm">                                           
                        <span class="render-scale">Choose Default Scale to Render Features:</span>
                        <select class="render-scale"
                        id="render-scale-select"
                        (change)="changeRenderScaleEvent($event)"
                        [value]="currentRenderScale">
                          <option value="500">500 ft.</option>
                          <option value="1000">1,000 ft.</option>
                          <option value="2000">2,000 ft.</option>
                          <option value="5000">5,000 ft.</option>
                          <option value="10000">10,000 ft.</option>
                        </select>                                                                                 
                  </form>
                </li>
              </ul>
            </div>

            <div class="site-controls" [ngClass]="{'disabled': isSiteEditingModeIsOn}" *ngIf="currentZoomLevel > 13.25"style="display: none;">
              <ul>
                <li>
                  <img src="../../../../../assets/images/ico_plus.svg" alt="Map Style" title="Site Controls" />
                    <ul>
                       <li data-bs-toggle="modal" data-bs-target="#addSite" (click)="getCountryList();">
                         Add Site
                      </li>
                      <li id="liAddSubsite" [attr.data-bs-toggle]="subSiteServices.addAttrForSubSiteModal ? '' : 'modal'" [attr.data-bs-target]="subSiteServices.addAttrForSubSiteModal ? '' : '#addSubSite'" (click)="subSiteModalOpenClick()">
                        Add Sub Site
                      </li>
                      <li data-bs-toggle="modal" class="disabled" data-bs-target="#addJob">
                        Add Job
                      </li>
                       <!-- <li>
                        Edit
                      </li> -->
                    </ul>
                  </li>
              </ul>
            </div>


            <div id="mapviewer" class="custom-mapbox"></div>
            <br />
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
<!--- Single Job Share -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <form aria-required="true">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title custom-modal-title" id="staticBackdropLabel">
            Share Job
          </h5>

          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="closeShareObjModal()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="col-lg-12 shared-content" *ngIf="objSharedUserList.length != 0 && isJobOwner">
                <div class="parent-wrapper">
                  <h3>SHARED WITH</h3>
                  <ul>
                    <li *ngFor="
                        let userDetail of objSharedUserList;
                        let i = index
                      ">
                      {{ userDetail.displayName }}
                      ({{ userDetail.emailId }}) - {{ userDetail.accessName }}
                      <span class="fa fa-edit pointer" data-bs-dismiss="modal" aria-label="Close" routerLink="/collaboration"></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="list-showing-parent mb-3">
                <label for="emailAddressShareModal" class="form-label custom-label">Email address
                  <span class="custom-form-label-star">*</span></label>
                <input type="email" class="form-control" id="emailAddressShareModal" [formControl]="emailFormControl"
                  placeholder="name@example.com" />
                <ul class="list-showing" [ngClass]="{
                    'fetched-emails-diplay': shouldFetchedEmailsBeDisplayed
                  }">
                  <li *ngFor="let item of fetchedEmails; let i = index" (click)="selectEmailFromFetchedEmails(item)">
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-12">
              <p class="custom-label">Access Control</p>
            </div>
            <div class="col-lg-6">
              <div class="form-check">
                <input class="form-check-input" type="radio" value="1" [formControl]="accessFormControl"
                  name="flexRadioDefault" id="flexRadioDefault1" (change)="shareObj(editRadio.value, true)" />
                <label class="form-check-label" for="flexRadioDefault1">
                  View
                </label>
              </div>
            </div>
            <div class="col-lg-6" >
              <div class="form-check">
                <input class="form-check-input" type="radio" value="2" [formControl]="accessFormControl"
                  name="flexRadioDefault" id="flexRadioDefault2" #editRadio (change)="shareObj(editRadio.value, true)">
                <label class="form-check-label" for="flexRadioDefault2">
                  Edit
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="closeShareObjModal()">
            Cancel
          </button>
          <button (click)="shareObj(editRadio.value)" type="button" class="btn btn-primary" data-bs-dismiss="modal"
            [disabled]="emailFormControl.invalid">
            Share
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<!--- Single Job Share -->

<!----multiple job share modal  -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop1" hidden id="btnModalMultiShare"
  aria-label="modal"></button>

<div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdrop1Label" aria-hidden="true">
  <div class="modal-dialog">
    <form aria-required="true">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title custom-modal-title" id="staticBackdrop1Label">
            Share Job
          </h5>

          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="closeShareObjModal()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <!-- <div class="col-lg-12 shared-content" *ngIf="objSharedUserList.length!=0">
                <div class="parent-wrapper">
                  <h3>SHARED WITH</h3>
                  <ul>
                    <li *ngFor="let userDetail of objSharedUserList; let i = index;">
                      {{userDetail.displayName}}
                      ({{userDetail.emailId}}) - {{userDetail.accessName}} <span class="fa fa-edit pointer"
                        routerLink="/collaboration"></span></li>
                  </ul>

                </div>
              </div> -->
              <div class="list-showing-parent mb-2">
                <label for="exampleFormControlInput1" class="form-label custom-label">Email address
                  <span class="custom-form-label-star">*</span></label>
                <input type="email" class="form-control" id="exampleFormControlInput1" [formControl]="emailFormControl"
                  placeholder="name@example.com" />
                <ul class="list-showing" [ngClass]="{
                    'fetched-emails-diplay': shouldFetchedEmailsBeDisplayed
                  }">
                  <li *ngFor="let item of fetchedEmails; let i = index" (click)="selectEmailFromFetchedEmails(item)">
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-12">
              <p class="custom-label">Access Control</p>
            </div>
            <div class="col-lg-6">
              <div class="form-check">
                <input class="form-check-input" type="radio" value="1" [formControl]="accessFormControl"
                  name="accessControlView2" id="accessControlView" />
                <label class="form-check-label" for="accessControlView">
                  View
                </label>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-check">
                <input class="form-check-input" type="radio" value="2" [formControl]="accessFormControl"
                  name="accessControlView2" id="accessControlEdit" />
                <label class="form-check-label" for="accessControlEdit">
                  Edit
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            Cancel
          </button>
          <button (click)="multipleShareJobObj()" type="button" class="btn btn-primary" data-bs-dismiss="modal"
            [disabled]="emailFormControl.invalid">
            Share
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<!----multiple job share modal  -->

<!-- Export Modal -->
<div class="modal fade" id="printModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="printModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="printModalLabel">
          <span>Export</span>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form [formGroup]="exportForm">
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="col-lg-12 pb-0 border-bottom">
                <div class="mb-3 row">
                  <label for="printModalCRC" class="col-sm-3 col-form-label">File Type<span class="custom-form-label-star">*</span></label>
                  <div class="col-sm-9">
                    <select class="form-select form-select-sm" aria-label="printModalCRC" formControlName="filetype">
                      <option value="1">KML</option>
                      <option value="2">SW MAPS</option>
                      <option value="3">GeoPackage</option>
                      <option value="4">ShapeFile</option>
                      <option value="5">GeoJson</option>
                      <option value="6">DXF</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-2 pb-0 border-bottom">
                <div class="mb-3 row">
                  <label for="printModalScale" class="col-sm-3 col-form-label">File Name<span class="custom-form-label-star">*</span></label>
                  <div class="col-sm-9">
                    <input type="text" class="form-control"  aria-label="printModalScale" formControlName="filename">
                    <p class="mb-0 text-danger" *ngIf="exportForm.get('filename')?.invalid && (exportForm.get('filename')?.dirty || exportForm.get('filename')?.touched) && exportForm.get('filename')?.errors?.required">
                      *Please provide a file name.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button  [disabled]="exportForm.invalid" (click)="generateExportLink()" type="button" class="btn btn-primary" data-bs-dismiss="modal">
            Export File
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- Export Modal -->

<div id="map-wrapper" class="letter landscape">
  <div id="crs-div">CRS:NAD83 / State Plane Ohio North (3401), Units: US Survey Feet</div>
  <div class="map-row flex-container">
    <div class="print-img-container flex-child">
      <img id="print-img"  src="" alt="">
      <!-- <img id="print-img"  src="../../assets//images/GPRSReportLetterLandscape.png" alt=""> -->
    </div>
    <div id="info-panel" class="flex-child">
      <div class="right-side" id="logo">
        <h1>N</h1>
        <span><img src="../../assets/images/logo.png" height="35"alt=""></span>
        <span>Powered by GPRS</span>
      </div>
      <div class="right-side" id="disclaimer">
        <p>DISCLAIMER</p>
        <ul>
          <li>THIS EXHIBIT IS INTENDED TO ILLUSTRATE RECORDS OF FEATURES, AND UTILITY MARKINGS COLLECTED ON SITE. THE HISTORIC RECORDS SHOWN ARE A COMBINATION OF GPRS COLLECTED DATA AS WELL AS ANY ADJUSTMENTS BY OTHER SITEMAP USERS WITH ACCESS TO MAKE CHANGES TO THIS DATA. GPRS CANNOT GUARANTEE IT WILL BE ABLE TO LOCATE ALL UTILITIES OR OTHER SUBSURFACE FEATURES ON SITE NOR CAN IT CERTIFY THAT THE ITEMS SHOWN ON THIS EXHIBIT ARE CURRENT BEYOND THE OFFICIAL SUBMITTAL DATE OF A GPRS CONTRACT LOCATE DELIVERABLE. ALL FINDINGS ARE SUBJECT TO THE TERMS AND CONDITIONS THAT CAN BE VIEWED WITH THE LINK: HTTPS://M.GP-RADAR.COM/TERMS-CONDITIONS</li>
          <li>GPRS DOES NOT PROVIDE GEOPHYSICAL, GEOLOGICAL, LAND SURVEYING, OR ENGINEERING SERVICES.</li>
          <li>FOR ADDITIONAL INFORMATION REGARDING FINDINGS AND EQUIPMENT USED, SEE THE "JOB SUMMARY REPORT" THAT WAS SUBMITTED VIA EMAIL FOLLOWING COMPLETION OF FIELD WORK.</li>
          <li>PRIVATE UTILITY LOCATING IS NEVER A REPLACEMENT FOR ONE CALL/811 SERVICES. STATE LAW REQUIRES 811 TO BE CALLED PRIOR TO ANY AND ALL EXCAVATION ACTIVITIES.</li>
        </ul>
      </div>


      <div class="side-legend">
        <h6>LEGEND</h6>
        <ul>
          <li *ngFor="let symbology of lineSymbology">
            <div>
              <span [ngStyle]="{'background-color': symbology.color, 'border': '2px solid ' + symbology.color  }"></span><i>{{ symbology.name }}</i>
            </div>
          </li>
        </ul>
      </div>

      <div class="flex-container right-side" id="legend">
        <div *ngFor="let icon of pointSymbology" class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/{{icon}}.png" alt="{{icon}}" />{{formatIconName(icon)}}
        </div>
      </div>
      <!--
      <div class="side-legend">
        <h6>LEGEND</h6>
        <ul>
          <li><div><span class="black box"></span><i>SCAN AREA</i></div></li>
          <li><div><span class="green box"></span><i>NO SCAN</i></div></li>
          <li><div><span class="yellow"></span><i>TELECOM</i></div></li>
          <li><div><span class="ajeeb"></span><i>GASFUEL</i></div></li>
          <li><div><span class="orange"></span><i>ELECTRICAL</i></div></li>
          <li><div><span class="pink"></span><i>UNKNOWN</i></div></li>
          <li><div><span class="blue"></span><i>WATER</i></div></li>
          <li><div><span class="blue-light"></span><i>IRRIGATION</i></div></li>
          <li><div><span class="green-light"></span><i>STORM</i></div></li>
          <li><div><span class="grey-light"></span><i>MISC LINE</i></div></li>
          <li><div><span class="green-light"></span><i>SANITARY</i></div></li>
          <li><div><span class="grey-update"></span><i>BY OTHERS</i></div></li>
        </ul>
      </div>

      <div class="flex-container right-side" id="legend">
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_CABINET.png" alt="ELEC_CABINET" />ELEC CABINET
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/COMM_PEDESTAL.png" alt="COMM_PEDESTAL" />COMM PEDESTAL
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_METER.png" alt="ELEC_METER" />ELEC METER
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/COMM_POLE.png" alt="COMM_POLE" />COMM POLE
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_PANEL.png" alt="ELEC_PANEL" />ELEC PANEL
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/COMM_CAMERA.png" alt="COMM_CAMERA" />CAMERA
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_EQUIPMENT.png" alt="ELEC_EQUIPMENT" />ELEC EQUIPMENT
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GAS_PUMP.png" alt="GAS_PUMP" />GAS PUMP
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_SIGN.png" alt="ELEC_SIGN" />ELEC SIGN
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/FIRE_HYDRANT.png" alt="FIRE_HYDRANT" />FIRE HYDRANT
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_TRANSFORMER.png" alt="ELEC_TRANSFORMER" />TRANSFORMER
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/WTR_METER.png" alt="WTR_METER" />WATER METER
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_LANDSCAPELIGHT.png" alt="ELEC_LANDSCAPELIGHT" />LANDSCAPE LIGHT
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/STRM_CATCHBASINROUND.png" alt="STRM_CATCHBASINROUND"/>CATCH BASIN
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_SITELIGHT.png" alt="ELEC_SITELIGHT" />LIGHT POLE
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/STRM_CATCHBASINSQUARE.png" alt="STRM_CATCHBASINSQUARE" />CATCH BASIN
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_POWERPOLE.png" alt="ELEC_POWERPOLE" />POWER POLE
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/STRM_CURBINLET.png" alt="STRM_CURBINLET" />CURB INLET
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_UTILITYPOLE.png" alt="ELEC_UTILITYPOLE" />UTILITY POLE
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/STRM_ENDPIPE.png" alt="STRM_ENDPIPE" />STORM ENDPIPE
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/SAN_MARKER.png" alt="SAN_MARKER" />SAN MARKER
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/BUILDING_CORNER.png" alt="BUILDING CORNER" />BUILDING CORNER
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/SAN_GREASETRAP.png" alt="SAN_GREASETRAP" />GREASE TRAP
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/SOIL_BORING.png" alt="SOIL_BORING" />SOIL BORING
        </div>
        <div style="font-weight: 600;">GENERIC SYMBOLS - COLOR DENOTES UTILITY</div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_RISER.png" alt="GENERIC_RISER" /><i>RISER</i>
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_UST_AST.png" alt="GENERIC_UST_AST" />UST / AST
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_LIFT_STATION.png" alt="GENERIC_LIFT_STATION" />LIFT STATION
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_VENT.png" alt="GENERIC_VENT" />VENT
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_BLACKFLOW_PREV.png" alt="GENERIC_BLACKFLOW_PREV" />BACKFLOW PREV
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_VALVE.png" alt="GENERIC_VALVE" />VALVE
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_CLEAN_OUT.png" alt="GENERIC_CLEAN_OUT" />CLEAN OUT
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_PST_IND_VALVE.png" alt="GENERIC_PST_IND_VALVE" />PST IND VALVE
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_BOX.png" alt="GENERIC_BOX" />BOX
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_MISCELLANEOUS.png" alt="MISC_CONTINUES" />MISCELLANEOUS
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_VAULT.png" alt="VALUT" />VALUT
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_END_OF_INFO.png" alt="GENERIC_END_OF_INFO" />END OF INFO
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_MANHOLE.png" alt="GENERIC_MANHOLE" />MANHOLE
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_LINE_CONTINUES.png" alt="GENERIC_LINE_CONTINUES" />LINE CONTINUES
        </div>
      </div>
    -->

      <div class="scale-bar-container">
        <div class="first-bar scale-bar-item"></div>
        <span id="first-bar-label" >0'</span>
        <div class="second-bar scale-bar-item"></div>
        <span id="second-bar-label">0'</span>
        <div class="third-bar scale-bar-item"></div>
        <span id="third-bar-label">0'</span>
        <div class="fourth-bar scale-bar-item"></div>
        <span id="fourth-bar-label" >0'</span>
        <span id="fifth-bar-label">0'</span>
        <!-- <div id="scale-bar-label">Scale 1" = 100'</div> -->
      </div>

      <div class="side-footer">
        <img src="../../../../../assets/images/pdfgprsv3.png" alt="" />
        <button>FOR INFORMATION ONLY</button>
        <h2>SITEMAP RECORD EXHIBIT</h2>
        <span>LOCATION</span>
        <i id="project-name">This is a Project Name</i>
        <i id="project-address">3556 North Tindle Blvd Apt 1</i>
        <i id="project-city">Flagstaff</i>
        <!-- <p>Print by: name</p>
        <p>Print date: 28-08-2022</p> -->
      </div>


      <!-- <div class="right-side" id="scale"></div> -->
      <!-- NITIN <div class="right-side" id="legal">
        <p>Class molestie! Placeat. Purus? Dolore primis quis debitis. Laboris, netus sapiente irure morbi suscipit maecenas habitant erat cillum culpa voluptate fugit similique repudiandae! Deserunt, aperiam praesent molestie praesentium netus egestas, expedita semper adipisci possimus torquent. Commodi, quidem laboriosam aute. Non labore ultrices optio facere excepteur quaerat rutrum rem, quibusdam augue atque? Penatibus. Aliquam tristique provident necessitatibus maiores earum, sollicitudin impedit iusto exercitation elit etiam voluptate pulvinar, dolorum voluptatum animi blanditiis, facere wisi! Aliquip felis sint occaecati ipsa curae, adipiscing varius. Potenti maxime congue inceptos pariatur lobortis minus fermentum? Aperiam magna laoreet, orci, aliquid vehicula interdum irure! Quidem porta aptent, repellat.</p>
      </div>
      <div class="right-side" id="location">
        <p>Class molestie! Placeat. Purus? Dolore primis quis debitis. Laboris, netus sapiente irure morbi suscipit maecenas habitant erat cillum culpa voluptate fugit similique repudiandae! Deserunt, aperiam praesent molestie praesentium netus egestas, expedita semper adipisci possimus torquent. Commodi, quidem laboriosam aute. Non labore ultrices optio facere excepteur quaerat rutrum rem, quibusdam augue atque? Penatibus. Aliquam tristique provident necessitatibus maiores earum, sollicitudin impedit iusto exercitation elit etiam voluptate pulvinar, dolorum voluptatum animi blanditiis, facere wisi! Aliquip felis sint occaecati ipsa curae, adipiscing varius. Potenti maxime congue inceptos pariatur lobortis minus fermentum? Aperiam magna laoreet, orci, aliquid vehicula interdum irure! Quidem porta aptent, repellat.Class molestie! Placeat. Purus? Dolore primis quis debitis. Laboris, netus sapiente irure morbi suscipit maecenas habitant erat cillum culpa voluptate fugit similique repudiandae! Deserunt, aperiam praesent molestie praesentium netus egestas, expedita semper adipisci possimus torquent. Commodi, quidem laboriosam aute. Non labore ultrices optio facere excepteur quaerat rutrum rem, quibusdam augue atque? Penatibus. Aliquam tristique provident necessitatibus maiores earum, sollicitudin impedit iusto exercitation elit etiam voluptate pulvinar, dolorum voluptatum animi blanditiis, facere wisi! Aliquip felis sint occaecati ipsa curae, adipiscing varius. Potenti maxime congue inceptos pariatur lobortis minus fermentum? Aperiam magna laoreet, orci, aliquid vehicula interdum irure! Quidem porta aptent, repellat.Aperiam magna laoreet, orci, aliquid vehicula interdum irure! Quidem porta aptent, repellat.Class molestie! Placeat. Purus? Dolore primis quis debitis. Laboris, netus sapiente irure morbi suscipit maecenas habitant erat cillum culpa voluptate fugit similique repudiandae! Class molestie! Placeat.</p>
      </div> -->
      <!-- <div class="current-info">
        <div class="right-side" id="printed-by">Print by:</div>
        <div class="right-side" id="printed-date">print_chris_12345678@website.com</div>
      </div>
      <div class="current-info">
        <div class="right-side" id="printed-by">Print date:</div>
        <div class="right-side" id="printed-date">01 December 2022</div>
      </div> -->

      <div class="print-information">
        <div class="print-information-data" >Printed by:</div>
        <div class="print-information-data" id="printed-by">print@website.com</div>
      </div>
      <div class="print-information second">
        <div class="print-information-data" >Print Date:</div>
        <div class="print-information-data" id="printed-date">01 December 2022</div>
      </div>
    </div>
  </div>
</div>

<!-- Side admin Details Menu Starts -->
<!-- <div *ngIf="loginUserRole.toLowerCase() == 'admin'">
  <app-site-admin-details [siteAddForm]="siteAddForm" [newlyAddedSiteValue]="newlyAddedSiteValue"
    [newlyAddedSubSiteValue]="newlyAddedSubSiteValue" [draw]="draw" [map]="map" [zoomOnMapParamVal]="zoomOnMapParamVal"
    [slideMenuOpenSiteDetails]="slideMenuOpenSiteDetails" [loginUserId]="loginUserId" [loginUserEmailId]="loginUserEmailId"
    [slideMenuOpenSiteAdminDetails]="slideMenuOpenSiteAdminDetails" [slideMenuOpenLayerDetails]="slideMenuOpenLayerDetails"
    [slideMenuOpenTools]="slideMenuOpenTools" [slideMenuOpenJobDescription]="slideMenuOpenJobDescription"
    [multipleJobsIdByPoligonDraw]="multipleJobsIdByPoligonDraw"  [subSiteModalData]="subSiteModalData"
    [loginUserTierId]="loginUserTierId" [loginUserRole]="loginUserRole" [currentZoomLevel]="currentZoomLevel"
    [slideMenuOpenFolder]="slideMenuOpenFolder" (onAddSite)="adminGetSiteList($event)" (drawPolygonOnMap)="onDrawPolygonOnMap($event)"
    (onGetSubSiteByID)="getSubSiteBySiteId($event)"(ontoggleClicked)="onSlideToggleClicked($event)" (mapEditor)="onMapEditor($event)"
    (editSitedata)="editSiteInfo($event)"(editSubSiteData)="editSubSiteData($event)" (getCountry)="getCountryList()" (newlyAddedSubSiteEmitter)="newlyAddedSubSiteEmitter($event)"
    (openSubSitePopup)="subSiteModalOpenClick()" (searchedUserDetail)="siteSearchUser($event)"></app-site-admin-details>
</div> -->
<!--End site admin details-->

<!-- ////////////////////////// -->
<!-- Side Details Menu Starts -->
<div *ngIf="currentZoomLevel > 13.25">
  <app-site-details [siteAddForm]="siteAddForm" [siteData]="siteData" [draw]="draw" [map]="map" [slideMenuOpenSiteDetails]="slideMenuOpenSiteDetails"
  [loginUserId]="loginUserId" [loginUserEmailId]="loginUserEmailId" [slideMenuOpenLayerDetails]="slideMenuOpenLayerDetails"
  [slideMenuOpenSiteAdminDetails]="slideMenuOpenSiteAdminDetails" [slideMenuOpenTools]="slideMenuOpenTools" [zoomOnMapParamVal]="zoomOnMapParamVal"
    [slideMenuOpenJobDescription]="slideMenuOpenJobDescription" [multipleJobsIdByPoligonDraw]="multipleJobsIdByPoligonDraw" [subSiteModalData]="subSiteModalData"
    [loginUserTierId]="loginUserTierId" [loginUserRole]="loginUserRole" [currentZoomLevel]="currentZoomLevel"
    [slideMenuOpenFolder]="slideMenuOpenFolder" (onAddSite)="getSiteList($event, undefined, true)" (drawPolygonOnMap)="onDrawPolygonOnMap($event)"
    (onGetSubSiteByID)="getSubSiteBySiteId($event)"(ontoggleClicked)="onSlideToggleClicked($event)" (mapEditor)="onMapEditor($event)"
    (editSitedata)="editSiteInfo($event)"(editSubSiteData)="editSubSiteData($event)" (getCountry)="getCountryList()"
    (openSubSitePopup)="subSiteModalOpenClick()"></app-site-details>
</div>
<!-- <ng-template #noDataSubSiteData>
<div>
<p class="text-center">No Data Found</p>
</div>
</ng-template> -->
<!-- Slide Details Menu Ends -->
<!-- ////////////////////////// -->

<div *ngIf="currentZoomLevel > userRenderScale">
<!-- Slide Menu Starts -->
<app-layer  [mergeParentLineId]="mergeParentLineId" [mergeChildLineId]="mergeChildLineId" [editingState]="editingState" [isSnappingToLine]="isSnappingToLine"
[isSnappingToVertex]="isSnappingToVertex"  [addVertexLineId]="addVertexLineId" [disablePointInteractions]="disablePointInteractions" [addGroupFeatureTypeObj]="addGroupFeatureType"
[featureTypeStyleMap]="featureTypeStyleMap" [map]="map" [draw]="draw" [enableEditModelOnDblClick]="enableEditModelOnDblClick" [isDrawing]="isDrawing"
[loginUserId]="loginUserId" [slideMenuOpenSiteDetails]="slideMenuOpenSiteDetails" [clickedFeatures]="clickedFeaturesForLayer"
[slideMenuOpenLayerDetails]="slideMenuOpenLayerDetails" [slideMenuOpenTools]="slideMenuOpenTools" [clickedFeatureLat]="clickedFeatureLat"
[slideMenuOpenJobDescription]="slideMenuOpenJobDescription" [showToggleSideBar]="showToggleSideBar" [clickedFeatureLng]="clickedFeatureLng"
[slideMenuOpenFolder]="slideMenuOpenFolder" [zoomedJobIds]="zoomedJobIds" [zoomedFeaturesObservableArray]="zoomedFeaturesObservableArray" [selectedFeature]="selectedFeatureFromDraw"
[dragMap] = "dragMap" [zoomMap] = "zoomMap" [slideMenuOpenSiteAdminDetails]="slideMenuOpenSiteAdminDetails"
(ontoggleClicked)="onSlideToggleClicked($event)" (onFeaturePropertyUpdate)="onFeaturePropertyObjectUpdate($event)"
(updateEditMode)="onUpdateEditMode($event)" (measeureLength)="onMeaseureLength($event)" (removeExtraPopup)="onRemoveExtraPopup()"
(measureCalcArea)="onMeasureCalcArea($event)" (updateNavigatedFeatures)="onUpdateNavigatedFeatures($event)" (onRefreshDescriptionData)="refreshDescriptionData($event)"
(onUpdateDblClick)="updateDblClick($event)" (onSaveSingleFeature)="onSaveSingleFeature($event)" [zoomedImportedImageObservableArray]="zoomedImportedImageObservableArray"
(toggleEditingState)="onToggleEditingState($event)" (parentOrChildMerge)="onParentOrChildMerge($event)" (onExitEditing)="onExitEditingLayer($event)"
[iframeExternalContentLink]="iframeExternalContentLink" [firstSecondLineFeatureFrMergedArr]="firstSecondLineFeatureFrMergedArr" (siteToggleApplied)="onSiteToggleApplied($event)"></app-layer>
<!-- <ng-template #noJobInLayer>
<div>
<p class="documents-msg">No Job Associated with this site</p>
</div>
</ng-template> -->
<!-- Slide Menu Ends -->
</div>

<!-- Slide Tools Starts -->
<app-tools [navigatedFeatures]="navigatedFeatures" [map]="map" [draw]="draw" [mergeParentOrChild]="mergeParentOrChild" [toggleEditingStateObj]="toggleEditingState" [isEditingModeIsOn]="isEditingModeIsOn"
[slideMenuOpenSiteDetails]="slideMenuOpenSiteDetails" [slideMenuOpenLayerDetails]="slideMenuOpenLayerDetails"
[slideMenuOpenSiteAdminDetails]="slideMenuOpenSiteAdminDetails" [slideMenuOpenTools]="slideMenuOpenTools"
[slideMenuOpenFolder]="slideMenuOpenFolder" (addGroupFeatureType)="onAddGroupFeatureType($event)" [slideMenuOpenJobDescription]="slideMenuOpenJobDescription"
(ontoggleClicked)="onSlideToggleClicked($event)" (setAddVertexLineId)="setAddVertexLineId($event)" (setDrawing)="setDrawing($event)" (setDisablePointInteractions)="setDisablePointInteractions($event)"
(globalDrawCreate)="globalDrawCreate($event)" (setIsSnappingToLine)="setIsSnappingToLine($event)" (setIsSnappingToVertex)="setIsSnappingToVertex($event)" (setEditingState)="setEditingState($event)"
(setMergeParentLineId)="setMergeParentLineId($event)" (setMergeChildLineId)="setMergeChildLineId($event)" (setfirstSecondLineFeatureObjFrMerge)="setfirstSecondLineFeatureObjFrMerge($event)"></app-tools>
<!-- Slide Tools Ends -->

<!-- Slide Site Starts -->
<app-feature-description [map]="map" [slideMenuOpenSiteDetails]="slideMenuOpenSiteDetails" [slideMenuOpenSiteAdminDetails]="slideMenuOpenSiteAdminDetails"
[slideMenuOpenLayerDetails]="slideMenuOpenLayerDetails" [slideMenuOpenTools]="slideMenuOpenTools"
[slideMenuOpenJobDescription]="slideMenuOpenJobDescription" [loginUserRole]="loginUserRole" [featureDetailOnClick]="featureDetailOnClick"
[slideMenuOpenFolder]="slideMenuOpenFolder" [navigatedJobId]="jobId" [editDescriptionValue]="editDescriptionValue" [loginUserId]="loginUserId" [openedFeatureName]="openedFeatureName" [openedFeatureProperties]="openedFeatureProperties" [openedFeatureAttributes]="openedFeatureAttributes"
(ontoggleClicked)="onSlideToggleClicked($event)" (onHandleLayerMouseClick)="updateOnDescriptionChange($event)"></app-feature-description>
<!-- Slide Site Ends -->

<div *ngIf="currentZoomLevel > 13.25">
<!-- Slide folder Starts -->
<app-dpr-menus [map]="map" [dragMap] = "dragMap" [zoomMap] = "zoomMap" [showToggleSideBar]="showToggleSideBar" [slideMenuOpenSiteDetails]="slideMenuOpenSiteDetails"
[slideMenuOpenLayerDetails]="slideMenuOpenLayerDetails" [slideMenuOpenTools]="slideMenuOpenTools" [clickedDocFeatures]="clickedDocFeatures"
[slideMenuOpenJobDescription]="slideMenuOpenJobDescription" [slideMenuOpenSiteAdminDetails]="slideMenuOpenSiteAdminDetails"
[slideMenuOpenFolder]="slideMenuOpenFolder" [zoomedJobIds]="zoomedJobIds" [loginUserId]="loginUserId"
(ontoggleClicked)="onSlideToggleClicked($event)"></app-dpr-menus>
<!-- Slide folder Ends -->
</div>

<!--Profile fill Modal-->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#profileModal1" hidden id="btnModalProfile1"
  aria-label="button modal"></button>
<div class="modal fade" id="profileModal1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="profileModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="profileModalLabel">
          <span>Profile Incomplete</span>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        Please complete your
        <a routerLink="/myprofile" data-bs-dismiss="modal" aria-label="Close">profile</a>
        details!
      </div>
    </div>
  </div>
</div>

<!--Measurements Modal-->
<div class="modal fade" id="MeasurementsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="MeasurementModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="MeasurementsModalLabel">
          <span>Measurements Tools No Access</span>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" id="MeasurementModalLabel">
        Measurement tools have been set as off. Please change it in My
        preferences to view the same.
      </div>
    </div>
  </div>
</div>

<!--Map photo Modal -->
<!-- <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#featurePhotoViewer" hidden id="btnModalPhoto"
  aria-labelledby="btnModalPhoto"></button>
<div class="modal fade" id="featurePhotoViewer" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="featurePhotoViewerLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="featurePhotoViewerLabel"><span>Photo</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>
          <span class="pe-4">Site ID: <span class="ps-1" id="siteId"></span></span>
          <span>WO No.: <span id="woNo" class="ps-1"></span></span>
        </p>
        <hr>
        <img id="imgPhoto" class="img-fluid" alt="Photo" />
      </div>
    </div>
  </div>
</div> -->



<!-- ADD SITE MODAL -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addSite" hidden id="btnAddSite" aria-labelledby="btnAddSite"></button>
<div class="modal fade" id="addSite" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="addSiteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="addSiteLabel" *ngIf="!editSiteForm">Add Site</h5>
        <h5 class="modal-title custom-modal-title" id="addSiteLabel" *ngIf="editSiteForm">Edit Site</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="cancelSiteEdit()"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="siteAddForm">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="mb-3">
              <label for="addSite_siteName" class="form-label form-label-sm">Site Name <span class="custom-form-label-star">*</span></label>
              <input type="text" class="form-control form-control-sm" id="addSite_siteName" formControlName="siteName"  placeholder="Site Name">
            </div>

          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="mb-3">
              <label for="addSite_description" class="form-label form-label-sm">Description</label>
              <input type="text" class="form-control form-control-sm" id="addSite_description" formControlName="description"  placeholder="Description">
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="list-showing-parent mb-3" *ngIf="loginUserRole == 'Admin' && slideMenuOpenSiteAdminDetails">
              <label
                for="exampleFormControlInput1"
                class="form-label custom-label"
                >User Email address
                <span class="custom-form-label-star">*</span></label
              >
              <input
                type="email"
                class="form-control"
                id="searchUser"
                formControlName="searchedUserEmail"
                (keyup)="searchUserByMail($event)"
                placeholder="name@example.com"
              />
              <ul
                class="list-showing"
                [ngClass]="{
                  'fetched-emails-diplay': shouldSiteFetchedEmailsBeDisplayed
                }"
              >
                <li
                  *ngFor="let item of fetchedSiteEmails; let i = index"
                  (click)="selectEmailFromSite(item)"
                >
                  {{ item.emailId }}
                </li>
              </ul>
            </div>
          </div>

          <!-- <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="mb-3">
              <label for="addSite_Address" class="form-label form-label-sm">Address</label>
              <input type="text" class="form-control form-control-sm" id="addSite_Address" formControlName="address"  placeholder="Address">
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="mb-3">
              <label for="addSite_city" class="form-label form-label-sm">Country</label>
              <select class="form-control form-control-sm" (change)="populateState(siteAddForm.value.country)" formControlName="country">
                <option [value]="null">Select Country</option>
                <option *ngFor="let c of country" [value]="c.name">{{ c.name }}</option>
              </select>
             </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="mb-3">
              <label for="addSite_state" class="form-label form-label-sm">State </label>
              <select class="form-control form-control-sm" (change)="populateCity(siteAddForm.value.state)" formControlName="state">
                <option value="null">Select State</option>
                <option *ngFor="let s of dropdownState" [value]="s.name">{{ s.name }}</option>
              </select>

            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="mb-3">
              <label for="addSite_country" class="form-label form-label-sm">City</label>
              <select class="form-control form-control-sm" formControlName="city">
                <option value="null">Select City</option>
                <option *ngFor="let c of dropdownCity" [value]="c.name">{{ c.name }}</option>
              </select>
            </div>
          </div> -->

        </div>
       </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="modal" (click)="cancelSiteEdit()">Cancel</button>
        <button type="button" *ngIf="!editSiteForm" class="btn btn-primary btn-sm" (click)="addSiteSubmit()" data-bs-dismiss="modal" aria-label="Close" [ngClass]="{'disabled':!siteAddForm.value.siteName}">Add</button>
        <button type="button" *ngIf="editSiteForm" class="btn btn-primary btn-sm" (click)="updateSiteInfo()" data-bs-dismiss="modal" aria-label="Close" [ngClass]="{'disabled':!siteAddForm.value.siteName}">Update</button>
      </div>
    </div>
  </div>
</div>

<!-- ADD SUB SITE MODAL -->
<div class="modal fade" id="addSubSite" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="addSubSiteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="addSubSiteLabel">{{subSiteModalHeader}} Subsite</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #subSiteModalClose (click)="subSiteModalHeader = 'Add'"></button>
      </div>
      <div class="modal-body">
        <form class="row g-3 mt-3" name="form" #f="ngForm" id="subSiteForm" (ngSubmit)="f.form.valid && onSubSiteSubmit()"
          novalidate>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="mb-3">
                <label for="addSubSite_selectSite" class="form-label form-label-sm">Select Site <span
                    class="custom-form-label-star">*</span></label>
                <select class="form-select" aria-label="Default select example" id="addSubSite_selectSite"
                  name="siteSelectedFromSubSiteDropdown"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="subSiteModalData.siteSelectedFromSubSiteDropdown" (change)="onSiteChange($event.target)"
                  required
                  #siteSelectedFromSubSiteDropdown="ngModel"
                  [ngClass]="{ 'is-invalid': f.submitted && siteSelectedFromSubSiteDropdown.errors }">
                  <option value="">
                    Select Site
                  </option>
                  <option *ngFor="let subSiteOption of siteData" [value]="subSiteOption.siteId">
                    {{ subSiteOption.siteName }}
                  </option>
                </select>
                <div *ngIf="f.submitted && siteSelectedFromSubSiteDropdown.errors" class="invalid-feedback">
                  <div *ngIf="siteSelectedFromSubSiteDropdown.errors['required']">Please select site</div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="mb-3">
                <label for="addSubSite_subsiteName" class="form-label form-label-sm">Subsite Name <span
                    class="custom-form-label-star">*</span></label>
                <input type="text" class="form-control form-control-sm" id="addSubSite_subsiteName"
                  placeholder="Subsite Name"
                  name="subSiteName"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="subSiteModalData.subSiteName"
                  required
                  #subSiteName="ngModel"
                  [ngClass]="{ 'is-invalid': f.submitted && subSiteName.errors }">
                  <div *ngIf="f.submitted && subSiteName.errors" class="invalid-feedback">
                    <div *ngIf="subSiteName.errors['required']">Subsite name is required</div>
                  </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="mb-3">
                <label for="addSubSite_description" class="form-label form-label-sm">Description</label>
                <input type="text" class="form-control form-control-sm" id="addSubSite_description"
                  placeholder="Description"
                  name="addSubSite_description"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="subSiteModalData.description"
                  >
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="mb-3" *ngIf="loginUserRole == 'Admin' && slideMenuOpenSiteAdminDetails">
                <label
                  for="searchUserSubsite"
                  class="form-label form-label-sm"
                  >User Email address
                  <span class="custom-form-label-star">*</span></label
                >
                <input
                  type="text"
                  class="form-control form-control-sm disabled"
                  id="searchUserSubsite"
                  name="searchUserSubsite"
                  placeholder="name@example.com"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="subSiteModalData.searchedUserEmail"
                />
                <!-- <ul
                  class="list-showing"
                  [ngClass]="{
                    'fetched-emails-diplay': shouldSiteFetchedEmailsBeDisplayed
                  }"
                >
                  <li
                    *ngFor="let item of fetchedSiteEmails; let i = index"
                    (click)="selectEmailFromSite(item)"
                  >
                    {{ item.emailId }}
                  </li>
                </ul> -->
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="modal" (click)="subSiteModalHeader = 'Add'">Cancel</button>
            <button type="submit" class="btn btn-primary btn-sm" [ngClass]="{'disabled':!subSiteModalData.subSiteName || !subSiteModalData.siteSelectedFromSubSiteDropdown}">{{subSiteModalHeader}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- ADD JOB MODAL -->
<div class="modal fade" id="addJob" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="addJobLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="addJobLabel">Add Job</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="mb-3">
              <label for="addJob_woNumber" class="form-label form-label-sm">WO Number <span class="custom-form-label-star">*</span></label>
              <input type="text" class="form-control form-control-sm" id="addJob_woNumber" placeholder="WO Number">
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="mb-3">
              <label for="addJob_projectManager" class="form-label form-label-sm">Project Manager</label>
              <input type="text" class="form-control form-control-sm" id="addJob_projectManager" placeholder="Project Manager">
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="mb-3">
              <label for="addJob_siteName" class="form-label form-label-sm">Site Name</label>
              <input type="text" class="form-control form-control-sm" id="addJob_siteName" placeholder="Site Name">
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="mb-3">
              <label for="addJob_subsiteName" class="form-label form-label-sm">Subsite Name</label>
              <input type="text" class="form-control form-control-sm" id="addJob_subsiteName" placeholder="Subsite Name">
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="mb-3">
              <label for="addJob_projectName" class="form-label form-label-sm">Project Name</label>
              <input type="text" class="form-control form-control-sm" id="addJob_projectName" placeholder="Project Name">
            </div>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary btn-sm">Add</button>
      </div>
    </div>
  </div>
</div>

<!-- Warning MODAL -->
<button class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#warningModal" id="btnWarningModal"></button>
<div class="modal fade" id="warningModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="warningModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="warningModalLabel">Subsite warning</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <p id="warningPData"></p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>


<!--- View Site or SubSite Details Modal -->
<div
  class="modal fade"
  id="staticSiteSubSiteDataBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticSiteSubSiteDataBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable">

      <div class="modal-content">
        <!-- <form aria-required="true"> -->
        <div class="modal-header">
          <h5 class="modal-title custom-modal-title" id="staticSiteSubSiteDataBackdropLabel">
            <span *ngIf="viewSiteOrSubSiteStatus == 'site'">Site - {{siteOrSubSiteDetailsByClick?.siteName}}</span><span *ngIf="viewSiteOrSubSiteStatus == 'subsite'">Subsite - {{siteOrSubSiteDetailsByClick?.subSiteName}}</span>
          </h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"

          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
            <div class="custom-map-tooltip">
              <div class="table-responsive">
                <table class="table table-bordered table-striped custom-table mb-0">
                  <tbody>
                    <tr>
                      <td>Description</td>
                      <td>{{siteOrSubSiteDetailsByClick?.description}}</td>
                    </tr>
                    <!-- <tr *ngIf="viewSiteOrSubSiteStatus == 'site'">
                      <td>Address</td>
                      <td>{{siteOrSubSiteDetailsByClick?.address}}</td>
                    </tr> -->
                    <!-- <tr *ngIf="viewSiteOrSubSiteStatus == 'site'">
                      <td>City</td>
                      <td>{{siteOrSubSiteDetailsByClick?.city}}</td>
                    </tr> -->
                    <!-- <tr *ngIf="viewSiteOrSubSiteStatus == 'site'">
                      <td>State</td>
                      <td>{{siteOrSubSiteDetailsByClick?.state}}</td>
                    </tr> -->
                    <!-- <tr *ngIf="viewSiteOrSubSiteStatus == 'site'">
                      <td>Country</td>
                      <td>{{siteOrSubSiteDetailsByClick?.country}}</td>
                    </tr> -->
                    <tr *ngIf="viewSiteOrSubSiteStatus == 'site' && subSiteNameDetailForSitePopup != undefined ">
                      <td><strong>SubSite</strong></td>
                      <td>
                      <ng-container *ngFor="let obj of subSiteNameDetailForSitePopup;">
                      <p class="text-black text-decoration-none">{{obj.subSiteName}}</p>
                       </ng-container>
                    </td>
                    </tr>
                    <tr>
                      <td><strong>Jobs</strong></td>
                      <td>
                    <ng-container *ngFor="let obj of jobDetailForSitePopup;">
                      <p class="text-black text-decoration-none"  *ngIf="obj.isAssociated">{{obj.jobName}}</p>
                    </ng-container>
                    </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"

          >
            Cancel
          </button>
          <button *ngIf="viewSiteOrSubSiteStatus == 'site'"
            (click)="openModalforSiteShareJob()"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"

          >
            Share
          </button>
          <button *ngIf="viewSiteOrSubSiteStatus == 'subsite'"
            (click)="openModalforSubSiteShareJob()"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"

          >
            Share
          </button>
        </div>
      <!-- </form> -->
      </div>
  </div>
</div>
<!--- View Site or SubSite Details Modal -->


<!--- Site Share Modal -->
<div
  class="modal fade"
  id="staticSiteBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticSiteBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <form aria-required="true">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title custom-modal-title" id="staticSiteBackdropLabel">
            Share Site
          </h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="closeSiteShareModal()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div
                class="col-lg-12 shared-content"
                *ngIf="objSharedUserListForSiteSubSite.length != 0"
              >
                <div class="parent-wrapper">
                  <h3>SHARED WITH</h3>
                  <ul>
                    <li
                      *ngFor="
                        let userDetail of objSharedUserListForSiteSubSite;
                        let i = index
                      "
                    >
                      {{ userDetail.displayName }}
                      ({{ userDetail.emailId }}) - {{ userDetail.accessName }}
                      <span
                        class="fa fa-edit pointer"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        routerLink="/collaboration"
                      ></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="list-showing-parent mb-3">
                <label
                  for="exampleFormControlInput1"
                  class="form-label custom-label"
                  >Email address
                  <span class="custom-form-label-star">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                  [formControl]="emailFormControl"
                  placeholder="name@example.com"
                />
                <ul
                  class="list-showing"
                  [ngClass]="{
                    'fetched-emails-diplay': shouldFetchedEmailsBeDisplayed
                  }"
                >
                  <li
                    *ngFor="let item of fetchedEmails; let i = index"
                    (click)="selectEmailFromFetchedEmails(item)"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-12">
              <p class="custom-label">Access Control</p>
            </div>
            <div class="col-lg-6">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="1"
                  [formControl]="accessFormControl"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  View
                </label>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="2"
                  [formControl]="accessFormControl"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  Edit
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            (click)="closeSiteShareModal()"
          >
            Cancel
          </button>
          <button
            (click)="shareSite()"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            *appPermission="permissionEnumList.CAN_SHARE_SITE;elementShowHide:false;"
            [disabled]="emailFormControl.invalid"
          >
            Share
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<!--- Site Share Modal -->

<!--- Sub-Site Share Modal -->
<div
  class="modal fade"
  id="staticSubSiteBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticSubSiteBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <form aria-required="true">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title custom-modal-title" id="staticSubSiteBackdropLabel">
            Share Sub-Site
          </h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="closeSiteShareModal()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div
                class="col-lg-12 shared-content"
                *ngIf="objSharedUserListForSiteSubSite.length != 0"
              >
                <div class="parent-wrapper">
                  <h3>SHARED WITH</h3>
                  <ul>
                    <li
                      *ngFor="
                        let userDetail of objSharedUserListForSiteSubSite;
                        let i = index
                      "
                    >
                      {{ userDetail.displayName }}
                      ({{ userDetail.emailId }}) - {{ userDetail.accessName }}
                      <span
                        class="fa fa-edit pointer"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        routerLink="/collaboration"
                      ></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="list-showing-parent mb-3">
                <label
                  for="exampleFormControlInput1"
                  class="form-label custom-label"
                  >Email address
                  <span class="custom-form-label-star">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                  [formControl]="emailFormControl"
                  placeholder="name@example.com"
                />
                <ul
                  class="list-showing"
                  [ngClass]="{
                    'fetched-emails-diplay': shouldFetchedEmailsBeDisplayed
                  }"
                >
                  <li
                    *ngFor="let item of fetchedEmails; let i = index"
                    (click)="selectEmailFromFetchedEmails(item)"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-12">
              <p class="custom-label">Access Control</p>
            </div>
            <div class="col-lg-6">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="1"
                  [formControl]="accessFormControl"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  View
                </label>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="2"
                  [formControl]="accessFormControl"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  Edit
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            (click)="closeSubSiteShareModal()"
          >
            Cancel
          </button>
          <button
            (click)="shareSubSite()"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"            
            *appPermission="permissionEnumList.CAN_SHARE_SUBSITE;elementShowHide:false;"
            [disabled]="emailFormControl.invalid"
          >
            Share
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<!--- Sub Site Share Modal -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticSiteBackdrop" hidden id="btnModalSiteShare"
  aria-label="modal"></button>
  <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticSubSiteBackdrop" hidden id="btnModalSubSiteShare"
  aria-label="modal"></button>
  <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticSiteSubSiteDataBackdrop" hidden id="btnModalSiteSubSiteDetails"
  aria-label="modal"></button>



  <!-- Print Modal -->
<div class="modal fade custom-print-modal" id="printModalNew" data-bs-backdrop="static" data-bs-keyboard="true" tabindex="-1"
aria-labelledby="printModalLabel" aria-hidden="true">
<div class="modal-dialog modal-sm modal-dialog-centered1 modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title custom-modal-title" id="printModalLabel"><span>Print Map</span></h5>
      <button type="button" class="btn-close" (click)="printBoxDelete()" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <form [formGroup]="printForm">
      <div class="modal-body modal-body-print">
        <div class="row">
          <div class="col-lg-12">
            <!-- <div class="col-lg-12 pb-2 mb-2 border-bottom">
              <div class=" d-flex justify-content-start align-items-center">
                <button class="btn btn-sm btn-primary" aria-label="showPrintBox" (click)="onPrintShowPrintBox($event)">Show Print Box</button>
              </div>
            </div>
            <div class="error-box" *ngIf="counter === 0 && printState">
              <p class="text-danger">Please click - Show Print Box</p>
            </div> -->
            <div class="col-lg-12 pb-2 mb-2 border-bottom">
              <div class=" d-flex justify-content-start align-items-center print-label-width">
                <label for="printModalCRC" class="form-label mt-1">Coordinate System</label>
                <select class="form-select form-select-sm" aria-label="printModalCRC" formControlName="psCRS" >
                  <option value="UTM" selected>UTM</option>
                  <option value="WGS84">WGS84</option>
                  <option value="SPCS">State Plane</option>
                </select>
              </div>
            </div>
            <div class="col-lg-12 pb-2 mb-2 border-bottom">
              <div class="input-group d-flex justify-content-start align-items-center print-label-width-scale">
                <label for="printModalScale" class="form-label mt-1">Scale</label>
                <input  type="number" class="form-control form-control-sm" pattern="\d+" name="psScale"  formControlName="psScale" min="0" max="13000" minlength="1" maxlength="5" (change)="onPrintClickScale()" />
                <span class="input-group-text input-group-text-sm scale-sign" id="printModalScale"></span>
              </div>
            </div>
            <div *ngIf="printForm.controls.psScale">
              <span *ngIf="printForm.controls.psScale && !printForm.controls.psScale.valid">
                 <p class="text-danger">Enter a valid psScale</p>
              </span>
            </div>
            <div class="error-box" *ngIf="printForm.value.psScale === null">
              <p class="text-danger">{{scaleError}}</p>
            </div>
            <div class="col-lg-12 pb-2 mb-2 border-bottom">
              <div class="d-flex justify-content-start align-items-center print-label-width">
                <label for="printModalPsize" class="form-label mt-1">Paper Size</label>
                <select class="form-select form-select-sm" aria-label="printModalPsize" formControlName="psSize" (change)="onPrintClickPaperSize()">
                  <option value="letter" selected>ANSI A (8.5" x 11")</option>
                  <option value="legal">ANSI B (11" x 17")</option>
                  <option value="ansi-c">ANSI C (17" x 22")</option>
                  <option value="ansi-d">ANSI D (22" x 34")</option>
                  <option value="ansi-e">ANSI E (34" x 44")</option>
                  <!-- <option value="3">ARCH D (24" x 36")</option>
                  <option value="3">ARCH E (36" x 42</option>
                  <option value="3">ARCH E1 (30" x 42")</option>-->
                  <option value="a3">A3 (11.7" × 16.5")</option>
                  <option value="a4">A4 (8.3" × 11.7")</option>

                </select>
              </div>
            </div>
            <div class="col-lg-12 pb-2 mb-2 border-bottom d-flex justify-content-start align-items-center">
              <p class="form-label mt-1">Orientation</p>
              <div class="form-check form-check-inline" >
                <input class="form-check-input" type="radio" formControlName="psOrientation" (change)="onPrintClickOrientation()" name="psOrientation" id="printModalPortrait"
                  value="portrait">
                <label class="form-check-label" for="printModalPortrait">Portrait</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="psOrientation" (change)="onPrintClickOrientation()" id="printModalLandscape"
                  value="landscape" formControlName="psOrientation" checked>
                <label class="form-check-label" for="printModalLandscape">Landscape</label>
              </div>
            </div>
            <div class="col-lg-12 d-flex justify-content-start align-items-center">
              <p class="form-label mt-1 print-label-width-p">Format</p>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="psFormat" id="printModalPDF" value="PDF"
                  formControlName="psFormat" checked>
                <label class="form-check-label" for="printModalPDF">PDF</label>
              </div>
              <div class="form-check form-check-inline d-flex justify-content-center">
                <input class="form-check-input" type="radio" name="psFormat" id="printModalJPG" value="JPG"
                  formControlName="psFormat">
                <label class="form-check-label bug-radio-label" for="printModalJPG">JPG</label>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-5">
            <div class="print-preview d-flex justify-content-center align-items-center">
              <p>Preview window if applicable</p>
            </div>
          </div> -->

        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="printModalGPrint" value="true"
            formControlName="psPrintGrid" (click)="onGridPrintClick()">
          <label class="form-check-label" for="printModalGPrint" style="color:#000">Grid Print</label>
        </div>
        <button type="button" class="btn btn-primary" (click)="onPrintClick()"
         >Print</button>
         <!-- data-bs-dismiss="modal" -->
      </div>
    </form>
    <!-- {{printForm.value | json}} -->
  </div>
</div>
</div>
<!-- Print Modal -->


<!--Map photo Modal -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#featurePhotoViewer" hidden id="btnModalPhoto" aria-labelledby="btnModalPhoto"></button>
<div class="modal fade" id="featurePhotoViewer" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="featurePhotoViewerLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="featurePhotoViewerLabel" ><span>{{modalHeader}}</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div id="photoDiv">
          <p>
            <span class="pe-4">Site ID: <span class="ps-1" id="siteId"></span></span>
            <span>WO No.: <span id="woNo" class="ps-1"></span></span>
          </p>
          <hr/>  
          <img id="imgPhoto" class="img-fluid" alt="Photo" />
        </div>

        <ng-container   *ngIf="modalHeader != 'PHOTO'" > <!-- #externalContentTemplate -->
          <p class="external-feature-data" *ngIf="externalContentTemplateData?.siteId && externalContentTemplateData?.siteId != '0'">
            <strong>Site Id: </strong> <span>{{externalContentTemplateData?.siteId}}</span>
          </p>

          <p class="external-feature-data">
            <strong>WO No.: </strong> <span>{{externalContentTemplateData?.woNo}}</span>
          </p>

          <p class="external-feature-data" *ngIf="externalContentTemplateData?.siteId && externalContentTemplateData?.siteId != '0'">
            <strong>Site Name: </strong> <span>{{externalContentTemplateData.siteName}}</span>
          </p>
          <p class="external-feature-data">
            <strong>Feature Name: </strong> <span>{{externalContentTemplateData.featureName}} </span>
          </p>

          <div *ngIf="(modalHeader | lowercase) == 'virtual tour'">
            <!-- show VT LINKS  -->
            <app-common-table   [tableHeader]="matterportTableHeader"
              [tableData]="matterportLinkArray"
              (buttonClickEmitter)="btnShowVT($event)"              
              idColumnName="linkURL"              
              tblClass="table-striped table-colored-virtual-tour table-bordered custom-table table-colored">
            </app-common-table>
          </div>
          
          <div class="modal-footer">
            <button *ngIf="modalHeader == 'EXTERNAL LINK'" type="button" data-bs-toggle="modal" data-bs-target=""
              class="btn btn-primary btn-sm" 
              (click)="gotolink(externalContentTemplateData.featureLink)">
              Open
            </button>
            <button *ngIf="modalHeader != 'EXTERNAL LINK' && modalHeader != 'VIRTUAL TOUR'" type="button" data-bs-toggle="modal" data-bs-target="#featureExternalViewer"
              class="btn btn-primary btn-sm" >
              Open
            </button>
           <!-- <button type="button" class="btn btn-primary btn-sm">Share</button> -->
          </div>

        </ng-container>
      </div>

      <!-- <div class="modal-footer" #externalContentTemplate>
        <button type="button" data-bs-toggle="modal" data-bs-target="#featureExternalViewer" class="btn btn-primary btn-sm">Open</button>

      </div> -->
    </div>
  </div>
</div>


