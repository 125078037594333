export enum permissionEnum {
  'CAN_CREATE_USER' = 6,
  'CAN_CREATE_ROLE' = 7,
  'CAN_ASSIGN_PERMISSION_TO_USER' = 8,
  'CAN_CREATE_USERGROUP' = 9,
  'CAN_CREATE_SITE' = 10,
  'CAN_CREATE_SUBSITE' = 11,
  'CAN_CREATE_JOB' = 12,
  'CAN_CREATE_EXTERNAL_CONTENT' = 13,
  'CAN_EDIT_USER_ACCESS_OR_PERMISSION' = 14,
  'CAN_EDIT_SITE' = 15,
  'CAN_EDIT_SUBSITE' = 16,
  'CAN_EDIT_JOB' = 17,
  'CAN_EDIT_EXTERNAL_CONTENT' = 18,
  'CAN_DELETE_USER' = 19,
  'CAN_DELETE_SITE' = 20,
  'CAN_DELETE_SUBSITE' = 21,
  'CAN_DELETE_JOB' = 22,
  'CAN_DELETE_EXTERNAL_CONTENT' = 23,
  'CAN_ASSIGN_ROLE' = 24,
  'CAN_SHARE_SITE' = 25,
  'CAN_SHARE_SUBSITE' = 26,
  'CAN_SHARE_JOB' = 27,
  'CAN_SHARE_EXTERNAL_CONTENT' = 28,
  'CAN_REVOKE_ACCESS_TO_SITE' = 29,
  'CAN_REVOKE_ACCESS_TO_JOB' = 30,
  'CAN_REVOKE_ACCESS_TO_SUBSITE' = 31,
  'CAN_REVOKE_ACCESS_TO_EXTERNAL_CONTENT' = 32,
  'CAN_MANAGE_ACESS_OF_ROLE_TO_TYPE_MSG' = 33,
  'CAN_RAISE_TICKET' = 34,
  'CAN_ASSIGN_PRIORITY_TO_TICKET' = 35,
  'CAN_RESPOND_TO_TICKET' = 36,
  'CAN_CLOSE_TICKET' = 37,
  'CAN_MANAGE_NOTIFICATION' = 38,
  'CAN_RAISE_FEEDBACK' = 39,
  'CAN_MANAGE_COLLABORATION_ACCESS_TO_JOBS' = 40,
  'CAN_RESET_PASSWORD_TO_DEFAULT' = 41,
  'CAN_VIEW_AUDIT_LOGS' = 42,
  'CAN_MAIL_TO_ANY_OR_ALL_USER' = 43,
  'CAN_MANAGE_COLLABORATION_ACCESS_TO_SITE' = 44,
  'CAN_MANAGE_COLLABORATION_ACCESS_TO_SUBSITE' = 45,
  'CAN_MANAGE_COLLABORATION_ACCESS_TO_DOCUMENTS' = 46,
  'CAN_MANAGE_COLLABORATION_ACCESS_TO_DOCUMENTS_FOR_SINGLE_USER' = 47,
  'CAN_ADD_COMMENT_TO_FEEDBACK' = 48,
  'CAN_VIEW_ALL_FEEDBACK' = 49,
  'CAN_VIEW_SUBSCRIPTION_LEVEL_OF_USER' = 50,
  'CAN_EDIT_SUBSCRIPTION_LEVEL_OF_USER' = 51,
  'CAN_EDIT_SUBSCRIPTION_END_OR_START_DATE' = 52,
  'CAN_EDIT_STORAGE_SPACE_FOR_ENTRPRISE_USER_OR_USRGRP' = 53,
  'CAN_VIEW_LAST_INVOICE_DATE_OR_AMOUNT_OR_PAYMENT_DATE' = 54,
  'CAN_SPECIFY_NUMBER_FULL_LICENSE' = 55,
  'CAN_CREATE_PRIMARY_ADMINISTRATOR_OF_ENTRPRISE_ACCOUNT' = 56,
  'CAN_EDIT_PRIMARY_ADMINISTRATOR_OF_ENTRPRISE_ACCOUNT' = 57,
  'CAN_ASSIGN_MULTIPLE_ENTRPRISE_ADMINISTRATOR_ACCOUNT' = 58,
  'CAN_VIEW_ALL_DOCUMENTS' = 59,
  'CAN_VIEW_THEIR_STORAGE_CAPACITY' = 60,
  'CAN_PURCHASE_MORE_STORAGE' = 61,
  'CAN_VIEW_GPRS_DELIVERABLES' = 62,
  'CAN_VIEW_DOCUMENT_IN_THEIR_VIEW' = 63,
  'CAN_VIEW_DOCUMENT_SHARED_WITH_THEM_OR_ANOTHER_USER' = 64,
  'CAN_VIEW_DOCUMENT_IN_ENTERPRISE_ROOT_FOLDER' = 65,
  'CAN_DOWNLOAD_DOCUMENT_FROM_ENTERPRISE_ROOT_FOLDER' = 66,
  'CAN_MOVE_CONTENT_TO_ENTERPRISE_ROOT_FOLDER' = 67,
  'CAN_ADD_DOCUMENT_IN_ENTERPRISE_ROOT_FOLDER' = 68,
  'CAN_DELETE_DOCUMENT_IN_ENTERPRISE_ROOT_FOLDER' = 69,
  'CAN_ADD_DOCUMENT_TO_FOLDER' = 70,
  'CAN_CREATE_FOLDER' = 71,
  'CAN_CREATE_SUBFOLDER' = 72,
  'CAN_DELETE_ALL_FOLDERS_OR_FILES_IN_FOLDER' = 73,
  'CAN_VIEW_CONTENT_IN_GPRS_DELIVERABLES_FOLDER_SORTED_AS_PER_CLIENT' = 74,
  'CAN_ADD_CONTENT_IN_GPRS_DELIVERABLES_FOLDER_SORTED_AS_PER_CLIENT' = 75,
  'CAN_DELETE_CONTENT_IN_GPRS_DELIVERABLES_FOLDER_SORTED_AS_PER_CLIENT' = 76,
  'CAN_ADD_CONTENT_TO_CLIENT_FOLDER' = 77,
  'CAN_ADD_CONTENT_TO_GPRS_DELIVERABLES_FOLDER' = 78,
  'CAN_MOVE_CONTENT_INTO_GPRS_DELIVERABLES_FOLDER' = 79,
  'CAN_DELETE_FILES_OR_FOLDER_CREATED_BY_CLIENT' = 80,
  'CAN_MOVE_CONTENT_FROM_ONE_FOLDER_TO_ANOTHER_IN_CLIENT_FOLDER' = 81,
  'CAN_DELETE_FILES_OR_FOLDER_CREATED_BY_GPRS' = 82,
  'CAN_GEOLOCATE_FILES_OR_FOLDER' = 83,
  'CAN_DISASSOCIATE_FILES_OR_FOLDER_FOR_CLIENTS' = 84,
  'CAN_DISASSOCIATE_FILES_OR_FOLDER_FOR_GPRS_DELIVERABLES' = 85,
  'CAN_SHARE_FILES_OR_FOLDERS' = 86,
  'CAN_PROVIDE_ACCESS_TYPE_TO_SHARE_FILES_OR_FOLDERS' = 87,
  'CAN_DOWNLOAD_FILE_OR_FOLDER' = 88,
  'CAN_PRINT_FILE_OR_FOLDER' = 89,
  'CAN_ASSOCIATE_JOB_TO_SITE' = 90,
  'CAN_DISASSOCIATE_JOB_TO_SITE' = 91,
  'CAN_ASSIGN_EXTERNAL_CONTENT_TO_UNIVERSAL_WO_NUMBER' = 92,
  'CAN_ASSOCIATE_DOCUMENT_TO_SITE' = 93,
  'CAN_DISSOCIATE_DOCUMENT_TO_SITE' = 94,
  'CAN_ASSOCIATE_JOB_TO_SUBSITE' = 95,
  'CAN_DISSOCIATE_JOB_TO_SUBSITE' = 96,
  'CAN_ASSOCIATE_DOCUMENT_TO_SUBSITE' = 97,
  'CAN_DISSOCIATE_DOCUMENT_TO_SUBSITE' = 98,
  'CAN_VIEW_SITE_ON_MAP' = 99,
  'CAN_VIEW_SUBSITE_ON_MAP' = 100,
  'CAN_CREATE_SITE_ON_MAP' = 101,
  'CAN_CREATE_SUBSITE_ON_MAP' = 102,
  'CAN_DELETE_SITE_ON_MAP' = 103,
  'CAN_DELETE_SUBSITE_ON_MAP' = 104,
  'CAN_CREATE_JOB_ON_MAP' = 105,
  'CAN_EDIT_LINE_OR_POINTS_OF_LAYER_IN_MAP' = 106,
  'CAN_VIEW_JOB_ON_MAP' = 107,
  'CAN_EDIT_SITE_ON_MAP' = 108,
  'CAN_EDIT_SUBSITE_ON_MAP' = 109,
  'CAN_EDIT_JOB_ON_MAP' = 110,
  'CAN_CREATE_POINT_ON_MAP' = 111,
  'CAN_IMPORT_LAYER_ON_MAP' = 112,
  'CAN_EDIT_POINT_ON_MAP' = 113,
  'CAN_TOGGLE_BETWEEN_LAYER_ON_MAP' = 114,
  'CAN_EDIT_LINE_OR_POINTS_OF_LAYER_IN_MAP_DUPLICATE' = 115,
  'CAN_EDIT_CLIENT_LAYER_ON_MAP' = 116,
  'CAN_EDIT_GPRS_LAYER_ON_MAP' = 117,
  'CAN_VIEW_CLIENT_LAYER_ON_MAP' = 118,
  'CAN_VIEW_GPRS_LAYER_ON_MAP' = 119,
  'CAN_DELETE_POINT_ON_MAP' = 120,
  'CAN_EDIT_FEATURE_ATTRIBUTES' = 121,
  'CAN_CREATE_LINES_ON_MAP' = 122,
  'CAN_EDIT_LINES_ON_MAP' = 123,
  'CAN_DELETE_LINES_ON_MAP' = 124,
  'CAN_EDIT_IMPORTED_LAYER_ON_MAP' = 125,
  'CAN_CARRYOUT_MEASUREMENT_ON_MAP' = 126,
  'CAN_EXPORT_MAP' = 127,
  'CAN_SHARE_COMPLETE_OR_PART_OF_MAP_WITH_VIEW_OR_EDIT_ACCESS' = 128,
  'CAN_ACCESS_MATTERPORT_CONTENT_FROM_ADDED_LINK' = 129,
  'CAN_SHARE_COMPLETE_OR_PART_OF_MAP_WITH_EDIT_ACCESS' = 130,
  'CAN_SCHEDULE_JOB' = 131,
  'CAN_RESCHEDULE_JOB' = 132,
  'CAN_WHITELABEL_PRINTED_OUTPUT' = 133,
  'CAN_VIEW_PHOTOS_ICON_ASSOCIATED_TO_MAP' = 134,
  'CAN_VIEW_DOCUMENT_ASSOCIATED_WITH_MAP_EXTENT' = 135,
  'CAN_ADD_EXTERNAL_CONTENT_TO_MAP_FOR_THEIR_RESPECTIVE_LAYER' = 136,
  'CAN_EDIT_EXTERNAL_CONTENT_TO_MAP_FOR_THEIR_RESPECTIVE_LAYER' = 137,
  'CAN_EDIT_POSITION_OF_EXTERNAL_CONTENT_ON_MAP' = 138,
  'CAN_EDIT_IMPORT_PDF_OR_SUPERIMPOSED_IMAGES_ON_MAP' = 139,
  'CAN_SET_TRANSPARENCY_LEVEL_OF_IMPORTED_IMAGES' = 140,
  'CAN_IMPORT_GEOREFERENCED_FILE_TYPES' = 141,
  'CAN_IMPORT_NON_GEOREFERENCED_FILE_TYPES' = 142,
  'CAN_CONVERT_NON_GEOREFERENCED_FILE_TYPES' = 143,
  'CAN_EDIT_REFERENCE_LAYERS' = 144,
  'CAN_ROLLBACK_CHANGES_MADE_TO_JOB_OR_SITE' = 145,
  'CAN_PRINT_THE_WHOLE_OR_SECTION_OF_MAP' = 146,
  'CAN_SET_NOTIFICATION_PREFERENCE' = 147,
  'CAN_SET_MULTI_FILE_DOWNLOAD_PREFERENCE' = 148,
  'CAN_SET_LANDING_PAGE_PREFERENCE' = 149,
  'CAN_SET_NO_OF_JOBS_OR_FILES_TO_SHOWN_IN_DASHBOARD' = 150,
  'CAN_CHANGE_PROFILE_PICTURE' = 151,
  'CAN_VIEW_COMPANY_ADMINISTRATOR_DETAIL' = 152,
  'CAN_EDIT_USER_MOBILE_OR_NAME' = 153,
  'CAN_INTEGRATE_INSTRUMENT_VIA_BLUETOOTH_WIFI_NFC' = 154,
  'CAN_RECORD_FEATURE' = 155,
  'CAN_HAVE_ACCESS_TO_OFFLINE_MODE' = 156,
  'CAN_HAVE_ACCESS_PAYMENT_GATEWAY_IN WEBAPP' = 157,
  'CAN_CREATE_PAYMENT_INQUIRES_IN_WEBAPP' = 158,
  'CAN_PAY_BILLING_INVOICE_IN_WEBAPP' = 159,
  'CAN_VIEW_BILLING_INVOICE' = 160,
  'CAN_EDIT_INDIVIDUAL_SUBSCRIPTION_PLAN_FOR_INDIVIDUAL' = 161,
  'CAN_VIEW_SUBSCRIPTION_START_AND_END_DATE' = 162,
  'CAN_VIEW_LIST_OF_ALL_USERS_IN_SUBSCRIPTION_PLAN' = 163,
  'USER_CAN_ASSIGN_OTHER_ENTRPRISE_ADMINISTRATOR' = 164,
  'CAN_EDIT_SUBSCRIPTION_PLAN_FOR_ENTERPRISE' = 165,
  'CAN_REMOVE_USER_FROM_SUBSCRIPTION_PLAN_IF_USER_IS_PART_OF_SAME_SUBSCRIPTION_PLAN' = 166,
  'CAN_VIEW_HELP_DOCUMENTS_AND_VIDEOS' = 167,
  'CAN_UPLOAD_HELP_DOCUMENTS_AND_VIDEOS' = 168,
  'CAN_REMOVE_CONTENT_FROM_HELP_MODULE' = 169,
  'CAN_DOWNLOAD_HELP_DOCUMENTS' = 170,
  'CAN_CREATE_MESSAGE_FOR_MESSAGE_BOARD' = 171,
  'CAN_CHOOSE_WHICH_USER_GROUP_MESSAGE_TO_BE_SENT' = 172,
  'CAN_SELECT_GROUP_FOR_TARGETED_MESSAGE' = 173,
  'CAN_DELETE_MESSAGE_FOR_MESSAGE_BOARD' = 174,
  'CAN_EDIT_MESSAGE_FOR_MESSAGE_BOARD' = 175,
  
}

export class UserPermissionConstant {

  userPermissionArr:any = []
  rolePermissionAssignToUser:any = [];

  constructor() {
  }
  
  checkUserPermissionAssignedOrNot(permissionId: number) { // return true if permission exist
    if (sessionStorage.getItem('PermissionObj')) {
      this.userPermissionArr = JSON.parse(sessionStorage.getItem('PermissionObj') || '{}');
    }
    let userPermissionExist = this.userPermissionArr.find((item: any) => item.permissionId === permissionId);
    if (userPermissionExist == undefined) {
      return false;
    } else {
      return true;
    }
  }

  

  UserRoleAssignPermission(){
    
     const UserRoleAssignPermission = {
      'CAN_CREATE_USER':this.checkUserPermissionAssignedOrNot(6),
      'CAN_CREATE_ROLE':this.checkUserPermissionAssignedOrNot(7),
      'CAN_ASSIGN_PERMISSION_TO_USER':this.checkUserPermissionAssignedOrNot(8),
      'CAN_CREATE_USERGROUP':this.checkUserPermissionAssignedOrNot(9),
      'CAN_CREATE_SITE':this.checkUserPermissionAssignedOrNot(10),
      'CAN_CREATE_SUBSITE':this.checkUserPermissionAssignedOrNot(11),
      'CAN_CREATE_JOB':this.checkUserPermissionAssignedOrNot(12),
      'CAN_CREATE_EXTERNAL_CONTENT':this.checkUserPermissionAssignedOrNot(13),
      'CAN_EDIT_USER_ACCESS_OR_PERMISSION':this.checkUserPermissionAssignedOrNot(14),
      'CAN_EDIT_SITE':this.checkUserPermissionAssignedOrNot(15),
      'CAN_EDIT_SUBSITE':this.checkUserPermissionAssignedOrNot(16),
      'CAN_EDIT_JOB':this.checkUserPermissionAssignedOrNot(17),
      'CAN_EDIT_EXTERNAL_CONTENT':this.checkUserPermissionAssignedOrNot(18),
      'CAN_DELETE_USER':this.checkUserPermissionAssignedOrNot(19),
      'CAN_DELETE_SITE':this.checkUserPermissionAssignedOrNot(20),
      'CAN_DELETE_SUBSITE':this.checkUserPermissionAssignedOrNot(21),
      'CAN_DELETE_JOB':this.checkUserPermissionAssignedOrNot(22),
      'CAN_DELETE_EXTERNAL_CONTENT':this.checkUserPermissionAssignedOrNot(23),
      'CAN_ASSIGN_ROLE':this.checkUserPermissionAssignedOrNot(24),
      'CAN_SHARE_SITE':this.checkUserPermissionAssignedOrNot(25),
      'CAN_SHARE_SUBSITE':this.checkUserPermissionAssignedOrNot(26),
      'CAN_SHARE_JOB':this.checkUserPermissionAssignedOrNot(27),
      'CAN_SHARE_EXTERNAL_CONTENT':this.checkUserPermissionAssignedOrNot(28),
      'CAN_REVOKE_ACCESS_TO_SITE':this.checkUserPermissionAssignedOrNot(29),
      'CAN_REVOKE_ACCESS_TO_JOB':this.checkUserPermissionAssignedOrNot(30),
      'CAN_REVOKE_ACCESS_TO_SUBSITE':this.checkUserPermissionAssignedOrNot(31),
      'CAN_REVOKE_ACCESS_TO_EXTERNAL_CONTENT':this.checkUserPermissionAssignedOrNot(32),
      'CAN_MANAGE_ACESS_OF_ROLE_TO_TYPE_MSG':this.checkUserPermissionAssignedOrNot(33),
      'CAN_RAISE_TICKET':this.checkUserPermissionAssignedOrNot(34),
      'CAN_ASSIGN_PRIORITY_TO_TICKET':this.checkUserPermissionAssignedOrNot(35),
      'CAN_RESPOND_TO_TICKET':this.checkUserPermissionAssignedOrNot(36),
      'CAN_CLOSE_TICKET':this.checkUserPermissionAssignedOrNot(37),
      'CAN_MANAGE_NOTIFICATION':this.checkUserPermissionAssignedOrNot(38),
      'CAN_RAISE_FEEDBACK':this.checkUserPermissionAssignedOrNot(39),
      'CAN_MANAGE_COLLABORATION_ACCESS_TO_JOBS':this.checkUserPermissionAssignedOrNot(40),
      'CAN_RESET_PASSWORD_TO_DEFAULT':this.checkUserPermissionAssignedOrNot(41),
      'CAN_VIEW_AUDIT_LOGS':this.checkUserPermissionAssignedOrNot(42),
      'CAN_MAIL_TO_ANY_OR_ALL_USER':this.checkUserPermissionAssignedOrNot(43),
      'CAN_MANAGE_COLLABORATION_ACCESS_TO_SITE':this.checkUserPermissionAssignedOrNot(44),
      'CAN_MANAGE_COLLABORATION_ACCESS_TO_SUBSITE':this.checkUserPermissionAssignedOrNot(45),
      'CAN_MANAGE_COLLABORATION_ACCESS_TO_DOCUMENTS':this.checkUserPermissionAssignedOrNot(46),
      'CAN_MANAGE_COLLABORATION_ACCESS_TO_DOCUMENTS_FOR_SINGLE_USER':this.checkUserPermissionAssignedOrNot(47),
      'CAN_ADD_COMMENT_TO_FEEDBACK':this.checkUserPermissionAssignedOrNot(48),
      'CAN_VIEW_ALL_FEEDBACK':this.checkUserPermissionAssignedOrNot(49),
      'CAN_VIEW_SUBSCRIPTION_LEVEL_OF_USER':this.checkUserPermissionAssignedOrNot(50),
      'CAN_EDIT_SUBSCRIPTION_LEVEL_OF_USER':this.checkUserPermissionAssignedOrNot(51),
      'CAN_EDIT_SUBSCRIPTION_END_OR_START_DATE':this.checkUserPermissionAssignedOrNot(52),
      'CAN_EDIT_STORAGE_SPACE_FOR_ENTRPRISE_USER_OR_USRGRP':this.checkUserPermissionAssignedOrNot(53),
      'CAN_VIEW_LAST_INVOICE_DATE_OR_AMOUNT_OR_PAYMENT_DATE':this.checkUserPermissionAssignedOrNot(54),
      'CAN_SPECIFY_NUMBER_FULL_LICENSE':this.checkUserPermissionAssignedOrNot(55),
      'CAN_CREATE_PRIMARY_ADMINISTRATOR_OF_ENTRPRISE_ACCOUNT':this.checkUserPermissionAssignedOrNot(56),
      'CAN_EDIT_PRIMARY_ADMINISTRATOR_OF_ENTRPRISE_ACCOUNT':this.checkUserPermissionAssignedOrNot(57),
      'CAN_ASSIGN_MULTIPLE_ENTRPRISE_ADMINISTRATOR_ACCOUNT':this.checkUserPermissionAssignedOrNot(58),
      'CAN_VIEW_ALL_DOCUMENTS':this.checkUserPermissionAssignedOrNot(59),
      'CAN_VIEW_THEIR_STORAGE_CAPACITY':this.checkUserPermissionAssignedOrNot(60),
      'CAN_PURCHASE_MORE_STORAGE':this.checkUserPermissionAssignedOrNot(61),
      'CAN_VIEW_GPRS_DELIVERABLES':this.checkUserPermissionAssignedOrNot(62),
      'CAN_VIEW_DOCUMENT_IN_THEIR_VIEW':this.checkUserPermissionAssignedOrNot(63),
      'CAN_VIEW_DOCUMENT_SHARED_WITH_THEM_OR_ANOTHER_USER':this.checkUserPermissionAssignedOrNot(64),
      'CAN_VIEW_DOCUMENT_IN_ENTERPRISE_ACCOUNT':this.checkUserPermissionAssignedOrNot(65),
      'CAN_DOWNLOAD_DOCUMENT_FROM_ENTERPRISE_ROOT_FOLDER':this.checkUserPermissionAssignedOrNot(66),
      'CAN_MOVE_CONTENT_TO_ENTERPRISE_ROOT_FOLDER':this.checkUserPermissionAssignedOrNot(67),
      'CAN_ADD_DOCUMENT_IN_ENTERPRISE_ROOT_FOLDER':this.checkUserPermissionAssignedOrNot(68),
      'CAN_DELETE_DOCUMENT_IN_ENTERPRISE_ROOT_FOLDER':this.checkUserPermissionAssignedOrNot(69),
      'CAN_ADD_DOCUMENT_TO_FOLDER':this.checkUserPermissionAssignedOrNot(70),
      'CAN_CREATE_FOLDER':this.checkUserPermissionAssignedOrNot(71),
      'CAN_CREATE_SUBFOLDER':this.checkUserPermissionAssignedOrNot(72),
      'CAN_DELETE_ALL_FOLDERS_OR_FILES_IN_FOLDER':this.checkUserPermissionAssignedOrNot(73),
      'CAN_VIEW_CONTENT_IN_GPRS_DELIVERABLES_FOLDER_SORTED_AS_PER_CLIENT':this.checkUserPermissionAssignedOrNot(74),
      'CAN_ADD_CONTENT_IN_GPRS_DELIVERABLES_FOLDER_SORTED_AS_PER_CLIENT':this.checkUserPermissionAssignedOrNot(75),
      'CAN_DELETE_CONTENT_IN_GPRS_DELIVERABLES_FOLDER_SORTED_AS_PER_CLIENT':this.checkUserPermissionAssignedOrNot(76),
      'CAN_ADD_CONTENT_TO_CLIENT_FOLDER':this.checkUserPermissionAssignedOrNot(77),
      'CAN_ADD_CONTENT_TO_GPRS_DELIVERABLES_FOLDER':this.checkUserPermissionAssignedOrNot(78),
      'CAN_MOVE_CONTENT_INTO_GPRS_DELIVERABLES_FOLDER':this.checkUserPermissionAssignedOrNot(79),
      'CAN_DELETE_FILES_OR_FOLDER_CREATED_BY_CLIENT':this.checkUserPermissionAssignedOrNot(80),
      'CAN_MOVE_CONTENT_FROM_ONE_FOLDER_TO_ANOTHER_IN_CLIENT_FOLDER':this.checkUserPermissionAssignedOrNot(81),
      'CAN_DELETE_FILES_OR_FOLDER_CREATED_BY_GPRS':this.checkUserPermissionAssignedOrNot(82),
      'CAN_GEOLOCATE_FILES_OR_FOLDER':this.checkUserPermissionAssignedOrNot(83),
      'CAN_DISASSOCIATE_FILES_OR_FOLDER_FOR_CLIENTS':this.checkUserPermissionAssignedOrNot(84),
      'CAN_DISASSOCIATE_FILES_OR_FOLDER_FOR_GPRS_DELIVERABLES':this.checkUserPermissionAssignedOrNot(85),
      'CAN_SHARE_FILES_OR_FOLDERS':this.checkUserPermissionAssignedOrNot(86),
      'CAN_PROVIDE_ACCESS_TYPE_TO_SHARE_FILES_OR_FOLDERS':this.checkUserPermissionAssignedOrNot(87),
      'CAN_DOWNLOAD_FILE_OR_FOLDER':this.checkUserPermissionAssignedOrNot(88),
      'CAN_PRINT_FILE_OR_FOLDER':this.checkUserPermissionAssignedOrNot(89),
      'CAN_ASSOCIATE_JOB_TO_SITE':this.checkUserPermissionAssignedOrNot(90),
      'CAN_DISASSOCIATE_JOB_TO_SITE':this.checkUserPermissionAssignedOrNot(91),
      'CAN_ASSIGN_EXTERNAL_CONTENT_TO_UNIVERSAL_WO_NUMBER':this.checkUserPermissionAssignedOrNot(92),
      'CAN_ASSOCIATE_DOCUMENT_TO_SITE':this.checkUserPermissionAssignedOrNot(93),
      'CAN_DISSOCIATE_DOCUMENT_TO_SITE':this.checkUserPermissionAssignedOrNot(94),
      'CAN_ASSOCIATE_JOB_TO_SUBSITE':this.checkUserPermissionAssignedOrNot(95),
      'CAN_DISSOCIATE_JOB_TO_SUBSITE':this.checkUserPermissionAssignedOrNot(96),
      'CAN_ASSOCIATE_DOCUMENT_TO_SUBSITE':this.checkUserPermissionAssignedOrNot(97),
      'CAN_DISSOCIATE_DOCUMENT_TO_SUBSITE':this.checkUserPermissionAssignedOrNot(98),
      'CAN_VIEW_SITE_ON_MAP':this.checkUserPermissionAssignedOrNot(99),
      'CAN_VIEW_SUBSITE_ON_MAP':this.checkUserPermissionAssignedOrNot(100),
      'CAN_CREATE_SITE_ON_MAP':this.checkUserPermissionAssignedOrNot(101),
      'CAN_CREATE_SUBSITE_ON_MAP':this.checkUserPermissionAssignedOrNot(102),
      'CAN_DELETE_SITE_ON_MAP':this.checkUserPermissionAssignedOrNot(103),
      'CAN_DELETE_SUBSITE_ON_MAP':this.checkUserPermissionAssignedOrNot(104),
      'CAN_CREATE_JOB_ON_MAP':this.checkUserPermissionAssignedOrNot(105),
      'CAN_EDIT_LINE_OR_POINTS_OF_LAYER_IN_MAP':this.checkUserPermissionAssignedOrNot(106),
      'CAN_VIEW_JOB_ON_MAP':this.checkUserPermissionAssignedOrNot(107),
      'CAN_EDIT_SITE_ON_MAP':this.checkUserPermissionAssignedOrNot(108),
      'CAN_EDIT_SUBSITE_ON_MAP':this.checkUserPermissionAssignedOrNot(109),
      'CAN_EDIT_JOB_ON_MAP':this.checkUserPermissionAssignedOrNot(110),
      'CAN_CREATE_POINT_ON_MAP':this.checkUserPermissionAssignedOrNot(111),
      'CAN_IMPORT_LAYER_ON_MAP':this.checkUserPermissionAssignedOrNot(112),
      'CAN_EDIT_POINT_ON_MAP':this.checkUserPermissionAssignedOrNot(113),
      'CAN_TOGGLE_BETWEEN_LAYER_ON_MAP':this.checkUserPermissionAssignedOrNot(114),
      'CAN_EDIT_LINE_OR_POINTS_OF_LAYER_IN_MAP_DUPLICATE':this.checkUserPermissionAssignedOrNot(115),
      'CAN_EDIT_CLIENT_LAYER_ON_MAP':this.checkUserPermissionAssignedOrNot(116),
      'CAN_EDIT_GPRS_LAYER_ON_MAP':this.checkUserPermissionAssignedOrNot(117),
      'CAN_VIEW_CLIENT_LAYER_ON_MAP':this.checkUserPermissionAssignedOrNot(118),
      'CAN_VIEW_GPRS_LAYER_ON_MAP':this.checkUserPermissionAssignedOrNot(119),
      'CAN_DELETE_POINT_ON_MAP':this.checkUserPermissionAssignedOrNot(120),
      'CAN_EDIT_FEATURE_ATTRIBUTES':this.checkUserPermissionAssignedOrNot(121),
      'CAN_CREATE_LINES_ON_MAP':this.checkUserPermissionAssignedOrNot(122),
      'CAN_EDIT_LINES_ON_MAP':this.checkUserPermissionAssignedOrNot(123),
      'CAN_DELETE_LINES_ON_MAP':this.checkUserPermissionAssignedOrNot(124),
      'CAN_EDIT_IMPORTED_LAYER_ON_MAP':this.checkUserPermissionAssignedOrNot(125),
      'CAN_CARRYOUT_MEASUREMENT_ON_MAP':this.checkUserPermissionAssignedOrNot(126),
      'CAN_EXPORT_MAP':this.checkUserPermissionAssignedOrNot(127),
      'CAN_SHARE_COMPLETE_OR_PART_OF_MAP_WITH_VIEW_OR_EDIT_ACCESS':this.checkUserPermissionAssignedOrNot(128),
      'CAN_ACCESS_MATTERPORT_CONTENT_FROM_ADDED_LINK':this.checkUserPermissionAssignedOrNot(129),
      'CAN_SHARE_COMPLETE_OR_PART_OF_MAP_WITH_EDIT_ACCESS':this.checkUserPermissionAssignedOrNot(130),
      'CAN_SCHEDULE_JOB':this.checkUserPermissionAssignedOrNot(131),
      'CAN_RESCHEDULE_JOB':this.checkUserPermissionAssignedOrNot(132),
      'CAN_WHITELABEL_PRINTED_OUTPUT':this.checkUserPermissionAssignedOrNot(133),
      'CAN_VIEW_PHOTOS_ICON_ASSOCIATED_TO_MAP':this.checkUserPermissionAssignedOrNot(134),
      'CAN_VIEW_DOCUMENT_ASSOCIATED_WITH_MAP_EXTENT':this.checkUserPermissionAssignedOrNot(135),
      'CAN_ADD_EXTERNAL_CONTENT_TO_MAP_FOR_THEIR_RESPECTIVE_LAYER':this.checkUserPermissionAssignedOrNot(136),
      'CAN_EDIT_EXTERNAL_CONTENT_TO_MAP_FOR_THEIR_RESPECTIVE_LAYER':this.checkUserPermissionAssignedOrNot(137),
      'CAN_EDIT_POSITION_OF_EXTERNAL_CONTENT_ON_MAP':this.checkUserPermissionAssignedOrNot(138),
       'CAN_EDIT_IMPORT_PDF_OR_SUPERIMPOSED_IMAGES_ON_MAP': this.checkUserPermissionAssignedOrNot(139),
       'CAN_SET_TRANSPARENCY_LEVEL_OF_IMPORTED_IMAGES': this.checkUserPermissionAssignedOrNot(140),
       'CAN_IMPORT_GEOREFERENCED_FILE_TYPES': this.checkUserPermissionAssignedOrNot(141),
       'CAN_IMPORT_NON_GEOREFERENCED_FILE_TYPES': this.checkUserPermissionAssignedOrNot(142),
       'CAN_CONBERT_NON_GEOREFERENCED_FILE_TYPES': this.checkUserPermissionAssignedOrNot(143),
       'CAN_EDIT_REFERENCE_LAYERS': this.checkUserPermissionAssignedOrNot(144),
       'CAN_ROLLBACK_CHANGES_MADE_TO_JOB_OR_SITE': this.checkUserPermissionAssignedOrNot(145),
       'CAN_PRINT_THE_WHOLE_OR_SECTION_OF_MAP': this.checkUserPermissionAssignedOrNot(146),
       'CAN_SET_NOTIFICATION_PREFERENCE': this.checkUserPermissionAssignedOrNot(147),
       'CAN_SET_MULTI_FILE_DOWNLOAD_PREFERENCE': this.checkUserPermissionAssignedOrNot(148),
       'CAN_SET_LANDING_PAGE_PREFERENCE': this.checkUserPermissionAssignedOrNot(149),
       'CAN_SET_NO_OF_JOBS_OR_FILES_TO_SHOWN_IN_DASHBOARD': this.checkUserPermissionAssignedOrNot(150),
       'CAN_CHANGE_PROFILE_PICTURE': this.checkUserPermissionAssignedOrNot(151),
       'CAN_VIEW_COMPANY_ADMINISTRATOR_DETAIL': this.checkUserPermissionAssignedOrNot(152),
       'CAN_EDIT_USER_MOBILE_OR_NAME': this.checkUserPermissionAssignedOrNot(153),
       'CAN_INTEGRATE_INSTRUMENT_VIA_BLUETOOTH_WIFI_NFC': this.checkUserPermissionAssignedOrNot(154),
       'CAN_RECORD_FEATURE': this.checkUserPermissionAssignedOrNot(155),

      
     
    }
    return UserRoleAssignPermission

  }

 
}

