<div class="container-fluid p-0">
    <div class="row">
      <div class="col-lg-2">
        <div class="row">
          <div class="col-lg-12 border-bottom mb-3">
            <h4>Name of the Sites</h4>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="data-list-menu">
            <ul>
              <li><a>Detroit Zoo</a></li>
              <li><a>Toledo Correctional Institute</a></li>
              <li><a>Culver Academy</a></li>
              <li><a>Lourdes College</a></li>
              <li><a>Cleveland Cliffs Toledo</a></li>
              <li><a>Terra State Community College</a></li>
              <li><a>University of Toledo - UTMC</a></li>
              <li><a>VA Ann Arbor Healthcare</a></li>
              <li><a>DOW Midland</a></li>
              <li><a>Ford Rouge Plant</a></li>
              <li><a>Pittsburgh International Airport</a></li>
              <li><a>Big Sky - Andesite Summit</a></li>
              <li><a>Owens Community College</a></li>
              <li><a>Shoreland Elementary School</a></li>
              <li><a>Cleveland Clinic - Main Campus</a></li>
              <li><a>Cleveland Community College</a></li>
            </ul>
          </div>
        </div>


      </div>
      <div class="col-lg-10">
        <div class="row">
          <div class="col-lg-5 border-bottom mb-3">
            <h4>Details</h4>
          </div>
          <div class="col-lg-7 border-bottom mb-3 d-flex justify-content-end">
            <div class="col-auto">
              <button class="btn btn-primary btn-sm">Edit Site on MAP</button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="tree">
              <ul>
                <li>
                  <div class="family">
                    <div class="person child">
                      <div class="name"><i data-bs-toggle="modal" data-bs-target="#siteDetails">Toledo
                          Correctional
                          Institute</i> <span>Address
                        </span></div>
                    </div>

                    <ul>
                      <li>
                        <div class="family">
                          <div class="person child">
                            <div class="name"><i>Subsite Name1</i></div>
                          </div>
                        </div>
                        <ul>
                          <li>
                            <div class="family">
                              <div class="person child">
                                <div class="name"><i>Job Name 1</i>
                                  <span>John Singer Sargent - Read</span>
                                  <span> Mitch Streight - Read</span>
                                  <span>Assign User</span>
                                </div>
                              </div>
                            </div>
                            <ul>
                              <li>
                                <div class="family">
                                  <div class="person child">
                                    <div class="name"><i>Job Name 6</i>
                                      <span>John Singer Sargent - Read</span>
                                      <span> Mitch Streight - Read</span>
                                      <span>Assign User</span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <div class="family">
                              <div class="person child">
                                <div class="name"><i data-bs-toggle="modal" data-bs-target="#addNewJobMap">Project
                                    Name</i>
                                  <span>Add Job</span>
                                </div>
                              </div>
                            </div>
                            <ul>
                              <li>
                                <div class="family">
                                  <div class="person child">
                                    <div class="name"><i>Job Name 6</i>
                                      <span>John Singer Sargent - Read</span>
                                      <span> Mitch Streight - Read</span>
                                      <span>Assign User</span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div class="family">
                          <div class="person child">
                            <div class="name"><i data-bs-toggle="modal" data-bs-target="#assignUser">Job Name
                                4</i>
                              <span>Assign User</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="family">
                          <div class="person child">
                            <div class="name"><i>Project Name</i>
                              <span>Add Job</span>
                            </div>
                          </div>
                        </div>
                        <ul>
                          <li>
                            <div class="family">
                              <div class="person child">
                                <div class="name"><i>Job Name 6</i>
                                  <span>John Singer Sargent - Read</span>
                                  <span> Mitch Streight - Read</span>
                                  <span>Assign User</span>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>

                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>