import { FeatureGroup } from "src/app/constants/common-model";

export default function getFeatureIndexFromGroups(groups: FeatureGroup[], featureId: number) {
  let groupIndex = -1;
  let typeIndex = -1;
  let featureIndex = -1;

  groups.some((group, groupIdx) => {
    groupIndex = groupIdx;
    return group.types.some((type, typeIdx) => {
      typeIndex = typeIdx;
      return type.features.some((feature, featureIdx) => {
        featureIndex = featureIdx;
        return parseInt(feature.featureId as string) === featureId;
      });
    });
  });

  return {
    groupIndex,
    typeIndex,
    featureIndex
  };
}
