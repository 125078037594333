<div class="row border-bottom mb-3">
  <div class="col-lg-4 col-md-12 col-sm-12">
    <div class="page-title">
      <h1>SiteMap Admin - {{ tabValue }}</h1>
    </div>
  </div>
  <div class="col-lg-8 col-md-12 col-sm-12 d-flex justify-content-end">
    <form class="row">
      <div class="col-auto search-admin">
        <div class="input-group">
          <div class="input-group custom-input-group mb-3"  *ngIf="searchBoxStatus">
            <label for="txtSearch" class="visually-hidden">Search</label>
            <input type="text" #txtSearch class="form-control" maxlength="30" [placeholder]="searchPlaceHolder"
              aria-label="Username" aria-describedby="basic-addon1" id="txtSearch"
              (input)="onSearchChange(txtSearch.value,'blur')"
              (keydown.enter)="onSearchChange(txtSearch.value,'enter')">
            <span class="input-group-text btn-secondary" id="basic-addon1"
              (click)="onSearchChange(txtSearch.value,'enter')">
              <img src="../../../../../assets/images/ico_search.svg" alt="Search" />
            </span>
          </div>
        </div>
      </div>
      <div class="col-auto btn-admin">
        <div class="input-group custom-input-group custom-button-list mb-3 ">
          <button type="button" *ngIf="tabValue === 'Sites'" class="btn btn-success btn-sm mx-1">Create Site</button>
          <button id="btn001" type="button" *ngIf="tabValue === 'Jobs'" class="btn btn-success btn-sm mx-1" 
            (click)="redirecToMapViewer()" >View
            Job on Map</button>
          <button type="button" *ngIf="tabValue === 'Jobs'" class="btn btn-success btn-sm mx-1" data-bs-toggle="modal"
            data-bs-target="#addNewJob" >Add
            Job</button>
          <button type="button" *ngIf="tabValue === 'Users'" class="btn btn-success btn-sm mx-1" data-bs-toggle="modal"
            data-bs-target="#addNewUser" (click)="onAddUserClick()">Add New
            User</button>
          <button type="button" *ngIf="tabValue === 'Roles'" class="btn btn-success btn-sm mx-1" data-bs-toggle="modal"
            data-bs-target="#addNewRole">Add New
            Role</button>
          <button type="button" *ngIf="tabValue === 'Users'" class="custom-icon-border btn-sm mx-2"
            (click)="ResetPasswordforSelectedUser()" aria-label="Reset Password">
            <i class="fa fa-lock pe-1 ps-1"></i>
          </button>
          <button type="button" *ngIf="tabValue === 'Users'" class="custom-icon-border btn-sm" aria-label="Reset"><img
              src="../../assets/images/ico_email_security_reset_info.svg" alt="Email Security Reset Info" ></button>
          <button type="button" class="custom-icon-border btn-sm mx-2" *ngIf="tabValue === 'Users'" (click)="UserAcountActiveInactiveFrSelectedUser('inactive')">
            <!-- <img src="../../assets/images/ico_delete.svg"
              alt="INACTIVE" > -->
              INACTIVE
            </button>
            <button type="button" class="custom-icon-border btn-sm mx-2" *ngIf="tabValue === 'Users'" (click)="UserAcountActiveInactiveFrSelectedUser('active')">
              <!-- <img src="../../assets/images/ico_delete.svg"
                alt="ACTIVE" > -->
                ACTIVE
              </button>
          <button type="button" *ngIf="tabValue != 'Users' && tabValue != 'Roles'" class="custom-icon-border btn-sm"
            aria-label="Share"><img src="../../assets/images/ico_share.svg" alt="Share"></button>
        </div>
      </div>
    </form>

  </div>
</div>

<div class="row">
  <div class="col-lg-12 theme-colored">
    <ul class="nav nav-tabs colored-nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="manage-sites-tab" data-bs-toggle="tab" data-bs-target="#manage-sites"
          type="button" role="tab" aria-controls="manage-sites" aria-selected="true" (click)="tabClick('sites')" disabled="true">Manage
          Sites</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="manage-jobs-tab" data-bs-toggle="tab" data-bs-target="#manage-jobs" type="button"
          role="tab" aria-controls="manage-jobs" aria-selected="false" (click)="tabClick('jobs')">Manage Jobs</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="manage-users-tab" data-bs-toggle="tab" data-bs-target="#manage-users" type="button"
          role="tab" aria-controls="manage-users" aria-selected="false" (click)="tabClick('users')">Manage
          Users</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="manage-roles-tab" data-bs-toggle="tab" data-bs-target="#manage-roles" type="button"
          role="tab" aria-controls="manage-roles" aria-selected="false" (click)="tabClick('roles')">Manage
          Roles</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="board-message-tab" data-bs-toggle="tab" data-bs-target="#board-message"
          type="button" role="tab" aria-controls="board-message" aria-selected="false" (click)="tabClick('board')">Board
          Message</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="help-module-tab" data-bs-toggle="tab" data-bs-target="#help-module" type="button"
          role="tab" aria-controls="help-module" aria-selected="false" (click)="tabClick('help')">Help Module</button>
      </li>

      <li class="nav-item" role="presentation">
        <button class="nav-link" id="miscellaneous-tab" data-bs-toggle="tab" data-bs-target="#miscellaneous"
          type="button" role="tab" aria-controls="miscellaneous" aria-selected="false"
          (click)="tabClick('miscellaneous')">Miscellaneous</button>
      </li>


    </ul>
    <div class="tab-content" id="myTabContent">
      <!--start tab for Manage Sites-->
      <div class="tab-pane fade show active" id="manage-sites" role="tabpanel" aria-labelledby="manage-sites-tab">
        <app-manage-sites></app-manage-sites>
      </div>
      <!--end tab for Manage Sites-->

      <!--start tab for Manage Jobs-->
      <div class="tab-pane fade" id="manage-jobs" role="tabpanel" aria-labelledby="manage-jobs-tab">
        <app-manage-jobs *ngIf="tabValue === 'Jobs'" [searchValue]="searchValue"
          (selectedJobDataEmitter)="getCheckedJobData($event)"></app-manage-jobs>
      </div>
      <!--end tab for Manage Jobs-->

      <!--start tab for Manage Users-->
      <div class="tab-pane fade" id="manage-users" role="tabpanel" aria-labelledby="manage-users-tab">
        <app-manage-users *ngIf="tabValue === 'Users'" [searchValue]="searchValue"
          (selectedDataEmitter)="getCheckedUserData($event)">
        </app-manage-users>
      </div>
      <!--end tab for Manage Users-->

      <!--start tab for Manage Roles-->
      <div class="tab-pane fade" id="manage-roles" role="tabpanel" aria-labelledby="manage-roles-tab">
        <app-manage-roles *ngIf="tabValue === 'Roles'" [rolesAdded]="rolesAdded" [searchValue]="searchValue">
        </app-manage-roles>
      </div>
      <!--start tab for Manage Roles-->

      <!--start tab for Board Message-->
      <div class="tab-pane fade" id="board-message" role="tabpanel" aria-labelledby="board-message-tab">
        <app-board-message *ngIf="tabValue === 'Content Management'"></app-board-message>
      </div>
      <!--start tab for Board Message-->

      <!--start tab for Help Module-->
      <div class="tab-pane fade" id="help-module" role="tabpanel" aria-labelledby="help-module-tab">
        <app-help-module *ngIf="tabValue === 'Help'"></app-help-module>
      </div>
      <!--start tab for Help Module-->

      <!--start tab for Miscellaneous -->
      <div class="tab-pane fade" id="miscellaneous" role="tabpanel" aria-labelledby="miscellaneous-tab">
        <app-miscellaneous></app-miscellaneous>
      </div>
      <!--start tab for Miscellaneous -->

    </div>
  </div>
</div>

<!-- Add New User Modal -->
<div class="modal fade" id="addNewUser" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="addNewUserLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="addNewUserLabel" ><span>Add New User</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="onCancelUserClick()"></button>
      </div>
      <div class="modal-body">
        <app-add-edit-user *ngIf="addUserClick" [submitBtnName]="'Add'" [addClick]="addUserClick"
          (cancelUserEmitter)="onCancelUserClick($event)"></app-add-edit-user>
      </div>
    </div>
  </div>
</div>



<!-- Add New Job Modal -->
<div class="modal fade" id="addNewJob" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="addNewJobLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="addNewJobLabel"><span>Add New Job </span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close Modal" #myModalClose></button>
      </div>
      <div class="modal-body">
        <form
        class="row g-3 mt-3"
        name="form"
        #f="ngForm"
        id="userForm"
        (ngSubmit)="f.form.valid && onSubmit()"
        novalidate
      >
       
    
        <div class="row">
            <div class="col-lg-6">
              <div class="row">
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="jn" class="form-label">Job Name
                      <span class="custom-form-label-star">*</span></label>
                    <input type="text" 
                    class="form-control form-control-sm" 
                    id="jn"  
                    name="jobName"  
                    [(ngModel)]="informationModalJobData.jobName"
                    [ngModelOptions]="{ standalone: true }"
                    required
                    #jobName="ngModel"
                    [ngClass]="{ 'is-invalid': f.submitted && jobName.errors }">
                    <div *ngIf="f.submitted && jobName.errors" class="invalid-feedback">
                      <div *ngIf="jobName.errors['required']">Name is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="workorderNumber" class="form-label">WO Number<span class="custom-form-label-star">*</span></label>
                    <input type="text" 
                    class="form-control form-control-sm" 
                    id="workorderNumber"
                    name="workorderNumber"
                    [(ngModel)]="informationModalJobData.workorderNumber"
                    [ngModelOptions]="{ standalone: true }"
                    >
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="customerName" class="form-label">Customer Name</label>
                    <input type="text" 
                    class="form-control form-control-sm" 
                    id="customerName"
                    name="customerName"
                    [(ngModel)]="informationModalJobData.customerName"
                    [ngModelOptions]="{ standalone: true }"
                    >
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="siteName" class="form-label">Site Name</label>
                    <input type="text" 
                    class="form-control form-control-sm" 
                    id="siteName"
                    name="siteName"
                    [(ngModel)]="informationModalJobData.siteName"
                    [ngModelOptions]="{ standalone: true }"
                    >
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="cp" class="form-label">Contact Person</label>
                    <input type="text" 
                    class="form-control form-control-sm" 
                    id="cp"
                    name="siteContact"
                    [(ngModel)]="informationModalJobData.siteContact"
                    [ngModelOptions]="{ standalone: true }"
                    >
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
    
                    <label for="txt_Email" class="form-label">Email<span class="custom-form-label-star">*</span></label>
                    <input
                      type="email"
                      class="form-control form-control-sm"
                      name="email"
                      [(ngModel)]="informationModalJobData.email"
                      [ngModelOptions]="{ standalone: true }"
                      email
                      required
                      #email="ngModel"
                      [ngClass]="{ 'is-invalid': f.submitted && email.errors }"
                      id="txt_Email"
                    />
                    <div *ngIf="f.submitted && email.errors" class="invalid-feedback">
                      <div *ngIf="email.errors['required']">Email Address is required</div>
                    </div>
                    <div *ngIf="f.submitted && email.errors" class="invalid-feedback">
                      <div *ngIf="email.errors['email']">Email Address is invalid</div>
                    </div>
    
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="phn" class="form-label">Phone Number</label>
                    <input type="text" 
                    class="form-control form-control-sm" 
                    id="phn"
                    name="phone"
                    placeholder=""
                    [(ngModel)]="informationModalJobData.phone"
                    [ngModelOptions]="{ standalone: true }"
                    >
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="assignedByUserId" 
                    class="form-label">Assigned To <span class="custom-form-label-star">*</span></label>
                    <!-- <select 
                    #dropDownTier
                    name="assignedByUserId"
                    class="form-select form-select-sm"
                    id="assignedByUserId"
                    [(ngModel)]="informationModalJobData.assignedByUserId"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="onTierSelected(dropDownTier.value)"
                    aria-label="Default select example">
                      <option value="2" selected>View</option>
                      <option value="3">Edit</option>
                    </select> -->
    
                    <input
                    type="assignedToEmailId"
                    class="form-control form-control-sm"
                    name="assignedToEmailId"
                    placeholder="xyz@gprsinc.com"
                    [(ngModel)]="informationModalJobData.assignedToEmailId"
                    [ngModelOptions]="{ standalone: true }"
                    email
                    required
                    #assignedToEmailId="ngModel"
                    [ngClass]="{ 'is-invalid': f.submitted && assignedToEmailId.errors }"
                    id="assignedByUserId"
                  />
                  <div *ngIf="f.submitted && assignedToEmailId.errors" class="invalid-feedback">
                    <div *ngIf="assignedToEmailId.errors['required']">Assigned To Email  Address is required</div>
                  </div>
                  <div *ngIf="f.submitted && assignedToEmailId.errors" class="invalid-feedback">
                    <div *ngIf="assignedToEmailId.errors['email']">Assigned To Email Address is invalid</div>
                  </div>
    
                  </div>
                </div>
                 <!-- commenting for bug 3808 -->
                <!-- <div class="col-lg-12">
                  <div class="mt-4 mb-3">
                    <div class="form-check">
                      <input class="form-check-input" [attr.disabled]="cadDisable" [(ngModel)]="informationModalJobData.cadRequired" type="checkbox" id="cd">
                      <label class="form-check-label" for="cd">
                        CAD Required?
                      </label>
                    </div>
                  </div>
                </div> -->
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label for="description" class="form-label">Description</label>
                    <textarea class="form-control form-control-sm" name="jobDescription" [(ngModel)]="informationModalJobData.jobDescription"  rows="3" id="description"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 p-0">
             
              <div class="edit-job-mapbox">
                 <div id="jobcreatemap" class="custom-mapbox"></div>
              </div>
            </div>
            
          
        </div>
    <!-- {{informationModalJobData | json}} -->
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="onCancelUserClick()">
            Cancel
          </button>
          <button type="submit" class="btn btn-success">Save</button>
        </div>
      </form>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCancelUserClick()" data-bs-dismiss="modal">Cancel</button>
        <button type="submit" class="btn btn-success" data-bs-dismiss="modal">Save</button>
      </div> -->
    </div>
  </div>
</div>

<!-- Site Details Modal -->
<div class="modal fade" id="siteDetails" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="siteDetailsLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="siteDetailsLabel"><span>Site Details</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="sname" class="form-label">Site Name
                <span class="custom-form-label-star">*</span></label>
              <input type="text" class="form-control" id="sname" value="Toledo Correctional Institute">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="snameDescription" class="form-label">Description</label>
              <input type="text" class="form-control" id="snameDescription">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="snameAddress" class="form-label">Address</label>
              <input type="text" class="form-control" id="snameAddress" value="503A woodlock Ave.">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="snameCity" class="form-label">City</label>
              <input type="text" class="form-control" id="snameCity" value="Atlanta">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="snameState" class="form-label">State</label>
              <input type="text" class="form-control" id="snameState">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="snameCountry" class="form-label">Country</label>
              <input type="input" class="form-control" id="snameCountry" value="USA">
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-success" data-bs-dismiss="modal">Save</button>
      </div>
    </div>
  </div>
</div>

<!-- Add New MapModal -->
<div class="modal fade" id="addNewJobMap" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="addNewJobMapLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="addNewJobMapLabel"><span>Add Job</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="won" class="form-label">WO Number</label>
              <input type="text" class="form-control" id="won">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="pmm" class="form-label">Project Manager</label>
              <input type="text" class="form-control" id="pmm">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="snid" class="form-label">Site ID / Name</label>
              <input type="text" class="form-control" id="snid">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="subnid" class="form-label">Subsite ID / Name</label>
              <input type="text" class="form-control" id="subnid">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="snPName" class="form-label">Project Name</label>
              <input type="text" class="form-control" id="snPName">
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-success" data-bs-dismiss="modal">Save</button>
      </div>
    </div>
  </div>
</div>

<!-- Add New Role Modal -->
<div class="modal fade" id="addNewRole" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="addNewRoleLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="addNewRoleLabel"><span>Add New Role</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="onCancelRoleClick()"></button>
      </div>
      <div class="modal-body">
        <form class="row g-3" name="form" #f="ngForm" id="form"
          (ngSubmit)="f.form.valid && onSubmitNewRole('btnNewRole')" novalidate>
          <div class="col-md-12">
            <label for="txtNewRole" class="form-label">Role
              <span class="custom-form-label-star">*</span>
            </label>
            <input type="text" class="form-control" name="txtNewRole" [(ngModel)]="addNewRole.name"
              [ngModelOptions]="{ standalone: true }" required #txtNewRole="ngModel" maxlength="30"
              [ngClass]="{ 'is-invalid': f.submitted && txtNewRole.errors }" id="txtNewRole" />
            <div *ngIf="f.submitted && txtNewRole.errors" class="invalid-feedback">
              <div *ngIf="txtNewRole.errors['required']">Role is required</div>
              <div *ngIf="txtNewRole.errors['maxlength']">Please enter only 30 characters</div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
              (click)="onCancelRoleClick()">Cancel</button>
            <button type="submit" id="btnNewRole" data-bs-dismiss="modal" [disabled]="!addNewRole.name"
              class="btn btn-success">Add</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
