import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as mapboxgl from 'mapbox-gl';
import { Subscription } from 'rxjs/internal/Subscription';
import { MapViewerJobFeaturesService } from 'src/app/services/mapviewerJobFeatures/map-viewer-job-features.service';
import { environment } from 'src/environments/environment';
import { CommonMapService } from '../common-map-service';
import { PortalAPI } from 'src/app/constants/api.constant';

@Component({
  selector: 'app-map-component',
  templateUrl: './map-component.component.html',
  styleUrls: ['./map-component.component.css']
})
export class MapComponentComponent implements OnInit {

  map!: mapboxgl.Map;
  navigatedJobId: any;
  pointLayerIds: any[] = [];
  lineLayerIds: any[] = [];
  style = 'mapbox://styles/mapbox/satellite-streets-v11';
  pointFeaturesNameArray: any = JSON.parse(
    sessionStorage.pointFeaturesNameArray
  );
  pointFeaturesNameSet = new Set<string>();
  loginUserId: any;
  mapViewerJobFeatureSubscribe: Subscription | undefined;
  centerCoordinatesForMap: any = [];

  constructor(
    private commonMapService: CommonMapService,
    private route: ActivatedRoute,
    private mapViewerJobFeatureService: MapViewerJobFeaturesService) {
    if (sessionStorage.getItem('loginUserId')) {
      this.loginUserId = sessionStorage.getItem('loginUserId');
    }
    this.route.params.subscribe((params: any) => {
      this.navigatedJobId = params.id;
      if (this.navigatedJobId && this.loginUserId) {
        this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
          .fetchFeaturesByJobId(this.navigatedJobId, this.loginUserId)
          .subscribe((dataWithLayer: any) => {
            console.log("data:", dataWithLayer);
            if (dataWithLayer.length != 0) {
              this.centerCoordinatesForMap.push(
                parseFloat(JSON.parse(dataWithLayer[0].geometry).coordinates[0])
              );
              this.centerCoordinatesForMap.push(
                parseFloat(JSON.parse(dataWithLayer[0].geometry).coordinates[1])
              );
              this.createMap();
            }
          });
      }
    });
  }

  ngOnInit(): void {
  }

  createMap() {
    console.log("create map:");
    /**
     * @desc Building map
     */
    //  (mapboxgl as any).accessToken = environment.mapbox.accessToken;
    this.map = new mapboxgl.Map({
      accessToken: environment.mapbox.accessToken,
      container: 'map1',
      style: this.style,
      zoom: 15,
      center: this.centerCoordinatesForMap,
      preserveDrawingBuffer: true,
      interactive: true,
      renderWorldCopies: false,
    });

    // this.draw = new MapboxDraw(this.drawOptions);
    // this.map.addControl(this.draw, 'top-left');

    this.map.addControl(new mapboxgl.NavigationControl(), 'top-left');
    const scale = new mapboxgl.ScaleControl({
      unit: 'imperial',
    });
    this.map.addControl(scale, 'bottom-left');
    scale.setUnit('imperial');
    this.registerDragEventInMap();

    this.map.on('load', () => {
      console.log('Style load!')
      // this.map.once('idle', (e) => {
      this.setVectorTiles();
      this.loadAllJobsLayerFunctionalities();
    });
  }

  registerDragEventInMap() {
    this.map.on('dragend', () => {
      if (this.map.getZoom() < 13.25) {
        // this.resetMapsData();
      }
    });
  }

  setVectorTiles() {
    for (const appLayerId of [1, 2, 3]) {
      let currentLineSourceId = `${appLayerId}-${this.navigatedJobId}-line`;
      let currentPointSourceId = `${appLayerId}-${this.navigatedJobId}-point`;
      // this.commonMapService.setSourceForVectorTilesV2(this.map, currentLineSourceId, appLayerId, 'line', this.navigatedJobId);
      // this.commonMapService.setSourceForVectorTilesV2(this.map, currentPointSourceId, appLayerId, 'point', this.navigatedJobId);
    }
    // let currentPhotoSourceId = `4-${this.navigatedJobId}-external`;
    // this.commonMapService.setSourceForVectorTilesV2(this.map, currentPhotoSourceId, 4, 'external', this.navigatedJobId);
    this.addGroupLayers();
  }

  //addGroupLayers method is used to add layer for point and line
  addGroupLayers = async () => {
    this.pointLayerIds = [];
    this.lineLayerIds = [];
    console.log(this.map);
    for (const layerID of [1, 2, 3]) {
      let currentLineSourceId = `${layerID}-${this.navigatedJobId}-line`;
      let currentPointSourceId = `${layerID}-${this.navigatedJobId}-point`;

      let currentLineLayerId = `${layerID}-${this.navigatedJobId}-line`;
      this.lineLayerIds.push(currentLineLayerId)
      this.map.addLayer({
        id: currentLineLayerId,
        type: 'line',
        source: currentLineSourceId,
        'source-layer': 'default',
        minzoom: 8.25,
        maxzoom: 23,
        layout: {
          visibility: 'visible',
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': 'red',
          'line-width': 1
        }
      });

      let currentPointLayerId = `${layerID}-${this.navigatedJobId}-point`;
      this.pointLayerIds.push(currentPointLayerId)
      this.map.addLayer({
        id: currentPointLayerId,
        type: 'symbol',
        source: currentPointSourceId,
        'source-layer': 'default',
        minzoom: 8.25,
        maxzoom: 23,
        layout: {
          'icon-allow-overlap': true,
          visibility: 'visible',
          'icon-image': [
            'coalesce',
            ['get', 'featureType'],
            'GENERIC_BRIAN',
          ], // expects "featureType" to have same name as the loaded icon
          'icon-size': ['interpolate', ['linear'], ['zoom'], 10.25, .1, 17, .3],
        }
      });
    }
    // let currentPhotosSourceId = `4-${this.navigatedJobId}-external`;
    // let currentPhotoLayerId = `4-${this.navigatedJobId}-photo`;
    // this.map.addLayer({
    //   id: currentPhotoLayerId,
    //   type: 'symbol',
    //   source: currentPhotosSourceId,
    //   'source-layer': 'default',
    //   minzoom: 13.25,
    //   maxzoom: 23,
    //   layout: {
    //     'icon-allow-overlap': true,
    //     visibility: 'visible',
    //     'icon-image': this.commonMapService.externalContentIconImage['photo'], // expects "featureType" to have same name as the loaded icon
    //     'icon-size': ['interpolate', ['linear'], ['zoom'], 10.25, .1, 17, .3],
    //   },
    //   filter: ['==', ['get', 'featureGroup'], 'PHOTO'],
    // });
  }

  addAllIconsInMap() {
    for (let i = 0; i < this.pointFeaturesNameArray.length; i++) {
      if (
        !this.pointFeaturesNameSet.has(this.pointFeaturesNameArray[i]) &&
        this.pointFeaturesNameArray[i] != 'GENERIC_BRIAN'
      ) {
        this.map.loadImage(
          `../../../../../../assets/images/GPRS_PNG/${this.pointFeaturesNameArray[i]}.png`,
          (error, image: any) => {
            this.map.addImage(this.pointFeaturesNameArray[i], image);
            if (error) {
              console.log('already exist' + this.pointFeaturesNameArray[i]);
            }
          }
        );
      }
    }
  }

  //loadAllJobsLayerFunctionalities method is used to load all data related to the screen like layer, source, create map etc
  loadAllJobsLayerFunctionalities() {
    this.addAllJobsSource();
    this.addAllIconsInMap();
  }

  //addAllJobsSource method is used to add source in map
  addAllJobsSource() {
    this.map.addSource('allJobs', {
      type: 'geojson',
      data: PortalAPI.DATA_SOURCE,
    });
    // this.addVectorTilesLayersAndSourceNew();
  }

  //addAllJobsLayerInMap method is used to add layer for all unclucstered point
  addAllJobsLayerInMap() {
    let visibility: any = 'visible';
    if (this.map.getZoom() > 14) {
      visibility = 'none';
    }
    this.map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'allJobs',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#11b4da',
        'circle-radius': 4,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff',
      },
      layout: {
        visibility: visibility,
      },
    });
  }




}
