import { Component, OnInit, Output } from '@angular/core';
import { AzureBlobStorageService } from './../azure-blob-storage.service';
import { AzureBlobStorageServiceVideo } from '../services/azure-blob-storage.service';
import { BehaviorSubjectService } from '../services/behaviorsubject.service';
import { ToastrService } from 'ngx-toastr';
import { EventEmitter } from 'stream';
import { threadId } from 'worker_threads';
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  // SAS (shared access signatures)
  // sas = "?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-03-31T14:24:32Z&st=2022-08-03T06:24:32Z&spr=https,http&sig=XNhNWf022pmV0kTeR4tAghyRf3mRgd3OaCzSoSsSgTE%3D";
  sas = '';
  picturesList: string[] = [];
  picturesDownloaded: string[] = []
  fileArrList: any = [];
  videosList: string[] = [];
  videoDownloaded: any;
  searchValue = "";
  searchFileArrList: any;
  showDefaultFileArrList: boolean = true;
  showSearchFileArrList: boolean = false;
  selectedTab: any = 'faq';
  searchBoxStatus:boolean = false;
  
  helpDocumentContainer:string  = '';
  helpDocumentContainerSAS:string = '';
  // @Output() 
  // emitEvent = new EventEmitter();

  constructor(
    private blobService: AzureBlobStorageService,
    private blobServicesvideo: AzureBlobStorageServiceVideo,
    private behaviourSubjectService: BehaviorSubjectService,
    private toastr: ToastrService,

  ) { 
    this.helpDocumentContainer = this.blobServicesvideo.helpDocumentContainer;
    this.helpDocumentContainerSAS = this.blobServicesvideo.helpDocumentContainerSAS;
    this.sas = this.helpDocumentContainerSAS;
  }

  ngOnInit(): void {
    this.reloadImages();
    console.log("ngOnInit Help without modules")
    
  }

  public setSas(event: any) {
    this.sas = event.target.value
  }

  //# upload file on input 
  public imageSelected(file: any) {
    if (file.target.files[0]) {
      this.blobServicesvideo.uploadImage(this.sas,this.helpDocumentContainer, file.target.files[0], file.target.files[0].name, () => {
        this.reloadImages()
      });
    }
  }

  //# delete files on click
  public deleteImage(name: string) {
    this.blobServicesvideo.deleteImage(this.sas,this.helpDocumentContainer, name, () => {
      this.reloadImages()
    })
  }

  //# download files on click
  public downloadImage(name: string) {
    this.blobServicesvideo.downloadImage(this.sas,this.helpDocumentContainer, name, blob => {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let fileName = name;
      link.download = fileName;
      link.click();
    })
  }

  //# list of files on page load
  private reloadImages() {
    this.fileArrList = [];
    this.blobServicesvideo.listImages(this.sas,this.helpDocumentContainer).then(list => {
      this.picturesList = list
      const array: any = [];
      this.picturesDownloaded = array

      for (let name of this.picturesList) {
        this.blobServicesvideo.downloadImage(this.sas,this.helpDocumentContainer, name, blob => {
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            array.push(reader.result as string)
          }
        })
        const title = name.split('.');
        const docname = title[0];
        let dummy = { 'name': name };
        this.fileArrList.push(dummy);

      }
    })
  }

  //# onSearchChange method is use for get a input search value
  onSearchChange(value: string) {
    if (value) {
      this.searchValue = value;
      
      if (this.selectedTab == 'document') {
        this.getSearchList();
      }
      if (this.selectedTab == 'videos') {
        this.behaviourSubjectService.changeHelpSearchValue(this.searchValue);
        this.behaviourSubjectService.changeHelpSelectedTabValue(this.selectedTab);
      }
      if (this.selectedTab == 'faq') {
        this.behaviourSubjectService.changeHelpSearchValue(this.searchValue);
        this.behaviourSubjectService.changeHelpSelectedTabValue(this.selectedTab);
      }
    }
    else {
      this.toastr.error("Please Specify Search Query or enter valid Keyword!","",{timeOut:3000})
    }
  }

  //# function to search element from file list
  searchElement(elementsArray: any, keyword: any) {
    const returnedElement = elementsArray.filter((element: any, index: any) => {
      return element.name.toLowerCase().includes(keyword.toLowerCase());
    });
    return returnedElement;
  }

  //# get final searched item list
  getSearchList() {
    this.showDefaultFileArrList = false;
    this.showSearchFileArrList = true;
    this.searchFileArrList = [];
    const result = this.searchElement(this.fileArrList, this.searchValue);
    for (let obj of result) {
      let dummy = { 'name': obj.name };
      this.searchFileArrList.push(dummy);
    }

  }

  //# reset search
  resetSearch() {
    let searchText = document.getElementById('txtSearch') as HTMLInputElement;
    searchText.value = "";
    if (this.selectedTab == 'document') {
      this.reloadImages();
      this.showDefaultFileArrList = true;
      this.showSearchFileArrList = false;
      this.searchValue = '';
    }
    if (this.selectedTab == 'videos') {
      this.behaviourSubjectService.changeHelpSelectedTabValue('resetsearch');
    }
    if (this.selectedTab == 'faq') {
      this.behaviourSubjectService.changeHelpSelectedTabValue('resetsearch');
    }
  }

  selectedTabOnclick(tab: any) {
    this.selectedTab = tab;
    setTimeout(() => {
      let resetHelp = document.getElementById('reset-help') as HTMLInputElement;
      resetHelp.click();
    }, 100);
   
  }
  // ,valVideoPlay:boolean
  searchShowHide(val:boolean){
    this.searchBoxStatus = val;
    let searchText = document.getElementById('txtSearch') as HTMLInputElement;
    searchText.value = "";
    // this.behaviourSubjectService.isVideoPlay.next(val);
    // console.log("isVisible 2",valVideoPlay)
    this.behaviourSubjectService.changeisVideoPlay(false);
    // this.emitEvent.next('message from button');
  }

  ngOnDestroy(): void{
    console.log("Destroy Help without modules")

  }

   


}
