import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';

import { msalConfig } from './auth-config';
import { HelpComponent } from './help/help.component';
import { HeaderComponent } from './header/header.component';
import { InterceptorService } from './services/interceptor/interceptor.service';
import { MapviewerjobsComponent } from './mapviewerjobs/mapviewerjobs.component';
import { SitemapadminModule } from './modules/sitemapadmin/sitemapadmin.module';
import { DashboardsModule } from './modules/dashboards/dashboards.module';
import { FaqComponent } from './help/components/faq/faq.component';
import { GettingstartedComponent } from './help/components/gettingstarted/gettingstarted.component';
import { TrobleshootComponent } from './help/components/trobleshoot/trobleshoot.component';
import { TechnicalComponent } from './help/components/technical/technical.component';
import { TrainingvideosComponent } from './help/components/trainingvideos/trainingvideos.component';
import { FormsdocumentsComponent } from './help/components/formsdocuments/formsdocuments.component';
import { HighlighterPipe } from './pipe/highlighter.pipe';
import { MapImportComponent } from './common-component/map-import/map-import.component';
import { SharedModule } from './shared/shared.module';
import { CommitChangesComponent } from './common-component/commit-changes/commit-changes.component';

// store related imports
//import { StoreModule } from '@ngrx/store';
//import { EffectsModule } from '@ngrx/effects';
//import { appReducers } from './app-state/reducers/app.reducers';
//import { centroidEffects } from './app-state/effetcs/centroid.effects';
import { RevisionHistoryModule } from './common-component/revision-history/revision-history.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MapModule } from './modules/map/map.module';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

// export class CacheRouteReuseStrategy implements RouteReuseStrategy {
//   storedRouteHandles = new Map<string, DetachedRouteHandle>();

//   allowRetrieveCache = {
//       'mapbox': true
//   };

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HelpComponent,
    HeaderComponent,
    MapviewerjobsComponent,
    FaqComponent,
    GettingstartedComponent,
    TrobleshootComponent,
    TechnicalComponent,
    TrainingvideosComponent,
    FormsdocumentsComponent,
    HighlighterPipe,
    MapImportComponent,
    CommitChangesComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    MsalModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    SitemapadminModule,
    DashboardsModule,
    SharedModule,
    RevisionHistoryModule,
    PdfViewerModule,
    MapModule,
  ],
  exports: [
    HighlighterPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],

})
export class AppModule { }
