import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { PortalAPI } from 'src/app/constants/api.constant';
import { Subscription } from 'rxjs';
import { BehaviorSubjectService } from 'src/app/services/behaviorsubject.service';
@Injectable({
  providedIn: 'root'
})
export class SettingService {
  public options: any = {};
  constructor(
    private http: HttpClient,
    private behaviourSubjectService: BehaviorSubjectService
  ) {
    let reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    reqHeader.append('Access-Control-Allow-Origin', '*');
    reqHeader.append('accept', 'text/plain');
    reqHeader.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    reqHeader.append('Access-Control-Allow-Methods', 'GET,POST,OPTIONS,DELETE');
    this.options = { headers: reqHeader };

  }

  getMyPreferencesList(data: any) {    
    return this.http.get(PortalAPI.PREFERENCES_LIST + '/' + data.userId, this.options);
  }

  saveUpdatePreferences(data: any) {
    return this.http.post(PortalAPI.SAVE_UPDATE_PREFERENCES, data, this.options);
  }

  ngOnDestroy() {
  }
}
