<div class="modal-content" style="height: 100%;">
    <div class="modal-header">
        <h5 class="modal-title">Save/Revert Edits</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="historyOpend.emit(false)"></button>
    </div>
    <div class="modal-body history-content">
        <ul id="revision-changes">
            <li *ngFor="let history of historyMetaData">
                <div *ngIf="isHistoryGroupClicked" class="title">
                    <!-- <div > <span class="content-bold"> Group: </span> {{ history.groupName }}</div> -->
                    <div class="content content-bold"> {{ history.groupName }}</div>
                </div>
                <ul class="active">
                    <li *ngFor="let type of history.types">
                        <div *ngIf="isHistoryTypeClicked" class="title">
                            <!-- <div class="content"> <span class="content-bold"> Type: </span> {{type.typeName}}</div> -->
                            <div class="content content-bold"> {{type.typeName}}</div>
                        </div>
                        <ul class="active">
                            <li *ngFor="let feature of type.features" >
                                <div class="title">
                                    <!-- <div class="content"> <span class="content-bold"> Feature: </span>
                                        {{feature.featureName}}</div> -->
                                    <div class="content content-bold"> {{feature.featureName}}</div>
                                </div>
                                <div *ngIf="getRevisions(feature.featureId)?.length > 0; else noRevisionChanges;">
                                    <div [ngClass]="i === 0 ? 'latest' : '' " style="padding: 10px" *ngFor="let revision of getRevisions(feature.featureId); let i = index;">
                                        <div class="content common-line-height"> {{ revision?.auditDate | date: 'dd/MM/yyyy h:mm:ss a' }}</div>
                                        <div class="hbar common-line-height">
                                            <div class="content">
                                                {{ revision?.auditOperation }}  
                                            </div>
                                            <div *ngIf="i > 0" class="common-line-height" (click)="onRevertHistoryClick(feature, revision)">
                                                <i class="fa fa-reply revert" title="Revert"></i>
                                            </div>
                                        </div>
                                        <div class="common-line-height user">
                                            <div class=" content user-content" [ngClass]="i === 0 ? 'latest' : '' "> By: {{ revision?.operationBy }} </div>
                                            <div class="bottom-border"></div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" style="margin-right: 20px;"
            (click)="onCancelHistoryFeature()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="onSaveHistoryFeatureClick()">Save</button>
    </div>
</div>

<ng-template #noRevisionChanges>
    <div>
      <div class="no-revision-changes">No Revision Changes to show...!</div>
    </div>
</ng-template>


  
