import { ICommonTableHeader } from "./common-table-data"

export enum SitemapRole {
  Admin = 1,
  ProjectManager = 2,
  Client = 3,
}

export enum SitemapTier {
  Personal = 1,
  Basic = 2,
  Enterprise = 3,
}

export interface IUserModel {
    userId: number,
    email: string,
    name: string,
    roleId: number,
    roleName: string,
    tierId: number,
    tierName: string,
    isActive: any,
    checkBox:boolean,
    assignedJobs:string,
    action:IActionModel[],
    permission:IPermissionModel[]
}

export interface IActionModel {
  icon: string,
  tooltip: string,
  dataBsToggle: string,
  dataBsTarget: string,
}

export interface IPermissionModel {
  permission: string,
  dataBsToggle: string,
  dataBsTarget: string,
}

export class userTableData {
    tblHeader:ICommonTableHeader[] = [
        {
            columnName: 'checkBox',
            displayName: 'Select Item',
            class: '',
            dropDown: false,
            icon: false,
            visible: false,
            span: false,
            link: false,
            button: false,
            text: false,
            checkBox: true,
          },
          {
            columnName: 'name',
            displayName: 'Name',
            class: '',
            dropDown: false,
            icon: false,
            visible: true,
            span: true,
            link: false,
            button: false,
            text: false,
            sort:true
          },
          {
            columnName: 'email',
            displayName: 'Email Address',
            class: '',
            dropDown: false,
            icon: false,
            visible: true,
            span: true,
            link: false,
            button: false,
            text: false,
            sort:true
          },
          {
            columnName: 'roleName',
            displayName: 'Role',
            class: '',
            dropDown: false,
            icon: false,
            visible: true,
            span: true,
            link: false,
            button: false,
            text: false,
          },
          {
            columnName: 'tierName',
            displayName: 'Sub. Tier',
            class: '',
            dropDown: false,
            icon: false,
            visible: true,
            span: true,
            link: false,
            button: false,
            text: false,
          },
          {
            columnName: 'isActive',
            displayName: 'Status',
            class: '',
            dropDown: false,
            icon: false,
            visible: true,
            span: true,
            link: false,
            button: false,
            text: false,
          },
          {
            columnName: 'assignedJobs',
            displayName: 'Assigned Jobs / Access Type',
            class: '',
            dropDown: false,
            icon: false,
            visible: true,
            span: false,
            link: true,
            button: false,
            text: false,
          },
          {
            columnName: 'action',
            displayName: 'Action',
            class: '',
            dropDown: false,
            icon: true,
            visible: false,
            span: false,
            link: false,
            button: true,
            text: false,
          },
          {
            columnName: 'permission',
            displayName: 'Permissions',
            class: '',
            dropDown: false,
            icon: false,
            visible: true,
            span: false,
            link: true,
            button: false,
            text: false,
          },
    ]

    actionButton = [
        {
          icon: 'fa-pencil',
          tooltip: 'Edit User Information',
          dataBsToggle: 'modal',
          dataBsTarget: '#editUser',
        },
        {
          icon: 'fa-info-circle',
          tooltip: 'Login History',
          dataBsToggle: 'modal',
          dataBsTarget: '#loginHistory',
        },
        {
          icon: 'fa-envelope',
          tooltip: 'Email Security Reset Information',
          dataBsToggle: 'modal',
          dataBsTarget: '#ESRInfo',
        },
        {
          icon: 'fa-lock',
          tooltip: 'Reset Password',
          dataBsToggle: 'modal',
          dataBsTarget: '#resetPassword',
        },
    ];
    
    linkButton = [
        {
          permission: 'Edit',
          dataBsToggle: 'modal',
          dataBsTarget: '#permissionsEdit',
        },
    ];
}

export class loginHistoryTableData{
  tblHeader:ICommonTableHeader[] = [
    {
      columnName: 'date',
      displayName: 'Date',
      class: '',
      dropDown: false,
      icon: false,
      visible: true,
      span: true,
      link: false,
      button: false,
      text: false,
    },
    {
      columnName: 'loginTime',
      displayName: 'Login Time',
      class: '',
      dropDown: false,
      icon: false,
      visible: true,
      span: true,
      link: false,
      button: false,
      text: false,
    },
    {
      columnName: 'logoutTime',
      displayName: 'Logout Time',
      class: '',
      dropDown: false,
      icon: false,
      visible: true,
      span: true,
      link: false,
      button: false,
      text: false,
    },
    {
      columnName: 'duration',
      displayName: 'Duration',
      class: '',
      dropDown: false,
      icon: false,
      visible: true,
      span: true,
      link: false,
      button: false,
      text: false,
    },
  ]  
}