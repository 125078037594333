import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MapRoutingModule } from './map-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SitemapadminModule } from '../sitemapadmin/sitemapadmin.module';
import { DprMenusComponent } from './component/dpr-menus/dpr-menus.component';
import { FeatureDescriptionComponent } from './component/feature-description/feature-description.component';
import { SiteDetailsComponent } from './component/site-details/site-details.component';
import { MapalljoblistComponent } from './component/mapalljoblist/mapalljoblist.component';
import { ToolsComponent } from './component/tools/tools.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RevisionHistoryModule } from 'src/app/common-component/revision-history/revision-history.module';
import { SiteAdminDetailsComponent } from './component/site-admin-details/site-admin-details.component';
import { FeatureTreeComponent } from './component/feature-tree/feature-tree.component';
import { LayerComponent } from './component/layer/layer.component';
import { MapLayerMenuItemComponent } from './component/map-layer-menu-item/map-layer-menu-item.component';


@NgModule({
  declarations: [
    MapalljoblistComponent,
    SiteDetailsComponent,
    LayerComponent,
    ToolsComponent,
    FeatureDescriptionComponent,
    DprMenusComponent,
    SiteAdminDetailsComponent,
    FeatureTreeComponent,
    MapLayerMenuItemComponent
  ],
  imports: [
    CommonModule,
    MapRoutingModule,
    FormsModule, SharedModule,
    ReactiveFormsModule,
    SitemapadminModule,
    RevisionHistoryModule,
    SharedModule
  ],
  exports: [
    FeatureTreeComponent,
    MapLayerMenuItemComponent
  ]
})
export class MapModule { }
