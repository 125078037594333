import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-sites',
  templateUrl: './manage-sites.component.html',
  styleUrls: ['./manage-sites.component.css']
})
export class ManageSitesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
