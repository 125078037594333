<div class="col-lg-12">
  <h2 class="table-page-title"> Recent Jobs  <a routerLink="/joblist">VIEW ALL JOBS</a>
    <ul *ngIf="pager.pages && pager.pages.length" class="custom-table-pagination">
      <li [ngClass]="{disabled:pager.currentPage === 1}">
        <a (click)="setPage(1)">First</a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === 1}">
        <a (click)="setPage(pager.currentPage - 1)">&#60;</a>
      </li>
      <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
        <a (click)="setPage(page)">{{page}}</a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage == pager.totalPages}">
        <a (click)="setPage(pager.currentPage + 1)">&#62;</a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage == pager.totalPages}">
        <a (click)="setPage(pager.totalPages)">Last</a>
      </li>
    </ul>
  </h2>

  <div class="table-responsive custom-height-scroll">
    <table class="table table-hover table-bordered custom-table table-colored first-column-hide-on-mobile">
      <thead>
        <tr>
          <td></td>
          <th class="dashboard-th3">Site Name</th>
          <th class="dashboard-th2">Job Name</th>
          <td></td>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let obj of pagedItems; let i = index;" (click)="selectedRow($event, obj)"
          [class.highlighted]="obj.name === currentRow">
          <td style="width: 100px;">
            <img *ngIf="isShared(obj)" src="../assets/images/new_share_icon.svg" alt="New Share" class="assign-share" /> 
            <img *ngIf="isAssigned(obj)" src="../assets/images/new_job_icon.svg"  class="assign-share" alt="New Job" />
          </td>
          <td href="javascript:void(0)" style="width: 100px;">{{obj.siteName}}</td>
          <td>                       
            <a *ngIf="obj.name != null" aria-label="false" (click)="shareJobObjOnMap(obj)" href="javascript:void(0)">
            {{obj.name}}
            </a>

            <a *ngIf="obj.name == null"   aria-label="false" (click)="shareJobObjOnMap(obj)" href="javascript:void(0)">
              Null
            </a>
          </td>          

          <!-- <td><a (click)="shareJobObj(obj)" href="javascript:void(0)" data-bs-toggle="modal"
              data-bs-target="#staticBackdrop">Share</a></td>
          <td> -->
          <td>
            <a aria-label="false" href="javascript:void(0)" (click)="addJobLogData(obj)"
              class="custom-action-status float-start"
              data-bs-toggle="modal" data-bs-target="#table-edit"
              >
              <!-- <i title="job log" [hidden]="!obj.logStatus" class="custom-action-status-dot"></i> -->
              <span class="fa fa-info-circle"></span>
            </a>
            <p class="float-end share-icon" href="javascript:void(0)"><span class="fa fa-share-alt"
                (click)="shareJobObj(obj)" data-bs-toggle="modal" data-bs-target="#staticBackdrop"></span></p>
          </td>
        </tr>
        <tr *ngIf="pagedItems.length== 0">
          <td colspan="3">No Job Found</td>
        </tr>
      </tbody>
    </table>
  </div>

  
</div>
