import { FileGroup } from "../feature-tree.component.types";

export default function getJobIdFromFile(file: FileGroup): number | null {
  // search for job id in groups
  for (const group of file.groups) {
    if (group.mapJobId) {
      return parseInt(group.mapJobId as string) || null;
    }
  }

  return null
}
