import { Injectable, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PortalAPI } from 'src/app/constants/api.constant';
import { ConnectionService } from '../../sitemapadmin/services/connection-service';
import * as turf from '@turf/turf';
import { NgxSpinnerService } from 'ngx-spinner';
import mapboxgl from 'mapbox-gl';
import { CommitChanges, Feature, FeatureGroup, FeatureType } from 'src/app/constants/common-model';
import { FeatureTypeStyleMapping } from 'src/app/constants/featureTypeIdStylingMapping';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MapEditService {

    loginUserId: any;
    loginUserRole: any;
    loginUserEmailId: any;

    // Commit Changes related code
    mapEditorChangesToBeCommitted : CommitChanges[] = [];
    committedChangesSub = new BehaviorSubject<any[]>([]);
    committedChangesList$ = this.committedChangesSub.asObservable();
    committedChangesCountAndSaveSub = new BehaviorSubject<any>({});
    committedChangesCountAndSave$ = this.committedChangesCountAndSaveSub.asObservable();
    committedChangesRevertOrSaveSub = new BehaviorSubject<any>({});
    committedChangesRevertOrSaveList$ = this.committedChangesRevertOrSaveSub.asObservable();
    draw:any;
  
    constructor(private restService: ConnectionService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService) {
        if (sessionStorage.getItem("loginCheck") === undefined || !sessionStorage.getItem("loginCheck")) {
            if (sessionStorage.getItem("loginUserId")) {
                this.loginUserId = sessionStorage.getItem("loginUserId");
            }
            if (sessionStorage.getItem("loginUserRole")) {  //Project Manager,Admin
                this.loginUserRole = sessionStorage.getItem("loginUserRole");
            }

            if (sessionStorage.getItem("loginUserEmailId")) {
                this.loginUserEmailId = sessionStorage.getItem("loginUserEmailId");
            }
        }
    }

    measeureLength(length: any, latLong: any, map: any) {
        if (length >= 5280) {
            setTimeout(() => {
                new mapboxgl.Popup()
                    .setLngLat(latLong)
                    .setHTML(
                        `<strong>Length of drawn string is : ${(length / 5280).toFixed(
                            2
                        )} mi.</strong>`
                    )
                    .addTo(map);
            }, 0);
        } else {
            setTimeout(() => {
                new mapboxgl.Popup()
                    .setLngLat(latLong)
                    .setHTML(
                        `<strong>Length of drawn string is : ${length} feet</strong>`
                    )
                    .addTo(map);
            }, 0);
        }
    }

    measureCalcArea(calcArea: any, lantLong: any, map: any) {
        if (calcArea >= 43560) {
            setTimeout(() => {
                new mapboxgl.Popup()
                    .setLngLat(lantLong)
                    .setHTML(`<strong>Area of drawn polygon is : ${(calcArea / 43560).toFixed(4)} acres.</strong>`)
                    .addTo(map);
            }, 0);
        }
        else {
            setTimeout(() => {
                new mapboxgl.Popup()
                    .setLngLat(lantLong)
                    .setHTML(`<strong>Area of drawn polygon is : ${calcArea} sq. feet</strong>`)
                    .addTo(map);
            }, 0);
        }
    }

    //#region removeExtraPopup method use to remove the extra open coordinate popup
    removeExtraPopup(className: any) {
        setTimeout(() => {
            let ele = document.querySelectorAll(className);
            if (ele?.length > 0) {
                if (ele?.length === 1) {
                    ele[0].remove();
                }
                else {
                    for (let i = 0; i < ele.length - 1; i++) {
                        ele[i].remove();
                    }
                }
            }
        }, 0);
    }
    //#endregion

    createDrawEvents(map: any, isEditingModeIsOn: any, draw: any) {
        map.on('draw.create', (e: any) => {
            //please confirm with chris
            // if (!this.isEditingModeIsOn || !this.isPrintSession)
            if (isEditingModeIsOn) {
                const data = draw.getAll();
                if (
                    data.features[data.features.length - 1].geometry.type == 'Polygon'
                ) {
                    let exportDrawPolygonCordinates =
                        data.features[data.features.length - 1].geometry.coordinates;
                    if (exportDrawPolygonCordinates) {
                        for (var i = 0; i < exportDrawPolygonCordinates.length; i++) {
                            if (i == 0) {
                                exportDrawPolygonCordinates[i].splice(i, 1);
                            }
                        }
                    }
                    var coordinates =
                        data.features[
                            data.features.length - 1
                        ].geometry.coordinates[0][0].slice();
                    const area = turf.area(data.features[data.features.length - 1]);
                    var rounded_area = Math.round(area * 10.7639);
                    this.measureCalcArea(rounded_area, coordinates, map);
                }
            }
        });

        map.on('draw.delete', (e: any) => {
            
            const data = draw.getAll();
            const popups = document.getElementsByClassName('mapboxgl-popup');
            if (popups.length) {
                popups[0].remove();
            }
            if (data.features.length > 0 && !isEditingModeIsOn) {
                const coordinates = data.features[0].geometry.coordinates[0][0].slice();
                const area = turf.area(data);
                // Restrict the area to 2 decimal points.
                const rounded_area = Math.round(area * 10.7639);
                this.measureCalcArea(rounded_area, coordinates, map);
            }
            // Get the draw action for history
            //   let drawAction = e.action;
            // An update action removes all future states, if any exist
            //   this.setEditingHistory(drawAction, e.features[0].id);
        });

        map.on('draw.update', (e: any) => {
            
            let selectedFeature = draw.getSelected().features[0];
            const drawFeatureCollection = draw.getAll();
            if (selectedFeature.geometry.type == 'LineString') {
                var coordinates = selectedFeature.geometry.coordinates[0].slice();
                const length = turf.length(selectedFeature);
                var rounded_length = Math.round(length * 3280.8398950131);
                if (!isEditingModeIsOn) {
                    this.measeureLength(rounded_length, coordinates, map);
                    this.removeExtraPopup('.mapboxgl-popup-anchor-bottom');
                }
            } else if (selectedFeature.geometry.type == 'Polygon') {
                var coordinates = selectedFeature.geometry.coordinates[0][0].slice();
                const area = turf.area(selectedFeature);
                var rounded_area = Math.round(area * 10.7639);
                this.measureCalcArea(rounded_area, coordinates, map);
                this.removeExtraPopup('.mapboxgl-popup-anchor-bottom');
            }
            // Get the draw action for history
            // let drawAction = e.action;
            // An update action removes all future states, if any exist
            // this.setEditingHistory(drawAction, selectedFeature.id);
        });

        map.on('draw.selectionchange', (e: any) => {
            
            let editSelectedFeature = e?.features[0];
            // Highlight the feature that is selected on the side panel
            if (e.features.length > 0) {
                e.features.forEach((feature: any) => {
                });
            } else {
                // Remove highlight from feature in the side panel
                console.log('No feature selected.');
            }
        });

        map.on('click', (e: any) => {
            let selectedFeature = draw.getSelected().features[0];
            if (selectedFeature?.properties) {
            }
            if (selectedFeature?.geometry?.type == 'LineString') {
                var coordinates = selectedFeature.geometry.coordinates[0].slice();
                const length = turf.length(selectedFeature);
                var rounded_length = Math.round(length * 3280.8398950131);
                if (!isEditingModeIsOn) {
                    this.measeureLength(rounded_length, coordinates, map);
                }
                this.removeExtraPopup('.mapboxgl-popup-anchor-bottom');
            } else if (selectedFeature?.geometry?.type === 'Polygon') {
                var coordinates = selectedFeature.geometry.coordinates[0][0].slice();
                const area = turf.area(selectedFeature);
                var rounded_area = Math.round(area * 10.7639);
                this.measureCalcArea(rounded_area, coordinates, map);
                this.removeExtraPopup('.mapboxgl-popup-anchor-bottom');
            }
        });
    }

    gotoMapEditor(layer: any, zoomedJobIds: any, isEditingModeIsOn: any) {
        // if (zoomedJobIds.length === 1) {
        return isEditingModeIsOn = true;
        // }
        // else if (zoomedJobIds.length > 1) {
        //   this.toastr.error("Please select single job for editing.", "", { timeOut: 2000 })
        // }
    }


    // When user clicks a feature to edit on the map and it is "valid"
    handleEditingforPointorLine(clickedFeatures: any, draw: any,map:any) {
        // Valid features are in current editing layer and are not being edited already
        // Get all Draw feature names
        const drawFeatureCollection = draw.getAll();
        let currentDrawFeatureNames = drawFeatureCollection.features.map((feature: any) => feature.properties.featureName)
        // Filter from the clicked features only that which are in the correct editing layer
        // and are not already in being edited - i.e. the feature name does not exist in the
        // drawFeatureCollection
        let potentialEditingFeatures = clickedFeatures.filter(
            (feature: any) =>
                feature.properties.layerId === 5 &&
                currentDrawFeatureNames.indexOf(feature.properties.featureName) === -1
        );
        // Handle 0, 1 and 2+ features clicked
        let isEdit = true;
        if (potentialEditingFeatures.length > 0) {
            let editingFeature = potentialEditingFeatures[0]
            let editingFeatureName = editingFeature.properties.featureName
            let editingFeatureType = editingFeature.properties.featureType
            let editingFeatureGroup = editingFeature.properties.featureGroup
            if (potentialEditingFeatures.length > 1) {
                if (!confirm(`You have selected multiple features. Would you like to edit ${editingFeatureGroup} - ${editingFeatureType} - ${editingFeatureName}?`)) {
                    isEdit = false
                }
            }
            if (isEdit) {
                this.getFeatureDetailsByFeatureIdForMapEditor(editingFeature);
            }
        } else {
            // turf.featureCollection([]);
            console.log("clicke:", clickedFeatures);
            if(clickedFeatures.length>0) {
                clickedFeatures[0].id = clickedFeatures[0].properties.id;
                let drawId = draw.add(clickedFeatures[0]);
                console.log("drawId: ", drawId);
                let lineFeature = draw.get(drawId);
                draw.changeMode('direct_select', { featureId: lineFeature.id });
                draw.setFeatureProperty(
                        drawId,
                        'mapLineId',
                        clickedFeatures[0].properties.id
                )
            }
            // this.handleGetFeatureDetails(clickedFeatures,'Polygon',draw);
        }
    }


    //# get features details for map editor
    getFeatureDetailsByFeatureIdForMapEditor(editFeature: any) {
        let geomType = editFeature.geometry.type;
        let editFeatureId = editFeature.properties.featureId;

        switch (geomType) {
            case 'Point':
                break;
            case 'LineString':
                break;
            default:
                break;
        }
    }
    //#endregion

    handleGetFeatureDetails(retrievedFeature: any, geomType: string, draw: any) {
        let geometry: any;
        if (retrievedFeature) {
            geometry = retrievedFeature.geometry;
        }
        let drawFeature: any = {};
        drawFeature.id = '999' + Math.random().toString().slice(2, 10)//Math.random().toString(36).slice(2) //; //retrievedFeature.properties.featureName.replace('.', '_'); //Math.random().toString().slice(2, 10); //
        drawFeature.type = 'Feature';
        // coords variable for manage External layer and other layers data seperately
        let coords = '';

        if (geomType === 'Point') {
            draw.changeMode('draw_point');
        }
        else if (geomType === 'Polygon') {
            drawFeature.geometry = { type: 'Polygon', coordinates: coords }
            draw.changeMode('draw_polygon');
        }
        else {
            drawFeature.geometry = { type: 'LineString', coordinates: coords }
            draw.changeMode('draw_line_string');
        }
        if (retrievedFeature) {
            let drawId = draw.add(drawFeature);
            draw.setFeatureProperty(
                drawId,
                'featureGroup',
                retrievedFeature.properties.featureGroup
            );
            draw.setFeatureProperty(
                drawId,
                'featureType',
                retrievedFeature.properties.featureType
            );
            draw.setFeatureProperty(
                drawId,
                'featureName',
                retrievedFeature.properties.featureName
            );
            draw.setFeatureProperty(
                drawId,
                'featureClass',
                retrievedFeature.properties.featureClass
            );
            draw.setFeatureProperty(
                drawId,
                'featureGroupId',
                retrievedFeature.properties.featureGroupId
            );
            draw.setFeatureProperty(
                drawId,
                'featureTypeId',
                retrievedFeature.properties.featureTypeId ? retrievedFeature.properties.featureTypeId : retrievedFeature.properties.featureGroupId
            );
            draw.setFeatureProperty(
                drawId,
                'featureId',
                retrievedFeature.properties.featureId
            );
            draw.setFeatureProperty(
                drawId,
                'mapJobId',
                retrievedFeature.properties.map_job_id ? retrievedFeature.properties.map_job_id : retrievedFeature.properties.mapJobId
            );
            draw.setFeatureProperty(
                drawId,
                'mapLayerId',
                retrievedFeature.properties.layerId
            );
            if (geomType === 'Point') {
                draw.changeMode('simple_select', { featureIds: [drawId] });
                if (retrievedFeature.properties.featureClass === 'point') {
                    draw.setFeatureProperty(
                        drawId,
                        'mapPointId',
                        retrievedFeature.properties.featureId
                    );
                } else if (retrievedFeature.properties.featureClass === 'annotation_point') {
                    draw.setFeatureProperty(
                        drawId,
                        'mapAnnotationPointId',
                        retrievedFeature.properties.featureId
                    );
                }
            } else if (geomType === 'LineString') {
                let lineFeature = draw.get(drawId);
                draw.changeMode('direct_select', { featureId: lineFeature.id });
                if (retrievedFeature.properties.featureClass === 'line') {
                    draw.setFeatureProperty(
                        drawId,
                        'mapLineId',
                        retrievedFeature.properties.featureId
                    );
                } else if (retrievedFeature.properties.featureClass === 'annotation_line') {
                    draw.setFeatureProperty(
                        drawId,
                        'mapAnnotationLineId',
                        retrievedFeature.properties.featureId
                    );
                }
            }
        }
    }

    getLayerName(layerId: any) {
        let layerName = '';
        if (layerId === 1) {
          layerName = 'GPRS';
        } else if (layerId === 2) {
          layerName = 'CLIENT';
        } else if (layerId === 3) {
          layerName = 'IMPORTED';
        } else if (layerId === 4) {
          layerName = 'EXTERNAL';
        } else if (layerId === 5) {
          layerName = 'SITE';
        }
        return layerName;
      }

    //************************ Commit Changes Code********************************** */

    constructCommitObject(feature: any,  isFromCreate:boolean, isAnnotationLine?:boolean) {
        let returnObj = null;
        const layerIdFeild = isFromCreate ? "layerId" : "mapLayerId";
        let obj:CommitChanges = {
            layerId : feature?.properties[layerIdFeild],
            layerName : feature?.properties.layerName ? feature?.properties.layerName : this.getLayerName(+feature?.properties[layerIdFeild]),
            groups : []
        }

        let mode = isFromCreate ? "Added" : "Moved";
        mode = isAnnotationLine ? 'Coordinate Changed' : mode;
        
        const index = this._isObjectPresent(this.mapEditorChangesToBeCommitted, feature, "layerId", layerIdFeild );
        if(index === -1){
          obj.groups = [...obj.groups, this._getGroupsFromFeature(feature, mode, obj)]; 
          this.mapEditorChangesToBeCommitted.push(obj);
          returnObj = obj;
        } else {
          const object = this.mapEditorChangesToBeCommitted[index];
          this._getGroupsFromFeature(feature, mode, object);
          this.mapEditorChangesToBeCommitted[index] = {...object};
          returnObj = object;
        }
        this.addOrUpdateCommitedChanges([...this.mapEditorChangesToBeCommitted]);
        return returnObj;
    }
      
    
    private _getGroupsFromFeature(feature: any, mode:string, commitChange:CommitChanges): FeatureGroup {
        let group : FeatureGroup = {
            groupId : feature?.properties.featureGroupId,
            groupName : (feature?.properties.featureGroupName ? feature?.properties.featureGroupName : 
            this._getNameFromFeatureStyleJson(feature, "featureGroupId", "feature_group_id", "feature_group_name")),
            types : []
        }
        const index = this._isObjectPresent(commitChange.groups, feature, "groupId", "featureGroupId");
        if(index === -1){
            group.types = [...group.types, this._getTypesFromFeature(feature, mode, group)];
            commitChange.groups.push(group);
        } else {
            const object = commitChange.groups[index];
            this._getTypesFromFeature(feature, mode, object);
            group = {...object};
        }
        return group;
    }
    
    private _getTypesFromFeature(feature: any, mode:string, group:FeatureGroup): FeatureType {
        let type:FeatureType = {
            typeId : feature?.properties.featureTypeId,
            typeName : (feature?.properties.featureTypeName ? feature?.properties.featureTypeName : 
            this._getNameFromFeatureStyleJson(feature, "featureTypeId", "feature_type_id", "feature_type_name")),
            features : []
        };
        const index = this._isObjectPresent(group.types, feature, "typeId","featureTypeId");
        if(index === -1) {
            type.features = [this._getFeaturesInfo(feature, mode, type)];
            group.types.push(type);
        } else {
            const object = group.types[index];
            this._getFeaturesInfo(feature, mode, object);
            type = {...object};
        }
        return type
    }

    private _getFeaturesInfo(feature: any, mode:string, type:FeatureType): Feature {
        const ft:Feature = {
            featureId : feature?.properties.featureId ? feature?.properties.featureId : feature?.properties.drawId,
            featureName : feature?.properties.featureName,
            featureMode : mode,
            id : feature.id ? feature.id : feature?.properties.drawId,
            type : feature?.geometry?.type,
            featureObj:feature
        }
        const index = this._isObjectPresent(type.features, feature, "featureId","featureId");
        if(index === -1) {
            type.features.push(ft);
        } else {
            type.features[index] = ft;
        }
        return ft;
    }

    private _isObjectPresent<T>(array:T[], feature:any, field1:any, field2:any) {
        const index = array.findIndex((obj:any)=> +obj[field1] === +feature?.properties[field2]);
        return index;
    }

    private _getNameFromFeatureStyleJson(feature: any, field1: string, field2: string, field3:string): string | undefined {
        const data:any = FeatureTypeStyleMapping.featureTypeStyleJson.find((featureJson:any)=> feature?.properties[field1] === featureJson[field2]);
        return data ? data[field3] : '';
    }

    updateChangesCount() {
        const count = this.getChangesCount();
        this.committedChangesCountAndSaveSub.next({count :count});
    }

    getChangesCount() {
       let count = 0;
       this.mapEditorChangesToBeCommitted.forEach((change)=>{
        change.groups.forEach((group)=>{
            group.types.forEach((type)=>{
                count+= type?.features?.length;
            })
        })
       })
       return count;
    }

    addOrUpdateCommitedChanges(data:CommitChanges[]){
        // add new data
        this.mapEditorChangesToBeCommitted = [...data];
        this.updateChangesCount();
        this.committedChangesSub.next([...this.mapEditorChangesToBeCommitted]);
    }

    //*********************** Commit Changes Code END ****************************************** */


}