import { FeatureGroup } from "src/app/constants/common-model";

export default function getTypeIndexFromGroups(groups: FeatureGroup[], typeId: number) {
  let groupIndex = -1;
  let typeIndex = -1;

  groups.some((group, groupIdx) => {
    groupIndex = groupIdx;
    return group.types.some((type, typeIdx) => {
      typeIndex = typeIdx;
      return parseInt(type.typeId as string) === typeId;
    });
  });

  return {
    groupIndex,
    typeIndex,
  };
}
