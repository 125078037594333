<app-common-table   [tableHeader]="userTableHeader"
[tableData]="usrTableData"
(buttonClickEmitter)="btnClick($event)"
(linkClickEmitter)="linkClick($event)"
(checkBoxEmitter)="onCheckBoxClick($event)" 
idColumnName="userId"
tblClass="table-striped table-bordered custom-table table-colored"></app-common-table>

<!-- Login History Modal -->
<div class="modal fade" id="loginHistory" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
aria-labelledby="loginHistoryLabel" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title custom-modal-title" id="loginHistoryLabel"><span>Login History</span></h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-12">
          <app-common-table [tableHeader]="loginHistoryTblHeader"
          [tableData]="loginHistoryTblData" [tblClass]="modalTable"></app-common-table>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<!--login history-->

<!-- Reset Password Lock Modal -->
<div class="modal fade" id="resetPassword" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="resetPasswordLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 text-center">
            <p class="text-info-size">Are you sure you want to reset the password</p>
          </div>
        </div>
      </div>
      <div class="modal-footer" id="resetPasswordLabel">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-success" data-bs-dismiss="modal" (click)="ResetUserPasswordByAdmin()">Proceed</button>
      </div>
    </div>
  </div>
</div>

<!-- Email Security Reset Information Modal -->
<div class="modal fade" id="ESRInfo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="ESRInfoLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="ESRInfoLabel"><span>Email Security Reset Information</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 d-flex justify-content-center align-items-center" style="height: 200px;">
            <p>Need content</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-success" data-bs-dismiss="modal">Save</button>
      </div>
    </div>
  </div>
</div>

<!-- Add New User Modal -->
<div class="modal fade" id="editUser" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="addNewUserLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="addNewUserLabel"><span>Edit User</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <app-add-edit-user [informationModalData]="informationModalData"></app-add-edit-user>
      </div>
    </div>
  </div>
</div>

  <!--Permissions Modal-->
  <div class="modal fade" id="permissionsEdit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="permissionsEditLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="permissionsEditLabel"><span>Edit Permissions for {{permissionModalData?.name}}
          </span>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="modal_title_view_cb">
            <label class="form-check-label" for="modal_title_view_cb" style="font-size: 20px; padding-top: 5px;">
              Select all
            </label>
          </div>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <app-permission-right
          [systemPermissionTypeData]="systemPermissionTypeData"
          [systemAllPermissionData]="systemAllPermissionData">
        </app-permission-right>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-success" data-bs-dismiss="modal">Save</button>
      </div>
    </div>
  </div>
</div>
<!-- Permissions Modal Ends-->
