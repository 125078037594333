import { Feature } from "src/app/constants/common-model";
import { FileGroup, NewFeature } from "../feature-tree.component.types";

export default function getNewFeature(files: FileGroup[], feature: Feature): NewFeature | null {
  for (const file of files) {
    for (const group of file.groups) {
      for (const type of group.types) {
        const newFeature = type.features.find(f => f.featureId === (feature.featureId || feature.properties?.featureId));
        if (newFeature) {
          return {
            feature: newFeature,
            group,
            type,
            file
          };
        }
      }
    }
  }

  return null;
}
