import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BehaviorSubjectService } from '../services/behaviorsubject.service';
import { MsalService } from '@azure/msal-angular';
import { ConnectionService } from '../modules/sitemapadmin/services/connection-service';
import { PortalAPI } from '../constants/api.constant';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  loggedInStatus: boolean = false;
  loggedInStatusDetermined: boolean = false;
  loggedInStatusSubscription: Subscription;
  loggedInStatusDeterminedSubscription: Subscription;

  showUtility: boolean = false;
  showLaser: boolean = false;
  showConcrete: boolean = false;
  showPipe: boolean = false;
  showMapping: boolean = false;
  showDrone: boolean = false;
  showLocating: boolean = false;
  showLeak: boolean = false;
  toggleActive: boolean = false;


  constructor(
    private behaviourSubjectService: BehaviorSubjectService,
    private authService: MsalService,
    private restService:ConnectionService
  ) {
    if(!sessionStorage.getItem('loginUserId') && localStorage.getItem('loginUserId')) {
      const restServiceBody = {
        "userId": localStorage.getItem('loginUserId'),
        "isLoggedin": false,
        "time": new Date().toISOString()
      }
      this.restService.post(PortalAPI.SET_LOGGEDIN_HISTORY_ASYNC, restServiceBody).subscribe((result:any)=>{
        if (result && result.processingStatus?.status.includes('Success')) {
          let temp:any
          if(sessionStorage.getItem('redirectToUrl')) {
            temp = sessionStorage.getItem('redirectToUrl');
          }
          localStorage.clear();
          sessionStorage.clear();         
           if(temp) {
            sessionStorage.setItem('redirectToUrl',temp);
          }
          this.authService.logout();
        }
      });
    }
    this.loggedInStatusSubscription = this.behaviourSubjectService.loginStatusObservable.subscribe((status) => {
      this.loggedInStatus = status;
    });

    this.loggedInStatusDeterminedSubscription = this.behaviourSubjectService.loginStatusDeterminedObservable.subscribe((status) => {
      this.loggedInStatusDetermined = status;
      if (this.loggedInStatusDetermined && !this.loggedInStatus) {
        // Item-29423 If loggedInStatus is false then send them to the login function which will remove access to the landing page.       
        this.login('client');
      } 
    })
  }

  ngOnInit(): void {  }

  ngOnDestroy() {
    this.loggedInStatusDeterminedSubscription.unsubscribe();
    this.loggedInStatusSubscription.unsubscribe();
  }

  onShowUtilty() {
    this.showUtility = !this.showUtility;
  }
  onShowLaser() {
    this.showLaser = !this.showLaser;
  }
  onShowConcrete() {
    this.showConcrete = !this.showConcrete;
  }
  onShowPipe() {
    this.showPipe = !this.showPipe;
  }
  onShowMapping() {
    this.showMapping = !this.showMapping;
  }
  onShowDrone() {
    this.showDrone = !this.showDrone;
  }
  onShowLocating() {
    this.showLocating = !this.showLocating;
  }
  onShowLeak() {
    this.showLeak = !this.showLeak;
  }

  //# call login function from home page
  login(data: any) {
    // let urlCheck = sessionStorage.getItem('mapViewerUrl');
    let urlCheck = sessionStorage.getItem('redirectToUrl');

    sessionStorage.clear();
    sessionStorage.clear();
    
    if (urlCheck) {
      // sessionStorage.setItem('mapViewerUrl', urlCheck);
      sessionStorage.setItem('redirectToUrl', urlCheck);

    }
    // if (data) {
    //   data = {
    //     state: "angulargprs" //set state parameter (type: string)
    //   };
    // }
    // console.log(data,"home");
    this.behaviourSubjectService.callLoginFromHomePage(data);
  }

  //# on/off toggle menu
  customToggleMenu() {
    this.toggleActive = !this.toggleActive;
  }
}