import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PortalAPI } from 'src/app/constants/api.constant';
import { CommonFunctionService } from 'src/app/services/common-function-service';
import { IRoleList } from '../../constants/manage-roles-data';
import { SitemapRole, SitemapTier } from '../../constants/manage-users-data';
import { ConnectionService } from '../../services/connection-service';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.css']
})
export class AddEditUserComponent implements OnInit {

  dropDownRoles:IRoleList[] = [];
  tenantId: string = "";
  @Output() cancelUserEmitter = new EventEmitter();
  @Input() submitBtnName = "Save";
  @Input() addClick: boolean = false;
  @Input() informationModalData = {
    displayName: "",
    email: "",
    phoneNumber: "",
    rid: 0,
    tierId: 0
  };

  dropDownTiers = [
    {
      value: SitemapTier.Personal,
      name: 'Personal',
    },
    {
      value: SitemapTier.Basic,
      name: 'Basic',
    },
    {
      value: SitemapTier.Enterprise,
      name: 'Enterprise',
    },
  ];
  selectedRole = 1;
  subTier = 1;

  constructor(
    private spinner:NgxSpinnerService,
    private restService:ConnectionService,
    private toastr: ToastrService,
    private commonService:CommonFunctionService
  ) {}

  ngOnInit() {
    this.getTenantID();
    this.getRoleList();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("add click: ",changes.addClick);
    if(changes.addClick) {
      this.getRoleList();
    }
  }

  //#region getRoleList method is use to get all the role list through the GetAllRoles api
  getRoleList()
  {
    this.spinner.show();
    this.dropDownRoles = [];
    this.restService.tenantId = this.tenantId;
    
    this.restService.get(PortalAPI.GET_ALL_ROLES).subscribe(response=>{
      if(response && response.length > 0)
      {
        response.forEach((element:any)=>{
          element.isChecked = false;
        })
        this.dropDownRoles = response;
        this.spinner.hide();
      }
      else
      {
        this.spinner.hide();
      }
    })
  }
  //#endregion

    //#region getTenantID method is used to get azure details from MsalService
    getTenantID()
    {
      const accountDetail = this.commonService.getAccountDetail()[0];
      if(accountDetail.tenantId)
      {
        this.tenantId = accountDetail.tenantId;
      }
      else
      {
        this.tenantId = '1';
      }
    }
    //#endregion

  //#region onRoleSelected is use to get the selected Role from Roles Dropdown
  onRoleSelected(value: any) {}
  //#endregion

  //#region onTierSelected is use to get the selected Tier from Tier Dropdown
  onTierSelected(value: any) {}
  //#endregion

  //#region onSubmit method is use for submit a form
  onSubmit() {
    console.log('form');
    // this.onCancelUserClick();
  }
  //#endregion

  //#region onCancelUserClick method is used to perform an action on cancel user button click
  onCancelUserClick() {
    this.cancelUserEmitter.emit(false);
    (<HTMLFormElement>document.getElementById("userForm")).reset();
  }
  //#endregion
}
