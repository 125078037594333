import { FileGroup } from "../feature-tree.component.types";

export default function getGroupIndexFromFiles(files: FileGroup[], groupId: number) {
  let fileIndex = -1;
  let groupIndex = -1;

  files.some((file, fileIdx) => {
    fileIndex = fileIdx;
    return file.groups.some((group, groupIdx) => {
      groupIndex = groupIdx;
      return parseInt(group.groupId as string) === groupId;
    });
  });

  return {
    fileIndex,
    groupIndex
  };
}
