import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  Renderer2,
  ChangeDetectorRef
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import '@watergis/mapbox-gl-export/css/styles.css';
import html2canvas from 'html2canvas';
import { JsonpClientBackend } from '@angular/common/http';
import { SubscriptionEditAccess } from 'src/app/constants/subscriptionEditAccess';
import { jsPDF } from 'jspdf';
import * as mapboxgl from 'mapbox-gl';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, switchMap, tap , first, retryWhen} from 'rxjs/operators';
import { CommonMapService } from 'src/app/common-component/common-map-service';
import { PortalAPI } from 'src/app/constants/api.constant';
import {
  fileListTableDataDC,
  IFileListModelDC,
} from 'src/app/constants/documentsConstant';
import { DashboardService } from 'src/app/modules/dashboards/services/dashboard/dashboard.service';
import { JobService } from 'src/app/modules/projects/service/job.service';
import { ConnectionService } from 'src/app/modules/sitemapadmin/services/connection-service';
import { ApiService } from 'src/app/services/api.service';
import { AzureBlobStorageServiceVideo } from 'src/app/services/azure-blob-storage.service';
import { CommonFunctionService } from 'src/app/services/common-function-service';
import { MapViewerJobFeaturesService } from 'src/app/services/mapviewerJobFeatures/map-viewer-job-features.service';
import { environment } from 'src/environments/environment';
import { FeatureTypeStyleMapping } from '../../../../constants/featureTypeIdStylingMapping';
import { BehaviorSubjectService } from '../../../../services/behaviorsubject.service';
import { MapEditService } from '../../services/mapedit.service';
import { MapSiteService } from '../../services/mapsite.service';
import { MapSubSiteService } from '../../services/mapsubsite.service';
import { MapViewerService } from '../../services/mapviewer.service';
import { select, Store } from '@ngrx/store';
//import { GetCentroidJobs } from 'src/app/app-state/action/centroid.action';
//import { IAppState } from 'src/app/app-state/state/app.state';
//import { centroidJobsList } from 'src/app/app-state/selectors/centroid.selector';
// @ts-ignore
import { bringLineLayersToTop, bringPointLayersToTop, deselectPointsForVertexEditing, getSelectedLineFeature, setSiteMapDrawMode } from '../../assets/js/sitemap_draw_modes/editing_utils';
// @ts-ignore
import siteMapSimpleSelect from '../../../../../assets/js/sitemap_draw_modes/sitemap_simple_select';
// @ts-ignore
import siteMapDirectSelect from '../../../../../assets/js/sitemap_draw_modes/sitemap_direct_select';
// @ts-ignore
import siteMapDrawPoint from '../../../../../assets/js/sitemap_draw_modes/sitemap_draw_point';
// @ts-ignore
import siteMapDrawLineString from '../../../../../assets/js/sitemap_draw_modes/sitemap_draw_line_string';
// @ts-ignore`
import moveVertexMode from '../../../../../assets/js/sitemap_draw_modes/move_vertex';
// @ts-ignore
import addVertexMode from '../../../../../assets/js/sitemap_draw_modes/add_vertex';
// @ts-ignore
import deleteVertexMode from '../../../../../assets/js/sitemap_draw_modes/delete_vertex';
// @ts-ignore
import mergeLineMode from '../../../../../assets/js/sitemap_draw_modes/merge_line';
// @ts-ignore
import mapboxDrawDefaultTheme from '@mapbox/mapbox-gl-draw/src/lib/theme';
import { PermissionService } from 'src/app/services/common/permission.service';
import { permissionEnum } from 'src/app/constants/userPermissionAccess';
import { MetadataService } from 'src/app/services/metadata.service';
import { RenderScaleService } from 'src/app/common-component/render-scale-service';
import { LayerComponent } from '../layer/layer.component';
import { virtualTourListTableDataDC } from 'src/app/constants/virtualTour.constants';

@Component({
  selector: 'app-mapalljoblist',
  templateUrl: './mapalljoblist.component.html',
  styleUrls: ['./mapalljoblist.component.css'],
})
export class MapalljoblistComponent implements OnInit {
  lineSymbology: any[] = [];
  pointSymbology: any[] = [];

  featureTypeSymbologyMap: { [key: string]: string[] } = {};

  @ViewChild('subSiteModalClose') subSiteModalClos: any;
  map!: mapboxgl.Map;
  /****Defined default style*******/
  currentStyle: any = 'satellite-streets-v11';
  style = 'mapbox://styles/mapbox/satellite-streets-v11';
  lat = 41.9008; //Latitude
  lng = -90.7514; //Longitude
  firstcordinate: any = [];
  sharedJobObj: any = {};
  public MapviewerSiteForm: any;
  public RenderScaleForm: any;
  searchInputSubscription: Subscription | null = null;
  searchTypeSubscription: Subscription | null = null;
  printTypeSubscription: Subscription | null = null;
  headerSearchSubscrition: Subscription | null = null;
  shareJobByHeaderIcon: Subscription | null = null;
  mapViewerDocumentsSubscribe: Subscription | undefined;
  isFeatureTypeClicked: any = {};
  searchStringInput: string = '';
  latitudeSearhStringInput: string = '';
  longitudeSearhStringInput: string = '';
  selectedSearchType = 0;
  isHeaderSearch: boolean = false;
  loginUserDataObj: any;
  searchJobList: any = {};
  searchJobIds: any = [];
  searchMarker: any;
  objSharedUserList: any = [];
  objSharedUserListForSiteSubSite: any = [];
  emailFormControl: FormControl = new FormControl('', [
    Validators.email,
    Validators.required,
  ]);
  shouldFetchedEmailsBeDisplayed: boolean = false;
  fetchedEmails: any = [];
  accessFormControl: FormControl = new FormControl('1');
  sharedObjResponse: any = {};
  modalJobdata: any;
  timeout: any;
  clickedJobItemId: number | null = null;
  clickedJobItemCoordinates: any | null = null;
  clickedJobName: string | null = null;
  loginUserId: any;
  loginUserRole: any;
  loginUserEmailId: any;
  geoJsonFromResponse: any;
  apiAllJobResponse: any;
  featureTypeStyleMap: any;
  zoomedJobIdLayerIdMap: any = new Map();
  zoomedJobIdLayerIdSearchMap: any = new Map();
  currentZoomLevel: any = null;
  previousZoomLevel: any = 3;
  currentZoomLevelSearchMap: any = null;
  previousZoomLevelSearchMap: any = null;
  isMapTypeChanged: boolean = false;
  multipleJobsIdByPoligonDraw: any = [];
  userRenderScale: number = 0;

  //******************************************* */
  mapZoomLevelGreaterThanThreshold: boolean = false;
  searchMapZoomLevelGreaterThanThreshold: boolean = false;
  slideMenuOpenLayerDetails: boolean = false;
  slideMenuOpenSiteDetails: boolean = false;
  slideMenuOpenSiteAdminDetails: boolean = false;
  editMenuDisplay: Boolean = false;

  slideMenuOpenTools: boolean = false;
  slideMenuOpenJobDescription: boolean = false;
  slideMenuOpenFolder: boolean = false;
  navigatedJobName: string | null = null;
  isGroupMapCompleted: boolean = false;
  featureGroupMap: any = new Map();
  siteLayerData: any;
  draw: any = null;
  jobId:any;
  drawHandlePrint: any
  openedFeatureAttributes: any = null;
  openedFeatureProperties: any = null;
  openedFeatureAttributesAddedLink: any = [];
  openedFeatureName: string = '';
  pointFeaturesNameArray: string[] = [];
  shareJobsByClickheaderIcon: string = '';
  pointFeaturesImagesNotPresent: any = [];
  showToggleSideBar: boolean = false;
  isJobOwner: boolean = true;
  dashboardSubscribe: Subscription | null = null;
  mapViewerSubscribe: Subscription | null = null;
  getAllVectorsSubscribe: Subscription | undefined;
  mapViewerJobFeatureSubscribe: Subscription | null = null;
  pointFeaturesIconsAvailableSet: any;
  adminBlobUrl: any;
  isQAenv: string = '';
 // qaEnvValSubscription: Subscription;
  searchData: any;
  allcoordinates: any;
  IsMeasurmentToolActive: any;
  print: any;
  mapEyeVisible: boolean = false;
  isGPRSLayerVisible: boolean = true;
  headerLayerButtonStateSubscription: Subscription;
  isSearchAppliedOnJobData: boolean = false;
  polygonGeoJson: any;
  exportDrawPolygonCordinates: any;
  public exportForm: any;
  showHeaderLayerZoomWarning: boolean = false;
  set: Set<string> = new Set();

  featureGroupToggleStateMap: any = new Map();
  featureGroupTypeToggleStateMap: any = new Map();
  featureGroupTypeFeatureNameToggleStateMap: any = new Map();
  zoomedJobIds: any = [];
  searchedJobGeoJson: any;
  forkJoinMapFeaturesSubscription: Subscription | null = null;
  forkJoinDocumentsSubscription: Subscription | null = null;
  myPreferenceSubscription: Subscription;
  adminGeojsonuploadSubscription: Subscription | null = null;
  siteDataSubscription: Subscription | null = null;
  emailFormControlSubscription: Subscription | null = null;
  finJobsSubscription: Subscription | null = null;
  isLayerEyeVisible: any = {
    SITE: true,
    GPRS: true,
    CLIENT: true,
    IMPORTED: true,
    EXTERNAL: true,
  };
  mapIntegrationCenterAndZoomLevelDataSubscription: Subscription | null = null;
  mapIntegrationCenterAndZoomLevelData: any = null;
  routeParamSubscription: Subscription | null = null;
  params: any;
  intervalId: any = null;
  boundsFromFitBound: any;
  tierIDData: any = [];
  screenHeight: any;
  screenWidth: any;
  allowShare: boolean = false;
  isJobShared: boolean = false;
  isDocumentShared: boolean = false;
  navigatedFeatures: any[] = [];
  pointLayerIds: any[] = [];
  lineLayerIds: any[] = [];
  zoomedFeaturesObservableArray: any[] = [];
  zoomedDocumentsObservableArray: any[] = [];
  zoomedImportedImageObservableArray: any[] = [];
  jobDocumentsList: any = [];
  @Input() fileTableData: IFileListModelDC[] = [];
  fileTableHeader = new fileListTableDataDC().tblHeader;
  // dprFileDownloadSAS: string =
  // '?sp=r&st=2022-09-30T13:40:04Z&se=2023-03-30T21:40:04Z&spr=https&sv=2021-06-08&sr=c&sig=Xg1ydasnsm%2FXj2u32mpm9d9%2F1OTl%2Fl0xYzBYJMbhpC0%3D';
  dprFileDownloadSAS: string = '';
  dprFileSWAPSDownloadSAS: string = '';
  previousZoomJobId: any[] = [];
  siteAddForm!: FormGroup;

  siteData: any = [];
  siteCoordinate: any = [];
  subSiteModalData: any = {
    siteSelectedFromSubSiteDropdown: Number(0),
    subSiteName: '',
    description: '',
    searchedUserEmail:''
  };
  clickSiteOrSubSiteID: any;
  associateJobIds: any = [];
  deassociateJobIds: any = [];
  zoomOnMapParamVal: any;
  isSiteEditingModeIsOn: boolean = false;
  siteEditId: any;
  isSiteEditClick: any;
  isSubSiteEditClick: any;
  viewSiteOrSubSiteStatus: string = 'site';
  siteOrSubSiteDetailsByClick: any;
  previousClickPolygon: any;
  editSiteData: any;
  isSiteSubEditProperties: any;
  editSiteForm: boolean = false;
  subSiteModalHeader: any = 'Add';
  toggleApiLoaded = false;
  editFormFeatureDescriptionView: boolean = true;
  currentRenderScale:any="1000";

  //for print start
  printForm!: FormGroup;
  isPrintSession: boolean = false;
  printState: boolean = false;
  counter: number = 0;
  //for print end
  tempGroupJobIds: any[] = [];
  // for External Layer Tab
  externalLayerjobs: any = [];
  externalLayerData: any = [];
  externalLayerDataForSubsite: any = [];
  externalLayerJobsForSubsite: any = [];
  allZoomedJobs: any = [];

  iframeExternalContentLink: boolean = true;

  externalContentTemplateData = {
    siteId: '',
    woNo: '',
    siteName: '',
    featureName: '',
    featureLink: '',
  };
  modalHeader: any;
  subSiteNameDetailForSitePopup: any = [];
  jobDetailForSitePopup: any = [];
  jobDetailForSubSitePopup: any = [];
  editDescriptionValue: any;
  featureDetailOnClick: any;
  currentDate: any;
  loginUserTierId!: any;
  editCheck = new SubscriptionEditAccess(this.commonService).getEditAccess();

  // SITE related
  country: any = [];
  dropdownState: any = [];
  dropdownCity: any = [];
  featureData: any = {};

  isEditingModeIsOn: boolean = false;
  newlyAddedFeatureProperties: any;

  numberOfClicks: number = 0;
  enableEditModelOnDblClick: boolean = false;
  clickedFeaturesForLayer: any[] = [];
  clickedFeatureLat: any;
  clickedFeatureLng: any;
  selectedFeatureFromDraw:any;
  previousStyle: any = this.currentStyle;
  scaleError: string="";
  currentFeatureToCommit:any;
  layerSiteData: any = [];
  //centroidData:any = this._store.pipe(select(centroidJobsList));
  dragMap: boolean = false;
  zoomMap: boolean = false;
  newAddedFeatureName: any;
  addNewFeatureTypeFrMapEditor: any;
  addNewFeatureGrpFrMapEditor: any;
  addNewFeatureGrpIdFrMapEditor: any;
  addNewFeatureTypeIdFrMapEditor: any;
  currentEditinglayerId: any;
  navigatedJobId: any;
  isSnappingToVertex: boolean = false;
  isSnappingToLine: boolean = false;
  editingState:any;
  origZoomJobIdsFeaturesForSearch:any;
  matterportTableHeader= new virtualTourListTableDataDC().tblHeader;

        /* Draw-related variables */
  // Mapbox Default Draw Styles with SiteMap filter
  drawDefaultstylesCustom = mapboxDrawDefaultTheme.map((style: any) => {
    style.filter.push(['!has', 'user_featureType']);
    style.filter.push(['!has', 'user_isPrintBox']);
    return style
  });
  // All Sitemap styles applied to Sitemap Draw features
  drawSiteMapStyles = [
    {
      id: 'sitemap-points-highlight-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'false'],
        ['has', 'user_featureType']
      ],
      paint: {
        'circle-color': 'yellow',
        'circle-radius': 0
      },
    },
    {
      id: 'sitemap-points-symbol-inactive',
      type: 'symbol',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'false'],
        ['has', 'user_featureType']
      ],
      layout: {
        'icon-allow-overlap': true,
        visibility: 'visible',
        'icon-image': ['get', 'user_featureType'], // expects "featureType" to have same name as the loaded icon
        'icon-size': 0.3,
      },
    },
    {
      id: 'sitemap-points-highlight-active',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'true'],
        ['has', 'user_featureType']
      ],
      paint: {
        'circle-color': 'yellow',
        'circle-radius': 14
      },
    },
    {
      id: 'sitemap-points-symbol-active',
      type: 'symbol',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'true'],
        ['has', 'user_featureType']
      ],
      layout: {
        'icon-allow-overlap': true,
        visibility: 'visible',
        'icon-image': ['get', 'user_featureType'], // expects "featureType" to have same name as the loaded icon
        'icon-size': 0.3,
      },
    },
    {
      id: 'sitemap-lines-highlight-inactive',
      type: 'line',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'false'],
        ['has', 'user_featureType']
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'yellow',
        'line-width': 0,
      },
    },
    {
      id: 'sitemap-lines-inactive',
      type: 'line',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'false'],
        ['has', 'user_featureType']
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': this.getLineStyle('line-color'),
        'line-width': 3,
      },
    },
    {
      id: 'sitemap-lines-highlight-active',
      type: 'line',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'true'],
        ['has', 'user_featureType']
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'yellow',
        'line-width': 5,
      },
    },
    {
      id: 'sitemap-lines-active',
      type: 'line',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'true'],
        ['has', 'user_featureType']
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': this.getLineStyle('line-color'),
        'line-width': 3,
      },
    },
    {
      id: 'sitemap-vertexes-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'vertex'],
        ['==', 'active', 'false'],
        // ['has', 'user_featureType'] This does not seemt to work on "vertex"
      ],
      paint: {
        'circle-radius': 3,
        'circle-color': 'blue',
        'circle-stroke-color': 'lightblue',
        'circle-stroke-width': 2
      },
    },
    {
      id: 'sitemap-vertexes-active',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'vertex'],
        ['==', 'active', 'true'],
        // ['has', 'user_featureType'] This does not seemt to work on "vertex"
      ],
      paint: {
        'circle-radius': 3,
        'circle-color': 'blue',
        'circle-stroke-color': 'darkblue',
        'circle-stroke-width': 2
      },
    },
    {
      id: 'sitemap-midpoints',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'midpoint'],
        // ['has', 'user_featureType'] This does not seemt to work on "midpoint"
      ],
      paint: {
        'circle-radius': 2,
        'circle-color': 'blue',
      },
    }
  ];
  // Style for the Print Box
  drawPrintBoxStyles = [
    {
      'id': 'gl-draw-polygon-fill-inactive-print-box',
      'type': 'fill',
      'filter': ['all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static'],
        ['has', 'user_isPrintBox']
      ],
      'paint': {
        'fill-color': 'blue',
        'fill-outline-color': 'blue',
        'fill-opacity': 0.01
      }
    },
    {
      'id': 'gl-draw-polygon-fill-active-print-box',
      'type': 'fill',
      'filter': ['all',
        ['==', 'active', 'true'],
        ['==', '$type', 'Polygon'],
        ['has', 'user_isPrintBox']
      ],
      'paint': {
        'fill-color': 'darkblue',
        'fill-outline-color': 'darkblue',
        'fill-opacity': 0.1
      }
    },
    {
      'id': 'gl-draw-polygon-stroke-inactive-print-box',
      'type': 'line',
      'filter': ['all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static'],
        ['has', 'user_isPrintBox']
      ],
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': 'blue',
        'line-width': 4
      }
    },
    {
      'id': 'gl-draw-polygon-stroke-active-print-box',
      'type': 'line',
      'filter': ['all',
        ['==', 'active', 'true'],
        ['==', '$type', 'Polygon'],
        ['has', 'user_isPrintBox']
      ],
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': 'darkblue',
        'line-width': 6
      }
    },
    {
      'id': 'gl-draw-polygon-midpoint-print-box',
      'type': 'circle',
      'filter': ['all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'midpoint'],
        ['has', 'user_isPrintBox']],
      'paint': {
        'circle-radius': 50,
        'circle-color': 'red'
      }
    },
    {
      'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive-print-box',
      'type': 'circle',
      'filter': ['all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['has', 'user_isPrintBox']
      ],
      'paint': {
        'circle-radius': 50,
        'circle-color': 'red'
      }
    },
    {
      'id': 'gl-draw-polygon-and-line-vertex-inactive-print-box',
      'type': 'circle',
      'filter': ['all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['has', 'user_isPrintBox']
      ],
      'paint': {
        'circle-radius': 50,
        'circle-color': 'red'
      }
    },
    {
      'id': 'gl-draw-polygon-and-line-vertex-active-print-box',
      'type': 'circle',
      'filter': ['all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['has', 'user_isPrintBox']
      ],
      'paint': {
        'circle-radius': 50,
        'circle-color': 'red'
      }
    },
    {
      'id': 'gl-draw-point-active-print-box',
      'type': 'circle',
      'filter': ['all',
        ['==', '$type', 'Point'],
        ['!=', 'meta', 'midpoint'],
        ['==', 'active', 'true'],
        ['has', 'user_isPrintBox']],
      'paint': {
        'circle-radius': 50,
        'circle-color': 'red'
      }
    }
  ];
  // Line Start/End Point Features
  drawLineStartEndStyles = [
    {
      id: 'line-start-point-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'false'],
        ['has', 'user_isStartPoint']
      ],
      paint: {
        'circle-color': 'red',
        'circle-radius': 5,
      },
    },
    {
      id: 'line-start-point-active',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'true'],
        ['has', 'user_isStartPoint']
      ],
      paint: {
        'circle-color': 'red',
        'circle-radius': 5,
      },
    },
    {
      id: 'line-end-point-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'false'],
        ['has', 'user_isEndPoint']
      ],
      paint: {
        'circle-color': 'green',
        'circle-radius': 5,
      },
    },
    {
      id: 'line-end-point-active',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'true'],
        ['has', 'user_isEndPoint']
      ],
      paint: {
        'circle-color': 'green',
        'circle-radius': 5,
      },
    },
  ];
  // Style for temporary "snap" feature
  drawSnapFeatureStyles = [
    {
      id: 'snap-point-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'false'],
        ['has', 'user_isSnapFeature']
      ],
      paint: {
        'circle-color': 'orange',
        'circle-radius': 5,
        'circle-stroke-color': 'steelblue',
        'circle-stroke-width': 2
      },
    },
    {
      id: 'snap-point-active',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'true'],
        ['has', 'user_isSnapFeature']
      ],
      paint: {
        'circle-color': 'orange',
        'circle-radius': 5,
        'circle-stroke-color': 'steelblue',
        'circle-stroke-width': 2
      },
    },
  ];

  // Draw Options
  drawOptions = {
    displayControlsDefault: false,
    userProperties: true,
    controls: {
      polygon: true,
      line_string: true,
      // point: true,
      trash: true,
    },
    modes: {
      ...MapboxDraw.modes,
      sitemap_simple_select: siteMapSimpleSelect(),
      sitemap_direct_select: siteMapDirectSelect(),
      sitemap_draw_point: siteMapDrawPoint(),
      sitemap_draw_line_string: siteMapDrawLineString(),
      move_vertex: moveVertexMode(),
      delete_vertex: deleteVertexMode(),
      add_vertex: addVertexMode(),
      merge_line: mergeLineMode()
    },
    styles: [
      ...this.drawDefaultstylesCustom,
      ...this.drawPrintBoxStyles,
      ...this.drawSiteMapStyles,
      ...this.drawLineStartEndStyles,
      ...this.drawSnapFeatureStyles
    ],
  };
  addGroupFeatureType: any
  toggleEditingState: any;
  isDrawing :boolean = false;
  disablePointInteractions :boolean = false;
  addVertexLineId:any;
  mergeParentLineId: any;
  mergeChildLineId: any;
  mergeParentOrChild: any;
  shouldSiteFetchedEmailsBeDisplayed: boolean = false;
  fetchedSiteEmails: any[] = [];
  newlyAddedSiteValue: any;
  newlyAddedSubSiteValue: any;
  currentEvent: any;
  printType: string ='';
  permissionEnumList:any = permissionEnum;
  firstSecondLineFeatureFrMergedArr:any
  clickedDocFeatures: any;
  isAssigned: any=false;
  isToggleApplied: boolean = false;
  matterportLinkArray: any;


  constructor(
    private behaviourSubjectService: BehaviorSubjectService,
    private fb: FormBuilder,
    private router: Router,
    private jobservice: JobService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private cdRef: ChangeDetectorRef,
    private dashboardservice: DashboardService,
    private apiService: ApiService,
    private mapViewerService: MapViewerService,
    private mapViewerJobFeatureService: MapViewerJobFeaturesService,
    private beahviourSubjectService: BehaviorSubjectService,
    private route: ActivatedRoute,
    private commonService: CommonFunctionService,
    private commonMapService: CommonMapService,
    private blobService: AzureBlobStorageServiceVideo,
    public subSiteServices: MapSubSiteService,
    public siteServices: MapSiteService,
    private restService: ConnectionService,
    private mapEditService: MapEditService,
    private renderer: Renderer2,
    private permissionService: PermissionService,
   // private _store: Store<IAppState>,
    private metadataService:MetadataService,
    private renderScaleService:RenderScaleService
  ) {
    this.siteAddFormGenerate();
    this.printFormGenerate();
    this.metadataService.loadGroupTypeMetadata();

     /**Print subscription start */
     this.counter = 0;
     this.printTypeSubscription = this.behaviourSubjectService.printTriggerHeader.subscribe((printType) => {
      //console.log(printType, '000000000000')
      this.printType = printType;
      if(this.printType =='print'){
         this.onPrintShowPrintBox(this.currentEvent);
         this.printState = false;
         if(this.counter == 0){
           this.counter = 1;
         }

       }else{
        this.counter = 0;
       }
     });
    /**Print subscription end */

    this.dprFileDownloadSAS = this.blobService.dprFilesContainerSAS;
    this.dprFileSWAPSDownloadSAS = this.blobService.dprFileSWAPSDownloadSAS;
    this.firstcordinate = [this.lng, this.lat];
    this.featureTypeStyleMap = new Map(
      JSON.parse(sessionStorage.featureTypeStyleMap)
    );
    this.pointFeaturesNameArray = JSON.parse(
      sessionStorage.pointFeaturesNameArray
    );
    this.pointFeaturesIconsAvailableSet = new Set(this.pointFeaturesNameArray);

    // this.qaEnvValSubscription =
    //   this.behaviourSubjectService.isQAenvObbservable.subscribe((val) => {
    //     this.isQAenv = val;
    //   });

    this.myPreferenceSubscription =
      this.behaviourSubjectService.myPreferencesObservable.subscribe((Obj) => {
        this.IsMeasurmentToolActive = Obj.MEASUREMENT_TOOLS;
        if (this.IsMeasurmentToolActive === 'FALSE') {
          this.drawOptions.controls = {
            polygon: false,
            line_string: false,
            trash: false,
          };
        } else {
          this.drawOptions.controls = {
            polygon: true,
            line_string: true,
            trash: true,
          };
        }
      });
    this.headerLayerButtonStateSubscription =
      this.beahviourSubjectService.headerLayerButtonClickStateObservable.subscribe(
        (val) => {
          this.userRenderScale = this.renderScaleService.getMinLevelForScale();
          if (this.map?.getZoom() >= this.userRenderScale) {
            if (val != null) {
              if (this.slideMenuOpenLayerDetails) {
                this.slideMenuOpenLayerDetails = false;
              } else {
                this.slideMenuOpenJobDescription = false;
                this.slideMenuOpenTools = false;
                this.slideMenuOpenFolder = false;
                this.slideMenuOpenLayerDetails = true;
                this.slideMenuOpenSiteDetails = true;
              }
            }
          } else {
            if (val != null) {
              if (this.showHeaderLayerZoomWarning) {
                this.toastr.warning(
                  'Layers become available once features are visible. Please zoom in to view.',
                  '',
                  {
                    timeOut: 2500,
                  }
                );
              } else {
                this.showHeaderLayerZoomWarning = true;
              }
            }
          }
        }
      );
  }

  /***** ngOnit START *******/
  ngOnInit() {
    this.spinner.show();
    this.onResize();


    this.behaviourSubjectService.multipleShareJobHeaderIcon(' ');
    this.exportForm = this.fb.group({
      filetype: 1,
      filename: ['', Validators.required],
    });
    let cdate = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    this.currentDate = cdate.getDate() + " " + monthNames[cdate.getMonth()] + " " + cdate.getFullYear();

    if (sessionStorage.getItem("loginCheck") === undefined || !sessionStorage.getItem("loginCheck")) {
       this.shareJobByHeaderIcon?.unsubscribe();

      if (sessionStorage.getItem("loginUserId")) {
        this.loginUserId = sessionStorage.getItem("loginUserId");
      }
      if (sessionStorage.getItem("loginUserEmailId")) {
        this.loginUserEmailId = sessionStorage.getItem("loginUserEmailId");
      }
      if (sessionStorage.getItem('tierId')) {
        this.loginUserTierId = sessionStorage.getItem('tierId');
      }
      if (sessionStorage.getItem('loginUserRole')) {
        this.loginUserRole = sessionStorage.getItem('loginUserRole');
      }
      // end site data load
      this.siteDataSubscription = this.apiService.getSiteList(this.loginUserId, this.allZoomedJobs).subscribe(
        (response: any) => {
          this.layerSiteData = response;
      });
      // end site data load

         if (this.loginUserRole != 'Admin' && this.loginUserRole != 'Project Manager') {
            this.userDetailsLoadOnMap(true,this.loginUserId,this.loginUserRole);
          }
          /****API CALL for role CLient or Project Manager END****/
          /**** for ADMIN ROLE */
          else {
            this.adminORPMDetailsLoadOnMap(true,this.loginUserId,this.loginUserRole);
          }



      this.routeParamSubscription = this.route.params.subscribe((params) => {
        this.params = params;
        const centre = [params.lng, params.lat];
      });

       // Fetch Shared Job Details
       this.behaviourSubjectService.jobSharedObservable.subscribe((jobObj) => {
        if (Object.keys(jobObj).length != 0) {
          this.sharedJobObj = jobObj;
          //this.isJobShared = true;
          this.isDocumentShared = false;
          this.dashboardservice.fetchSharedWithUserList(this.sharedJobObj.jobId, 1).subscribe((data) => {
            this.objSharedUserList = data;
          });
          if (this.sharedJobObj.isShared) {  // not assinged (not owner)
            this.isJobOwner = false;
          }else{
            this.isJobOwner =  true;
          }

          if (this.loginUserRole == 'Admin' || this.loginUserRole =='Project Manager') {
            this.isJobOwner = true;
          }
        }
      });

      this.mapIntegrationCenterAndZoomLevelDataSubscription = this.behaviourSubjectService.currentMapIntegrationCenterAndZoomLevelData.subscribe((val) => {
        if (val != null) {
          this.mapIntegrationCenterAndZoomLevelData = val;
         this.behaviourSubjectService.updateMapIntegrationCenterAndZoomLevelData(null);
        }
      })
      this.searchInputSubscription = this.behaviourSubjectService.headerMapSearchStringObservable.subscribe((searchString) => {
        this.searchStringInput = searchString;
      });
      this.searchTypeSubscription = this.behaviourSubjectService.headerMapSearchTypeObservable.subscribe((searchType) => {
        this.selectedSearchType = searchType;
      });
      this.headerSearchSubscrition = this.behaviourSubjectService.isHeaderMapSearchObservable.subscribe((result) => {
        this.isHeaderSearch = result;
        /****doubt - why these three lines?*****/
        const latlong = this.searchStringInput.split(',');
        this.latitudeSearhStringInput = latlong[0];
        this.longitudeSearhStringInput = latlong[1];
        /****doubt - why search marker is used here?*****/
        if (this.isHeaderSearch) {
          if (this.selectedSearchType == 4) {
            if (this.searchMarker) {
              this.searchMarker.remove();
            }
            if (this.commonService.checkRangeValueForLatLong(this.latitudeSearhStringInput, 'lat')
              && this.commonService.checkRangeValueForLatLong(this.longitudeSearhStringInput, 'long')) {
              if (this.searchMarker) {
                this.searchMarker.remove();
              }
              this.searchJobsData();
            } else {
              alert("Latitude in range between -90 to 90 & Longitude in range between -180 to 180");
            }

          } else {
            if (this.searchMarker) {
              this.searchMarker.remove();
            }
            this.searchJobsData();
          }
        }

      });

      this.shareJobByHeaderIcon = this.behaviourSubjectService.isJobShareByHeaderIconObservable.subscribe((headershare) => {
        this.shareJobsByClickheaderIcon = headershare;
        if (this.shareJobsByClickheaderIcon == 'mapviewer') {
          this.openModalforMultipleShareJob();
        }
      });

      this.MapviewerSiteForm = this.fb.group({
        mapType: 'satellite-v9'
      });


      //# enter email to search email address of users for share jobs
      this.emailFormControlSubscription = this.emailFormControl.valueChanges.subscribe((value) => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.fetchedEmails = []
          this.allowShare = false;
          if (value != "" && !this.fetchedEmails.includes(value)) {
            this.tierIDData = [];
            this.dashboardSubscribe = this.dashboardservice.fetchEmails(value).subscribe((data) => {
              if (data.length != 0) {
                data.forEach((val: any, key) => {
                  this.fetchedEmails.push(val.emailId);
                });

              }
              if (this.fetchedEmails.length != 0) {
                this.shouldFetchedEmailsBeDisplayed = true;
              } else {
                this.shouldFetchedEmailsBeDisplayed = false;
              }
            })

          } else {
            this.fetchedEmails = [];

            this.shouldFetchedEmailsBeDisplayed = false;
          }
        }, 1750)
      });
    }
    this.spinner.hide();
    this.accessFormControl.patchValue('1');
    this.commonMapService.mapToolTitleChange();

    this.searchInputSubscription = this.behaviourSubjectService.headerMapSearchStringObservable.subscribe((searchString) => {
      this.searchStringInput = searchString;
    });



    this.siteServices.isExternalSaveSiteObservable.subscribe(data => {
      if (data !== 0) {
        this.externalTabSelected(data);
      }
    })
    this.subSiteServices.isExternalSaveSubSiteObservable.subscribe(data => {
      if (data !== 0) {
        this.externalTabSelectedforSubSiteId(data);
      }
    })

    this.currentRenderScale = sessionStorage.getItem("userRenderingScale");
    this.userRenderScale = this.renderScaleService.getMinLevelForScale();
  }

  adminORPMDetailsLoadOnMap(fromConstructor: boolean, loginUserId: any, loginUserRole: any) {
    this.spinner.show();
    this.createMap();
    this.mapLoadOnPageLoad(loginUserRole, loginUserId);
    this.adminGeojsonuploadSubscription = this.mapViewerJobFeatureService.adminGeoJsonUpload(loginUserId).subscribe((response) => {
      this.adminBlobUrl = response;
    }, (err) => {
      console.log(err);
    });
    // if(!fromConstructor && data?.searchedUserDetail && data?.layerSiteData) {
    //   this.subSiteModalData.searchedUserEmail = data?.searchedUserDetail?.emailId;
    //   this.siteData = data?.layerSiteData;
    // }
    this.spinner.hide();
  }

  userDetailsLoadOnMap(fromConstructor: boolean = false,userId:any,loginUserRole:any) {
    let userInfo = { userId: userId};
    this.dashboardSubscribe = this.dashboardservice.getAllJobLatLong(userInfo).subscribe((response) => {

      this.apiAllJobResponse = response;
      
      this.geoJsonFromResponse = {
        "type": "FeatureCollection",
        "features": this.apiAllJobResponse.features
      }
      if(!fromConstructor) {
        this.map.remove();
      }
      this.createMap();
      this.mapLoadOnPageLoad(loginUserRole, userId);

      this.fitBound(this.apiAllJobResponse);
      this.spinner.hide();
    }, (err) => {
      console.log(err);
    });
  }
  /***** ngOnit END *******/

  onSlideToggleClicked(slideOptions: any) {
    this.slideMenuOpenSiteAdminDetails = slideOptions.slideMenuOpenSiteAdminDetails;
    this.slideMenuOpenSiteDetails = slideOptions.slideMenuOpenSiteDetails;
    this.slideMenuOpenLayerDetails = slideOptions.slideMenuOpenLayerDetails;
    this.slideMenuOpenTools = slideOptions.slideMenuOpenTools;
    this.slideMenuOpenJobDescription = slideOptions.slideMenuOpenJobDescription;
    this.slideMenuOpenFolder = slideOptions.slideMenuOpenFolder;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  @HostListener('document:keydown.delete', ['$event'])
  onDeleteComponent(event: KeyboardEvent) {
   this.draw?.delete(this.draw?.getSelected()?.features?.[0]?.id);
  }

  createMap() {
    const tileServerUrl = environment.TILES_MVT_BASE_URL;
    const accessToken = sessionStorage.getItem("token");
    console.log("tileserver url :", tileServerUrl);

    /**** Map Create on Page Load*******/
    (mapboxgl as any).accessToken = environment.mapbox.accessToken;
    this.map = new mapboxgl.Map({
      container: 'mapviewer',
      style: this.style,
      center: [-103.5917, 40.6699],
      zoom: 3,
      renderWorldCopies: false,
      transformRequest: (url: string, resourceType: string) => {
        if ((resourceType === 'Tile' || resourceType === 'Image') && url.indexOf(tileServerUrl) > -1) {
          // console.log("transformRequest If block");
          return {
            url: url,
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
          };
        } else {
          // console.log("transformRequest else block");
          return {
            url: url
          }
        }
      }
    });
    this.draw = new MapboxDraw(this.drawOptions);
    this.drawHandlePrint = MapboxDraw;
    this.mapEditService.draw = this.draw;
    this.map.addControl(this.draw, 'top-left');
    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disableRotation();
    this.map.keyboard.disable();

    this.map.addControl(new mapboxgl.NavigationControl(), 'top-left');
    // this.map.addControl(new mapboxgl.NavigationControl({showCompass: true, showZoom: false}), 'top-left');
    // this.map.addControl(new MapboxExportControl(mapboxgl as any), 'top-left');
    const scale = new mapboxgl.ScaleControl({
      unit: 'imperial',
    });
    this.map.addControl(scale, 'bottom-left');
    //this.map.addControl(scale, 'bottom-left');
    scale.setUnit('imperial');
    this.registerDragEventInMap();
    //this.map.addControl(scale, "bottom-left");
    this.commonMapService.mapToolTitleChange();
  }

  //# fitbound
  fitBound(jobsFeature: any, mapMaxZoom:number = 3) {
    let bounds = new mapboxgl.LngLatBounds();
    jobsFeature.features?.forEach((feature: any) => {
      bounds.extend(feature.geometry.coordinates);
    });
    if (jobsFeature.features.length > 0) this.boundsFromFitBound = bounds;
    this.map.fitBounds(bounds, {
      center: [-103.5917, 40.6699],
      //padding: 100,
      zoom: mapMaxZoom,
    });
  }

  fitBoundAddressSearch(jobsFeature: any, mapMaxZoom:number = 10) {
    let bounds = new mapboxgl.LngLatBounds();
    jobsFeature.features?.forEach((feature: any) => {
      bounds.extend(feature.geometry.coordinates);
    });
    if (jobsFeature.features.length > 0) this.boundsFromFitBound = bounds;
    this.map.fitBounds(bounds, {
      padding: 100,
      maxZoom: mapMaxZoom,
    });
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    this.currentEvent = event
    if (event.target.id == 'viewJobBtn') {
      this.router.navigate([`/mapviewerjobs/${this.clickedJobItemId}`]);
    }
    if (event.target.id == 'shareJobBtn') {
      this.behaviourSubjectService.changeJobShared({ jobId: this.clickedJobItemId, isShared: this.isJobShared }); // static obj
    }
  }


  mapLoadOnPageLoad(loginUserRole:any, userId?:any) {
    this.map.on('load', (e:any) => {
      this.zoomOnMapParamVal = e;
      if (loginUserRole != 'Admin' && loginUserRole != 'Project Manager') {
        this.map.addSource('allJobs', {
          type: 'geojson',
          data: this.geoJsonFromResponse,
        });
        // Working on bounds and fitting bounds to map canvas
        const bounds = new mapboxgl.LngLatBounds();
        this.geoJsonFromResponse.features?.forEach((feature: any) => {
          //bounds.extend(JSON.parse(feature.geometry.coordinates));
          bounds.extend(feature.geometry.coordinates);
        });
        if (this.geoJsonFromResponse.features.length > 0) {
          this.map.fitBounds(bounds, {
            padding: 100,
            maxZoom: 10
          });
        }

      //  this.addVectorTilesLayersAndSourceNew();

        this.mapClickInputFunction();
        this.mouseEnterOnMap();
        this.mouseLeaveOnMap();
        this.zoomOnMap();
        this.addDrawEvent();
        if(!this.slideMenuOpenSiteAdminDetails)
          this.getSiteList(false, undefined, true);
      } else {
        this.addSourceInMap();
      }
    });

    this.map.on('style.load', () => {
      this.map.once('idle', (e) => {
        if (
          loginUserRole != 'Admin' &&
          loginUserRole != 'Project Manager'
        ) {
          if(this.map.getSource("allJobs") === undefined) {
            this.map.addSource('allJobs', {
              type: 'geojson',
              data: this.geoJsonFromResponse,
            });
          }
          // load access types for all jobs for client
          //this.metadataService.loadJobToUserAccessMetadata(this.geoJsonFromResponse?.features, userId);
          this.addLayerInMap();
          // this.addVectorTilesLayersAndSourceNew();
          this.addGroupLayers();
          this.addAllIconsInMap();
          this.zoomOnMap();
          this.mapClickInputFunction();
          this.mouseEnterOnMap();
          this.mouseLeaveOnMap();
          this.addDrawEvent();
        } else {
          // this.addAllIconsInMap();
          // this.addLayerInMap();
          // this.addGroupLayers();
          this.addSourceInMap();
        }
        if (this.params.lng != 'null') {
          this.checkExpandedMapIntegrationEvent();
        }
        if (this.isMapTypeChanged) {

          // this.createFeatureLayers();
          this.isMapTypeChanged = false;
          
          setTimeout(() => { 
            if (
              this.loginUserRole != 'Admin' &&
              this.loginUserRole != 'Project Manager'
            ) {         
              this.commonMapService.filterMVTOnZoomIn(this.zoomedJobIds, this.map);
            }
         }, 200);
        }
      });
    });
  }

  //# source data by geojson
  addSourceInMap() {
    if (this.map.getSource('allJobs') === undefined)
    this.map.addSource('allJobs', {
      type: 'geojson',
      data: PortalAPI.DATA_SOURCE,
    });

        this.addAllIconsInMap();
        this.addLayerInMap();
        this.addGroupLayers();
        this.mapClickInputFunction();
        this.mouseEnterOnMap();
        this.mouseLeaveOnMap();
        this.zoomOnMap();
        this.addDrawEvent();
        this.getSiteList(false, undefined, true);
        this.getSubSiteList(false, undefined, true);
        if(this.map.getZoom() > 13.25) {
          this.getDocumentsOnZoom();
        }
      //}
    //});
  }
  //# map load on page load with job points


  checkExpandedMapIntegrationEvent() {
    this.spinner.show();
    this.intervalId = null;
    if (!this.intervalId) {
      this.intervalId = setInterval(() => {
        this.executeExpanded();
      }, 3000);
    }
    this.spinner.hide();
  }

  executeExpanded() {
    let bounds: any = [];
    // if (this.loginUserRole != 'Admin' && this.loginUserRole != 'Project Manager') {
    //   bounds = this.boundsFromFitBound;
    // }else{
    const swCoordinates: any = [473, 772];
    const neCoordinates: any = [1486, 270];
    bounds = [swCoordinates, neCoordinates];
    // }
    var features = this.map.queryRenderedFeatures(bounds, {
      layers: ['unclustered-point'],
    });
    if (features.length > 0) {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.spinner.hide();
      this.map.flyTo({
        center: [this.params.lng, this.params.lat],
        zoom: this.params.zoom,
      });
    }
  }

  btnShowVT(event:any) {
    //console.log("show vt :", event.data.url);
    this.iframeExternalContentLink = true;
    this.cdRef.detectChanges();
    let iframeExternal = document.getElementById("externalContentLink") as HTMLIFrameElement;
    iframeExternal.src = event.data.url;
  }

  addVectorTilesLayersAndSource() {
    // if (this.isQAenv === 'qa') {
    //   // Add the source for all line features
    //   // Set the "map_job_id" as the "feature id"
    //   this.map.addSource('pgtiles-custom-lines', {
    //     type: 'vector',
    //     tiles: [ PortalAPI.CUSTOME_LINES ],
    //     promoteId: 'featureId',
    //   });

    //   // Add the source for all point features
    //   // Set the "map_job_id" as the "feature id"
    //   this.map.addSource('pgtiles-custom-points', {
    //     type: 'vector',
    //     tiles: [
    //       'https://py.gprssitemap.com:8443/api/v1/mvttileserver/custom_points/{z}/{x}/{y}.mvt',
    //     ],
    //     promoteId: 'featureId',
    //   });

    //   this.map.addSource('pgtiles-custom-annotation-points', {
    //     type: 'vector',
    //     tiles: [
    //       'https://py.gprssitemap.com:8443/api/v1/mvttileserver/custom_annotation_points/{z}/{x}/{y}.mvt',
    //     ],
    //     promoteId: 'featureId',
    //   });

    //   this.map.addSource('pgtiles-custom-annotation-lines', {
    //     type: 'vector',
    //     tiles: [
    //       'https://py.gprssitemap.com:8443/api/v1/mvttileserver/custom_annotation_lines/{z}/{x}/{y}.mvt',
    //     ],
    //     promoteId: 'featureId',
    //   });
    // } else {
      // Add the source for all line features
      // Set the "map_job_id" as the "feature id"
      this.map.addSource('pgtiles-custom-lines', {
        type: 'vector',
        tiles: [PortalAPI.CUSTOME_LINES ],
        promoteId: 'featureId',
      });

      // Add the source for all point features
      // Set the "map_job_id" as the "feature id"
      this.map.addSource('pgtiles-custom-points', {
        type: 'vector',
        tiles: [ PortalAPI.CUSTOME_POINTS],
        promoteId: 'featureId',
      });

      this.map.addSource('pgtiles-custom-annotation-points', {
        type: 'vector',
        tiles: [ PortalAPI.CUSTOME_ANNOTATION_POINTS ],
        promoteId: 'featureId',
      });

      this.map.addSource('pgtiles-custom-annotation-lines', {
        type: 'vector',
        tiles: [ PortalAPI.CUSTOME_ANNOTATION_LINES ],
        promoteId: 'featureId',
      });
    // }

    this.map.addLayer({
      id: 'pgtiles-custom-annotation-lines-layer',
      type: 'line',
      source: 'pgtiles-custom-annotation-lines',
      'source-layer': 'default',
      minzoom: 13.25,
      maxzoom: 23,
      layout: {
        visibility: 'visible',
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#00FF00',
        'line-width': 2,
      },
    });

    this.map.addLayer({
      id: 'pgtiles-custom-annotation-points-layer',
      type: 'circle',
      source: 'pgtiles-custom-annotation-points',
      'source-layer': 'default',
      minzoom: 13.25,
      maxzoom: 23,
      paint: {
        'circle-color': '#00FF00',
        'circle-radius': 4,
      },
    });
  }

  addVectorTilesLayersAndSourceNew() {
    for (const appLayerId of [1, 2, 3]) {
      let currentLineSourceId = `${appLayerId}-active-lines-annotations-by-layerid`;
      let currentPointSourceId = `${appLayerId}-active-points-annotations-by-layerid`;
      let currentPhotoSourceId = `${appLayerId}-active-photos-annotations-by-layerid`;
      this.commonMapService.setSourceForVectorTiles(
        this.map,
        currentLineSourceId,
        appLayerId,
        'line'
      );
      this.commonMapService.setSourceForVectorTiles(
        this.map,
        currentPointSourceId,
        appLayerId,
        'point'
      );
      this.commonMapService.setSourceForVectorTiles(
        this.map,
        currentPhotoSourceId,
        appLayerId,
        'external'
      );
    }
  }

  addSourceForVectorTiles(sourceId: any, appLayerId: any, geomType: any) {
    let pointTiles,
      lineTiles = null;
    // if (this.isQAenv === 'dev') {
    //   pointTiles = `https://py.gprssitemap.com:9443/api/v1/mvttileserver/active_points_annotations_by_layerid/${appLayerId}/{z}/{x}/{y}.mvt`;
    //   lineTiles = `https://py.gprssitemap.com:9443/api/v1/mvttileserver/active_lines_annotations_by_layerid/${appLayerId}/{z}/{x}/{y}.mvt`;
    // } else if (this.isQAenv === 'prod') {
    //   pointTiles = `https://mvt.gprssitemap.com:8443/api/v1/mvttileserver/active_points_annotations_by_layerid/${appLayerId}/{z}/{x}/{y}.mvt`;
    //   lineTiles = `https://mvt.gprssitemap.com:8443/api/v1/mvttileserver/active_lines_annotations_by_layerid/${appLayerId}/{z}/{x}/{y}.mvt`;
    // } else if (this.isQAenv === 'preprod') {
    //   pointTiles = `https://mvtqa.gprssitemap.com:9443/api/v1/mvttileserver/active_points_annotations_by_layerid/${appLayerId}/{z}/{x}/{y}.mvt`;
    //   lineTiles = `https://mvtqa.gprssitemap.com:9443/api/v1/mvttileserver/active_lines_annotations_by_layerid/${appLayerId}/{z}/{x}/{y}.mvt`;
    // } else {
    //   pointTiles = `https://py.gprssitemap.com:8443/api/v1/mvttileserver/active_points_annotations_by_layerid/${appLayerId}/{z}/{x}/{y}.mvt`;
    //   lineTiles = `https://py.gprssitemap.com:8443/api/v1/mvttileserver/active_lines_annotations_by_layerid/${appLayerId}/{z}/{x}/{y}.mvt`;
    // }

    pointTiles = PortalAPI.POINT_TILES;
    lineTiles = PortalAPI.LINE_TILES;

    if (geomType === 'point') {
      this.map.addSource(sourceId, {
        type: 'vector',
        tiles: [pointTiles],
        promoteId: 'featureId',
      });
    } else {
      this.map.addSource(sourceId, {
        type: 'vector',
        tiles: [lineTiles],
        promoteId: 'featureId',
      });
    }
  }

  addLayerInMap() {
    if (!this.isSearchAppliedOnJobData) {
      let visibility: any = 'visible';
      // if (this.map.getZoom() >= 13.25) {
      //   visibility = 'none'
      // }
      if (this.map.getLayer('unclustered-point') === undefined)
        this.map.addLayer({
          id: 'unclustered-point',
          type: 'circle',
          source: 'allJobs',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#11b4da',
            'circle-radius': 4,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff',
          },
          layout: {
            visibility: visibility,
          },
        });
      if (this.map.getLayer('sourceSiteLayer') === undefined) {
        //For visbile site map
        this.showSiteIconOnMap();
      }
    }
    else {
      if (this.previousZoomLevel < 13.25) {
        this.searchJobsData();
      }
    }
    // this.addAllIconsInMap();
  }

  showSiteIconOnMap() {
    if (this.layerSiteData && this.layerSiteData.length > 0) {
      let temp: any = { type: 'FeatureCollection', features: [] };
      for (let i = 0; i < this.layerSiteData.length; i++) {
        const coordinate = JSON.parse(this.layerSiteData[i].geom);
        const jobCoordinates = coordinate.coordinates[0][0];
        temp.features.push({
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': jobCoordinates
          },
          'properties' :{
            'id': this.layerSiteData[i].siteId
          }
        })
      }
      this.map.loadImage(
        `../../../../../../assets/images/GPRS_PNG/MAP_SITE.png`,
        (error, image: any) => {
          let iconExits = this.map.hasImage('site_map_icon');
          if (iconExits) return;
          this.map.addImage('site_map_icon', image);
          if (error) {
            console.log('already exist' + error);
          }
        }
      );
      if (this.map.getLayer('sourceSiteLayer') != undefined) {
        this.map.removeLayer('sourceSiteLayer');
      }
      if (this.map.getSource('sourceSite') != undefined) {
        this.map.removeSource('sourceSite');
      }
      if (this.map.getSource('sourceSite') === undefined) {
        this.map.addSource(`sourceSite`, {
          type: 'geojson',
          data: temp
        });
      }
      if (this.map.getLayer('sourceSiteLayer') === undefined) {
        this.map.addLayer({
          id: `sourceSiteLayer`,
          type: 'symbol',
          source: `sourceSite`,
          maxzoom: 13.25,
          layout: {
            'icon-image': 'site_map_icon', // reference the image
            'icon-size': 1,
          }
        });
      }
    }
  }

  addAllIconsInMap() {
    for (let i = 0; i < this.pointFeaturesNameArray.length; i++) {
      this.map.loadImage(
        `../../../../../../assets/images/GPRS_PNG/${this.pointFeaturesNameArray[i]}.png`,
        (error, image: any) => {
          if (error) {
            this.pointFeaturesImagesNotPresent.push(
              this.pointFeaturesNameArray[i]
            );
          }
          if(!this.map.hasImage(this.pointFeaturesNameArray[i])){
            this.map.addImage(this.pointFeaturesNameArray[i], image);
          }
        }
      );
    }
  }

  //# map click function on map
  mapClickInputFunction() {
    if (this.screenWidth <= 397) {
      this.map.on('touchend', 'unclustered-point', (e: any) => {
        var errCoordinate: any[2] = [];
        //const coordinates = e.features[0].geometry.coordinates.slice();
        this.clickedJobItemId = +e.features[0].properties.jobId;
        this.dashboardservice
          .fetchSharedWithUserList(this.clickedJobItemId, 1)
          .subscribe((data) => {
            this.objSharedUserList = data;
          });
        //this.clickedJobItemCoordinates = e.features[0].geometry.coordinates.slice();
        this.spinner.show();
        this.mapViewerSubscribe = this.mapViewerService
          .fetchJobDetails(this.clickedJobItemId!, this.loginUserId)
          .subscribe(
            (jobDetailData: any) => {
              //const jobName = jobDetailData.data?.jobName;
              // const jobName = jobDetailData?.data?.groups?.features[0]?.attributes?.workorderNumber + " - " + jobDetailData?.data?.groups?.features[0]?.attributes?.customerName;
              const jobName =
                jobDetailData?.features[0]?.attributes?.workorderNumber +
                ' - ' +
                jobDetailData.features[0]?.attributes?.customerName;

              this.clickedJobName = jobName;
              var siteContact = '';
              var email = '';
              // if (jobDetailData?.data?.groups != null) {
              if (jobDetailData?.features[0] != null) {
                // siteContact = jobDetailData.data.groups.features[0].attributes.siteContact;
                // email = jobDetailData.data.groups.features[0].attributes.email;
                siteContact =
                  jobDetailData?.features[0]?.attributes.siteContact;
                email = jobDetailData?.features[0]?.attributes.email;
                // this.isJobOwner = jobDetailData.data.groups.features[0].attributes.isShared;
                this.isJobOwner =
                  jobDetailData?.features[0]?.attributes.isAssigned;
                if (!this.isJobOwner) {
                  // not assinged (not owner)
                  this.isJobOwner = false;
                } else {
                  this.isJobOwner = true;
                }

                if (
                  this.loginUserRole == 'Admin' ||
                  this.loginUserRole == 'Project Manager'
                ) {
                  this.isJobOwner = true;
                }
              }
              // const lat = jobDetailData.data.groups.features[0].attributes.latitude;
              // const long = jobDetailData.data.groups.features[0].attributes.longitude;
              const lat = jobDetailData?.features[0]?.attributes.latitude;
              const long = jobDetailData?.features[0]?.attributes.longitude;
              const netsuiteJobId = jobDetailData?.features[0]?.attributes.netsuiteJobId || '';
              const netsuitePoNumber = jobDetailData?.features[0]?.attributes.netsuitePoNumber || '';

              const coordinates: any[2] = [long, lat];
              errCoordinate = [long, lat];
              this.clickedJobItemCoordinates = coordinates;
              const description = `
          <div class="custom-map-header">
            <h2>${jobName}</h2>
          </div>
          <div class="custom-map-tooltip">
            <div class="table-responsive">
              <table class="table table-bordered table-striped custom-table">
                <tr>
                  <td>Location</td>
                  <td>${coordinates[1]},${coordinates[0]}</td>
                </tr>
                <tr>
                  <td>Site Contact</td>
                  <td>${siteContact}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>${email}</td>
                </tr>
                <tr>
                  <td>Job ID</td>
                  <td>${netsuiteJobId}</td>
                </tr>
                <tr>
                  <td>PO Number</td>
                  <td>${netsuitePoNumber}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="custom-map-footer">
            <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
            <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
          </div>
        `;
              // Ensure that if the map is zoomed out such that multiple
              // copies of the feature are visible, the popup appears
              // over the copy being pointed to.
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }
              this.spinner.hide();

              new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setHTML(description)
                .addTo(this.map);
            },
            (error) => {
              const description = `
      <div class="custom-map-header">
        <h2>Error occurred while fetching data for this job</h2>
      </div>
      <div class="custom-map-tooltip">
      </div>
      <div class="custom-map-footer">
        <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
        <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
      </div>
    `;
              new mapboxgl.Popup()
                .setLngLat(errCoordinate)
                .setHTML(description)
                .addTo(this.map);
              this.spinner.hide();
            }
          );
      });
    } else {
      this.map.on('click', 'unclustered-point', (e: any) => {
        var errCoordinate: any[2] = [];
        //const coordinates = e.features[0].geometry.coordinates.slice();
        this.clickedJobItemId = +e.features[0].properties.jobId;
        this.dashboardservice
          .fetchSharedWithUserList(this.clickedJobItemId, 1)
          .subscribe((data) => {
            this.objSharedUserList = data;
          });

        //this.clickedJobItemCoordinates = e.features[0].geometry.coordinates.slice();
        this.spinner.show();
        this.mapViewerSubscribe = this.mapViewerService
          .fetchJobDetails(this.clickedJobItemId!, this.loginUserId)
          .subscribe(
            (jobDetailData: any) => {
              //const jobName = jobDetailData.data?.jobName;
              // const jobName = jobDetailData?.data?.groups?.features[0]?.attributes?.workorderNumber + " - " + jobDetailData?.data?.groups?.features[0]?.attributes?.customerName;
              const jobName =
                jobDetailData?.features[0]?.attributes?.workorderNumber +
                ' - ' +
                jobDetailData.features[0]?.attributes?.customerName;

              this.clickedJobName = jobName;
              var siteContact = '';
              var email = '';
              // if (jobDetailData?.data?.groups != null) {
              if (jobDetailData?.features[0] != null) {
                // siteContact = jobDetailData.data.groups.features[0].attributes.siteContact;
                // email = jobDetailData.data.groups.features[0].attributes.email;
                siteContact =
                  jobDetailData?.features[0]?.attributes.siteContact;
                email = jobDetailData?.features[0]?.attributes.email;
                this.isJobShared  = jobDetailData?.features[0]?.attributes.isShared;
                this.isJobOwner = this.isJobShared;

                if (
                  this.loginUserRole == 'Admin' ||
                  this.loginUserRole == 'Project Manager'
                ) {
                  this.isJobOwner = true;
                }
              }
              // const lat = jobDetailData.data.groups.features[0].attributes.latitude;
              // const long = jobDetailData.data.groups.features[0].attributes.longitude;
              const lat = jobDetailData?.features[0]?.attributes.latitude;
              const long = jobDetailData?.features[0]?.attributes.longitude;
              const netsuiteJobId = jobDetailData?.features[0]?.attributes.netsuiteJobId || '';
              const netsuitePoNumber = jobDetailData?.features[0]?.attributes.netsuitePoNumber || '';

              const coordinates: any[2] = [long, lat];
              errCoordinate = [long, lat];
              this.clickedJobItemCoordinates = coordinates;
              const description = `
          <div class="custom-map-header">
            <h2>${jobName}</h2>
          </div>
          <div class="custom-map-tooltip">
            <div class="table-responsive">
              <table class="table table-bordered table-striped custom-table">
                <tr>
                  <td>Location</td>
                  <td>${coordinates[1]},${coordinates[0]}</td>
                </tr>
                <tr>
                  <td>Site Contact</td>
                  <td>${siteContact}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>${email}</td>
                </tr>
                <tr>
                  <td>Job ID</td>
                  <td>${netsuiteJobId}</td>
                </tr>
                <tr>
                  <td>PO Number</td>
                  <td>${netsuitePoNumber}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="custom-map-footer">
            <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
            <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
          </div>
        `;
              // Ensure that if the map is zoomed out such that multiple
              // copies of the feature are visible, the popup appears
              // over the copy being pointed to.
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }
              this.spinner.hide();

              new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setHTML(description)
                .addTo(this.map);
            },
            (error) => {
              const description = `
      <div class="custom-map-header">
        <h2>Error occurred while fetching data for this job</h2>
      </div>
      <div class="custom-map-tooltip">
      </div>
      <div class="custom-map-footer">
        <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
        <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
      </div>
    `;
              new mapboxgl.Popup()
                .setLngLat(errCoordinate)
                .setHTML(description)
                .addTo(this.map);
              this.spinner.hide();
            }
          );
      });
    }
  }

  mapClickInputFunctionAfterSearch(e: any) {
    const popups = document.getElementsByClassName('mapboxgl-popup');

    if (popups.length > 0 && this.searchJobList.totalRecord > 1) {
      popups[0].remove();
    }
    if (this.screenWidth <= 397) {
      this.map.on('touchend', 'search-unclustered-point', (e: any) => {
        var errCoordinate: any[2] = [];
        //const coordinates = e.features[0].geometry.coordinates.slice();
        this.clickedJobItemId = +e.features[0].properties.jobId;
        // this.clickedJobItemCoordinates = e.features[0].geometry.coordinates.slice();
        this.spinner.show();
        this.dashboardservice
          .fetchSharedWithUserList(this.clickedJobItemId!, 1)
          .subscribe((data) => {
            this.objSharedUserList = data;
          });
        this.mapViewerSubscribe = this.mapViewerService
          .fetchJobDetails(this.clickedJobItemId!, this.loginUserId)
          .subscribe(
            (jobDetailData: any) => {
              // const jobName = jobDetailData.data?.jobName;
              // const jobName = jobDetailData?.data?.groups?.features[0]?.attributes?.workorderNumber + " - " + jobDetailData?.data?.groups?.features[0]?.attributes?.customerName;
              const jobName =
                jobDetailData?.features[0]?.attributes?.workorderNumber +
                ' - ' +
                jobDetailData?.features[0]?.attributes?.customerName;

              this.clickedJobName = jobName;
              var siteContact = '';
              var email = '';
              if (jobDetailData?.features[0] != null) {
                // siteContact = jobDetailData.data.groups.features[0].attributes.siteContact;
                // email = jobDetailData.data.groups.features[0].attributes.email;
                siteContact =
                  jobDetailData?.features[0]?.attributes.siteContact;
                email = jobDetailData?.features[0]?.attributes.email;
                // this.isJobOwner = jobDetailData.data.groups.features[0].attributes.isShared;
                this.isJobOwner =
                  jobDetailData?.features[0]?.attributes.isAssigned;
                  if (!this.isJobOwner) {
                    // not assinged (not owner)
                    this.isJobOwner = false;
                  } else {
                    this.isJobOwner = true;
                  }
                if (this.loginUserRole == 'Admin' || 'Project Manager') {
                  this.isJobOwner = true;
                }
              }
              const lat = jobDetailData?.features[0].attributes.latitude;
              const long = jobDetailData?.features[0].attributes.longitude;
              const netsuiteJobId = jobDetailData?.features[0]?.attributes.netsuiteJobId || '';
              const netsuitePoNumber = jobDetailData?.features[0]?.attributes.netsuitePoNumber || '';

              const coordinates: any[2] = [long, lat];
              errCoordinate = [long, lat];
              this.clickedJobItemCoordinates = coordinates;
              const description = `
        <div class="custom-map-header">
          <h2>${jobName}</h2>
        </div>
        <div class="custom-map-tooltip">
          <div class="table-responsive">
            <table class="table table-bordered table-striped custom-table">
              <tr>
                <td>Location</td>
                <td>${coordinates[1]},${coordinates[0]}</td>
              </tr>
              <tr>
                <td>Site Contact</td>
                <td>${siteContact}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>${email}</td>
              </tr>
              <tr>
                <td>Job ID</td>
                <td>${netsuiteJobId}</td>
              </tr>
              <tr>
                <td>PO Number</td>
                <td>${netsuitePoNumber}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="custom-map-footer">
          <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
          <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
        </div>
      `;
              // Ensure that if the map is zoomed out such that multiple
              // copies of the feature are visible, the popup appears
              // over the copy being pointed to.
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }
              this.spinner.hide();
              new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setHTML(description)
                .addTo(this.map);
            },
            (error) => {
              const description = `
    <div class="custom-map-header">
      <h2>Error occurred while fetching data for this job</h2>
    </div>
    <div class="custom-map-tooltip">
    </div>
    <div class="custom-map-footer">
      <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
      <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
    </div>
  `;
              new mapboxgl.Popup()
                .setLngLat(errCoordinate)
                .setHTML(description)
                .addTo(this.map);
              this.spinner.hide();
            }
          );
      });
    } else {
      this.map.on('click', 'search-unclustered-point', (e: any) => {
        var errCoordinate: any[2] = [];
        //const coordinates = e.features[0].geometry.coordinates.slice();
        this.clickedJobItemId = +e.features[0].properties.jobId;
        // this.clickedJobItemCoordinates = e.features[0].geometry.coordinates.slice();
        this.spinner.show();
        this.dashboardservice
          .fetchSharedWithUserList(this.clickedJobItemId!, 1)
          .subscribe((data) => {
            this.objSharedUserList = data;
          });
        this.mapViewerSubscribe = this.mapViewerService
          .fetchJobDetails(this.clickedJobItemId!, this.loginUserId)
          .subscribe(
            (jobDetailData: any) => {
              // const jobName = jobDetailData.data?.jobName;
              // const jobName = jobDetailData?.data?.groups?.features[0]?.attributes?.workorderNumber + " - " + jobDetailData?.data?.groups?.features[0]?.attributes?.customerName;
              const jobName =
                jobDetailData?.features[0]?.attributes?.workorderNumber +
                ' - ' +
                jobDetailData?.features[0]?.attributes?.customerName;
              this.clickedJobName = jobName;
              var siteContact = '';
              var email = '';
              if (jobDetailData?.features[0] != null) {
                // siteContact = jobDetailData.data.groups.features[0].attributes.siteContact;
                // email = jobDetailData.data.groups.features[0].attributes.email;
                siteContact =
                  jobDetailData?.features[0]?.attributes.siteContact;
                email = jobDetailData?.features[0]?.attributes.email;
                // this.isJobOwner = jobDetailData.data.groups.features[0].attributes.isShared;
                this.isJobOwner =
                  jobDetailData?.features[0]?.attributes.isAssigned;
                  if (!this.isJobOwner) {
                    // not assinged (not owner)
                    this.isJobOwner = false;
                  } else {
                    this.isJobOwner = true;
                  }
                if (this.loginUserRole == 'Admin' || 'Project Manager') {
                  this.isJobOwner = true;
                }
              }
              const lat = jobDetailData?.features[0].attributes.latitude;
              const long = jobDetailData?.features[0].attributes.longitude;
              const netsuiteJobId = jobDetailData?.features[0]?.attributes.netsuiteJobId || '';
              const netsuitePoNumber = jobDetailData?.features[0]?.attributes.netsuitePoNumber || '';

              const coordinates: any[2] = [long, lat];
              errCoordinate = [long, lat];
              this.clickedJobItemCoordinates = coordinates;
              const description = `
        <div class="custom-map-header">
          <h2>${jobName}</h2>
        </div>
        <div class="custom-map-tooltip">
          <div class="table-responsive">
            <table class="table table-bordered table-striped custom-table">
              <tr>
                <td>Location</td>
                <td>${coordinates[1]},${coordinates[0]}</td>
              </tr>
              <tr>
                <td>Site Contact</td>
                <td>${siteContact}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>${email}</td>
              </tr>
              <tr>
                <td>Job ID</td>
                <td>${netsuiteJobId}</td>
              </tr>
              <tr>
                <td>PO Number</td>
                <td>${netsuitePoNumber}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="custom-map-footer">
          <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
          <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
        </div>
      `;
              // Ensure that if the map is zoomed out such that multiple
              // copies of the feature are visible, the popup appears
              // over the copy being pointed to.
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }
              this.spinner.hide();
              new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setHTML(description)
                .addTo(this.map);
            },
            (error) => {
              const description = `
    <div class="custom-map-header">
      <h2>Error occurred while fetching data for this job</h2>
    </div>
    <div class="custom-map-tooltip">
    </div>
    <div class="custom-map-footer">
      <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
      <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
    </div>
  `;
              new mapboxgl.Popup()
                .setLngLat(errCoordinate)
                .setHTML(description)
                .addTo(this.map);
              this.spinner.hide();
            }
          );
      });
    }
  }
  //# mouse enter job feature on map
  mouseEnterOnMap() {
    this.map.on('mouseenter', 'unclustered-point', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
  }

  //# mouse leave job feature  Change it back to a pointer when it leaves
  mouseLeaveOnMap() {
    this.map.on('mouseleave', 'unclustered-point', () => {
      this.map.getCanvas().style.cursor = '';
    });
  }

  mouseEnterOnMapAfterSearch() {
    this.map.on('mouseenter', 'search-unclustered-point', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
  }

  //# mouse leave function  Change it back to a pointer when it leaves
  mouseLeaveOnMapAfterSearch() {
    this.map.on('mouseleave', 'search-unclustered-point', () => {
      this.map.getCanvas().style.cursor = '';
    });
  }

  getDocumentsOnZoom() {
    if (this.map.getLayer('sourceDocumentLayer') != undefined) {
      this.map.removeLayer('sourceDocumentLayer');
    }
    if (this.map.getSource('sourceDocument') != undefined) {
      this.map.removeSource('sourceDocument');
    }
    let payLoad = {
      jobIds: this.zoomedJobIds,
      userId: this.loginUserId,
    };
    this.mapViewerService
      .fetchMultipleJobDocumentsDetails(payLoad).subscribe((item:any)=>{
        if (this.map.getLayer('sourceDocumentLayer') === undefined) {
          //For visbile document icon on map
          if (item && item.length > 0) {
            let temp: any = { type: 'FeatureCollection', features: [] };
            for (let i = 0; i < item.length; i++) {
              if(item[i].jobFiles.length > 0) {
                item[i].jobFiles.forEach((element:any) => {
                  if (!element.isPhoto) {
                    // const coordinate = JSON.parse(item[i].geom);
                    const jobCoordinates = [element.longitude,element.latitude];
                    temp.features.push({
                      'type': 'Feature',
                      'geometry': {
                        'type': 'Point',
                        'coordinates': jobCoordinates
                      },
                      'properties': {
                        'documentName': element.name,
                        'docName': element.name.replace(/\s/g, '')
                      }
                    });
                  }
                });
              }
            }
            this.map.loadImage(
              `../../../../../../assets/images/GPRS_PNG/ico_file_on_map.png`,
              (error, image: any) => {
                let iconExits = this.map.hasImage('doc_map_icon');
                if (iconExits) return;
                this.map.addImage('doc_map_icon', image);
                if (error) {
                  console.log('already exist' + error);
                }
              }
            );
            if (this.map.getSource('sourceDocument') === undefined) {
              this.map.addSource(`sourceDocument`, {
                type: 'geojson',
                data: temp
              });
            }
            let layerId = 'sourceDocumentLayer';
            if (this.map.getLayer(layerId) === undefined) {
              this.map.addLayer({
                id: layerId,
                type: 'symbol',
                source: `sourceDocument`,
                minzoom: 13.25,
                layout: {
                  'icon-image': 'doc_map_icon', // reference the image
                  'icon-size': 1,
                }
              });
            }
            this.map.on('mouseenter', layerId, () => {
              this.map.getCanvas().style.cursor = 'pointer';
            });
            this.map.on('mouseleave', layerId, () => {
              this.map.getCanvas().style.cursor = '';
            });
            this.map.on('click', layerId, (e: any) => {
              const slideOptions = {

                slideMenuOpenSiteAdminDetails: false,
                slideMenuOpenSiteDetails: false,
                slideMenuOpenLayerDetails: false,
                slideMenuOpenTools: false,
                slideMenuOpenJobDescription: false,
                slideMenuOpenFolder: true
              }
              this.clickedDocFeatures = this._getClickedDoc(e);
              this.onSlideToggleClicked(slideOptions);
            });
          }
        }
      },
      (err: any) => {
        // this.toastr.error('Job Documents error: ' + err.status, '', {
        //   timeOut: 3000,
        // });
        this.spinner.hide();

      });
  }

  _getClickedDoc(e:any){
    const bboxPadding = 3;
    let bbox : any = [];
    if(e.point){
      bbox = [
        [e.point.x - bboxPadding, e.point.y - bboxPadding],
        [e.point.x + bboxPadding, e.point.y + bboxPadding],
      ];
    }
    else {
      bbox = [
        [e.attributes.coordinate.long - bboxPadding, e.attributes.coordinate.lat - bboxPadding],
        [e.attributes.coordinate.long + bboxPadding, e.attributes.coordinate.lat + bboxPadding],
      ];
    }

    // Find features intersecting the bounding box.
    const clickedFeatures: any[] = this.map.queryRenderedFeatures(bbox, {
      layers: ['sourceDocumentLayer'],
    });

    return clickedFeatures;
  }

  getJobIdsInViewPortFromMVT() {
    let allMVTSourceIds: any =[];
    let jobIdsInViewPort: any = [];
    for (const layerId of [1,2,3]) {
      for (const tileType of ['point', 'line']) {
        allMVTSourceIds.push(`${layerId}-${tileType}`);
      }
    }
    for (const layerId of [4]) {
      for (const tileType of ['photo', 'matterport', 'pointcloud', 'virtualtour', 'attachment', 'externallink']) {
        allMVTSourceIds.push(`${layerId}-${tileType}`);
      }
    }
    // Query each source
    for (const sourceId of allMVTSourceIds) {
      let featuresInViewPort = this.map.querySourceFeatures(sourceId, { sourceLayer: 'default'});
      jobIdsInViewPort = jobIdsInViewPort.concat(featuresInViewPort.map((feature: any) => feature.properties.mapJobId));
    }
    // Convert to a Set so there are no duplicates and then return Array
    var jobArray = new Set(jobIdsInViewPort);
    let retArray = [...jobArray];
    //console.log("ret array ", retArray);
    return retArray; //Array.from(new Set(jobIdsInViewPort));
  }

  zoomOnMap() {
    // zoom event count test
    // let zoomCount = 0;
    // let zoomEndCount = 0;
    //  this.map.on('zoom', (e: any) => {
    //     console.log("Zoom Count: ", zoomCount++);
    //   });
    //   this.map.on('zoomend', (e: any) => {
    //    console.log("Zoom End Count: ", zoomEndCount++);
    //  });
    this.map.on('zoom', (e: any) => {
      //console.log("zoomend " +  this.currentZoomLevel);
      this.dragMap = false;
      this.zoomMap = true;
      this.userRenderScale = this.renderScaleService.getMinLevelForScale();

      if (this.map.getZoom() < this.userRenderScale) {
        this.allZoomedJobs = [];
      }
      this.zoomOnMapParamVal = e;
      this.previousZoomLevel = this.currentZoomLevel;
      this.currentZoomLevel = this.map.getZoom();
      this.behaviourSubjectService.updateMapIntegrationCenterAndZoomLevelData(
        this.currentZoomLevel
      );


      if (this.currentZoomLevel >= this.userRenderScale) {
        // Line below hurts map performance
        // this.showToggleSideBar = true;

        this.mapZoomLevelGreaterThanThreshold = true;
        if (this.previousZoomLevel < this.userRenderScale) {
          // Moving code here does not help
          this.showToggleSideBar = true;
          this.searchSiteName();
          this.assocateJobOnZoomIn(e);
        }
        else {
          if(!this.isEditingModeIsOn) {
            this.zoomedJobIds = [];
            let zoomedJObIdsFeatures:any
            if (this.isSearchAppliedOnJobData) {
              zoomedJObIdsFeatures = this.map.queryRenderedFeatures(e.point, {
                layers: ['search-unclustered-point'],
              });

              // if the map doesn't have the searched for job centroid displaying then revert
              // back to the origZoomJobIdsFeaturesForSearch
              if (zoomedJObIdsFeatures.length <= 0) {
                zoomedJObIdsFeatures=this.origZoomJobIdsFeaturesForSearch;
              } else {
                this.origZoomJobIdsFeaturesForSearch = zoomedJObIdsFeatures;
              };

            } else {
              if (this.map.getLayer('unclustered-point') !== undefined) {
                zoomedJObIdsFeatures = this.map.queryRenderedFeatures(e.point, {
                  layers: ['unclustered-point'],
                });
              } else {
                zoomedJObIdsFeatures = [];
              }
            }

            if (zoomedJObIdsFeatures.length <= 0)
            {
                // no job centroids found
                // query all layers on the map to see if any of them have a mapJobId
                let jobsInViewPortArray = this.getJobIdsInViewPortFromMVT();  // all jobs, this list includes jobs the user might not have access too.

                // see if any of the jobs returned are available for the user.
                // Jobs available to the user should be in the apiAllJobResponse variable
                this.allZoomedJobs = [];
                this.zoomedJobIds = [];
                if (this.loginUserRole == "Admin" || this.loginUserRole == "PM") {
                  // Admin and PM's can see all jobs
                  this.allZoomedJobs=jobsInViewPortArray;
                  this.zoomedJobIds=jobsInViewPortArray;
                } else {
                  //This is a client, and need to remove the jobs the user does not have access to view
                  for (var i = 0; i < this.apiAllJobResponse?.features.length; i++) {
                    let jobId = this.apiAllJobResponse.features[i].properties.jobId;
                    if (jobsInViewPortArray.includes(jobId)) {
                      this.allZoomedJobs.push(jobId);
                      this.zoomedJobIds.push(jobId);
                    }
                  }
                }
            }

            for (let i = 0; i < zoomedJObIdsFeatures.length; i++) {
              const zoomedJobId = zoomedJObIdsFeatures[i].properties?.jobId;
              if (
                !this.zoomedJobIds.find((it: any) => it === zoomedJobId)
              ) {
                this.zoomedJobIds.push(zoomedJobId);
              }
            }
            this._filterFeaturesForClient("zoom");
          }
        }
        this.spinner.hide();
      } else {
        // this.commonMapService.removeAllLayer(this.pointLayerIds,this.lineLayerIds,this.map);
        let mapTool = document.getElementsByClassName(
          'mapboxgl-ctrl-group mapboxgl-ctrl'
        );
        mapTool[0].classList.remove('disabled');
        this.deleteAllDrawFeatures();
        this.isLayerEyeVisible = {
          SITE: true,
          GPRS: true,
          CLIENT: true,
          IMPORTED: true,
          EXTERNAL: true,
        };
        this.isSiteEditClick = '';
        this.isSubSiteEditClick = '';
        this.siteEditId = '';
        this.isSiteEditingModeIsOn = false;
        if (this.currentZoomLevel >= this.userRenderScale) {
          this.showToggleSideBar = true;
        } else {
          this.showToggleSideBar = false;
        }

        this.toggleApiLoaded = false;
        this.mapZoomLevelGreaterThanThreshold = false;
        this.openedFeatureAttributes = null;
        if (this.previousZoomLevel >= this.userRenderScale) {
          if (this.isSearchAppliedOnJobData) {
            if(this.map.getLayer('search-unclustered-point') != undefined)
            {
              this.map.setLayoutProperty(
                'search-unclustered-point',
                'visibility',
                'visible'
              );
              this.map.setFilter('search-unclustered-point', true);
              this.map.setFilter('searchSiteImageLayer', true);
            } else {
              this.searchJobsData();
            }
          } else {
            if (this.map.getLayer('unclustered-point') != undefined) {
              this.map.setLayoutProperty(
                'unclustered-point',
                'visibility',
                'visible'
              );
            }
            if (this.map.getLayer('sourceSiteLayer') != undefined) {
              this.map.setLayoutProperty('sourceSiteLayer', 'visibility', 'visible');
            }
            if (this.map.getLayer('sourceSiteLayer') != undefined) {
              this.map.setLayoutProperty('sourceSiteLayer', 'visibility', 'visible');
            }
            if (this.map.getLayer('sourceDocumentLayer') != undefined) {
              this.map.setLayoutProperty('sourceDocumentLayer', 'visibility', 'visible');
            }
            if (this.map.getLayer('unclustered-point') != undefined) {
              this.map.setFilter('unclustered-point', true);
            }
          }
        }
      }
    });
  }

  //#region searchSiteName method is use to search the site on map
  searchSiteName() {
    if (this.selectedSearchType == 10) {
      const searchedJobData = this.searchJobList.allJobs;
      this.siteData.forEach((site: any) => {
        const findSite = searchedJobData?.filter((it: any) => it.siteId === site.siteId);

        if (findSite.length === 0) {
          let siteInfo = this.removeAllSpaceAndSpecialChar(site.siteName) + '-site-' + site.siteId;
          this.map.setLayoutProperty(siteInfo, 'visibility', 'none');
          if (site.subSiteData && site.subSiteData.length > 0) {
            site.subSiteData.forEach((subSite: any) => {
              siteInfo = this.removeAllSpaceAndSpecialChar(subSite.subSiteName) + '-subsite-' + subSite.subSiteId;
              this.map.setLayoutProperty(siteInfo, 'visibility', 'none');
            });
          }
        }
      });
      let findData: any[] = [];
      searchedJobData.forEach((ele: any) => {
        const data = this.siteData.find((it: any) => it.siteId === ele.siteId);
        if (data) {
          findData.push(data);
        }
      });
      this.siteData = [];
      this.siteData = findData;
    }
  }
  //#endregion

  //#region deleteAllDrawFeatures method is use to delete the draw or edit feature whenever you zoomedout below 1000
  deleteAllDrawFeatures() {
    if (this.draw.getAll().features.length > 0) {
      this.draw.getAll().features.forEach((element: any, index: any) => {
        if (this.draw.getAll().features[index]) {
          if (
            this.draw
              .getAll()
              .features[index].properties.name?.toLowerCase() === 'site'
          ) {
            const siteProperties =
              this.draw.getAll().features[index].properties;
            const data = this.siteData.find(
              (it: any) => it.siteId === this.draw.getAll().features[index].id
            );
            let geom = data.geom;
            let siteInfo =
              this.draw.getAll().features[index].properties.siteName;
            if (geom !== null) {
              const feature: any = {
                type: 'Feature',
                geometry: JSON.parse(geom),
                properties: {
                  siteName: siteInfo,
                  id: data.siteId,
                  name: siteProperties.name.toLowerCase(),
                  description: data.description,
                  address: data.address,
                  city: data.city,
                  state: data.state,
                  country: data.country,
                  siteSubsiteName: data['siteName'],
                },
              };
              const geojsonSource = this.map.getSource(
                siteInfo
              ) as mapboxgl.GeoJSONSource;
              if (geojsonSource) {
                geojsonSource.setData({
                  type: 'FeatureCollection',
                  features: [feature],
                });
              }
            }
          }
          if (
            this.draw
              .getAll()
              .features[index].properties.name?.toLowerCase() === 'subsite'
          ) {
            const siteProperties =
              this.draw.getAll().features[index].properties;
            let data: any;
            for (let element of this.siteData) {
              if (element.subSiteData && element.subSiteData.length > 0) {
                const findData = element.subSiteData.find(
                  (it: any) =>
                    it.subSiteId === this.draw.getAll().features[index].id
                );
                if (findData) {
                  data = findData;
                  break;
                }
              }
            }
            let geom = data.geom;
            let siteInfo =
              this.draw.getAll().features[index].properties.siteName;
            if (geom !== null) {
              const feature: any = {
                type: 'Feature',
                geometry: JSON.parse(geom),
                properties: {
                  siteName: siteInfo,
                  id: data.subSiteId,
                  name: siteProperties.name.toLowerCase(),
                  description: data.description,
                  address: data.address,
                  city: data.city,
                  state: data.state,
                  country: data.country,
                  siteSubsiteName: data['subSiteName'],
                },
              };
              const geojsonSource = this.map.getSource(
                siteInfo
              ) as mapboxgl.GeoJSONSource;
              if (geojsonSource) {
                geojsonSource.setData({
                  type: 'FeatureCollection',
                  features: [feature],
                });
              }
            }
          }
          this.draw.delete(this.draw.getAll().features[index].id);
        }
      });
    }
  }
  //#endregion

  assocateJobOnZoomIn(e: any) {
    //start code is used for get associate and deassociate zoom job ids
    let siteArray: any[] = [];
    // this.spinner.show();
    let findData: any[] = [];
    if (this.searchJobList?.allJobs && this.searchJobList?.allJobs.length > 0) {
      this.searchJobList.allJobs.forEach((ele: any) => {
        const data = this.siteData.find((it: any) => it.siteId === ele.siteId);
        if (data) {
          findData.push(data);
        }
      });
    }
    if (findData.length > 0) {
      findData.forEach((element: any) => {
        let param: any;
        param = {
          objectId: element.siteId,
          objectTypeId: 4, //4 is use for site id in objectTypeId
          userId: parseInt(this.loginUserId),
        };
        siteArray.push(
          this.restService
            .post(PortalAPI.GET_JOBS_BY_SUBSITEID, param)
            .pipe(
              catchError((err) => {
                console.log('error occurred');
                return of([]);
              })
            )
        );
        if (element && element.subSiteData && element.subSiteData.length > 0) {
          element.subSiteData.forEach((subSite: any) => {
            param = {
              objectId: subSite.subSiteId,
              objectTypeId: 5, //5 is use for subsite id in objectTypeId
              userId: parseInt(this.loginUserId),
            };
            siteArray.push(
              this.restService
                .post(
                  PortalAPI.GET_JOBS_BY_SUBSITEID,
                  param
                )
                .pipe(
                  catchError((err) => {
                    console.log('error occurred');
                    return of([]);
                  })
                )
            );

          });
        }
      });
    }
    let siteAssociatedJobIds: any[] = [];
    if (siteArray.length > 0) {
      forkJoin(siteArray).subscribe((result: any) => {
        for (let i = 0; i < result.length; i++) {
          if (result[i].length != 0) {
            for (let j = 0; j < result[i]?.length; j++) {

              // if (result[i][j]?.isAssociated) {
              siteAssociatedJobIds.push(result[i][j]);
              // }
            }
          }
        }
        //end for associate and deassociate

        // this.resetMapsData();
        this.slideMenuOpenLayerDetails = false;
        this.slideMenuOpenFolder = false;
        let zoomedJObIdsFeatures = [];
        this.zoomedJobIds = [];
        if (this.isSearchAppliedOnJobData) {
          zoomedJObIdsFeatures = this.map.queryRenderedFeatures(e.point, {
            layers: ['search-unclustered-point'],
          });
        } else {
          zoomedJObIdsFeatures = this.map.queryRenderedFeatures(e.point, {
            layers: ['unclustered-point'],
          });
        }
        // this.spinner.show();
        this.zoomedFeaturesObservableArray = [];
        this.zoomedImportedImageObservableArray = [];
        this.zoomedDocumentsObservableArray = [];
        for (let i = 0; i < zoomedJObIdsFeatures.length; i++) {
          const zoomedJobId = zoomedJObIdsFeatures[i].properties!.jobId;
          const tempJobId = siteAssociatedJobIds.filter(
            (it: any) => it.jobId === zoomedJobId
          );
          let flag = true;
          if (tempJobId && tempJobId.length > 0) {
            if (tempJobId.length > 1 && tempJobId[1].isAssociated === false)
              flag = false;
            else if (
              tempJobId.length === 1 &&
              tempJobId[0].isAssociated === false
            )
              flag = false;
          }
          if (
            !this.zoomedJobIds.find((it: any) => it === zoomedJobId) &&
            flag
          ) {
            this.zoomedJobIds.push(zoomedJobId);
          }
        }
        // this.commonMapService.filterMVTOnZoomIn(this.zoomedJobIds, this.map);
        this._filterFeaturesForClient("zoom");


        this.getDocumentsOnZoom();
        const filter: any = ['all', ['!', ['has', 'point_count']]];
        filter.push(['any']);
        this.zoomedJobIds.forEach((jobId: any) => {
          filter[2].push(['==', ['get', 'jobId'], jobId]);
        });
        if (this.isSearchAppliedOnJobData) {
          this.map.setFilter('search-unclustered-point', filter);
        } else {
          this.map.setFilter('unclustered-point', filter);
        }
        if (this.allZoomedJobs.length === 0) {
          this.allZoomedJobs = this.zoomedJobIds;
          if(!this.slideMenuOpenSiteAdminDetails)
            this.getSiteList(false, undefined, true);
            this.getSubSiteList(false, undefined, true);
        }
        // this.commonMapService.filterMVTOnZoomIn(this.zoomedJobIds, this.map);
        this.spinner.hide();
      });
    } else {
      this.splitZoomOnMap(e, "zoom");
    }
    if(this.isToggleApplied){
      this._resetSiteSubsiteToggle();
    }
  }

  splitZoomOnMap(e: any, from?:any) {
    this.showToggleSideBar = true;
    // this.resetMapsData();
    // this.slideMenuOpenLayerDetails = false;
    // this.slideMenuOpenFolder = false;
    let zoomedJObIdsFeatures:any = [];
    this.zoomedJobIds = [];
    if (this.isSearchAppliedOnJobData) {
      if(this.map.getLayer('search-unclustered-point') != undefined) {

        zoomedJObIdsFeatures = this.map.queryRenderedFeatures(e.point, {
          layers: ['search-unclustered-point'],
        });

        // if the map doesn't have the searched for job centroid displaying then revert
        // back to the origZoomJobIdsFeaturesForSearch
        if (zoomedJObIdsFeatures.length <= 0) {
          zoomedJObIdsFeatures=this.origZoomJobIdsFeaturesForSearch;
        } else {
          this.origZoomJobIdsFeaturesForSearch = zoomedJObIdsFeatures;
        };

        //this is comment when we need to show red dots at 1000 feet
        //Commented for visible red dot after search
        // this.map.setLayoutProperty(
        //   'search-unclustered-point',
        //   'visibility',
        //   'none'
        // );
      }
    } else {
      zoomedJObIdsFeatures = this.map.queryRenderedFeatures(e.point, {
        layers: ['unclustered-point'],
      });
    }

    if (zoomedJObIdsFeatures.length <= 0)
    {
        // no job centroids found
        // query all layers on the map to see if any of them have a mapJobId
        let jobsInViewPortArray = this.getJobIdsInViewPortFromMVT();  // all jobs, this list includes jobs the user might not have access too.

        // see if any of the jobs returned are available for the user.
        // Jobs available to the user should be in the apiAllJobResponse variable
        this.allZoomedJobs = [];
        this.zoomedJobIds = [];
        if (this.loginUserRole == "Admin" || this.loginUserRole == "PM") {
          // Admin and PM's can see all jobs
          this.allZoomedJobs=jobsInViewPortArray;
          this.zoomedJobIds=jobsInViewPortArray;
        } else {
          //This is a client, and need to remove the jobs the user does not have access to view
          for (var i = 0; i < this.apiAllJobResponse.features.length; i++) {
            let jobId = this.apiAllJobResponse.features[i].properties.jobId;
            if (jobsInViewPortArray.includes(jobId)) {
              this.allZoomedJobs.push(jobId);
              this.zoomedJobIds.push(jobId);
            }
          }
        }
    }

    // this.spinner.show();
    this.zoomedFeaturesObservableArray = [];
    this.zoomedImportedImageObservableArray = [];
    this.zoomedDocumentsObservableArray = [];
    for (let i = 0; i < zoomedJObIdsFeatures.length; i++) {
      const zoomedJobId = zoomedJObIdsFeatures[i].properties!.jobId;
      if (!this.zoomedJobIds.find((it: any) => it === zoomedJobId)) {
        this.zoomedJobIds.push(zoomedJobId);
      }
    }

    this._filterFeaturesForClient(from ? from : "drag");

    // for sprint 23
    this.getDocumentsOnZoom();
    // const filter: any = ['all', ['!', ['has', 'point_count']]];
    // filter.push(['any']);
    // this.zoomedJobIds.forEach((jobId: any) => {
    //   filter[2].push(['==', ['get', 'jobId'], jobId]);
    // });
    // if(this.map.getLayer('unclustered-point') != undefined) {
    //   this.map.setFilter('unclustered-point', filter);
    // }
    if (this.allZoomedJobs.length === 0) {
      this.allZoomedJobs = this.zoomedJobIds;
      if(!this.slideMenuOpenSiteAdminDetails)
        this.getSiteList(false, undefined, true);
        this.getSubSiteList(false, undefined, true);
    }

    this.spinner.hide();
  }

  private _filterFeaturesForClient(from:any) {
    console.log("_filterFeaturesForClient : ", from)
    if (this.loginUserRole?.toLowerCase() === 'client') {
      // this.toggleLayerFeatures('none');
      this.commonMapService.filterMVTOnZoomIn(this.zoomedJobIds, this.map);
      // setTimeout(() => {
      //   this.toggleLayerFeatures('visible');
      // }, 500);
    }
      }
  toggleLayerFeatures(visibilityVal: string) {
    for (let layerType of ['photo', 'matterport', 'pointcloud', 'virtualtour', 'attachment', 'externallink']) {
      this.map.setLayoutProperty(`4-${layerType}`,  'visibility', visibilityVal);
    }
    for (let layerId of [1, 2, 3]) {
      for (const tileType of ['point', 'line']) {
        let currentLayerId = `${layerId}-${tileType}`;
        this.map.setLayoutProperty(currentLayerId, 'visibility', visibilityVal);
      }
    }
  }

  getJobIdFromJobName(featureType: string) {
    let jobIds = this.siteLayerData.features.filter((feature: any) => feature.featureType === featureType).map((feature: any) => feature.featureTypeId);
    if (jobIds.length > 0) {
      return parseInt(jobIds[0]);
    }
    else {
      return -99
    }

  }

  setValuesInRequiredMaps(data: any) {
    let featuresData = data.features;
    // if(featuresData?.length > 0) {
    for (let i = 0; i < featuresData.length; i++) {
      let feature: any = {
        type: 'Feature',
        properties: {
          featureGroupId: featuresData[i].featureGroupId,
          featureGroup: featuresData[i].featureGroup,
          featureTypeId: featuresData[i].featureTypeId,
          featureType: featuresData[i].featureType,
          featureId: featuresData[i].featureId.toString(),
          featureName: featuresData[i].feature,
          featureGeometryType:
            featuresData[i].geometry.type === 'LineString'
              ? 'LineString'
              : 'Point',
        },
        geometry: {
          type:
            featuresData[i].geometry.type === 'LineString'
              ? 'LineString'
              : 'Point',
          coordinates: null,
        },
      };

      if (this.featureGroupMap.has(data.layerName)) {
        this.addMainFeatureData(data, feature, 'hasValue');
      } else {
        this.addMainFeatureData(data, feature, null);
      }
    }
  }

  addMainFeatureData(data: any, feature: any, isValue: any) {
    let featureTypeMap = new Map();
    let featurePropertyArray: any[] = [];
    if (isValue === 'hasValue') {
      featureTypeMap = this.featureGroupMap.get(data.layerName);
      if (featureTypeMap.has(feature.properties.featureGroup)) {
        featureTypeMap = this.featureGroupMap
          .get(data.layerName)
          .get(feature.properties.featureGroup);
        if (featureTypeMap.has(feature.properties.featureType)) {
          featurePropertyArray = [] = featureTypeMap.get(
            feature.properties.featureType
          );
          let featureTypeArr = this.featureGroupMap.get(data.layerName);
          featurePropertyArray.push(feature.properties);
          featureTypeMap.set(
            feature.properties.featureType,
            featurePropertyArray
          );
          featureTypeArr.set(feature.properties.featureGroup, featureTypeMap);
          this.featureGroupMap.set(data.layerName, featureTypeArr);

          //for featureGroupTypeFeatureNameToggleStateMap set true for toggle
          let temp = new Map();
          temp = this.featureGroupTypeFeatureNameToggleStateMap.get(
            data.layerName
          );
          let toggleGroup = new Map();
          toggleGroup = this.featureGroupTypeFeatureNameToggleStateMap
            .get(data.layerName)
            .get(feature.properties.featureGroup);
          let featureNameMap = new Map();
          featureNameMap = toggleGroup.get(feature.properties.featureType);
          featureNameMap.set(feature.properties.featureName, true);
          toggleGroup.set(feature.properties.featureType, featureNameMap);
          temp.set(feature.properties.featureGroup, toggleGroup);
          this.featureGroupTypeFeatureNameToggleStateMap.set(
            data.layerName,
            temp
          );
        } else {
          featurePropertyArray = [];
          let featureTypeArr = this.featureGroupMap.get(data.layerName);
          featurePropertyArray.push(feature.properties);
          featureTypeMap.set(
            feature.properties.featureType,
            featurePropertyArray
          );
          featureTypeArr.set(feature.properties.featureGroup, featureTypeMap);
          this.featureGroupMap.set(data.layerName, featureTypeArr);

          //for featureGroupTypeToggleStateMap set true for toggle
          let temp = new Map();
          let toggleGroup = this.featureGroupTypeToggleStateMap.get(
            data.layerName
          );
          temp = this.featureGroupTypeToggleStateMap
            .get(data.layerName)
            .get(feature.properties.featureGroup);
          temp.set(feature.properties.featureType, true);
          toggleGroup.set(feature.properties.featureGroup, temp);
          this.featureGroupTypeToggleStateMap.set(data.layerName, toggleGroup);

          //for featureGroupTypeFeatureNameToggleStateMap set true for toggle
          temp = new Map();
          temp = this.featureGroupTypeFeatureNameToggleStateMap.get(
            data.layerName
          );
          toggleGroup = new Map();
          toggleGroup = this.featureGroupTypeFeatureNameToggleStateMap
            .get(data.layerName)
            .get(feature.properties.featureGroup);
          let featureNameMap = new Map();
          featureNameMap.set(feature.properties.featureName, true);
          toggleGroup.set(feature.properties.featureType, featureNameMap);
          temp.set(feature.properties.featureGroup, toggleGroup);
          this.featureGroupTypeFeatureNameToggleStateMap.set(
            data.layerName,
            temp
          );
        }
      } else {
        featurePropertyArray = [];
        let featureTypeArr = new Map();
        featurePropertyArray.push(feature.properties);
        featureTypeArr.set(
          feature.properties.featureType,
          featurePropertyArray
        );
        featureTypeMap.set(feature.properties.featureGroup, featureTypeArr);
        this.featureGroupMap.set(data.layerName, featureTypeMap);

        //for featureGroupToggleStateMap set true fpr toggle
        let temp = this.featureGroupToggleStateMap.get(data.layerName);
        temp.set(feature.properties.featureGroup, true);
        this.featureGroupToggleStateMap.set(data.layerName, temp);

        //for featureGroupTypeToggleStateMap set true for toggle
        temp = new Map();
        temp = this.featureGroupTypeToggleStateMap.get(data.layerName);
        let toggleGroup = new Map();
        toggleGroup.set(feature.properties.featureType, true);
        temp.set(feature.properties.featureGroup, toggleGroup);
        this.featureGroupTypeToggleStateMap.set(data.layerName, temp);

        //for featureGroupTypeFeatureNameToggleStateMap set true for toggle
        temp = new Map();
        temp = this.featureGroupTypeFeatureNameToggleStateMap.get(
          data.layerName
        );
        toggleGroup = new Map();
        let featureNameMap = new Map();
        featureNameMap.set(feature.properties.featureName, true);
        toggleGroup.set(feature.properties.featureType, featureNameMap);
        temp.set(feature.properties.featureGroup, toggleGroup);
        this.featureGroupTypeFeatureNameToggleStateMap.set(
          data.layerName,
          temp
        );
      }
    } else {
      let featureTypeArr = new Map();
      featurePropertyArray.push(feature.properties);
      featureTypeArr.set(feature.properties.featureType, featurePropertyArray);
      featureTypeMap.set(feature.properties.featureGroup, featureTypeArr);
      this.featureGroupMap.set(data.layerName, featureTypeMap);

      //for featureGroupToggleStateMap set true for toggle
      let temp = new Map();
      temp.set(feature.properties.featureGroup, true);
      this.featureGroupToggleStateMap.set(data.layerName, temp);

      //for featureGroupTypeToggleStateMap set true for toggle
      temp = new Map();
      let toggleGroup = new Map();
      temp.set(feature.properties.featureType, true);
      toggleGroup.set(feature.properties.featureGroup, temp);
      this.featureGroupTypeToggleStateMap.set(data.layerName, toggleGroup);

      //for featureGroupTypeFeatureNameToggleStateMap set true for toggle
      temp = new Map();
      toggleGroup = new Map();
      let featureNameMap = new Map();
      featureNameMap.set(feature.properties.featureName, true);
      temp.set(feature.properties.featureType, featureNameMap);
      toggleGroup.set(feature.properties.featureGroup, temp);
      this.featureGroupTypeFeatureNameToggleStateMap.set(
        data.layerName,
        toggleGroup
      );
    }
  }

  loadMVTSource(appLayerId: number, tileType: string) {
    let currentSourceId = `${appLayerId}-${tileType}`;
    this.commonMapService.setSourceForVectorTilesV2(
      this.map,
      currentSourceId,
      appLayerId,
      tileType
    );
  }

  loadMVT() {
    for (const appLayerId of [1, 2, 3]) {
      for (const tileType of ['line', 'point']) {
        this.loadMVTSource(appLayerId, tileType);
        //this.commonMapService.loadMVTLayerV2(this.map, appLayerId, jobId, tileType, this.pointLayerIds, this.lineLayerIds);
      }
    }

    for (const tileType of ['photo', 'matterport', 'pointcloud', 'virtualtour', 'attachment', 'externallink']) {
      this.loadMVTSource(4, tileType);
    }
  }

  //# Add feature data layer wise
  addGroupLayers() {
    this.loadMVT();
    const regex = new RegExp('^[0-4]-');
    let gprsLayerIds: any = this.map
      .getStyle()
      .layers.filter((layer) => regex.test(layer.id))
      .map((layer) => layer.id);
    for (const layerId of gprsLayerIds) {
      this.map.off('mouseenter', layerId, this.handleLayerMouseEnter);
      this.map.on('mouseenter', layerId, this.handleLayerMouseEnter);
      this.map.off('mouseout', layerId, this.handleLayerMouseOut);
      this.map.on('mouseout', layerId, this.handleLayerMouseOut);
      this.map.off('click', layerId, this.handleLayerMouseClick);
      this.map.on('click', layerId, this.handleLayerMouseClick);
      this.map.off('draw.create', this.globalDrawCreate);
      this.map.on('draw.create', this.globalDrawCreate);
    }
  }

  // Mouseenter - sets hover curso
  handleLayerMouseEnter = (e: any) => {
    const siteMapRegex = new RegExp('^[0-4]-');
    const siteMapPointRegex = new RegExp('^[0-4]-point');
    const siteMapLineRegex = new RegExp('^[0-4]-line');
    let gprsLayerIdsMouseEnter: any = this.map.getStyle().layers.filter(layer => siteMapRegex.test(layer.id)).map(layer => layer.id);
    let gprsPointLayerIds: any = this.map.getStyle().layers.filter(layer => siteMapPointRegex.test(layer.id)).map(layer => layer.id);
    let gprsLineLayerIds: any = this.map.getStyle().layers.filter(layer => siteMapLineRegex.test(layer.id)).map(layer => layer.id);
    if (
      e.features[0].properties.name &&
      (e.features[0].properties.name === 'site' ||
        e.features[0].properties.name === 'subsite')
    ) {
      // for site & subsite
      var gprsLayerIds: any = this.map
        .getStyle()
        .layers.filter((layer) => layer.id)
        .map((layer) => layer.id);
    } else {
      const regex = new RegExp('^[0-4]-');
      var gprsLayerIds: any = this.map
        .getStyle()
        .layers.filter((layer) => regex.test(layer.id))
        .map((layer) => layer.id);
    }
    // Set `bbox` as ?px reactangle area around clicked point.
    const bboxPadding = 3;
    let bbox : any = [];
    if(e.point){
      bbox = [
        [e.point.x - bboxPadding, e.point.y - bboxPadding],
        [e.point.x + bboxPadding, e.point.y + bboxPadding],
      ];
    }
    else {
      bbox = [
        [e.attributes.coordinate.long - bboxPadding, e.attributes.coordinate.lat - bboxPadding],
        [e.attributes.coordinate.long + bboxPadding, e.attributes.coordinate.lat + bboxPadding],
      ];
    }
    let hoveredSitemapFeatures = [];
    if (this.isDrawing) {
      // Disable all interactions
    } else if (this.disablePointInteractions) {
      // Allow only Line interactions
      hoveredSitemapFeatures = this.map.queryRenderedFeatures(bbox, {
        layers: [...gprsLineLayerIds],
      });
    } else {
      // Allow all interactions
      hoveredSitemapFeatures = this.map.queryRenderedFeatures(bbox, {
        layers: [...gprsLayerIds],
      });
    }
    // let hoveredSitemapFeatures = this.map.queryRenderedFeatures(bbox, {
    //   layers: [...gprsLayerIds],
    // });
    if (hoveredSitemapFeatures.length > 0) {
      this.map.getCanvasContainer().style.cursor = 'pointer';
    }
  };

  // Mouseout - removes hover cursor
  handleLayerMouseOut = (e: any) => {
    this.map.getCanvasContainer().style.cursor = '';
  };

  // handle feature description update
  updateOnDescriptionChange(e:any) {
    this._proceedWithClick(e);
  }

  // Mouse click
  handleLayerMouseClick = (e: any) => {
    this.map.doubleClickZoom.disable();
    // if(!this.isEditingModeIsOn && this.enableEditModelOnDblClick)
    this.enableEditModelOnDblClick = false;
    this.numberOfClicks++;

    const clickedFeatures = this._getClickedFeatures(e);
    setTimeout(() => {
      // added log to see how many click event generated by mapbox
      console.log("No of Clicks : ", this.numberOfClicks);
      // BUG 30763, removing check for multiple layers.  Always return the topmost feature.
      this._proceedWithClick(e);
    }, 500);
  };

  private _getMultipleSelectionMsg(clickedFeatures: any[]) {
    let editingFeature = clickedFeatures[0];
    let editingFeatureName = editingFeature.properties.featureName;
    let editingFeatureType = editingFeature.properties.featureType;
    let editingFeatureGroup = editingFeature.properties.featureGroup;
    let confirmMessage: string = `It seems you have selected multiple features. Would you like proceed ahead with ${editingFeatureGroup} - ${editingFeatureType} - ${editingFeatureName}?`;

    return confirmMessage;
  }

  private _proceedWithClick(e: any) {
    this.enableEditModelOnDblClick = false;
    this._handleSingleClick(e);
    this.numberOfClicks = 0;
  }

  onUpdateNavigatedFeatures(data:any[]){
      this.navigatedFeatures = data;
      this.cdRef.detectChanges();
  }

  private _handleSingleClick(e: any, isDblClicked?: boolean) {
    this.featureDetailOnClick = e;
    //this.clickedFeaturesForLayer = [];
    this.clickedDocFeatures = [];
    let clickedFeatures: any[] = this._getClickedFeatures(e);
    if(clickedFeatures.length == 0){
      clickedFeatures = this.clickedFeaturesForLayer;
    }
    if (clickedFeatures.length > 0) {
      this.clickedFeaturesForLayer = [clickedFeatures[0]];
      let clickedFeature = clickedFeatures[0];
      if(this.isEditingModeIsOn){
        this.enableEditModelOnDblClick = true;
      } else if(!this.isEditingModeIsOn) {
        if (clickedFeature.geometry.type === 'Point' && !this.isEditingModeIsOn) {
          this.spinner.show();

          this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
            .fetchPointFeatureDetail(
              this.checkAnnotationPoint(clickedFeature.properties),
              parseInt(clickedFeature.properties.featureId)
            )
            .subscribe((data: any) => {
              if (
                clickedFeatures[0].properties.featureClass !=
                this.commonMapService.externalContentIconImage[
                  clickedFeatures[0].properties.featureClass
                ]?.toLowerCase()
              ) {
                this.editDescriptionValue = data;
                this.openedFeatureProperties = data.groups.features[0].properties;
                this.openedFeatureAttributes = data.groups.features[0].attributes;
                this.openedFeatureAttributes.coordinate = this.createCoordinates(data.groups.features[0].geometry);
                this.jobId = data.jobId;
                this.openedFeatureName =
                  data.groups.features[0].properties.FeatureName;
                if (
                  data.groups.features[0].attributes.addedLink != null ||
                  undefined || ''
                ) {
                  let textLink = data.groups.features[0].attributes.addedLink;
                  this.openedFeatureAttributesAddedLink = textLink.split(' , ');
                } else {
                  this.openedFeatureAttributesAddedLink = [];
                }
                this.slideMenuOpenJobDescription = isDblClicked ? false : true;
                this.slideMenuOpenLayerDetails = isDblClicked ? true : false;
              } else {
                if(!isDblClicked){
                  const woNO = data.groups?.features[0]?.attributes?.workOrderNumber;
                  const ftName = clickedFeatures[0].properties.featureName;
                  this.modalHeader = clickedFeatures[0].properties.featureGroup;
                  
                  if (this.modalHeader == "MATTERPORT") {
                    this.modalHeader = "VIRTUAL TOUR";
                  };
                  
                  const tempLayerData = this.navigatedFeatures.find(
                    (it: any) =>
                      it.layerId === clickedFeatures[0].properties.layerId &&
                      it.jobId === clickedFeatures[0].properties.mapJobId
                  );
                  const tempFeaturesDetail = tempLayerData?.features.find(
                    (it: any) =>
                      it.featureId === clickedFeatures[0].properties.featureId &&
                      it.feature === clickedFeatures[0].properties.featureName
                  );
                  let button = document.getElementById(
                    'btnModalPhoto'
                  ) as HTMLButtonElement;
                  button.click();
                  let siteId = document.getElementById('siteId') as HTMLSpanElement;
                  let woNo = document.getElementById('woNo') as HTMLSpanElement;

                  this.spinner.hide();
                  if (this.modalHeader === 'PHOTO') {
                    let img = document.getElementById(
                      'imgPhoto'
                    ) as HTMLImageElement;
                    let photoDiv = document.getElementById('photoDiv') as HTMLDivElement;
                    photoDiv.hidden = false;
                    // the imgPhoto element is needs to present in the DOM.
                    img.hidden=false;
                    img.src = tempFeaturesDetail ? tempFeaturesDetail.addedLink + this.dprFileDownloadSAS : clickedFeatures[0].properties.feature + this.dprFileDownloadSAS;
                    siteId.innerText = tempLayerData?.siteId ? tempLayerData.siteId : '';
                    woNo.innerText = tempLayerData?.workOrderNumber ? tempLayerData.workOrderNumber : clickedFeatures[0].properties.workorderNumber;
                  } else {
                    let img = document.getElementById(
                      'imgPhoto'
                    ) as HTMLImageElement;
                    img.hidden=true;
                    let photoDiv = document.getElementById('photoDiv') as HTMLDivElement;
                    photoDiv.hidden = true;
                    this.externalContentTemplateData = {
                      siteId: tempLayerData?.siteId,
                      woNo: tempLayerData?.workOrderNumber? tempLayerData.workOrderNumber :  clickedFeatures[0].properties.workorderNumber,
                      siteName: tempLayerData?.siteName,
                      featureName: tempFeaturesDetail?.feature ? tempFeaturesDetail.feature : ftName,
                      featureLink: tempFeaturesDetail?.addedLink ? tempFeaturesDetail.addedLink : clickedFeatures[0].properties.feature,
                    };

                    if (this.modalHeader === "VIRTUAL TOUR") {
                      // PAW create the table for the vt links 
                      let matterportObj = JSON.parse(clickedFeatures[0].properties.matterportLinks || '{}');
                      this.matterportLinkArray = matterportObj;
                      if (this.matterportLinkArray.length > 0){
                        this.matterportLinkArray.forEach((vtLink:any) => {
                          vtLink['action'] = new virtualTourListTableDataDC().actionOpenButton;      
                        });
                      }                      
                    }

                    if(tempFeaturesDetail?.addedLink || this.modalHeader === 'VIRTUAL TOUR' || this.modalHeader==='POINTCLOUD'){
                      this.externalContentTemplateData={
                        siteId: tempLayerData?.siteId,
                        woNo: clickedFeatures[0].properties.workorderNumber? clickedFeatures[0].properties.workorderNumber : woNO,
                        siteName: tempLayerData?.siteName,
                        featureName: tempFeaturesDetail?.feature ? tempFeaturesDetail.feature : ftName,
                        featureLink: tempFeaturesDetail?.addedLink ? tempFeaturesDetail.addedLink : clickedFeatures[0].properties.feature,
                      }
                      this.iframeExternalContentLink = true;
                      this.cdRef.detectChanges();
                      let iframeExternal = document.getElementById("externalContentLink") as HTMLIFrameElement;
                      iframeExternal.src = tempFeaturesDetail?.addedLink ? tempFeaturesDetail.addedLink : clickedFeatures[0].properties.feature;
                    } else {
                      this.iframeExternalContentLink = false;
                    }
                  }
              }
                this.slideMenuOpenJobDescription = false;
              }

              this.slideMenuOpenTools = false;
              this.slideMenuOpenFolder = false;
              this.slideMenuOpenLayerDetails = isDblClicked ? true : false;
              this.spinner.hide();
            });
        } else if ((clickedFeature.geometry.type === 'LineString' || clickedFeature.geometry.type === 'MultiLineString') && !this.isEditingModeIsOn) {
          this.spinner.show();
          this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
            .fetchLineFeatureDetail(
              this.checkAnnotationLine(clickedFeature.properties),
              parseInt(clickedFeature.properties.featureId)
            )
            .subscribe((data: any) => {
              this.editDescriptionValue = data;
              this.openedFeatureProperties = data.groups.features[0].properties;
              this.openedFeatureAttributes = data.groups.features[0].attributes;
              this.openedFeatureAttributes.coordinate = this.createCoordinates(data.groups.features[0].geometry);
              this.jobId = data.jobId;
              this.openedFeatureName =
                data.groups.features[0].properties.FeatureName;
              if (
                data.groups.features[0].attributes.addedLink != null ||
                undefined ||
                ''
              ) {
                let textLink = data.groups.features[0].attributes.addedLink;
                this.openedFeatureAttributesAddedLink = textLink.split(' , ');
              } else {
                this.openedFeatureAttributesAddedLink = [];
              }
              this.slideMenuOpenJobDescription = isDblClicked ? false : true;
              this.slideMenuOpenLayerDetails = isDblClicked ? true : false;
              this.slideMenuOpenTools = false;
              // this.slideMenuOpenLayerDetails = false;
              this.slideMenuOpenFolder = false;
              this.spinner.hide();
            });
        }
      }
    }
  }

  private _getClickedFeatures(e: any) {
    const regex = new RegExp('^[0-4]-');
    let gprsLayerIds: any = this.map
      .getStyle()
      .layers.filter((layer) => regex.test(layer.id))
      .map((layer) => layer.id);
    // Set `bbox` as ?px reactangle area around clicked point.
    const bboxPadding = 3;
    let bbox : any = [];
    if(e.point){
      bbox = [
        [e.point.x - bboxPadding, e.point.y - bboxPadding],
        [e.point.x + bboxPadding, e.point.y + bboxPadding],
      ];
    }
    else {
      bbox = [
        [e.attributes.coordinate.long - bboxPadding, e.attributes.coordinate.lat - bboxPadding],
        [e.attributes.coordinate.long + bboxPadding, e.attributes.coordinate.lat + bboxPadding],
      ];
    }

    // Find features intersecting the bounding box.
    const clickedFeatures: any[] = this.map.queryRenderedFeatures(bbox, {
      layers: [...gprsLayerIds],
    });

    return clickedFeatures;
  }

  gotolink(url:string) {
    if (confirm("You are leaving the Sitemap website to view external content.  Do you wish to continue?")) {
      window.open(url, "_blank");
    }
  }

  private _handleLayerDblClick(e: any) {
    if (!this.editCheck) {
      this.permissionService.showErrormessageForPersonal();
      return;
    }
    this.clickedFeatureLat = e?.lngLat?.lat;
    this.clickedFeatureLng = e?.lngLat?.lng;
    this.clickedFeaturesForLayer = [];
    this.clickedFeaturesForLayer = this._getClickedFeatures(e);
    this.showToggleSideBar = true;
    // this.slideMenuOpenLayerDetails = true;
    this.enableEditModelOnDblClick = true;
    setTimeout(() => {
      this._handleSingleClick(e, true);
    }, 300);
  }

  updateDblClick(isDblClick:any){
    this.enableEditModelOnDblClick = isDblClick;
  }

  getGroupFilterExpression(featureGroup: string) {

    const expression = [
      'all',
      ['==', ['get', 'featureGroup'], featureGroup],
      ['in', ['get', 'mapJobId'], ['literal', this.zoomedJobIds]],
    ];

    return expression;
  }

  /*
Generate the "paint" style required for LINE based on "feature_type_id"
Returns the entire "paint" style object
"case" syntax:
["case",
condition: boolean, output: OutputType,
condition: boolean, output: OutputType,
...,
fallback: OutputType
]
See: https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#case
*/
  getLinePaintByGroupName(lineFeatureGroupName: string) {
    // This is the return style object
    // "line-color" and "line-width" will be set using Mapbox expressions with "case" operator
    let linePaint: any = {
      'line-color': ['case'],
      'line-width': ['case'],
    };

    // Iterate over each LINE style in the style schema
    // and push "condition" and "output" to "case" expression
    // for each style based on "feature_type_name"
    FeatureTypeStyleMapping.featureTypeStyleJson
      .filter(
        (featureStyle) =>
          featureStyle.feature_group_name === lineFeatureGroupName
      )
      .forEach((featureStyle) => {
        linePaint['line-color'].push([
          '==',
          ['get', 'featureType'],
          featureStyle.feature_type_name,
        ]);
        linePaint['line-color'].push(featureStyle.color);
        linePaint['line-width'].push([
          '==',
          ['get', 'featureType'],
          featureStyle.feature_type_name,
        ]);
        linePaint['line-width'].push(featureStyle.line_width);
      });

    // "case" requires a default/fallback value
    // and they are added at the end of the expression
    linePaint['line-color'].push('rgb(255,153,255)');
    linePaint['line-width'].push(2);


    return linePaint;
  }

  getLinePaint() {
    // This is the return style object
    // "line-color" and "line-width" will be set using Mapbox expressions with "case" operator
    let linePaint: any = {
      'line-color': ['case'],
      'line-width': ['case'],
    };

    // Iterate over each LINE style in the style schema
    // and push "condition" and "output" to "case" expression
    // for each style based on "feature_type_name"
    FeatureTypeStyleMapping.featureTypeStyleJson
      .filter((feature) => feature.geometry_type == 'LINE')
      .forEach((featureStyle: any) => {
        linePaint['line-color'].push([
          '==',
          ['get', 'featureType'],
          featureStyle.feature_type_name,
        ]);
        linePaint['line-color'].push(featureStyle.color);
        linePaint['line-width'].push([
          '==',
          ['get', 'featureType'],
          featureStyle.feature_type_name,
        ]);
        linePaint['line-width'].push(featureStyle.line_width);
      });

    // "case" requires a default/fallback value
    // and they are added at the end of the expression
    linePaint['line-color'].push('rgb(255,153,255)');
    linePaint['line-width'].push(2);


    return linePaint;
  }

  onFeaturePropertyObjectUpdate(obj: any) {
    this.newlyAddedFeatureProperties = obj;
  }

  @ViewChild(LayerComponent) layerComponent! : LayerComponent;

  setEditingHistory(action: string, id: string) {
    if (
      action === 'create' ||
      action === 'move' ||
      action === 'change_coordinates'
    ) {

      this.currentFeatureToCommit = {...this.draw.get(id)};
      const layerId = action === 'create' ? this.currentFeatureToCommit?.properties?.layerId : this.currentFeatureToCommit?.properties?.mapLayerId;
      if(this.currentFeatureToCommit?.geometry?.type !== "Polygon" && layerId) {
        this.layerComponent.isFirstEditDetected = true;
        if(action === 'create'){
          this.selectedFeatureFromDraw = this.currentFeatureToCommit;
          this.mapEditService.constructCommitObject({...this.currentFeatureToCommit}, true);
        } else if(action === "move") {
          this.mapEditService.constructCommitObject({...this.currentFeatureToCommit}, false);
        }else if(action === "change_coordinates") {
          this.mapEditService.constructCommitObject({...this.currentFeatureToCommit}, false, true);
        }
      }

      if(this.layerComponent?.editingHistoryUndoArray.filter((ft:any)=> JSON.stringify(ft.geometry.coordinates) == JSON.stringify(this.draw.get(id).geometry.coordinates)).length === 0) {
        this.layerComponent?.editingHistoryUndoArray.push(this.draw.get(id));
      }
      //
      this.layerComponent.editingHistoryRedoArray = this.layerComponent?.editingHistoryRedoArray.filter((x:any)=> x.id != id);
    }
  }

  addDrawEvent() {
    // this.map.on('draw.create', (e) => {
    //   const data = this.draw.getAll();
    //   this.siteCoordinate =
    //     this.draw.getAll().features[0].geometry.coordinates[0];
    //   if (!this.isEditingModeIsOn) {
    //     if (
    //       data.features[data.features.length - 1].geometry.type == 'LineString'
    //     ) {
    //       var coordinates =
    //         data.features[
    //           data.features.length - 1
    //         ].geometry.coordinates[0].slice();
    //       const length = turf.length(data.features[data.features.length - 1]);
    //       var rounded_length = Math.round(length * 3280.8398950131);
    //       this.measeureLength(rounded_length, coordinates);
    //     } else if (
    //       data.features[data.features.length - 1].geometry.type == 'Polygon'
    //     ) {
    //       this.exportDrawPolygonCordinates =
    //         data.features[data.features.length - 1].geometry.coordinates;
    //       if (this.exportDrawPolygonCordinates) {
    //         for (var i = 0; i < this.exportDrawPolygonCordinates.length; i++) {
    //           if (i == 0) {
    //             this.exportDrawPolygonCordinates[i].splice(i, 1);
    //           }
    //         }
    //       }
    //       var coordinates =
    //         data.features[
    //           data.features.length - 1
    //         ].geometry.coordinates[0][0].slice();
    //       let allCoordinatesInPolygon: any = [];
    //       let bounds: any = new mapboxgl.LngLatBounds();
    //       this.getJobDataPolygon(data);
    //       const area = turf.area(data.features[data.features.length - 1]);
    //       var rounded_area = Math.round(area* 10.7639);
    //       this.measureCalcArea(rounded_area, coordinates);
    //     }
    //   } else {

    //     let drawFeatureId = e.features[0].id;
    //     let drawFeature = this.draw.get(drawFeatureId);

    //     // Set properties for the Draw feature
    //     // Create a temp featureName
    //     this.draw.setFeatureProperty(
    //       drawFeatureId,
    //       'featureName',
    //       this.newlyAddedFeatureProperties?.newAddedFeatureName
    //       // `${this.addNewFeatureTypeFrMapEditor}${Math.random()
    //       //   .toString()
    //       //   .slice(2, 10)}`
    //     );
    //     this.draw.setFeatureProperty(
    //       drawFeatureId,
    //       'featureType',
    //       this.newlyAddedFeatureProperties?.addNewFeatureTypeFrMapEditor
    //     );
    //     this.draw.setFeatureProperty(
    //       drawFeatureId,
    //       'featureGroup',
    //       this.newlyAddedFeatureProperties?.addNewFeatureGrpFrMapEditor
    //     );
    //     this.draw.setFeatureProperty(
    //       drawFeatureId,
    //       'featureGroupId',
    //       this.newlyAddedFeatureProperties?.addNewFeatureGrpIdFrMapEditor
    //     );
    //     this.draw.setFeatureProperty(
    //       drawFeatureId,
    //       'featureTypeId',
    //       this.newlyAddedFeatureProperties?.addNewFeatureTypeIdFrMapEditor
    //     );
    //     this.draw.setFeatureProperty(
    //       drawFeatureId,
    //       'layerId',
    //       this.newlyAddedFeatureProperties?.currentEditinglayerId
    //     );
    //     this.draw.setFeatureProperty(
    //       drawFeatureId,
    //       'mapJobId',
    //       this.newlyAddedFeatureProperties?.navigatedJobId
    //     );
    //     this.draw.setFeatureProperty(drawFeatureId, 'drawId', drawFeatureId);


    //     if (drawFeature.geometry.type === 'Point') {
    //       this.draw.setFeatureProperty(drawFeatureId, 'mapPointId', 0);
    //       this.draw.changeMode('simple_select');
    //     } else if (drawFeature.geometry.type === 'LineString') {
    //       this.draw.setFeatureProperty(drawFeatureId, 'mapLineId', 0);
    //     }

    //     // A create action removes all future states, if any exist
    //     this.setEditingHistory('create', drawFeatureId);
    //   }


    // });

    this.map.on('draw.delete', (e) => {
      this.counter = 0;
      const data = this.draw.getAll();
      const popups = document.getElementsByClassName('mapboxgl-popup');
      if (popups.length) {
        popups[0].remove();
      }
      if (false && data.features.length > 0) {
        const coordinates = data.features[0].geometry.coordinates[0][0].slice();
        const area = turf.area(data);
        // Restrict the area to 2 decimal points.
        var rounded_area = Math.round(area * 10.7639) / 100;
        this.measureCalcArea(rounded_area, coordinates);
      }
      this.exportDrawPolygonCordinates = [];
      this.polygonGeoJson = {};
      this.multipleJobsIdByPoligonDraw = [];
      this.exportForm.value.filename = '';
      // Get the draw action for history
      let drawAction = e.action;
      // An update action removes all future states, if any exist
      this.setEditingHistory(drawAction, e.features[0].id);
    });

    this.map.on('draw.update', (e: any) => {
      // if (this.counter == 1 && e.action == "change_coordinates") {
      //   this.draw.deleteAll()
      //   this.counter = 0
      //   this.onPrintShowPrintBox(e)
      // }
      let selectedFeature = this.draw.getSelected().features[0];

      const data = this.draw.getAll();
      if (selectedFeature.geometry.type == 'LineString') {
        var coordinates = selectedFeature.geometry.coordinates[0].slice();
        const length = turf.length(selectedFeature);
        var rounded_length = Math.round(length * 3280.8398950131);
        this.measeureLength(rounded_length, coordinates);
        this.removeExtraPopup('.mapboxgl-popup-anchor-bottom');
      } else if (selectedFeature.geometry.type == 'Polygon') {
        const geojsonSource = this.map.getSource(
          selectedFeature.properties.siteName
        ) as mapboxgl.GeoJSONSource;
        if (geojsonSource) {
          geojsonSource.setData({
            type: 'FeatureCollection',
            features: [selectedFeature],
          });
        }
        var coordinates = selectedFeature.geometry.coordinates[0][0].slice();
        this.getJobDataPolygon(data);
        const area = turf.area(selectedFeature);
        var rounded_area = Math.round(area*10.7639);
        this.measureCalcArea(rounded_area, coordinates);
        this.removeExtraPopup('.mapboxgl-popup-anchor-bottom');
      }
      // Get the draw action for history
      let drawAction = e.action;
      // An update action removes all future states, if any exist
      this.setEditingHistory(drawAction, selectedFeature.id);
    });

    this.map.on('draw.selectionchange', (e:any) => {
      this.selectedFeatureFromDraw = e?.features[0];
      if (e.features.length > 0) {
        if(e.features && this.layerComponent.editingHistoryUndoArray.filter((x:any)=>x.id==e.features[0].id).length == 0){
          this.layerComponent.editingHistoryUndoArray.push(e.features[0]);
        }
      }
      if(e?.features[0]?.properties?.isPrintBox) {
        this.drawHandlePrint.modes.direct_select.dragVertex = function(state:any, e:any){
          console.log('printbox polygon vertex can not be edited');
        }
      }
    })

    this.map.on('click', (e) => {
      // Set `bbox` as 5px reactangle area around clicked point.
      const width = 10;
      const height = 20;
      let features;
      if (this.isSearchAppliedOnJobData) {
        features = this.map.queryRenderedFeatures(
          [
            [e.point.x - width / 2, e.point.y - height / 2],
            [e.point.x + width / 2, e.point.y + height / 2],
          ],
          { layers: ['search-unclustered-point'] }
        );
      } else {
        features = this.map.queryRenderedFeatures(
          [
            [e.point.x - width / 2, e.point.y - height / 2],
            [e.point.x + width / 2, e.point.y + height / 2],
          ],
          { layers: ['unclustered-point'] }
        );
      }
      let selectedFeature = this.draw.getSelected().features[0];
      // this.selectedFeatureFromDraw = selectedFeature;
      this.selectedFeatureFromDraw = this.selectedFeatureFromDraw ? this.selectedFeatureFromDraw : selectedFeature;
      if (selectedFeature?.geometry?.type == 'LineString') {
        var coordinates = selectedFeature.geometry.coordinates[0].slice();
        const length = turf.length(selectedFeature);
        var rounded_length = Math.round(length * 3280.8398950131);
        this.measeureLength(rounded_length, coordinates);
        this.removeExtraPopup('.mapboxgl-popup-anchor-bottom');
      } else if (selectedFeature?.geometry?.type == 'Polygon') {
        var coordinates = selectedFeature.geometry.coordinates[0][0].slice();
        const area = turf.area(selectedFeature);
        var rounded_area = Math.round(area*10.7639);
        this.measureCalcArea(rounded_area, coordinates);
        this.removeExtraPopup('.mapboxgl-popup-anchor-bottom');
      }
    });

  }

  onMeaseureLength(obj:any) {
    this.measeureLength(obj.rounded_length, obj.coordinates);
  }

  onMeasureCalcArea(obj:any){
    this.measeureLength(obj.rounded_length, obj.coordinates);
  }

  onRemoveExtraPopup(){
    this.removeExtraPopup('.mapboxgl-popup-anchor-bottom');
  }

  getJobDataPolygon(data: any) {
    this.multipleJobsIdByPoligonDraw = [];
    const userPolygon: any = data.features[data.features.length - 1];

    // generate bounding box from polygon the user drew
    const polygonBoundingBox = turf.bbox(userPolygon);
    const southWest: any = [polygonBoundingBox[0], polygonBoundingBox[1]];
    const northEast: any = [polygonBoundingBox[2], polygonBoundingBox[3]];
    const northEastPointPixel = this.map.project(northEast);
    const southWestPointPixel = this.map.project(southWest);
    let features :any;
    if (this.isSearchAppliedOnJobData) {
      features = this.map.queryRenderedFeatures(
        [southWestPointPixel, northEastPointPixel],
        { layers: ['search-unclustered-point'] }
      );
    } else {
      features = this.map.queryRenderedFeatures(
        [southWestPointPixel, northEastPointPixel],
        { layers: ['unclustered-point'] }
      );
    }
    for (var i = 0; i < features.length; i++) {
      const jobid: any = features[i].properties;
      const jobIDGeom : any = features[i].geometry?.coordinates;
      let jobIDFeture = turf.point(jobIDGeom);
      let jobInsideBoundary = turf.pointsWithinPolygon(jobIDFeture,userPolygon);
      if(jobInsideBoundary.features.length){
        this.multipleJobsIdByPoligonDraw.push(jobid.jobId);
      }
    }
  }

  //#region removeExtraPopup method use to remove the extra open coordinate popup
  removeExtraPopup(className: any) {
    setTimeout(() => {
      let ele = document.querySelectorAll(className);
      if (ele?.length > 0) {
        if (ele?.length === 1) {
          ele[0].remove();
        } else {
          for (let i = 0; i < ele.length - 1; i++) {
            ele[i].remove();
          }
        }
      }
    }, 0);
  }
  //#endregion

  //# change map type for different map style
  changeMaptypeEvent(e: any) {
    this.ngOnDestroy();
    if (this.currentStyle != e.target.value) {
      this.map.setStyle('mapbox://styles/mapbox/' + e.target.value);
      this.isMapTypeChanged = true;
      this.currentStyle = e.target.value;
      this.counter = 0;
      this.printTypeSubscription = this.behaviourSubjectService.printTriggerHeader.subscribe((printType) => {
      // console.log(printType, '000000000000')
       this.printType = printType;
       console.log(' this.printType',  this.printType);
       
       if(this.printType =='print'){
          this.onPrintShowPrintBox(this.currentEvent);
          this.printState = false;
          if(this.counter == 0){
            this.counter = 1;
          }
        }else{
         this.counter = 0;
        }
      });
    }
    if (this.map.getZoom() >= 13.25) {
      this.getSiteList();
      this.getSubSiteList();
    }
  }

  changeRenderScaleEvent(e: any) {
    //console.log("changed rendering scale to " + e.target.value);

    if (this.currentRenderScale != e.target.value) {
      sessionStorage.setItem("userRenderingScale",  e.target.value);
      this.userRenderScale = this.renderScaleService.getMinLevelForScale();

      if (this.currentZoomLevel >= this.userRenderScale) {
        this.showToggleSideBar = true;
      } else {
        this.showToggleSideBar=false;
      }

      this.loadMVT();

      this.currentRenderScale = e.target.value;

      let payload = {
        userId: sessionStorage.getItem("loginUserId"),
        renderingScale: this.currentRenderScale
      };

      this.apiService.updateUserRenderScale(payload).subscribe((response) => {
        console.log("updated scale in db: " + response);
      });
    }

  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  //# Layers of different features
  createFeatureLayers() {
    if (this.map.getZoom() >= 13.25) {
      const jobsIds: any = Array.from(this.zoomedJobIdLayerIdMap.keys());
      this.zoomedJobIdLayerIdMap.clear();
      for (let i = 0; i < jobsIds.length; i++) {
        this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
          .fetchFeaturesByJobId(parseInt(jobsIds[i]), this.loginUserId)
          .subscribe(
            (dataWithLayer: any) => {
              if (dataWithLayer.length != 0) {
                let featuresData = dataWithLayer[0].features;
                this.zoomedJobIdLayerIdMap.set(jobsIds[i], []);
                var zoomedJobFeatureGeoJson: any = {
                  type: 'FeatureCollection',
                  features: [],
                };
                for (let i = 0; i < featuresData.length; i++) {
                  let feature: any = {
                    type: 'Feature',
                    properties: {
                      featureGroupId: featuresData[i].featureGroupId,
                      featureGroup: featuresData[i].featureGroup,
                      featureTypeId: featuresData[i].featureTypeId,
                      featureType: featuresData[i].featureType,
                      featureId: featuresData[i].featureId.toString(),
                      featureName: featuresData[i].feature,
                      featureGeometryType:
                        featuresData[i].geometry.type === 'LineString'
                          ? 'LineString'
                          : 'Point',
                    },
                    geometry: {
                      type:
                        featuresData[i].geometry.type === 'LineString'
                          ? 'LineString'
                          : 'Point',
                      coordinates: null,
                    },
                  };

                  const stringifiedCoordinates =
                    featuresData[i].geometry.coordinates;
                  let coordinates = stringifiedCoordinates
                    .slice(1, -1)
                    .split('],');
                  for (let i = 0; i < coordinates.length; i++) {
                    if (i == coordinates.length - 1) {
                      coordinates[i] = coordinates[i].slice(1, -1).split(', ');
                      coordinates[i][0] = parseFloat(coordinates[i][0]);
                      coordinates[i][1] = parseFloat(coordinates[i][1]);
                      coordinates[i] = coordinates[i].slice(0, 2);
                    } else {
                      coordinates[i] = coordinates[i].slice(1).split(', ');
                      coordinates[i][0] = parseFloat(coordinates[i][0]);
                      coordinates[i][1] = parseFloat(coordinates[i][1]);
                      coordinates[i] = coordinates[i].slice(0, 2);
                    }
                  }
                  if (feature.geometry.type === 'LineString') {
                    feature.geometry.coordinates = coordinates;
                  } else {
                    feature.geometry.coordinates = coordinates[0];
                  }
                  zoomedJobFeatureGeoJson.features.push(feature);
                }

                this.map.addSource(jobsIds[i], {
                  type: 'geojson',
                  data: zoomedJobFeatureGeoJson,
                });

                let layerIdArray = this.zoomedJobIdLayerIdMap.get(jobsIds[i]);

                for (const feature of zoomedJobFeatureGeoJson.features) {
                  const layerId = feature.properties.featureId;
                  layerIdArray.push(layerId);
                  const featureTypeId = feature.properties.featureTypeId;
                  if (!this.map.getLayer(layerId)) {
                    if (
                      feature.properties.featureGeometryType === 'LineString'
                    ) {
                      const featureTypeMetaData =
                        this.featureTypeStyleMap.get(featureTypeId);
                      if (featureTypeMetaData == undefined) {
                        this.map.addLayer({
                          id: layerId,
                          type: 'line',
                          source: jobsIds[i],
                          layout: {
                            visibility: 'visible',
                            'line-join': 'round',
                            'line-cap': 'round',
                          },
                          paint: {
                            'line-color': '#808080',
                            'line-width': 2,
                          },
                          filter: ['==', 'featureId', layerId],
                        });
                      } else {
                        this.map.addLayer({
                          id: layerId,
                          type: 'line',
                          source: jobsIds[i],
                          layout: {
                            visibility: 'visible',
                            'line-join': 'round',
                            'line-cap': 'round',
                          },
                          paint: {
                            'line-color': featureTypeMetaData.color,
                            'line-width': featureTypeMetaData.line_width,
                          },
                          filter: ['==', 'featureId', layerId],
                        });
                      }
                    } else if (
                      feature.properties.featureGeometryType === 'Point'
                    ) {
                      let updateFeatureName = '';
                      if (
                        this.pointFeaturesIconsAvailableSet.has(
                          feature.properties.featureType
                        )
                      ) {
                        updateFeatureName = feature.properties.featureType;
                      } else {
                        updateFeatureName = 'GENERIC_BRIAN';
                      }
                      this.map.addLayer({
                        id: layerId,
                        type: 'symbol',
                        source: jobsIds[i],
                        layout: {
                          visibility: 'visible',
                          'icon-image': updateFeatureName,
                          'icon-size': 0.3,
                        },
                        filter: ['==', 'featureId', layerId],
                      });
                    }
                  }
                }
                this.zoomedJobIdLayerIdMap.set(jobsIds[i], layerIdArray);
              }
            },
            (error: any) => {
              console.log('error fetching in data for: ' + jobsIds[i]);
            }
          );
      }
    }
    this.spinner.hide();
  }

  //# find out lat ,long by global search
  pointLatLongFrmGlobalSearch(lat: any, long: any) {
    this.map.flyTo({
      // center: [12.65147, 55.608166],
      center: [long, lat],
    });
    this.searchMarker = new mapboxgl.Marker({ color: 'green' })
      .setLngLat([long, lat])
      // .setLngLat([12.65147, 55.608166])
      .setPopup(
        new mapboxgl.Popup({ offset: 25 }).setHTML(`
            <div class="custom-map-header">
              <h2>102 3933 JAMES CONSTRUCTION CO</h2>
            </div>
            <div class="custom-map-tooltip">
              <div class="table-responsive">
                <table class="table table-bordered table-striped custom-table">
                  <tr>
                    <td>Location</td>
                    <td>${long}, ${lat}</td>
                  </tr>
                  <tr>
                    <td>Site Contact</td>
                    <td>327 Cranbrook Road
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>nitin.rajput@gprsinc.com
                    </td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Created on</td>
                    <td>07/03/2021 10:26:02 AM
                    </td>
                  </tr>
                  <tr>
                    <td>Added link </td>
                    <td>https://my.google.com /show/?m=uRGXgoiYk9f& help=1
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="custom-map-footer">
              <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
              <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
            </div>
          `)
      )
      .addTo(this.map);
  }

  ngOnDestroy() {
    this.behaviourSubjectService.printShareTrigger('');
    this.printTypeSubscription?.unsubscribe();
    this.searchTypeSubscription?.unsubscribe();
    this.searchInputSubscription?.unsubscribe();
    this.headerSearchSubscrition?.unsubscribe();
    this.shareJobByHeaderIcon?.unsubscribe();
    this.behaviourSubjectService.changeIsMapHeaderSearch(false);
    this.dashboardSubscribe?.unsubscribe();
    this.mapViewerSubscribe?.unsubscribe();
    this.getAllVectorsSubscribe?.unsubscribe();
    this.mapViewerJobFeatureSubscribe?.unsubscribe();
    this.headerLayerButtonStateSubscription?.unsubscribe();
    this.forkJoinMapFeaturesSubscription?.unsubscribe();
    this.forkJoinDocumentsSubscription?.unsubscribe();
   // this.qaEnvValSubscription.unsubscribe();
    this.myPreferenceSubscription.unsubscribe();
    this.adminGeojsonuploadSubscription?.unsubscribe();
    this.emailFormControlSubscription?.unsubscribe();
    this.finJobsSubscription?.unsubscribe();
    this.mapViewerDocumentsSubscribe?.unsubscribe();
    this.siteDataSubscription?.unsubscribe();
  }

    //# search job details by search bar
  //Anshuma search address parameter
  searchJobsMarker = new mapboxgl.Marker();
  searchJobsData() {
    this.searchJobsMarker.remove();
    if (this.selectedSearchType == 10) {
      this.getSiteList();
      this.getSubSiteList();
    }
    //Job search by address
    if(this.selectedSearchType == 6){
      this.spinner.show();
      const searchStringInput = JSON.parse(this.searchStringInput);
      const searchStringArray = Object.values(searchStringInput).filter((x:any)=> x != null && x != "" );
      this.jobservice.getnewmap(searchStringArray.toString()).subscribe((res: any) => {
        this.spinner.hide();
        if(res && res?.features.length > 0){
          res.features = [res.features[0]];
          this.searchJobsMarker.setLngLat(res.features[0]?.geometry?.coordinates).addTo(this.map);
          if (this.map.getLayer('search-unclustered-point') != undefined) {
            this.map.removeLayer('search-unclustered-point');
          }
          this.map.addLayer({
            id: 'search-unclustered-point',
            type: 'circle',
            source: 'allJobs',
            filter: ['!', ['has', 'point_count']],
            paint: {
              'circle-color': '#b40219',
              'circle-radius': 4,
              'circle-stroke-width': 1,
              'circle-stroke-color': '#fff'
            },
            'layout': {
              'visibility': 'visible'
            },
          });
          this.fitBoundAddressSearch(res, 15);
        }else{
          this.toastr.error("No matching results found.", "", { timeOut: 3000 });
        }
      })
    }
    if (this.selectedSearchType != 6) {
      this.removeExtraPopup(".mapboxgl-popup-anchor-top");
      if (this.map.getLayer('search-unclustered-point') != undefined) {
        this.map.removeLayer('search-unclustered-point');
      }
      if (this.map.getSource('searchJobs') != undefined) {
        this.map.removeSource('searchJobs');
      }
      this.featureGroupMap.clear();
      let searchPayload = {
        "request": {
          "pageNumber": 1,
          "pageSize": this.selectedSearchType != 8 ? 0 : 1000
        },
        "searchCategoryID": this.selectedSearchType,
        //"searchValue": this.searchStringInput,
        "searchValue": this.selectedSearchType == 4 ? (this.latitudeSearhStringInput + ',' + this.longitudeSearhStringInput) : this.searchStringInput,
        "userId": this.loginUserId,
        "securableObjectTypeId": 1,
      }
      this.spinner.show();

      this.finJobsSubscription = this.jobservice.searchJobs(searchPayload).subscribe(async (response) => {
        this.searchJobList = response;
        this.searchJobIds = [];

        this.spinner.hide();
        if (this.searchJobList.totalRecord === 0 && this.selectedSearchType !==8) {
          /*  this.jobservice.getnewmap(this.searchStringInput).subscribe((res: any) => {
            debugger;
            this.storedata = res
          }) */
          this.toastr.error("No matching results found.");
        } else {
          this.searchedJobGeoJson = {
            "type": "FeatureCollection",
            "FeatureCollectionId": null,
            "Name": null,
            "features": []
          };
          const searchedJobData = this.searchJobList.allJobs;
          let searchedSiteData : any = [];
          if(searchedJobData.length === 0){
            this.toastr.error("No matching results found.");
            return
          }

          if(this.selectedSearchType == 10){
            let siteIds = searchedJobData.reduce((acc:number[], value:any)=>{
              if(value && value.siteId){
                acc.push(value.siteId);
              }
              return acc;
            },[]);
            searchedSiteData = await this.subSiteServices.getJobsBySiteId(siteIds, this.loginUserId);
            this.setSearchedJobData(searchedSiteData);
          }else{
            this.setSearchedJobData(searchedJobData);
          }

          // this.map.setZoom(3);
          this.siteServices.isJobsBySitesObservable.subscribe((item: any) => {
            if (item) {
              this.bindSearchDataOnMap(this.searchJobList.allJobs);
            }
          })
        }

      }, (err) => {
        console.log(err);
      });
    }

  }

  setSearchedJobData(searchedJobData:any){
    for (let i = 0; i < searchedJobData.length; i++) {
      this.searchJobIds.push(searchedJobData[i].jobId);
      //this.popUpOnJobSearch(this.jobList.allJobs[i]);
      // const coordinate = JSON.parse(searchedJobData[i].geometry);
      // const jobCoordinates = coordinate.coordinates;
      let jobCoordinates;
      if(searchedJobData[i].geometry){
        const coordinate = JSON.parse(searchedJobData[i].geometry);
        jobCoordinates = coordinate.coordinates;
      }else{
        jobCoordinates = [Number(searchedJobData[i].longitude), Number(searchedJobData[i].latitude)];
      }

      this.searchedJobGeoJson.features.push({
        "type": "Feature",
        "properties": {
          "jobId": searchedJobData[i].jobId
        },
        "attributes": null,
        "geometry": {
          "type": "Point",
          "coordinates": jobCoordinates
        }
      });
      // if ((this.searchJobList.totalRecord - 1) === i) {
      //   this.siteServices.setJobsBySitesBehavior(true);
      // }
    }
    this.siteServices.setJobsBySitesBehavior(true);
  }



  storedata: any = []

  bindSearchDataOnMap(searchedJobData: any) {
    if (this.map.getSource('searchJobs') === undefined) {
      this.map.addSource('searchJobs', {
        type: 'geojson',
        data: this.searchedJobGeoJson,
      });
    }
    if(this.map.getLayer('unclustered-point') != undefined) {
      this.map.setLayoutProperty('unclustered-point', 'visibility', 'none');
    }
    if(this.map.getLayer('sourceSiteLayer') != undefined) {
      this.map.setLayoutProperty('sourceSiteLayer', 'visibility', 'none');
    }
    if(this.map.getLayer('sourceDocumentLayer') != undefined) {
      this.map.setLayoutProperty('sourceDocumentLayer', 'visibility', 'none');
    }
    let visibility: any = 'visible';
    // if (this.map.getZoom() >= 13.25) {
    //   visibility = 'none'
    // }
    if (this.map.getLayer('search-unclustered-point') === undefined) {
      this.map.addLayer({
        id: 'search-unclustered-point',
        type: 'circle',
        source: 'searchJobs',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#b40219',
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
        layout: {
          visibility: visibility,
        },
      });
    } else {
      if (this.map.getLayer('search-unclustered-point') === undefined) {
        this.map.loadImage(
          `../../../../../../assets/images/GPRS_PNG/MAP_JOB.png`,
          (error, image: any) => {
            let iconExits = this.map.hasImage('job_icon');
            if(iconExits) return;
            this.map.addImage('job_icon', image);
            if (error) {
              console.log('already exist' + error);
            }
          }
        );
        this.map.addLayer({
          id: 'search-unclustered-point',
          type: 'symbol',
          source: 'searchJobs',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-image': 'job_icon', // reference the image
            'icon-size': .5,
            visibility: visibility,
          }
        });
      }
    }
    if (this.searchJobList.totalRecord === 1) {
      this.popUpOnJobSearch(this.searchJobList.allJobs[0]);
    }
    if(this.previousStyle === this.currentStyle) {
      this.fitBound(this.searchedJobGeoJson);
    }
    this.isSearchAppliedOnJobData = true;
    this.mapClickInputFunctionAfterSearch(searchedJobData[0]);
    this.mouseEnterOnMapAfterSearch();
    this.mouseLeaveOnMapAfterSearch();
    this.siteServices.setJobsBySitesBehavior(false);
    this.spinner.hide();

    //For visbile site map
    if (this.selectedSearchType == 10) {
      let temp: any = { type: 'FeatureCollection', features: [] };
      for (let i = 0; i < searchedJobData.length; i++) {
        const coordinate = JSON.parse(searchedJobData[i].geometry);
        const jobCoordinates = coordinate.coordinates;
        temp.features.push({
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': jobCoordinates
          }
        })
      }
      this.map.loadImage(
        `../../../../../../assets/images/GPRS_PNG/MAP_SITE.png`,
        (error, image: any) => {
          let iconExits = this.map.hasImage('site_icon');
          if(iconExits) return;
          this.map.addImage('site_icon', image);
          if (error) {
            console.log('already exist' + error);
          }
        }
      );
      if (this.map.getLayer('searchSiteImageLayer') != undefined) {
        this.map.removeLayer('searchSiteImageLayer');
      }
      if (this.map.getSource('searchSiteImage') != undefined) {
        this.map.removeSource('searchSiteImage');
      }
      if (this.map.getSource('searchSiteImage') === undefined) {
        this.map.addSource(`searchSiteImage`, {
          type: 'geojson',
          data: temp
        });
      }
      if (this.map.getLayer('searchSiteImageLayer') === undefined) {
        this.map.addLayer({
          id: `searchSiteImageLayer`,
          type: 'symbol',
          source: `searchSiteImage`,
          maxzoom: 13.25,
          layout: {
            'icon-image': 'site_icon', // reference the image
            'icon-size': 1,
          }
        });
      }
    }else{
      if (this.map.getLayer('searchSiteImageLayer') != undefined) {
        this.map.removeLayer('searchSiteImageLayer');
      }
      if (this.map.getSource('searchSiteImage') != undefined) {
        this.map.removeSource('searchSiteImage');
      }
    }
  }

  private _resetSiteSubsiteToggle() {
    console.log("_resetSiteSubsiteToggle...!");
    // While drag the map showing all site, subsite, jobs, features
    let filteredSiteSubsiteData = this.map.getStyle().layers.filter((data: any)=> data.id.includes('-site-') || data.id.includes('-subsite-'));
    filteredSiteSubsiteData.forEach((data: any) => {
      // this.map.setLayoutProperty(data.id, 'visibility', 'visible');
      this.map.setLayoutProperty(data.id, 'visibility', 'visible');
      this.map.setFilter(data.id, true);
    })
    // this.map.setLayoutProperty('unclustered-point', 'visibility', 'visible');
    this.map.setFilter('unclustered-point', null);

    // clear the filter of external layer
    for (let layerType of ['photo', 'matterport', 'pointcloud', 'virtualtour', 'attachment', 'externallink']) {
      this.map.setFilter(`4-${layerType}`,  null)
    }
    // clear the filter of GPRS, Client and Imported Layers
    for (let id of [1, 2, 3]) {
      this.map.setFilter(`${id}-point`,  null);
      this.map.setFilter(`${id}-line`,  null);
    }
  }

  registerDragEventInMap() {
    this.map.on('dragend', (e:any) => {
      if (this.map.getZoom() < this.userRenderScale) {
        this.resetMapsData();
      }
      else {
        if (this.map.getZoom() > this.userRenderScale && !this.isEditingModeIsOn) {
          this.dragMap = true;
          // this.slideMenuOpenLayerDetails = false;
          // this.slideMenuOpenFolder = false;
          this.zoomMap = false;
          if(this.isToggleApplied){
            this._resetSiteSubsiteToggle();
          }
          setTimeout(()=>{
            this.splitZoomOnMap(e);
          }, 500)
        }
      }
    });
  }

  resetMapsData() {
    this.featureGroupMap.clear();
    this.featureGroupToggleStateMap.clear();
    this.featureGroupTypeToggleStateMap.clear();
    this.featureGroupTypeFeatureNameToggleStateMap.clear();
    this.commonMapService.featureGroupMap.clear();
    this.commonMapService.featureGroupToggleStateMap.clear();
    this.commonMapService.featureGroupTypeToggleStateMap.clear();
    this.commonMapService.featureGroupTypeFeatureNameToggleStateMap.clear();
  }

  //# close share modal
  closeShareObjModal() {
    this.accessFormControl.patchValue('1');
    //this.objSharedUserList = [];
    this.emailFormControl.setValue('');
  }

  //#select email from fetched email list
  selectEmailFromFetchedEmails(obj: any) {
    this.emailFormControl.setValue(obj);
    this.shouldFetchedEmailsBeDisplayed = false;
  }

  //# Share jobs to concerned person with view/edit access
  shareObj(editSelected?:any, isRadioClicked=false) {
    if (!this.editCheck && this.accessFormControl.value === '2') {
      this.permissionService.showErrormessageForPersonal();
      return;
    }
    if (this.emailFormControl.value == "") {
      this.toastr.error("Please enter email address");
      return;
    }

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(this.emailFormControl.value) == false) {
      this.toastr.error("Please enter a valid Email Address.");
      return;
    }
    this.spinner.show();
    this.dashboardservice
      .fetchEditEmails(this.emailFormControl.value)
      .subscribe((data) => {
        this.spinner.hide();
        this.tierIDData = data;
        let userTierID = '';
        let loginUserRole = '';
        if (this.tierIDData.length > 0) {
          userTierID = this.tierIDData[0].tierId;
          loginUserRole = this.tierIDData[0].roleName;
        }
        if (
          this.tierIDData.length === 0 &&
          this.accessFormControl.value == '2'
        ) {
          userTierID = '';
          this.toastr.error(
            'Free account User does not have access to edit the content'
          );
          return;
        }

        if (loginUserRole != 'Project Manager' && loginUserRole != 'Admin') {
          if (this.accessFormControl.value == '2' && userTierID == '1') {
            this.toastr.error(
              'Free account User does not have access to edit the content'
            );
            return;
          }

        }

        if(isRadioClicked){
          return;
        }

        if (!this.isJobOwner) {
          // not owner for job
          this.toastr.error(
            'You must be the owner of the job in order to share content'
          );
        } else {
          let tierId=sessionStorage.getItem('tierId');
          let role=sessionStorage.getItem('loginUserRole');
          if(role=='Client' && tierId=='1'&& this.emailFormControl.value == '2' ){
            this.toastr.error('You are not authorized to share job.');
            return;
          }
          if (this.emailFormControl.value == '') {
            this.toastr.error('Please enter email address');
          } else {
            if (
              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(
                this.emailFormControl.value
              )
            ) {
              this.spinner.show();
              let payLoad = {
                sharedWithEmailId: this.emailFormControl.value,
                sharedByUserId: this.loginUserId,
                objectId: this.clickedJobItemId,
                objectTypeId: 1, // 1 for job
                securableObjectPermissionTypeId: parseInt(
                  this.accessFormControl.value
                ),
              };

              this.dashboardSubscribe = this.dashboardservice
                .shareObject(payLoad)
                .subscribe((response) => {
                  this.sharedObjResponse = response;
                  this.spinner.hide();
                  this.emailFormControl.reset();
                  this.accessFormControl.patchValue('1');
                  this.emailFormControl.patchValue('');
                  if (
                    this.sharedObjResponse.processingStatus.status === 'Failure'
                  ) {
                    this.toastr.error(
                      "The job has already been shared with same access with user"
                    );
                  } else {
                    this.toastr.success('Job shared successfully');
                  }
                });
            } else {
              this.toastr.error('Please enter a valid Email Address.');
            }
          }
        }
      });
  }

  //# Multiple jobs share to  person with view/edit access
  multipleShareJobObj() {
    let tierId = sessionStorage.getItem('tierId');
    let loginUserRole = sessionStorage.getItem('loginUserRole');

    if (loginUserRole != 'Project Manager' && loginUserRole != 'Admin') {
      if (tierId === '1' && this.accessFormControl.value === '2') {
        this.toastr.error(
          'Free account User does not have access to edit the content'
        );
        return;
      }
    }
    // if (loginUserRole == 'Project Manager' || loginUserRole == 'Admin') {
    //   if (tierId === '1' && this.accessFormControl.value === '2') {
    //     this.toastr.error(
    //       'Free account User does not have access to edit the content'
    //     );
    //     return;
    //   }
    // }

    this.dashboardservice
      .fetchEditEmails(this.emailFormControl.value)
      .subscribe((data) => {
        this.spinner.hide();
        this.tierIDData = data;
        let userTierID = '';
        let userRole = '';
        if (this.tierIDData.length > 0) {
          userTierID = this.tierIDData[0].tierId;
          userRole = this.tierIDData[0].roleName;
        }
        if (
          userTierID == '1' &&
          this.accessFormControl.value == '2'
        ) {
          userTierID = '';
          this.toastr.error(
            'Free account User does not have access to edit the content'
          );
          return;
        }


    if (this.emailFormControl.value == '') {
      this.toastr.error('Please enter email address');
    } else {
      if (this.multipleJobsIdByPoligonDraw.length > 0) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(this.emailFormControl.value)) {
          var emailIdArr: any = [];
          emailIdArr.push(this.emailFormControl.value);
          this.spinner.show();
          let payLoad = {
            sharedWithEmailIds: emailIdArr,
            // "sharedWithEmailIds": [
            //   1
            // ],
            sharedByUserId: this.loginUserId,
            objectIds: this.multipleJobsIdByPoligonDraw,
            objectTypeId: 1,
            securableObjectPermissionTypeId: parseInt(
              this.accessFormControl.value
            ),
          };

          this.mapViewerSubscribe = this.mapViewerService
            .multipleJobShare(payLoad)
            .subscribe((response) => {
              this.sharedObjResponse = response;
              this.spinner.hide();
              this.emailFormControl.reset();
              if (
                this.sharedObjResponse.processingStatus.status === 'Failure'
              ) {
                this.behaviourSubjectService.multipleShareJobHeaderIcon('');
                if(this.sharedObjResponse.processingStatus.message){
                  this.toastr.error(this.sharedObjResponse.processingStatus.message);
                }
                else{
                this.toastr.error(
                  'The job has already been shared with same access with User'
                );
                }
              } else {
                this.toastr.success('Job shared successfully');
                this.behaviourSubjectService.multipleShareJobHeaderIcon('');
              }
              // this.multipleJobsIdByPoligonDraw = [];
            });
        } else {
          this.toastr.error('Please enter a valid Email Address.');
        }
      } else {
        this.toastr.error('No job is selected!');
      }
    }
  });
  }

  //# pop job on search jobs by global search
  popUpOnJobSearch(e: any) {
    const coordinates: any[2] = [e.longitude, e.latitude];
    this.flyMap(coordinates[0], coordinates[1]); // fly map to lat long
    this.clickedJobItemId = e.jobId;
    this.clickedJobItemCoordinates = coordinates;
    this.spinner.show();
    if (e.jobId > 0) {
      this.mapViewerSubscribe = this.mapViewerService.fetchJobDetails(e.jobId, this.loginUserId).subscribe((jobDetailData: any) => {
        this.spinner.hide();
        // const jobName = jobDetailData.data?.jobName;
        // const jobName = jobDetailData?.data?.groups?.features[0]?.attributes?.workorderNumber + " - " + jobDetailData?.data?.groups?.features[0]?.attributes?.customerName;
        const jobName = jobDetailData?.features[0]?.attributes?.workorderNumber + " - " + jobDetailData.features[0]?.attributes?.customerName;
        this.clickedJobName = jobName;
        // const siteContact = jobDetailData.data?.groups?.features[0]?.attributes?.siteContact;
        // const email = jobDetailData.data?.groups?.features[0]?.attributes?.email;
        const siteContact = jobDetailData?.features[0]?.attributes?.siteContact;
        const email = jobDetailData?.features[0]?.attributes?.email;
        const netsuiteJobId = jobDetailData?.features[0]?.attributes.netsuiteJobId || '';
        const netsuitePoNumber = jobDetailData?.features[0]?.attributes.netsuitePoNumber || '';

        // this.isJobOwner = jobDetailData.data?.groups?.features[0]?.attributes?.isShared;
        this.isJobOwner = jobDetailData?.features[0]?.attributes?.isAssigned;
        if (!this.isJobOwner) {
          // not assinged (not owner)
          this.isJobOwner = false;
        } else {
          this.isJobOwner = true;
        }
        if (this.loginUserRole == 'Admin' || 'Project Manager') {
          this.isJobOwner = true;
        }

          const description = `
      <div class="custom-map-header">
        <h2>${jobName}</h2>
      </div>
      <div class="custom-map-tooltip">
        <div class="table-responsive">
          <table class="table table-bordered table-striped custom-table">
            <tr>
              <td>Location</td>
              <td>${coordinates[1]},${coordinates[0]}</td>
            </tr>
            <tr>
              <td>Site Contact</td>
              <td>${siteContact}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>${email}</td>
            </tr>
            <tr>
              <td>Job ID</td>
              <td>${netsuiteJobId}</td>
            </tr>
            <tr>
              <td>PO Number</td>
              <td>${netsuitePoNumber}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="custom-map-footer">
        <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
        <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
      </div>
    `;
          // new mapboxgl.Marker({
          //   color: "#b40219"
          //   }).setLngLat(coordinates)
          //   .addTo(this.map);

          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(description)
            .addTo(this.map);
        });
    }
  }

  //# fly map to particular coordinates
  flyMap(long: any, lat: any) {
    this.map.flyTo({
      center: [long, lat],
    });
  }

  //# fly map to particular coordinates
  flyMaptoCurrentLocation(long: any, lat: any) {
    this.map.flyTo({
      center: [long, lat],
      zoom: 15,
    });
    const marker1 = new mapboxgl.Marker()
    .setLngLat([long, lat])
      .addTo(this.map);;
  }

  measureArea() {
    this.draw.changeMode('draw_polygon');
  }

  measureLength() {
    this.draw.changeMode('draw_line_string');
  }

  layerVisible(group: any, index: any) {
    let element = document.getElementsByClassName(
      'group-' + group
    ) as HTMLCollection;
    if (element.length > 0) {
      for (let i = 0; i < element[0].classList.length; i++) {
        if (element[0].classList[i] === 'fa-eye') {
          element[0]?.classList?.remove('fa-eye');
          element[0]?.classList?.add('fa-eye-slash');
          break;
        } else if (element[0].classList[i] === 'fa-eye-slash') {
          element[0]?.classList?.remove('fa-eye-slash');
          element[0]?.classList?.add('fa-eye');
          break;
        }
      }
    }
  }

  eyeLashNotVisible(
    mainEleClass: any,
    removeClass?: string,
    addClass?: string
  ) {
    removeClass = removeClass ? removeClass : 'fa-eye-slash';
    addClass = addClass ? addClass : 'fa-eye';
    let element = document.getElementsByClassName(
      'group-' + mainEleClass
    ) as HTMLCollection;
    if (element.length > 0) {
      for (let i = 0; i < element[0].classList.length; i++) {
        if (element[0].classList[i] === removeClass) {
          element[0]?.classList?.remove(removeClass);
          element[0]?.classList?.add(addClass);
          break;
        }
      }
    }
  }

  gprsChangeCSSOnEveryChildClick() {
    let ele = document.querySelectorAll('.inner-group');
    let gprsLayer = document.getElementById('mainGPRSLayer');
    let count = 0;
    if (ele?.length > 0) {
      for (let j = 0; j < ele?.length; j++) {
        const classList = Array.from(ele[j].classList);
        if (classList.find((it: any) => it === 'fa-eye-slash')) {
          count += 1;
        }
      }
      if (ele?.length === count) {
        gprsLayer?.classList.remove('fa-eye');
        gprsLayer?.classList.add('fa-eye-slash');
      } else {
        gprsLayer?.classList.remove('fa-eye-slash');
        gprsLayer?.classList.add('fa-eye');
      }
    }
  }

  handleFeatureTypeClick(
    layer: string,
    featureGroup: string,
    featureType: string
  ) {
    if (!this.isFeatureTypeClicked.hasOwnProperty(layer)) {
      this.isFeatureTypeClicked[layer] = {};
    }
    if (!this.isFeatureTypeClicked[layer].hasOwnProperty(featureGroup)) {
      this.isFeatureTypeClicked[layer][featureGroup] = {};
    }
    // Need to toggle this true/false, maybe based on the button class "collapsed"
    this.featureTypeload(layer, featureGroup, featureType);
  }

  featureTypeload(layer: any, featureGroup: any, featureType: any) {
    let featureTypeBtn = document.querySelector(
      `.feature-group-container.${featureGroup} .feature-type-container._${this.removeAllSpaceAndSpecialChar(
        featureType
      )} button`
    );

    if (featureTypeBtn?.classList.contains('collapsed')) {
      // Only remove DOM if no features are hidden
      let anyHidden = false;
      let featuresInThisType = document.querySelectorAll(
        `.feature-group-container.${featureGroup} .feature-type-container._${this.removeAllSpaceAndSpecialChar(
          featureType
        )} .feature-name`
      );
      featuresInThisType.forEach((nodeList) => {
        if (nodeList.classList.contains('fa-eye-slash')) {
          anyHidden = true;
          return;
        }
      });
      if (anyHidden) {
        this.isFeatureTypeClicked[layer][featureGroup][featureType] = true;
      } else {
        this.isFeatureTypeClicked[layer][featureGroup][featureType] = false;
      }
    } else {
      this.isFeatureTypeClicked[layer][featureGroup][featureType] = true;
    }
  }

  removeAllSpaceAndSpecialChar(val: any) {
    const noSpecialChars = val.replace(/[^a-zA-Z0-9_-]/g, '');
    return noSpecialChars.replaceAll(' ', '');
  }

  // Function to return the "featuretype" part of a filter expression
  // Implies featuregroup expression as well
  getTypeFilterExpression(
    featureGroup: string,
    featureType: string,
    layer: any
  ) {
    let featureGroupFeatureTypeMap = this.featureGroupTypeToggleStateMap
      .get(layer)
      .get(featureGroup);
    let visibleFeatueTypeArrayForGivenGroup: string[] = [];
    featureGroupFeatureTypeMap.forEach((value: boolean, key: string) => {
      if (value) {
        visibleFeatueTypeArrayForGivenGroup.push(key);
      }
    });
    let expression = [
      'in',
      ['get', 'featureType'],
      ['literal', visibleFeatueTypeArrayForGivenGroup],
    ];
    return expression;
  }

  // Function to return the "featureid" part of a filter expression
  // Implies featuregroup and/or featuretype expression(s) as well
  getFeatureFilterExpression(
    featureGroup: string,
    featureType: any,
    layer: any
  ) {
    let featureGroupFeatureTypeMap =
      this.featureGroupTypeFeatureNameToggleStateMap.get(featureGroup);
    // let featureTypeFeatureNameMap = featureGroupFeatureTypeMap.get(featureType);
    let visibleFeatueNameArray: string[] = [];
    featureGroupFeatureTypeMap.forEach((val: boolean, keyForType: string) => {
      let featureTypeFeatureNameMap =
        featureGroupFeatureTypeMap.get(keyForType);
      featureTypeFeatureNameMap.forEach((value: boolean, key: string) => {
        if (value) {
          visibleFeatueNameArray.push(key);
        }
      });
    });
    let expression = [
      'in',
      ['get', 'feature'],
      ['literal', visibleFeatueNameArray],
    ];
    return expression;
  }

  mainLayerChangeCss(className: any, updatedClassName: any) {
    let ele = document.querySelectorAll('.' + className);
    if (ele?.length > 0) {
      ele.forEach((it: any) => {
        it?.classList?.remove(className);
        it?.classList?.add(updatedClassName);
      });
    }
  }

  getLayerID(layer: any) {
    let layerID = null;
    if (layer === 'GPRS') {
      layerID = 1;
    } else if (layer === 'CLIENT') {
      layerID = 2;
    } else if (layer === 'IMPORTED') {
      layerID = 3;
    } else if (layer === 'EXTERNAL') {
      layerID = 4;
    } else if (layer === 'SITE') {
      layerID = 5;
    }
    return layerID;
  }

  // Handle featuregroup filter - turn off/on layers with Feature Group
  setGroupFilter(state: string, featureGroup: string) {
    for (let geomType of ['point', 'line']) {
      let layerId = `${featureGroup}-${geomType}`;
      if (this.map.getLayer(layerId) === undefined) {
        break;
      }
      if (state === 'off') {
        this.map.setFilter(layerId, false);
      } else {
        this.map.setFilter(
          layerId,
          this.getGroupFilterExpression(featureGroup)
        );
      }
    }
  }

  //# open modal for share jobs
  openModalforMultipleShareJob() {
    let button = document.getElementById(
      'btnModalMultiShare'
    ) as HTMLButtonElement;
    button.click();
  }

  ngAfterViewInit(): void {
    if (
      !sessionStorage.getItem('loginCheck') &&
      sessionStorage.getItem('profileIncomplete') &&
      sessionStorage.getItem('profileIncomplete') === 'true'
    ) {
      sessionStorage.removeItem('profileIncomplete');
      let button = document.getElementById(
        'btnModalProfile1'
      ) as HTMLButtonElement;
      button.click();
    }
    let print: any = document.querySelector(
      '.mapboxgl-export-control'
    ) as HTMLButtonElement;
    print?.setAttribute('title', 'Print');

    setTimeout(() => {
      this.commonMapService.mapToolTitleChange();
    }, 3000);
  }

  // Navigation to present location Error
  showError(error: any) {
    let self = this;
    switch (error.code) {
      case error.PERMISSION_DENIED:
        self.toastr.error('User denied the request for Geolocation.');
        break;
      case error.POSITION_UNAVAILABLE:
        self.toastr.error('Location information is unavailable.');
        break;
      case error.TIMEOUT:
        self.toastr.error('The request to get user location timed out.');
        break;
      case error.UNKNOWN_ERROR:
        self.toastr.error('An unknown error occurred.');
        break;
    }
  }

  // Navigation to present location Coordinates
  storeCoordinates(position: any) {
    let self = this;

    this.commonMapService.flyMaptoCurrentLocation(this.map,this.renderer,position.coords.longitude, position.coords.latitude,'markerCurrentLocation');

  }

  // Navigation to present location
  navigateToCurrentLocation() {
    let self = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        self.storeCoordinates.bind(self),
        self.showError.bind(this),
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
      );

      //   navigator.geolocation.getCurrentPosition(function (position) {
      //     // Long 77.3643512 Lat 28.6834382 Delhi
      //     // Long 72.8776559 Lat 19.0759837 Mumbai
      //     self.flyMaptoCurrentLocation(position.coords.longitude, position.coords.latitude)
      //   },function (error) {
      //     //Your error handling here
      //     switch(error.code) {
      //       case error.PERMISSION_DENIED:
      //         self.toastr.error("User denied the request for Geolocation.");
      //           break;
      //       case error.POSITION_UNAVAILABLE:
      //         self.toastr.error("Location information is unavailable.");
      //           break;
      //       case error.TIMEOUT:
      //         self.toastr.error("The request to get user location timed out.");
      //           break;
      //       // case error.UNKNOWN_ERROR:
      //       //   self.toastr.error("An unknown error occurred.");
      //       //     break;
      //   }
      // },{ timeout:5000, enableHighAccuracy:true});
      // maximumAge:60000,
      // },self.showError,{maximumAge:60000, timeout:5000, enableHighAccuracy:true});
    } else {
      this.toastr.error('Location is disabled.');
    }
  }

  //#region isEnableMeasurmentTools check Measurment Tools is enable or disable as per set my prefrences
  isEnableMeasurmentTools() {
    // Comment for Bug 3712
    // if (this.IsMeasurmentToolActive === 'TRUE') {
    //   this.slideMenuOpenTools = !this.slideMenuOpenTools;
    // }
    // else {
    //   let div = document.getElementById("divMeasurment") as HTMLDivElement;
    //   div.setAttribute('data-bs-toggle', "modal");
    //   div.setAttribute("data-bs-target", "#MeasurementsModal");
    //   div.click();
    // }
  }
  //#endregion

  //# generate export link
  generateExportLink() {
    this.polygonGeoJson = {
      type: 'Polygon',
      coordinates: this.exportDrawPolygonCordinates,
    };
    this.polygonGeoJson = JSON.stringify(this.polygonGeoJson);
    if (
      this.exportDrawPolygonCordinates == undefined ||
      this.exportDrawPolygonCordinates.length == 0
    ) {
      // this.polygonGeoJson = 'string';
      this.toastr.error('Please draw a polygon before export');
      return;
    } else {
      // mapjobid = 0;
    }

    let payLoad = {
      submittedByUserId: this.loginUserId,
      mapJobId: 0,
      polygonGeoJson: this.polygonGeoJson,
      fileFormatTypeId: this.exportForm.value.filetype,
      exportFileName: this.exportForm.value.filename,
    };
    this.toastr.success('Request for export is submitted ');
    this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
      .generateExportLink(payLoad)
      .subscribe((response) => {
        const linkaddedResponse: any = response;
        this.spinner.hide();
        if (linkaddedResponse.processingStatus.status === 'Success') {
          sessionStorage.setItem(
            'exportJobId',
            linkaddedResponse.importExportJobId
          );
          setTimeout(() => {
            this.toastr.clear();
            this.toastr.success(
              'The export process has started! Link for download will be available once complete.'
            );
          }, 3000);
        } else {
          this.toastr.error('Error in export');
        }
      });
  }

  measeureLength(length: any, latLong: any) {
    if (length >= 5280) {
      setTimeout(() => {
        new mapboxgl.Popup()
          .setLngLat(latLong)
          .setHTML(
            `<strong>Length of drawn string is : ${(length / 5280).toFixed(
              2
            )} mi.</strong>`
          )
          .addTo(this.map);
      }, 0);
    } else {
      setTimeout(() => {
        new mapboxgl.Popup()
          .setLngLat(latLong)
          .setHTML(
            `<strong>Length of drawn string is : ${length} feet</strong>`
          )
          .addTo(this.map);
      }, 0);
    }
  }

  measureCalcArea(calcArea: any, lantLong: any) {
    if (calcArea >= 43560) {
      setTimeout(() => {
        new mapboxgl.Popup()
          .setLngLat(lantLong)
          .setHTML(
            `<strong>Area of drawn polygon is : ${(calcArea / 43560).toFixed(
              4
            )} acres.</strong>`
          )
          .addTo(this.map);
      }, 0);
    } else {
      setTimeout(() => {
        new mapboxgl.Popup()
          .setLngLat(lantLong)
          .setHTML(
            `<strong>Area of drawn polygon is : ${calcArea} sq. feet</strong>`
          )
          .addTo(this.map);
      }, 0);
    }
  }

  checkAnnotationLine(feature: any) {
    if (feature.featureClass && feature.featureClass.includes('annotation')) {
      return 'GetAnnotationLineFeatureById';
    } else {
      return 'RetrieveLineDetailsByLineId';
    }
  }

  checkAnnotationPoint(feature: any) {
    if (feature.featureClass && feature.featureClass.includes('annotation')) {
      return 'GetAnnotationPointFeatureById';
    } else {
      return 'RetrievePointDetailsByPointId';
    }
  }


  btnClickdownloadMyFile(event: any) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    if (event.data.fileLink.includes('swmaps')) {
      link.setAttribute(
        'href',
        event.data.fileLink + this.dprFileSWAPSDownloadSAS
      );
    } else {
      link.setAttribute('href', event.data.fileLink + this.dprFileDownloadSAS);
    }
    link.setAttribute('download', event.data.name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  /**
   * Add site start
   */

  /** For popualte country */
  getCountryList() {
    // this.spinner.show();
    // this.country = [];
    // this.apiService.getCountryList(this.loginUserId).subscribe(
    //   (response: any) => {
    //     this.country = response;
    //     if (this.editSiteForm) {
    //       let countryData = this.country.filter(
    //         (item: any) => item.name == this.editSiteData.country
    //       );
    //       this.siteAddForm.patchValue({
    //         country: countryData[0].name,
    //       });
    //       this.populateState(countryData[0].name);
    //     }
    //     this.spinner.hide();
    //   },
    //   (err) => {
    //     this.spinner.hide();
    //   }
    // );
  }

  /** For popualte city */
  populateCity(value: any) {
    // this.spinner.show();
    // this.dropdownCity = [];
    // let stateData = this.dropdownState.filter(
    //   (item: any) => item.name == value
    // );
    // this.apiService
    //   .getCityList(this.loginUserId, stateData[0].stateId)
    //   .subscribe(
    //     (response: any) => {
    //       this.dropdownCity = response;
    //       if (this.editSiteForm) {
    //         this.siteAddForm.patchValue({
    //           city: this.editSiteData.city,
    //         });
    //       }
    //       this.spinner.hide();
    //     },
    //     (err) => {
    //       this.spinner.hide();
    //     }
    //   );
  }

  /** For popualte state */
  populateState(value: any) {
    // this.spinner.show();
    // this.dropdownState = [];
    // let countryData = this.country.filter((item: any) => item.name == value);
    // this.apiService
    //   .getStateList(this.loginUserId, countryData[0].countryId)
    //   .subscribe(
    //     (response: any) => {
    //       this.dropdownState = response;
    //       if (this.editSiteForm) {
    //         let stateData = this.dropdownState.filter(
    //           (item: any) => item.name == this.editSiteData.state
    //         );
    //         this.siteAddForm.patchValue({
    //           state: stateData[0].name,
    //         });
    //         this.populateCity(stateData[0].name);
    //       }
    //       this.spinner.hide();
    //     },
    //     (err) => {
    //       this.spinner.hide();
    //     }
    //   );
  }

  adminGetSiteList(event:any) {
    console.log("adminGetSiteList: ......");
    this.getSiteList(event.status, event.userId, true);
    this.getSubSiteList(event.status, event.userId, true);
  }

    /** For popualte site list start */
    getSubSiteList(status: boolean = false, loginUserId?:any, isFromAdmin?:boolean) {
      // this.spinner.show();
      if (this.siteData?.length === 0 || isFromAdmin) {

        this.siteData = [];
        let userId = loginUserId ? loginUserId : sessionStorage.getItem('loginUserId');
        let dataPost = this.allZoomedJobs;
        this.apiService.getSubSiteList(userId, dataPost).subscribe(
          (response: any) => {
            if (response?.length) {

              this.spinner.hide();
              this.drawPolygonOnMap(
                response,
                'orange',
                'subSiteName',
                'subSiteId',
                'subsite',
                'description',
                'address',
                'city',
                'state',
                'country'
              );

              if (status) {
                // this.reloadCurrentRoute();
                this.addLayerInMap();
              }

            }
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
          }
        );
      }
    }
    /** For popualte site list end */

  /** For popualte site list start */
  getSiteList(status: boolean = false, loginUserId?:any, isFromAdmin?:boolean) {
    // this.spinner.show();
    if (this.siteData?.length === 0 || isFromAdmin) {

      this.siteData = [];
      let userId = loginUserId ? loginUserId : sessionStorage.getItem('loginUserId');
      let dataPost = this.allZoomedJobs;
      this.apiService.getSiteList(userId, dataPost).subscribe(
        (response: any) => {
          if (response?.length) {

            this.siteData = response;
            this.spinner.hide();
            this.drawPolygonOnMap(
              response,
              'red',
              'siteName',
              'siteId',
              'site',
              'description',
              'address',
              'city',
              'state',
              'country'
            );
            if (status) {
              // this.reloadCurrentRoute();
              this.addLayerInMap();
            }
            this.siteData.forEach((site: any) => {
              this.getSubSiteBySiteId(site.siteId);
            });
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
    }
  }
  /** For popualte site list end */

  onDrawPolygonOnMap(obj: any) {
    const { data, color, key, idKey, Type, desc, address, city, state, country, } = obj;
    this.drawPolygonOnMap(data, color, key, idKey, Type, desc, address, city, state, country);
  }

  /**
   * Polygon draw start
   */
  drawPolygonOnMap(data: any, color: any, key: any, idKey: any, Type: any, desc: any, address: any, city: any, state: any, country: any) {
    /**Load polygon on map start*/
    let siteData = data;
    siteData?.forEach((site: any, index: any) => {
      let geom = site.geom;
      let siteInfo;
      if (site[key]) {
        siteInfo =
          this.removeAllSpaceAndSpecialChar(site[key]) +
          '-' + Type.toLowerCase() + '-' + site[idKey];
      } else {
        siteInfo = 'site-layer' + index;
      }
      if (geom !== null) {
        this.featureData = {
          type: 'Feature',
          geometry: JSON.parse(geom),
          properties: {
            siteName: siteInfo,
            id: site[idKey],
            name: Type,
            description: site[desc],
            address: site[address],
            city: site[city],
            state: site[state],
            country: site[country],
            siteSubsiteName: site[key],
          },
        };
        if (this.map.getSource(siteInfo) == undefined) {
          this.map.addSource(siteInfo, {
            type: 'geojson',
            data: this.featureData,
          });
        }
        if (this.map.getLayer(siteInfo) == undefined) {
          this.map.addLayer({
            id: siteInfo,
            type: 'line',
            source: siteInfo,
            minzoom: 13.25,
            maxzoom: 23,
            paint: {
              'line-color': color,
              'line-opacity': 1,
              'line-width': 4,
            },
          });
        }
        this.map.off('mouseenter', siteInfo, this.handleLayerMouseEnter);
        this.map.on('mouseenter', siteInfo, this.handleLayerMouseEnter);
        this.map.off('mouseout', siteInfo, this.handleLayerMouseOut);
        this.map.on('mouseout', siteInfo, this.handleLayerMouseOut);
        this.map.off('click', siteInfo, this.handleLayerPolygonClick);
        this.map.on('click', siteInfo, this.handleLayerPolygonClick);
        // this.getSubSiteBySiteId(site.siteId);
      }
      /**Load polygon on map end*/
    });
  }

  // Mouse click
  handleLayerPolygonClick = (e: any) => {
    this.map.doubleClickZoom.disable();
    let gprsLayerIds = this.map
      .getStyle()
      .layers.filter((layer) => layer.id)
      .map((layer) => layer.id);
    // Set `bbox` as ?px reactangle area around clicked point.
    const bboxPadding = 3;
    const bbox: any = [
      [e.point.x - bboxPadding, e.point.y - bboxPadding],
      [e.point.x + bboxPadding, e.point.y + bboxPadding],
    ];
    // Find features intersecting the bounding box.
    const clickedFeatures: any = this.map.queryRenderedFeatures(bbox, {
      layers: [...gprsLayerIds],
    });
    if (clickedFeatures.length > 0 && !this.isSiteEditingModeIsOn) {
      let clickedFeature = clickedFeatures[0];
      // this.siteOrSubSiteDetailsByClick = clickedFeature.properties;
      this.clickSiteOrSubSiteID = clickedFeature.properties.id;
      let sitedataa = this.siteData.find(
        (it: any) => it.siteId == this.clickSiteOrSubSiteID
      );
      if (clickedFeature.properties.name == 'site') {
        this.siteServices.getJobsBySiteId(this.clickSiteOrSubSiteID, sitedataa, this.loginUserId);
        this.viewSiteOrSubSiteStatus = 'site';
        this.siteOrSubSiteDetailsByClick = sitedataa;
      } else {
        this.viewSiteOrSubSiteStatus = 'subsite';
      }

      setTimeout(() => {
        this.openModalforSiteSubSiteDetails();
      }, 1000);
    } else if (clickedFeatures.length > 0 && this.isSiteEditingModeIsOn) {
      if (this.siteEditId === clickedFeatures[0].properties.id) {
        // if(!this.previousClickPolygon || (clickedFeatures[0].properties.name === this.previousClickPolygon[0].properties.name && clickedFeatures[0].properties.id === this.previousClickPolygon[0].properties.id)) {
        //   this.previousClickPolygon = clickedFeatures;
        this.mapEditService.handleEditingforPointorLine(
          clickedFeatures,
          this.draw,
          this.map
        );
        // }
      } else {
        this.toastr.error(
          clickedFeatures[0].properties.name.toUpperCase() +
            ' not match which you select from toggle and map, please select right site for edit.'
        );
      }
    }
  };
  /**
   * Polygon draw end
   */

  /** form create add site in Map  start*/

  /**for add site in Map */

  addSiteSubmit() {
    let data = this.siteData.filter((item: any) => item.siteId === undefined);
    let PolygonTool = document.querySelector(
      '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon'
    ) as HTMLDivElement;
    let subSite:any;
    this.siteData.forEach((element:any) => {
      if(element.subSiteData && element.subSiteData?.length > 0) {
        if(!subSite) {
          subSite = element.subSiteData.filter((item: any) => item.siteId === undefined);
        }
      }
    });
    if(subSite?.length) {
      this.toastr.error("please add/cancel subsite first");
    } else {
      if (data.length == 0) {
        if (this.siteAddForm.value.siteName) {
          this.siteData.push(this.siteAddForm.value);
          this.newlyAddedSiteValue = this.siteAddForm.value;
          this.toastr.success('Site added successfully in Site Properties');
          PolygonTool.style.border = '2px solid #ff0000';
        } else {
          this.toastr.error('Please enter siteName');
        }
      } else {
        this.toastr.error('Please add/draw selected site from Site Properties.');
      }
    }
  }

  newlyAddedSubSiteEmitter(event:any) {
    this.newlyAddedSubSiteValue = null;
    this.subSiteModalData = event.subSiteModalData;
  }

  /**
   * For edit site start
   */

  cancelSiteEdit() {
    this.editSiteForm = false;
    this.siteAddForm.reset();
  }
  editSiteInfo(data: any) {
    // this.getCountryList();
    this.editSiteData = data;
    this.editSiteForm = true;
    this.siteAddForm.patchValue({
      siteName: data.siteName,
      geom: data.geom,
      description: data.description,
      address: data.address,
    });
  }
  siteAddFormGenerate() {
    this.siteAddForm = this.fb.group({
      siteName: [null, Validators.required],
      geom: [null, Validators.required],
      description: [null],
      address: [null],
      city: [null],
      state: [null],
      country: [null],
      searchedUserEmail:[null,Validators.required],
      searchedUserId:[null]
    });
  }

  updateSiteInfo() {
    if (this.siteAddForm.value.siteName.trim() === '') {
      this.toastr.error('Site name is required');
      return;
    }
    let geomData = JSON.parse(this.siteAddForm.value.geom)
      .coordinates[0].map((a: any) => a.join(' '))
      .join(',');
    let dataGEOM = 'POLYGON((' + geomData + '))';
    let userInfo = {
      mapSiteID: this.editSiteData.siteId,
      mapSiteName: this.siteAddForm.value.siteName,
      geom: dataGEOM,
      contactPerson: this.editSiteData.contactPerson,
      customerName: this.editSiteData.customerName,
      email: this.editSiteData.email,
      contactNo: this.editSiteData.contactNo,
      description:
        this.siteAddForm.value.description === null
          ? ''
          : this.siteAddForm.value.description,
      userId: this.siteAddForm.value.searchedUserId?.userId ? this.siteAddForm.value.searchedUserId?.userId : parseInt(this.loginUserId),
      address:
        this.siteAddForm.value.address === null
          ? ''
          : this.siteAddForm.value.address,
      jobIds: [0],
      city:
        this.siteAddForm.value.city === null ? '' : this.siteAddForm.value.city,
      state:
        this.siteAddForm.value.state === null
          ? ''
          : this.siteAddForm.value.state,
      country:
        this.siteAddForm.value.country === null
          ? ''
          : this.siteAddForm.value.country,
      operationBy: parseInt(this.loginUserId),
    };

    if (confirm('Are you sure you want to update?')) {
      this.spinner.show();
      this.restService
        .post(PortalAPI.UPDATE_SITE, userInfo)
        .subscribe(
          (response: any) => {
            if (
              response &&
              response.processingStatus?.status.includes('Success')
            ) {
              this.toastr.success('Site updated successfully');
              this.editSiteForm = false;
              this.getSiteList(true);
            } else {
              this.toastr.error(response.processingStatus?.message);
            }
            this.spinner.hide();
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  //Refresh Description Data
  refreshDescriptionData(response: any) {
    this.editFormFeatureDescriptionView = true;
    this.openedFeatureProperties = response.groups.features[0].properties;
    this.openedFeatureAttributes = response.groups.features[0].attributes;
    this.openedFeatureAttributes.coordinate = this.createCoordinates(response.groups.features[0].geometry);
    this.jobId = response.jobId;
    this.openedFeatureName = response.groups.features[0].properties.FeatureName;
    if (
      response.groups.features[0].attributes.addedLink != null ||
      undefined ||
      ''
    ) {
      let textLink = response.groups.features[0].attributes.addedLink;
      this.openedFeatureAttributesAddedLink = textLink.split(' , ');
    } else {
      this.openedFeatureAttributesAddedLink = [];
    }
    this.slideMenuOpenJobDescription = true;
    this.slideMenuOpenTools = false;
    this.slideMenuOpenFolder = false;
    this.slideMenuOpenLayerDetails = false;
    this.editDescriptionValue = response;
    this.spinner.hide();
  }
  //Refresh Description Data End

  /*delete subsite*/
  deleteSubSite(siteData: any, siteId: any, subSiteId: any, name: any) {
    let filterData = siteData.find((it: any) => it.siteId === siteId);
    const data = {
      userId: this.loginUserId,
      subSiteId: parseInt(subSiteId),
    };
    const api =
      PortalAPI.DELETE_SUB_SITE +
      '/' +
      parseInt(subSiteId) +
      '/' +
      this.loginUserId;

    this.restService.delete(api, data).subscribe((data: any) => {
      if (data.processingStatus.status == 'Success') {
        const siteInfo = this.removeAllSpaceAndSpecialChar(name) + subSiteId;
        const index = filterData.subSiteData.findIndex(
          (it: any) => it.subSiteId === subSiteId
        );
        filterData.subSiteData.splice(index, 1);
        this.map.removeLayer(siteInfo);
        this.map.removeSource(siteInfo);
      } else {
        this.toastr.error(
          data.processingStatus.status + ' : ' + data.processingStatus.message
        );
      }
    });
  }

  //#region onSiteChange method is used to get selected site
  onSiteChange(data: any) {}
  //#endregion

  editSubSiteData(obj: any) {
    this.editSubSiteInfo(obj.data, obj.subSiteLoopData);
  }

  editSubSiteInfo(data: any, subSiteLoopData: any) {
    this.subSiteModalData = {
      siteSelectedFromSubSiteDropdown: Number(0),
      subSiteName: '',
      description: '',
      searchedUserEmail:''
    };
    if(!data){
      this.subSiteModalData.searchedUserEmail = subSiteLoopData.emailId;
    }
    if(data) {
      this.subSiteModalData.subSiteName = subSiteLoopData.subSiteName;
      this.subSiteModalData.description = subSiteLoopData.description;
      this.subSiteModalData.subSiteLoopData = subSiteLoopData;
      this.subSiteModalData.siteSelectedFromSubSiteDropdown = data?.siteId;
      this.subSiteModalData.data = data;
      this.subSiteModalHeader = 'Update';
    }
  }

  //#region onSubSiteSubmit method is used to add subsite data in local for particular site
  onSubSiteSubmit() {
    let data = this.siteAddForm.value.siteName;
    if(data) {
      this.toastr.error("please add/cancel site first");
    }
    else {
    this.subSiteServices.onSubSiteSubmit(
      this.siteData,
      this.subSiteModalData,
      this.draw.getAll().features[0]?.geometry?.coordinates[0],
      this.subSiteModalClos,
      this.draw,
      this.subSiteModalHeader
    );
    if(this.slideMenuOpenSiteAdminDetails) {
      setTimeout(() => {
        this.newlyAddedSubSiteValue = {...this.subSiteModalData};
     //   this.newlyAddedSubSiteValue = this.subSiteModalData;
      }, 200);
    }
    this.isSubSiteEditClick = '';
    }
  }
  //#endregion

  //#region getSubSiteBySiteId method used to get the subsitedata for particular site
  getSubSiteBySiteId(siteId: any) {
    if (siteId) {
      this.subSiteServices.getSubSiteBySiteId(siteId, this.siteData);
      setTimeout(() => {
        this.siteData.forEach((element: any) => {
          if (element.subSiteData && element.subSiteData.length > 0){
          const subsiteData = element.subSiteData?.filter((data: any) => data.isAssociated); //only associated subsite will be shown on map
              this.drawPolygonOnMap(
              subsiteData,
              'orange',
              'subSiteName',
              'subSiteId',
              'subsite',
              'description',
              'address',
              'city',
              'state',
              'country'
            );
        }
        });
      }, 1000);
    }
  }
  //#endregion

  //#region clearSubSiteForm method is used to clear the subsite form
  clearSubSiteForm() {
    this.subSiteServices.clearSubSiteForm(this.subSiteModalData);
  }
  //#endregion

  //#region subSiteModalOpenClick method is used to user click on the add subsite button from map
  subSiteModalOpenClick() {
    if(!this.slideMenuOpenSiteAdminDetails) {
      this.subSiteServices.subSiteModalOpenClick(
        this.siteData,
        this.subSiteModalData
      );
    } else {
      let siteAdminSiteData = sessionStorage.getItem('subSiteData');
      if(siteAdminSiteData) {
        if(JSON.parse(siteAdminSiteData).length > 0) {
          this.siteData = JSON.parse(siteAdminSiteData);
        }
        this.subSiteModalData.siteSelectedFromSubSiteDropdown = Number(0)
      }
    }
  }
  //#endregion

  onUpdateEditMode(editMode: any) {
    this.isEditingModeIsOn = editMode;
  }

  onMapEditor(obj: any) {
    this.isSiteEditingModeIsOn = true;
    if (obj.layer === 'SITE') {
      this.siteEditId = obj.data.siteId;
      this.isSiteEditClick = obj.data.siteId;
    } else if (obj.layer === 'SUBSITE') {
      this.siteEditId = obj.data.subSiteId;
      this.isSubSiteEditClick = obj.data.subSiteId;
    }
  }

  //# Share sites to concerned person with view/edit access/
  shareSite() {
    this.spinner.show();
    this.dashboardservice
      .fetchEditEmails(this.emailFormControl.value)
      .subscribe((data) => {
        this.spinner.hide();
        this.tierIDData = data;
        let sharedUserTierID = '';
        let sharedUserRole = '';
        if (this.tierIDData.length > 0) {

          sharedUserTierID = this.tierIDData[0].tierId;
          sharedUserRole = this.tierIDData[0].roleName;
        }

        if(this.loginUserRole  == 'Client' && this.isAssigned == false){
          this.toastr.error(
            'User does not have access to share the content'
          );
          return;
        }

        if (
          this.tierIDData.length === 0 &&
          this.accessFormControl.value == '2'
        ) {
          sharedUserTierID = '';
          this.toastr.error(
            'Unrigistered User does not have access to edit the content'
          );
          return;
        }
        let loginUsertierId = sessionStorage.getItem('tierId');

        if (sharedUserRole != 'Project Manager' && sharedUserRole != 'Admin') {
          if (
            loginUsertierId == '2' &&
            this.accessFormControl.value == '2' &&
            sharedUserTierID == '1'
          ) {
            // this.toastr.error("Free account User does not have access to edit the content");
            this.toastr.error(
              'Designated User does not have access to editing.'
            );
            return;
          }
          if (
            loginUsertierId == '1' &&
            this.accessFormControl.value == '2' &&
            sharedUserTierID == '2'
          ) {
            this.toastr.error(
              'Designated User does not have access to editing.'
            );
            return;
          }
          if (
            loginUsertierId == '1' &&
            this.accessFormControl.value == '2' &&
            sharedUserTierID == '1'
          ) {
            this.toastr.error(
              'Designated User does not have access to editing.'
            );
            return;
          }
        }

        if (this.emailFormControl.value == '') {
          this.toastr.error('Please enter email address');
        } else {
          if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(
              this.emailFormControl.value
            )
          ) {
            this.spinner.show();
            let payLoad = {
              sharedWithEmailId: this.emailFormControl.value,
              sharedByUserId: this.loginUserId,
              objectId: this.clickSiteOrSubSiteID,
              objectTypeId: 4, // 4 for sites
              securableObjectPermissionTypeId: parseInt(
                this.accessFormControl.value
              ),
            };
            this.dashboardSubscribe = this.dashboardservice
              .shareObject(payLoad)
              .subscribe((response) => {
                this.sharedObjResponse = response;
                this.spinner.hide();
                this.emailFormControl.reset();
                this.accessFormControl.patchValue('1');
                this.emailFormControl.patchValue('');
                if (
                  this.sharedObjResponse.processingStatus.status === 'Failure'
                ) {
                  this.toastr.error(
                    'The site has already been shared with same access with User'
                  );
                } else {
                  this.toastr.success('Site shared successfully');
                }
              });
          } else {
            this.toastr.error('Please enter a valid Email Address.');
          }
        }
      });
  }

  //# close site share modal
  closeSiteShareModal() {
    this.accessFormControl.patchValue('1');
    this.objSharedUserListForSiteSubSite = [];
    this.emailFormControl.setValue('');
  }

  //# Share Sub-sites to concerned person with view/edit access/
  shareSubSite() {
    this.spinner.show();
    this.dashboardservice
      .fetchEditEmails(this.emailFormControl.value)
      .subscribe((data) => {
        this.spinner.hide();
        this.tierIDData = data;
        let sharedUserTierID = '';
        let sharedUserRole = '';
        if (this.tierIDData.length > 0) {
          sharedUserTierID = this.tierIDData[0].tierId;
          sharedUserRole = this.tierIDData[0].roleName;
        }

        if(this.loginUserRole  == 'Client' && this.isAssigned == false){
          this.toastr.error(
            'User does not have access to share the content'
          );
          return;
        }

        if (
          this.tierIDData.length === 0 &&
          this.accessFormControl.value == '2'
        ) {
          sharedUserTierID = '';
          this.toastr.error(
            'Unrigistered User does not have access to edit the content'
          );
          return;
        }
        let loginUsertierId = sessionStorage.getItem('tierId');

        if (sharedUserRole != 'Project Manager' && sharedUserRole != 'Admin') {
          if (
            loginUsertierId == '2' &&
            this.accessFormControl.value == '2' &&
            sharedUserTierID == '1'
          ) {
            // this.toastr.error("Free account User does not have access to edit the content");
            this.toastr.error(
              'Designated User does not have access to editing.'
            );
            return;
          }
          if (
            loginUsertierId == '1' &&
            this.accessFormControl.value == '2' &&
            sharedUserTierID == '2'
          ) {
            this.toastr.error(
              'Designated User does not have access to editing.'
            );
            return;
          }
          if (
            loginUsertierId == '1' &&
            this.accessFormControl.value == '2' &&
            sharedUserTierID == '1'
          ) {
            this.toastr.error(
              'Designated User does not have access to editing.'
            );
            return;
          }
        }

        if (this.emailFormControl.value == '') {
          this.toastr.error('Please enter email address');
        } else {
          if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(
              this.emailFormControl.value
            )
          ) {
            this.spinner.show();
            let payLoad = {
              sharedWithEmailId: this.emailFormControl.value,
              sharedByUserId: this.loginUserId,
              objectId: this.clickSiteOrSubSiteID,
              objectTypeId: 5, // 5 for subsites
              securableObjectPermissionTypeId: parseInt(
                this.accessFormControl.value
              ),
            };

            this.dashboardSubscribe = this.dashboardservice
              .shareObject(payLoad)
              .subscribe((response) => {
                this.sharedObjResponse = response;
                this.spinner.hide();
                this.emailFormControl.reset();
                this.accessFormControl.patchValue('1');
                this.emailFormControl.patchValue('');
                if (
                  this.sharedObjResponse.processingStatus.status === 'Failure'
                ) {
                  this.toastr.error(
                    'The Subsite has already been shared with same access with User'
                  );
                } else {
                  this.toastr.success('Subsite shared successfully');
                }
              });
          } else {
            this.toastr.error('Please enter a valid Email Address.');
          }
        }
      });
  }

  //# close Sub-Site share modal
  closeSubSiteShareModal() {
    this.accessFormControl.patchValue('1');
    this.objSharedUserListForSiteSubSite = [];
    this.emailFormControl.setValue('');
  }

  //# open modal for share site
  openModalforSiteShareJob() {
    this.getSharedWithUserForSiteSubsiteList(this.clickSiteOrSubSiteID, 4);
    let button = document.getElementById(
      'btnModalSiteShare'
    ) as HTMLButtonElement;
    button.click();
  }

  //# open modal for share subsite
  openModalforSubSiteShareJob() {
    this.getSharedWithUserForSiteSubsiteList(this.clickSiteOrSubSiteID, 5);
    let button = document.getElementById(
      'btnModalSubSiteShare'
    ) as HTMLButtonElement;
    button.click();
  }

  //get user shared list for Site or Subsite
  getSharedWithUserForSiteSubsiteList(id: any, typeId: any) {
    this.dashboardservice
      .fetchSharedWithUserList(id, typeId)
      .subscribe((data) => {
        this.objSharedUserListForSiteSubSite = data;
      });
  }

  //# open modal for site or subsite details
  openModalforSiteSubSiteDetails() {
    if (this.viewSiteOrSubSiteStatus == 'site') {
      let siteDataDummy = this.siteData.find(
        (it: any) => it.siteId == this.clickSiteOrSubSiteID
      );
      this.subSiteNameDetailForSitePopup = siteDataDummy.subSiteData;
      this.jobDetailForSitePopup = siteDataDummy.jobData;
      console.log(siteDataDummy, '00000000')
      this.isAssigned = siteDataDummy.isAssigned;
    } else {
      let subsiteData: any;
      for (let element of this.siteData) {
        if (element.subSiteData && element.subSiteData.length > 0) {
          const findData = element.subSiteData.find(
            (it: any) => it.subSiteId === this.clickSiteOrSubSiteID
          );
          if (findData) {
            subsiteData = findData;
            break;
          }
        }
      }
      this.siteOrSubSiteDetailsByClick = subsiteData;
      this.isAssigned = subsiteData.isAssigned;
      this.subSiteServices
        .getJobsBySubSiteIdForSubSitePopup(this.clickSiteOrSubSiteID, this.loginUserId)
        .subscribe((response: any) => {
          this.jobDetailForSitePopup = response;
        });
    }

    let button = document.getElementById(
      'btnModalSiteSubSiteDetails'
    ) as HTMLButtonElement;
    button.click();
  }

  /***
   * Map Print model start
   */
  printFormGenerate() {
    this.printForm = this.fb.group({
      psCRS: ['SPCS', Validators.required],
      psScale: [25, [Validators.min(1),
      Validators.max(13000),
      Validators.minLength(1),
      Validators.maxLength(5),
      Validators.pattern(/\d/)]],
      psSize: ['letter', Validators.required],
      psOrientation: ['landscape', Validators.required],
      psFormat: ['PDF', Validators.required],
      psPrintGrid: [false, Validators.required],
    })


  }

  createScaleBar() {
    let scaleBarLabelDOM: any = document.getElementById('scale-bar-label') as HTMLElement;
    // if (scaleBarLabelDOM) {
    //   scaleBarLabelDOM.textContent = `Scale: 1" = ${this.printForm.value.psScale}'`;
    // }
    let scaleBarTickIncrements: number = parseFloat((this.printForm.value.psScale / 4).toFixed(1));    let firstBarLabelDOM: any = document.getElementById('first-bar-label') as HTMLElement;
    if (firstBarLabelDOM) {
      firstBarLabelDOM.textContent = '0';
    }
    let secondBarLabelDOM: any = document.getElementById('second-bar-label') as HTMLElement;
    if (secondBarLabelDOM) {
      secondBarLabelDOM.textContent = `${scaleBarTickIncrements}'`;
    }
    let thirdBarLabelDOM: any = document.getElementById('third-bar-label') as HTMLElement;
    if (thirdBarLabelDOM) {
      thirdBarLabelDOM.textContent = `${scaleBarTickIncrements * 2}'`;
    }
    let fourthBarLabelDOM: any = document.getElementById('fourth-bar-label') as HTMLElement;
    if (fourthBarLabelDOM) {
      fourthBarLabelDOM.textContent = `${scaleBarTickIncrements * 3}'`;
    }
    let fifthBarLabelDOM: any = document.getElementById('fifth-bar-label') as HTMLElement;
    if (fifthBarLabelDOM) {
      fifthBarLabelDOM.textContent = `${this.printForm.value.psScale}'`;
    }
  }

  onPrintClick() {
    this.scaleError = "";

    if(this.printForm.value.psScale  > 13000){
      return;
    }
    if(!this.printForm.value.psScale){
      this.scaleError = "Scale is required";
      return;
    }

    this.printState = true;

    // Create the scale bar
    this.createScaleBar();

    // Get the extent of the Print box
    let printBounds = turf.bbox(this.draw.getAll().features[0])

    // [-87.49852616685753, 36.859163957189054, -87.49128902620205, 36.86466840010627]

    // Get the Map Wrapper DOM
    const mwDom: HTMLElement | null = document.getElementById('map-wrapper');
    mwDom!.style.visibility = 'visible';


    // Get the dimensions of map-wrapper
    let mwWidth: number | undefined = mwDom?.clientWidth;
    let mwHeight: number | undefined = mwDom?.clientHeight;

    // Create a PDF document

    // let format;
    // if (
    //   this.printForm.value.psSize === 'ansi-c' ||
    //   this.printForm.value.psSize === 'ansi-d' ||
    //   this.printForm.value.psSize === 'ansi-e') {
    //   if (this.printForm.value.psOrientation === 'portrait') {
    //     format = [mwWidth, mwHeight]
    //   } else {
    //     format = [mwHeight, mwWidth]
    //   }
    // } else {
    //   format = this.printForm.value.psSize
    // }

    // ANSI paper types are not built into jsPDF so we specify dimensions based on map-wrapper
    let pdfPaperFormat: any;
    if (this.printForm.value.psSize.startsWith('ansi')) {

      if (this.printForm.value.psOrientation === 'portrait') {
        pdfPaperFormat = [mwWidth, mwHeight];
      } else {
        pdfPaperFormat = [mwHeight, mwWidth];
      }
    } else {
      pdfPaperFormat = this.printForm.value.psSize;
    }



    var docHtml = new jsPDF({
      orientation: this.printForm.value.psOrientation,
      unit: 'px',
      // format: format,
      format: pdfPaperFormat,
      putOnlyUsedFonts: true,
      floatPrecision: 16
    });

    // Get the dimensions of PDF doc being created
    let pageWidth = docHtml.internal.pageSize.width;
    let pageHeight = docHtml.internal.pageSize.height;

    // Scale map-wrapper to the lower of the ratios of PDF / DOM
    let scale: number = [pageHeight / mwHeight!, pageWidth / mwWidth!].sort()[0];


    // Get Print Box bounds in Lng/Lat
    const lowerLeftPnt: any = [printBounds[0], printBounds[1]];
    const upperRightPnt: any = [printBounds[2], printBounds[3]];

    // Get Print Box bounds in X/Y (pixels)
    const lowerLeftPixel = this.map.project(lowerLeftPnt);
    const upperRightPixel = this.map.project(upperRightPnt)




    // Create a bbox to pass to queryRenderedFeatures
    let queryBbox: any = [lowerLeftPixel, upperRightPixel];
    // Get all features in the bbox and build a Feature Collection
    let mapLayersAll = this.map.getStyle().layers;
    let printLayersRegex = new RegExp('^[1234]-.*');
    let mapLayersPrint: any = mapLayersAll.filter((layer:any) => printLayersRegex.test(layer.id)).map((layer:any) => layer.id)
    let allFeatures = this.map.queryRenderedFeatures(queryBbox, { layers: [...mapLayersPrint] }).filter(feature => feature.geometry.type !== 'Polygon')
    // let allFeatures = this.map.queryRenderedFeatures(queryBbox).filter(feature => feature.geometry.type !== 'Polygon')
    // let allFeatures = this.map.queryRenderedFeatures(queryBbox);

    // if (allFeatures.length === 0) {
    //   this.toastr.error("Click on print when feature is loaded.");
    //   return
    // }
    let featureCollection: any = {
      type: 'FeatureCollection',
      features: []
    }
    allFeatures.forEach((feature: any) => {
      let currentFeature: any = {
        type: 'Feature',
        properties: {}
      };
      currentFeature.properties.featureType = feature.properties.featureType;
      currentFeature.properties.featureName = feature.properties.featureName;
      currentFeature.geometry = feature.geometry;
      featureCollection.features.push(currentFeature);
    })
    let featureCollectionJSON: any = JSON.stringify(featureCollection);
    this.featureTypeSymbologyMap = this.processFeatures(allFeatures)
    this.pointSymbology = [
      ...(this.featureTypeSymbologyMap.point ?? []),
      ...(this.featureTypeSymbologyMap.photo ?? [])
  ]    // console.log('featureTypeMap', this.featureTypeSymbologyMap);
    this.processLineSymbology()
    // Image dimensions
    let printImgContainerDom = document.querySelector('.print-img-container');
    let printImgDom = document.getElementById('print-img');
    let imgWidth = printImgContainerDom!.clientWidth;
    let imgHeight = printImgContainerDom!.clientHeight;

    let printedDateDOM = document.getElementById('printed-date');
    printedDateDOM!.textContent = new Date().toLocaleDateString();
    let printedByDOM = document.getElementById('printed-by');
    printedByDOM!.textContent = this.loginUserEmailId;

    let currentStyleUrl = this.map.getStyle().sprite;
    let baseMapId = currentStyleUrl ? 'mapbox/' + currentStyleUrl.substring(currentStyleUrl.lastIndexOf('/') + 1) : 'mapbox/satellite-streets-v11';


    // Add project info - need to get this from API
    // Get any Job in the viewport - not sure what else to do here
    var payload = {
      printBounds: printBounds,
      xDim: imgWidth,
      yDim: imgHeight,
      featureCollection: featureCollection,
      crs: this.printForm.value.psCRS,
      printGrid: this.printForm.value.psPrintGrid,
      mapboxBaseMapId: baseMapId

    }

    let printFinalFunctionObj = {
      printImgDom: printImgDom,
      imgHeight: imgHeight,
      imgWidth: imgWidth,
      docHtml: docHtml,
      mwDom: mwDom,
      scale: scale
    }

    let printJobIds = this.map.querySourceFeatures('allJobs').map((source: any) => source.properties.jobId);
    let projectNameDOM = document.getElementById('project-name');
    let projectAddressDOM = document.getElementById('project-address');
    let projectCityDOM = document.getElementById('project-city');
    if (printJobIds.length > 0) {
      let printJobId = printJobIds[0];
      // Make MS call here to https://jobs.gprssitemap.com/Jobs/GetJob/printJobId
      // Then add Job info to print report
      this.restService.get(PortalAPI.GET_JOB_INFORMATION + `/${printJobId}`).subscribe(response => {
        projectNameDOM!.textContent = response?.jobName;
        projectAddressDOM!.textContent = response?.address1;
        projectCityDOM!.textContent = response?.city;
        this.printFinalFunction(payload, printFinalFunctionObj);
      }, (err: any) => {
        console.log("Error", err);
        projectNameDOM!.textContent = 'No Project Name';
        projectAddressDOM!.textContent = 'No Project Address';
        projectCityDOM!.textContent = 'No Project City';
        this.printFinalFunction(payload, printFinalFunctionObj);
      });
    } else {
      projectNameDOM!.textContent = 'No Project Name';
      projectAddressDOM!.textContent = 'No Project Address';
      projectCityDOM!.textContent = 'No Project City';
      // console.log(payload, 'payload');
      // console.log(printFinalFunctionObj, 'printFinalFunctionObj');


      this.printFinalFunction(payload, printFinalFunctionObj);
    }

  }

  printFinalFunction(payload : any, printFinalFunctionObj:any){
    this.spinner.show();
    this.commonService.postPrintData(payload).subscribe(response => {


      printFinalFunctionObj.printImgDom!.setAttribute('src', `data:image/png;base64,${response.data}`);
      printFinalFunctionObj.printImgDom!.setAttribute('width', printFinalFunctionObj.imgWidth.toString());
      printFinalFunctionObj.printImgDom!.setAttribute('height', printFinalFunctionObj.imgHeight.toString());
      // Update the CRS
      let crsDivDom = document.getElementById('crs-div');
      crsDivDom!.textContent = response.crsText;

      if (this.printForm.value.psFormat === 'PDF') {
        printFinalFunctionObj.docHtml.html(printFinalFunctionObj.mwDom!, {
          callback: (docHtml: any) => {
            let pageCount = docHtml.getNumberOfPages();
            if (pageCount > 1){
              docHtml.deletePage(pageCount);
            }
            docHtml.save('GPRSReport.pdf');
            printFinalFunctionObj.mwDom!.style.visibility = 'hidden';
            printFinalFunctionObj.printImgDom!.setAttribute('src', '');
            printFinalFunctionObj.printImgDom!.setAttribute('height', '0px');
            printFinalFunctionObj.printImgDom!.setAttribute('width', '0px');
            this.printSessionEnd();
            this.spinner.hide();
            // this.printBoxDelete();
            this.printState = false;
          },
          margin: 0,
          html2canvas: {
            scale: printFinalFunctionObj.scale,
          },
          x: 0,
          y: 0,
        });
      } else if (this.printForm.value.psFormat === 'JPG') {
        html2canvas(printFinalFunctionObj.mwDom!, {}).then((canvas: any) => {
          let canvasImg = canvas.toDataURL('image/jpg');
          let xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function () {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = 'GPRSReport.jpg';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
          };
          xhr.open('GET', canvasImg);
          xhr.send();
          printFinalFunctionObj.mwDom!.style.visibility = 'hidden';
          printFinalFunctionObj.printImgDom!.setAttribute('src', '');
          this.printSessionEnd();
          this.spinner.hide();
          // this.printBoxDelete();
          this.printState = false;

        });
      }
      // this.printBoxDelete();
      // setTimeout(() => {
      //   this.spinner.hide();
      // }, 2000);
      // this.printState = false;
    }, err => {
      console.log('Error retrieving image ...');
      console.log(err);
      this.spinner.hide();
    });
  }

  resetCounter() {
    this.counter = 0;
  }

  onPrintShowPrintBox(e: Event) {
    this.printState = false;
    if(this.counter == 0){
      this.counter = 1;
    }
    if (this.counter === 1) {
      this.printSessionStart();
    } else {
      this.toastr.error('Printbox already added');
    }

  }

  onPrintClickScale() {
    //console.log('00000')
    this.printBoxUpdate();
  }

  onPrintClickPaperSize() {
    this.setMapWrapperCSS()
    this.printBoxUpdate()
  }

  onPrintClickOrientation() {
    this.setMapWrapperCSS()
    this.printBoxUpdate()
  }

  onGridPrintClick() {
  }

  setMapWrapperCSS() {
    let mapWrapperDOM = document.getElementById('map-wrapper');
    let classList = [];
    for (let index = 0; index < mapWrapperDOM!.classList.length; index++) {
      let css = mapWrapperDOM!.classList.item(index);
      classList.push(css);

    }
    classList.forEach((css) => {

      mapWrapperDOM!.classList.remove(css!);
    });
    mapWrapperDOM!.classList.add(this.printForm.value.psOrientation);
    mapWrapperDOM!.classList.add(this.printForm.value.psSize);
  }

  getMapWidthInMeters(latlng1: any, latlng2: any) {
    // Uses spherical law of cosines approximation.
    const R = 6371000;

    const rad = Math.PI / 180,
        lat1 = latlng1.lat * rad,
        lat2 = latlng2.lat * rad,
        a = Math.sin(lat1) * Math.sin(lat2) +
          Math.cos(lat1) * Math.cos(lat2) * Math.cos((latlng2.lng - latlng1.lng) * rad);

    const xWidthInMeters = R * Math.acos(Math.min(a, 1));
    return xWidthInMeters;
  }

  getMapDistanceInMeters(lnglat1: any, lnglat2: any){
    let point1 = turf.point([lnglat1.lng, lnglat1.lat]);
    let point2 = turf.point([lnglat2.lng, lnglat2.lat]);

    let distance = turf.distance(point1, point2, {units: "kilometers"}) * 1000;

    return distance
  }

  getMapXInMetersAndPixels() {
    // Assume from center of map
    let mapContainer = this.map.getContainer();
    const yMidInPixels = mapContainer.clientHeight / 2;
    const xWidthInPixels = mapContainer.clientWidth;

    // Create two points from left to right and in the middle of the map
    let latlng1 = this.map.unproject([0, yMidInPixels]);
    let latlng2 = this.map.unproject([xWidthInPixels, yMidInPixels]);

    const xWidthInMeters = this.getMapWidthInMeters(latlng1, latlng2)
    return {xWidthInMeters, xWidthInPixels};
}

  printBoxCreate(centerCoordinates: any) {
    // Draw the print box
    // FIrst get the map's current scale
    let {xWidthInMeters, xWidthInPixels}  = this.getMapXInMetersAndPixels();
    // Assume 96 pixels per inch
    const ppi = 96;
    let mapScale = ppi * (xWidthInMeters/xWidthInPixels) * 39.3701; // scale in inches, e.g. 43.4; there are 39.3701 inches in a meter
    // Then get the scale value in the form
    let scaleValue = this.printForm.value.psScale * 12;
    // Compare scale of map with scale selected by user
    let scaleRatio = scaleValue / mapScale;
    // let currentScale = ppi * (xWidthInMeters/xWidthInPixels); // scale in inches, e.g. 43.4
    // console.log(`The map with in meters is ${xWidthInMeters} and the scale is ${currentScale}.`);

    // // The initial scale factor which correlates to value 1 on scale innput
    // let scaleFactor = 0.25;
    // let scaleValue = this.printForm.value.psScale / 100;
    // let scaleEffective = scaleFactor * scaleValue;

    // Get dimensions from the DOM
    let imgDOM = document.querySelector('.print-img-container');
    let imgWidth = imgDOM?.clientWidth;
    let imgHeight = imgDOM?.clientHeight;
    // Map coords in LntLat and Pixel space
    let mapCenterLngLat;
    if (centerCoordinates === undefined) {
      mapCenterLngLat = this.map.getCenter();
    } else {
      mapCenterLngLat = {
        lng: centerCoordinates[0],
        lat: centerCoordinates[1],
      };
    }
    // Get the pixel X, Y coordinates
    let mapCenterXY = this.map.project(mapCenterLngLat);

    let xMin = mapCenterXY.x - (imgWidth! / 2);
    let xMax = mapCenterXY.x + (imgWidth! / 2);
    let yMin = mapCenterXY.y - (imgHeight! / 2);
    let yMax = mapCenterXY.y + (imgHeight! / 2);

    // Find PrintBox dimensions to satisfy scale requirements
    imgWidth = imgWidth! * scaleRatio;
    imgHeight = imgHeight! * scaleRatio;
    xMin = mapCenterXY.x - (imgWidth! / 2);
    xMax = mapCenterXY.x + (imgWidth! / 2);
    yMin = mapCenterXY.y - (imgHeight! / 2);
    yMax = mapCenterXY.y + (imgHeight! / 2);

    let feature = {
      type: 'Polygon',
      coordinates: [
        [
          [this.map.unproject([xMin, yMin]).lng, this.map.unproject([xMin, yMin]).lat],
          [this.map.unproject([xMin, yMax]).lng, this.map.unproject([xMin, yMax]).lat],
          [this.map.unproject([xMax, yMax]).lng, this.map.unproject([xMax, yMax]).lat],
          [this.map.unproject([xMax, yMin]).lng, this.map.unproject([xMax, yMin]).lat],
          [this.map.unproject([xMin, yMin]).lng, this.map.unproject([xMin, yMin]).lat],
        ]
      ]
    };
    var featureIds = this.draw.add(feature);
    this.draw.setFeatureProperty(featureIds[0], 'isPrintBox', true);
  }

  printBoxUpdate() {
    // Get the current Print Box
    let printBox = this.draw
      .getAll()
      .features.filter((feature: any) => feature.properties.isPrintBox);
     // console.log(printBox, '000000')
    if (printBox.length > 0) {
      printBox = printBox[0];
      // Get the center of the Print Box
      let center = turf.center(printBox);

      // Remove any pre-exsting Print boxes
      this.draw.deleteAll();
      // Update the Print Box
      this.printBoxCreate(center.geometry.coordinates);
    }
  }

  printBoxDelete() {
    this.printType = "";
    this.draw.deleteAll();
    this.resetCounter();
    this.printSessionEnd();
    this.printForm.reset();
    this.printFormGenerate();
    this.onPrintClickOrientation();
  }

  printDrawSelectionChange(e: any) {


    let drawFeature = this.draw.getSelected();
    if (drawFeature.features.length > 0) {
      this.draw.changeMode('direct_select', {
        featureId: drawFeature.features[0].id,
      });
    }
  }

  handleZoomInPrint=(e: any) =>{
   // console.log(`Zooming while printing; zoom = ${this.map.getZoom()}`);
    this.printBoxUpdate();
  }

  printSessionStart() {
    // Set the printing session to true
    this.isPrintSession = true;

    // Handle zoom
    this.map.on('zoom', this.handleZoomInPrint);
    // Start listening for Draw selectionchange events
    // this.map.on('draw.selectionchange', this.printDrawSelectionChange);
    // Draw the inital Print box
    this.printBoxCreate(undefined);
  }

  printSessionEnd() {
    // Remove Zoom handler
    this.map.off('zoom', this.handleZoomInPrint);
    // Remove all Draw features
    // this.draw.deleteAll();
    // Set the printing session to false
    this.isPrintSession = false;
  }

  getLineStyle(paintProperty: string) {
    let paintProp: any = ['case'];
    let lineStyle: any = FeatureTypeStyleMapping.featureTypeStyleJson
      .filter((featureStyle) => featureStyle.geometry_type === 'LINE')
      .forEach((featureStyle: any) => {
        paintProp.push([
          'all',
          ['==', ['get', 'user_featureGroup'], featureStyle.feature_group_name],
          ['==', ['get', 'user_featureType'], featureStyle.feature_type_name],
        ]);
        if (paintProperty === 'line-color') {
          paintProp.push(featureStyle.color);
        } else if (paintProperty === 'line-width') {
          paintProp.push(featureStyle.line_width);
        }
      });

    if (paintProperty === 'line-color') {
      paintProp.push('rgb(255,153,255)');
    } else if (paintProperty === 'line-width') {
      paintProp.push(2);
    }

    return paintProp;
  }
  /***
   * Map Print model end
   */

  // Reterieve Vector Tiles by user id
  reteriveVectorTilesbyUser() {
    // this.loginUserId
    // this.getAllVectorsSubscribe = this.mapViewerJobFeatureService.retrieveVectorTiles(this.loginUserId).subscribe((response: any) => {
    //   if (response?.vectorTileEntities?.length > 0) {
    //     console.log("response",response.vectorTileEntities);
    //     for(let i = 0; i<response.vectorTileEntities.length;i++){
    //       this.commonMapService.setSourceForReferenceLayerVectorTiles(this.map,response.vectorTileEntities[i]);
    //     }
    //   } else {
    //     this.toastr.warning("No Data in Reference GIS layer");
    //    }
    //   }, (err: any) => {
    //     console.log("Error", err);
    //   });
  }

  //External layer Tab for subsite/siteid

  externalLayerAssociateSitesCheckbox(
    jobid: any,
    typeId: any,
    event: any,
    siteType: any,
    nestedlevel?: any
  ) {
    const extData =
      siteType === 'site'
        ? this.externalLayerData.map((data: any) => {
            return {
              typeId: data.externalContentTypeId,
              associateJobIds: data.externalContent
                .filter((job: any) => job.isAssociated)
                .map((subdata: any) => subdata.id),
              deassociateJobIds: data.externalContent
                .filter((job: any) => !job.isAssociated)
                .map((subdata: any) => subdata.id),
            };
          })
        : this.externalLayerDataForSubsite.map((data: any) => {
            return {
              typeId: data.externalContentTypeId,
              associateJobIds: data.externalContent
                .filter((job: any) => job.isAssociated)
                .map((subdata: any) => subdata.id),
              deassociateJobIds: data.externalContent
                .filter((job: any) => !job.isAssociated)
                .map((subdata: any) => subdata.id),
            };
          });
    const isTypeIdPresent =
      siteType === 'site'
        ? this.externalLayerjobs.find((data: any) => data.typeId === typeId)
        : this.externalLayerJobsForSubsite.find(
            (data: any) => data.typeId === typeId
          );
    if (!isTypeIdPresent && !nestedlevel && jobid !== '') {
      if (siteType === 'site') {
        this.externalLayerjobs.push({
          typeId,
          associateJobIds: [],
          deassociateJobIds: [],
        });
      } else {
        this.externalLayerJobsForSubsite.push({
          typeId,
          associateJobIds: [],
          deassociateJobIds: [],
        });
      }
    } else if (nestedlevel) {
      if (siteType === 'site') {
        this.externalLayerData = this.externalLayerData.map((data: any) => {
          if (data.externalContentTypeId === typeId) {
            data.externalContent = data.externalContent.map((subdata: any) => {
              subdata.isAssociated = event.target.checked;
              return subdata;
            });
            if (isTypeIdPresent) {
              this.externalLayerjobs = this.externalLayerjobs.map(
                (extjobs: any) => {
                  if (event.target.checked) {
                    extjobs.associateJobIds = data.externalContent.map(
                      (job: any) => job.id
                    );
                    extjobs.deassociateJobIds = [];
                  } else {
                    extjobs.deassociateJobIds = data.externalContent.map(
                      (job: any) => job.id
                    );
                    extjobs.associateJobIds = [];
                  }
                  return extjobs;
                }
              );
            } else {
              if (event.target.checked) {
                this.externalLayerjobs.push({
                  typeId,
                  associateJobIds: data.externalContent.map(
                    (job: any) => job.id
                  ),
                  deassociateJobIds: [],
                });
              } else {
                this.externalLayerjobs.push({
                  typeId,
                  deassociateJobIds: data.externalContent.map(
                    (job: any) => job.id
                  ),
                  associateJobIds: [],
                });
              }
            }
          }
          return data;
        });
      } else {
        this.externalLayerDataForSubsite = this.externalLayerDataForSubsite.map(
          (data: any) => {
            if (data.externalContentTypeId === typeId) {
              data.externalContent = data.externalContent.map(
                (subdata: any) => {
                  subdata.isAssociated = event.target.checked;
                  return subdata;
                }
              );
              if (isTypeIdPresent) {
                this.externalLayerJobsForSubsite =
                  this.externalLayerJobsForSubsite.map((extjobs: any) => {
                    if (event.target.checked) {
                      extjobs.associateJobIds = data.externalContent.map(
                        (job: any) => job.id
                      );
                      extjobs.deassociateJobIds = [];
                    } else {
                      extjobs.deassociateJobIds = data.externalContent.map(
                        (job: any) => job.id
                      );
                      extjobs.associateJobIds = [];
                    }
                    return extjobs;
                  });
              } else {
                if (event.target.checked) {
                  this.externalLayerJobsForSubsite.push({
                    typeId,
                    associateJobIds: data.externalContent.map(
                      (job: any) => job.id
                    ),
                    deassociateJobIds: [],
                  });
                } else {
                  this.externalLayerJobsForSubsite.push({
                    typeId,
                    deassociateJobIds: data.externalContent.map(
                      (job: any) => job.id
                    ),
                    associateJobIds: [],
                  });
                }
              }
            }
            return data;
          }
        );
      }
    }
    if (siteType === 'site') {
      this.externalLayerjobs.forEach((job: any) => {
        if (job.typeId === typeId && jobid !== '') {
          const initialdata = extData.find(
            (data: any) => data.typeId === typeId
          );
          if (event.target.checked) {
            job.associateJobIds.push(jobid);
            job.associateJobIds = [
              ...new Set([
                ...initialdata.associateJobIds,
                ...job.associateJobIds,
              ]),
            ];
            while (job.deassociateJobIds.indexOf(jobid) !== -1) {
              job.deassociateJobIds.splice(
                job.deassociateJobIds.indexOf(jobid),
                1
              );
            }
          } else {
            while (job.associateJobIds.indexOf(jobid) !== -1) {
              job.associateJobIds.splice(job.associateJobIds.indexOf(jobid), 1);
            }
            job.deassociateJobIds.push(jobid);
          }
          job.associateJobIds = job.associateJobIds.filter(
            (x: any) => !job.deassociateJobIds.find((y: any) => x === y)
          );
        }
      });

      this.externalLayerData = this.externalLayerData.map((data: any) => {
        const jobs = this.externalLayerjobs.find(
          (job: any) => job.typeId === data.externalContentTypeId
        );
        if (jobs) {
          data.externalContent = data.externalContent.map((job: any) => {
            if (jobs.associateJobIds.includes(job.id)) {
              job.isAssociated = true;
            } else {
              job.isAssociated = false;
            }
            return job;
          });
        }
        return data;
      });
    } else {
      this.externalLayerJobsForSubsite.forEach((job: any) => {
        if (job.typeId === typeId && jobid !== '') {
          const initialdata = extData.find(
            (data: any) => data.typeId === typeId
          );
          if (event.target.checked) {
            job.associateJobIds.push(jobid);
            job.associateJobIds = [
              ...new Set([
                ...initialdata.associateJobIds,
                ...job.associateJobIds,
              ]),
            ];
            while (job.deassociateJobIds.indexOf(jobid) !== -1) {
              job.deassociateJobIds.splice(
                job.deassociateJobIds.indexOf(jobid),
                1
              );
            }
          } else {
            while (job.associateJobIds.indexOf(jobid) !== -1) {
              job.associateJobIds.splice(job.associateJobIds.indexOf(jobid), 1);
            }
            job.deassociateJobIds.push(jobid);
          }
          job.associateJobIds = job.associateJobIds.filter(
            (x: any) => !job.deassociateJobIds.find((y: any) => x === y)
          );
        }
      });
      this.externalLayerDataForSubsite = this.externalLayerDataForSubsite.map(
        (data: any) => {
          const jobs = this.externalLayerJobsForSubsite.find(
            (job: any) => job.typeId === data.externalContentTypeId
          );
          if (jobs) {
            data.externalContent = data.externalContent.map((job: any) => {
              if (jobs.associateJobIds.includes(job.id)) {
                job.isAssociated = true;
              } else {
                job.isAssociated = false;
              }
              return job;
            });
          }
          return data;
        }
      );
    }
  }

  // Api for external content
  externalTabSelected(siteId: any) {
    this.siteServices
      .fetchExternalContentBySiteid(siteId, 4, this.loginUserId)
      .subscribe((data: any) => {
        this.externalLayerData = data;
      });
  }
  externalTabSelectedforSubSiteId(siteId: any) {
    this.siteServices
      .fetchExternalContentBySiteid(siteId, 5, this.loginUserId)
      .subscribe((data: any) => {
        this.externalLayerDataForSubsite = data;
      });
  }

  associateJObBySubSiteId(siteId: any, data: any, type: any) {
    if (this.associateJobIds.length > 0) {
      this.subSiteServices.associateJobsBySubSiteId(
        siteId,
        data,
        this.associateJobIds,
        type,
        this.loginUserId
      );
      this.associateJobIds = [];
      this.subSiteServices.isSaveJobAssociateSiteObservable.subscribe(
        (it: any) => {
          if (it) {
            if (this.isSearchAppliedOnJobData) {
              this.map.setLayoutProperty(
                'search-unclustered-point',
                'visibility',
                'visible'
              );
              this.map.setFilter('search-unclustered-point', true);
            } else {
              this.map.setLayoutProperty(
                'unclustered-point',
                'visibility',
                'visible'
              );
              this.map.setFilter('unclustered-point', true);
            }
            this.assocateJobOnZoomIn(this.zoomOnMapParamVal);
            this.subSiteServices.setSaveAssociateBehavior(false);
          }
        }
      );
    }
    if (this.deassociateJobIds.length > 0) {
      this.subSiteServices.deassociateJobsBySubSiteId(
        siteId,
        data,
        this.deassociateJobIds,
        type,
        this.loginUserId
      );
      this.deassociateJobIds = [];
      this.subSiteServices.isSaveJobAssociateSiteObservable.subscribe(
        (it: any) => {
          if (it) {
            if (this.isSearchAppliedOnJobData) {
              this.map.setLayoutProperty(
                'search-unclustered-point',
                'visibility',
                'visible'
              );
              this.map.setFilter('search-unclustered-point', true);
            } else {
              this.map.setLayoutProperty(
                'unclustered-point',
                'visibility',
                'visible'
              );
              this.map.setFilter('unclustered-point', true);
            }
            this.assocateJobOnZoomIn(this.zoomOnMapParamVal);
            this.subSiteServices.setSaveAssociateBehavior(false);
          }
        }
      );
    }
  }

  isAllInternalCheckboxesChecked(jobs: any) {
    const isAllChecked = jobs.filter((data: any) => !data.isAssociated);
    return !isAllChecked.length;
  }
  createCoordinates(geometry: any) {
    let geoCord = {};
    if (typeof geometry === 'object') {
      const coordinates = JSON.parse(geometry.coordinates).pop();
      geoCord = { long: coordinates[0], lat: coordinates[1] };
    }
    else {
      const featureGeometry = JSON.parse(geometry);
      if (featureGeometry.type == 'LineString') {
        const coordinates = featureGeometry.coordinates[0]; 
        geoCord = { long: coordinates[1], lat: coordinates[0] };
      } else {
        const coordinates = featureGeometry.coordinates; 
        geoCord = { long: coordinates[1], lat: coordinates[0] };
      }
    }

    return geoCord;
  }
  onSaveSingleFeature(data: any) {
    if (data && data.processingStatus.status === "Success") {
      this.getSiteList();
    }
  }

  globalDrawCreate = (e: any) => {
    //please confirm with chris
    // if (!this.isEditingModeIsOn || !this.isPrintSession)
    this.siteCoordinate = this.draw.getAll().features[0].geometry.coordinates[0];
    if (!this.isEditingModeIsOn) {
      const data = this.draw.getAll();
      if (
        data.features[data.features.length - 1].geometry.type == 'LineString'
      ) {
        var coordinates =
          data.features[
            data.features.length - 1
          ].geometry.coordinates[0].slice();
        const length = turf.length(data.features[data.features.length - 1]);
        var rounded_length = Math.round(length * 3280.8398950131);
        if (!this.isEditingModeIsOn) {
          this.measeureLength(rounded_length, coordinates);
        }
      } else if (
        data.features[data.features.length - 1].geometry.type == 'Polygon'
      ) {
        this.exportDrawPolygonCordinates =
          data.features[data.features.length - 1].geometry.coordinates;
        if (this.exportDrawPolygonCordinates) {
          for (var i = 0; i < this.exportDrawPolygonCordinates.length; i++) {
            if (i == 0) {
              this.exportDrawPolygonCordinates[i].splice(i, 1);
            }
          }
        }
        var coordinates =
          data.features[
            data.features.length - 1
          ].geometry.coordinates[0][0].slice();
        let allCoordinatesInPolygon: any = [];
        let bounds: any = new mapboxgl.LngLatBounds();
        this.getJobDataPolygon(data);
        const area = turf.area(data.features[data.features.length - 1]);
        var rounded_area = Math.round(area * 10.7639);
        this.measureCalcArea(rounded_area, coordinates);
      }
    } else {
      // console.log(e, 'draw.create');
      let drawFeatureId = e.features[0].id;
      let drawFeature = this.draw.get(drawFeatureId);
      // Handle Lines specially.
      // They have a hard-coded id = sitemap_draw_line_string_id that must be changed.
      // Clicking on a snap tool while drawing a Line causes a draw.create event - ignore if we are still drawing.
      if (drawFeature !== undefined) {
        let newDrawId: string;
        if (drawFeature.geometry.type === 'Point') {
          newDrawId = 'sitemap_draw_point_id';
        } else {
          newDrawId = 'sitemap_draw_line_string_id'
        }
        if (drawFeatureId === newDrawId) {
          // Delete the existing Point/Line - it's newly added
          this.draw.delete(drawFeatureId);
          // Make a fake Id and set it
          drawFeatureId = '999' + Math.random().toString().slice(2, 10);
          drawFeature.id = drawFeatureId;
          // Add this new Line
          this.draw.add(drawFeature);
        }
        // Set properties for the Draw feature
        // Create a temp featureName
        this.draw.setFeatureProperty(
          drawFeatureId,
          'featureName',
          this.newlyAddedFeatureProperties.newAddedFeatureName
          // `${this.addNewFeatureTypeFrMapEditor}${Math.random()
          //   .toString()
          //   .slice(2, 10)}`
        );
        this.draw.setFeatureProperty(
          drawFeatureId,
          'featureType',
          this.newlyAddedFeatureProperties.addNewFeatureTypeFrMapEditor
        );
        this.draw.setFeatureProperty(
          drawFeatureId,
          'featureGroup',
          this.newlyAddedFeatureProperties.addNewFeatureGrpFrMapEditor
        );
        this.draw.setFeatureProperty(
          drawFeatureId,
          'featureGroupId',
          this.newlyAddedFeatureProperties.addNewFeatureGrpIdFrMapEditor
        );
        this.draw.setFeatureProperty(
          drawFeatureId,
          'featureTypeId',
          this.newlyAddedFeatureProperties.addNewFeatureTypeIdFrMapEditor
        );
        this.draw.setFeatureProperty(
          drawFeatureId,
          'layerId',
          this.newlyAddedFeatureProperties.currentEditinglayerId
        );
        this.draw.setFeatureProperty(
          drawFeatureId,
          'mapJobId',
          this.newlyAddedFeatureProperties.navigatedJobId
        );

        this.draw.setFeatureProperty(drawFeatureId, 'drawId', drawFeatureId);

        // console.log(drawFeature);
        if (drawFeature.geometry.type === 'Point') {
          this.draw.setFeatureProperty(drawFeatureId, 'mapPointId', 0);
          // this.draw.changeMode('simple_select');
          setTimeout(() => {
            this.draw.changeMode('sitemap_simple_select', {
              featureIds: [drawFeatureId],
              mapLayerId: this.newlyAddedFeatureProperties.currentEditinglayerId,
              navigatedFeatures: this.navigatedFeatures,
              isSnappingToVertex: this.isSnappingToVertex,
              isSnappingToLine: this.isSnappingToLine
            });
          }, 500);

        } else if (drawFeature.geometry.type === 'LineString' || drawFeature.geometry.type === 'MultiLineString') {
          this.draw.setFeatureProperty(drawFeatureId, 'mapLineId', 0);
          setTimeout(() => {
            this.draw.changeMode('sitemap_direct_select', {
              featureId: drawFeatureId,
              mapLayerId: this.newlyAddedFeatureProperties.currentEditinglayerId,
              navigatedFeatures: this.navigatedFeatures,
              isSnappingToVertex: this.isSnappingToVertex,
              isSnappingToLine: this.isSnappingToLine
            });
          }, 500);
        }

        // A create action removes all future states, if any exist
        this.setEditingHistory('create', drawFeatureId);
      }
    }
  }

  onAddGroupFeatureType(obj: any) {
   this.addGroupFeatureType = {
    layerId: obj.layerId,
    mergedLine: obj.mergedLine
   }
  }

  onToggleEditingState(obj: any) {
    this.toggleEditingState = {
      editState: obj.editState,
      layer: obj.layer
     }
  }

  setDrawing(obj:any) {
    this.isDrawing = obj.drawing;
  }

  setDisablePointInteractions(obj:any) {
    this.disablePointInteractions = obj.disablePointInteractions;
  }

  setAddVertexLineId(obj:any){
    this.addVertexLineId = obj;
  }

   setIsSnappingToVertex(obj:any){
    this.isSnappingToVertex = obj.isSnappingToVertex;
  }

  setIsSnappingToLine(obj:any){
     this.isSnappingToLine = obj.isSnappingToLine;
   }

  setEditingState(obj:any){
     this.editingState = obj.editingState;
   }

  setMergeParentLineId(obj: any){
    this.mergeParentLineId = obj.mergeParentLineId
  }

  setMergeChildLineId(obj: any){
    this.mergeChildLineId = obj.mergeChildLineId
   }

   setfirstSecondLineFeatureObjFrMerge(obj:any){
    this.firstSecondLineFeatureFrMergedArr = obj.firstSecondLineFeatureObj;
   }

   onParentOrChildMerge(obj: any){
    this.mergeParentOrChild = obj
   }

   siteSearchUser(data:any) {
    if(data.searchedUserDetail.userId) {
      if(data.searchedUserDetail.roleName.toLowerCase() !='admin' || data.searchedUserDetail.roleName.toLowerCase() !='project manager') {
        this.userDetailsLoadOnMap(false,data.searchedUserDetail.userId,data.searchedUserDetail.roleName);
      } else {
        this.adminORPMDetailsLoadOnMap(false,data.searchedUserDetail.userId,data.searchedUserDetail.roleName);
      }
      this.siteAddForm.patchValue({
        searchedUserEmail:data.searchedUserDetail.emailId,
        searchedUserId:data.searchedUserDetail
      });
      if(data?.searchedUserDetail) {
        this.editSubSiteInfo("", data.searchedUserDetail);
      }
      if(data?.layerSiteData) {
        this.siteData = data?.layerSiteData;
        sessionStorage.setItem('subSiteData',JSON.stringify(this.siteData));
      }
      this.siteAddForm.controls['searchedUserEmail'].disable();
      // if(data.layerSiteData?.length > 0){
        this.layerSiteData = data.layerSiteData;
        this.showSiteIconOnMap();
      // }
      this.getSiteList(false,data.searchedUserDetail.userId);
      this.subSiteServices.addAttrForSubSiteModal = false;
    }
   }


  selectEmailFromSite(item:any) {
    this.siteAddForm.patchValue({
      searchedUserEmail:item.emailId,
      searchedUserId: item,
    });
    this.shouldSiteFetchedEmailsBeDisplayed = false;
    this.fetchedSiteEmails = [];
  }

  searchUserByMail(data:any) {
    if(data.target.value) {
      this.restService.get(PortalAPI.SEARCH_EMAIL_EDIT +'/' + data.target.value).subscribe((response:any)=>{
        if(response && response.length > 0) {
          this.shouldSiteFetchedEmailsBeDisplayed = true;
          this.fetchedSiteEmails = response.filter((item:any)=> item.tierId == 2);
        }
      });
    }
  }

  onExitEditingLayer(payload: { isCancel: boolean }){
    this._resetSiteSubsiteToggle();
    if (payload.isCancel) {
      return;
    }
    this.getSiteList(false, undefined,  true);
    this.reLoadMVT();
  }

  reLoadMVT() {
    for (var appLayerId of [1, 2, 3]) {
      for (var tileType of ['line', 'point']) {
        this.reLoadMVTSource(appLayerId, tileType);
        //this.commonMapService.loadMVTLayerV2(this.map, appLayerId, jobId, tileType, this.pointLayerIds, this.lineLayerIds);
      }
    }

    for (var tileType of ['photo', 'matterport', 'pointcloud', 'virtualtour', 'attachment', 'externallink']) {
      this.reLoadMVTSource(4, tileType);
    }
  }

  reLoadMVTSource(appLayerId: number, tileType: string) {
    let currentSourceId = `${appLayerId}-${tileType}`;
    this.commonMapService.reSetSourceForVectorTilesV2(
      this.map,
      currentSourceId,
      appLayerId,
      tileType
    );
  }
  onSiteToggleApplied(isToggleApplied:boolean) {
    this.isToggleApplied = isToggleApplied;
  }

  processFeatures(allFeatures: any[]) {
    const featureTypeMap: Record<string, string[]> = {};
  
    allFeatures.forEach((feature) => {
      if (feature.properties) {
        const featureClass = feature.properties.featureClass;
        const featureType = feature.properties.featureType;
  
        if (!featureTypeMap[featureClass]) {
          featureTypeMap[featureClass] = [];
      }
  
        if (!featureTypeMap[featureClass].includes(featureType)) {
          featureTypeMap[featureClass].push(featureType);
        }
      }
    });
  
    return featureTypeMap;
  }
  
  processLineSymbology(): void {
    const featureTypeStyle = FeatureTypeStyleMapping.featureTypeStyleJson;
  
    const uniqueSymbologySet = new Set<string>();
  
    this.lineSymbology = [
      ...(this.featureTypeSymbologyMap.line ?? []),
      ...(this.featureTypeSymbologyMap.line_annotation ?? [])
  ].map(lineItem => featureTypeStyle.find(style => style.feature_type_name === lineItem))
    .filter(style => !!style)
    .map(style => {
      const symbologyItem = { name: style?.feature_group_name, color: style?.color };
  
      const symbologyString = JSON.stringify(symbologyItem);
  
      if (!uniqueSymbologySet.has(symbologyString)) {
        uniqueSymbologySet.add(symbologyString);
        return symbologyItem;
      }
  
      return null;
    })
    .filter(style => !!style);
  }
  
  formatIconName(iconName: string): string {
    return iconName.replace(/_/g, ' ');
  }

}
