<div class="container-fluid p-0">
  <div class="row">
    <div class="col-lg-3">
      <div class="row">
        <div class="col-lg-12 border-bottom mb-3">
          <h4>List of Uploaded Videos</h4>
        </div>
      </div>
      <div class="row">
        <form [formGroup]="checkboxForm">

          <div class="col-lg-12">
            <div class="data-list-menu">
              <div class="table-responsive">
                <table class="table table-hover table-bordered table-colored table-radius-none custom-table align-middle">
                  <thead>
                    <tr>
                      <th scope="col">Name <span class="fa fa-sort"></span></th>
                      <th scope="col">Date of Upload <span class="fa fa-sort"></span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let pic of fileArrList; let i=index;">
                      <td>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" (change)="changeVideo($event,pic)"
                            id="{{'video-' + i}}">
                          <label class="form-check-label custom-ml" for="{{'video-' + i}}">
                             {{pic.title}}
                          </label>
                        </div>
                      </td>
                      <td>{{pic.properties.createdOn | date:'MM-dd-yyyy'}}</td>
                    </tr>


                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </form>
      </div>

    </div>
    <!--Detail form div-->
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-12 border-bottom mb-3">
          <h4>Details
            <span class="float-end mt-1"  >
              <i class="fa fa-trash me-1 pointer"  id="trash" (click)="removeFile()"></i>
              <!-- <i class="fa fa-edit pointer"></i> -->
            </span>
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="row" style="display: none1;">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="card">
                <div class="card-body">
                  <video width="100%" controls loop playsinline="true" webkit-playsinline="true" [src]="changeVideoUrl"
                    type="video/mp4">
                  </video>

                  <!-- <p class="help-like mt-2"><i class="fa fa-thumbs-down"></i> <i class="fa fa-thumbs-up"></i></p> -->
                </div>
              </div>
            </div>


            <div class="col-lg-6 col-md-6 col-sm-6">
              <form [formGroup]="uploadForm">
                <div class="mb-3">
                  <label for="help-file-upload" class="visually-hidden">Video Upload</label>
                  <input type="file" #fileuploader (change)="imageSelected($event)" id="help-file-upload">
                </div>
                <!-- id="VU" -->
                <div class="mb-3">
                  <label for="help-vt" id="vt" class="form-label">Video Title</label>
                  <input type="text" formControlName="title" class="form-control" id="help-vt" placeholder="Video Title"
                    value="Managing Layer">
                  <span class="text-danger"
                    *ngIf="(uploadFormControl.title.touched || submitted) && uploadFormControl.title.errors?.required">
                    Video title is required
                  </span>

                </div>
                <!-- <div class="mb-3">
                <label for="help-vt" class="form-label">Short Description</label>
                <input type="text" class="form-control" id="help-vt" placeholder="Short Description"
                  value="How to handle layers in site map">
              </div> -->
                <!-- <div class="mb-0">
                <label for="help-duration" class="form-label">Duration</label>
              </div> -->
                <!-- <div class="mb-3 video-input-group">
                <input type="text" class="form-control" id="help-duration-hh" placeholder="H" value="H">
                <input type="text" class="form-control" id="help-duration-mm" placeholder="H" value="10">
                <input type="text" class="form-control" id="help-duration-ss" placeholder="H" value="15">
              </div> -->
                <!-- <div class="mb-3">
                <label for="help-dd" class="form-label">Detailed Description</label>
                <textarea class="form-control" id="help-dd" rows="4"></textarea>
              </div> -->
                <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end border-top pt-2 mt-2">
                  <button class="btn btn-danger btn-sm me-2"
                    (click)="fileuploader.value = ''; cancelUpload();">Cancel</button>
                  <button class="btn btn-primary btn-sm"
                    (click)="publishUploadFile(); fileuploader.value = ''; ">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>

    </div>
    <!--End Detail form div-->
    <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end border-top pt-2 mt-3">
      <!-- <button class="btn btn-danger me-2">Discard</button>
      <button class="btn btn-primary">Publish</button> -->
    </div>
  </div>

</div>