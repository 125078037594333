import { FileGroup } from "../feature-tree.component.types";
import deleteTypeFromGroups from "./delete-type-from-groups.function";
import getTypeIndexFromFiles from "./get-type-index-from-files.function";

export default function deleteTypeFromFiles(files: FileGroup[], typeId: number) {

  const {fileIndex, groupIndex, typeIndex} = getTypeIndexFromFiles(files, typeId);

  if (typeIndex === -1 || groupIndex === -1 || fileIndex === -1) {
    return;
  }

  const file = files[fileIndex];
  deleteTypeFromGroups(file.groups, typeId);

  if (file.groups.length === 0) {
    files.splice(fileIndex, 1);
  }
}
