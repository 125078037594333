import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textSlice'
})
export class TextSlicePipe implements PipeTransform {

  transform(val: any, length?: any): unknown {
    return (length && val.length>length)? val.slice(0, length)+'...':val
  }

}