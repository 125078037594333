import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { Subscription } from 'rxjs/internal/Subscription';
import { PermissionService } from '../services/common/permission.service';

@Directive({
    selector: '[appPermission]'
})
export class PermissionDirective {

    @Input() appPermission: any;
    @Input() appPermissionUserId?: any;
    @Input() appPermissionErrorMessage: any = "You do not have access to the following feature";
    @Input() appPermissionElementShowHide: boolean = false;
    subscription = new Subscription();

    constructor(
        private permissionService: PermissionService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) { }

    ngOnInit() {
        this.hideOrClickDisableElement();
    }

    hideOrClickDisableElement() {
        //for hide/show the buttons
        if (this.permissionService.checkPermission(this.appPermissionUserId, this.appPermission, this.appPermissionErrorMessage)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            if (!this.appPermissionElementShowHide) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                const el = this.templateRef.elementRef.nativeElement;
                setTimeout(() => {
                    if ((this.viewContainer.get(0) as any).rootNodes[0].getAttribute("data-bs-toggle")) {
                        (this.viewContainer.get(0) as any).rootNodes[0].removeAttribute("data-bs-toggle");
                        (this.viewContainer.get(0) as any).rootNodes[0].removeAttribute("data-bs-target");
                    };
                    if ((this.viewContainer.get(0) as any).rootNodes[0].getAttribute("routerLink")) {
                        (this.viewContainer.get(0) as any).rootNodes[0].removeAttribute("routerLink");
                        (this.viewContainer.get(0) as any).rootNodes[0].removeAttribute("href");
                    }
                    this.subscription = fromEvent(el.parentNode, 'click', { capture: true })
                        .subscribe((e: any) => {
                            if (e.target == el.previousElementSibling) {
                                e.stopPropagation();
                                this.permissionService.showErrormessageForPersonal(this.appPermissionErrorMessage);
                            } else {
                                if (e.target.nodeName.toLowerCase() == 'img' || e.target.nodeName.toLowerCase() == 'i') {
                                    if (e.target.parentElement == el.previousElementSibling) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.permissionService.showErrormessageForPersonal(this.appPermissionErrorMessage);
                                    }
                                }
                            }
                        });
                }, 0);
                } else {
                this.viewContainer.clear();
            }
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}