import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { BehaviorSubjectService } from '../behaviorsubject.service';
import { catchError, finalize } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { CredentialessLogin } from 'src/app/common-component/credentialess-login/credentialess-login';

@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  accessToken: any = "";
  apiCount = 0;
  externalDomains = [
    "ipstack.com"
  ]

  constructor(
    private behaviourSubjectService: BehaviorSubjectService,
    private authService: MsalService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    if (sessionStorage.getItem("token")) {
      this.accessToken = sessionStorage.getItem("token");
    }
    else
      this.behaviourSubjectService.currentAccessToken.subscribe((token) => {
        this.accessToken = token;
        sessionStorage.setItem("token", token);
      });
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.apiCount++;
    this.behaviourSubjectService.updateCount(this.apiCount);
    // console.log("Count +++++ "+this.apiCount);
    //return next.handle(httpRequest.clone({ setHeaders: { 'TenantId' : '1' ,'authorization': `Bearer ${this.accessToken}`} }));
    let req = httpRequest;
    if(!this.isExternalUrl(httpRequest.url)) {
      req = httpRequest.clone({ setHeaders: { 'TenantId': '1', 'authorization': `Bearer ${this.accessToken}` } });
    }
    return next.handle(req).pipe(catchError(x => this.handleAuthError(x)), finalize(() => {
      this.apiCount--;
      // console.log("Count ----  "+this.apiCount);
      this.behaviourSubjectService.updateCount(this.apiCount);
    })); //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70

  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    this.spinner.show();
    if (err.status === 401 || err.status === 403) {
      this.behaviourSubjectService.changeHttpErrorValue(err);
      this.spinner.hide();
    }
    if (err.status === 200 || err.status === 201 || err.status === 500) {
      this.spinner.hide();
    }
    // this.spinner.hide();
    return throwError(err);
  }

  private isExternalUrl(url: string) {
    return !!this.externalDomains.find(domain => url.indexOf(domain) !== -1);
  }
}
