import { ReferenceGISTilesData } from 'src/app/constants/common-model';
import { LayerMenuItemData } from '../../map-layer-menu-item/map-layer-menu-item.types';

export default function referenceLayerDataToMenuItems(
  referenceLayerData: ReferenceGISTilesData[]
): LayerMenuItemData[] {
  const result: LayerMenuItemData[] = [];
  const baseIconUrl = '../../assets/images/GPRS_PNG';
  referenceLayerData.forEach((item) => {
    let menuItem = result.find((x) => x.id == (item.fileId ||-1));
    if (!menuItem) {
      menuItem = {
        id: item.fileId || -1,
        jobId: item.mapJobId,
        name: item.fileName || '',
        isEyeVisible: item.setEyeActive || false,
        children: [],
        hasNew: false,

      };
      result.push(menuItem);
    }
    const res: LayerMenuItemData = {
      id: item.mvtId,
      jobId: item.mapJobId,
      name: item.layerName,
      isEyeVisible: item.setEyeActive || false,
      hasNew: false,
      iconUrl: `${baseIconUrl}/${item.layerName?.replace(' ', '')}.png`,
      geomType: item.geomType,
      isLine: item.geomType.toLowerCase() == 'linestring' || item.geomType.toLowerCase() == 'line',
      color: '#008000'
    };

    menuItem.children?.push(res);
  });
  result.sort((a, b) => {
    if (a.name === '' || a.name == null) return 1; // a is empty, so it should go last
    if (b.name === '' || b.name == null) return -1; // b is empty, so it should go last
    return a.name.localeCompare(b.name); // if neither are empty, compare normally
  });
  return result;
}
