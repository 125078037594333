import { Component, OnInit, Inject, OnDestroy, ViewChild, SimpleChanges, ElementRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubjectService } from "../services/behaviorsubject.service";
import { Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';

import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionType,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
  AuthenticationResult,
  AuthError,
} from '@azure/msal-browser';


import { b2cPolicies } from '../auth-config';
import { CommonFunctionService } from '../services/common-function-service';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SettingService } from '../modules/setting/services/setting.service';
import { ConnectionService } from '../modules/sitemapadmin/services/connection-service';
import { MapViewerJobFeaturesService } from '../services/mapviewerJobFeatures/map-viewer-job-features.service';
import { CredentialessLogin } from '../common-component/credentialess-login/credentialess-login';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  icon: string | undefined;
  @Output() startLoginProcess = new EventEmitter<string>();
  @ViewChild('element') element!: ElementRef;

  title = 'GPRS SiteMap';
  isIframe = false;
  loginStatus = false;
  loginDisplay = false;
  appcomponentheader = false;
  private readonly _destroying$ = new Subject<void>();
  username: any;
  userDataObj: any;
  notificationlistObj: any;
  notificationMarkAsRead: any;
  messageObj: any;
  currentRoute: any;
  globalSearchValue: any;
  isFreemiumUserStatusActive: any;
  exportLink: any;
  showRedDot: any;
  tierName: any;

  searchTypes: Array<any> = [
    { num: 0, name: "All" },
    { num: 1, name: "Job Name" },
    { num: 2, name: "Work Order No." },
    { num: 3, name: "Customer Name" },
    { num: 4, name: "Latitude/Longitude" },
    { num: 5, name: "Project Manager" },
    { num: 6, name: "Address" },
    // { num: 7, name: "Site Name" },
    { num: 8, name: "PoId" },
    { num: 9, name: "JobId" },
  ];
  isPrintVisible: boolean = false;
  downloadLinkInterval: any;
  intervalData: any;


  base64textString: string = "";
  base64textStringImage: string = "";
  isBase64StringEmpty: boolean = true;
  exportFileListArr: any[] = [];

  changeExportSubscription: Subscription | null = null;
  mapIntegrationCenterAndZoomLevelData: any = 0;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private spinner: NgxSpinnerService,
    private behaviourSubjectService: BehaviorSubjectService,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private commonService: CommonFunctionService,
    private toastr: ToastrService,
    public iconHeader: Router,
    private location: Location,
    private settingservice: SettingService,
    private restService: ConnectionService,
    private mapService: MapViewerJobFeaturesService,
    private fb : FormBuilder
  ) {
    sessionStorage.setItem("clearExportPop", "firsttime");
    this.icon = iconHeader.url;
    this.downloadLinkInterval = setInterval(this.exportLinkCall, 30000, this.mapService);
    this.mapViewerSearch = this.fb.group({
      selectedSearchType : [0],
      searchStringInput : [''],
      latitudeSearhString : [''],
      longitudeSearhString : [''],
      addressSearhString : [''],
      addressSearhString1 : [''],
      addressSearhString2 : [''],
      addressSearhString3 : [''],
      addressSearhString4 : [''],
      addressSearhString5 : [''],
      addressSearhString6 : [''],
      countrySearhString : [''],
      postcodeSearhString : ['']
    })
  }

  searchInput: boolean = false;
  headerDisplay: Boolean = false;
  searchModalOpen: boolean = true;
  layersModalOpen: boolean = true;
  layerButtonClicked: boolean = false;
  folderButtonClicked: boolean = false;
  mapToolButtonClicked: boolean = false;
  notificationModalOpen: boolean = true;
  showMapIcons: boolean = false;
  exportMenu: boolean = true;
  mapViewerSearch : FormGroup;
  credentialessLogin:CredentialessLogin | null =null;
  isCredentialessLogin:boolean=false;

  ngOnInit(): void {
    if(sessionStorage.getItem('PermissionObj')){
      this.behaviourSubjectService.updareCurrentUserPermissionDetails(sessionStorage.getItem('PermissionObj'));
    }
    this.mapIntegrationCenterAndZoomLevelData = 0 ;
    this.tierName = window.sessionStorage.getItem("tierName");
    if (sessionStorage.getItem("isFreemiumUserStatusActive")) {
      this.isFreemiumUserStatusActive = sessionStorage.getItem("isFreemiumUserStatusActive");
    }
    this.isFreemiumUserStatusActive = true; // default
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
      if (this.location.path().includes('mapviewerjobs')) {
        this.isPrintVisible = true;
      }
      else {
        this.isPrintVisible = false;
      }
    });
    this.isIframe = window !== window.parent && !window.opener;

    this.behaviourSubjectService.loginStatusObservable.subscribe((loginStatus) => {
      this.loginStatus = loginStatus;
      this.setLoginDisplay();
    });
    console.log(this.downloadLinkInterval, "137");

    this.changeExportSubscription = this.behaviourSubjectService.isExportModalObservable.subscribe((isVisible) => {
      console.log('isVisible: cexportMenu ', isVisible); // => true/false
      this.exportMenu = isVisible;
    });

    this.behaviourSubjectService.credentialessLoginObservable.subscribe((credentialessLogin:CredentialessLogin) => {
      this.credentialessLogin = credentialessLogin
      
        if (this.credentialessLogin?.guid && !this.credentialessLogin.userLoggedIn) {
          this.isCredentialessLogin = true;
        } else {
          this.isCredentialessLogin=false;
        }
    });   
  }


  setLoginDisplay() {
    this.loginDisplay = this.loginStatus;

    if (this.loginDisplay) {
      this.appcomponentheader = true;
      this.spinner.show();
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
      }, 2000);
      this.behaviourSubjectService.currentMessage.subscribe(username => this.username = username);
      this.behaviourSubjectService.currentUserDetailsFromDB.subscribe(userData => {
        this.userDataObj = userData;

        this.base64textString = this.userDataObj?.photoBase64String?.trim() ? this.userDataObj.photoBase64String : "";
        if (this.base64textString === "") {
          this.isBase64StringEmpty = true;
        } else {
          this.base64textStringImage = `data:image/png;base64,${this.base64textString}`;
          this.isBase64StringEmpty = false;
        }


        this.getAllNotificationList();
      });


    }
  }

  // login(data?: any) {
  //   // this.spinner.show();
  //   sessionStorage.clear();
  //   sessionStorage.clear();
  //   if (data) {
  //     data = {
  //       state: "angulargprs" //set state parameter (type: string)
  //     };
  //   }
  //   this.startLoginProcess.emit(data);
  // }

  credentialessLoginClick() {
    // clear session storage, set session variable for creditialess login
    console.log("credentialessLoginClick");
    this.startLoginProcess.emit();
  }

  logout() {
    this.spinner.show();
    const allAccountData = this.authService.instance.getAllAccounts();
    if (allAccountData.length > 0) {
      const azureADId = allAccountData[0].localAccountId;
      const emailId = allAccountData[0].username;
      if (azureADId) {
        this.apiService.getUserDetails(azureADId, emailId).subscribe((response: any) => {
          if (response && response.userId != -1) {
            this.commonService.setloggedInOROutApi(response.userId, false).subscribe(result => {
              if (result && result.processingStatus?.status.includes('Success')) {
                localStorage.clear();
                sessionStorage.clear();
                this.authService.logout();                
                this.router.navigate([""]);
                this.spinner.hide();
              }
              else if (result && result.processingStatus?.status.includes("Failure")) {                
                this.logoutDetailsWithErrorToastr();
              }
            }, err => {
              console.log("logout setloggedInOROutApi: ", err);
            });
          }
          else {        
            this.logoutDetailsWithErrorToastr();
          }
          this.spinner.hide();
        }, err => {
          console.log("logout issue: ", err);
          sessionStorage.clear();
          this.authService.logout();
          this.spinner.hide();
          this.router.navigate([""]);
        });
      }
    }
  }

  logoutDetailsWithErrorToastr() {
    const toasterMsg = "Logged out details are not save due to user not found in DB, please connect with technical Team!";
    this.toastr.error(toasterMsg, "", {
      timeOut: 2000
    });
    sessionStorage.clear();
    this.authService.logout();
    this.router.navigate([""]);
    this.spinner.hide();
  }

  searchJobs() {
    if (this.mapViewerSearch.value.searchStringInput || this.mapViewerSearch.value.addressSearhString || (this.mapViewerSearch.value.latitudeSearhString && this.mapViewerSearch.value.longitudeSearhString)) {
      const addressSearchJson = {
        "address-line1" : this.mapViewerSearch.value.addressSearhString,
        "address-line2" : this.mapViewerSearch.value.addressSearhString1,
        "address-line3" : this.mapViewerSearch.value.addressSearhString2,
        "address-level4" : this.mapViewerSearch.value.addressSearhString3,
        "address-level3" : this.mapViewerSearch.value.addressSearhString4,
        "address-level2" : this.mapViewerSearch.value.addressSearhString5,
        "address-level1" : this.mapViewerSearch.value.addressSearhString6,
        "country" : this.mapViewerSearch.value.countrySearhString,
        "postal-code" : this.mapViewerSearch.value.postcodeSearhString
      }
      if (this.currentRoute == '/dashboards') {
        this.behaviourSubjectService.changeHeaderSearchType(this.mapViewerSearch.value.selectedSearchType);
        if (this.mapViewerSearch.value.selectedSearchType == 4) {
          this.behaviourSubjectService.changeHeaderSearchString((this.mapViewerSearch.value.latitudeSearhString + ',' + this.mapViewerSearch.value.longitudeSearhString));
        } else if(this.mapViewerSearch.value.selectedSearchType == 6){
          this.behaviourSubjectService.changeHeaderSearchString(JSON.stringify(addressSearchJson));
        }else{
          this.behaviourSubjectService.changeHeaderSearchString(this.mapViewerSearch.value.searchStringInput);
        }
        this.behaviourSubjectService.changeIsHeaderSearch(true);
      }
      if (this.currentRoute.includes('/mapviewer')) {
        this.behaviourSubjectService.changeHeaderMapSearchType(this.mapViewerSearch.value.selectedSearchType);
        if (this.mapViewerSearch.value.selectedSearchType == 4) {
          this.behaviourSubjectService.changeHeaderMapSearchString((this.mapViewerSearch.value.latitudeSearhString + ',' + this.mapViewerSearch.value.longitudeSearhString));
        } else if(this.mapViewerSearch.value.selectedSearchType == 6){
          this.behaviourSubjectService.changeHeaderMapSearchString(JSON.stringify(addressSearchJson));
        }else{
          this.behaviourSubjectService.searchName(this.mapViewerSearch.value.searchStringInput)
          this.behaviourSubjectService.changeHeaderMapSearchString(this.mapViewerSearch.value.searchStringInput);
        }
        this.behaviourSubjectService.changeIsMapHeaderSearch(true);
      }
    }
    else {
      this.toastr.error("Please Specify Search Query or enter valid Keyword!", "", { timeOut: 3000 });
    }

  }

  onCloseSearch() {
    this.clearSearchData();
  }

  onSearchTypeChange() {
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.changeExportSubscription?.unsubscribe();
  }

  clearSearchData(): void {
    this.mapViewerSearch.reset();
    this.mapViewerSearch.patchValue({
      selectedSearchType : 0
    })
  }

  resetSearchData() {

    this.clearSearchData();
    let currentUrl = this.router.url;
    let element = document.querySelectorAll('.mapboxgl-popup');
    if (element.length > 0)
      Array.prototype.forEach.call(element, function (node) {
        node.parentNode.removeChild(node);
      });
    this.reloadCurrentRoute();
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //   this.router.navigate([currentUrl]);
    // });
    window.location.reload();
  }

  //# get all notification list by user id
  getAllNotificationList() {
    const payload = {
      "request": {
        "pageNumber": 1,
        "pageSize": 50
      },
      "userId": this.userDataObj.userId
    };
    // this.apiService.getAllNotoficationList(payload).subscribe((response) => {
    //   this.notificationlistObj = response;
    //   this.messageObj = this.notificationlistObj.internalMessageNotificationsEntities;
    //  }, (err) => {
    //   console.log(err);
    // });
  }

  //# mark notification as read
  markNotificationAsRead(obj: any) {
    if (!obj.isRead) {
      this.apiService.markNotoficationMessageRead(obj.internalMessageId).subscribe((response) => {
        this.notificationMarkAsRead = response;
        if (this.notificationMarkAsRead.status === "Failure") {
          this.toastr.error("Unable to mark read");
        } else {
          // this.toastr.success("Read successfully");
        }
      }, (err) => {
        console.log(err);
      });
    }
  }
  printCurrentPage() {
    window.print();
  }

  hasRoute(route: string) {
    return this.iconHeader.url.includes(route);
  }

  multipleJobShare() {
    this.behaviourSubjectService.multipleShareJobHeaderIcon('mapviewer');
  }

  logoClick() {
    let userInfo = { userId: this.userDataObj?.userId };
    this.settingservice.getMyPreferencesList(userInfo).subscribe((response: any) => {
      if (response) {
        const homePage = response.find((it: any) => it.name === 'DEFAULT_HOMEPAGE')
        if (homePage?.value === 'DASHBOARD') {
          this.router.navigate(['/dashboards']);
        } else if (homePage?.value === 'MAP_VIEWER') {
          this.router.navigate(['/mapviewer/null/null/null']);
        } else {
          this.router.navigate(['/digitalplanmf']);
        }
      }
      else {
        this.router.navigate(['/dashboards']);
      }
    }, err => {
      console.log("logo click error: ", err);
    });
  }

  onLayerButtonClick() {
    this.layerButtonClicked = !this.layerButtonClicked;
    this.behaviourSubjectService.changeHeaderLayerButtonState(this.layerButtonClicked);
  }

  onFolderButtonClick() {
    this.folderButtonClicked = !this.folderButtonClicked;
    this.behaviourSubjectService.changeHeaderFolderButtonState(this.folderButtonClicked);
  }
  onMapToolButtonClick() {
    this.mapToolButtonClicked = !this.mapToolButtonClicked;
    this.behaviourSubjectService.changeHeaderMapToolButtonState(this.folderButtonClicked);
  }

  exportLinkCall(restService: any) {

    let temp = restService;

    if (restService === 'button') {

      restService = this.mapService;

    }

    if (!this.downloadLinkInterval || temp === 'button') {
      if (sessionStorage.getItem('loginUserId')) {
        restService.tenantId = "1";
        const userId = sessionStorage.getItem('loginUserId');
        restService.getExportLink(userId).subscribe((response: any) => {
          this.exportFileListArr = response;
          var exportJobId = sessionStorage.getItem("exportJobId");
          if (exportJobId == this.exportFileListArr[0]?.importExportJobId && this.exportFileListArr[0]?.isCompleted) {
            let button = document.getElementById("showreddotindicator") as HTMLButtonElement;
            button.click();
          }

        }, (err: any) => {
          this.toastr.error("export interval error: " + err.status, "", { timeOut: 3000 });
          this.spinner.hide();
        });
      }
    }
  }

  downloadFile(importExportJobId: any) {
    const userId = sessionStorage.getItem('loginUserId');
    sessionStorage.setItem('exportJobId', '0');

    this.apiService.markExportFileDownload(userId, importExportJobId).subscribe((response) => {
      this.showRedDot = false;

    }, (err) => {
      console.log(err);
    });

  }

  cancelmodal() {
    sessionStorage.setItem("clearExportPop", "null");

  }

  showRedDott() {
    this.showRedDot = true;

  }
  printModalBackDrop() {
    this.behaviourSubjectService.printShareTrigger('print');
    let cls: any = document.querySelector('.modal-backdrop.fade.show');
    cls.style.display = 'none'
  }

}

