import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
  
export class RenderScaleService {

    getMinLevelForScale() {
        // based on the user render scale from the session return the correct min level to be used
        // for rendering the features on the map.  NOTE the level corresponds the level that is used by mapbox
       var userRenderScale:Number =  Number(sessionStorage.getItem("userRenderingScale"));
       switch (userRenderScale) {
        case 500:
            return 14.12;            
        case 1000:
            return 13.25;
        case 2000:
            return 12.6;
        case 5000:
            return 11.7;
        case 10000:
            return 10.64;
       }
    
       return 13.25; // 1000 ft

    }
}