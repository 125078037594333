export  interface CommitChanges {
  layerId:string | number,
  layerName?:string,
  isEyeVisible?:boolean,
  isLayerEditVisible?:boolean,
  groups:FeatureGroup[],
  mapJobId?:number | string
}

export interface FeatureGroup{
  groupId?:string|number,
  groupName?:string,
  isEyeVisible?:boolean,
  types:FeatureType[],
  features?:Feature[],
  isGroupExpanded?:boolean,
  mapJobId?:number | string
}

export interface FeatureType {
  typeId?:string | number,
  typeName?:string,
  featureTypeId?:string | number,
  featureType?:string,
  isEyeVisible?:boolean,
  features:Feature[],
  isTypeExpanded?:boolean,
  siteJobIds? :[],
  mapJobId?:number | string
}

// export interface FeatureGroupTest{
//   groupId:string|number,
//   groupName?:string,
//   types:FeatureTypeTest[]
// }
// export interface FeatureTypeTest {
//   featureTypeId:string | number,
//   featureType?:string,
//   features:Feature[]
// }

export interface Feature {
  featureId:string | number,
  featureMode:string,
  featureName?:string,
  id?:string | number,
  type?:string ,
  featureGeometryType?:string ,
  featureObj?:any,
  isEyeVisible?:boolean,
  mapJobId ?:string | number,
  isAnnotation?: boolean,
  fileId?:string | number,
  fileName?: string,
  importedByUserId?:string | number,
  geometry?: {
    type? :string ,
    coordinates: null,
  },

  subsiteJobIds? : [],
  properties?: {
    featureId: string | number,
    featureName?: string,
    id?: string | number,
    type?: string,
    featureGeometryType?:string ,
    featureObj?: any,
    isEyeVisible?:boolean,
    mapJobId ?:string | number,
    isAnnotation?: boolean,
    fileId?:string | number,
    fileName?: string,
    importedByUserId?:string | number,
    geometry?: {
      type? :string ,
      coordinates: null,
    },
  subsiteJobIds? : []
  }
}

export const deepClone = (obj:any) =>{
  let clonned;
  if(obj){
    try {
      clonned = JSON.parse(JSON.stringify(obj));
    } catch (error) {
      console.log("deepClone ", error);
    }
  }
  return clonned;
}

export interface ReferenceGISTilesData {
  createdBy: number;
  createdDate: string;
  geomType: string;
  layerName: string;
  mapJobId: number;
  maxZoom: number;
  minZoom: number;
  mvtId: number;
  userId: number;
  xMax: number;
  xMin: number;
  yMax: number;
  yMin: number;
  setEyeActive?: boolean;
  fileId?: string | number;
  fileName?: string;
}
