import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { PortalAPI } from '../constants/api.constant';

import { userTableData } from '../modules/sitemapadmin/constants/manage-users-data';
import { ConnectionService } from '../modules/sitemapadmin/services/connection-service';
import { BehaviorSubjectService } from './behaviorsubject.service';

@Injectable({
  providedIn: 'root'
})

export class CommonFunctionService {
  public options :any =  {};
  myPreferencesObj: any;
  sendDataForm= {};
  userDataObj: any = {};
  displayrecetdoclist : any = [];

  alphaNumericPattern = '^[a-zA-Z0-9_.-]*$';  // alphanumeric pattern
  urlHttpValidation = '((http(s?)):///?(.*)$)';  // URL HTTP or HTTPS pattern
  urlWholeHttpValidation = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  tierId = sessionStorage.getItem('tierId')
  constructor(
    private http: HttpClient,
    private restService: ConnectionService,
    private behaviourSubjectService: BehaviorSubjectService,
    private spinner: NgxSpinnerService,
    private authService: MsalService) { 
        let reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
        reqHeader.append('Access-Control-Allow-Origin', '*');
        reqHeader.append('accept', 'text/plain');
        reqHeader.append('Access-Control-Allow-Headers','Origin, X-Requested-With, Content-Type, Accept');
        reqHeader.append('Access-Control-Allow-Methods','GET,POST,OPTIONS,DELETE,PUT');
        this.options = { headers: reqHeader };
    }



  postPrintData(data?: any): Observable<any> {    
      return this.http.post(PortalAPI.PRINT_FEATURE, data, this.options);   
  }

  searchDataWithDb(searchValue: string, columnName: string, data: any, url: string, restServiceBody?: any): Observable<any> {
    if (!restServiceBody) {
      restServiceBody = {
        "request": {
          "pageNumber": 1,
          "pageSize": 100
        },
        "searchValue": searchValue
      }
    }
    return this.restService.post(url, restServiceBody);
  }

  searchDataWithoutDB(searchValue: string, columnName: string, data: any) {
    return data.filter((item: { [x: string]: string; }) =>
      item[columnName].toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  setloggedInOROutApi(userId: any, isLoggedIn: boolean): Observable<any> {
    const restServiceBody = {
      userId: userId,
      isLoggedin: isLoggedIn,
      time: new Date().toISOString(),
      ip: localStorage.getItem("ip"),
      countryName:localStorage.getItem("countryName"),
      regionName:localStorage.getItem("regionName"),
      city:localStorage.getItem("city"),
      zip:localStorage.getItem("zip"),
      latitude:localStorage.getItem("latitude"),
      longitude:localStorage.getItem("longitude"),
    }
   
    return this.restService.post(PortalAPI.SET_LOGGEDIN_HISTORY_ASYNC, restServiceBody);
  }

  getAccountDetail() {
    return this.authService.instance.getAllAccounts();
  }

  //#region getLoginHistory method is use to get all the Login History through the GetLoggedinUserHistoryAsync api
  getLoginHistory(userId: any): Observable<any> {
    let data: any;
    this.spinner.show();
    this.restService.tenantId = this.authService?.instance?.getAllAccounts()[0]?.tenantId ? this.authService?.instance?.getAllAccounts()[0]?.tenantId : '1';
    const restServiceBody = {
      "request": {
        "pageNumber": 1,
        "pageSize": 1000
      },
      "userId": userId
    }
    return this.restService.post(PortalAPI.GET_LOGGEDIN_HISTORY_ASYNC, restServiceBody);
  }

  closeAllActiveSessions(userId: any): Observable<any> {
    let data: any;
    this.spinner.show();
    this.restService.tenantId = this.authService?.instance?.getAllAccounts()[0]?.tenantId ? this.authService?.instance?.getAllAccounts()[0]?.tenantId : '1';
    const restServiceBody = {
    }
    return this.restService.post(PortalAPI.CLOSE_ALL_ACTIVE_SESSIONS + `/${userId}` , restServiceBody);
  }
  //#endregion


  //#region getAllDocumentsByUserId method is use to get all the file list through the getAllDocumentsByUserId api
  getAllDocumentsByUserId(userId: any): Observable<any> {
    this.restService.tenantId = this.authService.instance.getAllAccounts()[0].tenantId ? this.authService.instance.getAllAccounts()[0].tenantId : '1';
    const restServiceBody = {
      "request": {
        "pageNumber": 1,
        "pageSize": 100
      },
      "userId": userId,
      "searchCategoryId": 0,
      "searchValue": "string"
    }
    return this.restService.post(PortalAPI.SEARCH_DOCUMENTS_FOR_USER, restServiceBody);
  }
  //#endregion

  //#region getFolderChildren method is use to get all the file list through the GetFolderChildren api
  getFolderChildren(folderId: number, userId: number): Observable<any> {
    return this.restService.get(PortalAPI.GET_FOLDER_CHILDREN + `/${folderId}` + `/${userId}`);
  }

  //#check range for lat and long
  checkRangeValueForLatLong(val: any, type: any) {
    if (type == 'lat') {
      if (val >= -90 && val <= 90) { //latitude
        return true;
      } else {
        return false;
      }
    } else {
      if (val >= -180 && val <= 180) { //longitude
        return true;
      } else {
        return false;
      }
    }
  }

  deleteObject(data:any,userId:any){
    let payLoad = {
      "objectIds":[data.id],
      "ObjectTypeId":data.objectTypeId,
      "userId": userId   
    }
    this.options.body=payLoad
    
     
    return this.http.delete(PortalAPI.DELETE_OBJECT,  this.options);    

  }

  deleteMultipleObject(data:any,userId:any,objectTypeId:number){
    let payLoad = {
      "objectIds":data,
      "ObjectTypeId":objectTypeId,
      "userId": userId   
    }
    this.options.body=payLoad
    return this.http.delete(PortalAPI.DELETE_OBJECT,  this.options); 

  }
  
  docInteractionFrRecentAccessFiles(objectId : any, objectTypeId:any, userId:any, userInteractionTypeId: number){
    this.restService.tenantId = this.authService?.instance?.getAllAccounts()[0]?.tenantId ? this.authService?.instance?.getAllAccounts()[0]?.tenantId : '1';
    const param={
    userId : Number(userId),
    objectId : Number(objectId),
    objectTypeId : Number(objectTypeId),
    userInteractionTypeId : Number(userInteractionTypeId)
    }

    
    
    this.http.post(PortalAPI.GET_DOCUMENT_LOGS_JOB +"?userId="+Number(userId)+"&objectId="+objectId+"&objectTypeId="+objectTypeId+"&userInteractionTypeId=" + userInteractionTypeId, this.restService.options).subscribe(response =>{})
    
   //this.restService.post(PortalAPI.BASE_URL + PortalAPI.GET_DOCUMENT_LOGS_JOB,param).subscribe(response =>{console.log("logs", response)})
  //this.http.post(PortalAPI.BASE_URL + "digitalplanroom.gprssitemap.com/api/DigitalPlanRoom/LogObjectInteraction" +"?userId="+Number(userId)+"&objectId="+objectId+"&objectTypeId="+objectTypeId+"&userInteractionTypeId=" + userInteractionTypeId, this.restService.options).subscribe(response =>{console.log("logs", response)})

  }

  fetchFileExtension(fileName:any){
    if(fileName.lastIndexOf('.') == -1){
        return " "
    }else{
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    return extension;
    }
  }

  setGracePeriod(data: any) {    
    return this.restService.post(PortalAPI.SET_GRACE_PERIOD_API, data);
  }

//   getGracePeriod(){     
//     return this.restService.post(PortalAPI.GET_GRACE_PERIOD_API);
//   }
}
