import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ConnectionService } from './services/connection-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PortalAPI } from 'src/app/constants/api.constant';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonFunctionService } from 'src/app/services/common-function-service';
import { environment } from 'src/environments/environment';
import MapboxDraw from "@mapbox/mapbox-gl-draw";

import mapboxgl from 'mapbox-gl';


@Component({
  selector: 'app-sitemapadmin',
  templateUrl: './sitemapadmin.component.html',
  styleUrls: ['./sitemapadmin.component.css']
})
export class SitemapadminComponent implements OnInit {
  map!: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/satellite-streets-v11';
  @Input() submitJobEmitter: boolean = true;
  tabValue = 'Sites';
  searchPlaceHolder = 'Search Site';
  searchValue = "";
  userSelectedData: any[] = [];
  ResetPasswordUserData: any = [];
  UserActivaInactiveData: any = [];
  searchBoxStatus: boolean = true;
  resetPasswrdResponse: any = {};
  addNewRole = { name: "" };
  rolesAdded = false;
  addUserClick: boolean = false;
  selectedJobId: any;
  cadDisable: boolean = true;
  informationModalJobData = {
    jobName: "",
    workorderNumber: "",
    customerName: "",
    siteName: "",
    siteContact: "",
    email: "",
    phone: "",
    geom: "",
    jobDescription: "",
    cadRequired: false,
    assignedByUserId: window.sessionStorage.getItem('loginUserId'),
    assignedToEmailId: "",
    objectTypeId: 1,
    securableObjectPermissionTypeId: 1,
    isSiteMapRestricted: true,
    userId: window.sessionStorage.getItem('loginUserId')
  };
  tenantId: any = "1";
  @ViewChild('myModalClose') modalClose: any;
  wktGeom: any = "";
  draw: any = null;
  drawOptions = {
    displayControlsDefault: false,
    controls: {
      line_string: false,
      polygon: true,
      trash: true
    },
  };
  drawPolygonCordinates: any
  constructor(
    private restService: ConnectionService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private commonService: CommonFunctionService

  ) { }

  ngOnInit(): void {
    // console.log(this.submitJobEmitter)
    this.getTenantID();
    this.createMap();
  }

  ngAfterViewInit() {
    if (sessionStorage.getItem('accessControl') && sessionStorage.getItem('accessControl') === 'user') {
      sessionStorage.removeItem('accessControl');
      const btn = document.getElementById('manage-users-tab') as HTMLButtonElement;
      btn.click();
    }
  }

  getTenantID() {
    const accountDetail = this.commonService.getAccountDetail()[0];
    if (accountDetail.tenantId) {
      this.tenantId = accountDetail.tenantId;
    }
    else {
      this.tenantId = '1';
    }
  }

  createMap() {
    /**** Map Create on Page Load*******/
    (mapboxgl as any).accessToken = environment.mapbox.accessToken;
    this.map = new mapboxgl.Map({
      container: 'jobcreatemap',
      style: this.style,
      center: [-103.5917, 40.6699],
      zoom: 3,
      renderWorldCopies: false,
      // center: this.firstcordinate
    });
    // console.log(this.map)
    const scale = new mapboxgl.ScaleControl({
      unit: 'imperial',
    });
    this.map.addControl(scale, 'bottom-left');
    this.draw = new MapboxDraw(this.drawOptions);
    this.map.addControl(this.draw, 'top-left');

    this.map.on('style.load', () => {

      this.map.on('click', (e) => {
        let coordinates = e.lngLat;
        // this.wktGeom = `POINT(${e.lngLat.lat} ${e.lngLat.lng} 0)`;
        // this.informationModalJobData.geom = this.wktGeom;
        // new mapboxgl.Popup()
        //   .setLngLat(coordinates)
        //   .setHTML('Coordinates ' + coordinates)
        //   .addTo(this.map);
      });

      this.map.on('draw.create', () => {
        let wktGeom: string = '';
        const data = this.draw.getAll();
        if (data.features[data.features.length - 1].geometry.type == "Polygon") {
          this.drawPolygonCordinates = data.features[data.features.length - 1].geometry.coordinates;
          // console.log(this.drawPolygonCordinates, "drawPolygonCordinates",this.drawPolygonCordinates[0].length)
          wktGeom = 'POLYGON((';
          for (var i = 0; i < this.drawPolygonCordinates[0].length; i++) {
            // console.log(i,"i");
            if (i < (this.drawPolygonCordinates[0].length - 1)) {
              console.log(this.drawPolygonCordinates[0][i], "137");
              let p = this.drawPolygonCordinates[0][i];
              // wktGeom = wktGeom + this.drawPolygonCordinates[0][i]  + ',';
              wktGeom = wktGeom + p[0] + ' ' + p[1] + ',';
            } else {
              let p = this.drawPolygonCordinates[0][i];
              // wktGeom = wktGeom + this.drawPolygonCordinates[0][i] + '))';
              wktGeom = wktGeom + p[0] + ' ' + p[1] + '))';
            }
          }
          // this.drawPolygonCordinates.forEach(function (p: any, i: number) {
          //   if (i < data.features[data.features.length - 1].geometry.coordinates.length - 1) {
          //     console.log(p);
          //     wktGeom = wktGeom + p[0] + ' ' + p[1] + ' 0, ';
          //   } else {
          //     wktGeom = wktGeom + p[0] + ' ' + p[1] + ' 0))';
          //   }

          // });
          this.informationModalJobData.geom = wktGeom;
          console.log(this.informationModalJobData.geom, "geom");

        }
      });

    });

  }

  onSubmit() {
    //  console.log('form', this.informationModalJobData, this.tenantId);
    if (this.informationModalJobData.jobName == "" || this.informationModalJobData.email == "" || this.informationModalJobData.assignedToEmailId == "") {
      this.toastr.error("Please fill mandatory fields");
      return;
    }
    this.spinner.show();
    let param = {
      tenantId: this.tenantId
    }
    this.restService.postJob(PortalAPI.CREATE_JOB, this.informationModalJobData, param).subscribe(response => {
      {
        if (response.processingStatus.status == 'Success') {
          this.toastr.success("Job created successfully");
        } else {
          this.toastr.error(response.processingStatus.message);
          this.modalClose.nativeElement.click();
        }
        this.spinner.hide();
      }

    })
    // this.onCancelUserClick();
  }

  // Check which tab is on click
  tabClick(value: string) {
    this.searchValue = "";


    if (value === 'sites') {
      this.tabValue = 'Sites';
      this.searchPlaceHolder = 'Search Site';
      this.searchBoxStatus = true;
    }
    if (value === 'jobs') {
      this.tabValue = 'Jobs';
      this.searchPlaceHolder = 'Search Job';
      this.searchBoxStatus = true;
    }
    if (value === 'users') {
      this.tabValue = 'Users';
      this.searchPlaceHolder = 'Search User';
      this.searchBoxStatus = true;
    }
    if (value === 'roles') {
      this.tabValue = 'Roles';
      this.searchPlaceHolder = 'Search Role';
      this.searchBoxStatus = true;
    }
    if (value === 'board') {
      this.tabValue = 'Content Management';
      this.searchPlaceHolder = 'Search Board';
      this.searchBoxStatus = false;
    }
    if (value === 'help') {
      this.tabValue = 'Help';
      this.searchPlaceHolder = 'Search Help';
      this.searchBoxStatus = false;
    }
    if (value === 'miscellaneous') {
      this.tabValue = 'Content Management';
      this.searchPlaceHolder = 'Search Miscellaneous';
      this.searchBoxStatus = true;
    }
    if (this.searchBoxStatus) {
      let ele = document.getElementById("txtSearch") as HTMLInputElement;
      ele.value = "";
    }
  }

  //#region onSearchChange method is use for get a input search value on every type or input
  onSearchChange(value: string, type: any) {
    if (!value && type === 'blur') {
      this.searchValue = value;
    }
    else if (type === 'enter') {
      if (value) {
        this.searchValue = value;
      }
      else {
        this.toastr.error("Please specify search query or enter valid Keyword");
      }
    }
  }
  //#endregion

  //# checked user data for reset password
  getCheckedUserData(event: any) {
    this.ResetPasswordUserData = [];
    this.UserActivaInactiveData = [];
    event.forEach((obj: any) => {
      // console.log(obj);
      let dummy = {
        "email": obj.data.email,
        "userADId": obj.data.azureADId,
        "name": obj.data.name
      };
      let UserID = {
        "userId": obj.data.userId
      }
      this.ResetPasswordUserData.push(dummy);
      this.UserActivaInactiveData.push(obj.data.userId)
    });

  }

  //# reset password for selected user by admin 
  ResetPasswordforSelectedUser() {
    if (confirm("Do you want to reset password for user(s)?")) {
      this.restService.ResetUserPasswordByAdmin(this.ResetPasswordUserData).subscribe((response) => {
        this.resetPasswrdResponse = response;
        this.spinner.hide();
        if (this.resetPasswrdResponse.processingStatus.status === "Failure") {
          this.toastr.error("Unable to reset password");
        } else {
          this.toastr.success("Reset password successfully");

        }
      });
    }
  }



  //# user account active & inactive for selected user by admin 
  UserAcountActiveInactiveFrSelectedUser(status: any) {
    let isActive: boolean = false;
    if (status == 'active') {
      isActive = true;
    } else {
      isActive = false;
    }
    if (confirm("Do you want to " + status + " user")) {
      let payLoad = {
        "userId": this.UserActivaInactiveData,
        "isActive": isActive
      }
      this.restService.ActiveInactiveUsersByAdmin(payLoad).subscribe((response) => {
        this.resetPasswrdResponse = response;
        this.spinner.hide();
        if (this.resetPasswrdResponse.processingStatus.status === "Failure") {
          this.toastr.error("Unable to " + status + " users");
        } else {
          this.toastr.success("User " + status + " successfully");

        }
      });
    }
  }

  //#region onSubmitNewRole method is use for add new role in db
  onSubmitNewRole(btnId: string) {
    if (this.addNewRole.name.trim()) {
      this.restService.tenantId = '1';
      this.restService.post(`${PortalAPI.CREATE_NEW_ROLE}/${this.addNewRole.name}`, {}).subscribe(response => {
        if (response && response?.processingStatus?.status === "Success") {
          this.rolesAdded = true;
          // const button = document.getElementById(btnId) as HTMLElement;
          // button.setAttribute('data-bs-dismiss', 'modal');
          (<HTMLFormElement>document.getElementById("form")).reset();
          this.addNewRole.name = '';
          // button.click();
          this.toastr.success("New role has been added successfully", "", {
            timeOut: 3000
          });
        }
        else {
          this.toastr.error(response.processingStatus.message, "", {
            timeOut: 3000
          });
          if (response.processingStatus.message === "Role Name Already Exists") {
            (<HTMLFormElement>document.getElementById("form")).reset();
            this.addNewRole.name = '';
          }
        }
      })
    }
  }
  //#endregion

  //#region onCancelRoleClick method is used to perform an action on cancel user modal
  onCancelRoleClick() {
    (<HTMLFormElement>document.getElementById("form")).reset();
  }
  //#endregion


  //#region onCancelUserClick method is used to perform an action on cancel user modal
  onCancelUserClick(event?: any) {
    this.addUserClick = false;
  }
  //#endregion

  //#region onAddUserClick method is used to perform an action on add user click
  onAddUserClick() {
    this.addUserClick = true;
  }
  //#endregion

  //# get checked job data
  getCheckedJobData(event: any) {
    this.selectedJobId = event;
  }

  //# redirect to mapviewer by job id
  redirecToMapViewer() {
    this.router.navigate([`/mapviewerjobs/${this.selectedJobId?.jobId}`]);
  }

}
