<!---BODY HERRE avdhesh--->

<div class="row" *ngIf="loginDisplay">
  <div class="col-lg-12">
    <div class="page-title border-bottom">
      <h1>Dashboard</h1>
    </div>
  </div>
</div>
<div class="row" *ngIf="loginDisplay">
  <div class="col-lg-6 dashboard-table-container">
    <app-recent-jobs [loginStatus]="loginDisplay"></app-recent-jobs>
    <app-recent-documents></app-recent-documents>
  </div>
  <div class="col-lg-6 google1 custom-order-1" *ngIf="loginDisplay">
    <app-map-integration></app-map-integration>
  </div>
</div>

<!-- Message Board Starts-->
<div class="accordion" id="messageBoard" *ngIf="loginDisplay">
  <div class="accordion-item page-message">
    <h2 class="accordion-header" id="headingOne">
      <button
        *ngIf="userDataObj.tierName != 'Personal' || userDataObj.role !='Client'"
        class="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#messageBoardOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        Message Board
      </button>
      <button *ngIf="userDataObj.tierName == 'Personal' && userDataObj.role =='Client'"> Message Board</button>
    </h2>

    <div
      id= "messageBoardOne"
      class="accordion-collapse collapse show"
      aria-labelledby="headingOne"
      data-bs-parent="#messageBoard"
    >
      <div class="accordion-body p-0">
        <app-message-board></app-message-board>
      </div>
    </div>
  </div>
</div>
<!-- Message Board End-->

<!-- Table Modal -->
<div
  class="modal fade"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <form aria-required="true">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title custom-modal-title"
            id="staticBackdropLabel"
            *ngIf="isJobShared"
          >
            Share Job
          </h5>
          <h5
            class="modal-title custom-modal-title"
            id="staticBackdropLabel"
            *ngIf="isDocumentShared"
          >
            Share File
          </h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="closeShareObjModal()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div
                class="col-lg-12 shared-content"
                *ngIf="objSharedUserList.length != 0"
              >
                <div class="parent-wrapper">
                  <h3>SHARED WITH</h3>
                  <ul>
                    <li
                      *ngFor="
                        let userDetail of objSharedUserList;
                        let i = index
                      "
                    >
                      {{ userDetail.displayName }}
                      ({{ userDetail.emailId }}) - {{ userDetail.accessName }}
                      <span
                        class="fa fa-edit pointer"
                        data-bs-dismiss="modal"
                        routerLink="/collaboration"
                      ></span>
                    </li>
                  </ul>
                  <!-- <p class="mb-0" *ngFor="let userDetail of objSharedUserList; let i = index;">{{userDetail.displayName}}
                  ({{userDetail.emailId}}) - {{userDetail.accessName}}</p> -->
                  <!-- <p>Stacy Kohler (stacy.kohler@gprsinc.com - Read</p> -->
                </div>
              </div>
              <div class="list-showing-parent mb-2">
                <label
                  for="exampleFormControlInput1"
                  class="form-label custom-label"
                  >Email Address
                  <span class="custom-form-label-star">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                  [formControl]="emailFormControl"
                  placeholder="name@example.com"
                  autocomplete="off"
                />
                <ul
                  class="list-showing"
                  [ngClass]="{
                    'fetched-emails-diplay': shouldFetchedEmailsBeDisplayed
                  }"
                >
                  <li
                    *ngFor="let item of fetchedEmails; let i = index"
                    (click)="selectEmailFromFetchedEmails(item)"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-12">
              <p class="custom-label">Access Control</p>
            </div>
            <div class="col-lg-6">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="1"
                  [formControl]="accessFormControl"
                  name="accessFormControl"
                  id="flexRadioDefault1"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  View
                </label>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-check" [ngClass]="{ 'disabled': loginUserRoleId === '3'}" >
                <input
                  class="form-check-input"
                  type="radio"
                  value="2"
                  [formControl]="accessFormControl"
                  name="accessFormControl"
                  id="flexRadioDefault2"
                  [attr.disabled]="true"
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  Edit
                </label>
              </div>
            </div>
            <!--  [attr.disabled]="true" <div class="col-lg-6">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3">
                <label class="form-check-label" for="flexRadioDefault3">
                  Admin
                </label>
              </div>
            </div> -->
            <!-- <div class="col-lg-6">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4">
                <label class="form-check-label" for="flexRadioDefault4">
                  Geometry Editing
                </label>
              </div>
            </div> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            (click)="closeShareObjModal()"
          >
            Cancel
          </button>
          <button
            (click)="shareObj()"
            type="button"
            class="btn btn-success"
            data-bs-dismiss="modal"
            [disabled]="emailFormControl.invalid"
          >
            Share
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Map Modal -->
<div
  class="modal fade"
  id="google-map"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="staticBackdropLabel">
          Site 84 - Walmart Dunwoody
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="mb-3">
              <select class="form-select" aria-label="Default select example">
                <option selected>Select Job*</option>
                <option value="1">WA 354512 Ashford Dr</option>
              </select>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="table-responsive">
              <table
                class="table table-hover table-bordered table-striped custom-table"
              >
                <tbody>
                  <tr>
                    <td>Location</td>
                    <td>33.93550, -84.34078</td>
                  </tr>
                  <tr>
                    <td>Site Contact</td>
                    <td>Karl Newman</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>abczoo.gmail.com</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>990-223-8765</td>
                  </tr>
                  <tr>
                    <td>Created on</td>
                    <td>11/14/2021 11:47:13 AM</td>
                  </tr>
                  <tr>
                    <td>Added link</td>
                    <td>
                      <a href="#"
                        >https://my.matterport.com /show/?m=uRGXgoiYk9f&
                        help=1</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Share
        </button>
        <button type="button" class="btn btn-success">View Job</button>
      </div>
    </div>
  </div>
</div>

<!-- Table Modal Info -->
<div
  class="modal fade"
  id="table-edit"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="staticBackdropLabel">
          Job Log
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-borderless custom-table bgnone">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Action</th>
                    <th scope="col">Email</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="logData.length > 0">
                    <tr *ngFor="let obj of logData; let i = index">
                      <td>{{ obj.date| date : "MM/dd/y" }}</td>
                      <td>{{ obj.action }}</td>
                      <td>{{ obj.email }}</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="logData.length == 0">
                    <tr>
                      <td colspan="3">No Job Log Found</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!---Mapbox Map surinder--->
<div class="map" id="map" class="match-parent"></div>
