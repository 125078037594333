import { ICommonTableHeader } from "./common-table-data";

export interface IJobList {
    jobId: number;
    name: string;
    description: string;
    latitude: string;
    longitude: string;
    customerName: string;
    workOrderNumber: string;
    projectManager: string;
    LastModified: string;
    jobContact: string;
    email: string;
    phone: string;
    createdOn: string;
    addedlink: string;
    address: string;
    logStatus: boolean;
    jobLogs: [LogEntity: IJobLogEntity];
    action:any;
    checkBox:boolean
  }
  
  export interface IJobLogEntity {
    date: string;
    action: string;
    user: string;
  }
  

  export interface IJobDetailForm {
      customerName: string,
      siteName: string,
      contactPerson: string,
      email: string,
      phoneNumber: string,
      CAD: boolean,
      description: string,
  }
  
  export class jobAssignedUserTableData {
    tblHeader:ICommonTableHeader[] = [
      {
        columnName: 'displayName',
        displayName: 'User Name',
        class: '',
        dropDown: false,
        icon: true,
        visible: true,
        span: true,
        link: false,
        button: false,
        text: false,
      },
      {
        columnName: 'email',
        displayName: 'Email Address',
        class: '',
        dropDown: false,
        icon: false,
        visible: true,
        span: true,
        link: false,
        button: false,
        text: false,
      },
      {
        columnName: 'objectPermisionTypeName',
        displayName: 'Access Type',
        class: '',
        dropDown: false,
        icon: false,
        visible: true,
        span: true,
        link: false,
        button: false,
        text: false,
      },
      {
        columnName: 'action',
        displayName: 'Action',
        class: '',
        dropDown: false,
        icon: true,
        visible: false,
        span: false,
        link: false,
        button: true,
        text: false,
      },
    ];
  
    private actionButton = [
      {
        icon: 'fa-trash',
        tooltip: 'Edit User Information',
        dataBsToggle: 'modal',
        dataBsTarget: '#deleteAssignUser',
      },
    ];
  }

  export class jobListTableData {
    tblHeader:ICommonTableHeader[] = [
      {
        columnName: 'checkBox',
        displayName: 'Select Item',
        class: '',
        dropDown: false,
        icon: false,
        visible: false,
        span: false,
        link: false,
        button: false,
        text: false,
        checkBox: true,
      },
      {
        columnName: 'name',
        displayName: 'Name',
        class: '',
        dropDown: false,
        icon: false,
        visible: true,
        span: true,
        link: false,
        button: false,
        text: false,
        sort: true
      },
      {
        columnName: 'workOrderNumber',
        displayName: 'WO Number',
        class: '',
        dropDown: false,
        icon: false,
        visible: true,
        span: true,
        link: false,
        button: false,
        text: false,
        sort: true
      },
    ]
  }