import { FeatureGroup } from "src/app/constants/common-model";
import getTypeIndexFromGroups from "./get-type-index-from-groups.function";

export default function deleteTypeFromGroups(groups: FeatureGroup[], typeId: number) {
  const {groupIndex, typeIndex} = getTypeIndexFromGroups(groups, typeId);

  if (typeIndex === -1 || groupIndex === -1) {
    return;
  }

  const group = groups[groupIndex];

  group.types.splice(typeIndex, 1);

  // If no types left in the group, delete the group
  if (group.types.length === 0) {
    groups.splice(groupIndex, 1);
  }
}
