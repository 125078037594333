<app-header (startLoginProcess)="login($event)"></app-header>

<div class="container-fluid">
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <!-- <router-outlet *ngIf="!isIframe"></router-outlet> -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
  
  
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-fall">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<!-- Mapbox Map Inset in Dashboard -->
<div class="map" id="map" class="match-parent"></div>

<!--Idle Time Modal-->
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#idleModal" hidden id="btnModalIdle" aria-labelledby="btnModalIdle" ></button>
<button type="button" class="btn btn-primary" hidden id="btnTimer" (click)="startTimer()" aria-label="Timer button"></button>
<div class="modal fade" id="idleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="idleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="idleModalLabel"><span>Session Expired</span></h5>
        <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close" 
        (click)="resetIdle() ">
        </button>
      </div>
      <div class="modal-body">
        {{sessionExpire}} <span>{{timeLeft}} Seconds left to auto logout.</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="resetIdle()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<!--unauthorized Modal-->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#unauthorizedAccess" hidden id="btnModalunauthorized" aria-labelledby="btnModalunauthorized"></button>
<div class="modal fade" id="unauthorizedAccess" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="unauthorizedAccessLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-md">
    <div class="modal-content">
      <div class="modal-header bg-danger">
        <h5 class="modal-title" id="unauthorizedAccessLabel"><span>Unauthorized Access</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="onCancelModal()">
        </button>
      </div>
      <div class="modal-body">
        unauthorized:Due to token expire access is denied!
        <!-- You are already logged in at other browser or any other location, please logged out first! -->
      </div>
    </div>
  </div>
</div>

<!--Already Login Modal-->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#alreadyLogin" hidden id="btnModalLogin" aria-labelledby="btnModalLogin"></button>
<div class="modal fade" id="alreadyLogin" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="alreadyLoginLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-md">
    <div class="modal-content">
      <div class="modal-header bg-danger">
        <h5 class="modal-title" id="alreadyLoginLabel"><span>Duplicate Login!</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="onCancelModal()">
        </button>
      </div>
      <div class="modal-body">
        This email address already has an open session. Please log out before opening another session.
        <!-- You are already logged in at other browser or any other location, please logged out first! -->
        <p style="margin-top: 20px;">Please click the button below to close all active sessions across all your devices and then login again.<br/>
          <button style="margin-top: 20px;" type="button" class="btn-close" aria-label="Close active sessions" 
          (click)="closeActiveSessions() ">Close all active sessions
          </button></p>
      </div>
    </div>
  </div>
</div>

<!--Profile fill Modal-->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#profileModal" hidden id="btnModalProfile" aria-labelledby="btnModalProfile"></button>
<div class="modal fade" id="profileModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="profileModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="profileModalLabel"><span>Profile Incomplete</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        Please complete your <a routerLink="/myprofile" data-bs-dismiss="modal" aria-label="Close">profile</a> details! 
      </div>
    </div>
  </div>
</div>


<!--Terms and conditions Modal -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#tncModal" hidden id="btnTncModal" aria-labelledby="btnTncModal"></button>
<div class="modal fade" id="tncModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="tncModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="tncModalTitle" ><span>Terms and conditions</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngIf="tncUrl">
          <pdf-viewer
          [src]="tncUrl"
          [rotation]="0"
          [fit-to-page]="true"
          [original-size]="true"
          [show-all]="true"
          style="display: block; min-height: 500px;"
        ></pdf-viewer>
        </div>
        <div *ngIf="!tncUrl">Loading...</div>
      </div>
      <div class="modal-footer">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="tnc_accept_checkbox" (change)="onAcceptTnC($event)" data-bs-dismiss="modal">
          <label class="form-check-label" for="tnc_accept_checkbox">
            Accept
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
