import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { PortalAPI,} from '../constants/api.constant';
import { FeatureTypeStyleMapping } from '../constants/featureTypeIdStylingMapping';
import { ConnectionService } from '../modules/sitemapadmin/services/connection-service';
import { BehaviorSubjectService } from '../services/behaviorsubject.service';
import { MapViewerJobFeaturesService } from '../services/mapviewerJobFeatures/map-viewer-job-features.service';
import * as mapboxgl from 'mapbox-gl';
import { Observable } from 'rxjs/internal/Observable';
import { CredentialessLogin } from './credentialess-login/credentialess-login';
import { environment } from 'src/environments/environment';
import { IRasterImage } from './common-map-service.types';
import { RenderScaleService } from './render-scale-service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CommonMapService {

    //qaEnvValSubscription: Subscription;
    featureGroupMap: any = new Map();
    featureGroupToggleStateMap: any = new Map();
    featureGroupTypeToggleStateMap: any = new Map();
    featureGroupTypeFeatureNameToggleStateMap: any = new Map();
    isImportedLayerCanEdit: boolean = false;
    loginUserId: any;
    isBasemapToggle: any;
    isQAenv: string = '';
    tilesUrl = '';
    imageServerUrl = '';
    rasterTileServerUrl = '';
    screenWidth: any;
    pointLayerIds: any;
    lineLayerIds: any;
    map: any;
    features: any[] = [];
    featureTypeStyleMap: any;
    isCredentialessLogin:boolean=false;
    credentialessLogin:CredentialessLogin | null =null;

    constructor(
      private restService: ConnectionService,
      private behaviourSubjectService: BehaviorSubjectService,
      private fb: FormBuilder,
      private mapViewerJobFeatureService: MapViewerJobFeaturesService,
      private renderScaleService:RenderScaleService,
      private spinner: NgxSpinnerService) {
        this.tilesUrl = PortalAPI.TILES_MVT_BASE_URL;
        this.imageServerUrl = PortalAPI.TILES_MVT_IMAGE_SERVER_URL;
        this.rasterTileServerUrl = PortalAPI.RASTER_TILES_SERVER;

        this.behaviourSubjectService.credentialessLoginObservable.subscribe((credentialessLogin:CredentialessLogin) => {
          this.credentialessLogin = credentialessLogin;

            if (this.credentialessLogin?.guid) {
              this.isCredentialessLogin = true;
            } else {
              this.isCredentialessLogin=false;
            }
        });
    }

    //getExternalContent method
    getExternalContent(jobId: number, userId: any) {
      var param = jobId + "/" + userId;
      if ((this.isCredentialessLogin && !this.credentialessLogin?.userLoggedIn) || (this.isCredentialessLogin && !this.credentialessLogin?.userHasAccessToJob)) {
        param = jobId + "/" + this.credentialessLogin?.guid;
        return this.restService.get(PortalAPI.GET_EXTERNAL_CONENTS_BY_JOBID_GUID, param);
      } else {
        return this.restService.get(PortalAPI.GET_EXTERNAL_CONENTS_BY_JOBID, param);
      }
    }

    //getSiteContent method
    getSiteContent(params:any) {
      var param = params;
      if ((this.isCredentialessLogin && !this.credentialessLogin?.userLoggedIn) || (this.isCredentialessLogin && !this.credentialessLogin?.userHasAccessToJob)) {
        return this.restService.post(PortalAPI.GET_SITE_COMPONENT_GUID, param);
      } else {
        return this.restService.post(PortalAPI.GET_SITE_COMPONENT, param);
      }
    }

    //getSiteContent New Structure method
    getSiteContentNewStructure(params: any):Observable<any> {
      const param = params;
      return this.restService.post(PortalAPI.GET_SITE_COMPONENT_NEW_STRUCTURE, param);
    }

    //#region setValuesInRequiredMaps method is used set the data for toggle and map binding
    setValuesInRequiredMaps(data: any) {
        let featuresData = data.features;
        for (let i = 0; i < featuresData.length; i++) {
            let feature: any = {
                type: 'Feature',
                properties: {
                    featureGroupId: featuresData[i].featureGroupId,
                    featureGroup: featuresData[i].featureGroup,
                    featureTypeId: featuresData[i].featureTypeId,
                    featureType: featuresData[i].featureType,
                    featureId: featuresData[i].featureId.toString(),
                    featureName: featuresData[i].feature,
                    isAnnotation: featuresData[i].isAnnotation,
                    fileId: featuresData[i].fileId,
                    fileName: featuresData[i].fileName,
                    importedByUserId: featuresData[i].importedByUserId,
                    featureGeometryType:
                        featuresData[i].geometry.type === 'LineString'
                            ? 'LineString'
                            : 'Point',
                },
                geometry: {
                    type:
                        featuresData[i].geometry.type === 'LineString'
                            ? 'LineString'
                            : 'Point',
                    coordinates: null,
                },
            };

      if (this.featureGroupMap.has(data.layerName)) {
        this.addMainFeatureData(data, feature, 'hasValue');
        if (feature.properties?.importedByUserId && (feature.properties?.importedByUserId?.toString() === this.loginUserId?.toString())) {
          this.isImportedLayerCanEdit = true;
        }
      } else {
        this.addMainFeatureData(data, feature, null);
      }
    }
    return [this.featureGroupMap, this.featureGroupToggleStateMap, this.featureGroupTypeToggleStateMap, this.featureGroupTypeFeatureNameToggleStateMap];
  }
  //#endregion

  //#region addMainFeatureData method is a part of setValuesInRequiredMaps method
  addMainFeatureData(data: any, feature: any, isValue: any) {
    let featureTypeMap = new Map();
    let featurePropertyArray: any[] = [];
    if (isValue === 'hasValue') {
      featureTypeMap = this.featureGroupMap.get(data.layerName);
      if (featureTypeMap.has(feature.properties.featureGroup)) {
        featureTypeMap = this.featureGroupMap
          .get(data.layerName)
          .get(feature.properties.featureGroup);
        if (featureTypeMap.has(feature.properties.featureType)) {
          featurePropertyArray = [] = featureTypeMap.get(
            feature.properties.featureType
          );
          if (!featureTypeMap.get(feature.properties.featureType).find((it: any) => it.featureName === feature.properties.featureName)) {
            let featureTypeArr = this.featureGroupMap.get(data.layerName);
            featurePropertyArray.push(feature.properties);
            featureTypeMap.set(
              feature.properties.featureType,
              featurePropertyArray
            );
            featureTypeArr.set(feature.properties.featureGroup, featureTypeMap);
            this.featureGroupMap.set(data.layerName, featureTypeArr);

            //for featureGroupTypeFeatureNameToggleStateMap set true for toggle
            let temp = new Map();
            temp = this.featureGroupTypeFeatureNameToggleStateMap.get(
              data.layerName
            );
            let toggleGroup = new Map();
            toggleGroup = this.featureGroupTypeFeatureNameToggleStateMap
              .get(data.layerName)
              .get(feature.properties.featureGroup);
            let featureNameMap = new Map();
            featureNameMap = toggleGroup.get(feature.properties.featureType);
            featureNameMap.set(feature.properties.featureName, true);
            toggleGroup.set(feature.properties.featureType, featureNameMap);
            temp.set(feature.properties.featureGroup, toggleGroup);
            this.featureGroupTypeFeatureNameToggleStateMap.set(
              data.layerName,
              temp
            );
          }
        } else {
          featurePropertyArray = [];
          let featureTypeArr = this.featureGroupMap.get(data.layerName);
          featurePropertyArray.push(feature.properties);
          featureTypeMap.set(
            feature.properties.featureType,
            featurePropertyArray
          );
          featureTypeArr.set(feature.properties.featureGroup, featureTypeMap);
          this.featureGroupMap.set(data.layerName, featureTypeArr);

          //for featureGroupTypeToggleStateMap set true for toggle
          let temp = new Map();
          let toggleGroup = this.featureGroupTypeToggleStateMap.get(
            data.layerName
          );
          temp = this.featureGroupTypeToggleStateMap
            .get(data.layerName)
            .get(feature.properties.featureGroup);
          temp.set(feature.properties.featureType, true);
          toggleGroup.set(feature.properties.featureGroup, temp);
          this.featureGroupTypeToggleStateMap.set(data.layerName, toggleGroup);

          //for featureGroupTypeFeatureNameToggleStateMap set true for toggle
          temp = new Map();
          temp = this.featureGroupTypeFeatureNameToggleStateMap.get(
            data.layerName
          );
          toggleGroup = new Map();
          toggleGroup = this.featureGroupTypeFeatureNameToggleStateMap
            .get(data.layerName)
            .get(feature.properties.featureGroup);
          let featureNameMap = new Map();
          featureNameMap.set(feature.properties.featureName, true);
          toggleGroup.set(feature.properties.featureType, featureNameMap);
          temp.set(feature.properties.featureGroup, toggleGroup);
          this.featureGroupTypeFeatureNameToggleStateMap.set(
            data.layerName,
            temp
          );
        }
      } else {
        featurePropertyArray = [];
        let featureTypeArr = new Map();
        featurePropertyArray.push(feature.properties);
        featureTypeArr.set(
          feature.properties.featureType,
          featurePropertyArray
        );
        featureTypeMap.set(feature.properties.featureGroup, featureTypeArr);
        this.featureGroupMap.set(data.layerName, featureTypeMap);

        //for featureGroupToggleStateMap set true fpr toggle
        let temp = this.featureGroupToggleStateMap.get(data.layerName);
        temp.set(feature.properties.featureGroup, true);
        this.featureGroupToggleStateMap.set(data.layerName, temp);

        //for featureGroupTypeToggleStateMap set true for toggle
        temp = new Map();
        temp = this.featureGroupTypeToggleStateMap.get(data.layerName);
        let toggleGroup = new Map();
        toggleGroup.set(feature.properties.featureType, true);
        temp.set(feature.properties.featureGroup, toggleGroup);
        this.featureGroupTypeToggleStateMap.set(data.layerName, temp);

        //for featureGroupTypeFeatureNameToggleStateMap set true for toggle
        temp = new Map();
        temp = this.featureGroupTypeFeatureNameToggleStateMap.get(
          data.layerName
        );
        toggleGroup = new Map();
        let featureNameMap = new Map();
        featureNameMap.set(feature.properties.featureName, true);
        toggleGroup.set(feature.properties.featureType, featureNameMap);
        temp.set(feature.properties.featureGroup, toggleGroup);
        this.featureGroupTypeFeatureNameToggleStateMap.set(
          data.layerName,
          temp
        );
      }
    } else {
      let featureTypeArr = new Map();
      featurePropertyArray.push(feature.properties);
      featureTypeArr.set(feature.properties.featureType, featurePropertyArray);
      featureTypeMap.set(feature.properties.featureGroup, featureTypeArr);
      this.featureGroupMap.set(data.layerName, featureTypeMap);

      //for featureGroupToggleStateMap set true for toggle
      let temp = new Map();
      temp.set(feature.properties.featureGroup, true);
      this.featureGroupToggleStateMap.set(data.layerName, temp);

      //for featureGroupTypeToggleStateMap set true for toggle
      temp = new Map();
      let toggleGroup = new Map();
      temp.set(feature.properties.featureType, true);
      toggleGroup.set(feature.properties.featureGroup, temp);
      this.featureGroupTypeToggleStateMap.set(data.layerName, toggleGroup);

      //for featureGroupTypeFeatureNameToggleStateMap set true for toggle
      temp = new Map();
      toggleGroup = new Map();
      let featureNameMap = new Map();
      featureNameMap.set(feature.properties.featureName, true);
      temp.set(feature.properties.featureType, featureNameMap);
      toggleGroup.set(feature.properties.featureGroup, temp);
      this.featureGroupTypeFeatureNameToggleStateMap.set(
        data.layerName,
        toggleGroup
      );
    }
  }
  //#endregion

  //#region refreshMapStyle
  refreshMapStyle(map: any) {
    this.isBasemapToggle = true;

    map.setStyle('')

    map.setStyle('mapbox://styles/mapbox/satellite-v9');
  }
  //#endregion

  //setVectorTiles method is used to Add the source for all layers
  setVectorTiles(map: any) {
    for (const appLayerId of [1, 2, 3]) {
      let currentLineSourceId = `${appLayerId}-active-lines-annotations-by-layerid`;
      let currentPointSourceId = `${appLayerId}-active-points-annotations-by-layerid`;
      let currentPhotoSourceId = `${appLayerId}-active-photos-annotations-by-layerid`;
      this.setSourceForVectorTiles(map, currentLineSourceId, appLayerId, 'line');
      this.setSourceForVectorTiles(map, currentPointSourceId, appLayerId, 'point');
      this.setSourceForVectorTiles(map, currentPhotoSourceId, appLayerId, 'external');
    }
  };

  //setSourceForVectorTiles method is used to Add the source for all line and point for features
  // setSourceForVectorTiles(map: any, sourceId: any, appLayerId: any, tileType: any, featureGroup: string[], zoomedJobId: any, hiddenFeature: any, pointLayerIds: any, lineLayerIds: any) {
  setSourceForVectorTiles(map: any, sourceId: any, mapLayerId: any, tileType: any,) {
    if(map.getSource(sourceId) === undefined) {
      map.addSource(sourceId, {
        type: 'vector',
        tiles: [PortalAPI.MVT_GET_ACTIVE_JOB_FEATURES_BY_LAYER_ID + `/${tileType}/${mapLayerId}/{z}/{x}/{y}.mvt`],
        promoteId: 'featureId',
        // minzoom: 13.25,
        // maxzoom: 23
      });
    }

    // this.loadMVT(appLayerId, tileType, featureGroup, this.tilesUrl, map, sourceId, zoomedJobId, hiddenFeature, pointLayerIds, lineLayerIds);
  }

  setSourceForVectorTilesV2(map: any, sourceId: any, mapLayerId: any, tileType: any, isUpdateFeatureDesc?:boolean) {
    let url;
    if (map.getSource(sourceId) === undefined) {

      if (isUpdateFeatureDesc) {
        url = PortalAPI.MVT_GET_ACTIVE_JOB_FEATURES_BY_LAYER_ID + `/${tileType}/${mapLayerId}/{z}/{x}/{y}.mvt?dt=${Date.now()}`;
      } else {
        url = PortalAPI.MVT_GET_ACTIVE_JOB_FEATURES_BY_LAYER_ID + `/${tileType}/${mapLayerId}/{z}/{x}/{y}.mvt`
      }
      map.addSource(sourceId, {
        type: 'vector',
        tiles: [url],
        promoteId: 'featureId'
      });
    }
    this.loadMVTLayerV2(map, mapLayerId, tileType);
    // this.loadMVT(appLayerId, tileType, featureGroup, this.tilesUrl, map, sourceId, zoomedJobId, hiddenFeature, pointLayerIds, lineLayerIds);
  }

  resetSourceForLayer(map: mapboxgl.Map, layerId: number)
  {
    for (var tileType of ['line', 'point']) {
      let sourceId = `${layerId}-${tileType}`;
      this.reSetSourceForVectorTilesV2(map, sourceId, layerId, tileType);
    }
  }

  reSetSourceForVectorTilesV2(map: any, sourceId: any, mapLayerId: any, tileType: any, isUpdateFeatureDesc?:boolean) {
    let url;
    if (map.getSource(sourceId) !== undefined) {
      url = PortalAPI.MVT_GET_ACTIVE_JOB_FEATURES_BY_LAYER_ID + `/${tileType}/${mapLayerId}/{z}/{x}/{y}.mvt?dt=${Date.now()}`;
      var currentLayerId = `${mapLayerId}-${tileType}`;

      if (map.getLayer(currentLayerId)) {
        map.removeLayer(sourceId);
        map.removeSource(sourceId);
        map.addSource(sourceId,{
          type: 'vector',
          tiles: [url],
          promoteId: 'featureId'
        });
        this.loadMVTLayerV2(map, mapLayerId, tileType);
      }
    }
  }

  loadMVTLayerV2(map: any, appLayerId: number, tileType: string) {
    let currentSourceId: any = null;
    let currentLayerId: any = null;
    currentSourceId = `${appLayerId}-${tileType}`;
    currentLayerId = `${appLayerId}-${tileType}`;
    var userMinZoom = this.renderScaleService.getMinLevelForScale();

    if (tileType === 'point') {
      // if (!pointLayerIds.find((it: any) => it === currentLayerId)) {
      //     pointLayerIds.push(currentLayerId);
      // }
      if (map.getLayer(currentLayerId) === undefined) {
        map.addLayer({
          id: currentLayerId,
          type: 'symbol',
          source: currentSourceId,
          'source-layer': 'default',
          minzoom: userMinZoom,
          maxzoom: 23,
          layout: {
            'icon-allow-overlap': true,
            visibility: 'visible',
            'icon-image': ['image', ['get', 'featureType']],
            // [
            //   'coalesce',
            //   // ['get', 'featureType'],
            //   // 'GENERIC_BRIAN',
            //   ['image', ['get', 'featureType']],
            //   ['image', 'MISC_CAD'],
            // ], // expects "featureType" to have same name as the loaded icon
            'icon-size': ['interpolate', ['linear'], ['zoom'], 10.25, .1, 17, .3],
          }
        });
      }
      else {
         map.setLayerZoomRange(currentLayerId, userMinZoom, 23);
      }
    } else if (tileType === 'line') {
      // if (!lineLayerIds.find((it: any) => it === currentLayerId)) {
      //     lineLayerIds.push(currentLayerId);
      // }
      if (map.getLayer(currentLayerId) === undefined) {
        map.addLayer({
          id: currentLayerId,
          type: 'line',
          source: currentSourceId,
          'source-layer': 'default',
          minzoom: userMinZoom,
          maxzoom: 23,
          layout: {
            visibility: 'visible',
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: this.getLinePaint()
        });
      }
      else {
        map.setLayerZoomRange(currentLayerId, userMinZoom, 23);
     }
    } else if (['photo', 'matterport', 'virtualtour', 'attachment', 'externallink'].indexOf(tileType) !== -1) {
      if (map.getLayer(currentLayerId) === undefined) {
        map.addLayer({
          id: currentLayerId,
          type: 'symbol',
          source: currentSourceId,
          'source-layer': 'default',
          minzoom: userMinZoom,
          maxzoom: 23,
          layout: {
            'icon-allow-overlap': true,
            visibility: 'visible',
            'icon-image': [
              'coalesce',
              ['get', 'featureType']
            ], // expects "featureType" to have same name as the loaded icon
            'icon-size': ['interpolate', ['linear'], ['zoom'], 10.25, .1, 17, .3],
          }
        });
      }
      else {
        map.setLayerZoomRange(currentLayerId, userMinZoom, 23);
     }
    } else if (['pointcloud'].indexOf(tileType) !== -1) {
      // turn pointcloud on at 2000 ft
      if (map.getLayer(currentLayerId) === undefined) {
        map.addLayer({
          id: currentLayerId,
          type: 'symbol',
          source: currentSourceId,
          'source-layer': 'default',
          minzoom: userMinZoom,
          maxzoom: 23,
          layout: {
            'icon-allow-overlap': true,
            visibility: 'visible',
            'icon-image': [
              'coalesce',
              ['get', 'featureType']
            ], // expects "featureType" to have same name as the loaded icon
            'icon-size': ['interpolate', ['linear'], ['zoom'], 10.25, .1, 17, .3],
          }
        });
      }
      else {
        map.setLayerZoomRange(currentLayerId, userMinZoom, 23);
      }
    }
  }

  removeAllSpaceAndSpecialChar(val: any) {
    const noSpecialChars = val?.replace(/[^a-zA-Z0-9_-]/g, '');
    return noSpecialChars?.replaceAll(' ', '');
  }

  removeAllLayer(pointLayerIds: any, lineLayerIds: any, map: any) {
    pointLayerIds?.forEach((ele: any) => {
      if (map.getLayer(ele) != undefined) {
        map.removeLayer(ele);
      }
      if (map.getSource(ele) != undefined) {
        map.removeSource(ele);
      }
    });
    lineLayerIds?.forEach((ele: any) => {

      if (map.getLayer(ele) != undefined) {
        map.removeLayer(ele);
      }
      if (map.getSource(ele) != undefined) {
        map.removeSource(ele);
      }
    });
  }

  /* Load and refresh MVT */
  /* Call unloadMVT after saving new or updated features, or deleting features
  ## After saving 1+ features with the same tileType and mapLayerId, e.g. "point" and 1
  unloadMVT(1, 'point')
  ## After saving 1+ features with different tileTypes and the same mapLayerId, e.g. "point", "line" and 1
  unloadMVT(1, 'point')
  unloadMVT(1, 'line')
  */
  unloadMVT(
    mapLayerId: number,           // Either 1, 2 or 3, for GPRS, Client and Imported layer respectively
    tileType: string,            // The theme of the MVT: "point", "line", "photo" and others to be added
    map: any,
    featureGroup: any,
    sourceId: any,
    zoomedJobId: any,
    hiddenFeature: any,
    pointLayerIds: any,
    lineLayerIds: any
  ) {
    // All layers must be unloaded first
    // We need to find all layers with mapLayerId and tileType
    let layerIdsToUnload = map.getStyle().layers.filter((layer: any) => layer.id.startsWith(mapLayerId.toString()) && layer.id.endsWith(tileType)).map((layer: any) => layer.id)
    layerIdsToUnload.forEach((currentLayerId: string) => {
      map.removeLayer(currentLayerId)
    })

    // Unload the source
    let currentSourceId = `${mapLayerId}-${tileType}`;
    map.removeSource(currentSourceId)

    // Reload the source and layers
    // This should be moved after code that saves/deletes features
    let pointFeatureGroups: string[] = [];
    let lineFeatureGroups: string[] = [];
    let photoFeatureGroups: string[] = [];
    switch (tileType) {
      case 'point':
        this.loadMVT(mapLayerId, tileType, featureGroup, this.tilesUrl, map, sourceId, zoomedJobId, hiddenFeature, pointLayerIds, lineLayerIds);
        // this.loadMVT(mapLayerId, tileType, [...pointFeatureGroups])
        break;
      case 'line':
        // this.loadMVT(mapLayerId, tileType, [...lineFeatureGroups])
        break;
      case 'photo':
        // this.loadMVT(mapLayerId, tileType, [...photoFeatureGroups])
        break;
      default:
        break;
    }
  }

  /* Call loadMVT 1) when page loads 2) when user selects a new base map and 3) when a feature is saved or deleted.
## Page load and loading a new basemap - get all mapLayerIds, tileTypes and featureGroups for points, lines, etc.
let mapLayerIds = [1,2,3]
mapLayerIds.forEach(mapLayerId => {
  loadMVT(mapLayerId, 'point', [...pointFeatureGroups])
  loadMVT(mapLayerId, 'line', [...lineFeatureGroups])
  loadMVT(mapLayerId, 'external', ['photo', 'matterport', 'pointcloud', 'virtualtour'])
})
*/
  externalContentIconImage: any = {
    photo: 'PHOTO',    // Change this to the name used when adding image to map
    matterport: 'MATTERPORT',
    pointcloud: 'POINTCLOUD',
    virtualtour: 'VIRTUALTOUR',
    attachment: 'ATTACHMENT',
    externallink: 'EXTERNALLINK'

  }
  loadMVT(
    mapLayerId: number,               // Either 1, 2 or 3, for GPRS, Client and Imported layer respectively
    tileType: string,                 // The theme of the MVT: "point", "line", "photo" and others to be added
    featureGroups: string[],
    url: string,
    map: any,
    currentSourceId: any,          // All feature groups in the data that need to be added as a layer
    jobId: any,
    hiddenFeature: any,
    pointLayerIds: any,
    lineLayerIds: any
  ) {

    //
    let tileSourceBaseUrl: string = url
    // Create the source ID and add the source to the map
    // let currentSourceId = `${mapLayerId}-${tileType}`;
    if (!currentSourceId)
      currentSourceId = `${mapLayerId}-${tileType}`;

    if (map.getSource(currentSourceId) === undefined)
      map.addSource(currentSourceId, {
        type: 'vector',
        tiles: [
          PortalAPI.MVT_GET_ACTIVE_JOB_FEATURES_BY_LAYER_ID + `/${tileType}/${mapLayerId}/{z}/{x}/{y}.mvt`,
        ],
        promoteId: 'featureId',
        // minzoom: 13.25,
        // maxzoom: 23
      });
    // Then add all required layers
    // Line and Point layers are created based on feature group
    if (tileType === 'point' || tileType === 'line') {
      for (let featureGroup of featureGroups) {
        let currentLayerId = `${mapLayerId}-${featureGroup}-${tileType}`;
        if (tileType === 'point') {
          pointLayerIds.push(currentLayerId);
          if (featureGroups.length > 0) {
            if (map.getLayer(currentLayerId) == undefined) {
              map.addLayer({
                id: currentLayerId,
                type: 'symbol',
                source: currentSourceId,
                'source-layer': 'default',
                minzoom: 10,
                maxzoom: 23,
                layout: {
                  'icon-allow-overlap': true,
                  visibility: 'visible',
                  'icon-image': ['get', 'featureType'],
                  'icon-size': ['interpolate', ['linear'], ['zoom'], 10.25, .1, 17, .3],
                },
                filter: this.getGroupFilterExpression(featureGroup, jobId, hiddenFeature)
              });
            }
            else {
              map.setFilter(
                currentLayerId,
                this.getGroupFilterExpression(featureGroup, jobId, hiddenFeature)
              );
            }
          }
        } else if (tileType === 'line') {
          lineLayerIds.push(currentLayerId);
          if (featureGroups.length > 0) {
            if (map.getLayer(currentLayerId) == undefined) {
              map.addLayer({
                id: currentLayerId,
                type: 'line',
                source: currentSourceId,
                'source-layer': 'default',
                minzoom: 10,
                maxzoom: 23,
                layout: {
                  visibility: 'visible',
                  'line-join': 'round',
                  'line-cap': 'round',
                },
                paint: this.getLinePaintByGroupName(featureGroup),
                filter: this.getGroupFilterExpression(featureGroup, jobId, hiddenFeature)
              });
            }
            else {
              map.setFilter(
                currentLayerId,
                this.getGroupFilterExpression(featureGroup, jobId, hiddenFeature)
              );
            }
          }
        }
        // Set the filter for mapJobId, featureType, featureName etc.
        // getFilterExpression
        // setFilter
      }
    } else if (tileType === 'external') {
      featureGroups.forEach((externalType: string) => {

        let currentLayerId = `${mapLayerId}-${externalType}-point`;
        pointLayerIds.push(currentLayerId);
        if (featureGroups.length > 0) {
          if (map.getLayer(currentLayerId) == undefined) {
            map.addLayer({
              id: currentLayerId,
              type: 'symbol',
              source: currentSourceId,
              'source-layer': 'default',
              minzoom: 10,
              maxzoom: 23,
              layout: {
                'icon-allow-overlap': true,
                visibility: 'visible',
                // 'icon-image': this.externalContentIconImage[externalType.toLowerCase()],
                // 'icon-size': ['interpolate', ['linear'], ['zoom'], 10.25, .1, 17, .3],
              },
              filter: this.getGroupFilterExpression(externalType, jobId, hiddenFeature)
            });
          }
          else {
            map.setFilter(
              currentLayerId,
              this.getGroupFilterExpression(externalType, jobId, hiddenFeature)
            );
          }
        }
        // Set the filter for mapJobId, featureType, featureName etc.
        // getFilterExpression
        // setFilter
      });
    }
  }

  mapEvent(map: any, pointLayerIds: any, lineLayerIds: any) {
    // Mouse events - these are turned on/off every time we refresh the tiles (Save/Delete)
    // Mouse click on Sitmap layers - handles single- and double-click
    // Do we need special handling for Draw layers?
    this.pointLayerIds = pointLayerIds;
    this.lineLayerIds = lineLayerIds;
    this.map = map;
    [...pointLayerIds, ...lineLayerIds].forEach((layerId: any) => {
      if (this.screenWidth <= 397) {
        map.off('touchend', layerId, this.handleFirstClick);
        map.on('touchend', layerId, this.handleFirstClick);
      } else {
        map.off('click', layerId, this.handleFirstClick);
        map.on('click', layerId, this.handleFirstClick);
      }
    });

    // Mouseenter on Sitmap layers
    [...pointLayerIds, ...lineLayerIds].forEach((layerId: any) => {
      map.off('mouseenter', layerId, this.handleLayerMouseEnter);
      map.on('mouseenter', layerId, this.handleLayerMouseEnter);
    });

    // Mouseout on Sitmap layers
    [...pointLayerIds, ...lineLayerIds].forEach((layerId: any) => {
      map.off('mouseout', layerId, this.handleLayerMouseOut);
      map.on('mouseout', layerId, this.handleLayerMouseOut);
    });
  }

  // Handle the first click on a Sitemap layer, then branch for single- or double-click
  handleFirstClick = (e: any) => {
    this.map.doubleClickZoom.disable();
    const bboxPadding = 3;
    const bbox: any = [
      [e.point.x - bboxPadding, e.point.y - bboxPadding],
      [e.point.x + bboxPadding, e.point.y + bboxPadding],
    ];
    // Find features intersecting the bounding box.
    const clickedFeatures: any[] = this.map.queryRenderedFeatures(
      bbox,
      {
        layers: [...this.pointLayerIds, ...this.lineLayerIds],
      }
    );
    //this.behaviourSubjectService.setClickFeature(clickedFeatures);
  }

  // Mouseenter - sets hover curso
  handleLayerMouseEnter = (e: any) => {
    // Only change to point if we are not hovering over a draw feature
    // Set `bbox` as ?px reactangle area around clicked point.
    const bboxPadding = 3;
    const bbox: any = [
      [e.point.x - bboxPadding, e.point.y - bboxPadding],
      [e.point.x + bboxPadding, e.point.y + bboxPadding],
    ];
    // let hoveredDrawFeatures = this.map.queryRenderedFeatures(bbox, {
    //     layers: [...this.drawLayerLineIds, ...this.drawLayerPointIds],
    // });
    // if (hoveredDrawFeatures.length === 0) {
    let hoveredSitemapFeatures = this.map.queryRenderedFeatures(bbox, {
      layers: [...this.pointLayerIds, ...this.lineLayerIds],
    });
    if (hoveredSitemapFeatures.length > 0) {
      this.map.getCanvasContainer().style.cursor = 'pointer';
    }
    // }
  }

  // Mouseout - removes hover cursor
  handleLayerMouseOut = (e: any) => {
    this.map.getCanvasContainer().style.cursor = '';
  }

  /*
Generate the "paint" style required for LINE based on "feature_type_id"
Returns the entire "paint" style object
"case" syntax:
["case",
condition: boolean, output: OutputType,
condition: boolean, output: OutputType,
...,
fallback: OutputType
]
See: https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#case
*/
  getLinePaintByGroupName(lineFeatureGroupName: string) {
    // This is the return style object
    // "line-color" and "line-width" will be set using Mapbox expressions with "case" operator
    let linePaint: any = {
      'line-color': ['case'],
      'line-width': ['case'],
    };

    // Iterate over each LINE style in the style schema
    // and push "condition" and "output" to "case" expression
    // for each style based on "feature_type_name"
    FeatureTypeStyleMapping.featureTypeStyleJson
      .filter(
        (featureStyle) =>
          featureStyle.feature_group_name === lineFeatureGroupName
      )
      .forEach((featureStyle) => {
        linePaint['line-color'].push([
          '==',
          ['get', 'featureType'],
          featureStyle.feature_type_name,
        ]);
        linePaint['line-color'].push(featureStyle.color);
        linePaint['line-width'].push([
          '==',
          ['get', 'featureType'],
          featureStyle.feature_type_name,
        ]);
        linePaint['line-width'].push(featureStyle.line_width);
      });

    // "case" requires a default/fallback value
    // and they are added at the end of the expression
    linePaint['line-color'].push('rgb(255,153,255)');
    linePaint['line-width'].push(2);


    return linePaint;
  }

  //getGroupFilterExpression method is used to filter the featuregroupmap data
  getGroupFilterExpression(featureGroup: string, jobId: any, hiddenFeatures: any) {
    const expression = [
      'all',
      ['==', ['get', 'featureGroup'], featureGroup],
      ['in', ['get', 'mapJobId'], ['literal', jobId]],
      ['!', ['in', ['get', 'featureName'], ['literal', hiddenFeatures]]],
    ];
    return expression;
  }

  // Function to return the "featuretype" part of a filter expression
  // Implies featureGroup expression as well
  getTypeFilterExpression(
    featureGroup: string,
    featureType: string,
    layer: any
  ) {
    let featureGroupFeatureTypeMap = this.featureGroupTypeToggleStateMap
      .get(layer)
      .get(featureGroup);
    let visibleFeatueTypeArrayForGivenGroup: string[] = [];
    featureGroupFeatureTypeMap.forEach((value: boolean, key: string) => {
      if (value) {
        visibleFeatueTypeArrayForGivenGroup.push(key);
      }
    });
    let expression = [
      'in',
      ['get', 'featureType'],
      ['literal', visibleFeatueTypeArrayForGivenGroup],
    ];
    return expression;
  }
  //#endregion

  // Function to return the "featureid" part of a filter expression
  // Implies featureGroup and/or featuretype expression(s) as well
  getFeatureFilterExpression(
    featureGroup: string,
    featureType: StringMap,
    layer: any
  ) {
    let featureGroupFeatureTypeMap =
      this.featureGroupTypeFeatureNameToggleStateMap
        .get(layer)
        .get(featureGroup);
    let visibleFeatueNameArray: string[] = [];
    featureGroupFeatureTypeMap.forEach((val: boolean, keyForType: string) => {
      let featureTypeFeatureNameMap =
        featureGroupFeatureTypeMap.get(keyForType);
      featureTypeFeatureNameMap.forEach((value: boolean, key: string) => {
        if (value) {
          visibleFeatueNameArray.push(key);
        }
      });
    });
    let expression = [
      'in',
      ['get', 'feature'],
      ['literal', visibleFeatueNameArray],
    ];
    return expression;
  }
  //#endregion

  getLinePaint() {
    // This is the return style object
    // "line-color" and "line-width" will be set using Mapbox expressions with "case" operator
    let linePaint: any = {
      'line-color': ['case'],
      'line-width': ['case'],
    };

    // Iterate over each LINE style in the style schema
    // and push "condition" and "output" to "case" expression
    // for each style based on "feature_type_name"
    FeatureTypeStyleMapping.featureTypeStyleJson
      .filter((feature) => feature.geometry_type == 'LINE')
      .forEach((featureStyle: any) => {
        linePaint['line-color'].push([
          '==',
          ['get', 'featureType'],
          featureStyle.feature_type_name,
        ]);
        linePaint['line-color'].push(featureStyle.color);
        linePaint['line-width'].push([
          '==',
          ['get', 'featureType'],
          featureStyle.feature_type_name,
        ]);
        linePaint['line-width'].push(featureStyle.line_width);
      });

    // "case" requires a default/fallback value
    // and they are added at the end of the expression
    // linePaint['line-color'].push('rgb(255,153,255)');
    linePaint['line-color'].push('#D4D4D4');
    linePaint['line-width'].push(2);


    return linePaint;
  }

  filterMVTFromToggle2(zoomedJobIds: any, map: any) {

    document.querySelectorAll('.map-layer-container').forEach(mapLayerContainer => {
      // Start with empty filter expression
      let filterExpression: any = [];
      // First get the container DOM for each map layer
      let mapLayerContainerName = mapLayerContainer.getAttribute('data-name');

      // Set up the layers
      zoomedJobIds?.forEach((jobId: any) => {
        let pointLayerId = `${mapLayerContainerName === 'GPRS' ? '1' : mapLayerContainerName === 'Client' ? '2' : '3'}-${jobId}-point`
        let lineLayerId = `${mapLayerContainerName === 'GPRS' ? '1' : mapLayerContainerName === 'Client' ? '2' : '3'}-${jobId}-line`


        // Is this layer visible?
        let mapLayerContainerClass = `.map-layer-container.${mapLayerContainerName}`
        let mapLayerIdDOM = document.querySelector(`${mapLayerContainerClass} .map-layer-id`);
        if (!mapLayerIdDOM) {
          return;
        }
        let mapLayerIdIsVisible = mapLayerIdDOM.classList.contains('eye-visible');

        if (!mapLayerIdIsVisible) {
          // We filter all layers
          if (map.getLayer(pointLayerId) !== undefined) {
            map.setFilter(pointLayerId, false)
          }
          if (map.getLayer(lineLayerId) !== undefined) {
            map.setFilter(lineLayerId, false)
          }
          return
        }
        // Add base condition
        filterExpression.push('all');
        // Get all featureGroups
        let featureGroupsDOM = document.querySelectorAll(`${mapLayerContainerClass} .feature-group`);

        featureGroupsDOM.forEach(featureGroupDOM => {
          let featureGroupName = featureGroupDOM.getAttribute('data-name');
          let featureGroupClassName = featureGroupName?.replace(' ', '');
          let featureGroupIsVisible = featureGroupDOM.classList.contains('eye-visible');


          if (!featureGroupIsVisible) {
            let currentExpression = ['!=', ['get', 'featureGroup'], featureGroupName];
            filterExpression.push(currentExpression);

          } else {
            // Get all featureTypes
            let featureTypesDOM = document.querySelectorAll(`${mapLayerContainerClass} .feature-group-container.${featureGroupClassName} .feature-type`)
            featureTypesDOM.forEach(featureTypeDOM => {
              let featureTypeName = "_" + featureTypeDOM.getAttribute('data-name');
              let featureTypeIsVisible = featureTypeDOM.classList.contains('eye-visible');

              if (!featureTypeIsVisible) {
                let currentExpression =
                  // ['all',
                  // ['!=', ['get', 'featureGroup'], featureGroupName],
                  ['!=', ['get', 'featureType'], featureTypeName]
                // ];
                filterExpression.push(currentExpression);

              } else {
                let featureNamesDOM = document.querySelectorAll(`${mapLayerContainerClass} .feature-group-container.${featureGroupName} .feature-type-container.${featureTypeName} .feature-name`);
                featureNamesDOM.forEach(featureNameDOM => {
                  let featureNameName = featureNameDOM.getAttribute('data-name');
                  let mapJobId = featureNameDOM.getAttribute('map-job-id');
                  let featureNameIsVisible = featureNameDOM.classList.contains('eye-visible');
                  if (!featureNameIsVisible) {

                    // let currentExpression = ['all',
                    //   ['!=', ['get', 'featureGroup'], featureGroupName],
                    //   ['!=', ['get', 'featureType'], featureTypeName],
                    //   ['!=', ['get', 'featureName'], featureNameName]
                    // ];
                    let currentExpression = ['!=', ['get', 'featureName'], featureNameName];
                    filterExpression.push(currentExpression);
                  }
                });
              }
            });
          }
        });
        if (filterExpression.length === 1) {

          if (map.getLayer(pointLayerId) !== undefined) {
            map.setFilter(pointLayerId, null);
          }
          if (map.getLayer(lineLayerId) !== undefined) {
            map.setFilter(lineLayerId, null);
          }
        } else {

          if (map.getLayer(pointLayerId) !== undefined) {
            map.setFilter(pointLayerId, filterExpression);
          }
          if (map.getLayer(lineLayerId) !== undefined) {
            map.setFilter(lineLayerId, filterExpression);
          }
        }


      });
    });
  }

  filterMVTFromToggle(zoomedJobIds: any, map: any, siteLayerData?: any, hiddenFeatures?: any) {


    document.querySelectorAll('.map-layer-container').forEach(mapLayerContainer => {
      // First get the container DOM for each map layer
      let mapLayerContainerName: any = mapLayerContainer.getAttribute('data-name');

      // Set up the layers
      if (mapLayerContainerName === 'GPRS' || mapLayerContainerName === 'CLIENT' || mapLayerContainerName === 'IMPORTED') {
        let pointLayerId = null;
        let lineLayerId = null;
        pointLayerId = `${mapLayerContainerName === 'GPRS' ? '1' : mapLayerContainerName === 'CLIENT' ? '2' : '3'}-point`;
        lineLayerId = `${mapLayerContainerName === 'GPRS' ? '1' : mapLayerContainerName === 'CLIENT' ? '2' : '3'}-line`;



        // Is this layer visible?
        let mapLayerContainerClass = `.map-layer-container.${mapLayerContainerName}`
        let mapLayerIdDOM = document.querySelector(`${mapLayerContainerClass} .map-layer-id`);
        if (!mapLayerIdDOM) {
          return;
        }
        let mapLayerIdIsVisible = mapLayerIdDOM.classList.contains('eye-visible');

        if (!mapLayerIdIsVisible) {
          // We filter all layers
          if (map.getLayer(pointLayerId) !== undefined) {
            map.setFilter(pointLayerId, false)
          }
          if (map.getLayer(lineLayerId) !== undefined) {
            map.setFilter(lineLayerId, false)
          }
          return
        }

        // Start with base condition filter expression
        let filterExpression: any = ['all'];
        // Add "any" condition for MapJobId filter expressions
        filterExpression.push(['any'])
        // Add MapJobId filter components
        zoomedJobIds.forEach((jobId: number) => {
          let currentJobFilterExpression = ['==', ['get', 'mapJobId'], jobId];
          filterExpression[1].push(currentJobFilterExpression)
        })

        // Get all featureGroups
        let featureGroupsDOM = document.querySelectorAll(`${mapLayerContainerClass} .feature-group`);

        featureGroupsDOM.forEach(featureGroupDOM => {
          let featureGroupName = featureGroupDOM.getAttribute('data-name');
          let featureGroupClassName = featureGroupName?.replace(' ', '');
          let featureGroupIsVisible = featureGroupDOM.classList.contains('eye-visible');


          if (!featureGroupIsVisible) {
            let currentExpression = ['!=', ['get', 'featureGroup'], featureGroupName];
            filterExpression.push(currentExpression);

          } else {
            // Get all featureTypes
            let featureTypesDOM = document.querySelectorAll(`${mapLayerContainerClass} .feature-group-container.${featureGroupClassName} .feature-type`);

            if (featureGroupClassName=="PHOTO") {
              // photos do not have a sub level (feature-type-container)
              let featureNamesDOM = document.querySelectorAll(`${mapLayerContainerClass} .is-photo`);

              featureNamesDOM.forEach(featureNameDOM => {
                let featureNameName = featureNameDOM.getAttribute('data-name');
                let mapJobId = featureNameDOM.getAttribute('map-job-id');
                let featureNameIsVisible = featureNameDOM.classList.contains('eye-visible');
                if (!featureNameIsVisible) {
                  let currentExpression = ['!=', ['get', 'featureName'], featureNameName];
                  filterExpression.push(currentExpression);
                }
              });
            }

            featureTypesDOM.forEach(featureTypeDOM => {
              let featureTypeName = featureTypeDOM.getAttribute('data-name');
              let featureTypeIsVisible = featureTypeDOM.classList.contains('eye-visible');

              if (!featureTypeIsVisible) {
                let currentExpression =
                  // ['all',
                  // ['!=', ['get', 'featureGroup'], featureGroupName],
                  ['!=', ['get', 'featureType'], featureTypeName]
                // ];
                filterExpression.push(currentExpression);

              } else {
                let featureNamesDOM = document.querySelectorAll(`${mapLayerContainerClass} .feature-group-container.${featureGroupClassName} .feature-type-container.${featureTypeName} .feature-name`);

                featureNamesDOM.forEach(featureNameDOM => {
                  let featureNameName = featureNameDOM.getAttribute('data-name');
                  let mapJobId = featureNameDOM.getAttribute('map-job-id');
                  let featureNameIsVisible = featureNameDOM.classList.contains('eye-visible');
                  if (!featureNameIsVisible) {

                    // let currentExpression = ['all',
                    //   ['!=', ['get', 'featureGroup'], featureGroupName],
                    //   ['!=', ['get', 'featureType'], featureTypeName],
                    //   ['!=', ['get', 'featureName'], featureNameName]
                    // ];
                    let currentExpression = ['!=', ['get', 'featureName'], featureNameName];
                    filterExpression.push(currentExpression);
                  }
                });
              }
            });

          }
        });
        if (hiddenFeatures) {
          filterExpression.push(['!', ['in', ['get', 'featureName'], ['literal', hiddenFeatures]]])
        }
        if (filterExpression.length === 1) {

          if (map.getLayer(pointLayerId) !== undefined) {
            map.setFilter(pointLayerId, null);
          }
          if (map.getLayer(lineLayerId) !== undefined) {
            map.setFilter(lineLayerId, null);
          }
        } else {

          if (map.getLayer(pointLayerId) !== undefined) {
            map.setFilter(pointLayerId, filterExpression);
          }
          if (map.getLayer(lineLayerId) !== undefined) {
            map.setFilter(lineLayerId, filterExpression);
          }
        }


      } else if (mapLayerContainerName === 'EXTERNAL') {
        let attachmentLayerId = '4-attachment';
        let photoLayerId = '4-photo';
        let externalLinkLayerId = '4-externallink';
        let matterportLayerId = '4-matterport';
        let pointcloudLinkLayerId = '4-pointcloud';
        let virtualtourLinkLayerId = '4-virtualtour';

        // Is this layer visible?
        let mapLayerContainerClass = `.map-layer-container.${mapLayerContainerName}`
        let mapLayerIdDOM = document.querySelector(`${mapLayerContainerClass} .map-layer-id`);
        if (!mapLayerIdDOM) {
          return;
        }
        let mapLayerIdIsVisible = mapLayerIdDOM.classList.contains('eye-visible');

        if (!mapLayerIdIsVisible) {
          // We filter all layers
          if (map.getLayer(attachmentLayerId) !== undefined) {
            map.setFilter(attachmentLayerId, false)
          }
          if (map.getLayer(externalLinkLayerId) !== undefined) {
            map.setFilter(externalLinkLayerId, false)
          }
          if (map.getLayer(photoLayerId) !== undefined) {
            map.setFilter(photoLayerId, false)
          }
          if (map.getLayer(matterportLayerId) !== undefined) {
            map.setFilter(matterportLayerId, false)
          }
          if (map.getLayer(pointcloudLinkLayerId) !== undefined) {
            map.setFilter(pointcloudLinkLayerId, false)
          }
          if (map.getLayer(virtualtourLinkLayerId) !== undefined) {
            map.setFilter(virtualtourLinkLayerId, false)
          }
          return
        }

        // Get all featureGroups
        for (const featureGroup of ['ATTACHMENT', 'EXTERNALLINK', 'MATTERPORT', 'POINTCLOUD', 'VIRTUALTOUR']) {
          // Start with base condition filter expression
          let filterExpression: any = ['all'];
          // Add "any" condition for MapJobId filter expressions
          filterExpression.push(['any'])
          // Add MapJobId filter components
          zoomedJobIds.forEach((jobId: number) => {
            let currentJobFilterExpression = ['==', ['get', 'mapJobId'], jobId];
            filterExpression[1].push(currentJobFilterExpression)
          })


          let featureGroupDOM = document.querySelector(`${mapLayerContainerClass} .feature-group.${featureGroup}`);
          if (featureGroupDOM) {

            let featureGroupName = featureGroupDOM!.getAttribute('data-name');

            let featureGroupClassName = featureGroupName?.replace(' ', '');
            let featureGroupIsVisible = featureGroupDOM!.classList.contains('eye-visible');


            if (!featureGroupIsVisible) {
              let currentExpression = ['!=', ['get', 'featureGroup'], featureGroupName];
              filterExpression.push(currentExpression);


              if (featureGroup === 'ATTACHMENT') {
                map.setFilter(attachmentLayerId, filterExpression)
              } else if (featureGroup === 'EXTERNALLINK') {
                map.setFilter(externalLinkLayerId, filterExpression)
              } else if (featureGroup === 'MATTERPORT') {
                map.setFilter(matterportLayerId, filterExpression)
              } else if (featureGroup === 'POINTCLOUD') {
                map.setFilter(pointcloudLinkLayerId, filterExpression)
              } else if (featureGroup === 'VIRTUALTOUR') {
                map.setFilter(virtualtourLinkLayerId, filterExpression)
              }
            } else {
              let featureNamesDOM = document.querySelectorAll(`${mapLayerContainerClass} .feature-group-container.${featureGroupClassName} .feature-name`);

              featureNamesDOM.forEach(featureNameDOM => {
                let featureNameName = featureNameDOM.getAttribute('data-name');

                let mapJobId = featureNameDOM.getAttribute('map-job-id');
                let featureNameIsVisible = featureNameDOM.classList.contains('eye-visible');
                if (!featureNameIsVisible) {

                  // let currentExpression = ['all',
                  //   ['!=', ['get', 'featureGroup'], featureGroupName],
                  //   ['!=', ['get', 'featureType'], featureTypeName],
                  //   ['!=', ['get', 'featureName'], featureNameName]
                  // ];
                  let currentExpression = ['!=', ['get', 'featureName'], featureNameName];
                  filterExpression.push(currentExpression);
                }
              });
              if (filterExpression.length === 1) {

                if (featureGroup === 'ATTACHMENT') {
                  map.setFilter(attachmentLayerId, null)
                } else if (featureGroup === 'EXTERNALLINK') {
                  map.setFilter(externalLinkLayerId, null)
                } else if (featureGroup === 'MATTERPORT') {
                  map.setFilter(matterportLayerId, null)
                } else if (featureGroup === 'POINTCLOUD') {
                  map.setFilter(pointcloudLinkLayerId, null)
                } else if (featureGroup === 'VIRTUALTOUR') {
                  map.setFilter(virtualtourLinkLayerId, null)
                }
              } else {

                if (featureGroup === 'ATTACHMENT') {
                  map.setFilter(attachmentLayerId, filterExpression)
                } else if (featureGroup === 'EXTERNALLINK') {
                  map.setFilter(externalLinkLayerId, filterExpression)
                } else if (featureGroup === 'MATTERPORT') {
                  map.setFilter(matterportLayerId, filterExpression)
                } else if (featureGroup === 'POINTCLOUD') {
                  map.setFilter(pointcloudLinkLayerId, filterExpression)
                } else if (featureGroup === 'VIRTUALTOUR') {
                  map.setFilter(virtualtourLinkLayerId, filterExpression)
                }
              }
            }
          } else {
            if (hiddenFeatures) {
              filterExpression.push(['!', ['in', ['get', 'featureName'], ['literal', hiddenFeatures]]])

              if (featureGroup === 'ATTACHMENT') {
                map.setFilter(attachmentLayerId, filterExpression)
              } else if (featureGroup === 'EXTERNALLINK') {
                map.setFilter(externalLinkLayerId, filterExpression)
              } else if (featureGroup === 'MATTERPORT') {
                map.setFilter(matterportLayerId, filterExpression)
              } else if (featureGroup === 'POINTCLOUD') {
                map.setFilter(pointcloudLinkLayerId, filterExpression)
              } else if (featureGroup === 'VIRTUALTOUR') {
                map.setFilter(virtualtourLinkLayerId, filterExpression)
              }
            }

          }

        }
      } else if (mapLayerContainerName === 'SITE') {
        let centroidLayerId = 'unclustered-point';
        let searchCentroidLayerId = 'search-unclustered-point';
        let mapLayerContainerClass = `.map-layer-container.${mapLayerContainerName}`
        let mapLayerIdDOM = document.querySelector(`${mapLayerContainerClass} .map-layer-id`);
        // If SITE is not in DOM quit
        if (!mapLayerIdDOM) {
          return;
        }

        // If the SITE layer is hidden, hide "unclustered-point" by setting filter to false and quit
        let mapLayerIdIsVisible = mapLayerIdDOM.classList.contains('eye-visible');

        if (!mapLayerIdIsVisible) {
          // We filter all layers

          if (siteLayerData) {
            let siteFeatureGroup = siteLayerData.features.filter((site: any) => site.featureGroup === "Site")
            siteFeatureGroup.forEach((group: any, index: number) => {
              // Hiding site
              let siteLayerId = this.removeAllSpaceAndSpecialChar(group.featureType) + '-site-' + group.featureTypeId
              let siteId = map.getStyle().layers.filter((lyr: any) => lyr.id == siteLayerId)[0]
              map.setLayoutProperty(siteId?.id, "visibility", "none")

              // Hiding subsite
              let subsiteLayerId = this.removeAllSpaceAndSpecialChar(group.feature) + '-subsite-' + group.featureId
              let subsiteId = map.getStyle().layers.filter((lyr: any) => lyr.id == subsiteLayerId)[0]
              map.setLayoutProperty(subsiteId?.id, "visibility", "none")

            })
          }
          if (map.getLayer(centroidLayerId) !== undefined) {
            map.setFilter(centroidLayerId, false)
          }
          else if (map.getLayer(searchCentroidLayerId) !== undefined) {
            map.setFilter(searchCentroidLayerId, false)
          }
          return
        }

        // Start without base condition filter expression
        let filterExpression: any;
        let siteFilterExpression: any = ['all'];
        siteFilterExpression.push(['any'])
        // Get all featureGroups - in this case the "Job" header
        let featureGroupsDOM = document.querySelectorAll(`${mapLayerContainerClass} .feature-group`);

        featureGroupsDOM.forEach(featureGroupDOM => {
          let featureGroupName = featureGroupDOM.getAttribute('data-name');
          let featureGroupClassName = featureGroupName?.replace(' ', '');
          let featureGroupIsVisible = featureGroupDOM.classList.contains('eye-visible');

          // If the Feature Group, i.e. "Job",  is hidden, hide "unclustered-point" by setting filter to false and quit
          if (!featureGroupIsVisible) {
            if (featureGroupName === 'Job') {

              filterExpression = false;
              return
            } else if (featureGroupName === 'Site') {
              if (siteLayerData) {
                let siteFeatureGroup = siteLayerData.features.filter((site: any) => site.featureGroup === "Site")
                siteFeatureGroup.forEach((group: any, index: number) => {
                  // Hiding site
                  let siteLayerId = this.removeAllSpaceAndSpecialChar(group.featureType) + '-site-' + group.featureTypeId
                  let siteId = map.getStyle().layers.filter((lyr: any) => lyr.id == siteLayerId)[0]
                  map.setLayoutProperty(siteId?.id, "visibility", "none")
                  // siteId.forEach((sid: any) => map.setLayoutProperty(sid.id, "visibility", "none"))

                  // Hiding subsite
                  let subsiteLayerId = this.removeAllSpaceAndSpecialChar(group.feature) + '-subsite-' + group.featureId
                  let subsiteId = map.getStyle().layers.filter((lyr: any) => lyr.id == subsiteLayerId)[0]
                  map.setLayoutProperty(subsiteId?.id, "visibility", "none")
                  // subsiteId.forEach((ssid: any) => map.setLayoutProperty(ssid.id, "visibility", "none"))

                })
              }
            }
          } else {
            if (featureGroupName === 'Job') {
              // Start with "false" as base condition filter expression
              filterExpression = false;
            } else if (featureGroupName === 'Site') {
              if (siteLayerData) {
                let siteFeatureGroup = siteLayerData.features.filter((site: any) => site.featureGroup === "Site")
                siteFeatureGroup.forEach((group: any, index: number) => {
                  // Hiding site
                  let siteLayerId = this.removeAllSpaceAndSpecialChar(group.featureType) + '-site-' + group.featureTypeId
                  let siteId = map.getStyle().layers.filter((lyr: any) => lyr.id == siteLayerId)[0]
                  map.setLayoutProperty(siteId?.id, "visibility", "visible")

                  // Hiding subsite
                  let subsiteLayerId = this.removeAllSpaceAndSpecialChar(group.feature) + '-subsite-' + group.featureId
                  let subsiteId = map.getStyle().layers.filter((lyr: any) => lyr.id == subsiteLayerId)[0]
                  map.setLayoutProperty(subsiteId?.id, "visibility", "visible")

                })
              }
            }

            // Get all featureTypes, e.g. Jobs
            let featureTypesDOM = document.querySelectorAll(`${mapLayerContainerClass} .feature-group-container.${featureGroupClassName} .feature-type`)
            featureTypesDOM.forEach(featureTypeDOM => {
              let featureTypeName = featureTypeDOM.getAttribute('data-name');
              let jobId: any = featureTypeDOM.getAttribute('data-jobid');
              let featureTypeIsVisible = featureTypeDOM.classList.contains('eye-visible');

              // if the Job is hidden do nothing; if not hidden, then add to "any" expression
              if (!featureTypeIsVisible) {
                // Nothing to do here...
                if (featureGroupName === "Site") {
                  if (siteLayerData) {
                    let siteFeatureGroup = siteLayerData.features.filter((site: any) => site.featureType === featureTypeName)
                    siteFeatureGroup.forEach((group: any) => {

                      // Hiding site
                      let siteLayerId = this.removeAllSpaceAndSpecialChar(group.featureType) + '-site-' + group.featureTypeId
                      let siteId = map.getStyle().layers.filter((lyr: any) => lyr.id == siteLayerId)[0]
                      map.setLayoutProperty(siteId?.id, "visibility", "none")

                      // Hiding subsite
                      let subsiteLayerId = this.removeAllSpaceAndSpecialChar(group.feature) + '-subsite-' + group.featureId
                      let subsiteId = map.getStyle().layers.filter((lyr: any) => lyr.id == subsiteLayerId)[0]
                      map.setLayoutProperty(subsiteId?.id, "visibility", "none")

                    })
                  }
                }
              } else {
                if (featureGroupName === 'Site') {
                  if (siteLayerData) {
                    let siteFeatureGroup = siteLayerData.features.filter((site: any) => site.featureType === featureTypeName)
                    siteFeatureGroup.forEach((group: any) => {
                      // Hiding site
                      let siteLayerId = this.removeAllSpaceAndSpecialChar(group.featureType) + '-site-' + group.featureTypeId
                      let siteId = map.getStyle().layers.filter((lyr: any) => lyr.id == siteLayerId)[0]
                      map.setLayoutProperty(siteId?.id, "visibility", "visible")

                      // Hiding subsite
                      let subsiteLayerId = this.removeAllSpaceAndSpecialChar(group.feature) + '-subsite-' + group.featureId
                      let subsiteId = map.getStyle().layers.filter((lyr: any) => lyr.id == subsiteLayerId)[0]
                      map.setLayoutProperty(subsiteId?.id, "visibility", "visible")

                    })
                  }
                }
                if (featureGroupName === 'Job') {
                  if (!filterExpression) {
                    // Set the base filter for "unclustered-point"
                    filterExpression = ['all', ['!', ['has', 'point_count']]];
                    // Add "any" condition for MapJobId negative filter expressions
                    filterExpression.push(['any']);
                  }
                  // Add this JobId to filter
                  let currentExpression = ['==', ['get', 'jobId'], parseInt(jobId)]
                  // Index 2 is the "any" component of the filter expression
                  filterExpression[2].push(currentExpression);
                }
                else {
                  let featureNamesDOM = document.querySelectorAll(`${mapLayerContainerClass} .feature-group-container.${featureGroupClassName} .feature-type-container.${this.removeAllSpaceAndSpecialChar(featureTypeName)} .feature-name`);

                  featureNamesDOM.forEach(featureNameDOM => {
                    let featureNameName = featureNameDOM.getAttribute('data-name');
                    let mapJobId = featureNameDOM.getAttribute('map-job-id');
                    let featureNameIsVisible = featureNameDOM.classList.contains('eye-visible');
                    if (!featureNameIsVisible) {

                      if (featureGroupName === "Site") {
                        // let subsite = map.getStyle().layers.filter((lyr: any) => lyr.id.startsWith(featureNameName))
                        let featureId = this.removeAllSpaceAndSpecialChar(featureNameName) + '-subsite-'
                        let subsite = map.getStyle().layers.filter((lyr: any) => lyr.id.startsWith(featureId))
                        subsite.forEach((ssid: any) => map.setLayoutProperty(ssid?.id, "visibility", "none"))
                      } else {
                        let currentExpression = ['!=', ['get', 'featureName'], featureNameName];
                        siteFilterExpression.push(currentExpression);
                      }
                      // let currentExpression = ['all',
                      //   ['!=', ['get', 'featureGroup'], featureGroupName],
                      //   ['!=', ['get', 'featureType'], featureTypeName],
                      //   ['!=', ['get', 'featureName'], featureNameName]
                      // ];
                    } else {
                      let featureId = this.removeAllSpaceAndSpecialChar(featureNameName) + '-subsite-'
                      let subsite = map.getStyle().layers.filter((lyr: any) => lyr.id.startsWith(featureId))
                      subsite.forEach((ssid: any) => map.setLayoutProperty(ssid?.id, "visibility", "visible"))
                    }
                  });
                }
              }
            });
          }
        });



        map.setFilter(centroidLayerId, filterExpression);
        if (map.getLayer(searchCentroidLayerId) !== undefined) {
          map.setFilter(searchCentroidLayerId, filterExpression)
        }
      }
    });
  }

  filterMVTOnZoomIn(zoomedJobIds: any, map: any) {

    // if (zoomedJobIds.length === 0) {
    //   return;
    // }
    let filterExpression: any
    for (const layerId of [1, 2, 3]) {
      filterExpression = ['any'];
      for (const tileType of ['point', 'line']) {
        let currentLayerId = `${layerId}-${tileType}`
        if (zoomedJobIds.length > 0) {
          zoomedJobIds.forEach((jobId: number) => {
            let currentJobFilterExpression = ['==', ['get', 'mapJobId'], jobId];
            filterExpression.push(currentJobFilterExpression)
          });
        }
        else {
          let currentJobFilterExpression = ['==', ['get', 'mapJobId'], ''];
          filterExpression.push(currentJobFilterExpression);
        }

        if (map.getLayer(currentLayerId) !== undefined) {

          map.setFilter(currentLayerId, filterExpression);
        }

      }
    }
    for (const tileType of ['photo', 'matterport', 'virtualtour', 'pointcloud', 'attachment', 'externallink']) {
      filterExpression = ['any'];
      let currentLayerId = `4-${tileType}`
      zoomedJobIds.forEach((jobId: number) => {
        let currentJobFilterExpression = ['==', ['get', 'mapJobId'], jobId];
        filterExpression.push(currentJobFilterExpression)
      });
      if (map.getLayer(currentLayerId) !== undefined) {

        map.setFilter(currentLayerId, filterExpression);
      }
    }
  }

  getFeature(data: any) {
    data.forEach((ele: any) => {
      let layer: any = { layerName: null, layerEyeVisisble: true, group: [] }
      const featureGroup = this.DistinctRecords(ele.features, 'featureGroup');

      let groupObject: any[] = [];
      featureGroup.forEach((group: any) => {
        let groupJson: any = { groupName: null, groupEyeVisisble: true, type: [] };
        const typeArr = ele.features.filter((it: any) => it.featureGroup === group.featureGroup);

        const featureType = this.DistinctRecords(typeArr, 'featureType');
        let typeObject: any[] = [];

        featureType.forEach((type: any) => {
          let typeJson: any = { typeName: type.featureType, featureTypeEyeVisisble: true, feature: [] };
          let featureName = typeArr.filter((it: any) => it.featureType === type.featureType);
          featureName.forEach((fName: any) => {
            fName.featureEyeVisisble = true;
            if (fName.geometry?.type == 'LineString') {
              fName.lineColor = this.fetchFeatureTypeColor(fName.featureTypeId)
            }
            else {
              fName.iconSrc = '../../assets/images/GPRS_PNG/' + fName.featureType + '.png';
            }
          });
          typeJson.feature = featureName;
          typeObject.push(typeJson);
        });

        //   return it?.group?.filter((gr:any)=>gr.groupName != ele.layerName)
        // }));
        groupJson.groupName = group.featureGroup;
        groupJson.type = typeObject;
        groupObject.push(groupJson);
      });
      if (this.features.filter(it => it.layerName != ele.layerName))
        layer.layerName = ele.layerName;
      layer.group = groupObject;
      this.features.push(layer);
    });

    return this.features;
  }

  DistinctRecords(jsonData: any, key: any) {
    return jsonData.filter((obj: any, pos: any, arr: any) => {
      return arr.map((mapObj: any) => mapObj[key]).indexOf(obj[key]) === pos;
    })
  }

  fetchFeatureTypeColor(featureTypeId: any) {
    if (this.featureTypeStyleMap.get(parseInt(featureTypeId)) == undefined) {
      return '#808080';
    } else {
      return this.featureTypeStyleMap.get(parseInt(featureTypeId)).color;
    }
  }


  /*MAP TITLE TEXT CHNAGE*/
  mapToolTitleChange() {
    const LineStringTool = document.querySelector(".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line") as HTMLDivElement;
    LineStringTool?.setAttribute('title', 'Line Tool');

    const PolygonTool = document.querySelector(".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon") as HTMLDivElement;
    PolygonTool?.setAttribute('title', 'Polygon Tool');

    const zoomIn = document.querySelector(".mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon") as HTMLButtonElement;
    zoomIn?.setAttribute('title', 'Zoom In');

    const zoomOut = document.querySelector(".mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon") as HTMLButtonElement;
    zoomOut?.setAttribute('title', 'Zoom Out');

    const compassTitle = document.querySelector(".mapboxgl-ctrl-compass .mapboxgl-ctrl-icon") as HTMLButtonElement;
    compassTitle?.setAttribute('title', 'Reset Bearing To North');
  }

  // Set Source for Reference Layer GIS in map
  setSourceForReferenceLayerVectorTiles(map: any, data: any) {

    if (map.getSource(`${data.mvtId}-reference`) === undefined) {
      map.addSource(`${data.mvtId}-reference`, {
        type: 'vector',
        tiles: [ `${environment.TILES_MVT_API}/MVTGetReferenceLayer/${data.mvtId}/{z}/{x}/{y}.mvt`],
        bounds: [data.xMin, data.yMin, data.xMax, data.yMax],
        minzoom: 3,
        maxzoom: 8

      });

      this.addLayerForReferenceLayerVectorTiles(map, data);
    }
  }

  // Set Layer for Reference Layer GIS in map
  addLayerForReferenceLayerVectorTiles(map: any, data: any) {
    let geomType = data.geomType.toLowerCase();
    switch (geomType) {
      case 'polygon':
        map.addLayer({
          id: `${data.mvtId}-reference`,
          type: 'fill',
          source: `${data.mvtId}-reference`,
          'source-layer': `${data.layerName}`,
          minzoom: 3,
          maxzoom: 23,
          paint: {
            'fill-color': 'steelblue',
            'fill-outline-color': 'white',
            'fill-opacity': .6
          },
          layout: {
            'visibility': 'none'
          }
        });
        // Polygons need to be moved lower - maybe below 'unclustered-point'
        if(map.getLayer('unclustered-point')  !== undefined){
          map.moveLayer(`${data.mvtId}-reference`, 'unclustered-point')
        }

        break;
      case 'multipolygon':
        map.addLayer({
          id: `${data.mvtId}-reference`,
          type: 'fill',
          source: `${data.mvtId}-reference`,
          'source-layer': `${data.layerName}`,
          minzoom: 3,
          maxzoom: 23,
          paint: {
            'fill-color': 'steelblue',
            'fill-outline-color': 'white',
            'fill-opacity': .6
          },
          layout: {
            'visibility': 'none'
          }
        });
        // Polygons need to be moved lower - maybe below 'unclustered-point'
        if(map.getLayer('unclustered-point')  !== undefined){
          map.moveLayer(`${data.mvtId}-reference`, 'unclustered-point')
        }
        break;
      case 'point':
        map.addLayer({
          id: `${data.mvtId}-reference`,
          type: 'circle',
          source: `${data.mvtId}-reference`,
          'source-layer': `${data.layerName}`,
          minzoom: 3,
          maxzoom: 23,
          paint: {
            'circle-color': 'orange',
            'circle-radius': 4,
            'circle-stroke-color': 'white',
            'circle-stroke-width': 1,
            'circle-opacity': .6
          },
          layout: {
            'visibility': 'none'
          }
        });

        break;
      case 'linestring':
        map.addLayer({
          id: `${data.mvtId}-reference`,
          type: 'line',
          source: `${data.mvtId}-reference`,
          'source-layer': `${data.layerName}`,
          minzoom: 3,
          maxzoom: 23,
          paint: {
            'line-color': 'green',
            'line-opacity': 1,
            'line-width': 2
          },
        });
        break;

      default:
        break;
    }

  }

  updatePointLineDescription(saveFeature: any, payLoad: any) {
    switch (saveFeature.geometry.type) {
      case 'Point':
        if (!saveFeature.properties.isAnnotation) {
          return this.mapViewerJobFeatureService
            .savePointFeatureDetailFrMapEditor(payLoad)
        }
        else {
          return null;
          // REST to UpsertAnnotationPoint
        }
        break;
      case 'LineString':
        if (!saveFeature.properties.isAnnotation) {
          this.spinner.show();
          return this.mapViewerJobFeatureService
            .saveLineFeatureDetailFrMapEditor(payLoad);
        }
        else {
          return null;
          // REST to UpsertAnnotationLine
        }
        break;
      default: return null;
        break;
    }
  }

  //#region updateFeatureDescriptionFormGenerate method is used to add from in screen
  updateFeatureDescriptionFormGenerate() {
    return this.fb.group({
      dateOfInstallation: [null],
      dateOfUtilityInvestigation: [null],
      publishedDate: [null],
      workOrderNumber: [null],
      utilityOwner: [null],
      locatorName: [null],
      locatorCollectorUsed: [null],
      accuracy: [null],
      coordinatelongtitude: [null],
      coordinatelatitude: [null],
      condition: [null],
      operationalStatus: [null],
      depthToTop: [null],
      description: [null],
      notes: [null],
      lineDiameter: [null],
      utilityElevation: [null],
      pressure: [null],
      sourceFile: [null],
      swMapsLayer: [null],
      rfidAssociation: [null],
    });

  }


  /* update featues */
  updateFeaturesDescition(editDescriptionValue: any, updateFeatureDescriptionForm: any) {
    let wktGeom: any = "";
    let saveFeature = editDescriptionValue.groups.features[0];
    let featureDescription: any = {
      mapJobId: editDescriptionValue.jobId,
      dateOfInstallation: updateFeatureDescriptionForm.value.dateOfInstallation,
      dateOfUtilityInvestigation: updateFeatureDescriptionForm.value.dateOfUtilityInvestigation,
      publishedDate: updateFeatureDescriptionForm.value.publishedDate,
      workorderNumber: updateFeatureDescriptionForm.value.workOrderNumber,
      fieldName: updateFeatureDescriptionForm.value.fieldName,
      description: updateFeatureDescriptionForm.value.description,
      notes: updateFeatureDescriptionForm.value.notes,
      locationMethod: updateFeatureDescriptionForm.value.locationMethod,
      type: updateFeatureDescriptionForm.value.type,
      depthToTop: updateFeatureDescriptionForm.value.depthToTop ? updateFeatureDescriptionForm.value.depthToTop : "",
      utilityOwner: updateFeatureDescriptionForm.value.utilityOwner ? updateFeatureDescriptionForm.value.utilityOwner : "",
      utilityElevation: updateFeatureDescriptionForm.value.utilityElevation,
      operationalStatus: updateFeatureDescriptionForm.value.operationalStatus,
      condition: updateFeatureDescriptionForm.value.condition,
      encasement: updateFeatureDescriptionForm.value.encasement,
      traceWire: updateFeatureDescriptionForm.value.traceWire,
      pressure: updateFeatureDescriptionForm.value.pressure,
      rfidAssociation: updateFeatureDescriptionForm.value.rfidAssociation,
      locatorName: updateFeatureDescriptionForm.value.locatorName,
      locationCollectorUsed: updateFeatureDescriptionForm.value.locatorCollectorUsed,
      accuracy: updateFeatureDescriptionForm.value.accuracy,
      coordinatelongtitude: updateFeatureDescriptionForm.value.coordinatelongtitude,
      coordinatelatitude: updateFeatureDescriptionForm.value.coordinatelatitude,
      numberOfSatellites: updateFeatureDescriptionForm.value.numberOfSatellites,
      sourceFilename: updateFeatureDescriptionForm.value.sourceFile,
      swmapsLayer: updateFeatureDescriptionForm.value.swMapsLayer,
      mapFeatureTypeId: saveFeature.properties.featureTypeId,
      mapGroupId: saveFeature.properties.featureGroupId,
      mapLayerId: saveFeature.properties.layerId,
      mapAddedLink: updateFeatureDescriptionForm.value.mapAddedLink,
      submissionId: updateFeatureDescriptionForm.value.submissionId,
      voltage: updateFeatureDescriptionForm.value.voltage,
      userId: this.loginUserId
    };

    if (editDescriptionValue.groups.features[0].geometry.type === 'LineString') {
      wktGeom = 'LINESTRING(';
      JSON.parse(saveFeature.geometry.coordinates).forEach((p: any, i: number) => {
        if (i < JSON.parse(saveFeature.geometry.coordinates).length - 1)
          wktGeom = wktGeom + p[0] + ' ' + p[1] + ' ' + p[2] + " , ";
        else wktGeom = wktGeom + p[0] + ' ' + p[1] + ' ' + p[2] + ')';
      });
      featureDescription.mapLineId = saveFeature.properties.featureId;
      featureDescription.lineDiameter = updateFeatureDescriptionForm.value.lineDiameter;
      featureDescription.material = updateFeatureDescriptionForm.value.material;
    } else if (saveFeature.geometry.type === 'Point') {
      wktGeom =
        wktGeom = `POINT(${JSON.parse(saveFeature.geometry.coordinates)[0][0]} ${JSON.parse(saveFeature.geometry.coordinates)[0][1]} 0)`;

      featureDescription.mapPointId = saveFeature.properties.featureId;
    }
    featureDescription.geom = wktGeom;

    return featureDescription;
  }

  /** Uptate features **/
  editFormFeatureDescriptionOpen(openedFeatureAttributes: any) {
    return this.fb.group({
      dateOfInstallation: openedFeatureAttributes.dateOfInstallation,
      dateOfUtilityInvestigation: openedFeatureAttributes.dateOfUtilityInvestigation,
      publishedDate: openedFeatureAttributes.publishedDate,
      workOrderNumber: openedFeatureAttributes.workOrderNumber,
      utilityOwner: openedFeatureAttributes.utilityOwner,
      locatorName: openedFeatureAttributes.locatorName,
      locatorCollectorUsed: openedFeatureAttributes.locatorCollectorUsed,
      accuracy: openedFeatureAttributes.accuracy,
      coordinatelongtitude: openedFeatureAttributes.coordinate.long,
      coordinatelatitude: openedFeatureAttributes.coordinate.lat,
      condition: openedFeatureAttributes.condition,
      operationalStatus: openedFeatureAttributes.operationalStatus,
      depthToTop: openedFeatureAttributes.depthToTop,
      lineDiameter: openedFeatureAttributes.lineDiameter,
      utilityElevation: openedFeatureAttributes.utilityElevation,
      pressure: openedFeatureAttributes.pressure,
      sourceFile: openedFeatureAttributes.sourceFile,
      swMapsLayer: openedFeatureAttributes.swMapsLayer,
      rfidAssociation: openedFeatureAttributes.rfidAssociation,
      description:openedFeatureAttributes.description,
      notes:openedFeatureAttributes.notes,

    });
  }

  getRasterImageSourceUrl(feature: { rasterimageId: number; fileName: string; }) {
    return `${this.imageServerUrl}/RasterImageGet/${feature.rasterimageId}/${feature.fileName}`;
  }

  getRasterTileSourceUrl(feature: { rastertileId: number;}) {
    return `${this.rasterTileServerUrl}/RasterTileGet/${feature.rastertileId}/{z}/{x}/{y}.png`;
  }

  // Set Source for Geo Reference Images in map
  setSourceForGeoReferenceImages(map: mapboxgl.Map, data: IRasterImage) {
    const name = `${data.rasterimageId}-rasterImage`;
    const sourceData : mapboxgl.ImageSourceRaw = {
      type: 'image',
      url: this.getRasterImageSourceUrl(data),
      coordinates: [
        [data.xMin, data.yMax],
        [data.xMax, data.yMax],
        [data.xMax, data.yMin],
        [data.xMin, data.yMin]
      ],
    }
    // console.log('sourceData', sourceData);

    const source = map?.getSource(name) as mapboxgl.ImageSourceRaw;


    if (source !== undefined && this.compareMapsources(source, sourceData)) {
      return;
    }else if (source !== undefined && !this.compareMapsources(source, sourceData)) {
      map.removeLayer(name);
      map.removeSource(name);
    }

    map.addSource(name, sourceData);
    this.addLayerForGeoReferenceImages(map, data);
  }

  private compareMapsources(source1: mapboxgl.ImageSourceRaw, source2: mapboxgl.ImageSourceRaw) {
    if (source1.url !== source2.url || !this.compareCoordinates(source1.coordinates, source2.coordinates)) {
      return false;
    };
    return true;
  }

  private compareCoordinates(coordinates1?: number[][], coordinates2?: number[][]) {
    if (coordinates1 === undefined || coordinates2 === undefined) {
      return false;
    }
    if (coordinates1.length !== coordinates2.length) {
      return false;
    }
    for (let i = 0; i < coordinates1.length; i++) {
      if (coordinates1[i][0] !== coordinates2[i][0] || coordinates1[i][1] !== coordinates2[i][1]) {
        return false;
      }
    }
    return true;
  }

  mergeRasterImageItemData(prevData: any[], currentData: any[]): any[] {
    const mergedData: any[] = [];

    prevData.forEach(element => {
      if(currentData.findIndex(item => item.rasterimageId === element.rasterimageId) !== -1){
        mergedData.push(element);
      }
    });

    // Add items from current data that are not already in mergedData
    currentData.forEach((element) => {
      if (mergedData.findIndex((item) => item.rasterimageId === element.rasterimageId) === -1) {
        mergedData.push(element);
      }
    });

    return mergedData;
  }



  // Set Source for Raster tiles on map
  setSourceForRasterTiles(map: mapboxgl.Map, data: IRasterImage) {
    this.addLayerForRasterTile(map, data);
  }

  // Set Layer for Geo Reference Images in map
  addLayerForGeoReferenceImages(map: any, data: any) {
    if (map?.getLayer(`${data.rasterimageId}-rasterImage`) === undefined) {
      map?.addLayer({
        id: `${data.rasterimageId}-rasterImage`,
        type: 'raster',
        source: `${data.rasterimageId}-rasterImage`,
        minzoom: 9,
        maxzoom: 23,
        paint: {
          'raster-opacity': data.opacity
        }
      });
      if(map?.getLayer('unclustered-point') !== undefined){
        map?.moveLayer(`${data.rasterimageId}-rasterImage`, 'unclustered-point')
      }
    }
  }

  // Set Layer for Raster Tile on map
  addLayerForRasterTile(map: any, data: any) {
    if (!map.getLayer(`${data.rastertileId}-rasterImage`)) {
    const sourceUrl = this.getRasterTileSourceUrl({ rastertileId: data.rastertileId });

    map.addSource(`${data.rastertileId}-rasterImage`, {
      type: 'raster',
      tiles: [sourceUrl],
      tileSize: 512,
      minzoom: 13,
      maxzoom: 21,
      bounds:[data.xMin, data.yMin, data.xMax, data.yMax],
    });

    map.addLayer({
      id: `${data.rastertileId}-rasterImage`,
      type: 'raster',
      source: `${data.rastertileId}-rasterImage`,
      minzoom: 13,
      maxzoom: 23,
      paint: {
        'raster-opacity': data.opacity
      }
    });

    if (map.getLayer('unclustered-point')) {
      map.moveLayer(`${data.rastertileId}-rasterImage`, 'unclustered-point');
    }
  }
  }

  //# fly map to particular coordinates
  flyMaptoCurrentLocation(map: any, renderer: any, long: any, lat: any, classdynamic: any) {
    map.flyTo({
      center: [long, lat],
      zoom: 15
    });

    const recaptchaContainer = renderer.createElement('div');
    renderer.setProperty(recaptchaContainer, 'id', 'recaptcha-container');
    renderer.appendChild(document.body, recaptchaContainer);
    renderer.setAttribute(recaptchaContainer, 'class', classdynamic);

    let marker1 = new mapboxgl.Marker(recaptchaContainer)
      .setLngLat([long, lat])
      .addTo(map);

  }

  // Slow it down zoom on map
  slowItMapZoom(map: any) {
    map.scrollZoom.setWheelZoomRate(1 / 900); //default 1/450
    map.scrollZoom.setZoomRate(1 / 200); // default 1/100

  }

  // Return to normal zoom on map
  normalItMapZoom(map: any) {
    map.scrollZoom.setWheelZoomRate(1 / 450); //default 1/450
    map.scrollZoom.setZoomRate(1 / 100); // default 1/100
  }

  async getImageFromUrl(url: string) {
   return this.restService.getWithOptions(url, null, { responseType: 'blob' })
          .pipe(map((response: Blob) => {
              return URL.createObjectURL(response || new Blob())
            })).toPromise();
  }
}
