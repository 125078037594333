import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { PortalAPI } from 'src/app/constants/api.constant';
import { IUserModel, loginHistoryTableData, userTableData } from '../../constants/manage-users-data';
import { CommonFunctionService } from '../../../../services/common-function-service';
import { ConnectionService } from '../../services/connection-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IAllPermission, IPersmissionType } from '../../constants/manage-roles-data';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit {

  @Input() searchValue: string = "";
  @Output() selectedDataEmitter = new EventEmitter();

  modalTable: string = '';
  userTableClass = new userTableData();
  userTableHeader = new userTableData().tblHeader;
  systemPermissionTypeData: IPersmissionType[] = [];
  systemAllPermissionData: IAllPermission[] = [];
  usrTableData: IUserModel[] = [];
  loginHistoryTblHeader = new loginHistoryTableData().tblHeader;
  loginHistoryTblData: any[] = [];
  checkedData: any[] = [];
  informationModalData = {
    displayName: "",
    email: "",
    phoneNumber: "",
    rid: 0,
    tierId: 0,

  };
  resetPasswordUserData: any = {};
  apiUserData: IUserModel[] = [];
  resetPasswrdResponse: any = {};
  tenantId: string = "";
  permissionModalData: any;

  constructor(
    private restService: ConnectionService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private commonService: CommonFunctionService,
    private toastr: ToastrService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.getTenantID()
    this.getUserList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchValue) {
      this.spinner.show();
      this.commonService.searchDataWithDb(this.searchValue, 'name', this.apiUserData, PortalAPI.GET_ALL_USERS)
        .subscribe(response => {
          if (response && response.usersEntities && response.totalRecord > 0) {
            this.usrTableData = response.usersEntities;
            this.usrTableData.forEach((it: any) => {
              it['checkBox'] = false;
              it['assignedJobs'] = 'View/Edit';
              if (it['isActive']) {
                it['isActive'] = 'Active';
              } else {
                it['isActive'] = 'Inactive';
              }
              it['action'] = new userTableData().actionButton;
              it['permission'] = new userTableData().linkButton;
              if (it.roleId != 3) { it['tierName'] = '' }
              else { it['tierName'] = it.tierName }
            });
            this.spinner.hide();
          }
          else {
            this.spinner.hide();
            this.usrTableData = [];
          }
        }, err => {
          console.log("search user error: ", err.status);
          this.spinner.hide();
        });
    }
  }

  //#region getTenantID method is used to get azure details from MsalService
  getTenantID() {
    const accountDetail = this.commonService.getAccountDetail()[0];
    if (accountDetail.tenantId) {
      this.tenantId = accountDetail.tenantId;
    }
    else {
      this.tenantId = '1';
    }
  }
  //#endregion

  //#region getUserList method is use to get all the job list through the GetAllUsers api
  getUserList() {
    this.spinner.show();
    this.usrTableData = [];
    this.restService.tenantId = this.tenantId;
    const restServiceBody = {
      "request": {
        "pageNumber": 1,
        "pageSize": 1000
      },
      "searchValue": "string"
    }
    this.restService.post(PortalAPI.GET_ALL_USERS, restServiceBody).subscribe(response => {
      if (response && response.usersEntities && response.totalRecord > 0) {
        this.usrTableData = response.usersEntities;
        this.usrTableData.forEach((it) => {
          it['checkBox'] = false;
          it['assignedJobs'] = 'View/Edit';
          it['action'] = this.userTableClass.actionButton;
          it['permission'] = this.userTableClass.linkButton;
          if (it['isActive']) {
            it['isActive'] = 'Active';
          } else {
            it['isActive'] = 'Inactive';
          }
          if (it.roleId != 3) { it['tierName'] = '' }
          else { it['tierName'] = it.tierName }
        });

        this.apiUserData = [...this.usrTableData];
      }
      this.spinner.hide();
    }, err => {
      this.toastr.error("user error: " + err.status, "", { timeOut: 3000 });
      this.spinner.hide();
    });
  }
  //#endregion

  //#region getLoginHistory method is use to get all the Login History through the GetLoggedinUserHistoryAsync api
  getLoginHistory(userId: any) {
    this.spinner.show();
    this.loginHistoryTblData = [];
    this.commonService.getLoginHistory(userId).subscribe(response => {
      if (response && response.userLoginHistoryEntities && response.totalRecord > 0) {
        response.userLoginHistoryEntities.forEach((element: any) => {
          element.date = this.splitDateTime(element["loginTime"], 'date');
          element.loginTime = this.splitDateTime(element["loginTime"]);
          element.logoutTime = this.splitDateTime(element["logoutTime"]);
          element.duration = this.getDuration(element.loginTime, element.logoutTime);
        });
        this.loginHistoryTblData = response.userLoginHistoryEntities;
      }
      this.spinner.hide();
    }, err => {
      this.toastr.error("Login history error: " + err.status, "", { timeOut: 3000 });
      this.spinner.hide();
    });
  }
  //#endregion

  //#region btnClick method is used to click on any button in the table then function will occure
  btnClick(event: any) {
    if (event) {
      if (event.data && event.header && event.header.includes('loginHistory')) {
        this.getLoginHistory(event.data.userId);
      }
      if (event.header.includes("#editUser")) {
        this.informationModalData = event.data;
      }
      if (event.header.includes("#resetPassword")) {
        this.resetPasswordUserData = event.data;
      }

    }
  }
  //#endregion

  //#region linkClick method is used to click on any link in the table then function will occure
  linkClick(event: any) {
    console.log("event:", event)
    if (event) {
      if (event.header && event.header?.columnName.includes("assignedJobs")) {
        this.router.navigate(['/accesscontrol'], { state: { user: JSON.stringify(event.data) } });
      }
      if (event.header && event.header?.columnName.includes("permission")) {
        this.permissionModalData = event.data;
        this.getSystemPermissionType();
      }
    }
  }
  //#endregion

  //#region onCheckBoxClick method is used to click on any checkBox in the table then function will occure
  onCheckBoxClick(event: any) {
    if (this.checkedData.length > 0) {
      const index = this.checkedData.findIndex(
        (item) => item.data.id === event.data.id
      );
      if (index != -1) {
        if (event.data.checkBox === false) {
          this.checkedData.splice(index, 1);
        }
        else {
          this.checkedData.push(event);
        }
      }
    }
    else {
      this.checkedData.push(event);
    }
    this.selectedDataEmitter.emit(this.checkedData);
  }
  //#endregion

  //#region splitDateTime method is used to split date and time from the date
  splitDateTime(date: any, convertInto?: string) {
    if (date) {
      if (convertInto && convertInto === 'date')
        return new Date(date).toLocaleDateString();
      else
        return new Date(date).getHours() + ":" + new Date(date).getMinutes();
    }
    else {
      return "";
    }
  }
  //#endregion

  //#region getDuration method is used to difference between the time 
  getDuration(startTime: any, endTime: any) {
    if (startTime && endTime) {
      return this.diff(startTime, endTime)
    }
    else {
      return "";
    }
  }
  //#endregion

  //#region diff method is used to difference between the start time and end time
  diff(start: any, end: any) {
    start = start.split(":");
    end = end.split(":");
    const startDate = new Date(0, 0, 0, start[0], start[1], 0);
    const endDate = new Date(0, 0, 0, end[0], end[1], 0);
    let diff = endDate.getTime() - startDate.getTime();
    const hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(diff / 1000 / 60);
    return (hours < 9 ? "0" : "") + hours + ":" + (minutes < 9 ? "0" : "") + minutes;
  }
  //#endregion

  //# Reset user password by System Admin
  ResetUserPasswordByAdmin() {
    this.spinner.show();
    let payLoad = [
      {
        "email": this.resetPasswordUserData.email,
        "userADId": this.resetPasswordUserData.azureADId,
        "name": this.resetPasswordUserData.name
      }
    ]

    this.restService.ResetUserPasswordByAdmin(payLoad).subscribe((response) => {
      this.resetPasswrdResponse = response;
      this.spinner.hide();
      if (this.resetPasswrdResponse.processingStatus.status === "Failure") {
        this.toastr.error("Unable to reset password");
      } else {
        this.toastr.success("User password has been reset successfully");

      }
    }, err => {
      console.log("reset password user error: ", err.status);
      this.spinner.hide();
    });
  }

  //#region getSystemPermissionType method is use to get all the permission type list through the GetAllSystemPermissionsTypes api
  getSystemPermissionType() {
    this.spinner.show();
    this.systemPermissionTypeData = [];
    this.restService.tenantId = this.tenantId;

    this.restService.get(PortalAPI.GET_ALL_SYSTEM_PERMISSIONS_TYPES).subscribe(response => {
      if (response && response.length > 0) {
        response.forEach((element: any) => {
          element.checked = false;
        });
        this.systemPermissionTypeData = response
        this.getAllSystemPermission();
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, err => {
      this.toastr.error("System Permission Type error: " + err.status, "", { timeOut: 3000 });
      this.spinner.hide();
    });
  }
  //#endregion

  //#region getAllSystemPermission method is use to get all system permission list through the GetAllSystemPermissions api
  getAllSystemPermission() {
    this.spinner.show();
    this.systemAllPermissionData = [];
    this.restService.tenantId = this.tenantId;

    this.restService.get(PortalAPI.GET_ALL_SYSTEM_PERMISSIONS).subscribe(response => {
      if (response && response.length > 0) {
        response.forEach((element: any) => {
          element.permissionTypeChecked = false;
          element.permissionSubTypeChecked = false;
          element.permissionChecked = false;
        });
        this.systemAllPermissionData = response
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, err => {
      console.log("all permission error: ", err.status);
      this.spinner.hide();
    });
  }
  //#endregion




  logout(payLoad: any) {
    this.spinner.show();
    // const allAccountData = this.authService.instance.getAllAccounts();
    // if (allAccountData.length > 0) {
    //   const azureADId = allAccountData[0].localAccountId;
    //   const emailId = allAccountData[0].username;
    // if (azureADId) {
    this.apiService.getUserDetails(this.resetPasswordUserData.azureADId, this.resetPasswordUserData.email).subscribe((response: any) => {
      if (response && response.userId != -1) {
        this.commonService.setloggedInOROutApi(response.userId, false).subscribe(result => {
          if (result && result.processingStatus?.status.includes('Success')) {
            this.spinner.hide();
          }
          else if (result && result.processingStatus?.status.includes("Failure")) {
            this.logoutDetailsWithErrorToastr();
          }
        }, err => {
          console.log("logout setloggedInOROutApi: ", err);
        });
      }
      else {
        this.logoutDetailsWithErrorToastr();
      }
      this.spinner.hide();
    }, err => {
      console.log("logout issue: ", err);
      this.spinner.hide();
    });
    // }
    // }
  }

  logoutDetailsWithErrorToastr() {
    const toasterMsg = "Logged out details are not save due to user not found in DB, please connect with technical Team!";
    this.toastr.error(toasterMsg, "", {
      timeOut: 2000
    });
    // sessionStorage.clear();
    //     sessionStorage.clear();
    // this.authService.logout();
    // this.router.navigate([""]);
    this.spinner.hide();
  }
}