<div class="row">
  <div class="col-lg-6 col-md-6 col-sm-12 arrow-navigation">
    <h1 style="margin-left: 0px;">Current Message</h1>
    <!-- <h4 class="text-center">Message 1</h4> -->
    <div id="carouselExampleControlsNoTouching" class="carousel slide" data-bs-touch="false" data-bs-interval="false">
      <div class="carousel-inner carousel-width">
        <ng-container *ngFor="let message of messageList;let i=index;">
          
          <div class="carousel-item current-message" [class.active]="i === 0" [id]="message.messageId">
            <h4 class="text-center">Message {{i+1}}</h4>
            <div class="col-lg-12 col-md-12 col-sm-12 space">
              
              <input type="file" id="editUpload-{{i}}" (change)="handleFileSelect($event,message,'current')" hidden>
              <p rol="none">Image
                <label class="" for="editUpload-{{i}}">.
                  <span class="fa fa-edit"></span>
                </label>
                <label class="float-end" data-bs-toggle="modal" data-bs-target="#deleteMessage"
                  (click)="onDeleteCurrentMessage(message)">.
                  <span class="fa fa-trash me-2"> </span>
                </label>
              </p>
              <img [src]="message.imageMessage" class="d-block w-100 h-250" alt="Dashboard Message" />
              <p>Description <span class="fa fa-edit float-end" (click)="onTxtDescription(message)"></span></p>
              <textarea class="form-control mb-3" name="description" [(ngModel)]="message.textMessage"
                [disabled]="txtDisable" id="txtDescription-{{i}}" cols="30" rows="8"></textarea>
                <label for="txtDescription-{{i}}" class="visually-hidden">txtDescription</label>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 space">
              <div class="form-check form-check-inline mt-1">
                <input class="form-check-input" checked="message.isExternal=false" type="checkbox" id="gprs-users-{{i}}"
                  value="" [(ngModel)]="message.gprsUser" >
                <label class="form-check-label" for="gprs-users-{{i}}">Publish to GPRS Users</label>
              </div>

              <div class="form-check form-check-inline mt-1">
                <input class="form-check-input" checked="message.isExternal=true"  type="checkbox" id="non-gprs-users-{{i}}"
                  value=""  [(ngModel)]="message.nonGprsUser">
                <label class="form-check-label" for="non-gprs-users-{{i}}">Publish to Non-GPRS Users</label>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <button class="carousel-control-prev w-auto" type="button" data-bs-target="#carouselExampleControlsNoTouching"
        data-bs-slide="prev" (click)="carousalClick()">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next w-auto" type="button" data-bs-target="#carouselExampleControlsNoTouching"
        data-bs-slide="next" (click)="carousalClick()">
        <i class="fa fa-arrow-right" aria-hidden="true"></i>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end border-top mt-3 pt-3 space">
      <button class="btn btn-cancel me-2"  (click)="btnCurrentDiscard()">Discard</button>
      <button class="btn btn-primary"  (click)="onSaveExistingPublish()">Publish</button>
    </div>
  </div>


  
  
 <!-- new message -->
  <div class="col-lg-6 col-md-6 col-sm-12">
    <div class="col-lg-12 col-md-12 col-sm-6" >
      <h1>New Message <small>(When you publish a new message it will replace the current message)</small></h1>
      <h4 class="text-center">Message {{messageList.length+1}} <a routerLink="javaScript:void()"
          (click)="onAnotherMessageClick()">ADD ANOTHER MESSAGE</a></h4>
    </div>
    <div id="carouselNewMessageControls" class="carousel slide" data-bs-touch="false" data-bs-interval="false">
      <div class="carousel-inner carousel-width">
        <ng-container *ngFor="let message of newMessage;let i=index;">
          <div class="carousel-item new-message" [class.active]="i === 0" [id]="i">
            
            <div class="col-lg-12 col-md-12 col-sm-12">
              <p>Image <span class="fa fa-edit float-end"></span></p>
              <div class="upload">
                <label for="upload" *ngIf="!message.imageMessage">
                  <img src="../../../../../assets/images/upload-img.svg" alt="Upload"
                    class="d-block w-100" /><span>Upload</span></label>
                <img class="d-block w-100 h-250" [src]="message.imageMessage" *ngIf="message.imageMessage"
                  alt="Dashboard Message" />
                <input type="file" id="upload" (change)="handleFileSelect($event,message,'new')" hidden>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <p>Description <span class="fa fa-edit float-end"></span></p>
              <textarea class="form-control mb-3" name="description-upload" [(ngModel)]="message.textMessage" id=""
                cols="30" rows="8"></textarea>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-check form-check-inline mt-1">
                <input class="form-check-input" type="checkbox" id="gprs-users-new-{{i}}" 
                  value="option1" checked="externalNew=false"  >
                <label class="form-check-label" for="gprs-users-upload-{{i}}">Publish to GPRS Users</label>
              </div>

              <div class="form-check form-check-inline mt-1">
                <input class="form-check-input" type="checkbox" 
                  id="non-gprs-users-new-{{i}}" value="option2" disabled="externalNew=false">
                <label class="form-check-label" for="non-gprs-users-upload-{{i}}">Publish to Non-GPRS Users</label>
              </div>
            </div>
          </div>
          
        </ng-container>
      </div>
      <button class="carousel-control-prev w-auto" type="button" data-bs-target="#carouselNewMessageControls"
        data-bs-slide="prev" (click)="onCarouselNewClick()">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next w-auto" type="button" data-bs-target="#carouselNewMessageControls"
        data-bs-slide="next" (click)="onCarouselNewClick()">
        <i class="fa fa-arrow-right" aria-hidden="true"></i>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end border-top mt-3 pt-3">
      <button type="button" class="btn btn-cancel me-2" (click)="onNewDiscard()">Discard</button>
      <button type="button" class="btn btn-primary" (click)="onSaveNewPublish()">Publish</button>
    </div>
  </div>
</div>

<!--Delete Message Modal-->
<div class="modal fade" id="deleteMessage" tabindex="-1" role="dialog" aria-hidden="true">>
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="deleteMessageHeading"><span>Delete Message</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       <p class="custom-black-text">Are you sure want to delete?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="modalDelete()">Delete</button>
      </div>
    </div>
  </div>
</div>
