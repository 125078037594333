export class Default_MyPreferences {
    
    JOB_DISPLAY_WIDGET  = 5;
    FILE_DISPLAY_WIDGET = 5;
    NOTIFICATION_FREQUENCY  = 'INSTANTLY';  // INSTANTLY/DAILY/WEEKLY
    NOTIFICATION_METHOD = 'EMAIL'; // EMAIL/SMS
    NOTIFICATION_EVENT = 'CHECK_ALL'; // CHECK_ALL,JOB_ADDED_ACCOUNT,JOB_ASSIGN,JOB_RESCHEDULE,FILE_SHARED,SITE_SHARED,JOB_SHARED,SUB_SITE_SHARED
    MARKER_DECLUTTERING = 'FALSE'; // TRUE/FALSE
    PROFILE_SEARCHABLE_TO_OTHER_USERS  =  'FALSE'; // TRUE/FALSE
    MEASUREMENT_TOOLS  = 'TRUE'; // TRUE/FALSE
    THEME_SELECTION =  'LIGHT'; // LIGHT/DARK
    DEFAULT_HOMEPAGE = 'DASHBOARD'; // DASHBOARD/MAP_VIEWER/DIGITAL_PLAN_ROOM
    FILE_DOWNLOAD_PREFERENCE = 'INDIVIDUAL_FILES' // INDIVIDUAL_FILES/COMBINE_INTO_ZIP
}