import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { BehaviorSubjectService } from 'src/app/services/behaviorsubject.service';

import { ConnectionService } from 'src/app/modules/sitemapadmin/services/connection-service';
import { CredentialessLogin } from 'src/app/common-component/credentialess-login/credentialess-login';
import { PortalAPI } from 'src/app/constants/api.constant';

@Injectable({
  providedIn: 'root',
})
export class CredentialessLoginService {
  public options: any = {};

  constructor(
    private http: HttpClient,
    private behaviourSubjectService: BehaviorSubjectService,
    private restService: ConnectionService
  ) { 
    let reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    reqHeader.append('Access-Control-Allow-Origin', '*');
    reqHeader.append('accept', 'text/plain');
    reqHeader.append(
      'Access-Control-Allow-Headers',
      'Origin, X-Requested-With, Content-Type, Accept'
    );
    reqHeader.append(
      'Access-Control-Allow-Methods',
      'GET,POST,OPTIONS,DELETE,PUT'
    );
    this.options = { headers: reqHeader };
  }

  getJobInfoByGUID(guid:string) {
    return this.http.get(`${PortalAPI.GET_ALL_JOBS_LAT_LONG_GUID}/${guid}`);  
  }
}
