import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import mouseEventPoint from '@mapbox/mapbox-gl-draw/src/lib/mouse_event_point';
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
import StringSet from '@mapbox/mapbox-gl-draw/src/lib/string_set';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import SimpleSelect from '@mapbox/mapbox-gl-draw/src/modes/simple_select';

import showSnappingFeature, { snapFeatureLayerId, getSiteMapMVTLayerIdsById } from './mode_utils';


function siteMapSimpleSelect() {

  const SiteMapSimpleSelect = { ...SimpleSelect };

  SiteMapSimpleSelect.onSetup = function(opts) {
    // Setup editing options
    opts = opts || {};
    // turn the opts into state.
    const state = {
      dragMoveLocation: null,
      boxSelectStartLocation: null,
      boxSelectElement: undefined,
      boxSelecting: false,
      canBoxSelect: false,
      dragMoving: false,
      canDragMove: false,
      initiallySelectedFeatureIds: opts.featureIds || []
    };

    this.mapLayerId = opts.mapLayerId;
    this.isSnappingToLine = opts.isSnappingToLine;
    this.isSnappingToVertex = opts.isSnappingToVertex;
    this.isSnapping = this.isSnappingToLine || this.isSnappingToVertex;
    this.navigatedFeatures = opts.navigatedFeatures;

    if (this.mapLayerId !== undefined) {
      this.siteMapMVTLayerIds = getSiteMapMVTLayerIdsById(this.map, this.mapLayerId);
    }

    this.setSelected(state.initiallySelectedFeatureIds.filter(id => this.getFeature(id) !== undefined));
    this.fireActionable();

    this.setActionableState({
      combineFeatures: true,
      uncombineFeatures: true,
      trash: true
    });

    return state;
  };

  SiteMapSimpleSelect.onDrag = function(state, e) {
    if (state.canDragMove) return this.dragMove(state, e);
    if (this.drawConfig.boxSelect && state.canBoxSelect) return this.whileBoxSelect(state, e);
  };

  SiteMapSimpleSelect.dragMove = function(state, e) {
    // Dragging when drag move is enabled
    state.dragMoving = true;
    e.originalEvent.stopPropagation();

    const delta = {
      lng: e.lngLat.lng - state.dragMoveLocation.lng,
      lat: e.lngLat.lat - state.dragMoveLocation.lat
    };

    moveFeatures(this.getSelected(), delta);

    state.dragMoveLocation = e.lngLat;


    if (this.isSnapping) {
      // Remove snapping features
      if (this.getFeature(snapFeatureLayerId) !== undefined) {
        this.deleteFeature(snapFeatureLayerId);
      }
      // Mouse coords
      let lat = e.lngLat.lat;
      let lng = e.lngLat.lng;
      // Selected feature
      let selectedFeature = this.getSelected()[0];
      // These are the "subject" lng/lat coordinates, e.g. the moving mouse cursor
      let geographicalCoordinates = [lng, lat];

      // Reference point - "from" snapping based on mouse position
      let movingFeature = {
        id: 'simple_select_feature',
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: geographicalCoordinates
        }
      }

      showSnappingFeature.call(
        this,
        geographicalCoordinates,
        movingFeature,
        selectedFeature.id,
        'simple_select');
    }
  };

  SiteMapSimpleSelect.onTouchEnd = SiteMapSimpleSelect.onMouseUp = function(state, e) {
    // Check for snapping
    if (this.isSnapping && state.initiallySelectedFeatureIds.length === 1) {
      // Get the feeature of the Vertex being dragged
      let movingFeature = this.getFeature(state.initiallySelectedFeatureIds[0]);
      // Get the snap feature from the map, or undefined if it does not exist
      let snapFeature = this.getFeature(snapFeatureLayerId);
      if (snapFeature !== undefined) {
        let geomType = movingFeature.type;
        if (geomType === 'Point') {
          this.deleteFeature(snapFeatureLayerId);
          movingFeature.coordinates = snapFeature.coordinates;
          this.addFeature(movingFeature);
        }
      }
    }
    // End any extended interactions
    if (state.dragMoving) {
      this.fireUpdate();
    } else if (state.boxSelecting) {
      const bbox = [
        state.boxSelectStartLocation,
        mouseEventPoint(e.originalEvent, this.map.getContainer())
      ];
      const featuresInBox = this.featuresAt(null, bbox, 'click');
      const idsToSelect = this.getUniqueIds(featuresInBox)
        .filter(id => !this.isSelected(id));

      if (idsToSelect.length) {
        this.select(idsToSelect);
        idsToSelect.forEach(id => this.doRender(id));
        this.updateUIClasses({ mouse: Constants.cursors.MOVE });
      }
    }
    this.stopExtendedInteractions(state);
  };

  SiteMapSimpleSelect.onTap = SimpleSelect.onClick = function(state, e) {
    // Click (with or without shift) on no feature
    if (CommonSelectors.noTarget(e)) return this.clickAnywhere(state, e); // also tap
    if (CommonSelectors.isOfMetaType(Constants.meta.VERTEX)(e)) return this.clickOnVertex(state, e); //tap
    if (CommonSelectors.isFeature(e)) return this.clickOnFeature(state, e);
  };

  SiteMapSimpleSelect.clickAnywhere = function (state) {
    // Clear the re-render selection
    const wasSelected = this.getSelectedIds();
    if (wasSelected.length) {
      this.clearSelectedFeatures();
      wasSelected.forEach(id => this.doRender(id));
    }
    doubleClickZoom.enable(this);
    this.stopExtendedInteractions(state);
  };

  SiteMapSimpleSelect.toDisplayFeatures = function(state, geojson, display) {
    geojson.properties.active = (this.isSelected(geojson.properties.id)) ?
      Constants.activeStates.ACTIVE : Constants.activeStates.INACTIVE;
    display(geojson);
    this.fireActionable();
    if (geojson.properties.active !== Constants.activeStates.ACTIVE ||
      geojson.geometry.type === Constants.geojsonTypes.POINT) return;
    createSupplementaryPoints(geojson).forEach(display);
  };

  return SiteMapSimpleSelect;
}


export default siteMapSimpleSelect;
