import { Injectable, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PortalAPI } from 'src/app/constants/api.constant';
import { ConnectionService } from '../../sitemapadmin/services/connection-service';
import * as turf from '@turf/turf';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MapSiteService {

    loginUserId: any;
    loginUserRole: any;
    loginUserEmailId: any;
    addAttrForSubSiteModal: boolean = false;
    private isSaveJobAssociateSite = new BehaviorSubject<boolean>(false);
    isSaveJobAssociateSiteObservable = this.isSaveJobAssociateSite.asObservable();
    private isSaveJobDeAssociateSite = new BehaviorSubject<boolean>(false);
    private isJobsBySites = new BehaviorSubject<boolean>(false);
    isJobsBySitesObservable = this.isJobsBySites.asObservable();
    isSaveJobDeAssociateSiteObservable = this.isSaveJobDeAssociateSite.asObservable();
    private isExternalSaveSite = new BehaviorSubject<number>(0);
    isExternalSaveSiteObservable = this.isExternalSaveSite.asObservable();

    constructor(private restService:ConnectionService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService) {
        if (sessionStorage.getItem("loginCheck") === undefined || !sessionStorage.getItem("loginCheck")) {
            if (sessionStorage.getItem("loginUserId")) {
                this.loginUserId = sessionStorage.getItem("loginUserId");
            }
            if (sessionStorage.getItem("loginUserRole")) {  //Project Manager,Admin
                this.loginUserRole = sessionStorage.getItem("loginUserRole");
            }

            if (sessionStorage.getItem("loginUserEmailId")) {
                this.loginUserEmailId = sessionStorage.getItem("loginUserEmailId");
            }
        }
    }

    setSaveAssociateBehavior(val:boolean){   // for map all joblist screen
        this.isSaveJobAssociateSite.next(val);
    }

    setSaveDeAssociateBehavior(val:boolean){   // for map all joblist screen
        this.isSaveJobDeAssociateSite.next(val);
    }

    setJobsBySitesBehavior(val:boolean){   // for map all joblist screen
        this.isJobsBySites.next(val);
    }
  
    /**Get site job by id start*/
    getJobsBySiteId(siteId:any,siteData:any=[], userId:any) {
        this.spinner.show();
        const param = {
            objectId: siteId,
            objectTypeId: 4, //4 is use for site id in objectTypeId
            userId: parseInt(userId)
        };
        this.restService.post(PortalAPI.GET_JOBS_BY_SUBSITEID,param).subscribe(response => {
            if (response.length > 0) {
                siteData.jobData = response;
            }
            this.spinner.hide();
          },(err:any)=>{
            this.spinner.hide();
        });
    }
     /**Get site job by id end*/

    /**Associate site job by id start*/
    associateJobsBySiteId(siteId:any,siteData:any, jobs:any=[], type:any,userId:any) {
        
        this.spinner.show();
        const externalTypes = [6, 7, 8, 9, 10];
        if(type === 1){
            let data = siteData.jobData.filter((item: any) => item.isAssociated === true);
            data.forEach((item: any) => {
                jobs.push(item.jobId);
            });
        }else if (!externalTypes.includes(type)){
            let data = siteData.docData.filter((item:any)=> item.isAssociated === true);
            data.forEach((item:any)=>{
                jobs.push(item.id);
            });
        }
        const param = {
            objectIds: jobs,
              objectTypeId: type,
              siteIds: [
                siteId
              ],
              userId: parseInt(userId)
        }
        this.restService.post(PortalAPI.ASSOCIATE_SITE, param).subscribe(response => {
            if(response?.processingStatus?.status?.toLowerCase() === 'success'){
                let info;
                if(type === 1){
                    info = "Job(s)";
                    this.getJobsBySiteId(siteId,siteData,userId);
                }
                else if (externalTypes.includes(type)) {
                    info = "External Content";
                    this.isExternalSaveSite.next(siteId);
                }
                else{
                    info = "Document(s)";
                    this.getDocumentSiteId(siteId,siteData,userId);
                }
                this.toastr.success(info+' associated with site successfully.');
                this.setSaveAssociateBehavior(true);
            } else {
                this.toastr.success('there is some error with associate');
            }
            this.spinner.hide();
          },(err:any)=>{
            this.spinner.hide();
        });
    }
     /**Associate site job by id end*/

      /**DeAssociate site job by id start*/
    deassociateJobsBySiteId(siteId:any,siteData:any, jobs:any=[], type:any,userId:any) {
       const param = {
            objectIds:jobs,
            siteIds: [siteId],
            objectTypeId: type, //4 is use for site id in objectTypeId
            userId: parseInt(userId)
        };
        const externalTypes = [6, 7, 8, 9, 10];
        this.restService.post(PortalAPI.DEASSOCIATE_SITE, param).subscribe(response => {
           
           let info;
            if(type === 1){
                info = "Job(s)";
                this.getJobsBySiteId(siteId,siteData,userId);
            }
            else if(externalTypes.includes(type)) 
            {
                info = "External Content";
                this.isExternalSaveSite.next(siteId);
            }
            else{
                info = "Document(s)";
                this.getDocumentSiteId(siteId,siteData,userId);
            }
            this.toastr.success(info+' dissociated with site successfully.');
            this.setSaveDeAssociateBehavior(true);        
            this.spinner.hide();
          },(err:any)=>{
            this.spinner.hide();
        });
    }
     /**DeAssociate site job by id end*/

     /**Get site job by id start*/
     getDocumentSiteId(siteId:any,siteData:any,userId:any) {
        //only thing need to test at when api ready after save 
        this.spinner.show();
        const param = {
            objectId: siteId,
            objectTypeId: 4, //5 is use for subsite id in objectTypeId
            userId: parseInt(userId)
        };
        this.restService.post(PortalAPI.GET_DOCUMENTS_BY_OBJECTID,param).subscribe(response => {
            if (response.length > 0) {
                siteData.docData = response;
            }
            this.spinner.hide();
          },(err:any)=>{
            this.spinner.hide();
        });
    }
     /**Get site job by id end*/
	
  /** Fetch External Content from siteId */
    fetchExternalContentBySiteid(siteId: any,siteType:any, userId:any) {
        const param = {
            objectId: siteId,
            objectTypeId: siteType, //4 is use for site id in objectTypeId
            userId: parseInt(userId)
        };
        return this.restService.post(PortalAPI.GET_EXTERNAL_CONTENT_BY_SITEID, param)
    }
}