import { FeatureGroupItem, FeatureItem, FeatureTypeItem } from '../../feature-tree/feature-tree.component.types';
import mapboxgl from 'mapbox-gl';

export default function applyFilterOnLayer(
  map: mapboxgl.Map,
  layerId: number,
  jobIds: number[],
  hiddenFeatures: FeatureItem[]
) {
  const mapPointId = layerId + '-point';
  const mapLineId = layerId + '-line';

  // Start with base condition filter expression
  let filterExpression: any = ['all'];
  // Add "any" condition for MapJobId filter expressions
  filterExpression.push(['any']);
  // Add MapJobId filter components
  jobIds.forEach((jobId: number) => {
    filterExpression[1].push(['==', ['get', 'mapJobId'], jobId]);
  });
  hiddenFeatures.forEach((feat: FeatureItem) => {
    if (!feat.isEyeVisible) {
      // building filter
      filterExpression.push([
        '!=',
        ['get', 'featureName'],
        feat.featureName,
      ]);
    }
  });
  map.setFilter(mapPointId, filterExpression);
  map.setFilter(mapLineId, filterExpression);
  if (filterExpression.length === 1) {
    if (map.getLayer(mapPointId) !== undefined) {
      map.setFilter(mapPointId, null);
    }
    if (map.getLayer(mapLineId) !== undefined) {
      map.setFilter(mapLineId, null);
    }
  } else {
    if (map.getLayer(mapPointId) !== undefined) {
      map.setFilter(mapPointId, filterExpression);
    }
    if (map.getLayer(mapLineId) !== undefined) {
      map.setFilter(mapLineId, filterExpression);
    }
  }
}
