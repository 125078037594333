import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import DrawPoint from '@mapbox/mapbox-gl-draw/src/modes/draw_point';

import showSnappingFeature, { snapFeatureLayerId, getSiteMapMVTLayerIdsById } from './mode_utils';
import {  sitemapDrawPointId } from './editing_utils';


function siteMapDrawPoint() {

  const SiteMapDrawPoint = { ...DrawPoint };

  SiteMapDrawPoint.onSetup = function(opts) {
    // Setup editing options
    opts = opts || {};
    this.mapLayerId = opts.mapLayerId;
    this.isSnappingToLine = opts.isSnappingToLine;
    this.isSnappingToVertex = opts.isSnappingToVertex;
    this.isSnapping = this.isSnappingToLine || this.isSnappingToVertex;
    this.navigatedFeatures = opts.navigatedFeatures;

    if (this.mapLayerId !== undefined) {
      this.siteMapMVTLayerIds = getSiteMapMVTLayerIdsById(this.map, this.mapLayerId);
    }

    const point = this.newFeature({
      id: sitemapDrawPointId,
      type: Constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: Constants.geojsonTypes.POINT,
        coordinates: []
      }
    });

    this.addFeature(point);

    this.clearSelectedFeatures();
    this.updateUIClasses({ mouse: Constants.cursors.ADD });
    this.activateUIButton(Constants.types.POINT);

    this.setActionableState({
      trash: true
    });

    return { point };
  };

  SiteMapDrawPoint.onMouseMove = function(state, e) {
    // console.log('onMouseMove', 'state', state, 'e', e);

    if (this.isSnapping) {
      // Mouse coords
      let lat = e.lngLat.lat;
      let lng = e.lngLat.lng;

      let geographicalCoordinates = [lng, lat];

      let movingFeature = {
        id: 'draw_point_feature',
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: geographicalCoordinates
        }
      }

      showSnappingFeature.call(
        this,
        geographicalCoordinates,
        movingFeature,
        'draw_point_feature',
        'draw_point');
    } else {
      if (this.getFeature(snapFeatureLayerId) !== undefined) {
        this.deleteFeature(snapFeatureLayerId);
      }
    }
  }

  SiteMapDrawPoint.onTap = SiteMapDrawPoint.onClick = function(state, e) {
    this.updateUIClasses({ mouse: Constants.cursors.MOVE });
    // Get the snap feature from the map, or undefined if it does not exist
    let snapFeature = this.getFeature(snapFeatureLayerId);
    if (this.isSnapping && snapFeature !== undefined) {
      state.point.updateCoordinate('', snapFeature.coordinates[0], snapFeature.coordinates[1]);
    } else {
      state.point.updateCoordinate('', e.lngLat.lng, e.lngLat.lat);
    }

    this.map.fire(Constants.events.CREATE, {
      features: [state.point.toGeoJSON()]
    });
    this.changeMode('sitemap_simple_select', {
      featureIds: [state.point.id],
      mapLayerId: this.mapLayerId,
      navigatedFeatures: this.navigatedFeatures,
      isSnappingToVertex: this.isSnappingToVertex,
      isSnappingToLine: this.isSnappingToLine
    });
  };

  SiteMapDrawPoint.onStop = function(state) {
    this.activateUIButton();
    if (!state.point.getCoordinate().length) {
      this.deleteFeature([state.point.id], { silent: true });
    }
    if (this.getFeature(snapFeatureLayerId) !== undefined) {
      this.deleteFeature(snapFeatureLayerId);
    }
  };

  SiteMapDrawPoint.toDisplayFeatures = function(state, geojson, display) {
    // Never render the point we're drawing
    const isActivePoint = geojson.properties.id === state.point.id;
    geojson.properties.active = (isActivePoint) ? Constants.activeStates.ACTIVE : Constants.activeStates.INACTIVE;
    if (!isActivePoint) return display(geojson);
  };

  return SiteMapDrawPoint

}


export default siteMapDrawPoint;
