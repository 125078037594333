import { BehaviorSubjectService } from 'src/app/services/behaviorsubject.service';
import { Component, ElementRef, HostListener, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AzureBlobStorageServiceVideo } from 'src/app/services/azure-blob-storage.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  searchValue: any;
  selectedTab: any
  changeSearchValueSubscription: Subscription | null = null;
  changeSelectedTabSubscription: Subscription | null = null;

  title = 'highlight-text';
  searchText = '';
  searchText1 = ''
  text = `<div class="row">
    <div class="col-lg-12">
      <div class="card">
        <h2 class="card-header">FAQ</h2>
        <div class="card-body">
          <h2 class="sub-heading">What is SiteMap?</h2>
          <p>
            SiteMap is a one-of-a-kind, cloud-based digital platform that is
            designed to be an easy-to-use geospatial portal, containing your
            accurate and up-to-date architectural, structural, mechanical, and
            as-built drawings, maps, models, subsurface and above ground
            locates, notes, specifications, and tags, all in one place.
          </p>
          <h2 class="sub-heading">Is the service free?</h2>
          <p>
            Our current SiteMap offering is free and available for anyone to
            use, but we will be offering a Premium subscription plan as well as
            a Platinum option.
          </p>
          <h1>Signing Up / Logging In:</h1>
          <h2 class="sub-heading">What is needed to sign up?</h2>
          <p>
            All you need to get started is a valid email address and phone
            number. You will also be prompted to create a password. The whole
            process takes less than a minute to complete.
          </p>
          <h2 class="sub-heading">How can I sign up?</h2>
          <p>
            You can create an account by clicking the ‘Sign Up Now’ button or by
            clicking (insert link here). You can also click on the Sign Up Now
            button on the Login screen of SiteMap.
          </p>
          <h2 class="sub-heading">How do I reset my password?</h2>
          <p>
            If you forgot your password or want to change your password for
            security reasons, you can click the ‘Forgot Password’ button on the
            login screen. A verification code will then be sent to the email
            address associated with your account.
          </p>

          <h2 class="sub-heading">What do I do if I forgot my Login ID?</h2>
          <p>
            Your username is the email address you used when creating your
            account.
          </p>

          <h1>Viewing Deliverables:</h1>
          <h2 class="sub-heading">
            How can I view my deliverables within SiteMap?
          </h2>
          <p>
            Once you are logged into SiteMap, as a Free User you will be able to
            access the Digital Plan Room which houses all the files from any
            scans that GPRS has done associated with your email address.
          </p>

          <h2 class="sub-heading">How do I download my deliverables?</h2>
          <p>
            All site information and data can be found in the Digital Plan Room.
            You can either click into different folders to view the different
            files associated with the different jobs or you can search at the
            top of the page. Once you have selected the items you would like to
            download, just click the Download icon on the job itself or at the
            top of the page to execute your download.
          </p>

          <h1>Sharing content:</h1>
          <h2 class="sub-heading">How can I share a Job with someone?</h2>
          <p>
            You can click the share icon next to the Job Name from either the
            Dashboard or the Digital Plan Room to enter an email address to
            share access with another user.
          </p>

          <h2 class="sub-heading">
            How long will someone have access to a Job or Document once I have
            shared it with them?
          </h2>
          <p>
            Once you have shared a Job or Document, anyone who you shared with
            will have access to it until you remove their access. There are no
            time limits, and the link will not expire.
          </p>

          <h2 class="sub-heading">
            How do I take away access after I have shared something?
          </h2>
          <p>
            You can click on the Collaboration icon on the title bar which will
            bring you to a page with all of the items that have been shared from
            your account. You can click on the Access Type that was given and
            change anything here.
          </p>

          <h1>Map Viewer:</h1>
          <h2 class="sub-heading">
            How do I view a specific site in the Map Viewer?
          </h2>
          <p>
            You can either click on a droplet icon on your map and click onto
            the job, or you can zoom into a specific area on the map using the
            ‘+’ on the map, or by using the scroll wheel on your mouse.
          </p>

          <h2 class="sub-heading">
            Is it possible to change the map from satellite view to something
            else?
          </h2>
          <p>
            In the Map Viewer you have the option to click on the Globe icon to
            switch to one of the other views. Currently we offer Satellite,
            Light, Dark, Streets, Satellite Streets, Outdoors, Navigation Day,
            and Navigation Night views.
          </p>

          <h1>My Preferences:</h1>
          <h2 class="sub-heading">
            Is there a way to customize what I see on my dashboard?
          </h2>
          <p>
            Yes! You can customize how many recent jobs and documents are shown.
            Simply navigate to ‘My Preferences’ in the drop-down menu on the
            title bar at the top of the screen.
          </p>

          <h2 class="sub-heading">
            How do I update my notification preferences?
          </h2>
          <p>
            Once you are logged into your account, you can click ‘My
            Preferences’ and update your settings for how you receive
            notifications, as well as how often you receive them.
          </p>

          <h2 class="sub-heading">
            How do I update my contact information once I have created my
            account?
          </h2>
          <p>
            After logging in, you can navigate to ‘My Profile’ in the drop-down
            menu and update your contact information
          </p>

          <h1>Searching:</h1>
          
          <h2 class="sub-heading">
            How can I search for a specific job?
          </h2>

          <p>There are a couple different ways to search for a job.</p>
          <p>First, you can use the Search icon on the anchored title bar at the top of the page. If you search this way, you will notice that all of the blue job dots disappear on the map and are replaced with red dot(s) – which will be reflective of the search performed.</p>
          <p>You can also search through the All Jobs list by clicking ‘View All Jobs’ from the Dashboard. Searching from this page will provide a list view of the search results.</p>

          <h2 class="sub-heading">
            Can I search by address?
          </h2>
          <p>
            Yes, you can search by address, job name, work order number, customer name, latitude/longitude or the project manager name!
          </p>
        </div>
      </div>
    </div>
  </div>
</div>`

  faqData = [
    { "id": false, "heading": "SiteMap", "ques": "What is SiteMap?", "answ": "SiteMap is a one-of-a-kind, cloud-based digital platform that is designed to be an easy-to-use geospatial portal, containing your accurate and up-to-date architectural, structural, mechanical, and as-built drawings, maps, models, subsurface and above ground locates, notes, specifications, and tags, all in one place." },
    { "id": false, "heading": "SiteMap", "ques": "Is the service free?", "answ": "Our current SiteMap offering is free and available for anyone to use, but we will be offering a Premium subscription plan as well as a Platinum option." },
    { "id": true, "heading": "Signing Up / Logging In:", "ques": "What is needed to sign up?", "answ": "All you need to get started is a valid email address and phone number. You will also be prompted to create a password. The whole process takes less than a minute to complete." },
    { "id": false, "heading": "Signing Up / Logging In:", "ques": "How can I sign up?", "answ": "You can create an account by clicking the ‘Sign Up Now’ button or by clicking (insert link here). You can also click on the Sign Up Now button on the Login screen of SiteMap." },
    { "id": false, "heading": "Signing Up / Logging In:", "ques": "How do I reset my password?", "answ": "If you forgot your password or want to change your password for security reasons, you can click the ‘Forgot Password’ button on the login screen. A verification code will then be sent to the email address associated with your account." },
    { "id": false, "heading": "Signing Up / Logging In:", "ques": "What do I do if I forgot my Login ID?", "answ": "Your username is the email address you used when creating your account." },
    { "id": true, "heading": "Viewing Deliverables:", "ques": "How can I view my deliverables within SiteMap?", "answ": "Once you are logged into SiteMap, as a Free User you will be able to access the Digital Plan Room which houses all the files from any scans that GPRS has done associated with your email address." },
    { "id": false, "heading": "Viewing Deliverables:", "ques": "How do I download my deliverables?", "answ": "All site information and data can be found in the Digital Plan Room. You can either click into different folders to view the different files associated with the different jobs or you can search at the top of the page. Once you have selected the items you would like to download, just click the Download icon on the job itself or at the top of the page to execute your download." },
    { "id": true, "heading": "Sharing content:", "ques": "How can I share a Job with someone?", "answ": "You can click the share icon next to the Job Name from either the Dashboard or the Digital Plan Room to enter an email address to share access with another user." },
    { "id": false, "heading": "Sharing content:", "ques": "How long will someone have access to a Job or Document once I have shared it with them?", "answ": "Once you have shared a Job or Document, anyone who you shared with will have access to it until you remove their access. There are no time limits, and the link will not expire." },
    { "id": false, "heading": "Sharing content:", "ques": "How do I take away access after I have shared something?", "answ": "You can click on the Collaboration icon on the title bar which will bring you to a page with all of the items that have been shared from your account. You can click on the Access Type that was given and change anything here." },
    { "id": true, "heading": "Map Viewer:", "ques": "How do I view a specific site in the Map Viewer?", "answ": "You can either click on a droplet icon on your map and click onto the job, or you can zoom into a specific area on the map using the ‘+’ on the map, or by using the scroll wheel on your mouse." },
    { "id": false, "heading": "Map Viewer:", "ques": "Is it possible to change the map from satellite view to something else?", "answ": "In the Map Viewer you have the option to click on the Globe icon to switch to one of the other views. Currently we offer Satellite, Light, Dark, Streets, Satellite Streets, Outdoors, Navigation Day, and Navigation Night views." },
    { "id": true, "heading": "My Preferences:", "ques": "Is there a way to customize what I see on my dashboard?", "answ": "Yes! You can customize how many recent jobs and documents are shown. Simply navigate to ‘My Preferences’ in the drop-down menu on the title bar at the top of the screen." },
    { "id": false, "heading": "My Preferences:", "ques": "How do I update my notification preferences?", "answ": "Once you are logged into your account, you can click ‘My Preferences’ and update your settings for how you receive notifications, as well as how often you receive them." },
    { "id": false, "heading": "My Preferences:", "ques": "How do I update my contact information once I have created my account?", "answ": "After logging in, you can navigate to ‘My Profile’ in the drop-down menu and update your contact information." },
    { "id": true, "heading": "Searching:", "ques": "How can I search for a specific job?", "answ": "There are a couple different ways to search for a job." },
    { "id": false, "heading": "Searching:", "ques": "", "answ": "First, you can use the Search icon on the anchored title bar at the top of the page. If you search this way, you will notice that all of the blue job dots disappear on the map and are replaced with red dot(s) – which will be reflective of the search performed." },
    { "id": false, "heading": "Searching:", "ques": "", "answ": "You can also search through the All Jobs list by clicking ‘View All Jobs’ from the Dashboard. Searching from this page will provide a list view of the search results." },
    { "id": false, "heading": "Searching:", "ques": "Can I search by address?", "answ": "Yes, you can search by address, job name, work order number, customer name, latitude/longitude or the project manager name!" },

  ]


  characters = [
    'Ant-Man',
    'Aquaman',
    'Asterix',
    'The Atom',
    'The Avengers',
    'Batgirl',
    'Batman',
    'Batwoman',
    'Black Canary',
    'Black Panther',
    'Captain America',
    'Captain Marvel',
    'Catwoman',
    'Conan the Barbarian',
    'Daredevil',
    'The Defenders',
    'Doc Savage',
    'Doctor Strange',
    'Elektra',
    'Fantastic Four',
    'Ghost Rider',
    'Green Arrow',
    'Green Lantern',
    'Guardians of the Galaxy',
    'Hawkeye',
    'Hellboy',
    'Incredible Hulk',
    'Iron Fist',
    'Iron Man',
    'Marvelman',
    'Robin',
    'The Rocketeer',
    'The Shadow',
    'Spider-Man',
    'Sub-Mariner',
    'Supergirl',
    'Superman',
    'Teenage Mutant Ninja Turtles',
    'Thor',
    'The Wasp',
    'Watchmen',
    'Wolverine',
    'Wonder Woman',
    'X-Men',
    'Zatanna',
    'Zatara',
  ];
  constructor(
    private behaviourSubjectService: BehaviorSubjectService) { }

  //# ngOnInit function on page load   
  ngOnInit(): void {
    console.log("ngOnInit FAQ without modules");
    this.changeSearchValueSubscription = this.behaviourSubjectService.helpSearchValueObservable.subscribe((searchVal) => {
      this.searchValue = searchVal;
    });
    this.changeSelectedTabSubscription = this.behaviourSubjectService.helpSelectedTabObservable.subscribe((tab) => {
      this.selectedTab = tab;
      console.log("this.selectedTab", this.selectedTab)
      if (this.selectedTab === 'videos' && this.searchValue) {
        // this.getSearchList();
      }
      if (this.selectedTab === 'resetsearch') {
        this.searchValue = '';
      }
    });
  }

  //# unsubscribe behaviuor subject services  
  ngOnDestroy(): void {
    console.log("Destroy FAQ without modules")
    this.changeSearchValueSubscription?.unsubscribe();
    this.changeSelectedTabSubscription?.unsubscribe();
  }

}
