import { Component, OnInit, Inject, ElementRef, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { EventMessage, EventType, AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, InteractionRequiredAuthError } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { BehaviorSubjectService } from './services/behaviorsubject.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingService } from './modules/setting/services/setting.service';
import { Default_MyPreferences } from './constants/mypreference.constant';
import { ApiService } from './services/api.service';
import { CommonFunctionService } from './services/common-function-service';
import { ToastrService } from 'ngx-toastr';
import { FeatureTypeStyleMapping } from './constants/featureTypeIdStylingMapping';
import { PortalAPI } from './constants/api.constant';
import { ConnectionService } from './modules/sitemapadmin/services/connection-service';
import { PermissionService } from './services/common/permission.service';
import { environment } from 'src/environments/environment';
import { CredentialessLogin } from './common-component/credentialess-login/credentialess-login';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'GPRS SiteMap';
  isIframe = false;
  loginDisplay = false;
  appcomponentheader = false;
  username: any;
  loginTriggered = false;
  private readonly _destroying$ = new Subject<void>();
  mypreferencesList: any = [];
  default_preference_obj: any = {};
  accessTokenResponse: any = null;
  Default_MyPreferences_Obj: any = new Default_MyPreferences(); //default mypreferences object
  azureAdb2cUserDetails: any = {};
  userDetailsFromDb: any = {};
  fetchUserDetailsFRomDBSubscription: any;
  default_homepage: any;
  time: any;
  sessionExpire = "You have been logged out due to inactivity.";
  timeLeft: number = 30;
  interval: any;
  // idelTimeInterval: number = 600000;
  idelTimeInterval: number = 3600000;
  clickInside: boolean = false;
  beforeUnload: boolean = false;
  checkUserActiveFlag: boolean = false;
  tncUrl: string | null = null;
  credentialessLogin: CredentialessLogin={userLoggedIn:false};
  isCredentialessLogin: boolean = false;

  @HostListener('mouseover', ['$event'])
  onMouseOver(event: any) {
    this.resetTimer();
  }

  @HostListener('mouseout', ['$event'])
  onMouseOut(event: any) {
    this.clickInside = false;
  }
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: any) {
    this.resetTimer();
  }
  @HostListener('click', ['$event'])
  onClick(event: Event) {
    this.clickInside = false;
    if (this.elem.nativeElement.contains(event.target))
      this.clickInside = true;
    else
      this.clickInside = false;
  }

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private spinner: NgxSpinnerService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private behaviourSubjectService: BehaviorSubjectService,
    private router: Router,
    private route: ActivatedRoute,
    private settingservice: SettingService,
    private apiService: ApiService,
    private commonService: CommonFunctionService,
    private toastr: ToastrService,
    private restService: ConnectionService,
    private elem: ElementRef,
    private permissionservice:PermissionService
  ) {

    this.autoLogout();
    this.convertFeatureStyleJsonToMap();
    this.spinner.show();
    this.isExportModalP();
    let prefData: any;
    try {
      prefData = JSON.parse(sessionStorage.getItem('preferences') || '');
    } catch (error) {
    }

    if (prefData) {
      this.behaviourSubjectService.setMyPreferencesObjData(prefData, true);
    }
    this.isIframe = window !== window.parent && !window.opener;
    //console.log(this.authService?.instance?.getAllAccounts(), "accountsCheck")
    if (this.authService?.instance?.getAllAccounts()?.length === 0) {
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          console.log(payload.account, "line72");
          if (payload?.account && sessionStorage.getItem("token")) {
            this.apiService.getUserDetails(payload.account.localAccountId, payload.account.username).subscribe((response: any) => {
              if (response && response?.userId != -1 && response.isActive) {
                /*** User assign permissions */
                this.permissionservice.getPermissionsByUserID(response.userId).subscribe((apiresponse: any) => {
                  sessionStorage.setItem("PermissionObj", JSON.stringify(apiresponse));
                  this.behaviourSubjectService.updareCurrentUserPermissionDetails(JSON.stringify(apiresponse));

                });

                this.createDefaultFolderForDPR(response.userId);
                
                sessionStorage.setItem("loginUserId", response.userId);
                localStorage.setItem("loginUserId", response.userId);
                sessionStorage.setItem("loginUserRole", response.role);
                sessionStorage.setItem("loginUserRoleId", response.roleId);
                sessionStorage.setItem("isFreemiumUserStatusActive", response.isFreemiumUserStatusActive);
                sessionStorage.setItem("loginUserEmailId", response.email);

                this.getUserPreferenceDetailsByUserID(response.userId);

                // broadcasting that the userid has been set in the session.  This is used when a user is logging in after accessing
                // a credentialess login
                this.behaviourSubjectService.changeLoginUserIdSetInSession(response.userId);  

                this.commonService.getLoginHistory(response.userId).subscribe(res => {
                  if (res && res.userLoginHistoryEntities && res.totalRecord > 0) {
                    if (res && res.userLoginHistoryEntities && response?.role === 'Client') {
                      if (res?.userLoginHistoryEntities.filter((it: any) => it.logoutTime).length > 5
                        && (!response?.mobileNumber || response?.mobileNumber == ' ' || response?.mobileNumber == '0' || !response?.displayName || response?.displayName == ' ' || !response?.email || response?.email == ' ')
                      ) {
                        sessionStorage.setItem("profileIncomplete", 'true');
                      }
                    }
                    if (res?.userLoginHistoryEntities.filter((it: any) => !it.logoutTime).length > 0) {
                      // res?.userLoginHistoryEntities.filter((it: any) => !it.logoutTime)?.forEach((ele: any) => {
                      //   if (new Date(ele.loginTime).toLocaleDateString() === new Date().toLocaleDateString()) {
                      sessionStorage.setItem("loginCheck", "true");
                      this.behaviourSubjectService.setAlreadyLogin(sessionStorage.getItem('loginCheck'));
                      //   }
                    }
                    else {
                      this.setLoginDetail(response.userId);
                    }
                    // });
                    return;
                    // }
                  }
                  else {
                    this.setLoginDetail(response.userId);
                  }
                });
              }
            }, err => {
              console.log("getUserDetails: ", err);
            });
          }
          this.authService.instance.setActiveAccount(payload.account);


          this.behaviourSubjectService.currentAccessToken.pipe(filter(token => token !== null && token !== ""), first()).subscribe(() => {
            this.behaviourSubjectService.currentUserDetailsFromDB.pipe(filter(data => data !== null && data.userId), first()).subscribe(() => {
              const userId = sessionStorage.getItem("loginUserId") || "";
              this.storeIpStack(userId);
            });
          });
        });
    }
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      ).subscribe(async () => {
        this.behaviourSubjectService.changeLoginStatus(this.authService.instance.getAllAccounts().length > 0);
        if (this.authService.instance.getAllAccounts().length > 0) {
          //this.azureAdb2cUserDetails = this.authService.instance.getAllAccounts()[0];
          //this.fetchUserDetails();
        }
        if ((this.authService.instance.getAllAccounts().length > 0) &&
         (this.router.url == "/dashboards" || this.router.url == "/" || this.router.url.toLowerCase().includes("/mapviewerjobs"))) {
          this.behaviourSubjectService.updateCurrentUserDetailsFromAzureADB2C(this.authService.instance.getAllAccounts()[0]);
          await this.getAccessToken();


          /**
             * @desc AccessToken can be null-> thus a fallback for it
             */
          if (this.accessTokenResponse) {
			      sessionStorage.setItem("token", this.accessTokenResponse.accessToken);
            this.behaviourSubjectService.updateAccessToken(this.accessTokenResponse.accessToken);
            //this.metadataService.loadGroupTypeMetadata();
            this.azureAdb2cUserDetails = this.authService.instance.getAllAccounts()[0];
            if(!this.checkUserActiveFlag)
              this.fetchUserDetails();
            } else {
              sessionStorage.clear();
              this.authService.logout();
              this.router.navigate([""]);
          }

        } else {
          console.log("should be redirected to login page");
          this.login('client');
          this.spinner.hide();
        }
      });

  }

  private async getAccessToken() {
    var clientId = environment.CLIENT_ID;
    var tokenRequest = {
      scopes: ['openid',
        'profile',
        'email',
        'offline_access',
        clientId]
    };
    this.accessTokenResponse = await this.authService.instance.acquireTokenSilent(tokenRequest).catch(error => {
      console.log(error);
      if (error instanceof InteractionRequiredAuthError) {
        this.spinner.hide();
        console.log(error);
        return this.authService.instance.acquireTokenRedirect(tokenRequest);
        // return error;
      } else {
        return null;
      }
    });
  }

  //#region setLoginDetail is used fot set login detail in the db
  setLoginDetail(userId: any) {
    this.commonService.setloggedInOROutApi(userId, true).subscribe(result => {
      if (result && result.processingStatus?.status.includes("Failure")) {
        const toasterMsg = "Login Entry details are not save due to user not found in DB, please connect with technical Team!";
        this.toastr.error(toasterMsg, "", {
          timeOut: 5000
        });
      }
    }, err => {
      console.log("setloggedInOROutApi: ", err);
    });
  }
  //#endregion

  /**Function to store ipstack data of user */
  storeIpStack(userId: string){
    this.restService.getExtenalAPI(PortalAPI.IP_STACK).subscribe(response => {
      this.restService.tenantId = "1";
      localStorage.setItem("ip", response.ip);
      localStorage.setItem("countryName", response.country_name);
      localStorage.setItem("regionName", response.region_name);
      localStorage.setItem("city", response.city);
      localStorage.setItem("zip", response.zip);
      localStorage.setItem("latitude", response.latitude);
      localStorage.setItem("longitude", response.longitude);
      let body = {
        userId,
        isLoggedin: true,
        time: new Date(),
        ip: response.ip,
        countryName: response.country_name,
        regionName: response.region_name,
        city: response.city,
        zip: response.zip,
        latitude: '"'+response.latitude+'"',
        longitude: '"'+response.longitude+'"',
        isProxy: true,
        isCrawler: true
      };
       this.restService.post(PortalAPI.SET_LOGGEDIN_HISTORY_ASYNC, body).subscribe((response:any)=>{
            console.log(response,'000000000000')
       });
    //success code
    }, err => {
  });
  }

  ngOnInit(): void {
    this.behaviourSubjectService.credentialessLoginObservable.subscribe((credentialessLogin:CredentialessLogin) => {
      this.credentialessLogin = credentialessLogin;
      if (this.credentialessLogin?.guid) {
        this.isCredentialessLogin = true;
      } else {
        this.isCredentialessLogin=false;
      }
    });
    
   this.behaviourSubjectService.isAlreadyLoginObservable.subscribe((res: any) => {
      if (sessionStorage.getItem("loginCheck") === undefined || !sessionStorage.getItem("loginCheck")) {
        this.behaviourSubjectService.callLoginFromHomePageObservable.subscribe((user) => {
          console.log(user, "appcomp");
          if (user == 'client') {
            this.login();
          }
          if (user == 'gprs') {
            var data: any = {
              state: "angulargprs" //set state parameter (type: string)
            };
            this.login(data);
          }
        });

        this.behaviourSubjectService.httpErrorObservable.subscribe(data => {
          if (data) {
            this.spinner.hide();
            // let button = document.getElementById("btnModalunauthorized") as HTMLButtonElement;
            // button.click();
          }
        });
      }
      else {
        if (sessionStorage.getItem("loginCheck") && sessionStorage.getItem("loginCheck") === "true") {
          let button = document.getElementById("btnModalLogin") as HTMLButtonElement;
          button.click();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    if (sessionStorage.getItem("timeOut") && sessionStorage.getItem("timeOut") === 'idle') {
      this.sessionExpire = "You have been logged out due to inactivity.";
      let button = document.getElementById("btnModalIdle") as HTMLButtonElement;
      button.click();
      this.startTimer();

    }
    if (!sessionStorage.getItem("loginCheck") && sessionStorage.getItem("profileIncomplete") && sessionStorage.getItem("profileIncomplete") === "true") {
      sessionStorage.removeItem("profileIncomplete");
      let button = document.getElementById("btnModalProfile") as HTMLButtonElement;
      button.click();
    }
    if(this.clickInside) {
      this.clickInside = false;
    }
  }

  /**
  * @desc open login screen on azure ad b2c
  */
  login(userFlowRequest?: any) {
    // this.spinner.show();
    this.loginTriggered = true;
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest).subscribe((response) => {
          console.log(response);
        });
      } else {
        this.authService.loginRedirect(userFlowRequest).subscribe((response) => {
          console.log(response);
        });
      }
    }
  }
  //# @desc get loggedIn user details from DB
  fetchUserDetails() {
    this.spinner.show();
    this.fetchUserDetailsFRomDBSubscription = this.apiService.getUserDetails(this.azureAdb2cUserDetails.localAccountId, this.azureAdb2cUserDetails.username).subscribe((result: any) => {
      this.userDetailsFromDb = result;
      sessionStorage.setItem("loginUserId", result.userId);
      sessionStorage.setItem("tierId", result.tierId);
      sessionStorage.setItem("loginUserRole", result.role);
      sessionStorage.setItem("loginUserRoleId", result.roleId);
      sessionStorage.setItem("isFreemiumUserStatusActive", result.isFreemiumUserStatusActive);
      sessionStorage.setItem("loginUserEmailId", result.email);
      sessionStorage.setItem("userRenderingScale", result.renderingScale);

      this.createDefaultFolderForDPR(result.userId);
      
      // broadcasting that the userid has been set in the session.  This is used when a user is logging in after accessing
      // a credentialess login
      this.behaviourSubjectService.changeLoginUserIdSetInSession(result.userId);

      this.getUserPreferenceDetailsByUserID(result.userId);
      //this.getUserSyncByEmailMobileNo();
      if (!this.userDetailsFromDb.isActive && !this.checkUserActiveFlag) {
        this.toastr.error("Your Account is Inactive Please contact to System Admin..!!");
		    this.checkUserActiveFlag = true;
        setTimeout(() => {
          sessionStorage.clear();
          this.authService.logout();
          this.router.navigate([""]);
        }, 2000);

      }
      else if (result.showTNC) {

        const modal = document.getElementById('tncModal') as HTMLDivElement;
        const isHidden = Array.from(modal.classList).indexOf('show') == -1;
          if (isHidden) {
            const button = document.getElementById("btnTncModal") as HTMLButtonElement;
            button.click();
            if (!this.tncUrl) {
              const typeId = result.tierId;
              this.apiService.getLatestTnc(typeId).subscribe(x => this.tncUrl = x.url, error => console.error(error));
            }
          }
      }

         /*** User assign permissions */
         this.permissionservice.getPermissionsByUserID(result.userId).subscribe((apiresponse: any) => {
          sessionStorage.setItem("PermissionObj", JSON.stringify(apiresponse));
          this.behaviourSubjectService.updareCurrentUserPermissionDetails(JSON.stringify(apiresponse));

        });

      this.behaviourSubjectService.updateCurrentUserDetailsFromDB(this.userDetailsFromDb);
      this.spinner.hide();
    });
  }



  ngOnDestroy(): void {
    this.fetchUserDetailsFRomDBSubscription.unsubscribe();

    // this._destroying$.next(undefined);
    // this._destroying$.complete();
  }




  //# @desc call mypreference list endpoint to set mypreferences
  getUserPreferenceDetailsByUserID(userid: any) {
    let userInfo = { userId: userid };
    this.settingservice.getMyPreferencesList(userInfo).subscribe((response) => {
      this.mypreferencesList = response;
      this.mypreferencesList?.map((e: any) => {
        this.default_preference_obj[e.name] = e.value;
      });
      for (let prop in this.Default_MyPreferences_Obj) {
        if (this.default_preference_obj[prop] !== undefined) {
          this.Default_MyPreferences_Obj[prop] = this.default_preference_obj[prop];
        }
      }
      this.behaviourSubjectService.setMyPreferencesObjData(this.Default_MyPreferences_Obj);
      this.default_homepage = this.Default_MyPreferences_Obj.DEFAULT_HOMEPAGE;
      let urlCheck = sessionStorage.getItem('redirectToUrl');
      //let urlCheck = sessionStorage.getItem('mapViewerUrl');
      if (!this.isCredentialessLogin){
        // if logging in from a credentialess link, then do not redirect to default homepage.  User should automatically go to the
        // mapviewerjob route.
        if (urlCheck) {
          // sessionStorage.removeItem('mapViewerUrl');
          sessionStorage.removeItem('redirectToUrl');
          this.router.navigate([urlCheck]);
        }
        else {
          if (this.default_homepage === 'DASHBOARD') {
            this.router.navigate(['/dashboards']);
          } else if (this.default_homepage === 'MAP_VIEWER') {
            this.router.navigate(['/mapviewer/null/null/null']);
          } else {
            this.router.navigate(['/digitalplanmf']);
          }
        }
      } else {
        this.router.navigate([`/credentialess/${this.credentialessLogin.guid}`]);
      }
      

    }, (err) => {
      console.log(err);
    });
  }
  //# get user details sync by emailId Mobile No.
  getUserSyncByEmailMobileNo() {
    if (this.userDetailsFromDb.mobileNumber == null) {
      this.userDetailsFromDb.mobileNumber = 0;
    }
    let userInfo = { emailId: this.userDetailsFromDb.email, contactno: this.userDetailsFromDb.mobileNumber };
    this.apiService.userSyncByEmailMobileNo(userInfo).subscribe((response) => {
      const resp: any = response;
    }, (err) => {
      console.log(err);
    });
  }

  //# convert all features to style json map for using features type icons and stylisation
  convertFeatureStyleJsonToMap() {

    let hashMap = new Map();
    let pointFeaturesNameSet = new Set<string>();
    FeatureTypeStyleMapping.featureTypeStyleJson.forEach((feature) => {
      hashMap.set(feature.feature_type_id, feature);
    });
    FeatureTypeStyleMapping.iconsPresent.forEach((iconName: any) => {
      pointFeaturesNameSet.add(iconName);
    });
    sessionStorage.setItem("featureTypeStyleMap", JSON.stringify(Array.from(hashMap.entries())));
    sessionStorage.setItem("pointFeaturesNameArray", JSON.stringify(Array.from(pointFeaturesNameSet)));

  }

  resetTimer() {
    if (this.authService.instance.getAllAccounts().length > 0) {
      clearTimeout(this.time);
      this.time = setTimeout(this.logout, this.idelTimeInterval, this.authService, this.commonService, this.setLoggedOutApi);//60 minutes
    }
  }

  //# logout from the application
  logout(service: any, commonService: any, logoutFunction: any) {
    if (!sessionStorage.getItem("timeOut")) {
      sessionStorage.setItem("timeOut", "idle");
      this.sessionExpire = "You have been logged out due to inactivity.";
      let button = document.getElementById("btnModalIdle") as HTMLButtonElement;
      button.click();
      let btnTimer = document.getElementById("btnTimer") as HTMLButtonElement;
      btnTimer.click();
    }
    //logoutFunction(service,commonService);
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
      if (this.timeLeft === 0) {
        this.onCancelModal();
      }
    }, 1000)
  }

  //# logout api for logout process
  setLoggedOutApi(service: any, commonService: any) {
    this.spinner.show();
    const allAccountData = this.authService.instance.getAllAccounts();
    if (allAccountData.length > 0) {
      const azureADId = allAccountData[0].localAccountId;
      const emailId = allAccountData[0].username;
      this.apiService.getUserDetails(azureADId, emailId).subscribe((response: any) => {
        if (response && response.userId != -1) {
          commonService.setloggedInOROutApi(response.userId, false).subscribe((result: any) => {
            if (result && result.processingStatus?.status.includes('Success')) {
              localStorage.clear();
              sessionStorage.clear();
              service.logout();
              this.router.navigate([""]);
              this.spinner.hide();
            }
            else if (result && result.processingStatus?.status.includes("Failure")) {
              this.toastr.error("App component error in Logout!", "", {
                timeOut: 3000
              });
              this.spinner.hide();
            }
          }, (err: any) => {
            console.log("app component logout setloggedInOROutApi: ", err);
            this.toastr.error(err, "", {
              timeOut: 3000
            });
            this.spinner.hide();
          });
        }
      });
    }
  }

  onCancelModal() {
    this.setLoggedOutApi(this.authService, this.commonService);
  }

  //# create default folder for dpr
  createDefaultFolderForDPR(userId: any) {
    this.restService.tenantId = "1";
    this.restService.put(PortalAPI.CREATE_DEFAULT_FOLDERS_FOR_USER + `/${userId}`).subscribe(response => {
      //success code
    }, err => {
      console.error("getFolderChildren error: " + err.status, "", { timeOut: 3000 });
    });
  }

  //# auto logout when user in idle mode
  autoLogout() {
    if (sessionStorage.getItem("loginUserId")) {
      this.commonService.getLoginHistory(sessionStorage.getItem("loginUserId")).subscribe(response => {
        if (response && response?.userLoginHistoryEntities && response?.totalRecord > 0) {
          let date1 = new Date(response?.userLoginHistoryEntities[0]?.loginTime);
          let date2 = new Date();
          let diff = date2.getTime() - date1.getTime();
          let msec = diff;
          let hh = Math.floor(msec / 1000 / 60 / 60);
          msec -= hh * 1000 * 60 * 60;
          let mm = Math.floor(msec / 1000 / 60);
          msec -= mm * 1000 * 60;
          let ss = Math.floor(msec / 1000);
          msec -= ss * 1000;
          console.log(hh + ":" + mm + ":" + ss);
          if (hh >= 6 && mm >= 0) {
            this.onCancelModal();
            // sessionStorage.setItem("timeOut","idle");
            // this.sessionExpire = "You have been logged out due to token expire.";
            // let button = document.getElementById("btnModalIdle") as HTMLButtonElement;
            // button.click();
          }
        }
      });
    }
  }

  isExportModalP() {
    console.log("isVisible isExportModalP",);
    this.behaviourSubjectService.changeisExportModal(true);
  }

  doBeforeUnload(event:any) {
    // Alert the user window is closing
    // console.log("performance.getEntriesByType('navigation'): ", performance.getEntriesByType('navigation'));
    // let temp = performance.getEntriesByType('navigation');
    // let navType = temp.map((event:any)=> event.type);
    // console.log("cc: ",navType);
    // navType[0] === 'reload'
    // if(document.visibilityState != 'visible' && sessionStorage.getItem('loginUserId') && !this.clickInside && navType[0] != 'reload') {
    //   this.beforeUnload = true;
    //   return false;
    // } else {
      event.preventDefault();
      console.log("event returnValue: ", event.returnValue);
      return null;
    // }
  }

  doUnload() {
    // Clear session or do something
    // if (!this.clickInside) {
    let temp = performance.getEntriesByType('navigation');
    let navType = temp.map((event:any)=> event.type);
    if((document.visibilityState != 'visible' && sessionStorage.getItem('loginUserId') && !this.clickInside)) {
      const restServiceBody = {
          userId: sessionStorage.getItem('loginUserId'),
          isLoggedin: false,
          time: new Date().toISOString(),
          ip: localStorage.getItem("ip"),
          countryName:localStorage.getItem("countryName"),
          regionName:localStorage.getItem("regionName"),
          city:localStorage.getItem("city"),
          zip:localStorage.getItem("zip"),
          latitude:localStorage.getItem("latitude"),
          longitude:localStorage.getItem("longitude"),
      }
      sessionStorage.clear();
      fetch(PortalAPI.SET_LOGGEDIN_HISTORY_ASYNC, {
        keepalive: true,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          TenantId: '1',
          Authorization: 'Bearer ' + sessionStorage.getItem("token")
          // any header you can pass here
        },
        body: JSON.stringify(restServiceBody),
      });
    }
  }

  resetIdle() {
    if (sessionStorage.getItem("timeOut") && sessionStorage.getItem("timeOut") === 'idle') {
      sessionStorage.removeItem("timeOut");
      window.clearInterval(this.interval)
      this.timeLeft = 30;
    }
  }

  closeActiveSessions(){
    this.commonService.closeAllActiveSessions(sessionStorage.getItem("loginUserId")).subscribe(response =>{
      localStorage.clear();
      sessionStorage.clear();
      this.authService.logout();
      this.router.navigate([""]);
      this.spinner.hide();
    });
  }

  onAcceptTnC($event: Event) {
    const checkbox = $event.target as HTMLInputElement;
    const checked = checkbox.checked;
    const userIdStr = sessionStorage.getItem("loginUserId") || "";
    const tierIdStr = sessionStorage.getItem("tierId") || "";
    if (checked && userIdStr && tierIdStr) {

      const userId = parseInt(userIdStr);
      const tncTypeId = parseInt(tierIdStr);

      this.apiService.acceptTNC(userId, tncTypeId).subscribe(() => { /* do nothing after accept TNC */});
    }
  }
}
