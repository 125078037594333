import {noTarget, isOfMetaType, isActiveFeature, isInactiveFeature, isShiftDown} from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
import constrainFeatureMovement from '@mapbox/mapbox-gl-draw/src/lib/constrain_feature_movement';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';
import DirectSelect from '@mapbox/mapbox-gl-draw/src/modes/direct_select';

import showSnappingFeature, { snapFeatureLayerId, getSiteMapMVTLayerIdsById } from './mode_utils';

const isVertex = isOfMetaType(Constants.meta.VERTEX);
const isMidpoint = isOfMetaType(Constants.meta.MIDPOINT);


function siteMapDirectSelect() {

  const SiteMapDirectSelect = { ...DirectSelect };

  SiteMapDirectSelect.onSetup = function(opts) {
    const featureId = opts.featureId;
    const feature = this.getFeature(featureId);

    if (!feature) {
      throw new Error('You must provide a featureId to enter direct_select mode');
    }

    if (feature.type === Constants.geojsonTypes.POINT) {
      throw new TypeError('direct_select mode doesn\'t handle point features');
    }

    const state = {
      featureId,
      feature,
      dragMoveLocation: opts.startPos || null,
      dragMoving: false,
      canDragMove: false,
      selectedCoordPaths: opts.coordPath ? [opts.coordPath] : []
    };

    this.mapLayerId = opts.mapLayerId;
    this.isSnappingToLine = opts.isSnappingToLine;
    this.isSnappingToVertex = opts.isSnappingToVertex;
    this.isSnapping = this.isSnappingToLine || this.isSnappingToVertex;
    this.navigatedFeatures = opts.navigatedFeatures;

    if (this.mapLayerId !== undefined) {
      this.siteMapMVTLayerIds = getSiteMapMVTLayerIdsById(this.map, this.mapLayerId);
    }

    this.setSelectedCoordinates(this.pathsToCoordinates(featureId, state.selectedCoordPaths));
    this.setSelected(featureId);
    doubleClickZoom.disable(this);

    this.setActionableState({
      trash: true
    });

    return state;
  };

  SiteMapDirectSelect.onDrag = function(state, e) {
    // Remove snapping features
    if (this.getFeature(snapFeatureLayerId) !== undefined) {
      this.deleteFeature(snapFeatureLayerId);
    }
    if (state.canDragMove !== true) return;
    state.dragMoving = true;
    e.originalEvent.stopPropagation();

    const delta = {
      lng: e.lngLat.lng - state.dragMoveLocation.lng,
      lat: e.lngLat.lat - state.dragMoveLocation.lat
    };
    if (state.selectedCoordPaths.length > 0) this.dragVertex(state, e, delta);
    else this.dragFeature(state, e, delta);

    state.dragMoveLocation = e.lngLat;
  };

  SiteMapDirectSelect.dragVertex = function(state, e, delta) {
    // Mouse coords
    let lat = e.lngLat.lat;
    let lng = e.lngLat.lng;

    if (this.isSnapping) {
      let selectedFeature = this.getFeature(state.featureId);

      // These are the "subject" lng/lat coordinates, e.g. the moving mouse cursor
      let geographicalCoordinates = [lng, lat];

      let movingFeature = {
        id: 'direct_select_feature',
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: geographicalCoordinates
        }
      }

      showSnappingFeature.call(
        this,
        geographicalCoordinates,
        movingFeature,
        selectedFeature.id,
        'direct_select');
    }

    const selectedCoords = state.selectedCoordPaths.map(coord_path => state.feature.getCoordinate(coord_path));
    const selectedCoordPoints = selectedCoords.map(coords => ({
      type: Constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: Constants.geojsonTypes.POINT,
        coordinates: coords
      }
    }));

    const constrainedDelta = constrainFeatureMovement(selectedCoordPoints, delta);
    for (let i = 0; i < selectedCoords.length; i++) {
      const coord = selectedCoords[i];
      state.feature.updateCoordinate(state.selectedCoordPaths[i], coord[0] + constrainedDelta.lng, coord[1] + constrainedDelta.lat);
    }
  };

  SiteMapDirectSelect.onTouchEnd = SiteMapDirectSelect.onMouseUp = function(state, e) {
    console.log('onMouseUp', 'state', state, 'e', e);
    if (this.isSnapping) {
      // Get the feeature of the Vertex being dragged
      let movingFeature = state.feature;
      if (isVertex(e)) {
        let movingVertexIndex = parseInt(state.selectedCoordPaths[0]);
        // Get the snap feature from the map, or undefined if it does not exist
        let snapFeature = this.getFeature(snapFeatureLayerId);
        if (snapFeature !== undefined) {
          this.deleteFeature(snapFeatureLayerId);
          movingFeature.coordinates[movingVertexIndex] = snapFeature.coordinates;
          this.addFeature(movingFeature);
        }
      }

    }

    if (state.dragMoving) {
      this.fireUpdate();
    }
    this.stopDragging(state);
  };

  SiteMapDirectSelect.onMouseMove = function(state, e) {
    // On mousemove that is not a drag, stop vertex movement.
    const isFeature = isActiveFeature(e);
    const onVertex = isVertex(e);
    const noCoords = state.selectedCoordPaths.length === 0;
    if (isFeature && noCoords) this.updateUIClasses({ mouse: Constants.cursors.MOVE });
    else if (onVertex && !noCoords) this.updateUIClasses({ mouse: Constants.cursors.MOVE });
    else this.updateUIClasses({ mouse: Constants.cursors.NONE });
    this.stopDragging(state);

    // Skip render
    return true;
  };

  SiteMapDirectSelect.toDisplayFeatures = function(state, geojson, push) {
    if (state.featureId === geojson.properties.id) {
      geojson.properties.active = Constants.activeStates.ACTIVE;
      push(geojson);
      createSupplementaryPoints(geojson, {
        map: this.map,
        midpoints: true,
        selectedPaths: state.selectedCoordPaths
      }).forEach(push);
    } else {
      geojson.properties.active = Constants.activeStates.INACTIVE;
      push(geojson);
    }
    this.fireActionable(state);
  };

  return SiteMapDirectSelect
}


export default siteMapDirectSelect;
