import { Component, OnInit } from '@angular/core';
import imageCompression from 'browser-image-compression';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PortalAPI } from 'src/app/constants/api.constant';
import { IAllMessage } from 'src/app/modules/dashboards/constant/message-model';
import { BehaviorSubjectService } from 'src/app/services/behaviorsubject.service';
import { ISaveMessage } from '../../constants/board-message';
import { ConnectionService } from '../../services/connection-service';

@Component({
  selector: 'app-board-message',
  templateUrl: './board-message.component.html',
  styleUrls: ['./board-message.component.css']
})
export class BoardMessageComponent implements OnInit {

  saveMessageData!: ISaveMessage;
  messageList: IAllMessage[] = [];
  base64textString: string = "";
  base64textStringImage: string = "";
  isBase64StringEmpty: boolean = true;
  txtNewDescription: string = "";
  isCheckGprsUser: boolean = false;
  isCheckNonGprsUser: boolean = false;
  currentUserDetailSubscription: Subscription | null = null;
  userDetails: any;
  txtDisable: boolean = true;
  btnDisable: boolean = true;
  currentMessageDetail: any;
  currentMessageId: any;
  newMessage:ISaveMessage[] = [];
  newMessageIndex: number = 0;
  newMessageHeading = "Message 1";
  deleteMessageProperty: any;
  fileMessageType: any;
  dataFromFileSelection: any;
 external:any;
 externalNew:any;

  constructor(
    private behaviourSubjectService: BehaviorSubjectService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private restService:ConnectionService,
  ) { 
    this.currentUserDetailSubscription = this.behaviourSubjectService.currentUserDetailsFromDB.subscribe((userDetails) => {
      this.userDetails = userDetails;
    });
  }

  ngOnInit(): void {
    this.getAllMessages();
  }

  ngOnDestroy() {
    this.currentUserDetailSubscription?.unsubscribe();
  }

  //#region getJobList method is use to get all the job list through the GetAllJobs api
  getAllMessages()
  {
    this.spinner.show();
    this.messageList = [];
    this.restService.tenantId = '1';
    const restServiceBody={
      "request": {
        "pageNumber": 1,
        "pageSize": 100
      }
    }
    this.restService.post(PortalAPI.GET_ALL_Messages,restServiceBody).subscribe(response=>{
      if(response && response.messageEntities && response.totalRecords > 0)
      {
        this.messageList = response.messageEntities;
        this.messageList.forEach(element => {
          if(element.isExternal==true){
            element.gprsUser=false;
            element.nonGprsUser=true;
          }
          else{
            element.gprsUser=true;
            element.nonGprsUser=false;
          }
        });
        const temp = {
          messageId: this.messageList[0].messageId,
          textMessage: "",
          imageMessage: "",
          createdBy: "",
          lastUpdatedBy: "",
          isExternal: false
        }
        // this.newMessage.push(temp);
        this.newMessageHeading = "Message 1";
        this.spinner.hide();
        this.currentMessageId = this.messageList[0].messageId;
         
      }
      else
      {
        this.spinner.hide();
      }
    })
  }
  //#endregion

  async commonMethodForFileUpload(evt:any) {
    var files = evt.target.files;
    var file = files[0];
    if (files && file) {
      if (file.size > 1.7 * (2 ** 21)) {
        this.toastr.error("Please upload image less than 3MB", "", {
          timeOut: 2000
        });
      } else {
        const options = {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1000,
          useWebWorker: true
        }

        try {
          var compressedFile = await imageCompression(file, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024} KB`);
          var reader = new FileReader();

          reader.onload = this._handleReaderLoaded.bind(this);
          reader.readAsBinaryString(compressedFile);
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  //#region handleFileSelect method is used to get the selected file whatever user select from the file uploader 
  async handleFileSelect(evt: any,message:any,messageType:any) {
    this.dataFromFileSelection = message;
    this.fileMessageType = messageType;
    await this.commonMethodForFileUpload(evt);
  }
  //#endregion

  //#region _handleReaderLoaded method is use to convert the orignal image to base64
  _handleReaderLoaded(readerEvt: any) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    console.log(btoa(binaryString));
    this.base64textStringImage = `data:image/png;base64,${this.base64textString}`;
    this.isBase64StringEmpty = false;
    if(this.fileMessageType?.includes('new'))
      this.newMessage[this.newMessageIndex].imageMessage = this.base64textStringImage;
    else
    {
      const index = this.messageList.findIndex(it=> it.messageId === this.dataFromFileSelection.messageId)
      if(index > -1)
      {
        this.messageList[index].imageMessage = this.base64textStringImage;
        this.btnDisable = false;
      }
    }
  }
  //#endregion

  //#region onNewPublish method is used to save New data in db
  onSaveNewPublish() {
    sessionStorage.removeItem("board_index");
    this.spinner.show();
    
    let isValueInNewMessage =  false;
    
    this.newMessage?.forEach((element:any,index:number)=>{
      if(element.textMessage && element.imageMessage)
      {
        isValueInNewMessage = true;
        // if(element.gprsUser==false && element.nonGprsUser==true){
        //   this.externalNew=true;
        // }
        // if(element.gprsUser==true && element.nonGprsUser==false){
        //   this.externalNew=false;
        // }
        let restServiceBody:any = {
          textMessage: element.textMessage,
          imageMessage: element.imageMessage,
          createdBy: this.userDetails.email,
          lastUpdatedBy: this.userDetails.email,
          isExternal: false
        }
        if(index === 0)
        {
          restServiceBody["messageId"] = Number(element.messageId);
        }
        this.restService.post(PortalAPI.SAVE_MESSAGE_ASYNC,restServiceBody).subscribe(response=>{
          if(response && response.status === "Success")
          {
            if(this.newMessage.length === Number(sessionStorage.getItem("board_index"))+1)
            {
              this.toastr.success("Message board(s) save successfully!", "", {
                timeOut: 3000
              });
              this.clearNewDetails();
              this.newMessage = [];
              this.getAllMessages();
              this.spinner.hide();
            }
          }
          else
          {
            this.toastr.error("Message board not save, please connect with Technical team!", "", {
              timeOut: 3000
            });
          }
        },err=>{
          this.spinner.hide();
          console.error("error save new board message",err);
        }
        );
      }
      else {
        isValueInNewMessage = false;
      }
      sessionStorage.setItem("board_index",index.toString());
    });
    if(!isValueInNewMessage)
    {
      this.toastr.error("Please select/enter value for image and description in New Message pane!", "", {
        timeOut: 3000
      });
      this.spinner.hide();
    }
  }
  //#endregion

  //#region onSaveExistingPublish method is used to save New data in db
  onSaveExistingPublish() {
    let tempArr:any[] = [];
    // var userTypeCheck = (document.getElementById("gprs-users") as HTMLInputElement);
    //  var isGPRSUserChecked = userTypeCheck.checked;

    tempArr.push(this.messageList.find(it=> it.messageId === this.currentMessageId));
    tempArr?.forEach((element:any,index:number)=>{
      this.spinner.show();
      if(element.messageId && element.textMessage && element.imageMessage)
      {
        if(element.gprsUser==false && element.nonGprsUser==true){
          this.external=true;
        }
        if(element.gprsUser==true && element.nonGprsUser==false){
          this.external=false;
        }
        let restServiceBody:any = {
          messageId: Number(element.messageId),
          textMessage: element.textMessage,
          imageMessage: element.imageMessage,
          createdBy: this.userDetails.email,
          lastUpdatedBy: this.userDetails.email,
          
          isExternal: this.external
        }
        this.restService.post(PortalAPI.SAVE_MESSAGE_ASYNC,restServiceBody).subscribe(response=>{
          if(response && response.status === "Success")
          {
            this.toastr.success("Message board save successfully!", "", {
              timeOut: 3000
            });
            this.spinner.hide();
            this.getAllMessages();
          }
          else
          {
            this.toastr.error("Message board not save, please connect with Technical team!", "", {
              timeOut: 3000
            });
            this.spinner.hide();
          }
        });
        this.clearExistDetails();
      }
      else {
        this.toastr.error("Please select/enter value for image and description in New Message pane!", "", {
          timeOut: 3000
        });
        this.spinner.hide();
      }
    });
  }
  //#endregion

  //#region clearNewDetails method is used to clear New data
  clearNewDetails()
  {
    this.base64textString = "";
    this.base64textStringImage = "";
    this.isBase64StringEmpty = true;
    this.txtNewDescription = "";
    this.isCheckGprsUser = false;
    this.isCheckNonGprsUser = false;  
  }
  //#endregion

  //#region onTxtDescription method is used to enable or disable txtarea or btn
  onTxtDescription(event:any){
    this.btnDisable = false;
    this.txtDisable = false;
    this.currentMessageDetail = event;
  }
  //#endregion
  
  btnCurrentDiscard()
  {
    this.clearExistDetails();
    this.getAllMessages();
  }

  //#region clearExistDetails method is used to clear exist data
  clearExistDetails() {
    this.btnDisable = true;
    this.txtDisable = true;
    this.currentMessageDetail = "";
    this.deleteMessageProperty = "";
    this.dataFromFileSelection = "";
    this.fileMessageType = "";
  }
  //#endregion

  //#region carousalClick method is used to enable or disable txtarea or btn
  carousalClick() {
    this.clearExistDetails();
    setTimeout(() => {
      let element = document.querySelectorAll('.current-message');
      element?.forEach(ele=>{
        if(ele.className.includes('active'))
        {
          this.currentMessageId = Number(ele.id);
          //this newmessage is use to update the message id for next or previous
          this.newMessage[0].messageId = this.currentMessageId;
        }
      })
    }, 1000);
  }
  //#endregion

  //#region onCarouselNewClick method is used to enable or disable txtarea or btn
  onCarouselNewClick() {
    this.clearExistDetails();
    setTimeout(() => {
      let element = document.querySelectorAll('.new-message');
      element?.forEach(ele=>{
        if(ele.className.includes('active'))
        {
          this.newMessageIndex = Number(ele.id);
          this.newMessageHeading = "Message " + (this.newMessageIndex + 1);
        }
      });
    }, 800);
  }
  //#endregion

  //#region onAnotherMessageClick method is used to enable or disable txtarea or btn
  onAnotherMessageClick() {
    if(this.newMessage.length < 3) {
      const temp = {
        messageId: "",
        textMessage: "",
        imageMessage: "",
        createdBy: "",
        lastUpdatedBy: "",
        isExternal: false
      }
      this.newMessage.push(temp);
      this.toastr.success("Another Message added successfully in New Message pane, please use arrow keys to navigate another message!", "", {
        timeOut: 4000
      });
    }
    else {
      this.toastr.warning("Only 3 New message allowed!","", {
        timeOut:2000
      });
    }
  }
  //#endregion

  //#region onNewDiscard method is used to discard New data from the entities
  onNewDiscard()
  {
    this.clearNewDetails();
    this.newMessage = [];
    const temp = {
      messageId: "",
      textMessage: "",
      imageMessage: "",
      createdBy: "",
      lastUpdatedBy: "",
      isExternal: false
    }
    this.newMessage.push(temp);
  }
  //#endregion

  //#region onDeleteCurrentMessage method is used to click the delete button in current message
  onDeleteCurrentMessage(message:any){
    this.deleteMessageProperty = message;
  }
  //#endregion

  //#region onDeleteCurrentMessage method is used to delete current message from db using MESSAGE_DELETE_ASYNC api
  modalDelete() {
    if(this.deleteMessageProperty && this.deleteMessageProperty?.messageId) {
      this.spinner.show();
      this.restService.post(PortalAPI.MESSAGE_DELETE_ASYNC+ `/${this.deleteMessageProperty.messageId}`,"").subscribe(response=>{
        if(response && response.status === "Success")
        {
          this.toastr.success("Current Message deleted successfully!", "", {
            timeOut: 3000
          });
          this.spinner.hide();
          this.getAllMessages();
        }
        else
        {
          this.toastr.error("Message board not deleted, please connect with Technical team!", "", {
            timeOut: 3000
          });
          this.spinner.hide();
        }
      });
    }
    else {
      this.toastr.show("Current message not found!","",{ timeOut: 2000})
    }
  }

}
