/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        //signUpSignIn: "B2C_1_signin_signup_SiteMap", // using this userflow only in our application
        // For PROD2
        // signUpSignIn:((window.location.href.includes("webapp-qa-ui")) ||  (window.location.href.includes("webapp-ui")) ||  (window.location.href.includes("4200")) ||  (window.location.href.includes("sitemap-preproduction")) || (window.location.href.includes("afdqa"))) ? "B2C_1_signup-signin-prod" :"B2C_1_signup-signin-prod", // using this userflow only in our application
        signUpSignIn: environment.SIGNUP_SIGNIN, // using this userflow only in our application
        forgot: "b2c_1_reset",
        editProfile: "b2c_1_edit_profile_v2"
    },
    authorities: {
        signUpSignIn: {
            // For Prod2
            // authority:((window.location.href.includes("webapp-qa-ui")) ||  (window.location.href.includes("webapp-ui")) ||  (window.location.href.includes("4200")) ||  (window.location.href.includes("sitemap-preproduction")) || (window.location.href.includes("afdqa"))) ? "https://gprssitemap.b2clogin.com/gprssitemap.onmicrosoft.com/B2C_1_signup-signin-prod" :"https://gprssitemap.b2clogin.com/gprssitemap.onmicrosoft.com/B2C_1_signup-signin-prod", // using this userflow only in our application
            authority: environment.B2C_LOGIN,
            // authority: "https://gprsclients.b2clogin.com/gprsclients.onmicrosoft.com/B2C_1_signin_signup_SiteMap",
            // authority: "https://gprssitemap.b2clogin.com/gprssitemap.onmicrosoft.com/B2C_1_signup-signin-prod",   // prod

        },
        editProfile: {
            authority: "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2"
        },
        forgot: {
            authority: "https://your-tenant-name.b2clogin.com/your-tenant-name.onmicrosoft.com/b2c_1_reset",
        },
    },
    // authorityDomain: "gprsclients.b2clogin.com"
    // authorityDomain:  "gprssitemap.b2clogin.com"
    // For Prod2
    // authorityDomain : ((window.location.href.includes("webapp-qa-ui")) ||  (window.location.href.includes("webapp-ui")) ||  (window.location.href.includes("4200")) || (window.location.href.includes("webapp-dev-ui")) || (window.location.href.includes("sitemap-preproduction")) || (window.location.href.includes("afdqa"))) ? "gprssitemap.b2clogin.com" :"gprssitemap.b2clogin.com", // using this userflow only in our application
    authorityDomain : environment.AUTHORITY_DOMAIN, // using this userflow only in our application
};

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
var clientID = environment.CLIENT_ID; //"429b4425-9ea0-4fe2-952c-13d82cbdc620";

export const msalConfig: Configuration = {
    auth: {
        //  clientId: clientID, // This is the ONLY mandatory field that you need to supply.
        // For PROD2
        // clientId : ((window.location.href.includes("webapp-qa-ui")) ||  (window.location.href.includes("webapp-ui")) ||  (window.location.href.includes("4200")) || (window.location.href.includes("webapp-dev-ui")) || (window.location.href.includes("sitemap-preproduction")) || (window.location.href.includes("afdqa"))) ? prodClientID : prodClientID , // using this userflow only in our application
        clientId : clientID, // using this userflow only in our application
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
         knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage, // Configures cache location. "sessionStorage" is more secure, but "sessionStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
};