import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlighter'
})
export class HighlighterPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  // transform(value: any, args: any,type:string): unknown {
  //   if(!args) return value;
  //   if(type==='full'){
  //     const re = new RegExp("\\b("+args+"\\b)", 'igm');
  //     value= value.replace(re, '<span class="highlighted-text">$1</span>');
  //   }
  //   else{
  //     const re = new RegExp(args, 'igm');
  //     value= value.replace(re, '<span class="highlighted-text">$&</span>');
  //   }

  //     return value;
  // }


  transform(itemList: any, searchKeyword: string) {
    if (!itemList)
      return [];
    if (!searchKeyword)
      return itemList;
    let filteredList: any[] = [];
    if (itemList.length > 0) {
      searchKeyword = searchKeyword.toLowerCase();
      itemList.forEach((item: { [s: string]: unknown; } | ArrayLike<unknown>) => {
        //Object.values(item) => gives the list of all the property values of the 'item' object
        let propValueList: any[] = Object.values(item);
        for(let i=0;i<propValueList.length;i++)
        {
          if (propValueList[i]) {
            if (propValueList[i].toString().toLowerCase().indexOf(searchKeyword) > -1)
            {
              filteredList.push(item);
              break;
            }
          }
        }
      });
    }
    return filteredList;
  }
}
