import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { FileGroup, LayerInfo, RasterImageItem, FeatureGroupItem, FeatureTypeItem, FeatureItem, AddFeatureEventArgs, NewFeature, EventType, ChangeLayerVisibilityEventArgs, DeleteFeatureEventArgs, LayerChangedEventArgs, DeleteTypeEventArgs, DeleteGroupEventArgs, DeleteFileEventArgs } from "./feature-tree.component.types";
import reGroupByFile from "./functions/re-group-by-file.function";
import getNewFeature from "./functions/get-new-feature.function";
import addNewFeature from "./functions/add-new-feature.function";
import { CommitChanges, Feature } from "src/app/constants/common-model";
import deleteFeatureFromFiles from "./functions/delete-feature-from-files.function";
import { takeUntil } from "rxjs/operators";
import deleteFeatureFromGroups from "./functions/delete-feature-from-groups.function";
import deleteTypeFromGroups from "./functions/delete-type-from-groups.function";
import deleteTypeFromFiles from "./functions/delete-type-from-files.function";
import deleteGroupFromFiles from "./functions/delete-group-from-files.functions";

@Component({
  selector: 'app-feature-tree',
  templateUrl: './feature-tree.component.html',
  styleUrls: ['./feature-tree.component.css'],
})
export class FeatureTreeComponent implements OnInit, OnDestroy
{
  public files: FileGroup[] = [];

  @Input()
  public layer?: LayerInfo;

  @Input()
  public featureTypeStyles?: Map<number, {color: string}>;

  @Input()
  public rasterImages?: RasterImageItem[];

  @Input()
  public edit: boolean = false;

  @Output()
  public onToggleRasterImage = new EventEmitter<RasterImageItem>();

  @Output()
  public onRasterImageOpacityChange = new EventEmitter<{ item: RasterImageItem, value: number }>();

  @Output()
  public onEditRasterImage = new EventEmitter<RasterImageItem>();

  @Output()
  public onDeleteRasterImage = new EventEmitter<RasterImageItem>();

  @Output()
  public onDeleteGroup = new EventEmitter<{group: FeatureGroupItem, file: FileGroup}>();

  @Output()
  public onDeleteFile = new EventEmitter<FileGroup>();

  @Output()
  public onDeleteFeature = new EventEmitter<{feature: FeatureItem, featureType: FeatureTypeItem, group: FeatureGroupItem, file: FileGroup}>();

  @Output()
  public onDeleteType = new EventEmitter<{featureType: FeatureTypeItem, group: FeatureGroupItem, file: FileGroup}>();


  @Output()
  public onToggleVisibility = new EventEmitter<{ hiddenFeatures: FeatureItem[], hiddenImages: RasterImageItem[] }>();

  @Input()
  public events?: Observable<EventType>;

  @ViewChildren('featureItemElement')
  public featureItemsElements?: QueryList<HTMLElement>;

  private readonly destroy$ = new Subject<void>();

  public ngOnInit(): void {
    this.regroup();

    this.events?.pipe(takeUntil(this.destroy$)).subscribe(event => {
      const { type, data } = event;
      if (type === 'AddFeature') {
        this.addFeatureEventHandler(data as AddFeatureEventArgs);
      } else if (type === 'ChangeLayerVisibility') {
        this.changeLayerVisibilityEventHandler(data as ChangeLayerVisibilityEventArgs);
      } else if (type === 'DeleteFeature') {
        this.deleteFeatureEventHandler(data as DeleteFeatureEventArgs);
      } else if (type === 'DeleteType') {
        this.deleteTypeEventHandler(data as DeleteTypeEventArgs);
      } else if (type === 'DeleteGroup') {
        this.deleteGroupEventHandler(data as DeleteGroupEventArgs);
      } else if (type === 'DeleteFile') {
        this.deleteFileEventHandler(data as DeleteFileEventArgs);
      } else if (type === 'LayerChanged') {
        this.layer = (data as LayerChangedEventArgs).layer;
        this.regroup();
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public toggleGroup(group: FeatureGroupItem): void {
    const visible = !group.isEyeVisible;
    group.isEyeVisible = visible;
    for (const type of group.types) {
      type.isEyeVisible = visible;
      for (const feature of type.features) {
        feature.isEyeVisible = visible;
      }
    }
    this.toogleVisibility();
  }

  public toggleFile(file: FileGroup): void {
    const visible = !file.isEyeVisible;
    file.isEyeVisible = visible;
    for (const group of file.groups) {
      group.isEyeVisible = visible;
      for (const type of group.types) {
        type.isEyeVisible = visible;
        for (const feature of type.features) {
          feature.isEyeVisible = visible;
        }
      }
    }
    this.toogleVisibility();
  }

  public toggleExpandType(type: FeatureTypeItem): void {
    type.isTypeExpanded = !type.isTypeExpanded;
  }

  public toggleEyeType(type: FeatureTypeItem, group: FeatureGroupItem): void {
    const visible = !type.isEyeVisible;
    type.isEyeVisible = visible;
    for (const feature of type.features) {
      feature.isEyeVisible = visible;
    }
    this.toogleVisibility();
  }

  public toggleFeature(feature: FeatureItem, type: FeatureTypeItem, group: FeatureGroupItem): void {
    feature.isEyeVisible = !feature.isEyeVisible;
    this.toogleVisibility();
  }

  public toggleRasterImage(item: RasterImageItem): void {
    item.setEyeActive = !item.setEyeActive;
    this.onToggleRasterImage.emit(item);
  }

  public changeRasterImageOpacity($event: Event, item: RasterImageItem): void {
    const input = $event.target as HTMLInputElement;
    const value = parseInt(input.value);
    this.onRasterImageOpacityChange.emit({ item, value });
  }

  public deleteType(type: FeatureTypeItem, group: FeatureGroupItem, file: FileGroup): void {
    this.onDeleteType.emit({ featureType: type, group, file });
  }

  public deleteFeature(feature: FeatureItem, type: FeatureTypeItem, group: FeatureGroupItem, file: FileGroup): void {
    this.onDeleteFeature.emit({ feature, featureType: type, group, file });
  }

  public deleteGroup(group: FeatureGroupItem, file: FileGroup): void {
    this.onDeleteGroup.emit({ group, file });
  }

  private regroup() {
    if (this.layer) {
      this.files = reGroupByFile(this.layer, this.featureTypeStyles);
    }
  }

  private toogleVisibility()
  {
    const hiddenFeatures = this.files.reduce((acc, file) =>
      acc.concat(file.groups.reduce((acc2, group) =>
        acc2.concat(group.types.reduce((acc3, type) =>
          acc3.concat(type.features.filter(feature => !feature.isEyeVisible))
          , <FeatureItem[]>[]))
        , <FeatureItem[]>[]))
      , <FeatureItem[]>[]);

    const hiddenImages = this.rasterImages?.filter(image => !image.setEyeActive) || [];

    this.onToggleVisibility.emit({ hiddenFeatures, hiddenImages });
  }

  private changeLayerVisibilityEventHandler({ visible } : { visible: boolean })
  {
    for (const file of this.files) {
      for (const group of file.groups) {
        group.isEyeVisible = visible;
        for (const type of group.types) {
          type.isEyeVisible = visible;
          for (const feature of type.features) {
            feature.isEyeVisible = visible;
          }
        }
      }
    }

    for (const image of this.rasterImages || []) {
      image.setEyeActive = visible;
    }
    this.toogleVisibility();
  }

  private addFeatureEventHandler(data: AddFeatureEventArgs) {
    const newFiles = reGroupByFile(data.layer, this.featureTypeStyles);
    const newFeature = getNewFeature(newFiles, data.item);

    if (!newFeature)
    {
      return;
    }

    addNewFeature(this.files, newFeature);

    const subscription = this.featureItemsElements?.changes.subscribe(() => {
      subscription?.unsubscribe();
      expandGroupAndType(newFeature);
    });
  }

  private deleteFeatureEventHandler(data: DeleteFeatureEventArgs) {
    const featureId = parseInt(data.item.featureId as string);
    deleteFeatureFromFiles(this.files, featureId);
    // should not be necessary to delete from groups, but parent re-create this component.
    deleteFeatureFromGroups(data.layer.groups, featureId);
  }

  private deleteTypeEventHandler(data: DeleteTypeEventArgs) {
    const typeId = parseInt(data.type.typeId as string);
    deleteTypeFromFiles(this.files, typeId);
    // should not be necessary to delete from groups, but parent re-create this component.
    deleteTypeFromGroups(data.layer.groups, typeId);
  }

  private deleteGroupEventHandler(data: DeleteGroupEventArgs) {
    const groupId = parseInt(data.group.groupId as string);
    deleteGroupFromFiles(this.files, groupId);
    // should not be necessary to delete from groups, but parent re-create this component.
    const groupIndex = data.layer.groups.findIndex(g => g.groupId === groupId);
    if (groupIndex !== -1) {
      data.layer.groups.splice(groupIndex, 1);
    }
  }

  private deleteFileEventHandler(data: DeleteFileEventArgs) {
    const fileId = data.file.fileId!;
    const fileIndex = this.files.findIndex(f => f.fileId === fileId);
    if (fileIndex !== -1) {
      this.files.splice(fileIndex, 1);
    }
  }
}

function expandGroupAndType({ group, type }: NewFeature): void {
  // TODO: this should be done witht the group.isExpanded and type.isExpanded
  //       properties instead of interact with the DOM.
  const groupButton = document.getElementById(group.buttonElementId);
  const typeButton = document.getElementById(type.typeButtonElementId);

  if (groupButton && groupButton.getAttribute('aria-expanded') === 'false') {
    groupButton.click();
  }

  if (typeButton && typeButton.getAttribute('aria-expanded') === 'false') {
    typeButton.click();
  }
}
