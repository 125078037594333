import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MsalGuard } from '@azure/msal-angular';
import { HelpComponent } from './help/help.component';
import { MapviewerjobsComponent } from './mapviewerjobs/mapviewerjobs.component';
import { LoginGuard } from './login-guard/login-guard.guard';
import { MapComponentComponent } from './common-component/map-component/map-component.component';
import { CredentialessLoginComponent } from './common-component/credentialess-login/credentialess-login.component';
/**
 * MSAL Angular can protect routes in your application
 * using MsalGuard. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#secure-the-routes-in-your-application
 */
const routes: Routes = [
  {
    // Needed for hash routing
    path: 'error',
    component: HomeComponent
  },
  {
    // Needed for hash routing
    path: 'state',
    component: HomeComponent
  },
  {
    // Needed for hash routing
    path: 'code',
    component: HomeComponent
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'credentialess/:guid',
    component: CredentialessLoginComponent
  },
  {
    path: 'mapviewerjobs/:guid/:id',
    component: MapviewerjobsComponent,    
  },
  {
    path: 'mappro/:id',
    component: MapComponentComponent
  },

  {
    path: 'help',
    component: HelpComponent,
    canActivate: [
      LoginGuard
      ] 
  },

  // { path: 'help', loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule) },

  {
    path: 'dashboards',
    loadChildren: () => import('./modules/dashboards/dashboards.module').then(m => m.DashboardsModule),
    canActivate: [
      LoginGuard
    ]
  },

  { path: 'joblist', loadChildren: () => import('./modules/projects/projects.module').then(m => m.ProjectsModule),
  canActivate: [
    LoginGuard
    ]  },

  { path: 'mypreferences', loadChildren: () => import('./modules/setting/setting.module').then(m => m.SettingModule),
  canActivate: [
    LoginGuard
    ]  },

  { path: 'forgotloginid', loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
  canActivate: [
    LoginGuard
    ]  },

   { path: 'myprofile', loadChildren: () => import('./modules/myprofile/myprofile.module').then(m => m.MyprofileModule) },

   { path: 'technicalsupport', loadChildren: () => import('./modules/technicalsupport/technicalsupport.module').then(m => m.TechnicalsupportModule),
   canActivate: [
    LoginGuard
    ] 
   },


 
  { path: 'mapviewer/:lng/:lat/:zoom', loadChildren: () => import('./modules/map/map.module').then(m => m.MapModule),canActivate: [
    MsalGuard
    ]  },

    { path: 'mapeditorjob/:id', loadChildren: () => import('./modules/mapeditorjob/mapeditorjob.module').then(m => m.MapeditorjobModule),canActivate: [
      MsalGuard
      ]  },

 
  
  { path: 'digitalplanmf', loadChildren: () => import('./modules/digitalplanroom/digitalplanroom.module').then(m => m.DigitalplanroomModule),
  canActivate: [
    MsalGuard
    ]  
  },
  { path: 'digitalplanmf/:folderId/:objectTypeId/:type', loadChildren: () => import('./modules/digitalplanroom/digitalplanroom.module').then(m => m.DigitalplanroomModule),
  canActivate: [
    LoginGuard
    ]
  },
  { path: 'digitalplanmf/:folderId/:objectTypeId', loadChildren: () => import('./modules/digitalplanroom/digitalplanroom.module').then(m => m.DigitalplanroomModule),
  canActivate: [
    LoginGuard
    ]
  },

  
  { path: 'collaboration', loadChildren: () => import('./modules/collaboration/collaboration.module').then(m => m.CollaborationModule),canActivate: [
    LoginGuard
    ]  
  },


  //Site Map Admin Module
  { path: 'sitemapadmin', loadChildren: () => import('./modules/sitemapadmin/sitemapadmin.module').then(m => m.SitemapadminModule), 
  canActivate: [
    LoginGuard
    ] 
  },

  {
    path: 'mapviewerjobs/:id',
    component: MapviewerjobsComponent,
    canActivate: [
      LoginGuard
      ] 
  },
  
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabled' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
