import { Injectable, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PortalAPI } from 'src/app/constants/api.constant';
import { ConnectionService } from '../../sitemapadmin/services/connection-service';
import * as turf from '@turf/turf';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MapSubSiteService {

    loginUserId: any;
    loginUserRole: any;
    loginUserEmailId: any;
    addAttrForSubSiteModal: boolean = false;
    subSiteVal = 5; //5 is use for subsite in objectTypeId
    jobObjectTypeId = 1; //1 is use for job in objectTypeId
    private isSaveSubSite = new BehaviorSubject<boolean>(false);
    isSaveSubSiteObservable = this.isSaveSubSite.asObservable();
    private isSaveJobAssociateSite = new BehaviorSubject<boolean>(false);
    isSaveJobAssociateSiteObservable = this.isSaveJobAssociateSite.asObservable();
    private isExternalSaveSubSite = new BehaviorSubject<number>(0);
    isExternalSaveSubSiteObservable = this.isExternalSaveSubSite.asObservable();


    constructor(private restService: ConnectionService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService) {
        if (sessionStorage.getItem("loginCheck") === undefined || !sessionStorage.getItem("loginCheck")) {
            if (sessionStorage.getItem("loginUserId")) {
                this.loginUserId = sessionStorage.getItem("loginUserId");
            }
            if (sessionStorage.getItem("loginUserRole")) {  //Project Manager,Admin
                this.loginUserRole = sessionStorage.getItem("loginUserRole");
            }

            if (sessionStorage.getItem("loginUserEmailId")) {
                this.loginUserEmailId = sessionStorage.getItem("loginUserEmailId");
            }
        }
    }

    //#region getSubSiteBySiteId method used to get the subsitedata for particular site
    getSubSiteBySiteId(siteId: any, siteData: any, optinalVal?: any) {
        //only thing need to test at when api ready after save 
        this.spinner.show();
        this.restService.get(PortalAPI.GET_SUB_SITE, siteId).subscribe(response => {
            if (response.length > 0) {
                // this.addAttrForSubSiteModal = false;
                response.forEach((element:any) => {
                    element.isSubSiteToggle = true;
                });
                let filterData = siteData.find((it: any) => parseInt(it.siteId) === parseInt(siteId));
                if (filterData) {
                    if (filterData['subSiteData'] === undefined) {
                        filterData['subSiteData'] = response;
                    }
                    else {
                        response.forEach((ele: any) => {
                            if (filterData.subSiteData.filter((it: any) => it.subSiteId === ele.subSiteId).length === 0) {
                                filterData.subSiteData.push(ele);
                            }
                        });
                    }
                    if (optinalVal && optinalVal === 'saveSubSite') {
                        this.setSaveSubSiteBehavior(true);
                    }
                }
            }
            this.spinner.hide();
        }, (err: any) => {
            this.spinner.hide();
        });
    }
    //#endregion

    //#region getJobsBySubSiteId method used to get the subsitedata for particular site
    getJobsBySubSiteId(siteId: any, subSiteId: any, siteData: any, userId:any) {
        //only thing need to test at when api ready after save 
        this.spinner.show();
        const param = {
            objectId: subSiteId,
            objectTypeId: this.subSiteVal, //5 is use for subsite id in objectTypeId
            userId: parseInt(userId)
        };
        this.restService.post(PortalAPI.GET_JOBS_BY_SUBSITEID, param).subscribe(response => {
            if (response.length > 0) {
                // this.addAttrForSubSiteModal = false;
                let filterData = siteData.find((it: any) => parseInt(it.siteId) === parseInt(siteId));
                if (filterData && filterData.subSiteData.length > 0) {
                    if (filterData.subSiteData.find((it: any) => parseInt(it.subSiteId) === parseInt(subSiteId))) {
                        filterData.subSiteData.find((it: any) => parseInt(it.subSiteId) === parseInt(subSiteId)).jobData = response;
                    }
                }
            }
            this.spinner.hide();
        }, (err: any) => {
            this.spinner.hide();
        });
    }
    //#endregion

    //#region getDocumentBySubSiteId method used to get the document for particular subsite
    getDocumentBySubSiteId(siteId: any, subSiteId: any, siteData: any, userId:any) {
        //only thing need to test at when api ready after save 
        this.spinner.show();
        const param = {
            objectId: subSiteId,
            objectTypeId: this.subSiteVal, //5 is use for subsite id in objectTypeId
            userId: parseInt(userId)
        };
        this.restService.post(PortalAPI.GET_DOCUMENTS_BY_OBJECTID, param).subscribe(response => {
            if (response.length > 0) {
                // this.addAttrForSubSiteModal = false;
                let filterData = siteData.find((it: any) => parseInt(it.siteId) === parseInt(siteId));
                if (filterData && filterData.subSiteData.length > 0) {
                    if (filterData.subSiteData.find((it: any) => parseInt(it.subSiteId) === parseInt(subSiteId))) {
                        filterData.subSiteData.find((it: any) => parseInt(it.subSiteId) === parseInt(subSiteId)).docData = response;
                    }
                }
            }
            this.spinner.hide();
        }, (err: any) => {
            this.spinner.hide();
        });
    }
    //#endregion

    //#region checkIntersect method used to check is two polygon intersecting or not
    checkIntersect(siteData: any, subSiteModalData: any, siteCoordinate: any) {
        let siteId: any;
        if (subSiteModalData.siteSelectedFromSubSiteDropdown) {
            siteId = subSiteModalData.siteSelectedFromSubSiteDropdown;
        }
        else {
            siteId = subSiteModalData.siteId;
        }
        const temp1 = siteData.find((it: any) => parseInt(it.siteId) === parseInt(siteId));
        let val: any = null;
        if (temp1) {
            for (var i = 0; i < siteCoordinate.length; i++) {
                if (i == 0) {
                    siteCoordinate.splice(i, 1);
                }
            }
            let t = JSON.parse(temp1.geom).coordinates[0];
            for (var i = 0; i < t.length; i++) {
                if (i == 0) {
                    t.splice(i, 1);
                }
            }
            
            
            let poly1 = turf.lineString(t)
            let poly2 = turf.lineString(siteCoordinate);
            
            val = turf.lineIntersect(poly1, poly2);
        }
        return val;
    }
    //#endregion

    //#region isSubSiteInside method used to check if draw polygon under site or not
    isSubSiteInsideSite(siteData: any, subSiteModalData: any, siteCoordinate: any, forUpdateSite?: any) {
        let val = true;
        let siteId: any;
        if (subSiteModalData.siteSelectedFromSubSiteDropdown) {
            siteId = subSiteModalData.siteSelectedFromSubSiteDropdown;
        }
        else {
            siteId = subSiteModalData.siteId;
        }
        const temp1 = siteData.find((it: any) => parseInt(it.siteId) === parseInt(siteId));
        if (temp1) {
            let t = JSON.parse(temp1.geom).coordinates[0];
            if (forUpdateSite) {
                t = forUpdateSite;
            }
            siteCoordinate.forEach((element: any, index: any) => {
                var pt = turf.point(element);
                var poly = turf.polygon([t]);
                
                if (!turf.booleanPointInPolygon(pt, poly)) {
                    val = false;
                    return;
                }
            });
        }
        return val;
    }
    //#endregion

    //#region saveSubSite method is used to add subsite under the selected site
    saveSubSite(data: any, siteCoordinate: any, siteData: any, subSiteModalData: any, pointSave: any, multipleJobsIdByPoligonDraw?: any) {
        this.spinner.show();
        if (siteCoordinate && siteCoordinate.length > 0) {
            if (!this.isSubSiteInsideSite(siteData, subSiteModalData, siteCoordinate)) {
                this.spinner.hide();
                this.toastr.error("Sub-site boundary out of bounds of Site. Please adjust the boundary to continue.");
            }
            else {
                const tempCoord = JSON.parse(JSON.stringify(siteCoordinate));;
                if (this.checkIntersect(siteData, subSiteModalData, siteCoordinate)?.features.length > 0) {
                    this.spinner.hide();
                    this.toastr.error("Please adjust the subsite boundary within the Site boundary");
                }
                else {
                    let param: any = [{
                        mapSubsiteName: data.subSiteName,
                        mapSiteId: pointSave === 'add' ? data.siteSelectedFromSubSiteDropdown : data.siteId,
                        geom: 'POLYGON((' + tempCoord.map((a: any) => a.join(" ")).join(",") + '))',
                        customerName: "",
                        contactPerson: "",
                        email: "",
                        contactNo: "",
                        description: data.description,
                        userId: data.userId ? data.userId : parseInt(this.loginUserId),
                        assignedToEmailId: this.loginUserEmailId,
                        jobIds: multipleJobsIdByPoligonDraw,
                        operationBy: parseInt(this.loginUserId)
                    }];
                    let api: any;
                    if (pointSave.toLowerCase() === 'update') {
                        param[0].mapSubsiteId = siteData[0].subSiteData[0].subSiteId;
                        param[0].mapSiteId = siteData[0].subSiteData[0].siteId;
                        api = PortalAPI.UPDATE_SUB_SITE;
                    }
                    else {
                        api = PortalAPI.ADD_SUB_SITE;
                    }
                    this.restService.post(api, param).subscribe(response => {
                        if (response && response?.processingStatus?.status === "Success") {
                            const siteIdData: any = (pointSave === 'add' ? parseInt(subSiteModalData.siteSelectedFromSubSiteDropdown) : parseInt(data.siteId));
                            let PolygonTool = document.querySelector(".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon") as HTMLDivElement;
                            let filterData = siteData.find((it: any) => parseInt(it.siteId) === siteIdData);
                            if (filterData) {
                                if (filterData['subSiteData'] != undefined) {
                                    const index = filterData.subSiteData.findIndex((it: any) => it.subSiteId == 0);
                                    if (index > -1) {
                                        siteData.find((it: any) => parseInt(it.siteId) === siteIdData).subSiteData.splice(index, 1);
                                    }
                                }
                            }
                            
                            this.getSubSiteBySiteId(siteIdData, siteData, 'saveSubSite');
                            this.getJobsBySubSiteId(data.siteId, data.subSiteId, siteData,data.userId ? data.userId : parseInt(this.loginUserId));
                            this.addAttrForSubSiteModal = false;
                            this.toastr.success("Subsite " + pointSave.toLowerCase() + " successfully!");
                            PolygonTool.removeAttribute('style');

                        }
                        else {
                            if (response.processingStatus.message != undefined) {
                                this.toastr.error(response.processingStatus?.message);
                            }
                        }
                        this.spinner.hide();
                    }, (err) => {
                        this.spinner.hide();
                        this.toastr.error(err);
                    });
                }
            }
        }
        else {
            this.spinner.hide();
            if (pointSave.toLowerCase() === 'add') {
                this.toastr.warning("Before save please draw/add subsite within the Site boundary")
            } else {
                this.toastr.error("Before save please modify the subsite")
            }
        }
    }
    //#endregion

    setSaveSubSiteBehavior(val: boolean) {   // for map all joblist screen
        this.isSaveSubSite.next(val);
    }

    //#region onSubSiteSubmit method is used to add subsite data in local for particular site
    onSubSiteSubmit(siteData: any, subSiteModalData: any, siteCoordinate: any, subSiteModalClos: any, draw: any, type: any) {
        if (draw.getAll()?.features?.length > 1) {
            this.toastr.error("In map multiple polygons presents, please use single for subsite creation");
            return;
        }
        const tempSubSite: any = {
            subSiteId: 0,
            subSiteName: subSiteModalData.subSiteName,
            geom: siteCoordinate ? 'POLYGON((' + siteCoordinate.map((a: any) => a.join(" ")).join(",") + '))' : "",
            description: subSiteModalData.description
        }
        let filterData = siteData.find((it: any) => parseInt(it.siteId) === parseInt(subSiteModalData.siteSelectedFromSubSiteDropdown));
        if (filterData && type.toLowerCase() === 'add') {
            let PolygonTool = document.querySelector(".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon") as HTMLDivElement;
            if (filterData['subSiteData'] === undefined) {
                filterData['subSiteData'] = [tempSubSite];
            }
            else {
                filterData.subSiteData.push(tempSubSite);
            }
            
            this.addAttrForSubSiteModal = true;
            this.toastr.success("Subsite added successfully in site properties!");
            PolygonTool.style.border = '2px solid #ff0000';
            subSiteModalClos.nativeElement.click();
        } else {
            subSiteModalData.subSiteLoopData.subSiteName = subSiteModalData.subSiteName;
            subSiteModalData.subSiteLoopData.description = subSiteModalData.description;
            
            this.saveSubSite(subSiteModalData.subSiteLoopData, JSON.parse(subSiteModalData.subSiteLoopData.geom)?.coordinates[0], siteData, subSiteModalData.subSiteLoopData, 'update', [0]);
            subSiteModalClos.nativeElement.click();
        }
    }
    //#endregion

    //#region clearSubSiteForm method is used to clear the subsite form 
    clearSubSiteForm(subSiteModalData: any) {
        subSiteModalData = {
            siteSelectedFromSubSiteDropdown: "",
            subSiteName: "",
            description: "",
            searchedUserEmail:''
        };
        (<HTMLFormElement>document.getElementById("subSiteForm")).reset();
    }
    //#endregion 

    //#region subSiteModalOpenClick method is used to user click on the add subsite button from map
    subSiteModalOpenClick(siteData: any, subSiteModalData: any) {
        this.clearSubSiteForm(subSiteModalData);
        if (this.addAttrForSubSiteModal) {
            (<HTMLElement>document.getElementById("warningPData")).innerText = "Please add the last selected sub site to map before you proceed.";
            (<HTMLButtonElement>document.getElementById("btnWarningModal")).click();
        }
    }
    //#endregion

    //#region cancelNewlyAddedSubSite method is used to delete newly added subsite from the site
    cancelNewlyAddedSubSite(siteId: any, subSiteId: any, siteData: any, draw: any) {
        if (confirm('Are you sure you want to cancel?')) {
            if (siteData.find((it: any) => it.siteId === siteId)) {
                if (draw.getAll()?.features?.length > 0) {
                    const popups = document.getElementsByClassName("mapboxgl-popup");
                    if (popups.length) {
                        popups[0].remove();
                    }
                    draw.delete(draw.getAll().features[0].id);
                }
                this.addAttrForSubSiteModal = false;
                if(siteData.find((it: any) => it.siteId === siteId).subSiteData.findIndex((item:any)=> item.subSiteId == 0) > -1) {
                    siteData.find((it: any) => it.siteId === siteId).subSiteData.splice(siteData.find((it: any) => it.siteId === siteId).subSiteData.findIndex((item:any)=> item.subSiteId == 0),1);
                }
                // siteData.find((it: any) => it.siteId === siteId).subSiteData.pop();
            }
        }
    }
    //#endregion


    //#region saveSubSiteJobs method is used to save associate or deassociate jobs for subsite
    saveSubSiteJobsDoc(selectedSiteData: any, selectedSubSiteData: any, allData: any, dataKey: any, idKey: any, type: any, userId:any) {
        //console.log(allData,'hify')
        let selectedJobs: any[] =[];
        let deSelectedJobs: any[] =[]
        let filterData = allData.find((it: any) => parseInt(it.siteId) === parseInt(selectedSiteData.siteId));
        if (filterData && filterData.subSiteData.length > 0) {
            let filterSubSiteData = filterData.subSiteData.find((it: any) => parseInt(it.subSiteId) === parseInt(selectedSubSiteData.subSiteId));
            if (filterSubSiteData && filterSubSiteData[dataKey]) {
                selectedJobs = filterSubSiteData[dataKey].filter((it: any) => it.isAssociated);
                deSelectedJobs = filterSubSiteData[dataKey].filter((it: any) => !it.isAssociated);
                if (selectedJobs.length > 0) {
                  //  console.log(selectedJobs,'selfie');
                    this.spinner.show();
                    let objectId: any[] = [];
                    selectedJobs.forEach((element: any) => {
                        objectId.push(element[idKey])
                    });
                    const param = {
                        objectIds: objectId,
                        objectTypeId: type,
                        subsiteIds: [selectedSubSiteData.subSiteId],
                        userId: parseInt(userId)
                    };
                    let message = "Document(s)";
                    this.restService.post(PortalAPI.ASSOCIATE_OBJECTS_TO_SUBSITES, param).subscribe(response => {
                        if (response && response.processingStatus?.status?.toLowerCase() === 'success') {
                            if (dataKey === 'jobData') {
                                this.setSaveAssociateBehavior(true);
                                message = "Job(s)"
                            }
                            if (deSelectedJobs.length === 0) {
                                this.toastr.success(message + "associated with subsite successfully");
                                this.getJobsBySubSiteId(selectedSiteData.siteId, selectedSubSiteData.subSiteId, allData,userId);
                            }
                        } else {
                            if (response.processingStatus.message != undefined)
                                this.toastr.error(response.processingStatus.message);
                        }
                        this.spinner.hide();
                    }, (err) => {
                        this.spinner.hide();
                    });
                }
                if (deSelectedJobs.length > 0) {
                   // console.log(deSelectedJobs,'selfie1');
                    this.spinner.show();
                    let objectId: any[] = [];
                    deSelectedJobs.forEach((element: any) => {
                        objectId.push(element[idKey])
                    });
                    const param = {
                        objectIds: objectId,
                        objectTypeId: type, //5 is use for subsite id in objectTypeId
                        subsiteIds: [selectedSubSiteData.subSiteId],
                        userId: parseInt(userId)
                    };
                    let message = "Document(s)";
                    this.restService.post(PortalAPI.DEASSOCIATE_OBJECTS_TO_SUBSITES, param).subscribe(response => {
                        if (response && response.processingStatus?.status?.toLowerCase() === 'success') {
                            if (dataKey === 'jobData') {
                                message = "Job(s)"
                            }
                            if(selectedJobs.length === 0) {
                                this.toastr.success(message + " disassociated with subsite successfully");
                            }else {
                                this.toastr.success(message + " Associated/Disassociated with subsite successfully");
                            }
                            this.getJobsBySubSiteId(selectedSiteData.siteId, selectedSubSiteData.subSiteId, allData, userId);
                            if (dataKey === 'jobData') {
                                this.setSaveAssociateBehavior(true);
                            }
                        } else {
                            if (response.processingStatus.message != undefined)
                                this.toastr.error(response.processingStatus.message);
                        }
                        this.spinner.hide();
                    }, (err) => {
                        this.spinner.hide();
                    });
                }
            }
        }
        return {selectedJobs,deSelectedJobs}
    }
    //#endregion

    setSaveAssociateBehavior(val: boolean) {   // for map all joblist screen
        this.isSaveJobAssociateSite.next(val);
    }

    // Associate/Deassociate for external Content

    associateJobsBySubSiteId(siteId: any, siteData: any, jobs: any = [], type: any, userId:any) {
        
        this.spinner.show();
        const externalTypes = [6, 7, 8, 9, 10];
        if (type === 1) {
            let data = siteData.jobData.filter((item: any) => item.isAssociated === true);
            data.forEach((item: any) => {
                jobs.push(item.jobId);
            });
        } else if (!externalTypes.includes(type)) {
            let data = siteData.docData.filter((item: any) => item.isAssociated === true);
            data.forEach((item: any) => {
                jobs.push(item.id);
            });
        }
        const param = {
            objectIds: jobs,
            subsiteIds: [siteId],
            objectTypeId: type, //4 is use for site id in objectTypeId
            userId: parseInt(userId)
        };
        this.restService.post(PortalAPI.ASSOCIATE_OBJECTS_TO_SUBSITES, param).subscribe(response => {
            
            let info;
            if (type === 1) {
                info = "Job";
                this.getJobsBySubSiteId(siteId, siteData.subSiteId, siteData, userId);
                // this.getJobsBySiteId(siteId,siteData);
            }
            else if (externalTypes.includes(type)) {
                info = "External Content";
                this.isExternalSaveSubSite.next(siteId);
            }
            else {
                info = "Documents";
                //this.getDocumentSiteId(siteId,siteData);
            }
            this.toastr.success(info + ' associated with subsite successfully.');
            this.setSaveAssociateBehavior(true);
            this.spinner.hide();
        }, (err: any) => {
            this.spinner.hide();
        });
    }
    deassociateJobsBySubSiteId(siteId: any, siteData: any, jobs: any = [], type: any, userId:any) {
        const param = {
            objectIds: jobs,
            subsiteIds: [siteId],
            objectTypeId: type, //4 is use for site id in objectTypeId
            userId: parseInt(userId)
        };
        const externalTypes = [6, 7, 8, 9, 10];
        this.restService.post(PortalAPI.DEASSOCIATE_OBJECTS_TO_SUBSITES, param).subscribe(response => {
            
            let info;
            if (type === 1) {
                info = "Job";
                // this.getJobsBySiteId(siteId,siteData);
            }
            else if (externalTypes.includes(type)) {
                info = "External Content";
                this.isExternalSaveSubSite.next(siteId);
            }
            else {
                info = "Documents";
                //this.getDocumentSiteId(siteId,siteData);
            }
            this.setSaveAssociateBehavior(true);
            this.toastr.success(info + ' dissociated with subsite successfully.');
            this.spinner.hide();
        }, (err: any) => {
            this.spinner.hide();
        });
    }

    // get jobs details by subsite id for subsite details popup
    getJobsBySubSiteIdForSubSitePopup(subSiteId: any, userId:any): Observable<any> {
        const param = {
            objectId: subSiteId,
            objectTypeId: this.subSiteVal, //5 is use for subsite id in objectTypeId
            userId: parseInt(userId)
        };
        return this.restService.post(PortalAPI.GET_JOBS_BY_SUBSITEID, param);
    }


    // get jobs by site id
    getJobsBySiteId(siteIds: number[], userId:any): Promise<any> {
        const param = {
            siteIds: siteIds,
            userId: parseInt(userId)
        };
        return this.restService.post(PortalAPI.GET_JOBS_BY_SITEIDS, param).toPromise();
    }

    // associate/diassociate subsite by site id
    associateDisassociateSubsiteBySiteID(subsiteIds: any = [],siteId:any,sitedata:any,type:any, userId:any): Observable<any> {
        if(type === 'associate'){
            let data = sitedata.subSiteData.filter((item:any)=> item.isAssociated === true);
            data.forEach((item:any)=>{
                subsiteIds.push(item.subSiteId);
            });
            const param = {
                objectIds: subsiteIds,
                siteIds: [siteId],
                objectTypeId: 5, //5 is use for subsite id in objectTypeId
                userId: parseInt(userId)
            };
            return this.restService.post(PortalAPI.ASSOCIATE_SUBSITE, param);
        }else{
            const param = {
                objectIds: subsiteIds,
                siteIds: [siteId],
                objectTypeId: 5, //5 is use for subsite id in objectTypeId
                userId: parseInt(userId)
            };
            return this.restService.post(PortalAPI.DEASSOCIATE_SUBSITE, param);
        }
       
    }




}