<div class="col-lg-12">
  <h2 class="table-page-title">Recent Documents
    <ul class="custom-table-pagination">
      <li [ngClass]="{disabled:pager.currentPage === 1}">
        <a (click)="setPage(1)">First</a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === 1}">
        <a (click)="setPage(pager.currentPage - 1)">&#60;</a>
      </li>
      <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
        <a (click)="setPage(page)">{{page}}</a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a (click)="setPage(pager.currentPage + 1)">&#62;</a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a (click)="setPage(pager.totalPages)">Last</a>
      </li>
    </ul>
  </h2>

  <div class="table-responsive custom-height-scroll-digital">
    <table class="table table-hover table-bordered custom-table table-colored first-column-hide-on-mobile">
      <thead>
        <tr>
          <th class="no-white-space">Site Name</th>
          <th>Document Name </th>
          <td class="fixed-width-th"></td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let obj of pagedItems; let i = index;" (click)="selectedRow($event, obj)"
        [class.highlighted]="obj.name === currentRow">
          <td href="javascript:void(0)">{{obj.siteName}}</td>
          <!-- <td><a download href={{obj.fileLink}} target="_blank">{{obj.name}}</a></td> -->
          <td><a *ngIf="obj.name != null" aria-label="false" href="javascript:void(0)" (click)="ridirectDocumentLocationOnClick(obj)">{{obj.name}}</a></td>
          <!-- <td><a (click)="shareDocumentObj(obj)" href="javascript:void(0)" data-bs-toggle="modal"
              data-bs-target="#staticBackdrop">Share</a></td> -->
          <td>
            <a aria-label="false" href="javascript:void(0)" (click)="addDocumentLogData(obj.documentLogs)"
              class="custom-action-status float-start" [hidden]="!obj.logStatus" data-bs-toggle="modal" data-bs-target="#table-edit-documents">
              <!-- <i title="document log" [hidden]="!obj.logStatus" class="custom-action-status-dot"></i> -->
              <span class="fa fa-info-circle"></span>

              <!-- hover is display none -->
              <div class="card card-tooltip" style="display: none;">
                <div class="modal-header">
                  Document Log
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="table-responsive">
                        <table class="table table-borderless custom-table bgnone">
                          <thead *ngIf="documentLogData != null && documentLogData.length > 0">
                            <tr>
                              <th>Date</th>
                              <th>Action</th>
                              <th>Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngIf="documentLogData.length > 0">
                              <tr *ngFor="let obj of documentLogData; let i = index;">
                                <td>{{obj.date | date : "MM/dd/y"}}</td>
                                <td>{{obj.action}}</td>
                                <td>{{obj.user}}</td>
                              </tr>
                            </ng-container>
                            <tr *ngIf="documentLogData == null || documentLogData.length==0">
                              <td colspan="3">No Document Log</td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- hover is display none -->

            </a>
            <p class="float-end share-icon" (click)="shareDocumentObj(obj)" href="javascript:void(0)"
              data-bs-toggle="modal" data-bs-target="#staticBackdrop"><span class="fa fa-share-alt"></span></p>
          </td>

        </tr>
        <tr *ngIf="pagedItems.length== 0">
          <td colspan="3">No Document Found</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<!-- Table Modal Info -->
<div
  class="modal fade"
  id="table-edit-documents"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabelDocuments"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="staticBackdropLabelDocuments">
          Document Log
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-borderless custom-table bgnone">
                <thead *ngIf="documentLogData != null && documentLogData.length > 0">
                  <tr>
                    <th>Date</th>
                    <th>Action</th>
                    <th>Email </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="documentLogData.length > 0">
                    <tr *ngFor="let obj of documentLogData; let i = index;">
                      <td>{{obj.date | date : "MM/dd/y"}}</td>
                      <td>{{obj.action}}</td>
                      <td>{{obj.email}}</td>
                    </tr>
                  </ng-container>
                  <tr *ngIf="documentLogData == null || documentLogData.length==0">
                    <td colspan="3">No Document Log</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>