import { CommonFunctionService } from "../services/common-function-service";

export class SubscriptionEditAccess {

  tierId: any;
  tierName: any;
  loginUserRole: any;
  subscriptionEndDate: any;
  calcDays: any;

  constructor(private commonService: CommonFunctionService) {
    this.tierId = window.sessionStorage.getItem('tierId');
    this.tierName = window.sessionStorage.getItem('tierName');
    this.loginUserRole = window.sessionStorage.getItem("loginUserRole");
    this.subscriptionEndDate = window.sessionStorage.getItem("subscriptionEndDate");
    this.gracePeriod();
  }

  getEditAccess() {
    let flag = false;
    if (this.tierId != 1 || this.loginUserRole?.toLowerCase() == 'admin' || this.loginUserRole.toLowerCase() == 'project manager' ) {
      flag = true;
    }
    return flag;
  }

  gracePeriod() {
    if (this.tierId == 1 && this.loginUserRole?.toLowerCase() == 'client') {
      // this.commonService.getGracePeriod().subscribe((response: any) => {
      //   let currTierID = response.filter((it: any) => it.tierId === 2);
      //   if (currTierID && currTierID.length > 0) {
      //     this.calcDays = this.addDays(currTierID[0].dprGraceDays, this.subscriptionEndDate);
      //     // this.calcDays = this.addDays(currTierID.dprGraceDays, "2023-01-29T04:59:59Z");
      //   }
      // })
    }
  }

  addDays(days: any, date: any) {
    let dt = new Date(date);
    dt.setDate(dt.getDate() + days);
    return dt;
  }

  getGracePeriod() {
    let flag = true;
    if (this.calcDays) {
      if (new Date() <= new Date(this.calcDays)) {
        flag = false;
      }
    }
    return flag;
  }
}