import { Component, ElementRef, HostListener, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AzureBlobStorageServiceVideo } from 'src/app/services/azure-blob-storage.service';
import { Subscription } from 'rxjs';
import { BehaviorSubjectService } from 'src/app/services/behaviorsubject.service';
@Component({
  selector: 'app-trainingvideos',
  templateUrl: './trainingvideos.component.html',
  styleUrls: ['./trainingvideos.component.css']
})
export class TrainingvideosComponent implements OnInit {
  // SAS (shared access signatures)
  // sas = "?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-03-31T14:24:32Z&st=2022-08-03T06:24:32Z&spr=https,http&sig=XNhNWf022pmV0kTeR4tAghyRf3mRgd3OaCzSoSsSgTE%3D";
  sas = '';
  picturesList: string[] = [];
  videoUrl: any
  picturesDownloaded: string[] = []

  changeVideoUrl: any
  fileArrList: any = [];
  first_video_posted_date: any;
  first_video_title: any;
  searchValue: any;
  selectedTab: any
  changeSearchValueSubscription: Subscription | null = null;
  changeSelectedTabSubscription: Subscription | null = null;
  changeVideoPlaySubscription: Subscription | null = null;
  searchFileArrList: any;
  showDefaultFileArrList: boolean = true;
  showSearchFileArrList: boolean = false;
  autoPlayISEnable: boolean = false;

  @ViewChild("videoPlayer", { static: false })
  videoplayer!: ElementRef;

  currentPlayingVideo!: HTMLVideoElement;
  @HostListener('click', ['$event'])
  onClick(event: any) {
    console.log("test: ", event);
  }

  picturesContainer:string = '';
  picturesContainerSAS:string = '';
  azureEnvAccount:string = '';
  constructor(
    private blobServicesvideo: AzureBlobStorageServiceVideo,
    private behaviourSubjectService: BehaviorSubjectService,
  ) { 
    this.picturesContainer = this.blobServicesvideo.picturesContainer;
    this.picturesContainerSAS = this.blobServicesvideo.picturesContainerSAS;
    this.sas = this.picturesContainerSAS;
    this.azureEnvAccount = this.blobServicesvideo.azureEnvAccount;
  }

  ngOnInit(): void {
    console.log("ngOnInit Training Video without modules")

    this.reloadVideos();
    var self =  this;
    // this.autoPlayISEnable = false;

    setTimeout(() => {
      
   this.changeVideoPlaySubscription= this.behaviourSubjectService.isVideoPlayObservable.subscribe((isVisible) => {
      console.log('isVisible: ', isVisible); // => true/false
      // this.autoPlayISEnable = isVisible;
      if(!isVisible){
        // this.currentPlayingVideo.pause();
        console.log('isVisible: ', isVisible); // => true/false

        this.videoplayer.nativeElement.pause();

      }

      console.log('autoPlayISEnable: ', this.autoPlayISEnable); // => true/false

    });
    }, 1000);
    this.changeSearchValueSubscription = this.behaviourSubjectService.helpSearchValueObservable.subscribe((searchVal) => {
      this.searchValue = searchVal;
    });
    this.changeSelectedTabSubscription = this.behaviourSubjectService.helpSelectedTabObservable.subscribe((tab) => {
      this.selectedTab = tab;
      console.log("this.selectedTab", this.selectedTab)
      if (this.selectedTab === 'videos' && this.searchValue) {
        this.getSearchList();
      }
      if (this.selectedTab === 'resetsearch') {
        this.reloadVideos();
        this.showDefaultFileArrList = true;
        this.showSearchFileArrList = false;
        this.searchValue = '';
      }
    });
  }

  onClickVideo() {
    console.log("test1: ");
  }

  //# list of videos on page load
  private reloadVideos() {
    this.fileArrList = [];
    this.blobServicesvideo.listImages(this.sas,this.picturesContainer).then(list => {
      this.picturesList = list;
      const array: any = [];
      this.picturesDownloaded = array
      for (let obj of this.picturesList) {
        const final = JSON.parse(obj);
        const title = (final.name).split('.');
        final.title = title[0];
        final.checkBoxValue = false;
        final.videourl = this.azureEnvAccount + this.picturesContainer+"/" + final.name + this.picturesContainerSAS; //"?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-03-31T14:24:32Z&st=2022-08-03T06:24:32Z&spr=https,http&sig=XNhNWf022pmV0kTeR4tAghyRf3mRgd3OaCzSoSsSgTE%3D&_=1660018601561";
        this.fileArrList.push(final);
      }
      this.videoUrl = this.azureEnvAccount + this.picturesContainer+"/" + this.fileArrList[0].name + this.picturesContainerSAS; //"?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-03-31T14:24:32Z&st=2022-08-03T06:24:32Z&spr=https,http&sig=XNhNWf022pmV0kTeR4tAghyRf3mRgd3OaCzSoSsSgTE%3D&_=1660018601561";
      this.changeVideoUrl = this.videoUrl;
      this.first_video_posted_date = this.fileArrList[0].properties.createdOn;
      const title = (this.fileArrList[0].name).split('.');
      this.first_video_title = title[0];
    });
  }

  //# select video for show on large screen
  changeVideo(e: any) {
    console.log("r", e);
    this.changeVideoUrl = this.azureEnvAccount+this.picturesContainer+"/" + e.name + this.picturesContainerSAS;// "?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-03-31T14:24:32Z&st=2022-08-03T06:24:32Z&spr=https,http&sig=XNhNWf022pmV0kTeR4tAghyRf3mRgd3OaCzSoSsSgTE%3D&_=1660018601561"
    this.first_video_posted_date = e.properties.createdOn;
    const title = (e.name).split('.');
    this.first_video_title = title[0];
    this.autoPlayISEnable = true;
  }

  //# function to search element from video list
  searchElement(elementsArray: any, keyword: any) {
    const returnedElement = elementsArray.filter((element: any, index: any) => {
      return element.title.toLowerCase().includes(keyword.toLowerCase());
    });

    return returnedElement;
  }

  //# get final searched item list
  getSearchList() {
    this.showDefaultFileArrList = false;
    this.showSearchFileArrList = true;
    this.searchFileArrList = [];
    const result = this.searchElement(this.fileArrList, this.searchValue);
    for (let obj of result) {
      let dummy = { 'name': obj.title };
      for (let val of this.fileArrList) {
        if (val.title == obj.title) {
          this.searchFileArrList.push(val);
        }
      }
    }
    // if(typeof this.searchFileArrList !== 'undefined' && this.searchFileArrList.length > 0){
    //   this.videoUrl = "https://sitemapsadev.blob.core.windows.net/helpvideos/" + this.searchFileArrList[0].name +  "?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-03-31T14:24:32Z&st=2022-08-03T06:24:32Z&spr=https,http&sig=XNhNWf022pmV0kTeR4tAghyRf3mRgd3OaCzSoSsSgTE%3D&_=1660018601561";
    //   this.changeVideoUrl = this.videoUrl;
    //   this.first_video_posted_date = this.searchFileArrList[0].properties.createdOn;
    //   const title = (this.searchFileArrList[0].name).split('.');
    //   this.first_video_title = title[0];
    // }
  }

  ngOnDestroy() {
    this.changeSearchValueSubscription?.unsubscribe();
    this.changeSelectedTabSubscription?.unsubscribe();
    this.changeVideoPlaySubscription?.unsubscribe();
    this.behaviourSubjectService.changeHelpSearchValue("");
    // this.autoPlayISEnable = false;
    console.log("Destroy Video Training without modules")
 
  }

  //# play the video when click by user
   onPlayingVideo(event :any) {
    event.preventDefault();
    // play the first video that is chosen by the user
    if (this.currentPlayingVideo === undefined) {
        this.currentPlayingVideo = event.target;
        this.currentPlayingVideo.play();
    } else {
    // if the user plays a new video, pause the last 
    // one and play the new one
        if (event.target !== this.currentPlayingVideo) {
            this.currentPlayingVideo.pause();
            this.currentPlayingVideo = event.target;
            this.currentPlayingVideo.play();
        }
    }
}


}
