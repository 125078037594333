import {noTarget, isOfMetaType, isActiveFeature, isInactiveFeature, isShiftDown} from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
import constrainFeatureMovement from '@mapbox/mapbox-gl-draw/src/lib/constrain_feature_movement';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';
import DirectSelect from '@mapbox/mapbox-gl-draw/src/modes/direct_select';

import showSnappingFeature, { snapFeatureLayerId, getSiteMapMVTLayerIdsById } from './mode_utils';


const isVertex = isOfMetaType(Constants.meta.VERTEX);
const isMidpoint = isOfMetaType(Constants.meta.MIDPOINT);


function deleteVertexMode() {
  console.log('DirectSelect', DirectSelect)
  const deleteVertexMode = { ...DirectSelect }

  // Disable draggin feature in Delete Vertex mode
  deleteVertexMode.dragFeature = function (state, e, delta) {
    const geojson = state.feature.toGeoJSON();
    // Nothing
  };

  // Disable draggging vertex in Delete Vertex
  deleteVertexMode.dragVertex = function(state, e, delta) {
    console.log('state', state, 'e', e, 'delta', delta);
  };

  // When clicking on a vertex, trash it
  deleteVertexMode.onVertex = function (state, e) {
    console.log('onVertex', state, e);
    // this.startDragging(state, e);
    const about = e.featureTarget.properties;
    const selectedIndex = state.selectedCoordPaths.indexOf(about.coord_path);
    if (!isShiftDown(e) && selectedIndex === -1) {
      state.selectedCoordPaths = [about.coord_path];
    } else if (isShiftDown(e) && selectedIndex === -1) {
      state.selectedCoordPaths.push(about.coord_path);
    }

    const selectedCoordinates = this.pathsToCoordinates(state.featureId, state.selectedCoordPaths);
    this.setSelectedCoordinates(selectedCoordinates);
    this.onTrash(state);
    this.setSelected(state.featureId);
  };

  deleteVertexMode.onMouseMove = function(state, e) {
    // On mousemove that is not a drag, stop vertex movement.
    const isFeature = isActiveFeature(e);
    const onVertex = isVertex(e);
    const noCoords = state.selectedCoordPaths.length === 0;
    if (isFeature && noCoords) this.updateUIClasses({ mouse: Constants.cursors.NONE });
    else if (onVertex) this.updateUIClasses({ mouse: Constants.cursors.POINTER });
    else if (onVertex && !noCoords) this.updateUIClasses({ mouse: Constants.cursors.POINTER });
    else this.updateUIClasses({ mouse: Constants.cursors.NONE });
    this.stopDragging(state);

    // Skip render
    return true;
  };

  deleteVertexMode.clickNoTarget = function (state, e) {
    console.log('clickNoTarget', 'state', state, e);
    this.setSelected(state.featureId);
    // this.changeMode(Constants.modes.SIMPLE_SELECT);
  };

  deleteVertexMode.onStop = function() {
    // this.changeMode('sitemap_simple_select');
    doubleClickZoom.enable(this);
    this.clearSelectedCoordinates();

    if (this.getFeature(snapFeatureLayerId) !== undefined) {
      this.deleteFeature(snapFeatureLayerId);
    }
  };

  // Midpoints could be hidden if client wants
  deleteVertexMode.toDisplayFeatures = function (state, geojson, push) {
    if (state.featureId === geojson.properties.id) {
      geojson.properties.active = Constants.activeStates.ACTIVE;
      push(geojson);
      createSupplementaryPoints(geojson, {
        map: this.map,
        midpoints: false, // Set to true/false to show/hide midpoints
        selectedPaths: state.selectedCoordPaths
      }).forEach(push);
    } else {
      geojson.properties.active = Constants.activeStates.INACTIVE;
      push(geojson);
    }
    this.fireActionable(state);
  };

  return deleteVertexMode;
}


export default deleteVertexMode;
