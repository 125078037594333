import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SitemapadminRoutingModule } from './sitemapadmin-routing.module';
import { SitemapadminComponent } from 'src/app/modules/sitemapadmin/sitemapadmin.component';
import { ManageSitesComponent } from './component/manage-sites/manage-sites.component';
import { ManageJobsComponent } from './component/manage-jobs/manage-jobs.component';
import { ManageUsersComponent } from './component/manage-users/manage-users.component';
import { ManageRolesComponent } from './component/manage-roles/manage-roles.component';
import { CommonTableComponent } from './component/common/common-table/common-table.component';
import { PermissionRightComponent } from './component/permission-right/permission-right.component';
import { AddEditUserComponent } from './component/add-edit-user/add-edit-user.component';
import { AccesscontrolComponent } from './component/accesscontrol/accesscontrol.component';
import { BoardMessageComponent } from './component/board-message/board-message.component';
import { HelpModuleComponent } from './component/help-module/help-module.component';
import { FaqComponent } from './component/help-module/component/faq/faq.component';
import { TrainingVideos } from './component/help-module/component/training-videos/training-videos.component';
import { FormsDocumentsComponent } from './component/help-module/component/forms-documents/forms-documents.component';
import { DashboardsModule } from '../dashboards/dashboards.module';
import { MiscellaneousComponent } from './component/miscellaneous/miscellaneous.component';
import { SortDirective } from './component/common/util/sort.directive';


@NgModule({
  declarations: [
    SitemapadminComponent,
    ManageSitesComponent,
    ManageJobsComponent,
    ManageUsersComponent,
    ManageRolesComponent,
    CommonTableComponent,
    PermissionRightComponent,
    AddEditUserComponent,
    AccesscontrolComponent,
    BoardMessageComponent,
    HelpModuleComponent,
    FaqComponent,
    TrainingVideos,
    FormsDocumentsComponent,
    MiscellaneousComponent,
    SortDirective
  ],
  imports: [
    CommonModule,
    SitemapadminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardsModule
  ],
  exports: [CommonTableComponent]
})
export class SitemapadminModule { }
