import { ICommonTableHeader } from "../../sitemapadmin/constants/common-table-data";

export enum enumViewType {
    list = 1,
    tile = 2
}

export interface IFileListModel {
    id: number,
    name: string,
    prevFolderId: number,
    parentFolderId: number,
    nextFolderId: number,
    size: number,
    jobId: number,
    jobName: string,
    address: string,
    siteName: string,
    createdDate: string,
    createdBy:string,
    lastUpdatedBy: string,
    lastUpdatedDate: string,
    objectTypeId: number,
    objectTypeName: string,
    path: string,
    workOrderNumber: string,
    isShared: boolean,
    securableObjectPermissionTypeId: number,
    securableObjectPermissionName: string,
    isDefaultForUser: boolean,
    logStatus: boolean,
    action: IActionModel[],
    nameIcon:any,
    checked?:boolean,
}

export interface IActionModel {
    icon: string,
    tooltip: string,
    dataBsToggle: string,
    dataBsTarget: string,
}

export class fileListTableData {
    tblHeader: ICommonTableHeader[] = [
        {
            columnName: 'name',
            displayName: 'Name',
            class: '',
            icon: true,
            visible: true,
            span: true
        },
        {
            columnName: 'createdDate',
            displayName: 'Date Created',
            class: '',
            visible: true,
            span: false, 
            date: true
        },
        {
            columnName: 'createdBy',
            displayName: 'Created By',
            class: '',
            visible: true,
            span: true
        },
        {
            columnName: 'modifiedDate',
            displayName: 'Date Modified',
            class: '',
            visible: true,
            span: false,
            date: true
        },
        {
            columnName: 'modifiedBy',
            displayName: 'Modified By',
            class: '',
            visible: true,
            span: true
        },
        {
            columnName: 'action',
            displayName: 'Action',
            class: '',
            icon: true,
            visible: true,
            button: true,
        }
    ]

    actionButton = [
        {
            icon: 'fa-info-circle',
            tooltip: 'File Information',
            dataBsToggle: 'modal',
            dataBsTarget: '#docInformation',
        },
        {
            icon: 'fa-download',
            tooltip: 'Download',
            dataBsToggle: 'modal',
            dataBsTarget: '',
        },
        {
            icon: 'fa-share-alt',
            tooltip: 'Share',
            dataBsToggle: 'modal',
            dataBsTarget: '#shareJob',
        },
        {
            icon: 'fa-ellipsis-v',
            tooltip: 'More',
            dataBsToggle: 'modal',
            dataBsTarget: '',
        },
    ];
}

export class documentInformationTableData{
    tblHeader:ICommonTableHeader[] = [
      {
        columnName: 'date',
        displayName: 'Date',
        class: '',
        visible: true,
        span: false,
        date: true
      },
      {
        columnName: 'action',
        displayName: 'Action',
        class: '',
        visible: true,
        span: true
      },
      {
        columnName: 'email',
        displayName: 'Email',
        class: '',
        visible: true,
        span: true
      }
    ]  
  }