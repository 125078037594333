import { FileGroup } from "../feature-tree.component.types";
import getFeatureIndexFromFiles from "./get-feature-index-from-files.function";
import deleteFeatureFromGroups from "./delete-feature-from-groups.function";

export default function deleteFeatureFromFiles(files: FileGroup[], featureId: number) {

  const {fileIndex, groupIndex, typeIndex, featureIndex} = getFeatureIndexFromFiles(files, featureId);

  if (featureIndex === -1 || typeIndex === -1 || groupIndex === -1 || fileIndex === -1) {
    return;
  }

  const file = files[fileIndex];
  deleteFeatureFromGroups(file.groups, featureId);

  if (file.groups.length === 0) {
    files.splice(fileIndex, 1);
  }
}
