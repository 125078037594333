import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import mouseEventPoint from '@mapbox/mapbox-gl-draw/src/lib/mouse_event_point';
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
import StringSet from '@mapbox/mapbox-gl-draw/src/lib/string_set';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import SimpleSelect from '@mapbox/mapbox-gl-draw/src/modes/simple_select';

import * as turf from '@turf/turf';
import showSnappingFeature, { snapFeatureLayerId, getSiteMapMVTLayerIdsById } from './mode_utils';


function addVertexMode() {
  console.log('DirectSelect', SimpleSelect);
  const addVertexMode = { ...SimpleSelect };

  // Point features to show start and end points on selected lines in certain edit modes
  const lineStartPointFeatureLayerId = 'line-start-point';
  const lineEndPointFeatureLayerId = 'line-end-point';
  const lineStartPointStyleLayerIds = ['line-start-point-active', 'line-start-point-inactive'];
  const lineEndPointStyleLayerIds = ['line-end-point-active', 'line-end-point-inactive'];
  const lineStartPointFeature = {
    id: lineStartPointFeatureLayerId,
    type: 'Feature',
    properties: {
      isStartPoint: true
    },
    geometry: {
      type: 'Point',
      coordinates: []
    }
  };
  const lineEndPointFeature = {
    id: lineEndPointFeatureLayerId,
    type: 'Feature',
    properties: {
      isEndPoint: true
    },
    geometry: {
      type: 'Point',
      coordinates: []
    }
  }
  // Snapping feature - handled in main app for now
  let snapFeatureCoordinates = undefined;

  // Track mouse movement over map for snapping purposes
  // Throttle this event to occur no more than mouseMoveEventsPerSecond
  const mouseMoveEventsPerSecond = 10;
  let mouseMoveWait = false;
  const onMouseMoveWait = function(state, e) {
    // console.log('onMouseMove', 'state', state, e);
    /*
    No selection:
    {
      "dragMoveLocation": null,
      "boxSelectStartLocation": null,
      "boxSelecting": false,
      "canBoxSelect": false,
      "dragMoving": false,
      "canDragMove": false,
      "initiallySelectedFeatureIds": []
    }
    Selection:
    {
      "dragMoveLocation": null,
      "boxSelectStartLocation": null,
      "boxSelecting": false,
      "canBoxSelect": false,
      "dragMoving": false,
      "canDragMove": false,
      "initiallySelectedFeatureIds": [
          "99940003186"
      ]
    }
    */

    // Mouse coords
    let lat = e.lngLat.lat;
    let lng = e.lngLat.lng;

    // Remove snapping features
    if (this.getFeature(snapFeatureLayerId) !== undefined) {
      this.deleteFeature(snapFeatureLayerId);
    }
    // Remove Start/Enpoint
    if (this.getFeature(lineStartPointFeatureLayerId) !== undefined) {
      this.map.setPaintProperty('line-start-point-inactive.hot', 'circle-radius', 0);
      this.map.setPaintProperty('line-start-point-inactive.cold', 'circle-radius', 0);

    }
    if (this.getFeature(lineEndPointFeatureLayerId) !== undefined) {
      this.map.setPaintProperty('line-end-point-inactive.hot', 'circle-radius', 0);
      this.map.setPaintProperty('line-end-point-inactive.cold', 'circle-radius', 0);
    }

    // In some cases, selecting a Line does not set initiallySelectedFeatureIds correctly
    if (state.initiallySelectedFeatureIds.length === 1 || this.getSelectedIds().length === 1) {
      this.map.getCanvasContainer().style.cursor = 'crosshair';
      let selectedFeature;
      if (state.initiallySelectedFeatureIds.length === 1) {
        selectedFeature = this.getFeature(state.initiallySelectedFeatureIds[0]);
      } else {
        selectedFeature = this.getFeature(this.getSelectedIds()[0]);
      }
      let geomType = selectedFeature.type;
      if (geomType !== 'LineString') {
        return;
      }

      let startOrEnd = findNearestStartEndPointOnLine.call(this, selectedFeature, lng, lat);

      if (startOrEnd === 'start') {
        this.map.setPaintProperty('line-start-point-inactive.hot', 'circle-radius', 10);
        this.map.setPaintProperty('line-start-point-inactive.cold', 'circle-radius', 10);
        this.map.setPaintProperty('line-end-point-inactive.hot', 'circle-radius', 5);
        this.map.setPaintProperty('line-end-point-inactive.cold', 'circle-radius', 5);
      } else {
        this.map.setPaintProperty('line-start-point-inactive.hot', 'circle-radius', 5);
        this.map.setPaintProperty('line-start-point-inactive.cold', 'circle-radius', 5);
        this.map.setPaintProperty('line-end-point-inactive.hot', 'circle-radius', 10);
        this.map.setPaintProperty('line-end-point-inactive.cold', 'circle-radius', 10);
      }

      // Move layers to the top of the map
      for (const drawTemp of ['hot', 'cold']) {
        for (const layerId of lineStartPointStyleLayerIds) {
          this.map.moveLayer(`${layerId}.${drawTemp}`);
        }
        for (const layerId of lineEndPointStyleLayerIds) {
          this.map.moveLayer(`${layerId}.${drawTemp}`);
        }
      }
    }

    if (state.initiallySelectedFeatureIds.length === 1 || this.getSelectedIds().length === 1) {
      if (this.isSnapping) {
        let selectedFeature;
        if (state.initiallySelectedFeatureIds.length === 1) {
          selectedFeature = this.getFeature(state.initiallySelectedFeatureIds[0]);
        } else {
          selectedFeature = this.getFeature(this.getSelectedIds()[0]);
        }

        // These are the "subject" lng/lat coordinates, e.g. the moving mouse cursor
        let geographicalCoordinates = [lng, lat];

        let movingFeature = {
          id: 'add_vertex_feature',
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: geographicalCoordinates
          }
        }

        showSnappingFeature.call(
          this,
          geographicalCoordinates,
          movingFeature,
          selectedFeature.id,
          'add_vertex');
    }
  }

    // Skip render
    return true;
  };

  const findNearestStartEndPointOnLine = function(selectedFeature, lng, lat) {
    let numberCoords = selectedFeature.coordinates.length;
    let startPoint = selectedFeature.coordinates[0];
    let endPoint = selectedFeature.coordinates[numberCoords - 1];
    // Measure distances from click to line start and endpoints
    let from = turf.point([lng, lat]);
    let toStart = turf.point(startPoint);
    let toEnd = turf.point(endPoint);
    let options = { units: 'miles' };
    let distanceToStart = turf.distance(from, toStart, options);
    let distanceToEnd = turf.distance(from, toEnd, options);
    if (distanceToStart < distanceToEnd) {
      return 'start';
    } else {
      return 'end';
    }
  }

  const updateLineStartEndPointCoords = function(selectedFeature) {
    let numberCoords = selectedFeature.coordinates.length;
    let startPoint = selectedFeature.coordinates[0];
    let endPoint = selectedFeature.coordinates[numberCoords - 1];
    lineStartPointFeature.geometry.coordinates = startPoint;
    lineEndPointFeature.geometry.coordinates = endPoint;
    // this.addFeature(this.newFeature(lineStartPointFeature));
    // this.addFeature(this.newFeature(lineEndPointFeature));
  }

  const updateLineStartEndPointStyle = function (startOrEnd) {
    if (startOrEnd === 'start') {
      this.map.setPaintProperty('line-start-point-inactive.hot', 'circle-radius', 10);
      this.map.setPaintProperty('line-start-point-inactive.cold', 'circle-radius', 10);
      this.map.setPaintProperty('line-end-point-inactive.hot', 'circle-radius', 5);
      this.map.setPaintProperty('line-end-point-inactive.cold', 'circle-radius', 5);
    } else {
      this.map.setPaintProperty('line-start-point-inactive.hot', 'circle-radius', 5);
      this.map.setPaintProperty('line-start-point-inactive.cold', 'circle-radius', 5);
      this.map.setPaintProperty('line-end-point-inactive.hot', 'circle-radius', 10);
      this.map.setPaintProperty('line-end-point-inactive.cold', 'circle-radius', 10);
    }
  }

  const extendLineFromVertex = function(selectedFeature, fromPoint, fromCoords) {
    //
    let nearestPoint = findNearestStartEndPointOnLine(selectedFeature, fromCoords[0], fromCoords[1]);
    if (nearestPoint === 'start') {
      console.log('Extending line from start point.');
      selectedFeature.coordinates.unshift(fromCoords);
    } else {
      console.log('Extending line from end point.');
      selectedFeature.coordinates.push(fromCoords);
    }
    updateLineStartEndPointCoords.call(this, selectedFeature)
    updateLineStartEndPointStyle.call(this, nearestPoint);

    // Adding the feature with updated geometry will effectively update it
    this.addFeature(selectedFeature);
    // Update endoints
    this.addFeature(this.newFeature(lineStartPointFeature));
    this.addFeature(this.newFeature(lineEndPointFeature));
    // Put the feature back into "direct_select" mode
    this.setSelected(selectedFeature.id);
  }

  const handleInitialSelect = function(selectedFeature, lng, lat) {
    // Update the coords of start/end point of Line
    updateLineStartEndPointCoords.call(this, selectedFeature);

    // Find if closest to start or end Vertex and style accordingly
    let nearestPoint = findNearestStartEndPointOnLine.call(this, selectedFeature, lng, lat);
    updateLineStartEndPointStyle.call(this, nearestPoint);

    // Update endoints
    this.addFeature(this.newFeature(lineStartPointFeature));
    this.addFeature(this.newFeature(lineEndPointFeature));

    // Force render
    // Need to wait since we are fetching data from API to create the Draw feature
    setTimeout(() => {
      this.doRender(lineStartPointFeatureLayerId);
      this.doRender(lineEndPointFeatureLayerId);
    }, 400);
  }

  addVertexMode.onSetup = function(opts) {
    console.log('opts', opts);
    // turn the opts into state.
    const state = {
      dragMoveLocation: null,
      boxSelectStartLocation: null,
      boxSelectElement: undefined,
      boxSelecting: false,
      canBoxSelect: false,
      dragMoving: false,
      canDragMove: false,
      initiallySelectedFeatureIds: opts.featureIds || []
    };

    this.mapLayerId = opts.mapLayerId;
    this.isSnappingToLine = opts.isSnappingToLine;
    this.isSnappingToVertex = opts.isSnappingToVertex;
    this.isSnapping = this.isSnappingToLine || this.isSnappingToVertex;
    this.navigatedFeatures = opts.navigatedFeatures;

    if (this.mapLayerId !== undefined) {
      this.siteMapMVTLayerIds = getSiteMapMVTLayerIdsById(this.map, this.mapLayerId);
    }


    this.setSelected(state.initiallySelectedFeatureIds.filter(id => this.getFeature(id) !== undefined));
    let selectedFeature = this.getFeature(state.initiallySelectedFeatureIds[0]);
    handleInitialSelect.call(this, selectedFeature, selectedFeature.coordinates[0][0], selectedFeature.coordinates[0][1]);
    this.fireActionable();

    this.setActionableState({
      combineFeatures: false,
      uncombineFeatures: false,
      trash: true
    });

    return state;
  };

  addVertexMode.onMouseMove = function(state, e) {
    // Don't handle events when one has just occurred
    if (!mouseMoveWait) {
      // console.log('Mousemoving!!');
      // Fire the event
      onMouseMoveWait.call(this, state, e);
      // Run some required code here
      this.stopExtendedInteractions(state);
      // Stop any further events
      mouseMoveWait = true;
      // After a fraction of a second, allow events again
      setTimeout(() => {
        mouseMoveWait = false;
      }, 1000 / mouseMoveEventsPerSecond);
    }
  }

  addVertexMode.clickAnywhere = function (state, e) {
    /* This event is NOT triggered when user clicks on the selected feature - Line, Point, Vertex, Midpoint, etc.
    This event is triggered when no Line is selected and a user clicks on an MVT Line after starting "Add Vertex" mode - need to handle this somehow.
    So if this event is triggered and a Line is selected, the user is extending the Line from either start or end point. */

    // When clicking on the Map (not any feature) or when clicking on a feature:
    // If there was one or more previously selected feature, wasSelectedIds contains the feature Ids
    // If there were no previously selected feature, wasSelectedIds will be an empty Array
    const wasSelectedIds = this.getSelectedIds();
    // console.log('clickAnywhere', 'state', state,  'e', e, 'this', this, 'wasSelectedIds', wasSelectedIds);
    // if (wasSelected.length) {
    //   this.clearSelectedFeatures();
    //   wasSelected.forEach(id => this.doRender(id));
    // }
    // doubleClickZoom.enable(this);

    let lat = e.lngLat.lat;
    let lng = e.lngLat.lng;

    // Determine if the click happened on the Line (then add vertex within Line) or
    // on the map (extend the Line from the start/end point ot the click)
    if (wasSelectedIds.length === 1) {
      // If there is one selected feature then the user is extending the Line
      let selectedFeature = this.getFeature(wasSelectedIds[0]);
      let geomType = selectedFeature.type;
      // The user must click on a Line otherwise do nothing
      if (geomType !== 'LineString') {
        return;
      }

      // If snapping is enabled, use the snapping geometry if it exists
      let fromPoint, fromCoords;
      if (snapFeatureCoordinates !== undefined) {
        // Get the coords from the snapping feature - these are the new point values
        // for either the "subject" point or line being moved
        fromCoords = snapFeatureCoordinates;
        fromPoint = turf.point(snapFeatureCoordinates);
      } else {
        fromCoords = [lng, lat];
        fromPoint = turf.point([lng, lat]);
      }
      extendLineFromVertex.call(this, selectedFeature, fromPoint, fromCoords);
      // Force render
      this.doRender(selectedFeature.id);
      // Fire an Update so we can keep track of undo/redo history
      this.map.fire(Constants.events.UPDATE, {
        action: Constants.updateActions.CHANGE_COORDINATES,
        features: this.getSelected().map(f => f.toGeoJSON())
      });
    }
    this.stopExtendedInteractions(state);
  };

  addVertexMode.clickOnFeature = function(state, e) {
    /* This is triggered when:
    - Clicking on a Draw feature when none are selected.
    - Clicking on the Snapping Feature
    - Not when clicking on a different feature when one is already selected.
    */
    const wasSelectedIds = this.getSelectedIds();
    console.log('clickOnFeature', 'wasSelectedIds', wasSelectedIds, 'state', state,  'e', e);

    // If the user clicks on a Feature, and there are none selected, e.g. wasSelectedIds.length === 0
    // Then get the ID from the event and select the feature.
    // This is need for Draw features.
    // It does not enable onMouseMove for some reason ???
    let lng = e.lngLat.lng;
    let lat = e.lngLat.lat;
    if (wasSelectedIds.length === 0) {
      // let featureId = e.featureTarget.properties.id;
      // let geomType = e.featureTarget.geometry.type;
      // if (featureId !== undefined && (geomType === 'LineString' || geomType === 'MultiLineString')) {
      //   this.setSelected(featureId);
      //   let selectedFeature = this.getFeature(featureId);
      //   handleInitialSelect.call(this, selectedFeature, lng, lat);
      // }
    } else {
      // The user either clicked on the selected Line, in which case we add a vertex to the Line, or the snapping feature
      let lat = e.lngLat.lat;
      let lng = e.lngLat.lng;
      let selectedFeature = this.getFeature(wasSelectedIds[0]);
      let clickedFeature = e.featureTarget;
      // If snapping is enabled, use the snapping geometry if it exists
      let fromPoint, fromCoords;
      // Get the coords from the snapping feature - these are the new point values
      // for either the "subject" point or line being moved
      if (snapFeatureCoordinates !== undefined) {
        fromCoords = snapFeatureCoordinates;
        fromPoint = turf.point(snapFeatureCoordinates);
      } else {
        fromCoords = [lng, lat];
        fromPoint = turf.point([lng, lat]);
      }
      // Add a Vertex to the subject Line if the user clicked on the Line
      if (clickedFeature !== undefined && selectedFeature.id === clickedFeature.properties.id) {
        let nearestPointOnLine = turf.nearestPointOnLine(selectedFeature, fromPoint, { units: 'miles' });
        // Insert the point into the LineString
        selectedFeature.coordinates.splice(nearestPointOnLine.properties.index + 1, 0, nearestPointOnLine.geometry.coordinates);
        // Add the feature with updated vertex
        this.addFeature(selectedFeature);
        // this.setEditingHistory('change_coordinates', selectedFeature.id);
      } else if (clickedFeature.properties.id === snapFeatureLayerId) {
        // Otherwise extend the Line from the closest Vertex to the Snapping Feature or location of mouse click
        extendLineFromVertex.call(this, selectedFeature, fromPoint, fromCoords);
      }
      // Force render
      this.doRender(selectedFeature.id);
      // Fire an Update so we can keep track of undo/redo history
      this.map.fire(Constants.events.UPDATE, {
        action: Constants.updateActions.CHANGE_COORDINATES,
        features: this.getSelected().map(f => f.toGeoJSON())
      });
    }

    // // Stop everything
    // doubleClickZoom.disable(this);
    // this.stopExtendedInteractions(state);

    // const isShiftClick = CommonSelectors.isShiftDown(e);
    // const selectedFeatureIds = this.getSelectedIds();
    // const featureId = e.featureTarget.properties.id;
    // const isFeatureSelected = this.isSelected(featureId);

    // // Click (without shift) on any selected feature but a point
    // if (!isShiftClick && isFeatureSelected && this.getFeature(featureId).type !== Constants.geojsonTypes.POINT) {
    //   // Enter direct select mode
    //   return this.changeMode(Constants.modes.DIRECT_SELECT, {
    //     featureId
    //   });
    // }

    // // Shift-click on a selected feature
    // if (isFeatureSelected && isShiftClick) {
    //   // Deselect it
    //   this.deselect(featureId);
    //   this.updateUIClasses({ mouse: Constants.cursors.POINTER });
    //   if (selectedFeatureIds.length === 1) {
    //     doubleClickZoom.enable(this);
    //   }
    // // Shift-click on an unselected feature
    // } else if (!isFeatureSelected && isShiftClick) {
    //   // Add it to the selection
    //   this.select(featureId);
    //   this.updateUIClasses({ mouse: Constants.cursors.MOVE });
    // // Click (without shift) on an unselected feature
    // } else if (!isFeatureSelected && !isShiftClick) {
    //   // Make it the only selected feature
    //   selectedFeatureIds.forEach(id => this.doRender(id));
    //   this.setSelected(featureId);
    //   this.updateUIClasses({ mouse: Constants.cursors.MOVE });
    // }

    // // No matter what, re-render the clicked feature
    // this.doRender(featureId);
  };

  addVertexMode.clickOnVertex = function(state, e) {
    console.log('clickOnVertex', 'state', state,  'e', e);
    // Enter direct select mode
    this.changeMode(Constants.modes.DIRECT_SELECT, {
      featureId: e.featureTarget.properties.parent,
      coordPath: e.featureTarget.properties.coord_path,
      startPos: e.lngLat
    });
    this.updateUIClasses({ mouse: Constants.cursors.MOVE });
  };

  addVertexMode.onMouseDown = function(state, e) {
    // console.log('onMouseDown', 'state', state,  'e', e);
    /*
      In "Add Vertex" mode with snapping enabled, the user will likely click on the snapping feature. This will prevent clickAnywhere
      from firing. To avoid that problem, use onMouseDown to find if the snapping feature is present. If so, save it's coordinates,
      and then delete the feature from the map.
    */
    // Get the snap feature from the map, or undefined if it does not exist
    let snapFeature = this.getFeature(snapFeatureLayerId);
    if (snapFeature === undefined) {
      snapFeatureCoordinates = undefined;
    } else {
      snapFeatureCoordinates = snapFeature.coordinates;
      this.deleteFeature(snapFeatureLayerId);
    }
    if (CommonSelectors.isActiveFeature(e)) return this.startOnActiveFeature(state, e);
    if (this.drawConfig.boxSelect && CommonSelectors.isShiftMousedown(e)) return this.startBoxSelect(state, e);
  };

  addVertexMode.onMouseUp = function(state, e) {
    console.log('onMouseUp', 'state', state,  'e', e);
    // End any extended interactions
    if (state.dragMoving) {
      this.fireUpdate();
    } else if (state.boxSelecting) {
      const bbox = [
        state.boxSelectStartLocation,
        mouseEventPoint(e.originalEvent, this.map.getContainer())
      ];
      const featuresInBox = this.featuresAt(null, bbox, 'click');
      const idsToSelect = this.getUniqueIds(featuresInBox)
        .filter(id => !this.isSelected(id));

      if (idsToSelect.length) {
        this.select(idsToSelect);
        idsToSelect.forEach(id => this.doRender(id));
        this.updateUIClasses({ mouse: Constants.cursors.MOVE });
      }
    }
    this.stopExtendedInteractions(state);
  };

  addVertexMode.onStop = function() {
    // Always remove the line start and end points
    if (this.getFeature(lineStartPointFeatureLayerId) !== undefined) {
      this.deleteFeature(lineStartPointFeatureLayerId)
    }
    if (this.getFeature(lineEndPointFeatureLayerId) !== undefined) {
      this.deleteFeature(lineEndPointFeatureLayerId)
    }
    if (this.getFeature(snapFeatureLayerId) !== undefined) {
      this.deleteFeature(snapFeatureLayerId);
    }
    doubleClickZoom.enable(this);
  };

  addVertexMode.toDisplayFeatures = function(state, geojson, display) {
    geojson.properties.active = (this.isSelected(geojson.properties.id)) ?
      Constants.activeStates.ACTIVE : Constants.activeStates.INACTIVE;
    display(geojson);
    this.fireActionable();
    if (geojson.properties.active !== Constants.activeStates.ACTIVE ||
      geojson.geometry.type === Constants.geojsonTypes.POINT) return;
    createSupplementaryPoints(geojson).forEach(display);
  };

  return addVertexMode;
}


export default addVertexMode;
