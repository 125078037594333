import { CommitChanges, Feature, FeatureGroup } from "src/app/constants/common-model";
import getFeatureIndexFromGroups from "./get-feature-index-from-groups.function";

export default function deleteFeatureFromGroups(groups: FeatureGroup[], featureId: number) {
  const {groupIndex, typeIndex, featureIndex} = getFeatureIndexFromGroups(groups, featureId);

  if (featureIndex === -1 || typeIndex === -1 || groupIndex === -1) {
    return;
  }

  const group = groups[groupIndex];
  const type = group.types[typeIndex];

  // Delete the feature
  type.features.splice(featureIndex, 1);

  // If no features left in the type, delete the type
  if (type.features.length === 0) {
    group.types.splice(typeIndex, 1);

    // If no types left in the group, delete the group
    if (group.types.length === 0) {
      groups.splice(groupIndex, 1);
    }
  }
}
