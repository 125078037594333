import { Component, Input, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { BehaviorSubjectService } from 'src/app/services/behaviorsubject.service';
import { CommonFunctionService } from 'src/app/services/common-function-service';
import { PaginationService } from 'src/app/services/pagination.service';
import { DashboardService } from '../../services/dashboard/dashboard.service';

@Component({
  selector: 'app-recent-documents',
  templateUrl: './recent-documents.component.html',
  styleUrls: ['./recent-documents.component.css']
})
export class RecentDocumentsComponent implements OnInit {
  @Input() loginStatus: boolean = false;

  recentDocumentList: any = {};
  sendDataForm = {};
  documentLogData: any | null = [];
  pager: any = {};
  pagedItems: any = [];
  myPreferencesObj: any = {};
  userDataObj: any = {};
  currentRow: any;
  restServiceSubscriber: Subscription | undefined;
  folderId : any = {};
  userId : any = {};
  folderClickedData: any = null;


  constructor(
    private spinner: NgxSpinnerService,
    private dashboardService: DashboardService,
    private behaviourSubjectService: BehaviorSubjectService,
    private paginationservice: PaginationService,
    private commonService: CommonFunctionService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    
    this.myPreferencesObj.FILE_DISPLAY_WIDGET = 5; //initial value
    this.behaviourSubjectService.myPreferencesObservable.subscribe((Obj) => {
      this.myPreferencesObj = Obj;
      // this.myPreferencesObj.FILE_DISPLAY_WIDGET = 10;

    });
    this.behaviourSubjectService.currentUserDetailsFromDB.subscribe(userData => {
      this.userDataObj = userData
      this.getRecentDocumenttList();
    });
  }

  getRecentDocumenttList() {
    this.setPage(1);
  }

  addDocumentLogData(data: any) {
    this.behaviourSubjectService.changeLogData(data);
    this.documentLogData = data;
  }

  shareDocumentObj(obj: any) {
    console.log(obj)
    this.behaviourSubjectService.changeDocumentShared(obj);
  }

  //# get recent documents list by User ID
  setPage(page: number) {
    this.sendDataForm = {
      "request": {
        "pageNumber": page,
        "pageSize": this.myPreferencesObj.FILE_DISPLAY_WIDGET
      },
      "userId": this.userDataObj.userId
     // "securableObjectTypeId": 2, // 2 for document
    }
    this.spinner.show();
    this.dashboardService.getRecentDocumentList(this.sendDataForm).subscribe((response) => {
      console.log("testrecentdoc",response)
      this.recentDocumentList = response;
      this.pagedItems = this.recentDocumentList.recentDocuments;
      this.pager = this.paginationservice.getPager(this.recentDocumentList.totalRecord, page, this.myPreferencesObj.FILE_DISPLAY_WIDGET);
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  ridirectDocumentLocationOnClick(data:any){
    //  this.commonService.getFolderChildren(data.folderId, data.userId);
    //  this.folderClickedData = data;
     this.behaviourSubjectService.recentdoc(data)
     if(data){
      this.router.navigate(['/digitalplanmf']);
     }
  }

  selectedRow(event: any, obj: any) {
    this.currentRow = obj.name;
  }
}
