import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RevisionHistoryComponent } from "./revision-history.component";

@NgModule({
    declarations: [RevisionHistoryComponent],
    imports: [
      CommonModule,
    ],
    exports:[RevisionHistoryComponent]
  })
  export class RevisionHistoryModule { }