<div class="container-flud">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-8">
      <div class="card colored-card">
        <h2 class="card-header">Training Videos</h2>
        <div class="card-body">
          <video
            width="100%"
            controls
            [autoplay]="autoPlayISEnable"
            (playing)="onPlayingVideo($event)"
            muted
            playsinline="true"
            webkit-playsinline="true"
            id="mainVideo"
            [src]="changeVideoUrl"
            type="video/mp4"
            #videoPlayer
          ></video>

          <!-- <h2 class="card-title">Managing Layers</h2> -->
          <p class="card-text">
            <strong>Title:</strong> {{ first_video_title }}
          </p>
          <!-- <p class="card-text"><strong>Duration:</strong> 30:24</p> -->
          <p class="card-text">
            <strong>Posted on:</strong>
            {{ first_video_posted_date | date: "MM-dd-yyyy" }}
          </p>
          <p class="help-like">
            <i class="fa fa-thumbs-down"></i
            ><i class="fa fa-thumbs-up ms-3"></i>
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4">
      <ng-container *ngIf="showDefaultFileArrList">
        <div
          class="card mb-3 custom-card-video"
          *ngFor="let obj of fileArrList; let i = index"
        >
          <div class="row g-0">
            <div class="col-md-4 position-relative bg-video" (click)="changeVideo(obj)">
              <video
                (click)="changeVideo(obj)"
                (playing)="onPlayingVideo($event)"
                loop
                muted
                playsinline="true"
                webkit-playsinline="true"
                [src]="obj.videourl"
                type="video/mp4"
                class="video-help"
              ></video>
              <span class="fa fa-play"></span>
            </div>
            <div class="col-md-8" (click)="changeVideo(obj)">
              <div class="card-body">
                <!-- <h5 class="card-title cursor">Topic of the Video</h5> -->
                <p class="card-text">
                  <small class="text-muted">Title: {{ obj.title }} </small>
                </p>
                <!-- <p class="card-text"><small class="text-muted">Duration: </small></p> -->
                <p class="card-text">
                  <small class="text-muted"
                    >Posted on:
                    {{ obj.properties.createdOn | date: "MM-dd-yyyy" }}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="showSearchFileArrList && searchFileArrList?.length > 0"
      >
        <div
          class="card mb-3 custom-card-video"
          *ngFor="let obj of searchFileArrList; let i = index"
        >
          <div class="row g-0">
            <div class="col-md-4 position-relative bg-video" (click)="changeVideo(obj)">
              <video
                (click)="changeVideo(obj)"
                (playing)="onPlayingVideo($event)"
                loop
                muted
                playsinline="true"
                webkit-playsinline="true"
                [src]="obj.videourl"
                type="video/mp4"
                class="video-help"
              ></video>
              <span class="fa fa-play"></span>
            </div>
            <div class="col-md-8" (click)="changeVideo(obj)">
              <div class="card-body">
                <!-- <h5 class="card-title cursor">Topic of the Video</h5> -->
                <p class="card-text">
                  <small class="text-muted">Title: {{ obj.title }} </small>
                </p>
                <!-- <p class="card-text"><small class="text-muted">Duration: </small></p> -->
                <p class="card-text">
                  <small class="text-muted"
                    >Posted on:
                    {{ obj.properties.createdOn | date: "MM-dd-yyyy" }}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="!showDefaultFileArrList && searchFileArrList?.length === 0"
      >
        <div>
          <p class="text-danger text-center">No Data Found</p>
        </div>
      </ng-container>
      <div class="card mb-3 custom-card-video" style="display: none">
        <div class="row g-0">
          <div class="col-md-4">
            <!-- <img src="..." class="img-fluid rounded-start" alt="..."> -->
            <video width="100%" controls>
              <source
                src="https://www.w3schools.com/tags/movie.mp4"
                type="video/mp4"
              />
              <source
                src="https://www.w3schools.com/tags/movie.ogg"
                type="video/ogg"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Topic of the Video</h5>
              <p class="card-text">
                <small class="text-muted">Subject: </small>
              </p>
              <p class="card-text">
                <small class="text-muted">Duration: </small>
              </p>
              <p class="card-text">
                <small class="text-muted">Posted on:</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
