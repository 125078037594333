import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
//# route guards are interfaces provided by Angular which, when implemented, 
//# allow us to control the accessibility of a route based on conditions provided in class 
//# implementation of that interface
export class LoginGuard implements CanActivate {
  constructor(private authService:MsalService,
    private routes: Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(state.url.includes('mapviewerjobs/') || state.url.includes('digitalplanmf/') || state.url.includes('mapviewer/')) {
        sessionStorage.setItem("redirectToUrl",state.url);
      }


      if (this.authService.instance.getAllAccounts().length > 0 && !sessionStorage.getItem("loginCheck")) {
        return true;
      }
      else {
        this.routes.navigate(['/']);
        return false
      }
  }

}
