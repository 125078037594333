import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonMapService } from 'src/app/common-component/common-map-service';
import { MapViewerService } from '../../services/mapviewer.service';
import * as turf from '@turf/turf';
import { SubscriptionEditAccess } from 'src/app/constants/subscriptionEditAccess';
import { CommonFunctionService } from 'src/app/services/common-function-service';
import { PermissionService } from 'src/app/services/common/permission.service';
import { permissionEnum } from 'src/app/constants/userPermissionAccess';
import { MetadataService } from 'src/app/services/metadata.service';

@Component({
  selector: 'app-feature-description',
  templateUrl: './feature-description.component.html',
  styleUrls: ['../mapalljoblist/mapalljoblist.component.css']
})
export class FeatureDescriptionComponent implements OnInit, OnChanges {
  @Input() slideMenuOpenTools: boolean = false;
	@Input() slideMenuOpenLayerDetails: boolean = false;
	@Input() slideMenuOpenJobDescription: boolean = false;
	@Input() slideMenuOpenSiteDetails: boolean = false;
	@Input() slideMenuOpenFolder: boolean = false;
  @Input() slideMenuOpenSiteAdminDetails: boolean = false;
	@Input() openedFeatureName: string = "";
  @Input() openedFeatureAttributes: any = null;
  @Input() openedFeatureProperties: any = null;

	@Input() openedFeatureAttributesAddedLink: any = [];
  @Input() loginUserId:any;
  @Input() loginUserRole:any;
  @Input() map: any;
  @Input() editDescriptionValue:any;
  // @Input() updateFeatureDescriptionForm:any;
  @Input() editFormFeatureDescriptionView:boolean = true;
  @Input() featureDetailOnClick:any;
  @Input() navigatedJobId:any;
  @Output() ontoggleClicked = new EventEmitter<any>();
  @Output() onHandleLayerMouseClick = new EventEmitter<any>();

  updateFeatureDescriptionForm!: FormGroup;
  mapViewerSubscribe: Subscription | undefined;
  editCheck = new SubscriptionEditAccess(this.commonService).getEditAccess();
  permissionEnumList:any = permissionEnum;
  currentFeaturesLayer :any;

  constructor(
    private commonMapService:CommonMapService,
    private spinner:NgxSpinnerService,
    private toastr: ToastrService,
    private mapViewerService: MapViewerService,
    private commonService: CommonFunctionService,
    private permissionService: PermissionService,
    private metadataService : MetadataService
    

  ) {
    this.updateFeatureDescriptionFormGenerate();

   }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.editDescriptionValue?.currentValue) {
      this.currentFeaturesLayer = -1;
      this.currentFeaturesLayer = this.editDescriptionValue?.groups?.features?.[0]?.properties?.layerId;
    }
  }

  ngOnInit(): void {
    this.loginUserRole = this.loginUserRole ? this.loginUserRole : sessionStorage.getItem('loginUserRole');
  }

  ontoggle(
    slideMenuOpenSiteAdminDetails: boolean,
    slideMenuOpenSiteDetails: boolean,
    slideMenuOpenLayerDetails: boolean,
    slideMenuOpenTools: boolean,
    slideMenuOpenJobDescription: boolean,
    slideMenuOpenFolder: boolean
  ) {
    this.slideMenuOpenSiteAdminDetails = slideMenuOpenSiteAdminDetails
    this.slideMenuOpenSiteDetails = slideMenuOpenSiteDetails
    this.slideMenuOpenLayerDetails = slideMenuOpenLayerDetails
    this.slideMenuOpenTools = slideMenuOpenTools
    this.slideMenuOpenJobDescription = slideMenuOpenJobDescription
    this.slideMenuOpenFolder = slideMenuOpenFolder

    this.ontoggleClicked.emit({
      slideMenuOpenSiteAdminDetails,
      slideMenuOpenSiteDetails,
      slideMenuOpenLayerDetails,
      slideMenuOpenTools,
      slideMenuOpenJobDescription,
      slideMenuOpenFolder,
    });
  }

  updateFeatureDescriptionFormGenerate() {
    this.updateFeatureDescriptionForm = this.commonMapService.updateFeatureDescriptionFormGenerate();
  }


  unloadMVT(mapLayerId: number, tileType: string, map: any) {
    let layerIdsToUnload = map.getStyle().layers.filter((layer: any) => layer.id.startsWith(mapLayerId.toString()) && layer.id.endsWith(tileType)).map((layer: any) => layer.id)
    layerIdsToUnload.forEach((currentLayerId: string) => {
      map.removeLayer(currentLayerId)
    })

    // Unload the source
    let currentSourceId = `${mapLayerId}-${tileType}`;
    map.removeSource(currentSourceId)
  }

  loadMVT(appLayerId: number, tileType: string) {
    this.loadMVTSource(appLayerId, tileType);
  }

  loadMVTSource(appLayerId: number, tileType: string) {
    let currentSourceId = `${appLayerId}-${tileType}`;
    this.commonMapService.setSourceForVectorTilesV2(
      this.map,
      currentSourceId,
      appLayerId,
      tileType,
      true
    );
  }
//update Coordinates
updateCoordinates(featureDescription:any){
  this.openedFeatureAttributes.coordinate.long = +this.updateFeatureDescriptionForm.value.coordinatelongtitude;
  this.openedFeatureAttributes.coordinate.lat = +this.updateFeatureDescriptionForm.value.coordinatelatitude;
  let payLoad: any = {
    mapJobId: featureDescription.mapJobId,
    mapFeatureTypeId: featureDescription.mapFeatureTypeId,
    mapGroupId: featureDescription.mapGroupId,
    mapLayerId: featureDescription.mapLayerId,
    userId: this.loginUserId,
    swmapsLayer:this.metadataService.featureType[featureDescription.mapFeatureTypeId]
  };
  let coordinate = featureDescription.geom.split('(')[1].slice(0,-1).split(',');
  coordinate.pop();
  coordinate.push(`${this.openedFeatureAttributes.coordinate.long} ${this.openedFeatureAttributes.coordinate.lat} 0`);
  let featureCoordinate = this.editDescriptionValue.groups.features[0].geometry.coordinates;
  featureCoordinate = JSON.parse(featureCoordinate);
  featureCoordinate[featureCoordinate.length-1] = [this.openedFeatureAttributes.coordinate.long,this.openedFeatureAttributes.coordinate.lat, 0];
  featureCoordinate = JSON.stringify(featureCoordinate);
  this.editDescriptionValue.groups.features[0].geometry.coordinates = featureCoordinate;
  coordinate =  featureDescription.geom.split('(')[0] + ' Z'+ '('+coordinate.join(',') + ')';
  payLoad.geom = coordinate;
  featureDescription.geom = coordinate;
  
  this.updatePointLineDescription(this.editDescriptionValue.groups.features[0], featureDescription);
}

   /* update featues */
   updateFeaturesDescition() {
    const regex = /^(?!-0(\.0+)?$)-?(0|[1-9]\d*)(\.\d+)?$/ ;
    if(!this.updateFeatureDescriptionForm.value.coordinatelongtitude.toString().match(regex) ||
    !this.updateFeatureDescriptionForm.value.coordinatelatitude.toString().match(regex)){
      alert('Please enter coordinates in number format');
      return;
    }
    this.commonMapService.loginUserId = this.loginUserId;
    let featureDescription = this.commonMapService.updateFeaturesDescition(this.editDescriptionValue, this.updateFeatureDescriptionForm);
   const enteredcoordinates = [+this.updateFeatureDescriptionForm.value.coordinatelongtitude, +this.updateFeatureDescriptionForm.value.coordinatelatitude];
   let isCoordsInsideJobs
   this.mapViewerSubscribe = this.mapViewerService
     .fetchJobDetails(this.navigatedJobId!, this.loginUserId)
     .subscribe(
       (jobDetailData: any) => {
         const coordinatesPolygon = JSON.parse(jobDetailData.features[0].geometry.coordinates);
         let poly = turf.polygon([coordinatesPolygon]) //poly_coords - Jobs Polygon Coordinates e.g [[[-5, 52], [-4, 56], [-2, 51], [-7, 54], [-5, 52]]]
         let pt = turf.point(enteredcoordinates); //point_coords - Entered Coordinates [Lng, Lat]
         let buffer_10ft = turf.buffer(poly, 0.003048);
         isCoordsInsideJobs = turf.booleanPointInPolygon(pt, buffer_10ft);
         if (!isCoordsInsideJobs) {
           alert("This move is outside the relative job area.");
         }
         else if (confirm('Are you sure you want to update?')) {
           this.spinner.show();
           this.updateCoordinates(featureDescription);
         }
         this.openedFeatureAttributes.depthToTop = this.updateFeatureDescriptionForm.controls.depthToTop.value;
         this.openedFeatureAttributes.description = this.updateFeatureDescriptionForm.controls.description.value;
         this.openedFeatureAttributes.notes = this.updateFeatureDescriptionForm.controls.notes.value;
         this.spinner.hide();

       })
  }

  updatePointLineDescription(saveFeature: any, payLoad: any) {
    let data = this.commonMapService.updatePointLineDescription(saveFeature,payLoad);
    if(data) {
      data.subscribe((response: any) => {
        if (response && response.processingStatus.status == "Success") {
          this.featureDetailOnClick.lngLat.lng = +this.updateFeatureDescriptionForm.value.coordinatelongtitude;
          this.featureDetailOnClick.lngLat.lat = +this.updateFeatureDescriptionForm.value.coordinatelatitude;
          
          this.onHandleLayerMouseClick.emit(this.featureDetailOnClick);
          this.toastr.success(`${saveFeature.geometry.type} Description Updated Successfully`);
          this.editFormFeatureDescriptionView = true;

          this.unloadMVT(saveFeature.properties.layerId, saveFeature.properties.featureClass, this.map);
          this.loadMVT(saveFeature.properties.layerId, saveFeature.properties.featureClass);
        } else {
          if (response && response.processingStatus.status == "Failure") {
            this.toastr.error(response.processingStatus.message);
          }
        }
      }, err => {
                this.toastr.error(err.error.ProcessingStatus.Error);
      });
    }
  }

   /** Uptate features **/
   editFormFeatureDescriptionOpen() {
    if (!this.editCheck) {
      this.permissionService.showErrormessageForPersonal();
      return;
    }
    this.editFormFeatureDescriptionView = false;
    this.updateFeatureDescriptionForm = this.commonMapService.editFormFeatureDescriptionOpen(this.openedFeatureAttributes);
  }

  editFormFeatureDescriptionClose() {
    this.editFormFeatureDescriptionView = true;
  }

}
