import { Injectable } from '@angular/core';
import { ConnectionService } from '../modules/sitemapadmin/services/connection-service';
import { PortalAPI } from '../constants/api.constant';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
 
  jobsMetadata:any[] = [];
  featureGroupsMetadata:any[] = [];
  featureTypesMetadata:any[] = [];

  jobIdToJobName:any = {};
  jobIdToWONo:any = {};
  jobIdToAccess:any = {};
  featureGroup:any = {};
  featureType:any = {};

  constructor(private restService:ConnectionService) { }

  loadGroupTypeMetadata(){
    this._loadFeatureGroupsMetadata();
    this._loadFeatureTypesMetadata();
  }

  resetMetadata() {
    this.featureGroupsMetadata = [];
    this.featureTypesMetadata = [];
    this.featureGroup = {};
    this.featureType = {};
  }


  private _loadFeatureGroupsMetadata() {
    this.restService.get(PortalAPI.ALL_FEATURE_GROUPS).subscribe((res:any[])=>{
      this.featureGroupsMetadata = res;
      this.featureGroupsMetadata.forEach((group:any)=>{
        this.featureGroup[group.groupId] = group.groupName;
      })
    })
  }

  private _loadFeatureTypesMetadata() {
    this.restService.get(PortalAPI.ALL_FEATURE_TYPES).subscribe((res:any[])=>{
      this.featureTypesMetadata = res;
      this.featureTypesMetadata.forEach((type:any)=>{
        this.featureType[type.featureTypeId] = type.featureTypeName;
      })
    })
  }

  loadJobsMetadata(jobIds:any[]) {
    const ids:any[] = this._validateAndGetJobIds(jobIds);
    const payload = {
      "jobIds": ids
    }
    if(ids?.length) {
      console.log("New JObIds found : ", ids);
      this.restService.post(PortalAPI.GET_JOB_NAMES, payload).subscribe((res:any[])=>{
          this.jobsMetadata = this.jobsMetadata?.length ? this.jobsMetadata.concat(res) : res;
          this.jobsMetadata = this._fetchUniqueArrayData(this.jobsMetadata, 'jobId', 'name');
          this.jobsMetadata.forEach((job:any)=>{
            this.jobIdToJobName[job.jobId] = job.name;
            this.jobIdToWONo[job.jobId] = job.workorderNumber;
          })
      })
    } else {
      console.log("No change in jobIds....!");
    }
  }

  loadJobToUserAccessMetadata(data:any[], userId:any) {
    const jobIds = data.map((ft:any) => ft?.properties?.jobId)
    const payload = {
      objectTypeId: 1,
      userId: userId,
      objectId: jobIds
    }  
    this.restService.post(PortalAPI.GET_ACCESS_PERMISSION_FOR_JOB, payload).subscribe((resp:any[])=>{
      resp?.forEach((job:any)=>{
        this.jobIdToAccess[job.objectId] = job.permissionTypeId
      })
    },
    (error:any)=>{
      console.log("Error in loadJobToUserAccessMetadata: ", error)
    })
  }

  private _validateAndGetJobIds(jobIds: any[]): any[] {
    const ids:any[] = jobIds.filter(x => !Object.keys(this.jobIdToJobName).includes(x));
    return ids;
    
  }

  private _fetchUniqueArrayData(arr: any[], field1: any, field2: any) {
    return arr.filter((value, index, self) =>
      index === self.findIndex((t) =>
        t[field1] === value[field1] && t[field2] === value[field2]
      ))
  }
}
