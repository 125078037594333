import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// import { AzureBlobStorageService } from 'src/app/modules/sitemapadmin/services/azure-blob-storage.service';
import { AzureBlobStorageServiceVideo } from 'src/app/services/azure-blob-storage.service';
import { FormGroup, FormBuilder, Validators, FormControl, NgForm } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastPackage, ToastrService } from 'ngx-toastr';
import { callbackify } from 'util';
import { BlobClient } from '@azure/storage-blob';

@Component({
  selector: 'app-training-videos',
  templateUrl: './training-videos.component.html',
  styleUrls: ['./training-videos.component.css'],
})
export class TrainingVideos implements OnInit {
  // SAS (shared access signatures)
  // sas =
  //   '?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-03-31T14:24:32Z&st=2022-08-03T06:24:32Z&spr=https,http&sig=XNhNWf022pmV0kTeR4tAghyRf3mRgd3OaCzSoSsSgTE%3D';
  sas = '';
  picturesList: string[] = [];
  picturesDownloaded: string[] = [];

  videosList: string[] = [];
  videoDownloaded: any;
  uploadFileArr: any;
  uploadFileName: any;
  uploadForm: any;
  videoUrl: any;
  changeVideoUrl: any;
  selectVideoName: any;
  selectVideoNameToRemove: any[] = [];
  fileArrList: any = [];
  checkboxForm: any;
  myFileInput: any;

  submitted = false;
  @ViewChild('help-file-upload')
  myInputVariable!: ElementRef;

  picturesContainer:string = '';
  picturesContainerSAS:string = '';
  azureEnvAccount:string = '';

  constructor(
    private blobService: AzureBlobStorageServiceVideo,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.picturesContainer = this.blobService.picturesContainer;
    this.picturesContainerSAS = this.blobService.picturesContainerSAS;
    this.sas = this.picturesContainerSAS;
    this.azureEnvAccount = this.blobService.azureEnvAccount;
  }

  ngOnInit(): void {
    this.reloadImages();
    this.uploadForm = this.fb.group({
      title: ['', [Validators.required]],
    });
    this.checkboxForm = this.fb.group({
      videoName: ['', [Validators.required]],
    });
  }

  public setSas(event: any) {
    this.sas = event.target.value;
  }

  public imageSelected(file: any) {
    this.uploadFileArr = file.target.files[0];
    this.uploadFileName = file.target.files[0].name;
    if (file.target.files[0]) {
      this.uploadFileArr = file.target.files[0];
      this.uploadFileName = file.target.files[0].name;
    }
  }

  // Delete any Image from Azure
  public deleteImage(name: string) {
    this.blobService.deleteImage(this.sas,this.picturesContainer, name, () => {
      // this.reloadImages();
      this.spinner.hide();
    });
  }

  public downloadImage(name: string) {
    this.blobService.downloadImage(this.sas,this.picturesContainer, name, (blob) => {
      let url = window.URL.createObjectURL(blob);
      console.log(url);
      window.open(url);
    });
  }

  private reloadImages() {
    this.fileArrList = [];
    this.blobService.listImages(this.sas,this.picturesContainer).then((list) => {
      this.picturesList = list;

      const array: any = [];
      this.picturesDownloaded = array;
      for (let obj of this.picturesList) {
        const final = JSON.parse(obj);
        const title = final.name.split('.');
        final.title = title[0];
        final.checkBoxValue = false;
        this.fileArrList.push(final);
      }
      this.videoUrl =
        this.azureEnvAccount+'/'+this.picturesContainer+"/" +
        this.fileArrList[0].name + this.picturesContainerSAS;
        // '?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-03-31T14:24:32Z&st=2022-08-03T06:24:32Z&spr=https,http&sig=XNhNWf022pmV0kTeR4tAghyRf3mRgd3OaCzSoSsSgTE%3D&_=1660018601561';
      this.changeVideoUrl = this.videoUrl;
    });
  }

  get uploadFormControl() {
    return this.uploadForm.controls;
  }

  // Publish file on Azure
  publishUploadFile() {
    this.submitted = true;
    var videoName = '';
    var videoTitle = (document.getElementById('help-vt') as HTMLInputElement)
      .value;
    videoName = (
      document.getElementById('help-file-upload') as HTMLInputElement
    ).value.toLowerCase();
    if (this.uploadForm.valid && (videoName != null || videoName != '')) {
      if (
        videoName.includes('.mp4') ||
        videoName.includes('.mov') ||
        videoName.includes('.wmv')
      ) {
        if (videoTitle != '') {
          this.spinner.show();

          const match = this.uploadFileName.split('.');
          this.uploadFileName = this.uploadForm.value.title + '.' + match[1];
          console.log(this.uploadFileName);
          this.blobService.uploadImage(
            this.sas,this.picturesContainer,
            this.uploadFileArr,
            this.uploadFileName,
            () => {
              this.reloadImages();
              (document.getElementById('help-vt') as HTMLInputElement).value =
                '';

              this.spinner.hide();
              this.toastr.success('Video uploaded Successfully.');
            }
          );
        } else {
          this.uploadForm = this.fb.group({
            title: ['', [Validators.required]],
          });
        }
      } else {
        (document.getElementById('help-vt') as HTMLInputElement).value = '';

        this.toastr.error('Please upload a valid video');
      }
    } else {
      console.log('Error Video Title');
    }
  }

  cancelUpload() {
    (document.getElementById('help-vt') as HTMLInputElement).value = '';
    this.uploadFileName = null;
  }

  changeVideo(e: any, file: any) {
    this.selectVideoName = file.name;
    if (e.target.checked) {
      this.selectVideoNameToRemove.push(this.selectVideoName);

      this.changeVideoUrl =
        this.azureEnvAccount+'/'+this.picturesContainer+"/" +
        file.name + this.picturesContainerSAS;
        // '?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-03-31T14:24:32Z&st=2022-08-03T06:24:32Z&spr=https,http&sig=XNhNWf022pmV0kTeR4tAghyRf3mRgd3OaCzSoSsSgTE%3D&_=1660018601561';
    } else {
      var index = this.selectVideoNameToRemove.indexOf(this.selectVideoName);
      if (index > -1) {
        this.selectVideoNameToRemove.splice(index, 1);
      }
    }
  }

  removeFile() {
    if (this.selectVideoNameToRemove.length == 0) {
      (document.getElementById('trash') as HTMLButtonElement).disabled = true;
    } else {
      if (confirm('Do you want to remove ?')) {
        this.spinner.show();
        for (var m = 0; m < this.selectVideoNameToRemove.length; m++) {
          this.deleteImage(this.selectVideoNameToRemove[m]);
        }
        this.reloadImages();
        this.spinner.hide();
        this.toastr.success('Successfully deleted selected video(s).');
      }
    }
  }
}
