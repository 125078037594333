<ul class="nav nav-tabs d-flex justify-content-center" id="helpTab" role="tablist">
  <!-- <li class="nav-item" role="presentation">
    <button class="nav-link active" id="faq-tab" data-bs-toggle="tab" data-bs-target="#faq" type="button" role="tab"
      aria-controls="faq" aria-selected="true">FAQ</button>
  </li>
  <li class="nav-item" role="presentation" style="display:none;">
    <button class="nav-link" id="gettying-started-tab" data-bs-toggle="tab" data-bs-target="#getting-started"
      type="button" role="tab" aria-controls="getting-started" aria-selected="false">Getting Started</button>
  </li>
  <li class="nav-item" role="presentation" style="display:none;">
    <button class="nav-link" id="trouble-shoot-tab" data-bs-toggle="tab" data-bs-target="#trouble-shoot" type="button"
      role="tab" aria-controls="trouble-shoot" aria-selected="false">Troubleshoot</button>
  </li>
  <li class="nav-item" role="presentation" style="display:none;">
    <button class="nav-link" id="tech-specification-tab" data-bs-toggle="tab" data-bs-target="#tech-specification"
      type="button" role="tab" aria-controls="tech-specification" aria-selected="false">Tech Specification</button>
  </li> -->
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="training-videos-tab" data-bs-toggle="tab" data-bs-target="#training-videos"
      type="button" role="tab" aria-controls="training-videos" aria-selected="false">Training Videos</button>
  </li>
  <li class="nav-item" role="presentation" style="display:none;">
    <button class="nav-link disabled" id="forms-documents-tab" data-bs-toggle="tab" data-bs-target="#forms-documents"
      type="button" role="tab" aria-controls="forms-documents" aria-selected="false">Forms/Documents</button>
  </li>
</ul>

<div class="tab-content" id="helpTabContent">
  <!-- <div class="tab-pane fade show active" id="faq" role="tabpanel" aria-labelledby="home-tab">
    <app-faq></app-faq>
  </div>

  <div class="tab-pane fade" id="getting-started" role="tabpanel" aria-labelledby="getting-started-tab">
    2
  </div>

  <div class="tab-pane fade" id="trouble-shoot" role="tabpanel" aria-labelledby="trouble-shoot-tab">
    3
  </div>

  <div class="tab-pane fade" id="tech-specification" role="tabpanel" aria-labelledby="tech-specification-tab">
    4
  </div> -->

  <div class="tab-pane fade show active" id="training-videos" role="tabpanel" aria-labelledby="training-videos-tab">
    <app-training-videos></app-training-videos>
  </div>

  <div class="tab-pane fade" id="forms-documents" role="tabpanel" aria-labelledby="forms-documents-tab">
    <app-forms-documents></app-forms-documents>
  </div>
</div>
