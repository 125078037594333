// General objects and functions to assist withe editing mode
const siteMapDrawPointIds = ['sitemap-points-symbol-inactive.hot', 'sitemap-points-symbol-inactive.cold', 'sitemap-points-symbol-active.hot', 'sitemap-points-symbol-active.cold'];
const siteMapDrawPointHighlightIds = ['sitemap-points-highlight-inactive.hot', 'sitemap-points-highlight-inactive.cold', 'sitemap-points-highlight-active.hot', 'sitemap-points-highlight-active.cold'];
const siteMapDrawLineIds = ['sitemap-lines-inactive.hot', 'sitemap-lines-inactive.cold', 'sitemap-lines-active.hot', 'sitemap-lines-active.cold'];
const siteMapDrawLineHighlightIds = ['sitemap-lines-highlight-inactive.hot', 'sitemap-lines-highlight-inactive.cold', 'sitemap-lines-highlight-active.hot', 'sitemap-lines-highlight-active.cold'];
const siteMapDrawVertexIds = ['sitemap-vertexes-active.hot', 'sitemap-vertexes-active.cold', 'sitemap-vertexes-inactive.hot', 'sitemap-vertexes-inactive.cold'];
const siteMapDrawMidpointIds = ['sitemap-midpoints.hot', 'sitemap-midpoints.cold'];
const snapPointLayerIds = ['snap-point-inactive.cold', 'snap-point-active.cold', 'snap-point-inactive.hot', 'snap-point-active.hot'];

// Set an Id when creating a new Point/LineString
export const sitemapDrawLineStringId = 'sitemap_draw_line_string_id';
export const sitemapDrawPointId = 'sitemap_draw_point_id';
// Helper functions for editing
// Reorder Point/Line layers (when user selects a Point/Line, bring Point/Line layers to top)
export function bringLineLayersToTop(map, siteMapMVTLineLayerIds) {
  // First highlights from Draw
  siteMapDrawLineHighlightIds.forEach((layerId) => {
    map.moveLayer(layerId);
  });
  // Then lines from Draw
  siteMapDrawLineIds.forEach((layerId) => {
    map.moveLayer(layerId);
  });
  // Then lines from MVT
  siteMapMVTLineLayerIds.forEach((layerId) => {
    map.moveLayer(layerId);
  });
  // Then vertexes from Draw
  siteMapDrawVertexIds.forEach((layerId) => {
    map.moveLayer(layerId);
  });
  // Then midpoints from Draw
  siteMapDrawMidpointIds.forEach((layerId) => {
    map.moveLayer(layerId);
  });
}

export function bringPointLayersToTop(map, siteMapMVTPointLayerIds) {
  // First highlights from Draw
  siteMapDrawPointHighlightIds.forEach((layerId) => {
    map.moveLayer(layerId);
  });
  // Then points from Draw
  siteMapDrawPointIds.forEach((layerId) => {
    map.moveLayer(layerId);
  });
  // The points from MVT
  siteMapMVTPointLayerIds.forEach((layerId) => {
    map.moveLayer(layerId);
  });
}

// Deselect any selected Points when entering a Line editing mode
export function deselectPointsForVertexEditing(draw) {
  let pointFeatures = draw.getSelected()
    .features
    .filter((feature) => feature.geometry.type === 'Point');
  if (pointFeatures.length > 0) {
    draw.changeMode('sitemap_simple_select');
  }
}

export function getSelectedLineFeature(draw) {
  let selectedLineFeatures = draw.getSelected()
    .features
    .filter((feature) => feature.geometry.type === 'LineString' || feature.geometry.type === 'MultiLineString');

  if (selectedLineFeatures.length > 0) {
    return selectedLineFeatures[selectedLineFeatures.length - 1];
  } else {
    return undefined;
  }
}

export function setSiteMapDrawMode(e, draw, editingState, mapLayerId, navigatedFeatures, isSnappingToVertex, isSnappingToLine) {
  // Find the active editing Draw mode, if any
  let currentDrawMode = draw.getMode();
  let selectedLineFeature = getSelectedLineFeature(draw);


  // Set e
  let ePointsLength;
  if (e === undefined) {
    ePointsLength = 0;
  } else {
    ePointsLength = e.points.length;
  }

  if (editingState.editingModes.isMovingVertex) {
    if (selectedLineFeature !== undefined && ePointsLength === 0) {
      console.warn(`Setting SiteMap Draw mode from ${currentDrawMode} to move_vertex with feature Id ${selectedLineFeature.id}.`);
      draw.changeMode('move_vertex', {
        featureId: selectedLineFeature.id,
        mapLayerId: mapLayerId,
        navigatedFeatures: navigatedFeatures,
        isSnappingToVertex: isSnappingToVertex,
        isSnappingToLine: isSnappingToLine});
    }
  } else if (editingState.editingModes.isAddingVertex) {
    if (selectedLineFeature !== undefined) {
      console.warn(`Setting SiteMap Draw mode from ${currentDrawMode} to add_vertex with feature Id ${selectedLineFeature.id}.`);
      draw.changeMode('add_vertex', {
        featureIds: [selectedLineFeature.id],
        mapLayerId: mapLayerId,
        navigatedFeatures: navigatedFeatures,
        isSnappingToVertex: isSnappingToVertex,
        isSnappingToLine: isSnappingToLine});
    }
  } else if (editingState.editingModes.isDeletingVertex) {
    if (selectedLineFeature !== undefined && ePointsLength === 0) {
      console.warn(`Setting SiteMap Draw mode from ${currentDrawMode} to delete_vertex with feature Id ${selectedLineFeature.id}.`);
      draw.changeMode('delete_vertex', { featureId: selectedLineFeature.id });
    }
  } else if (editingState.editingModes.isMergingLine) {
    // if (selectedLineFeature !== undefined) {
    //   draw.changeMode('merge_line', { featureIds: [selectedLineFeature.id] });
    // } else {
    //   draw.changeMode('sitemap_simple_select');
    // }
  } else if (currentDrawMode === 'draw_point' || currentDrawMode === 'sitemap_draw_point') {
    console.warn(`Setting SiteMap Draw mode from ${currentDrawMode} to sitemap_draw_point.`);
    draw.changeMode('sitemap_draw_point', {
      mapLayerId: mapLayerId,
      navigatedFeatures: navigatedFeatures,
      isSnappingToVertex: isSnappingToVertex,
      isSnappingToLine: isSnappingToLine});
  } else if (currentDrawMode === 'draw_line_string' || currentDrawMode === 'sitemap_draw_line_string') {
    let drawLineStringFeature = draw.get(sitemapDrawLineStringId);
    if (drawLineStringFeature !== undefined) {
      let coords = drawLineStringFeature.geometry.coordinates;
      if (coords.length === 1) {
        console.warn(`Setting SiteMap Draw mode from ${currentDrawMode} to sitemap_draw_line_string.`);
        draw.changeMode('sitemap_draw_line_string', {
          mapLayerId: mapLayerId,
          navigatedFeatures: navigatedFeatures,
          isSnappingToVertex: isSnappingToVertex,
          isSnappingToLine: isSnappingToLine});
      } else if (coords.length === 2) {
        let fromCoords = coords[coords.length - 2];
        let from = {
          type: 'Point',
          coordinates: fromCoords
        }
        console.warn(`Setting SiteMap Draw mode from ${currentDrawMode} to sitemap_draw_line_string.`);
        draw.changeMode('sitemap_draw_line_string', {
          from: from,
          mapLayerId: mapLayerId,
          navigatedFeatures: navigatedFeatures,
          isSnappingToVertex: isSnappingToVertex,
          isSnappingToLine: isSnappingToLine});
      } else if (coords.length > 2) {
        let fromCoords = coords[coords.length - 2];
        let from = {
          type: 'Point',
          coordinates: fromCoords
        }
        console.warn(`Setting SiteMap Draw mode from ${currentDrawMode} to sitemap_draw_line_string with feature Id ${sitemapDrawLineStringId}.`);
        draw.changeMode('sitemap_draw_line_string', {
          featureId: sitemapDrawLineStringId,
          from: from,
          mapLayerId: mapLayerId,
          navigatedFeatures: navigatedFeatures,
          isSnappingToVertex: isSnappingToVertex,
          isSnappingToLine: isSnappingToLine});
      }
    }

  } else if (currentDrawMode === 'simple_select' || currentDrawMode === 'sitemap_simple_select') {
    if (draw.getSelectedIds().length > 0) {
      if (selectedLineFeature === undefined) {
        console.warn(`Setting SiteMap Draw mode from ${currentDrawMode} to sitemap_simple_select with feature Ids ${draw.getSelectedIds()}.`);
        draw.changeMode('sitemap_simple_select', {
          featureIds: draw.getSelectedIds(),
          mapLayerId: mapLayerId,
          navigatedFeatures: navigatedFeatures,
          isSnappingToVertex: isSnappingToVertex,
          isSnappingToLine: isSnappingToLine});
      } else {
        console.warn(`Setting SiteMap Draw mode from ${currentDrawMode} to sitemap_direct_select with feature Id ${selectedLineFeature.id}.`);
        draw.changeMode('sitemap_direct_select', {
          featureId: selectedLineFeature.id,
          mapLayerId: mapLayerId,
          navigatedFeatures: navigatedFeatures,
          isSnappingToVertex: isSnappingToVertex,
          isSnappingToLine: isSnappingToLine});
      }
    }

  } else if (currentDrawMode === 'direct_select' || currentDrawMode === 'sitemap_direct_select') {
    // Get the selected Line if any
    if (selectedLineFeature !== undefined && ePointsLength === 0) {
      console.warn(`Setting SiteMap Draw mode from ${currentDrawMode} to sitemap_direct_select with feature Id ${selectedLineFeature.id}.`);
      // draw.delete(selectedLineFeature);
      // draw.add(selectedLineFeature);
      draw.changeMode('sitemap_direct_select', {
        featureId: selectedLineFeature.id,
        mapLayerId: mapLayerId,
        navigatedFeatures: navigatedFeatures,
        isSnappingToVertex: isSnappingToVertex,
        isSnappingToLine: isSnappingToLine});
    }
  }
}

export function toggleMoveVertexMode(bool, map, draw, mapLayerId, navigatedFeatures, isSnappingToVertex, isSnappingToLine, siteMapMVTLineLayerIds) {
  // if (bool) {
  //   console.log('Starting "Move Vertex".');
  //   // Deselect any selected points and disable interactions
  //   deselectPointsForVertexEditing(draw);
  //   // First move all line layers to the top of the map
  //   bringLineLayersToTop(map, siteMapMVTLineLayerIds);
  //   // If there is a line selected, enter "move_vertex" Draw mode
  //   let selectedLineFeature = getSelectedLineFeature(draw);
  //   if (selectedLineFeature !== undefined) {
  //     draw.changeMode('move_vertex', {
  //       featureId: selectedLineFeature.id,
  //       mapLayerId: mapLayerId,
  //       navigatedFeatures: navigatedFeatures,
  //       isSnappingToVertex: isSnappingToVertex,
  //       isSnappingToLine: isSnappingToLine});
  //   } else {
  //     draw.changeMode('sitemap_simple_select');
  //   }
  // } else {
  //   console.log('Stopping "Move Vertex".');
  //   // Change Draw mode to "simple_select"
  //   draw.changeMode('sitemap_simple_select');
  // }
}

export function toggleAddVertexMode(bool, map, draw, mapLayerId, navigatedFeatures, isSnappingToVertex, isSnappingToLine) {
  // if (bool) {
  //   console.log('Starting "Add Vertex".');
  //   // Deselect any selected points and disable interactions
  //   deselectPointsForVertexEditing(draw)
  //   // Check for a selected Line and update currentlySelectedLine
  //   let selectedLineFeature = getSelectedLineFeature(draw);
  //   if (selectedLineFeature !== undefined) {
  //     draw.changeMode('add_vertex', {
  //       featureIds: [selectedLineFeature.id],
  //       mapLayerId: mapLayerId,
  //       navigatedFeatures: navigatedFeatures,
  //       isSnappingToVertex: isSnappingToVertex,
  //       isSnappingToLine: isSnappingToLine});
  //   }
  // } else {
  //   console.log('Stopping "Add Vertex".');
  //   // Change Draw mode to "simple_select"
  //   draw.changeMode('sitemap_simple_select');
  //   // Make sure to reset cursor
  //   map.getCanvasContainer().style.cursor = '';
  // }
}

export function toggleDeleteVertexMode(bool, map, draw, siteMapMVTLineLayerIds) {
  // if (bool) {
  //   console.log('Starting "Delete Vertex".');
  //   // Deselect any selected points and disable interactions
  //   deselectPointsForVertexEditing(draw)
  //   // First move all line layers to the top of the map
  //   bringLineLayersToTop(map, siteMapMVTLineLayerIds);
  //   // If there is a line selected, enter "delete_vertex" Draw mode
  //   let selectedLineFeature = getSelectedLineFeature(draw);
  //   if (selectedLineFeature !== undefined) {
  //     draw.changeMode('delete_vertex', { featureId: selectedLineFeature.id });
  //   }
  // } else {
  //   console.log('Stopping "Delete Vertex".');
  //   // Change Draw mode to "simple_select"
  //   draw.changeMode('sitemap_simple_select');
  // }
}

export function toggleMergeLineMode(bool, map, draw) {
  // if (bool) {
  //   console.log('Starting MergeLine.');
  //   // Deselect any selected points and disable interactions
  //   deselectPointsForVertexEditing(draw)
  //   // Check for a selected Line and update currentlySelectedLine
  //   let selectedLine = getSelectedLineFeature(draw);
  //   if (selectedLine !== undefined) {
  //     // draw.changeMode('merge_line', { featureIds: [selectedLine.id]});
  //     return selectedLine.id;
  //   } else {
  //     return '';
  //   }
  // } else {
  //   console.log('Stopping MergeLine.');
  //   // Make sure to reset cursor
  //   map.getCanvasContainer().style.cursor = '';
  //   // Deselet features
  //   draw.changeMode('sitemap_simple_select');
  // }
}

export function toggleSnapping(e, draw, editingState, currentEditinglayerId, navigatedFeatures, isSnappingToVertex, isSnappingToLine) {
  // setSiteMapDrawMode(e, draw, editingState, currentEditinglayerId, navigatedFeatures, isSnappingToVertex, isSnappingToLine);
}