import { Component, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PortalAPI } from 'src/app/constants/api.constant';
import { CommonFunctionService } from 'src/app/services/common-function-service';
import { IAllPermission, IPersmissionType, IRoleList } from '../../constants/manage-roles-data';
import { IUserModel } from '../../constants/manage-users-data';
import { ConnectionService } from '../../services/connection-service';

@Component({
  selector: 'app-manage-roles',
  templateUrl: './manage-roles.component.html',
  styleUrls: ['./manage-roles.component.css']
})
export class ManageRolesComponent implements OnInit {

  @Input() searchValue = "";
  @Input() rolesAdded = false;
  roleList: IRoleList[] = [];
  systemPermissionTypeData: IPersmissionType[] = [];
  systemAllPermissionData: IAllPermission[] = [];
  rolePermission: any[] = []
  userList: IUserModel[] = [];
  permissionsIsCheckedData: any = {};
  roleCheckedData: any[] = [];
  tenantId: string = "";
  permissionSelectAll: boolean = false;
  selectedUser: any[] = [];
  userDrpDwnOpen: boolean = false;
  userRoleExist: any[] = [];
  apiRoleListData: IRoleList[] = [];
  isCheckClick: boolean = false;
  activeUserArr: any = [];
  isCheckedRoles:boolean = false;
  constructor(
    private spinner: NgxSpinnerService,
    private restService: ConnectionService,
    private toastr: ToastrService,
    private commonService: CommonFunctionService
  ) {
    this.getTenantID();
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    // get the clicked element
    if (event.target?.id && event.target?.id?.includes('multiSelect')) {
      this.userDrpDwnOpen = true;
    }
    else {
      this.userDrpDwnOpen = false;
    }
  }

  ngOnInit(): void {
    this.getRoleList();
    // this.getUserList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rolesAdded && this.rolesAdded) {
      this.getRoleList();
      this.roleCheckedData = [];
    }
    if (changes.searchValue) {
      this.cancelPermission();
      this.roleList = this.commonService.searchDataWithoutDB(this.searchValue, 'name', this.apiRoleListData);
      this.roleList?.forEach((element: any) => {
        element.isChecked = false;
      });
      if (this.roleList.length === 0) {
        this.systemPermissionTypeData = [];
        this.systemAllPermissionData = [];
        this.roleCheckedData = [];
        this.userList = [];
      }
    }
  }

  //#region getTenantID method is used to get azure details from MsalService
  getTenantID() {
    const accountDetail = this.commonService.getAccountDetail()[0];
    if (accountDetail.tenantId) {
      this.tenantId = accountDetail.tenantId;
    }
    else {
      this.tenantId = '1';
    }
  }
  //#endregion

  //#region getSystemPermissionType method is use to get all the permission type list through the GetAllSystemPermissionsTypes api
  getSystemPermissionType() {
    this.spinner.show();
    this.systemPermissionTypeData = [];
    this.restService.tenantId = this.tenantId;

    this.restService.get(PortalAPI.GET_ALL_SYSTEM_PERMISSIONS_TYPES).subscribe(response => {
      if (response && response.length > 0) {
        response.forEach((element: any) => {
          element.checked = false;
        });
        this.systemPermissionTypeData = response
        this.getAllSystemPermission();
        // this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    })
  }
  //#endregion

  //#region getAllSystemPermission method is use to get all system permission list through the GetAllSystemPermissions api
  getAllSystemPermission() {
    this.spinner.show();
    this.systemAllPermissionData = [];
    this.restService.tenantId = this.tenantId;

    this.restService.get(PortalAPI.GET_ALL_SYSTEM_PERMISSIONS).subscribe(response => {
      if (response && response.length > 0) {
        response.forEach((element: any) => {
          element.permissionTypeChecked = false;
          element.permissionSubTypeChecked = false;
          element.permissionChecked = false;
        });
        // this.systemAllPermissionData = response
        let withoutViewEditData = [];
        let viewEditData = [];
        // # viewEditData is used to show data of View and Edit after filter
        // the other data and 2 sort based on TypeName and SubTypeName
        viewEditData = response
          .filter(function (el: any) {
            return el.permissionSubTypeName != null;
          })
          .sort(function (a: any, b: any) {
            if (a.permissionSubTypeName < b.permissionSubTypeName) {
              return -1;
            }
            if (a.permissionSubTypeName > b.permissionSubTypeName) {
              return 1;
            }
            return 0;
          })
          .sort(function (a: any, b: any) {
            if (a.permissionTypeName < b.permissionTypeName) {
              return -1;
            }
            if (a.permissionTypeName > b.permissionTypeName) {
              return 1;
            }
            return 0;
          });
        // # withoutViewEditData is used to show data which doesn't include on viewEditData

        withoutViewEditData = response.filter(function (el: any) {
          return el.permissionSubTypeName == null;
        });
        this.systemAllPermissionData = [
          ...viewEditData,
          ...withoutViewEditData,
        ];
        console.log(
          'this.systemAllPermissionData',
          this.systemAllPermissionData
        );

        this.getRolePermission(this.roleCheckedData[0]);
        // this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    })
  }
  //#endregion

  //#region getRoleList method is use to get all the role list through the GetAllRoles api
  getRoleList() {
    this.spinner.show();
    this.roleList = [];
    this.restService.tenantId = this.tenantId;

    this.restService.get(PortalAPI.GET_ALL_ROLES).subscribe(response => {
      if (response && response.length > 0) {
        response.forEach((element: any) => {
          element.isChecked = false;
        })
        this.roleList = response;
        this.apiRoleListData = [...this.roleList];
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, err => {
      this.toastr.error("Role Httperror: " + err.status + "", "", { timeOut: 3000 });
      this.spinner.hide();
    })
  }
  //#endregion

  //#region getUserList method is use to get all the job list through the GetAllUsers api
  getUserList() {
    this.spinner.show();
    this.restService.tenantId = this.tenantId;
    const restServiceBody = {
      "request": {
        "pageNumber": 1,
        "pageSize": 1000
      },
      "searchValue": "string"
    }
    this.restService.post(PortalAPI.GET_ALL_USERS, restServiceBody).subscribe(response => {
      if (response && response.usersEntities && response.totalRecord > 0) {
        this.userList = response.usersEntities;
        this.userList.forEach((element: any) => {
          element.checkBox = false;
        });
        this.getRoleForUser();
      }
      this.spinner.hide();
    }, err => {
      this.toastr.error("User Httperror: " + err.status + "", "", { timeOut: 3000 });
      this.spinner.hide();
    })
  }
  //#endregion

  //#region getRolePermission method is use to get all the permission list for particular role using GetRolePermissions api
  getRolePermission(roleId: string) {
    this.spinner.show();
    this.permissionSelectAll = false;
    this.rolePermission = [];
    this.restService.tenantId = this.tenantId;

    this.restService.get(PortalAPI.GET_ROLE_PERMISSIONS, roleId).subscribe(response => {
      if (response && response.length > 0) {
        response.forEach((element: any) => {
          const index = this.systemAllPermissionData.findIndex((it: any) => it.permissionId === element.permissionId);
          if (index != -1) {
            this.systemAllPermissionData[index].permissionChecked = true;
          }
          if (response.length === this.systemAllPermissionData.length) {
            this.systemAllPermissionData[index].permissionSubTypeChecked = true;
            this.permissionSelectAll = true;
          }
        });
        if (response.length === this.systemAllPermissionData.length) {
          this.systemPermissionTypeData.forEach((el: any) => {
            el.checked = true;
          })
        }
        this.systemPermissionTypeData.forEach((el: any, index: number) => {
          const allPermissionLength = this.systemAllPermissionData.filter(it => it.permissionTypeId === el.permissionTypeId);
          const checkedPermissionLength = this.systemAllPermissionData.filter(it => it.permissionTypeId === el.permissionTypeId && it.permissionChecked);
          if (allPermissionLength.length === checkedPermissionLength.length) {
            el.checked = true;
          }
          const uniqArray = [...new Map(allPermissionLength.map(item =>
            [item.permissionSubTypeId, item])).values()];

          uniqArray.forEach((item: any) => {
            const permissionSubType = this.systemAllPermissionData.filter(it => it.permissionSubTypeId === item.permissionSubTypeId);
            const checkedpermissionSubType = this.systemAllPermissionData.filter(it => it.permissionSubTypeId === item.permissionSubTypeId && it.permissionChecked);
            if (permissionSubType.length === checkedpermissionSubType.length) {
              this.systemAllPermissionData.filter(it => it.permissionSubTypeId === item.permissionSubTypeId).forEach((chk: any) => {
                chk.permissionSubTypeChecked = true;
              })
            }
          });
        });
        this.rolePermission = response;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, err => {
      this.toastr.error("Role all permission Httperror: " + err.status + "", "", { timeOut: 3000 });
      this.spinner.hide();
    })
  }
  //#endregion

  //#region getCheckedUncheckedPermissions method is used to get the true or false in permission list
  getCheckedUncheckedPermissions(data: any) {
    this.permissionsIsCheckedData = {};
    let checkedData: any = [], uncheckedData: any = [];
    this.systemAllPermissionData?.forEach((element: any, index: number) => {
      if (element.permissionChecked) {
        checkedData.push(element.permissionId);
      }
      else {
        if (this.rolePermission?.find(it => (it.permissionId === element.permissionId))) {
          uncheckedData.push(this.rolePermission.find(it => (it.permissionId === element.permissionId)).permissionId);
        }
      }
    });
    if (uncheckedData.length === 0 && this.rolePermission.length > 0 && this.rolePermission.filter(it => !checkedData.some((a: any) => it.permissionId === a)).length > 0) {
      const temp = this.rolePermission.filter(it => !checkedData.some((a: any) => it.permissionId === a));
      temp.forEach((it: any) => {
        uncheckedData.push(it.permissionId);
      });
    }

    const tempLength = this.systemAllPermissionData.filter(it => it.permissionChecked);
    if (this.systemAllPermissionData.length === tempLength.length) {
      this.permissionSelectAll = true;
    }
    else {
      this.permissionSelectAll = false;
    }
    this.isCheckClick = true;
    this.permissionsIsCheckedData = { checkedData, uncheckedData };
  }
  //#endregion

  //#region grantRolePermission method is used to save the checked permission in DB
  grantRolePermission() {
    if (this.roleCheckedData.length === 1 && (this.permissionsIsCheckedData?.checkedData?.length > 0 || this.permissionsIsCheckedData?.uncheckedData?.length > 0)) {
      this.spinner.show();
      let restServiceBody = {
        "roleId": this.roleCheckedData,
        "permissionId": this.permissionsIsCheckedData.checkedData
      }
      // this.spinner.show();
      this.restService.tenantId = this.tenantId;
      this.restService.post(PortalAPI.GRANT_ROLE_PERMISSION, restServiceBody).subscribe(response => {
        if (response) {
          this.revokeRolePermission(response);
        }
      }, err => {
        this.toastr.error("Grant role permission Httperror: " + err.status + "", "", { timeOut: 3000 });
        this.spinner.hide();
      });
    }
    else if (this.roleCheckedData.length === 0) {
      this.toastr.error("Please select atleast one Role from Role list!", "", {
        timeOut: 3000
      });
      return;
    }
    else if (this.roleCheckedData.length > 1) {
      this.toastr.error("Select only one Role at a time from Role list!", "", {
        timeOut: 3000
      });
      return;
    }
    else {
      this.toastr.error("Please select atleast one Permission from permission list!", "", {
        timeOut: 3000
      });
    }

  }
  //#endregion

  revokeRolePermission(response: any) {
    let restServiceBody = {
      "roleId": this.roleCheckedData,
      "permissionId": this.permissionsIsCheckedData.uncheckedData
    }
    if (this.permissionsIsCheckedData?.uncheckedData?.length > 0) {
      this.restService.post(PortalAPI.REVOKE_ROLE_PERMISSION, restServiceBody).subscribe(response => {
        if (response && response?.processingStatus?.status.includes("Success")) {
          this.toastr.success("Permissions has been updated successfully", "", {
            timeOut: 3000
          });
        }
        this.cancelPermission();
        this.spinner.hide();
      }, err => {
        this.toastr.error("Revoke role permission Httperror: " + err.status + "", "", { timeOut: 3000 });
        this.spinner.hide();
      });
    }
    else {
      if (response && response?.processingStatus?.status.includes("Success")) {
        this.toastr.success("Permissions has been updated successfully", "", {
          timeOut: 3000
        });
        this.cancelPermission();
        this.spinner.hide();
      }
      else {
        this.toastr.error(response.processingStatus.message, "", {
          timeOut: 3000
        });
        this.spinner.hide();
      }
    }
    const roleIndex = this.roleList.findIndex(it => it.roleId === this.roleCheckedData[0]);
    if (roleIndex != -1) {
      this.roleList[roleIndex].isChecked = false;
    }
    this.roleCheckedData = [];
  }

  //#region onRoleChange method is used to click on any checkBox in the table then function will occure
  onRoleChange(event: any) {
    this.isCheckedRoles = event.isChecked;
    this.cancelPermission();
    this.getUserList();
    this.roleList.forEach((element: any) => {
      if (element.roleId != event.roleId)
        element.isChecked = false;
    });

    if (this.roleCheckedData.length > 0) {
      const index = this.roleCheckedData.findIndex(
        (item) => item === event.roleId
      );
      if (index != -1) {
        if (event.isChecked === false) {
          this.roleCheckedData.splice(index, 1);
        }
      }
      else {
        this.roleCheckedData.splice(0, 1);
        this.roleCheckedData.push(event.roleId);
      }
    }
    else {
      this.roleCheckedData.push(event.roleId);
    }
    if (this.roleCheckedData.length > 0 && this.isCheckedRoles) {
      this.getSystemPermissionType();
    }
  }
  //#endregion

  //#region savePermission Method is used to call a method on save click
  savePermission() {
    this.grantRolePermission();
  }
  //#endregion

  //#region cancelPermission Method is used to close the permission window
  cancelPermission() {
    const roleIndex = this.roleList.findIndex(it => it.roleId === this.roleCheckedData[0]);
    if (roleIndex != -1) {
      this.roleList[roleIndex].isChecked = false;
    }
    this.roleCheckedData = [];
    this.isCheckClick = false;
    this.selectedUser = [];
    this.permissionsIsCheckedData = {};
    this.rolePermission = [];
    this.activeUserArr = [];
  }
  //#endregion

  //#region onAssignRoleClick method is used to asign a roles to selected user and save in DB
  onAssignRoleClick() {
    if (this.rolePermission.length === 0) {
      let message = "Please select permission(s) before assigning role to a user!";
      console.log("mess: ",this.permissionsIsCheckedData.checked);
      if ((this.permissionsIsCheckedData?.checkedData?.length > 0)) {
        message = "Please save changes before assigning role to user(s).";
      }
      this.toastr.error(message, "", {
        timeOut: 3000
      });
    }
    else if (this.isCheckClick && this.rolePermission.length > 1 && (this.rolePermission.length != this.permissionsIsCheckedData?.checkedData?.length || this.permissionsIsCheckedData?.uncheckedData?.length > 0)) {
      let message = "";
      // if((this.permissionsIsCheckedData?.checkedData?.length > 0 || this.permissionsIsCheckedData?.uncheckedData?.length > 0))
      // {
      message = "Please save changes before assigning role to user(s).";
      // }
      this.toastr.error(message, "", {
        timeOut: 3000
      });
    }
    else {
      if (this.userRoleExist.length > 0 && this.selectedUser.length === 0) {
        this.toastr.error("This role has been already assigned to this user.", "", {
          timeOut: 3000
        });
      }
      else {
        if (this.selectedUser.length === 0) {
          this.toastr.error("Please select atleast one user!", "", {
            timeOut: 3000
          });
        }
        else {
          if ((this.selectedUser.length > 0 || this.userRoleExist.length > 0) && this.roleCheckedData.length > 0
            && (this.rolePermission.length > 0)) {
            this.saveAssignRoleToUser();
          }
        }
      }
    }

  }
  //#endregion

  //#region saveAssignRoleToUser method is used to save the data in db what ever it is grant and revoke
  saveAssignRoleToUser() {
    let revokeArr: any[] = [];
    this.userRoleExist?.forEach((ele: any) => {
      const index = this.selectedUser?.find(it => it === ele.userId);
      if (index > -1 && !ele.checkBox) {
        revokeArr.push(ele.userId);
      }
      // else if(index > -1 && ele.checkBox && this.selectedUser.length === 0){
      //   this.selectedUser.push(ele.userId);
      // }
    });

    if (this.selectedUser.length > 0 || revokeArr.length > 0) {
      this.spinner.show();
      let restServiceBody = {
        "userId": this.selectedUser,
        "roleId": this.roleCheckedData[0]
      }
      this.restService.post(PortalAPI.GRANT_USER_ROLE_ASSIGNMENT, restServiceBody).subscribe(response => {
        if (response && response?.processingStatus?.status.includes("Success")) {
          if (revokeArr?.length > 0) {
            this.revokeUserRoleAssignment(revokeArr);
          }
          else {
            this.toastr.success("User with Role assignment save successfully", "", {
              timeOut: 3000
            });
            this.cancelPermission();
            this.spinner.hide();
          }
        }
        else {
          if (revokeArr?.length > 0) {
            this.revokeUserRoleAssignment(revokeArr);
          }
          else {
            this.toastr.error(response.processingStatus?.message, "", {
              timeOut: 3000
            });
            this.spinner.hide();
          }
        }
      }, err => {
        this.toastr.error("Save role permission Httperror: " + err.status + "", "", { timeOut: 3000 });
        this.spinner.hide();
      })
    }
    else {
      this.toastr.error("Please select atleast one user!", "", {
        timeOut: 3000
      });
    }
  }
  //#endregion

  //#region revokeUserRoleAssignment method is use to revoke the user permission from the db
  revokeUserRoleAssignment(revokeData: any) {
    if (revokeData?.length > 0) {
      this.spinner.show();
      let restServiceBody = {
        "userId": revokeData,
        "roleId": this.roleCheckedData[0]
      }
      this.restService.post(PortalAPI.REVOKE_USER_ROLE_ASSIGNMENT, restServiceBody).subscribe(response => {
        if (response && response?.processingStatus?.status.includes("Success")) {
          this.toastr.success("User with Role assignment save successfully", "", {
            timeOut: 3000
          });
          this.cancelPermission();
          this.spinner.hide();
        }
        else {
          this.toastr.error(response.processingStatus?.message, "", {
            timeOut: 3000
          });
          this.spinner.hide();
        }
      }, err => {
        this.toastr.error("Revoke roles permission Httperror: " + err.status + "", "", { timeOut: 3000 });
        this.spinner.hide();
      });
    }
  }
  //#endregion

  //#region userChange method is used to get selected user from the user list
  userChange(event: any) {
    // this.selectedUser = Number(event.userId);
    if (this.selectedUser.length > 0) {
      const index = this.selectedUser.findIndex(
        (item) => item === event.userId
      );
      if (index != -1) {
        if (event.checkBox === false) {
          this.selectedUser.splice(index, 1);
        }
      }
      else {
        this.selectedUser.push(event.userId);
      }
    }
    else {
      this.selectedUser.push(event.userId);
    }
  }
  //#endregion

  //#region selectPermissionAll method is used to select or unselect all permission
  selectPermissionAll(isChecked: boolean = false) {
    this.systemAllPermissionData.forEach((element: any) => {
      element.permissionChecked = isChecked;
      element.permissionSubTypeChecked = isChecked;
      element.permissionTypeChecked = isChecked;
    });
    this.systemPermissionTypeData.forEach((element: any) => {
      element.checked = isChecked;
    });
    this.getCheckedUncheckedPermissions(this.systemAllPermissionData);
  }
  //#endregion

  //#region getRoleForUser method is used to get the role for particular user
  getRoleForUser() {
    this.userRoleExist = [];
    if (this.roleCheckedData.length > 0) {
      this.userList.forEach((element: any, index: any) => {
        const indexx = this.roleCheckedData.findIndex(it => it === element.roleId);
        if (indexx > -1) {
          element.checkBox = true;
          this.userRoleExist.push(element);
        }
        if (!element.isActive || element.isActive == 'false') {
          // delete this.userList[index];
          // this.userList.splice(index, 1);
        } else {
          this.activeUserArr.push(this.userList[index]);
        }
      });
    }
  }
  //#endregion

}