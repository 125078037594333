import { ErrorHandler, Injectable } from '@angular/core';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { Subscription } from 'rxjs';
import { BehaviorSubjectService } from './behaviorsubject.service';
import { PortalAPI } from '../constants/api.constant';

@Injectable({
  providedIn: 'root'
})
export class AzureBlobStorageServiceVideo {

  // Enter your storage account name  By Default for Dev Env.
  azureEnvAccount = "";

  // Container name
  // It's same for all enviroment (Dev, QA , Prod)
  picturesContainer :string = "helpvideos";
  dprFilesContainer : string = "digitalplanroom";
  importedContainer : string = "importdata";
  helpDocumentContainer : string = "helpdocuments";

  // Enter your storage account for Imported Layer
  importedAccount = "sitemapsadev";
  // For Dev Container and SAS Token Condition for QA is under the method
  // importedContainer = "importdata";
  // sasforImportLayer:string = '';

  picturesContainerSAS:string = '';
  dprFilesContainerSAS:string = '';
  importedContainerSAS:string = '';
  helpDocumentContainerSAS:string = '';
  dprFileSWAPSDownloadSAS:string = '';


  constructor(
    private behaviourSubjectService: BehaviorSubjectService
  ) {
      
      this.azureEnvAccount = PortalAPI.AZURE_URL_CONTAINER;
      this.picturesContainerSAS = PortalAPI.AZURE_PICTURE_CONTAINER_SAS;
      this.dprFilesContainerSAS = PortalAPI.AZURE_DPR_CONTAINER_SAS;
      this.importedContainerSAS = PortalAPI.AZURE_IMPORTED_CONTAINER_SAS;
      this.helpDocumentContainerSAS = PortalAPI.AZURE_HELP_CONTAINER_SAS;
      this.dprFileSWAPSDownloadSAS = PortalAPI.AZURE_DPR_DOWNLOAD_CONTAINER_SAS;

    }

    // Nitin Share Token
    // QA------
    // ?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-12-29T20:42:46Z&st=2022-11-18T12:42:46Z&spr=https&sig=ZFI6h8SZf3iTCtVk1A%2Fv%2BeNl91bmTSOrRvqf2rlHYm8%3D
    // PROD -----
    // ?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-12-29T20:44:45Z&st=2022-11-18T12:44:45Z&spr=https&sig=RDTsXYrzAzg2gpqHRVoy2DISzISRjJkL353bVFG62dk%3D
    // DEV---
    // ?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2022-11-18T20:55:19Z&st=2022-11-18T12:55:19Z&spr=https&sig=2%2FD8rCbAYGWFhKWpziQCVhlufbFZjShl3le%2F84%2FoWXU%3D
    // Dev New One
    // ?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-12-29T19:43:15Z&st=2022-11-21T11:43:15Z&spr=https&sig=aeiUfEa7mmCLEjkk6%2Fsl%2Fv8NK1mHYeMdTQO3qsb13qE%3D
    


  // +Uploading Training Videos Start
  public uploadImage(sas: string, containerName: string, content: Blob, name: string, handler: () => void) {
    return this.uploadBlob(content, name, this.containerClient(sas,containerName), handler);
  }

  public listImages(sas: string, containerName: string): Promise<string[]> {
    return this.listBlobs(this.containerClient(sas,containerName),containerName)
  }

  public downloadImage(sas: string, containerName: string, name: string, handler: (blob: Blob) => void) {
    this.downloadBlob(name, this.containerClient(sas,containerName), handler)
  }

  public deleteImage(sas: string, containerName: string, name: string, handler: () => void) {
    this.deleteBlob(name, this.containerClient(sas,containerName), handler)
  }

  private containerClient(sas: string, containerName: string): ContainerClient {
    return new BlobServiceClient(`${this.azureEnvAccount}?${sas}`)
            .getContainerClient(containerName);
            // .getContainerClient(this.picturesContainer);
  }
  // // +Uploading Training Videos End

  // Azure Blob Inbuilt method Start
  private uploadBlob(content: Blob, name: string, client: ContainerClient, handler: () => void) {
    let blockBlobClient = client.getBlockBlobClient(name);
    return blockBlobClient.uploadData(content, { blobHTTPHeaders: { blobContentType: content.type } })
      .then(() => handler())
  }

  private async listBlobs(client: ContainerClient,containerName:any): Promise<string[]> {
    let result: string[] = []
 
    if(containerName == 'helpdocuments'){
        let blobs = client.listBlobsFlat();
        for await (const blob of blobs) {
        result.push(blob.name);
      }
      } else {
        let blobs = client.listBlobsFlat() && client.listBlobsFlat();
        for await (const blob of blobs) {
          //result.push(blob.name);
          result.push(JSON.stringify(blob));
      }
    }
    return result;
  }

  private downloadBlob(name: string, client: ContainerClient, handler: (blob: Blob) => void) {
    const blobClient = client.getBlobClient(name);
    blobClient.download().then((resp:any) => {
     resp.blobBody.then((blob:any) => {
        handler(blob)
      });
    })
  }

  private deleteBlob(name: string, client: ContainerClient, handler: () => void) {
    client.deleteBlob(name).then(() => {
      handler()
    })
  }

    // Azure Blob Inbuilt method Start End
 

    //#region For Imported Layer Start
    // qaEnv:string, 
    public uploadImportedLayer(sas: string, containerName: string,content: Blob, name: string, handler: () => void) {
      this.uploadBlob(content, name, this.containerImportedLayer(sas, containerName), handler);
    }

    // qaEnv:string
  private containerImportedLayer(sas: string, containerName: string): ContainerClient {

            return new BlobServiceClient(`${this.azureEnvAccount}?${this.importedContainerSAS}`)
            .getContainerClient(this.importedContainer);
  }
  //#region For Imported Layer End



  // DPR Upload Starts
  // public uploadDPRFiles(sas: string, content: Blob, name: string, handler: () => void, picturesContainer:string="") {
  //   this.uploadBlob(content, name, this.containerDPRFiles(sas,containerName), handler);
  // }

  public uploadDPRFiles(sas: string, containerName: string, content: Blob, name: string, handler: () => void) {
    this.uploadBlob(content, name, this.containerDPRFiles(sas,containerName), handler);
  }

  public listDPRFiles(sas: string, containerName: string): Promise<string[]> {
    return this.listBlobs(this.containerDPRFiles(sas,containerName),containerName)
  }

  public downloadDPRFiles(sas: string, containerName: string, name: string, handler: (blob: Blob) => void) {
    this.downloadBlob(name, this.containerDPRFiles(sas,containerName), handler)
  }

  public deleteDPRFiles(sas: string, containerName: string, name: string, handler: () => void) {
    this.deleteBlob(name, this.containerDPRFiles(sas,containerName), handler)
  }

  private containerDPRFiles(sas: string, containerName: string): ContainerClient {
    return new BlobServiceClient(`${this.azureEnvAccount}?${sas}`)
            .getContainerClient(this.dprFilesContainer);
  }

  // private containerDPRFiles(sas: string, containerClientData:string=""): ContainerClient {
  //   return new BlobServiceClient(`${this.azureEnvAccount}?${sas}`)
  //           .getContainerClient((containerClientData!="" ? containerClientData : this.picturesContainer) as string);
  // }

  // DPR Upload End

  ngOnDestroy() {
    //this.qaEnvValSubscription.unsubscribe();
  }
 }
