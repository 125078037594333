import { ICommonTableHeader } from "./../modules/sitemapadmin/constants/common-table-data";

export class virtualTourListTableDataDC {
    tblHeader: ICommonTableHeader[] = [

        {
            columnName: 'url',
            displayName: 'URL',
            class: '',
            icon: true,
            visible: true,
            textWithDefault: true
        },
        {
            columnName: 'displayName',
            displayName: 'Name',
            class: '',
            icon: true,
            visible: true,
            textWithDefault: true
        },
        {
            columnName: 'action',
            displayName: 'Action',
            class: '',
            icon: true,
            visible: true,
            button: true,
        }
    ]

    actionButton = 
        [{
            icon: 'fa-trash',
            tooltip: 'Delete',
            svgIconSrc: '../../../../assets/images/ico_delete.svg',
            dataBsToggle: '',
            dataBsTarget: '',
        }
    ];

    actionOpenButton = 
        [{
            icon: 'fa-circle-play',
            tooltip: 'Open',
            dataBsToggle: 'modal',
            dataBsTarget: '#featureExternalViewer',
        }
    ];
}