import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import mouseEventPoint from '@mapbox/mapbox-gl-draw/src/lib/mouse_event_point';
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
import StringSet from '@mapbox/mapbox-gl-draw/src/lib/string_set';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import SimpleSelect from '@mapbox/mapbox-gl-draw/src/modes/simple_select';
import * as turf from '@turf/turf';
import showSnappingFeature, { snapFeatureLayerId, getSiteMapMVTLayerIdsById } from './mode_utils';



function mergeLineMode() {
  const MergeLineMode = { ...SimpleSelect };

  let firstMergeLineId = undefined;
  let secondMergeLineId = undefined;

  const mergeTwoLines = function(firstMergeLineId, secondMergeLineId) {
    let firstLine = this.getFeature(firstMergeLineId);
    let secondLine = this.getFeature(secondMergeLineId);
    // Get values for group and type
    let firstLineGroupId = firstLine.properties.featureGroupId;
    let firstLineTypeId = firstLine.properties.featureTypeId;
    let secondLineGroupId = secondLine.properties.featureGroupId;
    let secondLineTypeId = secondLine.properties.featureTypeId;
    // If the two lines does not have exact match for group and type names, disallow the Merge
    if (firstLineGroupId !== secondLineGroupId || firstLineTypeId !== secondLineTypeId) {
      this.map.fire(Constants.events.UPDATE, {
        action: 'merge', //Constants.updateActions.CHANGE_COORDINATES,
        features: this.getSelected().map(f => f.toGeoJSON()),
        status: false,
        message: 'Lines must have same groups/types in order to be merged.'
      });
      return;
    }

    // Get the start/end points for the two Lines; index 0 is start point, and length-1 is endpoint
    let firstStartPoint = { type: 'first', index: 0, point: turf.point(firstLine.coordinates[0]) };
    let firstEndPoint = { type: 'first', index: firstLine.coordinates.length - 1, point: turf.point(firstLine.coordinates.slice(-1)[0]) };
    let secondStartPoint = { type: 'second', index: 0, point: turf.point(secondLine.coordinates[0]) };
    let secondEndPoint = { type: 'second', index: secondLine.coordinates.length - 1, point: turf.point(secondLine.coordinates.slice(-1)[0]) };

    // We'll find the two closest points from first Line to second Line.
    // E.g. Start-Start, Start-End, End-Start, End-End
    let shortestDistance;
    [firstStartPoint, firstEndPoint].forEach(firstPoint => {
      [secondStartPoint, secondEndPoint].forEach(secondPoint => {
        let options = { units: 'miles' };

        let distance = turf.distance(firstPoint.point, secondPoint.point, options);
        console.log(distance);
        if (shortestDistance === undefined || distance < shortestDistance.distance) {
          // firstIndex - either the start or end point of the first Line
          // secondIndex - either the start or end point of the second Line
          shortestDistance = { firstIndex: firstPoint.index, secondIndex: secondPoint.index, distance: distance };
        }
      });
    });
    if (shortestDistance !== undefined && shortestDistance.distance < .00001) {
      console.log(`Found the shortest distance from ${shortestDistance.firstIndex} to ${shortestDistance.secondIndex} at ${shortestDistance.distance} miles.`);
      // We'll use the complete coords for each Line to build a single LineString.
      let firstCoords = firstLine.coordinates;
      let secondCoords = secondLine.coordinates;
      let concatCoords;
      if (shortestDistance.firstIndex === 0) {
        if (shortestDistance.secondIndex === 0) {
          // Build LineString from start-start point
          // Remove the start coord of the second Line - this is the snapping coord
          secondCoords.shift();
          if (secondCoords.length > 1) {
            secondCoords = secondCoords.reverse();
          }
          concatCoords = secondCoords.concat(firstCoords);
        } else {
          // Build LineString from start-end point
          // Remove the end coord of the second Line - this is the snapping coord
          secondCoords.pop();
          concatCoords = secondCoords.concat(firstCoords);
        }
      } else if (shortestDistance.firstIndex !== 0) {
        if (shortestDistance.secondIndex === 0) {
          // Build LineString from end-start point
          // Remove the start coord of the second Line - this is the snapping coord
          secondCoords.shift();
          concatCoords = firstCoords.concat(secondCoords);
        } else {
          // Build LineString from end-end point
          // Remove the end coord of the second Line - this is the snapping coord
          secondCoords.pop();
          if (secondCoords.length > 1) {
            secondCoords.reverse();
          }
          concatCoords = firstCoords.concat(secondCoords);
        }
      }
      // The the first Line to create a new Line from the Merge.
      // Set the coordinates of first Line to include the coords of the second Line.
      firstLine.coordinates = concatCoords;
      // Clone first Line
      let mergedLine = firstLine;
      // Change the Id
      mergedLine.id += 1;
      // Update the "featureName" property
      let mergedLineFeatureName = mergedLine.properties.featureName;
      mergedLine.properties.featureName = 'merged_' + mergedLineFeatureName;
      // Add the merged Line to the map
      this.addFeature(mergedLine);
      // this.toastr.success('Selected Lines Merged');
      /* For Rishabh ... You can access the features added/deleted in two ways ...
      By Id:
        Two features deleted:
          this.editingState.firstMergeLineId
          this.editingState.secondMergeLineId
        One new feature:
          mergedLine.id
      By Feature (with all properties):
        Two features deleted:
          firstLine
          secondLine
        One new feature:
          mergedLine
      */
      // Remove the two lines used in the Merge
      this.deleteFeature(firstMergeLineId);
      this.deleteFeature(secondMergeLineId);
      // Set firstMergeLineId to the new, merged Line Id
      firstMergeLineId = mergedLine.id;
      // And reset secondMergeLineId to undefined - otherwise actions will be triggered
      secondMergeLineId = undefined;
      //console.log("firstLine",firstLine);
      //console.log("secondLine",secondLine);
      // Select the merged Line
      this.changeMode('merge_line', { featureIds: [mergedLine.id] });
      // Fire an Update so we can keep track of undo/redo history
      this.map.fire(Constants.events.UPDATE, {
        action: 'merge', //Constants.updateActions.CHANGE_COORDINATES,
        features: this.getSelected().map(f => f.toGeoJSON()),
        status: true,
        message: 'The selected lines have been merged.',
        mergedlines:[firstLine,secondLine]
      });
    } else {
      // No two vertexes on the merge Lines were "close" enough ??
      this.map.fire(Constants.events.UPDATE, {
        action: 'merge', //Constants.updateActions.CHANGE_COORDINATES,
        features: [],
        status: false,
        message: 'Cannot merge two lines that do not share a vertex. Please use Snap Vertex option before merge of two lines.'
      });
      // Reset secondMergeLineId to undefined - otherwise actions will be triggered
      secondMergeLineId = undefined;
      // Select only the first Line
      this.changeMode('merge_line', { featureIds: [firstMergeLineId] });
    }
  };


  MergeLineMode.onSetup = function(opts) {
    // turn the opts into state.
    const state = {
      dragMoveLocation: null,
      boxSelectStartLocation: null,
      boxSelectElement: undefined,
      boxSelecting: false,
      canBoxSelect: false,
      dragMoving: false,
      canDragMove: false,
      initiallySelectedFeatureIds: opts.featureIds || []
    };

    this.setSelected(state.initiallySelectedFeatureIds.filter(id => this.getFeature(id) !== undefined));
    this.fireActionable();

    this.setActionableState({
      combineFeatures: true,
      uncombineFeatures: true,
      trash: true
    });

    if (state.initiallySelectedFeatureIds.length === 1) {
      firstMergeLineId = state.initiallySelectedFeatureIds[0];
    } else if (state.initiallySelectedFeatureIds.length === 2) {
      mergeTwoLines.call(this, state.initiallySelectedFeatureIds[0], state.initiallySelectedFeatureIds[1]);
    }

    return state;
  };

  MergeLineMode.onStop = function() {
    doubleClickZoom.enable(this);
  };

  MergeLineMode.onTap = MergeLineMode.onClick = function(state, e) {
    // Click (with or without shift) on no feature
    if (CommonSelectors.noTarget(e)) return this.clickAnywhere(state, e); // also tap
    if (CommonSelectors.isOfMetaType(Constants.meta.VERTEX)(e)) return this.clickOnVertex(state, e); //tap
    if (CommonSelectors.isFeature(e)) return this.clickOnFeature(state, e);
  };

  MergeLineMode.clickAnywhere = function (state) {
    // Clear the re-render selection
    const wasSelected = this.getSelectedIds();
    if (wasSelected.length) {
      this.clearSelectedFeatures();
      wasSelected.forEach(id => this.doRender(id));
    }
    doubleClickZoom.enable(this);
    this.stopExtendedInteractions(state);
  };

  MergeLineMode.clickOnVertex = function(state, e) {
    // Enter direct select mode
    this.changeMode(Constants.modes.DIRECT_SELECT, {
      featureId: e.featureTarget.properties.parent,
      coordPath: e.featureTarget.properties.coord_path,
      startPos: e.lngLat
    });
    this.updateUIClasses({ mouse: Constants.cursors.MOVE });
  };

  MergeLineMode.clickOnFeature = function(state, e) {
    // Stop everything
    doubleClickZoom.disable(this);
    this.stopExtendedInteractions(state);

    const isShiftClick = CommonSelectors.isShiftDown(e);
    const selectedFeatureIds = this.getSelectedIds();
    const featureId = e.featureTarget.properties.id;
    const isFeatureSelected = this.isSelected(featureId);

    // Click (without shift) on any selected feature but a point
    if (!isShiftClick && isFeatureSelected && this.getFeature(featureId).type !== Constants.geojsonTypes.POINT) {
      // Enter direct select mode
      return this.changeMode(Constants.modes.DIRECT_SELECT, {
        featureId
      });
    }

    // Shift-click on a selected feature
    if (isFeatureSelected && isShiftClick) {
      // Deselect it
      this.deselect(featureId);
      this.updateUIClasses({ mouse: Constants.cursors.POINTER });
      if (selectedFeatureIds.length === 1) {
        doubleClickZoom.enable(this);
      }
    // Shift-click on an unselected feature
    } else if (!isFeatureSelected && isShiftClick) {
      // Add it to the selection
      this.select(featureId);
      this.updateUIClasses({ mouse: Constants.cursors.MOVE });
    // Click (without shift) on an unselected feature
    } else if (!isFeatureSelected && !isShiftClick) {
      // Make it the only selected feature
      selectedFeatureIds.forEach(id => this.doRender(id));
      this.setSelected(featureId);
      this.updateUIClasses({ mouse: Constants.cursors.MOVE });
    }

    let clickedFeature = e.featureTarget;
    if (clickedFeature !== undefined) {
      if (clickedFeature.geometry.type === 'LineString') {
        if (firstMergeLineId === undefined) {
          firstMergeLineId = clickedFeature.properties.id;
        }
        else {
          secondMergeLineId = clickedFeature.properties.id;
          console.log(`Merging ${firstMergeLineId} and ${secondMergeLineId}.`);
          // Since we have two lines selected, merge them
          mergeTwoLines.call(this, firstMergeLineId, secondMergeLineId);
        }
      }
    }

    // No matter what, re-render the clicked feature
    this.doRender(featureId);
  };

  MergeLineMode.onMouseDown = function(state, e) {
    if (CommonSelectors.isActiveFeature(e)) return this.startOnActiveFeature(state, e);
    if (this.drawConfig.boxSelect && CommonSelectors.isShiftMousedown(e)) return this.startBoxSelect(state, e);
  };

  MergeLineMode.toDisplayFeatures = function(state, geojson, display) {
    geojson.properties.active = (this.isSelected(geojson.properties.id)) ?
      Constants.activeStates.ACTIVE : Constants.activeStates.INACTIVE;
    display(geojson);
    this.fireActionable();
    if (geojson.properties.active !== Constants.activeStates.ACTIVE ||
      geojson.geometry.type === Constants.geojsonTypes.POINT) return;
    createSupplementaryPoints(geojson).forEach(display);
  };

return MergeLineMode
}

export default mergeLineMode;
