<div class="row">
  <div class="col-lg-12">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-colored mb-3" *ngFor="let mainType of systemPermissionTypeData">
            <h5 class="card-header card-header-bold">
              {{mainType.name}}
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" [id]="'modal_'+mainType.name+'_cb'"
                  [(ngModel)]="mainType.checked" (change)="permissionTypeCheckedChange(mainType,mainType.checked)">
                <label class="form-check-label" [for]="'modal_'+mainType.name+'_cb'">
                  Select all
                </label>
              </div>
            </h5>
            <div class="card-body py-0">
              <div class="row">
                <div class="col-lg-12">
                  <ng-container *ngFor="let permission of systemAllPermissionData; let i = index;">
                    <ng-container *ngIf="mainType.permissionTypeId === permission.permissionTypeId">
                      <div class="form-check px-0 custom-form-check" *ngIf="isSubTypeVisible(permission,mainType)">
                        <i class="float-start" style="min-width: 152px;">{{permission.permissionSubTypeName}}</i>
                        <input class="form-check-input" type="checkbox"
                          [id]="permission.permissionSubTypeId + 'modal_subtype'"
                          [(ngModel)]="permission.permissionSubTypeChecked"
                          (change)="permissionSubTypeCheckedChange(permission,permission.permissionSubTypeChecked,mainType)">
                        <label [for]="permission.permissionSubTypeId + 'modal_subtype'">Select All</label>
                      </div>
                      <div class="form-check form-check-inline custom-form-check-inline-list">
                        <input class="form-check-input" type="checkbox"
                          [id]="'modal_'+mainType.name+'_cb'+permission.permissionId"
                          [(ngModel)]="permission.permissionChecked"
                          (change)="permissionCheckedChange(permission,mainType)">
                        <label class="form-check-label" data-toggle="tooltip" [title]="permission.permissionName"
                          [for]="'modal_'+mainType.name+'_cb'+permission.permissionId">{{permission.permissionName}}</label>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
