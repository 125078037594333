import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PortalAPI } from 'src/app/constants/api.constant';
import { ConnectionService } from 'src/app/modules/sitemapadmin/services/connection-service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  tenantId: any = 1;

  constructor(
    private restService: ConnectionService,
    private toastr: ToastrService,
  ) { 

  }
   //#region getUserPermission method is use to get all the permission list for login user using GetPermissionByUserId api
   getPermissionsByUserID(userId: number): Observable<any> {
    this.restService.tenantId = '1';   
    return this.restService.get(PortalAPI.GET_USERID_PERMISSION, userId);     
  }

  showErrormessageForPersonal(message?:any){
    if(!message) {
      message = "Functionality is reserved for Premium Subscribers. Please upgrade to access this feature.";
    }
    this.toastr.error(message, "", {
      timeOut: 3000
    });
  }

  checkPermission(userId:any,permissionId:any,errorMessage:any) {
    if(permissionId) {
      let allUserPermission:any = sessionStorage.getItem('PermissionObj');
      if(allUserPermission) {
        const featurePermission = JSON.parse(allUserPermission).find((it:any)=> it.permissionId == permissionId);
        if(featurePermission) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }
}
