import { environment } from "src/environments/environment";

const { DPR_API, IMPORT_EXPORT_API, JOBS_API, MAP_SCREEN_API, NOTIFICATION_API, PROJECTS_API, SECURITY_API, SETTINGS_API, SITES_API, USER_MANAGEMENT_API, HELP_API,  TILES_MVT_API } = environment;

export class PortalAPI {
    public static AZURE_URL_CONTAINER = environment.AZURE_URL_CONTAINER;
    public static AZURE_PICTURE_CONTAINER_SAS = environment.picturesContainerSAS;
    public static AZURE_DPR_CONTAINER_SAS = environment.dprFilesContainerSAS;
    public static AZURE_DPR_DOWNLOAD_CONTAINER_SAS = environment.dprFileSWAPSDownloadSAS;
    public static AZURE_HELP_CONTAINER_SAS = environment.helpDocumentContainerSAS;
    public static AZURE_IMPORTED_CONTAINER_SAS = environment.importedContainerSAS;
    public static DATA_SOURCE = environment.AZURE_URL_CONTAINER + 'alljobcentroids/allcentroids.geojson';

    public static IP_STACK = environment.IP_STACK;
    public static TILES_MVT_BASE_URL = environment.TILES_MVT_BASE_URL;
    public static MVT_GET_ACTIVE_JOB_FEATURES_BY_LAYER_ID =  TILES_MVT_API +  '/MVTGetActiveJobFeaturesByLayerId';
    public static TILES_MVT_IMAGE_SERVER_URL = environment.TILES_IMAGE_SERVER;
    public static RASTER_TILES_SERVER = environment.RASTER_TILES_SERVER;


    public static SHARE_JOB_AT_LOGIN = SECURITY_API + '/ShareJobAtLogin';
    public static FETCH_USER_DETAILS = USER_MANAGEMENT_API + '/GetUserDetails';
    public static UPDATE_USER_DETAILS = USER_MANAGEMENT_API + '/SaveUserDetails';
    public static UPDATE_USER_RENDER_SCALE = USER_MANAGEMENT_API + '/SaveRenderingScale';
    public static PROFILE_DRODOWN_DATA = USER_MANAGEMENT_API + '/GetMyProfileDropDownData';
    //public static AcceptTNC = USER_MANAGEMENT_API + '/AcceptTNC';
    public static FETCH_LATEST_TNC = USER_MANAGEMENT_API + '/GetLatestTnc';
    public static ACCEPT_TNC = USER_MANAGEMENT_API + '/AcceptTNC';
    public static JOBS_SHARED_COLLABORATION = SECURITY_API + '/GetCollaborationJobObject';
    public static DOCUMENTS_SHARED_COLLABORATION = SECURITY_API + '/GetCollaborationDocumentObject';
    public static SITE_SHARED_COLLABORATION = SECURITY_API + '/GetCollaborationSiteObject';
    public static SUBSITE_SHARED_COLLABORATION = SECURITY_API + '/GetCollaborationSubsiteObject';
    public static ACTIVITY_LOGS_COLLABORATION = SECURITY_API + '/GetCollaborationActivityLogs';
    public static GET_ACCESS_PERMISSION_FOR_JOB = SECURITY_API + '/GetObjectPermission';

    /***** User Sync Endpoint *****/
    public static SYNC_USER = USER_MANAGEMENT_API + '/SyncUserDetails';
    /***** Forgot LoginId Endpoint *****/
    public static FORGOT_LOGIN_ID = USER_MANAGEMENT_API + '/ForgotLoginIDByPhoneNumber';
    public static SET_LOGGEDIN_HISTORY_ASYNC = USER_MANAGEMENT_API + '/SetLoginHistoryAsync';
    /*****  Dashboard Endpoint *****/
    public static RECENT_JOBS = JOBS_API + '/GetRecentJobsByUserId';
    public static RECENT_PROJECTS = PROJECTS_API + '/GetRecentProjects';
    public static RECENT_DOCUMENTS = DPR_API + '/GetRecentDocumentsByUserId';
    public static SEARCH_EMAIL = USER_MANAGEMENT_API + '/SearchEmail';
    public static SEARCH_EMAIL_EDIT = USER_MANAGEMENT_API + '/SearchEmailWithoutPreferenceSettings';
    public static FETCH_SHARED_WITH_USER_LIST = SECURITY_API + '/GetSharedWithUserList';
    public static SHARE_OBJECT = SECURITY_API + '/ObjectShare';
    public static GET_JOBS_LOGS_JOB_ID = JOBS_API + '/GetJobLogs';

    /*****  List of country state city Endpoint *****/  /*****  List of Jobs Endpoint *****/
    public static COUNTRY_LIST = USER_MANAGEMENT_API + '/GetCountryList';
    public static STATE_LIST = USER_MANAGEMENT_API + '/GetStatesByCountryId';
    public static CITY_LIST = USER_MANAGEMENT_API + '/GetCitiesByStateId';
    public static ADD_SITE = SITES_API + '/AddSite';
    public static UPDATE_SITE = SITES_API + '/UpdateSite';
    public static GET_SITE = SITES_API + '/GetSitesByUserId';
    public static GET_SUBSITE = SITES_API + '/GetSubsitesByUserId';
    public static ASSOCIATE_SUBSITE = SITES_API + '/AssociateObjectsToSites';
    public static DEASSOCIATE_SUBSITE = SITES_API + '/DeAssociateObjectsToSites';


    /***** country state city Endpoint *****/

    /*****  List of Jobs Endpoint *****/  /*****  List of Jobs Endpoint *****/
    public static JOBS_LIST = JOBS_API + '/GetAllJobsByUserId';
    public static SEARCH_JOBS = JOBS_API + '/FindJobs';
    public static GET_JOBS_BY_SUBSITEID = JOBS_API + '/GetJobsByObjectId';
    public static GET_JOBS_BY_SITEIDS = JOBS_API + '/GetJobsBySiteIds';
    public static ASSOCIATE_SITE = SITES_API + '/AssociateObjectsToSites';
    public static DEASSOCIATE_SITE = SITES_API + '/DeAssociateObjectsToSites';
    public static ASSIGNED_SHARED_JOBS =  JOBS_API + '/GetSharedAndAssignedJobs';
    /***** MyPreferences Endpoint *****/
    public static PREFERENCES_LIST = SETTINGS_API + '/GetSettings';
    public static SAVE_UPDATE_PREFERENCES = SETTINGS_API + '/Upsert';
    /***** SiteAdmin Endpoint *****/
    public static GET_ALL_JOB_LIST = JOBS_API + '/GetAllJobs';
    public static GET_JOB_INFORMATION = JOBS_API + '/GetJob';
    public static GET_ALL_USERS = USER_MANAGEMENT_API + '/GetAllUsers';
    public static GET_LOGGEDIN_HISTORY_ASYNC = USER_MANAGEMENT_API + '/GetLoggedinUserHistoryAsync';
    public static REMOVE_USERPERMISSION_FOR_JOB = SECURITY_API + '/RevokeUserSecurableObjectPermission';
    public static RESET_USERPSSWRD_BYADMIN = USER_MANAGEMENT_API + '/ResetUsersPasswordAsync';
    public static USER_ACTIVE_INACTIVE_BYADMIN = USER_MANAGEMENT_API + '/SetUserStatus';
    public static Get_Assigned_Users = JOBS_API + '/GetAssignedUsers';
    public static SAVE_JOB = JOBS_API + '/SaveJob';
    public static UPDATE_JOB = JOBS_API + '/UpdateJob';
    public static ASSIGN_JOB_TO_USER = SECURITY_API + '/ObjectAssign';
    public static GET_JOBS_ASSIGNED_TO_USER = JOBS_API + '/GetJobsAssignedToUser';
    public static GET_ALL_ROLES = SECURITY_API + '/GetAllRoles';
    public static GET_ALL_SYSTEM_PERMISSIONS = SECURITY_API + '/GetAllSystemPermissions';
    public static GET_ALL_SYSTEM_PERMISSIONS_TYPES = SECURITY_API + '/GetAllSystemPermissionsTypes';
    public static CREATE_NEW_ROLE = SECURITY_API + "/CreateRole";
    public static GRANT_ROLE_PERMISSION = SECURITY_API + "/GrantRolePermission";
    public static GET_ROLE_PERMISSIONS = SECURITY_API + "/GetRolePermissions";
    public static REVOKE_ROLE_PERMISSION = SECURITY_API + "/RevokeRolePermission";
    public static GRANT_USER_ROLE_ASSIGNMENT = SECURITY_API + "/GrantUserRoleAssignment";
    public static REVOKE_USER_ROLE_ASSIGNMENT = SECURITY_API + "/RevokeUserRoleAssignment";
    public static GET_ALL_JOBS_BY_USERID = JOBS_API + '/GetAllJobsByUserId';
    public static GET_USERID_PERMISSION = SECURITY_API + "/GetUserPermissions";

    /**** list of manage group endpoint ****/
    public static SAVE_GROUP = NOTIFICATION_API + "/SaveUserGroup";
    public static GET_ALL_GROUP = NOTIFICATION_API + "/GetAllUserGroup";
    public static DELETE_GROUP = NOTIFICATION_API + "/GetAllUserGroup";
    public static ADD_USER_TO_GROUP = NOTIFICATION_API + "/AddUserToGroup";
    public static REMOVE_USER_TO_GROUP = NOTIFICATION_API + "/RemoveUserFromGroup";
    public static GET_USER_FROM_GROUPID = NOTIFICATION_API + "/GetUsersByGroupId";
    /*****  List of Message/Notification Endpoint *****/
    public static GET_ALL_Messages = NOTIFICATION_API + "/GetAllMessages";
    public static CREATE_JOB = JOBS_API + "/AddJob";
    public static SAVE_MESSAGE_ASYNC = NOTIFICATION_API + "/SaveMessageAsync";
    public static GET_ALL_NOTIFICATION_LIST = NOTIFICATION_API + "/GetAllIntertnalMessageNotifications";
    public static MARK_NOTIFICATION_MESSAGE_READ = NOTIFICATION_API + "/MarkInternalMessageRead";
    public static MESSAGE_DELETE_ASYNC = NOTIFICATION_API + "/MessageDeleteAsync";
    public static GET_MESSAGES_BY_USERID = NOTIFICATION_API + "/GetMessagesByUserId";

    /*****  List of MapViewer Endpoint *****/
    public static GET_ALL_JOBS_LAT_LONG = MAP_SCREEN_API + "/RetrieveCentroidsForAllJobs";
    public static GET_ALL_JOBS_LAT_LONG_GUID = MAP_SCREEN_API + "/RetrieveCentroidsForGuid";
    public static FETCH_JOB_DETAILS = MAP_SCREEN_API + "/RetrieveJobFullDetailsByJobId";
    public static FETCH_FEATURES_JOB = MAP_SCREEN_API + "/RetrieveFeaturesByJobId";
    public static FETCH_FEATURES_JOB_GUID = MAP_SCREEN_API + "/RetrieveFeaturesByJobIdAndGuid";
    public static FETCH_LINE_FEATURE_DETAIL = MAP_SCREEN_API + "";
    public static FETCH_POINT_FEATURE_DETAIL = MAP_SCREEN_API + "";
    public static MATTERPORT_LINK_ADD = MAP_SCREEN_API + "/SaveAddedLink";
    public static VECTOR_TILES_BY_USER = MAP_SCREEN_API + "/GetAllVectorTileByUserId";
    public static RASTER_TILES_BY_JOB_ID = MAP_SCREEN_API + "/GetAllRasterTile";
    public static RASTER_TILES_ALL_MULTIPLE_JOBS = MAP_SCREEN_API + "/GetAllRasterTileByJobIds";
    public static DELETE_RASTER_TILE_BY_TILE_ID = MAP_SCREEN_API + "/DeleteRasterTile";



    public static RASTER_IMAGES_ALL = MAP_SCREEN_API + "/GetAllRasterImage";
    public static RASTER_IMAGES_ALL_MULTIPLEJOBS = MAP_SCREEN_API + "/GetAllRasterImageByJobIds";
    public static MULTIPLE_JOB_SHARE = SECURITY_API + "/MultipleObjectShare";
    public static GENERATE_IMPORT_LOG = IMPORT_EXPORT_API + "/StartImportJob";
    public static COMPLETE_IMPORT_LOG = IMPORT_EXPORT_API + "/UpdateImportJob";
    public static GENERATE_EXPORT_LINK = IMPORT_EXPORT_API + "/StartExportJob";
    public static GET_EXPORT_LINK = IMPORT_EXPORT_API + "/GetExportedFileDetails";
    public static GET_ADMIN_ALLJOBS_BLOBFILE_URL = MAP_SCREEN_API + "/UploadJobCentroidsToAzureBlob";
    public static MARK_EXPORT_FILEDOWNLOAD = IMPORT_EXPORT_API + "/MarkFileDownloaded";
    public static DELETE_FEATURES = MAP_SCREEN_API + "/DeletePoint";
    public static MULTIPLE_DELETE_FEATURES = MAP_SCREEN_API + "";
    public static MULTIPLE_DELETE_FEATURES_BY_IDS = MAP_SCREEN_API + "";
    public static SEARCH_FEATURE_GROUP = MAP_SCREEN_API + "/SearchFeatureGroups";
    public static SEARCH_FEATURE_TYPE = MAP_SCREEN_API + "/GetFeatureTypesByGroupId";
    public static FEATURETYPE_DELETE_JOBID = MAP_SCREEN_API + "/DeleteFeatureByFeatureTypeId";
    public static FEATUREGROUP_DELETE_JOBID = MAP_SCREEN_API + "/DeleteFeatureByGroupId";
    public static FEATUREFILE_DELETE_JOBID = MAP_SCREEN_API + "/DeleteFeatureByFileId";
    public static SAVE_UPDATE_LINE_FEATURES = MAP_SCREEN_API + "/UpsertLine";
    public static SAVE_UPDATE_POINT_FEATURES = MAP_SCREEN_API + "/UpsertPoint";
    public static GET_EXTERNAL_CONENTS_BY_JOBID = MAP_SCREEN_API + "/RetrieveExternalComponentsByJobId";
    public static GET_EXTERNAL_CONENTS_BY_JOBID_GUID = MAP_SCREEN_API + "/RetrieveExternalComponentsByJobIdAndGuid";
    public static DELETE_PHOTO_BY_LAYER_ID = MAP_SCREEN_API + "/DeletePhotoByLayerId";
    public static DELETE_PHOTO = MAP_SCREEN_API + "/DeletePhoto";
    public static GET_EXTERNAL_CONTENT_TYPES = MAP_SCREEN_API + "/RetrieveExternalComponentTypes";
    public static UPSERT_EXTERNAL_CONTENT = MAP_SCREEN_API + "";
    public static RETRIEVE_EXTERNAL_COMPONENTS_BY_JOBID = MAP_SCREEN_API + "/RetrieveExternalComponentsByJobId";
    public static RETRIEVE_EXTERNAL_COMPONENTS_BY_JOBID_GUID = MAP_SCREEN_API + "/RetrieveExternalComponentsByJobIdAndGuid";
    public static GET_SITE_COMPONENT = MAP_SCREEN_API + '/RetrieveSiteComponentsForJobs';
    public static GET_SITE_COMPONENT_GUID = MAP_SCREEN_API + '/RetrieveSiteComponentsForJobsAndGuid';
    public static GET_SITE_COMPONENT_NEW_STRUCTURE = MAP_SCREEN_API + '/RetrieveSiteComponentsNew';
    public static GET_EXTERNAL_CONTENT_BY_SITEID = MAP_SCREEN_API + '/GetAllExternalContentsByObjectId';
    public static UPSERT_RASTER_IMAGE = MAP_SCREEN_API + '/UpsertRasterImage';
    public static RETRIEVE_FEATURES_N_LAYER = MAP_SCREEN_API + '/RetrieveFeaturesByJobNLayer';
    public static RETRIEVE_EXTERNALFEATURES_JOBIDS = MAP_SCREEN_API + '/RetrieveExternalComponentsByJobIds';
    public static RETRIEVE_LINE_DETAILS_BY_LINEID = MAP_SCREEN_API + '/RetrieveLineDetailsByLineId';
    public static RETRIEVE_LINE_DETAILS_BY_LINEID_GUID = MAP_SCREEN_API + '/RetrieveLineDetailsByLineIdByGuid';
    public static GET_JOB_NAMES = JOBS_API + '/GetJobNames';

    /*****  List of Digital Plan Endpoint *****/
    public static GET_ALL_DOCUMENTS_BY_USER = DPR_API + "/GetAllDocumentsByUserId";
    public static GET_FOLDER_CHILDREN = DPR_API + "/GetFolderChildren";
    public static GET_USER_CONTENT = DPR_API + "/GetUserContentsObjects";
    public static CREATE_DEFAULT_FOLDERS_FOR_USER = DPR_API + "/CreateDefaultFoldersforUser";
    public static GET_SHARED_WITH_USER_DOCUMENTS = DPR_API + "/GetSharedWithUserDocuments"
    public static SEARCH_DOCUMENTS_FOR_USER = DPR_API + "/FindAllDocumentsByUserId";
    public static ADD_FOLDER = DPR_API + "/AddFolder";
    public static ADD_FILE = DPR_API + "/AddFile";
    public static GET_DOCUMENTS_FOR_JOB = DPR_API + "/GetDocumentsByJobId";
    public static GET_DOCUMENTS_FOR_JOB_GUID = DPR_API + "/GetDocumentsByJobIdAndGuid";
    public static GET_MULTIPLE_DOCUMENTS_FOR_JOB = DPR_API + "/GetDocumentsByJobIds";
    public static RENAME_FILE_NAME = DPR_API + "/RenameObject";
    public static ADD_GEOLOCATE_FILE = DPR_API + "/GeoloacateDocument";
    public static DELETE_OBJECT = DPR_API + "/DeleteObject";
    public static COPY_OBJECT = DPR_API + "/CopyObject";
    public static MOVE_OBJECT = DPR_API + "/MoveObject";
    public static GET_DOCUMENT_LOGS_JOB = DPR_API + '/LogObjectInteraction';

    public static GET_USERS_FOR_ROLE = USER_MANAGEMENT_API + '/GetUsersforRole';

    public static PRINT_FEATURE = environment.TILES_PRINT_API + '/RenderWebMapImage';
    public static SUBMIT_GPRSDELIVERABLES = DPR_API + '/SubmitGPRSDeleverable';
    public static USER_STORAGE_INFORMATION = DPR_API + '/GetUserStorageInformation';
    public static DOCUMENTS_FOR_GPRSDELIVERABLES = DPR_API + '/GetGPRSDeliverables';
    public static GET_DOCUMENTS_BY_OBJECTID = DPR_API + '/GetDocumentsByObjectId';
    public static MARK_OBJECT_Favorite = DPR_API + '/MarkObjectFavorite';
    public static GET_Favorite_OBJECTS = DPR_API + '/GetFavoriteDocuments';
    public static GET_OBJECT_FULL_PATH = DPR_API + '/GetObjectFullPath';
    public static DOWNLOAD_FOLDER = DPR_API + '/DownloadFolder';

    /*****  List of sites Endpoint *****/
    public static ADD_SUB_SITE = SITES_API + '/AddSubSite';
    public static UPDATE_SUB_SITE = SITES_API + '/UpdateSubSite';
    public static GET_SUB_SITE = SITES_API + '/GetSubSitesBySiteId';
    public static ASSOCIATE_OBJECTS_TO_SUBSITES = SITES_API + '/AssociateObjectsToSubsites';
    public static DEASSOCIATE_OBJECTS_TO_SUBSITES = SITES_API + '/DeAssociateObjectsToSubsites';
    public static DELETE_SUB_SITE = SITES_API + '/DeleteSubSite';
    public static DELETE_SITE = SITES_API + '/DeleteSite';

    //chargebee api
    public static CANCEL_CHARGEBEE_SUBSCRIPTION = USER_MANAGEMENT_API + '/CancelChargebeeSubscription';
    public static SET_GRACE_PERIOD_API = USER_MANAGEMENT_API + '/SetGracePeriod';
    public static GET_GRACE_PERIOD_API = USER_MANAGEMENT_API + '/GetGracePeriodInformation';
    public static SET_CHARGEBE_DETAILS_TO_DB = USER_MANAGEMENT_API + '/UpsertCBSubscriptionToDB';
    public static UPSERT_CHECKOUTFOR_CBSUBSCRIPTION = USER_MANAGEMENT_API + '/UpsertCheckoutForCBSubscription';
    public static ACKNOWLEDGEMENT_HOSTEDPAGE_CBAPI = USER_MANAGEMENT_API + '/AcknowledgeHostedPageCB';
    public static UPDATE_PAYMENT_METHOD = USER_MANAGEMENT_API + '/ManagePaymentSourcesCB'
    public static CB_Plan_List =USER_MANAGEMENT_API + '/GetCBListOfFrequency?plan='
    public static DECREASE_STORAGE_SPACE =DPR_API + '/SetUserStorage'
    public static SUBSCRIPTION_LIST =USER_MANAGEMENT_API + '/GetUserSubscriptionList'
    //Faq API
    public static GET_FAQ_API = HELP_API + '/GetAllFaq';
    public static ADD_FAQ_API = HELP_API + '/SaveFaq';
    public static DELETE_FAQ_API = HELP_API + '/RemoveFaq';
    public static DELETEQA_FAQ_API = HELP_API + '/RemoveFaqQA';
    public static GET_USER_SUBSCRIPTION_INFO = USER_MANAGEMENT_API + '/GetUserSubscriptionInfo';

    //History API
    public static HISTORY = MAP_SCREEN_API + '';

    // Forcefully Logout, Polling user status
    public static LOGOUT_FORCEFULLY = USER_MANAGEMENT_API + '/ToLogoutUserForcefully';
    public static POLL_USER_STATUS = USER_MANAGEMENT_API + '/IsUserLoggedOut';
    public static CLOSE_ALL_ACTIVE_SESSIONS = USER_MANAGEMENT_API + '/CloseAllActiveSessions';

    // points line mvt
    public static CUSTOME_POINTS = environment.CUSTOME_POINTS;
    public static CUSTOME_LINES = environment.CUSTOME_LINES;
    public static CUSTOME_ANNOTATION_POINTS = environment.CUSTOME_ANNOTATION_POINTS;
    public static CUSTOME_ANNOTATION_LINES = environment.CUSTOME_ANNOTATION_LINES;
    public static LINE_TILES = environment.LINE_TILES;
    public static POINT_TILES = environment.POINT_TILES;
    public static TILES = '/{z}/{x}/{y}.mvt';

    // For all feature Type and Feature Groups
    public static ALL_FEATURE_GROUPS = MAP_SCREEN_API + '/GetAllFeatureGroup';
    public static ALL_FEATURE_TYPES = MAP_SCREEN_API + '/GetAllFeatureType';
}
