import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { permissionEnum } from 'src/app/constants/userPermissionAccess';
import { AzureBlobStorageServiceVideo } from 'src/app/services/azure-blob-storage.service';
import { CommonFunctionService } from 'src/app/services/common-function-service';
import { MapViewerService } from '../../services/mapviewer.service';

@Component({
  selector: 'app-dpr-menus',
  templateUrl: './dpr-menus.component.html',
  styleUrls: ['../mapalljoblist/mapalljoblist.component.css'],
})
export class DprMenusComponent implements OnInit, OnChanges {
  @Input() slideMenuOpenTools: boolean = false;
  @Input() slideMenuOpenLayerDetails: boolean = false;
  @Input() slideMenuOpenJobDescription: boolean = false;
  @Input() slideMenuOpenSiteDetails: boolean = false;
  @Input() slideMenuOpenFolder: boolean = false;
  @Input() slideMenuOpenSiteAdminDetails: boolean = false;
  @Input() showToggleSideBar: boolean = false;
  @Input() zoomedJobIds: any = [];
  @Input() clickedDocFeatures:any = [];
  @Input() loginUserId: any;
  @Input() dragMap: boolean = false;
  @Input() map:any;
  @Input() zoomMap: boolean = false;
  @Output() ontoggleClicked = new EventEmitter<any>();

  jobDocumentsList: any = [];
  mapViewerDocumentsSubscribe: Subscription | undefined;
  dprFileSWAPSDownloadSAS: string = '';
  dprFileDownloadSAS: string = '';
  permissionEnumList:any = permissionEnum;
  jobIdToDocuments:any = {};

  isAllJobContainsFiles : boolean = true;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private mapViewerService: MapViewerService,
    private blobService: AzureBlobStorageServiceVideo,
    private commonService: CommonFunctionService
  ) { 
    this.dprFileDownloadSAS = this.blobService.dprFilesContainerSAS;
    this.dprFileSWAPSDownloadSAS = this.blobService.dprFileSWAPSDownloadSAS;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.slideMenuOpenFolder?.currentValue == true){
      this.loadDocuments();
    }
    if( (changes?.zoomedJobIds?.currentValue !== changes?.zoomedJobIds?.previousValue) && !changes?.zoomedJobIds?.isFirstChange()) {
      if(this.zoomMap) {
        if(!this.compareArrays(changes?.zoomedJobIds?.currentValue, changes?.zoomedJobIds?.previousValue) && this.slideMenuOpenFolder){
          console.log("Change in ZoomedJobIds in ZoomIn-Out...!");
          this.onZoomJobIdChanged();
        }
      }
      else if(this.dragMap) {
        if(!this.compareArrays(changes?.zoomedJobIds?.currentValue, changes?.zoomedJobIds?.previousValue) && this.slideMenuOpenFolder){
          console.log("Change in ZoomedJobIds in Panning...!");
          this.onZoomJobIdChanged();
        }
      }
      
      if(!this.compareArrays(changes?.zoomedJobIds?.currentValue, changes?.zoomedJobIds?.previousValue) && this.slideMenuOpenFolder){
        console.log("Change in ZoomedJobIds...!");
        this.onZoomJobIdChanged();
      }
      if(changes?.clickedDocFeatures?.currentValue.length) {
        
      }
    }
  }

  ngOnInit(): void { }

  private onZoomJobIdChanged() {
    // this.jobDocumentsList = [];
    console.log("onZoomJobIdChanged zoomedJobIds: ", this.zoomedJobIds);
    const allData: any[] = [];
    const newJobIds = []
      for (let index = 0; index < this.zoomedJobIds.length; index++) {
        const jobId = this.zoomedJobIds?.[index];
        if(this.jobIdToDocuments.hasOwnProperty(jobId)) {
          const docs = this.jobIdToDocuments?.[jobId];
          if(docs){
            allData.push(...docs);
          }
        } else {
          newJobIds.push(jobId);
        }
      }

      // console.log("this.jobDocumentsList: ", this.jobDocumentsList);
      console.log("filtered Doc count: ", allData);
      this.loadDocumentsFromExisting(allData);
      if(newJobIds?.length) {
        console.log("newJobIds: ", newJobIds);
        this._loadDocumentsData(newJobIds, true);
      }
    }
    
    loadDocumentsFromExisting(allData:any[]) {
      this.jobDocumentsList = JSON.parse(JSON.stringify(allData));
      const count = this.jobDocumentsList.filter((obj:any) => obj.jobFiles.length > 0).length;
      this.isAllJobContainsFiles = count ? true : false;
      console.log("Count : ", count, "  isAllJobContainsFiles: ", this.isAllJobContainsFiles)
      console.log(`Out of "${this.jobDocumentsList.length} jobs",  "${count} jobs" having files present in it`);
      console.log("this.jobDocumentsList: ", this.jobDocumentsList);
  }

  private compareArrays(arr1:any[], arr2:any[]) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }

  ontoggle(
    slideMenuOpenSiteAdminDetails: boolean,
    slideMenuOpenSiteDetails: boolean,
    slideMenuOpenLayerDetails: boolean,
    slideMenuOpenTools: boolean,
    slideMenuOpenJobDescription: boolean,
    slideMenuOpenFolder: boolean
  ) {
    this.slideMenuOpenSiteAdminDetails = slideMenuOpenSiteAdminDetails
    this.slideMenuOpenSiteDetails = slideMenuOpenSiteDetails
    this.slideMenuOpenLayerDetails = slideMenuOpenLayerDetails
    this.slideMenuOpenTools = slideMenuOpenTools
    this.slideMenuOpenJobDescription = slideMenuOpenJobDescription
    this.slideMenuOpenFolder = slideMenuOpenFolder

    this.ontoggleClicked.emit({
      slideMenuOpenSiteAdminDetails,
      slideMenuOpenSiteDetails,
      slideMenuOpenLayerDetails,
      slideMenuOpenTools,
      slideMenuOpenJobDescription,
      slideMenuOpenFolder,
    });
  }

  loadDocuments() {
    this.jobDocumentsList = [];
    this._loadDocumentsData(this.zoomedJobIds, false);
  }

  private _loadDocumentsData(jobIds:any[], isNewJibIds:boolean) {
    if (this.showToggleSideBar && this.slideMenuOpenFolder) {
      if(isNewJibIds && (this.jobDocumentsList.length === 0 || !isNewJibIds)) {
        this.spinner.show();
      } 
      console.log("Call for jobIds: ", jobIds);
      let payLoad = {
        jobIds: jobIds,
        userId: this.loginUserId,
      };
      this.mapViewerDocumentsSubscribe = this.mapViewerService
        .fetchMultipleJobDocumentsDetails(payLoad)
        .subscribe(
          (response: any) => {
            this.spinner.hide();
            console.log("_loadDocumentsData jobDocumentsList: ",JSON.parse(JSON.stringify(this.jobDocumentsList)));
            console.log("_loadDocumentsData response: ", response);
            this.jobDocumentsList = isNewJibIds ? this.jobDocumentsList.concat(response) :  response;
            this.jobDocumentsList.map((it:any)=>{
              it.isEyeVisible = true;
              if(it.jobFiles.length > 0) {
                it.jobFiles.map((item:any)=> item.isEyeVisible = true);
              }
            });
            console.log("_loadDocumentsData jobDocumentsList After: ",JSON.parse(JSON.stringify(this.jobDocumentsList)));
            const count = this.jobDocumentsList.filter((obj:any) => obj.jobFiles.length > 0).length;
            this.isAllJobContainsFiles = count ? true : false;
            console.log("_loadDocumentsData Count : ", count, "  isAllJobContainsFiles: ", this.isAllJobContainsFiles)
            console.log(`Out of "${this.jobDocumentsList.length} jobs",  "${count} jobs" having files present in it`);
            this.jobIdToDocuments = this.groupItems([...this.jobDocumentsList], "jobId");
          },
          (err: any) => {
            this.toastr.error('Job Documents error: ' + err.status, '', {
              timeOut: 3000,
            });
            this.spinner.hide();

          }
        );
    }
  }

  groupItems(array:any[], property:any) {
    let reducer = function(groups:any, item:any) {
        let name = item[property] ? item[property] : property
        let group = groups[name] || (groups[name] = []);
        group.push(item);
        return groups;
    };
    return array.reduce(reducer, {});
}

  btnClickDownloadDPRFile(data: any) {
    let userInteractionTypeId = 12; // For files Download interaction its value 12
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    if (data.fileLink.includes('swmaps')) {
      link.setAttribute('href', data.fileLink + this.dprFileSWAPSDownloadSAS);
    } else {
      link.setAttribute('href', data.fileLink + this.dprFileDownloadSAS);
    }
    link.setAttribute('download', data.name);
    document.body.appendChild(link);
    link.click();
    link.remove();
    this.commonService.docInteractionFrRecentAccessFiles(data?.id, data?.objectTypeId, this.loginUserId, userInteractionTypeId);
  }

  toggleDocuemnt(data: any, jobData:any) {
    data.isEyeVisible = !data.isEyeVisible;
    this._toggleDoc();
    let totaljobFiles = jobData.jobFiles?.filter((obj:any) => !obj.isEyeVisible);
    if(jobData.jobFiles.length && jobData.jobFiles.length == totaljobFiles.length) {
      jobData.isEyeVisible = false;
    } else {
      jobData.isEyeVisible = true;
    }
  }

  _toggleDoc(){
    let filterExp:any = ['all'];
    let hiddenFeatures:any[] = [];
    this.jobDocumentsList.filter((obj:any) => obj.jobFiles.length > 0)?.forEach((element:any) => {
      element.jobFiles.forEach((item:any) => {
        if(!item.isEyeVisible) {
          hiddenFeatures.push(item.name.replace(/\s/g, ''));
        }
      });
    });
    filterExp.push(['!', ['in', ['get', 'docName'], ['literal', hiddenFeatures]]])
    this.map.setFilter('sourceDocumentLayer',filterExp);
  }

  toggleJobDocuemnt(data:any) {
    data.isEyeVisible = !data.isEyeVisible;
    data.jobFiles?.forEach((item:any) => {
      item.isEyeVisible = data.isEyeVisible;
    });
    this._toggleDoc();
  }

}
