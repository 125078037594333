import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CredentialessLogin } from '../common-component/credentialess-login/credentialess-login';

@Injectable({
  providedIn: 'root'
})
export class BehaviorSubjectService {
  private userNameSource = new BehaviorSubject("Test Name");
  private loginStatus = new BehaviorSubject<boolean>(false);
  private isLoginStatusDetermined = new BehaviorSubject<boolean>(false);
  private logDataObj = new BehaviorSubject<any>({});
  private logDataObjfordocument = new BehaviorSubject<any>({});
  private documentShared = new BehaviorSubject<any>({});
  private jobShared = new BehaviorSubject<any>({});
  private jobSharedOnMap = new BehaviorSubject<any>({});
  private myPreferencesObj = new BehaviorSubject<any>({});
  private isHeaderSearch = new BehaviorSubject<boolean>(false);
  private jobsharebyheadershareicon = new BehaviorSubject<string>("");
  public  printTriggerHeader = new BehaviorSubject<string>("");
  private headerSearchType =  new BehaviorSubject<number>(0);
  private headerSearchString = new BehaviorSubject<string>("");
  private isHeaderMapSearch = new BehaviorSubject<boolean>(false);
  private headerMapSearchType =  new BehaviorSubject<number>(0);
  private headerMapSearchString = new BehaviorSubject<string>("");
  private userDetailsFromAzureADB2C = new BehaviorSubject<any>({});
  private userPermissionDetails = new BehaviorSubject<any>({});
  private userDetailsFromDB = new BehaviorSubject<any>({});
  private accessToken = new BehaviorSubject("");
  private userDetails = new BehaviorSubject<any>({});
  private isAlreadyLogin = new BehaviorSubject<any>("");
  private helpSearchValue =  new BehaviorSubject<any>("");
  private helpSelectedTab =  new BehaviorSubject<any>("");
  private httpError =  new BehaviorSubject<any>("");
  private headerLayerButtonClickState = new BehaviorSubject<any>(null);
  private headerFolderButtonClickState = new BehaviorSubject<any>(null);
  private headerMapToolButtonClickState = new BehaviorSubject<any>(null);

  private callLoginFromhome = new BehaviorSubject<string>("");
  private mapIntegrationCenterAndZoomLevelData = new BehaviorSubject<any>(null);

  private isVideoPlay: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isExportModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private recentDocdetail =  new BehaviorSubject<any>("");
  public searchString = new BehaviorSubject<any>('');
  public apiCount = new BehaviorSubject<number>(0);
  public credentialessLogin = new BehaviorSubject<CredentialessLogin>(JSON.parse('{}'));
  public loginUserIdSetInSession = new BehaviorSubject<string>("");

  
  currentMessage = this.userNameSource.asObservable();
  loginStatusObservable = this.loginStatus.asObservable();
  loginStatusDeterminedObservable = this.isLoginStatusDetermined.asObservable();
  logDataObservable = this.logDataObj.asObservable();
  logDataObservableforDoc = this.logDataObjfordocument.asObservable();
  documentSharedObservable = this.documentShared.asObservable();
  jobSharedObservable = this.jobShared.asObservable();
  jobSharedOnMapObservable = this.jobSharedOnMap.asObservable();
  myPreferencesObservable = this.myPreferencesObj.asObservable();
  isHeaderSearchObservable = this.isHeaderSearch.asObservable();
  isJobShareByHeaderIconObservable = this.jobsharebyheadershareicon.asObservable();
  headerSearchTypeObservable = this.headerSearchType.asObservable();
  headerSearchStringObservable = this.headerSearchString.asObservable();
  isHeaderMapSearchObservable = this.isHeaderMapSearch.asObservable();
  headerMapSearchTypeObservable = this.headerMapSearchType.asObservable();
  headerMapSearchStringObservable = this.headerMapSearchString.asObservable();
  currentUserDetailsFromAzureADB2C = this.userDetailsFromAzureADB2C.asObservable();
  currentUserDetailsFromDB = this.userDetailsFromDB.asObservable();
  currentAccessToken = this.accessToken.asObservable();
  isAlreadyLoginObservable = this.isAlreadyLogin.asObservable();
  helpSearchValueObservable = this.helpSearchValue.asObservable();
  helpSelectedTabObservable = this.helpSelectedTab.asObservable();
  isVideoPlayObservable = this.isVideoPlay.asObservable();
  httpErrorObservable = this.httpError.asObservable();
  headerLayerButtonClickStateObservable = this.headerLayerButtonClickState.asObservable();
  headerFolderButtonClickStateObservable = this.headerFolderButtonClickState.asObservable();
  headerMapToolButtonClickStateObservable = this.headerMapToolButtonClickState.asObservable();
  
  callLoginFromHomePageObservable = this.callLoginFromhome.asObservable();
  currentMapIntegrationCenterAndZoomLevelData = this.mapIntegrationCenterAndZoomLevelData.asObservable();
  isExportModalObservable = this.isExportModal.asObservable();
  recentDocumentDetail = this.recentDocdetail.asObservable();
  pendingApiCount$ = this.apiCount.asObservable();
  credentialessLoginObservable = this.credentialessLogin.asObservable();
  loginUserIdSetInSessionObsesrvable = this.loginUserIdSetInSession.asObservable();

  constructor() { }

  changeCredentialessLogin(oneTimeLink: CredentialessLogin) {
    this.credentialessLogin.next(oneTimeLink);
  }

  changeLoginUserIdSetInSession(userId: string) {
    this.loginUserIdSetInSession.next(userId);
  }
  
  changeMessage(message: string) {
    this.userNameSource.next(message)
  }

  changeLoginStatus(status : boolean){
    this.loginStatus.next(status);
    this.isLoginStatusDetermined.next(true);
  }

  changeLogData(logData: any){
    this.logDataObj.next(logData);
  }

  changedoclogdata(documentLogData:any){
    this.logDataObjfordocument.next(documentLogData);
  }

  changeDocumentShared(logData: any){
    this.documentShared.next(logData);
  }

  changeJobShared(logData: any){
    this.jobShared.next(logData);
  }
  changeJobPopOnMap(jobSharedOnMap: any){
    this.jobSharedOnMap.next(jobSharedOnMap);
  }
  setMyPreferencesObjData(obj: any, FromLocalStor?: boolean) {
    if (!FromLocalStor) {
      sessionStorage.setItem('preferences', JSON.stringify(obj));
    }
    this.myPreferencesObj.next(obj);
  }

  changeIsHeaderSearch(isHeaderSearch: boolean){
    this.isHeaderSearch.next(isHeaderSearch);
  }

  changeHeaderSearchType(searchType: number){
    this.headerSearchType.next(searchType);
  }

  changeHeaderSearchString(searchString: string){
    this.headerSearchString.next(searchString);
  }
  changeIsMapHeaderSearch(isHeaderMapSearch: boolean){ // for map all joblist screen
    this.isHeaderMapSearch.next(isHeaderMapSearch);
  }

  changeHeaderMapSearchType(headerMapSearchType: number){   // for map all joblist screen
    this.headerMapSearchType.next(headerMapSearchType);
  }

  changeHeaderMapSearchString(headerMapSearchString: string){  // for map all joblist screen
    this.headerMapSearchString.next(headerMapSearchString);
  }

  updateCurrentUserDetailsFromAzureADB2C(userDetails: any){
    this.userDetailsFromAzureADB2C.next(userDetails);
  }

  updareCurrentUserPermissionDetails(permissionObj:any){
    this.userPermissionDetails.next(permissionObj);
  }

  updateCurrentUserDetailsFromDB(userDetails: any){
    this.userDetailsFromDB.next(userDetails);
  }

  updateAccessToken(accessToken: string){
    this.accessToken.next(accessToken);
  }


  setAlreadyLogin(val:any) {
    this.isAlreadyLogin.next(val);
  }
  
  changeHelpSearchValue(searchVal: any){
    this.helpSearchValue.next(searchVal);
  }
  changeHelpSelectedTabValue(searchVal: any){
    this.helpSelectedTab.next(searchVal);
  }
  changeisVideoPlay(searchVal: any){
    this.isVideoPlay.next(searchVal);
  }

  changeisExportModal(searchVal: any){
    this.isExportModal.next(searchVal);
  }
  
  multipleShareJobHeaderIcon(jobsharebyheadershareicon: string){
    this.jobsharebyheadershareicon.next(jobsharebyheadershareicon);
  }

  printShareTrigger(printTriggerHeader: string){
    this.printTriggerHeader.next(printTriggerHeader);
  }
  
  changeHttpErrorValue(searchVal: any){
		this.httpError.next(searchVal);
	}

  changeHeaderLayerButtonState(val: boolean){
    this.headerLayerButtonClickState.next(val);
  }

  changeHeaderFolderButtonState(val: boolean){
    this.headerFolderButtonClickState.next(val);
  }

  changeHeaderMapToolButtonState(val: boolean){
    this.headerMapToolButtonClickState.next(val);
  }

  callLoginFromHomePage(val: any) {
    this.callLoginFromhome.next(val);
  }

  updateMapIntegrationCenterAndZoomLevelData(val: any){
    this.mapIntegrationCenterAndZoomLevelData.next(val);
  }

  recentdoc(data:any){
    this.recentDocdetail.next(data)
  }
  
  //Address search parameter
  searchName(sdata:any){
    this.searchString.next(sdata)
  }

  updateCount(count: number) {
    this.apiCount.next(count);
  }
}
