import { FileGroup, NewFeature } from "../feature-tree.component.types";

export default function addNewFeature(files: FileGroup[], newFeature: NewFeature): void
{
  removeHasNewFlags(files);
  const { feature, group, type, file } = newFeature;

  feature.isNew = group.hasNew = type.hasNew = file.hasNew = true;
  const existingFile = files.find(x => x.fileId === file.fileId);

  if (!existingFile)
  {
    files.push(file);
    return;
  }

  existingFile.hasNew = true;
  const existingGroup = existingFile.groups.find(x => x.groupId === group.groupId);
  if (!existingGroup)
  {
    existingFile.groups.push(group);
    return;
  }

  existingGroup.hasNew = true;
  const existingType = existingGroup.types.find(x => x.typeId === type.typeId);
  if (!existingType)
  {
    existingGroup.types.push(type);
    return;
  }

  existingType.hasNew = true;
  existingType.features.push(feature);
}

function removeHasNewFlags(files: FileGroup[]): void
{
  for (const file of files) {
    file.hasNew = false;
    for (const group of file.groups) {
      group.hasNew = false;
      for (const type of group.types) {
        type.hasNew = false;
        for (const feature of type.features) {
          feature.isNew = false;
        }
      }
    }
  }
}
