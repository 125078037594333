import { IEnvironment } from "./environment.types";

export const environment: IEnvironment = {
  production: false,
  name:'preprod',
  CLIENT_ID: '2222aadc-378c-418d-ad9c-aa71df49d5f2',
  B2C_LOGIN:'https://login-pp.sitemap.com/gprssitemapngpp.onmicrosoft.com/b2c_1_signin-signup-sitemapng-pp',
  SIGNUP_SIGNIN:'B2C_1_SignIn-SignUp-SiteMapNG-PP',
  AUTHORITY_DOMAIN: "login-pp.sitemap.com",
  AZURE_URL_CONTAINER : 'https://straccsitemapngpp.blob.core.windows.net/',
  IP_STACK : 'https://api.ipstack.com/check?access_key=564ee495a0a99851e8f4b498e19d24b4',
  TILES_MVT_BASE_URL : 'https://tileserver-pp.sitemap.com/',

  // Portal APIs
  DPR_API: 'https://digitalplanroompp.sitemap.com/api/DigitalPlanRoom',
  HELP_API: 'https://helppp.sitemap.com/api/Help',
  IMPORT_EXPORT_API: 'https://importexportpp.sitemap.com/api/ImportExport',
  JOBS_API: 'https://jobspp.sitemap.com/Jobs',
  MAP_SCREEN_API: 'https://mapscreenpp.sitemap.com/api/MapScreen',
  NOTIFICATION_API: 'https://notificationpp.sitemap.com/api/Notification',
  PROJECTS_API: 'https://projectspp.sitemap.com/Projects',
  SECURITY_API: 'https://securitypp.sitemap.com/api/Security',
  SETTINGS_API: 'https://settingspp.sitemap.com/Settings',
  SITES_API: 'https://sitespp.sitemap.com/Sites',
  USER_MANAGEMENT_API:'https://usermanagementpp.sitemap.com/api/UserManagement',

  // this is the tileserver endpoint that is used to call MVTGetActiveJobFeaturesByLayerId
  TILES_MVT_API: 'https://tileserver-pp.sitemap.com/api/v1/mvttileserver',
  TILES_IMPORT_API: 'https://tileserver-pp.sitemap.com/api/v1/importserver',
  TILES_PRINT_API: 'https://tileserver-pp.sitemap.com/api/v1/printserver',
  TILES_IMAGE_SERVER: ' https://tileserver-pp.sitemap.com/api/v1/imageserver',
  RASTER_TILES_SERVER: ' https://tileserver-pp.sitemap.com/api/v1/rastertileserver',

  picturesContainerSAS : '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-21T04:19:20Z&st=2023-11-20T20:19:20Z&spr=https&sig=ILi8rtvUEvJhH77t37Bsm5Qai84AXmcuajG3LdamdAg%3D',
  dprFilesContainerSAS : '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-21T04:19:20Z&st=2023-11-20T20:19:20Z&spr=https&sig=ILi8rtvUEvJhH77t37Bsm5Qai84AXmcuajG3LdamdAg%3D',
  importedContainerSAS : '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-21T04:19:20Z&st=2023-11-20T20:19:20Z&spr=https&sig=ILi8rtvUEvJhH77t37Bsm5Qai84AXmcuajG3LdamdAg%3D',
  helpDocumentContainerSAS : '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-21T04:19:20Z&st=2023-11-20T20:19:20Z&spr=https&sig=ILi8rtvUEvJhH77t37Bsm5Qai84AXmcuajG3LdamdAg%3D',
  dprFileSWAPSDownloadSAS : '?sv=2021-12-02&ss=bfqt&srt=sco&sp=rtfx&se=2023-05-01T20:17:40Z&st=2023-03-11T13:17:40Z&spr=https&sig=7NiH3KUgqblXDlVknd2zgIJOQ2p2gqVcEcWHhHXN1ww%3D',
  CUSTOME_LINES : 'https://tileserver-pp.sitemap.com/api/v1/mvttileserver/custom_lines/{z}/{x}/{y}.mvt',
  CUSTOME_POINTS : 'https://tileserver-pp.sitemap.com/api/v1/mvttileserver/custom_points/{z}/{x}/{y}.mvt',
  CUSTOME_ANNOTATION_LINES : 'https://tileserver-pp.sitemap.com/api/v1/mvttileserver/custom_lines/{z}/{x}/{y}.mvt',
  CUSTOME_ANNOTATION_POINTS : 'https://tileserver-pp.sitemap.com/api/v1/mvttileserver/custom_annotation_lines/{z}/{x}/{y}.mvt',
  POINT_TILES: 'https://tileserver-pp.sitemap.com/api/v1/mvttileserver/active_points_annotations_by_layerid/',
  LINE_TILES: 'https://tileserver-pp.sitemap.com/api/v1/mvttileserver/active_lines_annotations_by_layerid/',
  NEW_MAP : 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
  STATIC_PHOTO_IMAGE: 'https://tileserver-pp.sitemap.com/static/MPO4326.png',
  mapbox: {
    accessToken: 'pk.eyJ1Ijoic21vaGFuMjAyMiIsImEiOiJjbDI0bm1xb3owMWxoM2tucGowMnc1ejk1In0.1ZaYMVjLOMwSVoX9QfLUZA'
  }
};
