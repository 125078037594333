
  <div class="side-main-menu site-details right"
    [ngClass]="{
    'open':
      slideMenuOpenSiteDetails &&
      !slideMenuOpenSiteAdminDetails &&
      !slideMenuOpenLayerDetails &&
      !slideMenuOpenTools &&
      !slideMenuOpenJobDescription  &&
      !slideMenuOpenFolder,
    'end-0':
      slideMenuOpenSiteAdminDetails ||
      slideMenuOpenTools ||
      slideMenuOpenJobDescription ||
      slideMenuOpenFolder ||
      slideMenuOpenLayerDetails
    }"
  >
    <div class="menu-first"
      title="Site Details"
      (click)="ontoggle(false,!slideMenuOpenSiteDetails, false, false, false, false);">
      <!-- <span class="fa fa-angle-left"></span> -->
      <img class="right-toggle" src="../../../../../assets/images/ico_my_files.svg" alt="Site Details" />
    </div>

    <div class="flex-shrink-0 p-0 bg-white1 sub-page-menu" [ngClass]="siteAddForm?.value?.searchedUserId?.userId ? 'disabled' : ''">
     <div class="sub-page-menu-body">
      <div class="accordion custom-accordion" id="accordionPanelsStayOpenExample5">
        <h3>Site
          <ng-container *ngIf="currentZoomLevel > 13.25">
          <span class="btn btn-primary btn-sm" 
          [ngClass]="{'disabled': isSiteEditingModeIsOn}" 
          *appPermission="permissionEnumList.CAN_CREATE_SUBSITE_ON_MAP;elementShowHide:false;"
          id="liAddSubsite" 
          (click)="addSubsitePopup()">Add Sub Site</span>
          </ng-container>
          <ng-container *ngIf="currentZoomLevel > 13.25">
            <span class="btn btn-primary btn-sm me-2" [ngClass]="{'disabled': isSiteEditingModeIsOn}"   (click)="addSitePopup();" *appPermission="permissionEnumList.CAN_CREATE_SITE_ON_MAP;elementShowHide:false;">Add Site</span>
          </ng-container>
      </h3>

        <div class="accordion-item custom-accordion-item" *ngFor="let data of siteData; let i=index;">
          <h2 class="accordion-header" id="imported-group-layer">
            <button class="accordion-button collapsed btn-checkbox" (click)="getSiteJObBySiteId(data.siteId, data);onGetSubSiteByID.emit(data.siteId);" type="button" data-bs-toggle="collapse"
              [attr.data-bs-target]="'#site-'+data.siteId+'-content-'+i" aria-expanded="false"
              aria-controls="imported-group-layer-content">
              {{data.siteName}}
              <span *ngIf="data.siteId == undefined" class="sub-page-menu-action">
                <i (click)="addSiteOnSave(data)" class="fa fa-save"></i>
                <i (click)="resetSiteData(siteAddForm.value.siteName, data.siteId)" class="fa fa-close"></i>
              </span>
              <span *ngIf="data.siteId != undefined" class="sub-page-menu-action">
                <i *ngIf="data.siteId === siteEditId && isSiteSubEditProperties === 'SITE'" class="fa fa-info-circle" aria-hidden="true" data-bs-toggle="modal" data-bs-target="#addSite" (click)="editSiteInfo(data);"></i>
                <i *ngIf="data.siteId === siteEditId && isSiteSubEditProperties === 'SITE'" (click)="updateSite(data)" class="fa fa-save"></i>
                <i *ngIf="data.siteId === siteEditId && isSiteSubEditProperties === 'SITE'" (click)="cancelSiteSubEditMode(data,'SITE','siteName')" class="fa fa-close"></i>
                <ng-container *ngIf="isSiteEditingModeIsOn && data.siteId === siteEditId && isSiteSubEditProperties === 'SITE'">
                <i  class="fa fa-trash span-delete" (click)="deleteSiteSubSite(data.siteId, '', 'site', data.siteName);cancelSiteSubEditMode(data,'SITE','siteName')" *appPermission="permissionEnumList.CAN_DELETE_SITE_ON_MAP;elementShowHide:false;"></i>
                </ng-container>
                <ng-container *ngIf="data.siteId != isSiteEditClick">
                <i  class="fa fa-edit" [ngClass]="{'disabled':(isSiteEditClick && data.siteId != isSiteEditClick) || isSubSiteEditClick}"
                (click)="gotoMapEditor(data,'SITE')" *appPermission="permissionEnumList.CAN_EDIT_SITE_ON_MAP;elementShowHide:false;"></i>
               </ng-container>
              </span>
            </button>
          </h2>

          <div id="site-{{data.siteId}}-content-{{i}}" class="accordion-collapse collapse" aria-labelledby="imported-group-layer">
            <div class="accordion-body custom-accordion-body">
              <div class="flex justify-content-center">
                <!-- <p class="mb-0">{{data.description}}</p> -->
                <!-- <p class="mb-0">{{data.address}}</p> -->
              </div>
              <nav class="custom-layer-links">
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <button class="nav-link active" id="nav-jobs-tab-{{data.siteId}}" data-bs-toggle="tab" [attr.data-bs-target]="'#nav-jobs-'+data.siteId"  type="button"
                    role="tab" [attr.aria-controls]="'nav-jobs'+data.siteId" aria-selected="true">Jobs</button>
                  <button class="nav-link" id="nav-documents-tab-{{data.siteId}}" data-bs-toggle="tab" [attr.data-bs-target]="'#nav-documents-'+data.siteId"
                    type="button" role="tab" [attr.aria-controls]="'nav-documents'+data.siteId" aria-selected="false">Documents</button>
                    <button class="nav-link" id="nav-externalLayer-tab-{{data.siteId}}" data-bs-toggle="tab"
                    [attr.data-bs-target]="'#nav-external-'+data.siteId" type="button" role="tab"
                    [attr.aria-controls]="'nav-external'+data.siteId" aria-selected="false"
                    (click)='externalTabSelected(data.siteId)'>External Layer</button>
                    <button class="nav-link" id="nav-subsite-tab-{{data.siteId}}" data-bs-toggle="tab" [attr.data-bs-target]="'#nav-subsite-'+data.siteId"  type="button"
                    role="tab" [attr.aria-controls]="'nav-subsite'+data.siteId" aria-selected="true">Subsites</button>
                  </div>
              </nav>
              <div class="tab-content custom-layer-contents" id="nav-tabContent">
                <div class="tab-pane fade show active position-relative" id="nav-jobs-{{data.siteId}}" role="tabpanel" [attr.aria-labelledby]="'nav-jobs-tab-'+data.siteId">
                   <!-- <pre>{{data | json}}</pre> -->
                   <!--start job content-->
                   <div class="subsite-properties-nav-btn set-right">
                    <span class="sub-page-menu-action">
                      <i class="fa fa-save"  (click)="associateJObBySiteId(data.siteId, data, 1)"></i>
                      <i class="fa fa-close" (click)="getSiteJObBySiteId(data.siteId, data)"></i>
                    </span>
                  </div>
                  <ng-container *ngIf="data.jobData && data.jobData.length > 0; else noDataSubSiteData">
                    <div class="accordion-item custom-accordion-item" *ngFor="let job of data.jobData">
                      <h2 class="accordion-header" id="subSiteJob-{{job.jobId}}">
                        <button class="accordion-button collapsed btn-checkbox w-auto" type="button" data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#siteSubJob-'+job.jobId+'-content'" aria-expanded="false">
                        </button>
                        <input class="site-properties-checkbox" name="job-{{job.jobId}}" (change)="associateJobsCheckbox(job.jobId, $event)" [checked]="job.isAssociated"type="checkbox" />
                        <i class="site-properties">{{ job.jobName }}</i>
                      </h2>
                      <div id="siteSubJob-{{job.jobId}}-content" class="accordion-collapse collapse">
                        <div class="accordion-body custom-accordion-body">
                          <div>
                            <p class="site-properties-content">
                            Latitude: <span> {{job.latitude}} </span>
                            </p>
                          </div>
                          <div>
                            <p class="site-properties-content">
                            Longitude: <span> {{job.longitude}} </span>
                             </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!--end job content-->

                </div>
                <div class="tab-pane fade position-relative" id="nav-documents-{{data.siteId}}" role="tabpanel" [attr.aria-labelledby]="'nav-documents-tab-'+data.siteId">
                    <!--start document content-->
                    <div class="subsite-properties-nav-btn">
                      <span class="sub-page-menu-action">
                        <i class="fa fa-save" (click)="associateJObBySiteId(data.siteId, data, 2)"></i>
                        <i class="fa fa-close" (click)="getDocumentBySiteId(data.siteId, data)"></i>
                      </span>
                    </div>
                    <ng-container *ngIf="data.docData && data.docData.length > 0; else noDataSubSiteData">
                      <div class="accordion-item custom-accordion-item" *ngFor="let doc of data.docData">
                        <h2 class="accordion-header" id="subSiteJob-{{doc.id}}">
                          <input class="site-properties-checkbox" name="doc-{{doc.id}}" (change)="associateJobsCheckbox(doc.id, $event)"  [checked]="doc.isAssociated" type="checkbox" />
                          <i class="site-properties">{{ doc.name }}</i>
                        </h2>
                       </div>
                    </ng-container>
                    <!--end document content (change)="associateJobsCheckbox(doc.id, $event)"-->
                </div>
                <!-- start external content-->
                <div class="tab-pane fade position-relative" id="nav-external-{{data.siteId}}" role="tabpanel"
                    [attr.aria-labelledby]="'nav-external-tab-'+data.siteId">
                    <div class="subsite-properties-nav-btn set-right">
                      <span class="sub-page-menu-action">
                        <i class="fa fa-save" (click)="buttonClicked(data.siteId, data,'site')"></i>
                        <i class="fa fa-close" (click)="getSiteJObBySiteId(data.siteId, data)"></i>
                      </span>
                    </div>
                    <ng-container *ngIf="externalLayerData && externalLayerData.length > 0; else noDataSubSiteData">
                      <div class="accordion-item custom-accordion-item" *ngFor="let jobs of externalLayerData">
                        <h2 *ngIf = 'jobs.externalContent.length > 0' class="accordion-header" id="subSiteJob-{{jobs.externalContentTypeId}}">
                          <button class="accordion-button collapsed btn-checkbox w-auto" type="button"
                            data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#siteSubJob-'+jobs.externalContentTypeId+'-content'"
                            aria-expanded="false">
                        </button>
                          <input class="site-properties-checkbox" name="job-{{jobs.externalContentTypeId}}"
                            (change)="externalLayerAssociateSitesCheckbox('',jobs.externalContentTypeId,$event,'site',true)"
                            [(ngModel)]="jobs.isAssociated" type="checkbox" />
                          <i class="site-properties">{{ jobs.externalContentTypeName }}</i>
                        </h2>
                        <div *ngIf = 'jobs.externalContent.length > 0' id="siteSubJob-{{jobs.externalContentTypeId}}-content" class="accordion-collapse collapse">
                          <div class="accordion-body custom-accordion-body">
                            <div>
                              <div class='site-properties-content'>
                                <div class="accordion-item custom-accordion-item"
                                  *ngFor="let job of jobs.externalContent">
                                  <h2 class="accordion-header" id="subSiteJob-{{job.id}}">
                                    <button class="accordion-button collapsed btn-checkbox w-auto" type="button"
                                      data-bs-toggle="collapse"
                                      [attr.data-bs-target]="'#siteSubJob-'+job.id+'-content-nested'"
                                      aria-expanded="false">
                                    </button>
                                    <input class="site-properties-checkbox" name="job-{{job.id}}"
                                      (change)="externalLayerAssociateSitesCheckbox(job.id, jobs.externalContentTypeId,$event,'site',true)"
                                      [(ngModel)]="job.isAssociated" type="checkbox" />
                                    <i class="site-properties">{{ job.displayName }}</i>
                                  </h2>
                                  <div id="siteSubJob-{{job.id}}-content-nested" class="accordion-collapse collapse">
                                    <div class="accordion-body custom-accordion-body">
                                      <div>
                                        <p class="site-properties-content">
                                          Latitude: <span> {{job.latitude}} </span>
                                        </p>
                                      </div>
                                      <div>
                                        <p class="site-properties-content">
                                          Longitude: <span> {{job.longitude}} </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                <!-- end external content-->
  
                <!-- start subsite content -->
                <div class="tab-pane fade position-relative" id="nav-subsite-{{data.siteId}}" role="tabpanel" [attr.aria-labelledby]="'nav-subsite-tab-'+data.siteId">
                  <div class="subsite-properties-nav-btn">
                    <span class="sub-page-menu-action">
                      <i class="fa fa-save" (click)="associateDisassociateSubsiteBySiteId(data.siteId,data)"></i>
                      <i class="fa fa-close" (click)="getDocumentBySiteId(data.siteId, data)"></i>
                    </span>
                  </div>
                  <ng-container *ngIf="data.subSiteData && data.subSiteData.length > 0; else noDataSubSiteData">
                    <div class="accordion-item custom-accordion-item" *ngFor="let subsite of data.subSiteData">
                      <h2 class="accordion-header" id="subSiteJob-{{subsite.subSiteId}}">
                        <input class="site-properties-checkbox" name="doc-{{subsite.subSiteId}}" (change)="associateJobsCheckbox(subsite.subSiteId, $event)"  [checked]="subsite.isAssociated" type="checkbox" />
                        <i class="site-properties">{{ subsite.subSiteName }}</i>
                      </h2>
                     </div>
                  </ng-container>
                </div>
                <!-- end subsite content -->

              </div>
              <ng-container *ngIf="data.subSiteData != undefined && data.subSiteData.length > 0">
                <div class="accordion-item custom-accordion-item" *ngFor="let subSiteLoopData of data.subSiteData">
                  <h2 class="accordion-header" id="{{subSiteLoopData.subSiteId}}'-'i">
                    <button class="accordion-button collapsed btn-checkbox w-auto" type="button" data-bs-toggle="collapse"
                      (click)="getJobsBySubSiteId(data.siteId,subSiteLoopData.subSiteId)" [attr.data-bs-target]="'#subSite-'+subSiteLoopData.subSiteId+'-content'"
                      aria-expanded="false">
                    </button>
                    <i class="site-properties ps-1">{{ subSiteLoopData.subSiteName }}</i>
                    <span class="sub-page-menu-action" *ngIf="subSiteLoopData.subSiteId === 0">
                      <i class="fa fa-save" (click)="saveSubSite('add',data,subSiteLoopData)"></i>
                      <i class="fa fa-close" (click)="cancelNewlyAddedSubSite(data.siteId,subSiteLoopData.subSiteId)"></i>
                    </span>
                    <span *ngIf="subSiteLoopData.subSiteId != 0" class="sub-page-menu-action">
                      <i *ngIf="subSiteLoopData.subSiteId === siteEditId && isSiteSubEditProperties === 'SUBSITE'" (click)="editSubSiteInfo(data,subSiteLoopData)" class="fa fa-info-circle" aria-hidden="true" data-bs-toggle="modal" data-bs-target="#addSubSite"></i>
                      <i *ngIf="subSiteLoopData.subSiteId === siteEditId && isSiteSubEditProperties === 'SUBSITE'" (click)="saveSubSite('update',data,subSiteLoopData)" class="fa fa-save"></i>
                      <i *ngIf="subSiteLoopData.subSiteId === siteEditId && isSiteSubEditProperties === 'SUBSITE'" (click)="cancelSiteSubEditMode(subSiteLoopData,'SUBSITE','subSiteName',data)" class="fa fa-close"></i>
                      <ng-container *ngIf="isSiteEditingModeIsOn && subSiteLoopData.subSiteId === siteEditId && isSiteSubEditProperties === 'SUBSITE'">
                      <i class="fa fa-trash span-delete" *appPermission="permissionEnumList.CAN_DELETE_SUBSITE_ON_MAP;elementShowHide:false;"
                      (click)="deleteSiteSubSite(data.siteId, subSiteLoopData.subSiteId, 'subSite', subSiteLoopData.subSiteName);cancelSiteSubEditMode(subSiteLoopData,'SUBSITE','subSiteName',data)"></i>
                      </ng-container>
                      <ng-container *ngIf="subSiteLoopData.subSiteId != isSubSiteEditClick">
                      <i  class="fa fa-edit" [ngClass]="{'disabled':(isSiteEditClick && data.siteId != isSiteEditClick) || (isSiteEditClick && data.siteId === isSiteEditClick) || (isSubSiteEditClick && subSiteLoopData.subSiteId != isSubSiteEditClick)}"
                      (click)="gotoMapEditor(subSiteLoopData,'SUBSITE')" *appPermission="permissionEnumList.CAN_EDIT_SUBSITE_ON_MAP;elementShowHide:false;"></i>
                      </ng-container>
                    </span>
                  </h2>
                  <div id="subSite-{{subSiteLoopData.subSiteId}}-content" class="accordion-collapse collapse">
                    <div class="accordion-body custom-accordion-body">
                      <nav class="custom-layer-links">
                        <div class="nav nav-tabs" id="nav-tab-{{subSiteLoopData.subSiteId}}" role="tablist">
                          <button class="nav-link active" id="nav-jobs-tab-{{subSiteLoopData.subSiteId}}" data-bs-toggle="tab" [attr.data-bs-target]="'#nav-jobs-'+subSiteLoopData.subSiteId"
                            type="button" role="tab" [attr.aria-controls]="'nav-job-'+subSiteLoopData.subSiteId" aria-selected="true">Jobs</button>
                          <button class="nav-link" id="nav-documents-tab-{{subSiteLoopData.subSiteId}}" data-bs-toggle="tab" [attr.data-bs-target]="'#nav-documents-'+subSiteLoopData.subSiteId"
                            type="button" role="tab" [attr.aria-controls]="'nav-documents-'+subSiteLoopData.subSiteId" aria-selected="false">Documents</button>
                            <button class="nav-link" id="nav-externalLayer-tab-{{subSiteLoopData.subSiteId}}" data-bs-toggle="tab"
                            [attr.data-bs-target]="'#nav-external-'+subSiteLoopData.subSiteId" type="button" role="tab"
                            [attr.aria-controls]="'nav-external'+subSiteLoopData.subSiteId" aria-selected="false"
                            (click)='externalTabSelectedforSubSiteId(subSiteLoopData.subSiteId)'>External Layer</button>
                        </div>
                      </nav>
                      <div class="tab-content custom-layer-contents" id="nav-tabContent-{{subSiteLoopData.subSiteId}}">
                        <div class="tab-pane fade show active position-relative" id="nav-jobs-{{subSiteLoopData.subSiteId}}" role="tabpanel" [attr.aria-labelledby]="'nav-jobs-tab-'+subSiteLoopData.subSiteId">
                          <!--start job content-->
                          <div class="subsite-properties-nav-btn set-right">
                            <span class="sub-page-menu-action">
                              <i class="fa fa-save" *appPermission="permissionEnumList.CAN_DISSOCIATE_JOB_TO_SUBSITE;elementShowHide:false;" (click)="saveSubSiteJobsDoc(data,subSiteLoopData,'jobData','jobId', 1)"></i>
                              <i class="fa fa-close" (click)="this.subSiteServices.getJobsBySubSiteId(data.siteId,subSiteLoopData.subSiteId,siteData,loginUserId)"></i>
                            </span>
                          </div>
                          <ng-container *ngIf="subSiteLoopData.jobData && subSiteLoopData.jobData.length > 0; else noDataSubSiteData">
                            <div class="accordion-item custom-accordion-item" *ngFor="let job of subSiteLoopData.jobData">
                              <h2 class="accordion-header" id="subSiteJob-{{job.jobId}}">
                                <button class="accordion-button collapsed btn-checkbox w-auto" type="button" data-bs-toggle="collapse"
                                  [attr.data-bs-target]="'#siteSubJob-'+job.jobId+'-content'" aria-expanded="false">
                                </button>
                                <input class="site-properties-checkbox" type="checkbox" [id]="job.jobId" name="chkBox"
                                  [(ngModel)]="job.isAssociated" [value]="job.isAssociated" />
                                <label [for]="job.jobId" aria-label="chkb">
                                  <i class="site-properties">{{ job.jobName }}</i>
                                </label>
                              </h2>
                              <div id="siteSubJob-{{job.jobId}}-content" class="accordion-collapse collapse">
                                <div class="accordion-body custom-accordion-body">
                                  <p class="site-properties-content">
                                    Latitude: <span> {{job.latitude}} </span>
                                  </p>
                                  <p class="site-properties-content">
                                    Longitude: <span> {{job.longitude}} </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                          <!--end job content-->
                        </div>
                        <div class="tab-pane fade position-relative" id="nav-documents-{{subSiteLoopData.subSiteId}}" role="tabpanel" [attr.aria-labelledby]="'nav-documents-tab-' + subSiteLoopData.subSiteId">
                          <!--start doc content-->
                          <div class="subsite-properties-nav-btn set-right">
                            <span class="sub-page-menu-action">
                              <i class="fa fa-save" *appPermission="permissionEnumList.CAN_DISSOCIATE_DOCUMENT_TO_SUBSITE;elementShowHide:false;" (click)="saveSubSiteJobsDoc(data,subSiteLoopData,'docData','id', 2)"></i>
                              <i class="fa fa-close" (click)="this.subSiteServices.getDocumentBySubSiteId(data.siteId,subSiteLoopData.subSiteId,siteData,loginUserId)"></i>
                            </span>
                          </div>
                          <ng-container *ngIf="subSiteLoopData.docData && subSiteLoopData.docData.length > 0; else noDataSubSiteData">
                            <div class="accordion-item custom-accordion-item" *ngFor="let doc of subSiteLoopData.docData">
                              <h2 class="accordion-header" id="subSitedoc-{{doc.id}}">
                                <input class="site-properties-checkbox" type="checkbox" [id]="doc.id" name="chkBox"
                                  [(ngModel)]="doc.isAssociated" [value]="doc.isAssociated" />
                                <label [for]="doc.id" aria-label="chkb">
                                  <i class="site-properties">{{ doc.name }}</i>
                                </label>
                              </h2>
                            </div>
                          </ng-container>
                          <!--end doc content-->
                        </div>
            <!--Start External for subsiteid-->
                        <div class="tab-pane fade position-relative" id="nav-external-{{subSiteLoopData.subSiteId}}" role="tabpanel"
                        [attr.aria-labelledby]="'nav-external-tab-'+subSiteLoopData.subSiteId">
                        <div class="subsite-properties-nav-btn set-right">
                          <span class="sub-page-menu-action">
                            <i class="fa fa-save" (click)="buttonClicked(subSiteLoopData.subSiteId, subSiteLoopData,'subsite')"></i>
                            <i class="fa fa-close" (click)="getSiteJObBySiteId(subSiteLoopData.subSiteId, subSiteLoopData)"></i>
                          </span>
                        </div>
                        <ng-container *ngIf="externalLayerDataForSubsite && externalLayerDataForSubsite.length > 0; else noDataSubSiteData">
                          <div class="accordion-item custom-accordion-item" *ngFor="let jobs of externalLayerDataForSubsite">
                            <h2 *ngIf = 'jobs.externalContent.length > 0' class="accordion-header" id="subSiteJobLoopData-{{jobs.externalContentTypeId}}">
                              <button class="accordion-button collapsed btn-checkbox w-auto" type="button"
                                data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#siteSubJobLoopData-'+jobs.externalContentTypeId+'-content'"
                                aria-expanded="false">
                              </button>
                              <input class="site-properties-checkbox" name="job-{{jobs.externalContentTypeId}}"
                                (change)="externalLayerAssociateSitesCheckbox('',jobs.externalContentTypeId,$event,'subsite',true)"
                                [(ngModel)]="jobs.isAssociated" type="checkbox" />
                              <i class="site-properties">{{ jobs.externalContentTypeName }}</i>
                            </h2>
                            <div *ngIf = 'jobs.externalContent.length > 0' id="siteSubJobLoopData-{{jobs.externalContentTypeId}}-content" class="accordion-collapse collapse">
                              <div class="accordion-body custom-accordion-body">
                                <div>
                                  <div class='site-properties-content'>
                                    <div class="accordion-item custom-accordion-item"
                                      *ngFor="let job of jobs.externalContent">
                                      <h2 class="accordion-header" id="subSiteJobLoopData-{{job.id}}">
                                        <button class="accordion-button collapsed btn-checkbox w-auto" type="button"
                                          data-bs-toggle="collapse"
                                          [attr.data-bs-target]="'#siteSubJobLoopData-'+job.id+'-content-nested'"
                                          aria-expanded="false">
                                        </button>
                                        <input class="site-properties-checkbox" name="job-{{job.id}}"
                                          (change)="externalLayerAssociateSitesCheckbox(job.id, jobs.externalContentTypeId,$event,'subsite')"
                                          [(ngModel)]="job.isAssociated" type="checkbox" />
                                        <i class="site-properties">{{ job.displayName }}</i>
                                      </h2>
                                      <div id="siteSubJobLoopData-{{job.id}}-content-nested" class="accordion-collapse collapse">
                                        <div class="accordion-body custom-accordion-body">
                                          <div>
                                            <p class="site-properties-content">
                                              Latitude: <span> {{job.latitude}} </span>
                                            </p>
                                          </div>
                                          <div>
                                            <p class="site-properties-content">
                                              Longitude: <span> {{job.longitude}} </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

      </div>

    </div>

    </div>
  </div>
<ng-template #noDataSubSiteData>
  <div>
    <p class="text-center" style="color: white;">No Results Found</p>
  </div>
</ng-template>
<!-- Slide Details Menu Ends -->
<!-- ////////////////////////// -->
