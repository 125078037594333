<div *ngFor="let file of files" class="accordion-item custom-accordion-item">

  <div *ngIf="file.fileId" class="mt-4">
    <h2 class="file-title">
      <span class="file-name">{{ file.fileName }}</span>
      <span class="sub-page-menu-action">
        <i
          *ngIf="!edit"
          (click)="toggleFile(file)"
          [ngClass]="file.isEyeVisible ? 'eye-visible' : 'eye-lash'"
        ></i>
        <i *ngIf="edit" class="map-delete-icon" (click)="onDeleteFile.emit(file)"></i>
      </span>
    </h2>
  </div>

  <div *ngFor="let group of file.groups" class="feature-group-container {{group.groupClassName}}">
    <h2 class="accordion-header" [id]="group.headerElementId">

      <button
        class="accordion-button collapsed"
        [id]="group.buttonElementId"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="group.buttonTarget"
        aria-expanded="false"
        [attr.aria-controls]="group.contentElementId"
        (click)="group.isGroupExpanded = !group.isGroupExpanded"
        >
        <span [ngClass]="{'text-primary': group.hasNew }" >{{ group.groupName }}</span>
      </button>

      <span class="sub-page-menu-action">
        <i
          *ngIf="!edit"
          class="group-{{ group.groupClassName }} inner-group feature-group {{ group.groupClassName }} feature-group-margin"
          attr.data-name="{{ group.groupName }}"
          (click)="toggleGroup(group)"
          [ngClass]=" group.isEyeVisible ? 'eye-visible' : 'eye-lash'"
        ></i>
        <i *ngIf="edit" class="map-delete-icon" (click)="deleteGroup(group, file)"></i>
      </span>

    </h2>

    <div
      [id]="group.contentElementId"
      class="accordion-collapse collapse"
      [attr.aria-labelledby]="group.headerElementId"
    >

      <div class="accordion-body custom-accordion-body" *ngIf="group.types.length">

        <div *ngFor="let featureType of group.types; let j = index"
          class="accordion-item custom-accordion-item feature-type-container"
          [ngClass]="featureType.typeClassName"
          >

          <h2 class="accordion-header" [id]="featureType.typeHeaderElementId">

            <button
              class="accordion-button collapsed"
              type="button"
              [id]="featureType.typeButtonElementId"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="featureType.typeButtonTarget"
              aria-expanded="false"
              [attr.aria-controls]="featureType.typeContentElementId"
              (click)="toggleExpandType(featureType)"
              >
              <span [id]="featureType.typeNameElementId" [ngClass]="{'text-primary': featureType.hasNew }">{{ featureType.typeName }}</span>
            </button>

            <span class="sub-page-menu-action">
              <i
                *ngIf="!edit"
                class="group-{{ featureType.typeClassName }} inner-group feature-type {{ featureType.typeClassName }} feature-group-margin"
                [attr.data-name]="featureType.typeName"
                (click)="toggleEyeType(featureType, group)"
                [ngClass]="featureType.isEyeVisible ? 'eye-visible' : 'eye-lash'"
              ></i>
              <i *ngIf="edit" class="map-delete-icon" (click)="deleteType(featureType, group, file)"></i>
            </span>

          </h2>

          <div [id]="featureType.typeContentElementId" class="accordion-collapse collapse" [attr.aria-labelledby]="featureType.typeLabelElementId">
            <div class="accordion-body custom-accordion-body" *ngIf="featureType.features.length">
              <ul [id]="featureType.typeLabelElementId">
                <li  *ngFor="let feature of featureType.features" class="position-relative" #featureItemElement>
                  <img *ngIf="feature.isPoint" [src]="feature.src" (error)="feature.src = feature.errorSrc" [alt]="featureType.typeName" />
                  <span *ngIf="feature.isLine" [ngStyle]="{ 'background-color': feature.color }" class="line-feature"></span>
                  <i class="feature-style" [title]="feature.featureName" [ngClass]="{ 'text-primary': feature.isNew }" >{{ feature.featureName }}</i>
                  <span class="sub-page-menu-action">
                    <i
                      *ngIf="!edit"
                      class="group-{{feature.featureName}} inner-group feature-name {{feature.featureName}} feature-group-margin"
                      [attr.data-name]="feature.featureName"
                      [attr.data-job-id]="feature.mapJobId"
                      [ngClass]="feature.isEyeVisible ? 'eye-visible' : 'eye-lash'"
                      (click)="toggleFeature(feature, featureType, group)"
                    ></i>
                    <i *ngIf="edit && !feature.isNew" class="map-delete-icon" (click)="deleteFeature(feature, featureType, group, file)"></i>
                  </span>
                </li>
              </ul>
            </div>
          </div>

        </div>

      </div>

    </div>

  </div>

</div>

<div class="accordion-body custom-accordion-body raster-images-container" *ngIf="rasterImages && rasterImages.length > 0">
  <ul>
    <li *ngFor="let item of rasterImages;let i = index" class="position-relative custom-input-range">
      <img [src]="'../../assets/images/GPRS_PNG/RASTER_IMAGE.png'" onerror="this.src='../../assets/images/GPRS_PNG/RASTER_IMAGE.png';" />
      <i class="feature-style text-break">{{item.fileName}}</i>
      <span class="sub-page-menu-action">
        <i
          *ngIf="!edit"
          class="group-{{item.fileName}} inner-group feature-name feature-group-margin"
          attr.data-name="{{ item.fileName }}"
          attr.data-job-id="{{ item.mapJobId }}"
          (click)="toggleRasterImage(item)"
          [ngClass]="item.setEyeActive ? 'eye-visible' : 'eye-lash'"></i>
        <i *ngIf="edit && item.type!=='tile'" class="map-edit-icon" (click)="onEditRasterImage.emit(item)"></i>
        <i *ngIf="edit" class="map-delete-icon" (click)="onDeleteRasterImage.emit(item)"></i>
      </span>
      <div class="custom-progess-bar">
        <input type="range" id="refImage" name="refImage" min="0" max="100" value="{{ (item.opacity * 100) }}"
          (oninput)="changeRasterImageOpacity($event, item)" (change)="changeRasterImageOpacity($event, item)" />
      </div>
    </li>
  </ul>
</div>
