<div class="container-flud">
  <div class="row">
    <div class="col-lg-12">
      <div class="card colored-card">
        <h2 class="card-header">Troubleshoot</h2>
        <div class="card-body">
          <p>Coming soon!</p>
          <!-- <h2 class="sub-heading">Who uploads the Project plan in SiteMap?</h2>
          <p>The System Administrator creates the new project using information provided by the Project
            Administrator.
            System
            Administrator or Project/GBU Administrators are responsible for the initial upload into
            SmartStaff using
            the
            Initial
            Upload and Mass Change user guide as a resource.
          </p>
          <h2 class="sub-heading">Who provides the initial staffing plan for the SiteMap upload?</h2>
          <p>The staffing plan for upload is typically provided by Project Controls and is the latest
            approved
            staffing
            plan.
          </p>
          <h2 class="sub-heading">How do I make changes in the Staffing plan?</h2>
          <p>First, you need to have a specific role (i.e. Project/Functional Lead, etc.) to be able to make
            changes.
            Not
            all roles
            have access to make changes. Some roles have read-only access (i.e. Project Controls). Please
            see the
            SmartStaff Access
            Control document for specific role permissions. Changes are made to the staffing plan through
            the
            In-Process
            Plan module
            and should be sent through the change control process via the Approval module as needed. The imp
            Actions
            document and
            General User Guide are good instructional resources for how to make changes. Bechtel University
            courses
            are
            also
            available.
          </p>
          <h2 class="sub-heading">Recruiter is a mandatory field when starting a TW requisition. Who is my
            Recruiter?
          </h2>
          <p>You should always select “Coordinator, TA” as the recruiter when raising a requisition.</p> -->
        </div>
      </div>
    </div>
  </div>
</div>
