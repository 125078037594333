<!-- Slide Menu Starts -->
<div class="side-main-menu layer right"
  [ngClass]="{
    'open':
      slideMenuOpenLayerDetails &&
      !slideMenuOpenSiteAdminDetails &&
      !slideMenuOpenTools &&
      !slideMenuOpenJobDescription  &&
      !slideMenuOpenFolder &&
      !slideMenuOpenSiteDetails,
    'end-0':
      slideMenuOpenSiteAdminDetails ||
      slideMenuOpenTools ||
      slideMenuOpenJobDescription ||
      slideMenuOpenFolder ||
      slideMenuOpenSiteDetails
  }"
  *ngIf="showToggleSideBar">
  <div
    class="menu-first"
    title="Layer"
    (click)="ontoggle(false,false, !slideMenuOpenLayerDetails, false, false, false);
      loadFeature()">
    <!-- <span class="fa fa-angle-left"></span> -->
    <img class="right-toggle" src="../../../../../assets/images/ico_layers.svg" alt="Layers" />
  </div>

  <div class="flex-shrink-0 p-0 bg-white1 sub-page-menu">

    <div class="sub-page-menu-body">
      <ng-container *ngIf="toggleApiLoaded">
        <div class="accordion custom-accordion" id="accordionPanelsStayOpenExample" *ngIf="data.length && navigatedFeatures?.length; else noJobInLayer">
          <ng-container *ngFor="let layer of data">
            <div *ngIf="layer" [ngClass]="{'d-none':!layer.isLayerEditVisible}" attr.data-name="{{ layer.layerName }}" class="map-layer-container {{ layer.layerName }}">
              <h3>
                {{ layer.layerName }} {{ layer.layerName === 'SITE' ? 'DETAILS' : 'LAYER'}}
                <ng-container *ngIf="layer.layerName == 'GPRS' && isGprsLayerCanEdit">
                <i
                  class="map-edit-icon"
                  [ngClass]="!isEditLayer ? 'disabled' : ''"
                  (click)="gotoMapEditor(layer.layerName)"
                  *appPermission="permissionEnumList.CAN_EDIT_GPRS_LAYER_ON_MAP;elementShowHide:false;"
                ></i>
              </ng-container>
              <ng-container *ngIf="layer.layerName == 'CLIENT' && isClientLayerCanEdit">
                <i
                  class="map-edit-icon"
                  [ngClass]="!isEditLayer ? 'disabled' : ''"
                  (click)="gotoMapEditor(layer.layerName)"
                  *appPermission="permissionEnumList.CAN_EDIT_CLIENT_LAYER_ON_MAP;elementShowHide:false;"
                ></i>
              </ng-container>
              <ng-container  *ngIf="layer.layerName == 'IMPORTED' && isImportedLayerCanEdit" >
                <i
                  class="map-edit-icon"
                  [ngClass]="!isEditLayer ? 'disabled' : ''"
                  (click)="gotoMapEditor(layer.layerName)"
                ></i>
              </ng-container>
                <!-- Comment for the time being for Imported layer demo without any check/Validation -->
                <!-- &&
                isImportedLayerCanEdit &&
                isEyeViewVisibleForEmpty(layer) -->
                <ng-container *ngIf="layer.layerName == 'EXTERNAL' && isExternalLayerCanEdit">
                <i
                class="map-edit-icon"
                [ngClass]="!isEditLayer ? 'disabled' : ''"
                (click)="gotoMapEditor(layer.layerName)"
              ></i>
               </ng-container>
              <ng-container *ngIf="layer.layerName == 'REFERENCE' && isReferenceLayerCanEdit && referenceGISTilesData.length > 0">
              <i
                class="map-edit-icon"
                [ngClass]="!isEditLayer ? 'disabled' : ''"
                (click)="gotoMapEditor(layer.layerName)"
              ></i>
            </ng-container>
            <ng-container *ngIf="(layer.layerName == 'IMPORTED' && isImportedLayerCanEdit) || (layer.layerName == 'REFERENCE' && isReferenceLayerCanEdit)">
                <i type="button"

                  class="map-import-icon"  (click)="importJobModalCall(layer.layerName)" style="margin-left:10px; float:none;">
                </i>
              </ng-container>
               <!-- [ngClass]="
                checkEyeLayerVisible(layer) ? 'eye-visible' : 'eye-lash'
              "
              (click)="toggleGPRSLayer(layer.layerName)" -->
              <!-- || !featureGroupMap.has(layer.layerName) -->
                <i *ngIf="layer.layerName !== 'REFERENCE' && layer.groups.length > 0"
                  [class.d-none]="!isEditLayer"
                  id="main{{ layer.layerName }}Layer"
                  class="map-layer-id {{ layer.layerName}} layer-eye-width"
                  attr.data-name="{{ layer.layerName }}"
                  (click)="testToggleLayer(layer)"
                  [ngClass]="
                    layer.isEyeVisible ? 'eye-visible' : 'eye-lash'
                  "
                  style="margin-right: 5px"
                ></i>
                <i
                *ngIf="referenceGISTilesData.length > 0 && layer.layerName === 'REFERENCE' && isEditLayer"
                  (click)="toggleReferenceLayer(layer)"
                  id="main{{ layer.layerName }}Layer"
                  class="map-layer-id {{ layer.layerName}} layer-eye-width"
                  attr.data-name="{{ layer.layerName }}"
                  [ngClass]="
                  layer.isEyeVisible ? 'eye-visible' : 'eye-lash'
                  "
                  style="margin-right: 5px"
                ></i>
              </h3>

              <!-- Editing dropdown code start-->
              <ng-container *ngIf="editLayerName === layer.layerName && !isEditLayer && showAddFeatureContainerFrMapEditor && checkImportedData(layer) && layer.layerName !='REFERENCE' && layer.layerName !='IMPORTED'">
                <div id="dropdownGroup{{ layer.layerName }}Layer">
                  <h2>Select Group</h2>
                  <select class="form-select form-select-sm" id="grpDropDown" name="grpDropDown" [(ngModel)]="groupToSelect"
                    (change)="onGroupChange($event.target)">
                    <option *ngFor="let title of groupDropDownData" [value]="title.featureGroupId">
                      {{ title.featureGroup }}
                    </option>
                  </select>
                </div>

                <div id="dropdownGroup{{ layer.layerName }}Layer">
                  <h2>Features Type</h2>
                  <div class="row">
                    <div class="col-10 pe-0">
                      <select class="form-select form-select-sm" id="featureTypDropDown" name="featureTypDropDown"
                        [(ngModel)]="featureTypeToSelect" (change)="onFeatureTypeChange($event.target)">
                        <option *ngFor="let title of featureTypeDropDownData" [value]="title.featureTypeId">
                          {{ title.featureType }}
                        </option>
                      </select>
                    </div>
                    <div class="col-2">
                      <img *ngIf="featureImg" [src]="featureImg" style="width: 80%" />
                      <span [ngStyle]="{
                                        'background-color': fetchFeatureTypeColor(
                                          featureTypeToSelect
                                        )
                                      }" style="
                                        height: 4px;
                                        float: left;
                                        width: 20px;
                                        margin-top: 10px;
                                        margin-right: 5px;
                                      " *ngIf="!featureImg"></span>
                    </div>
                  </div>
                </div>

                <div class="pt-2 pb-2">
                  <button *appPermission="permissionEnumList.CAN_CREATE_POINT_ON_MAP;elementShowHide:false;" class="btn btn-primary me-1 btn-sm" (click)="addGroupFeatureType(layer.layerName)">
                    Add
                  </button>
                  <button class="btn btn-primary btn-sm" (click)="cancelContainerFrEditor(layer.layerName)">
                    Exit
                  </button>
                </div>
              </ng-container>
              <!-- || (layer.layerName =='IMPORTED' && !importedLayerFileId) -->
              <ng-container *ngIf="editLayerName === layer.layerName && !isEditLayer && showAddFeatureContainerFrMapEditor && (layer.layerName =='REFERENCE'  || (layer.layerName =='IMPORTED')) ">
                  <div class="pt-2 pb-2">
                      <button class="btn btn-primary btn-sm" (click)="cancelContainerFrEditor(layer.layerName)">
                                 Exit
                      </button>
                </div>
              </ng-container>
              <ng-container *ngIf="
                              !isEditLayer &&
                              editLayerName === 'EXTERNAL'
                            ">
                <div id="dropdownExtCont">
                  <h2>Select external content to add</h2>
                  <div class="row">
                    <div class="col-10 pe-0">
                      <select class="form-select form-select-sm" id="extContDropDown" name="extContDropDown"
                        [(ngModel)]="selectOptionsExternal" (change)="getSelectedExternalContentType()">
                        <option *ngFor="let title of externalContentDropdownData" [value]="title.exernalComponentName">
                          {{ title.alias }}
                        </option>
                      </select>
                    </div>
                    <div class="col-2">
                      <img *ngIf="externalContentIcon" [src]="externalContentIcon" style="width: 80%" />
                    </div>
                  </div>
                </div>
<div class="pt-2 pb-2">
                              <button
                                class="btn btn-primary me-1 btn-sm"
                                (click)="addExtenalContent(layer.layerName)"
                              >
                                Add
                              </button>
                              <button
                                class="btn btn-primary btn-sm"
                                (click)="cancelContainerFrEditor(layer.layerName)"
                              >
                                Exit
                              </button>
                            </div>
              </ng-container>
              <!-- Editing dropdown code end -->
              <!-- fetchFeatureGroupArray(layer).length -->
              <ng-container *ngIf="layer.layerName !== 'IMPORTED' && isGroupMapCompleted && layer.groups.length > 0; else onlyReference">
                <div
                  class="accordion-item custom-accordion-item feature-group-container {{ group?.groupName?.replace(' ', '') }}"
                  *ngFor="
                    let group of layer.groups;
                    let i = index
                  "
                >
                  <!-- *ngIf="isGroupData(layer.layerName, group.groupName)" -->
                  <ng-container *ngIf="group?.types?.length || group?.features?.length">
                    <h2 class="accordion-header" [id]="getFinalGrp(group.groupName)">
                      <button
                        class="accordion-button collapsed"
                        [id]="'btn' +getFinalGrp(group.groupName)"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#' + getFinalGrp(group.groupName) + '-content' + layer.layerName"
                        aria-expanded="false"
                        [attr.aria-controls]="getFinalGrp(group.groupName) + '-content' + layer.layerName"
                        (click)="group.isGroupExpanded = !group.isGroupExpanded"
                      >
                      <span  *ngIf="group.groupName !== 'MATTERPORT'" [ngClass]="{'text-primary':!isEditLayer && editSelectedFeature?.properties?.featureGroup === group.groupName}">
                        {{ group.groupName }}
                      </span>
                      <span  *ngIf="group.groupName === 'MATTERPORT'" [ngClass]="{'text-primary':!isEditLayer && editSelectedFeature?.properties?.featureGroup === group.groupName}">
                        VIRTUAL TOUR
                      </span>
                      </button>
                      <span class="sub-page-menu-action">

                        <span class="history-icon" *ngIf="isEditLayer && layer.layerName !== 'SITE'">
                          <i *ngIf="loginUserRole.toLowerCase() === 'client' && layer.layerName === 'CLIENT' " class="fa fa-share-square"
                            (click)="featureGroupHistoryClick(layer, group)"></i>
                          <i *ngIf="loginUserRole.toLowerCase() !== 'client' && layer.layerName === 'GPRS' " class="fa fa-share-square"
                            (click)="featureGroupHistoryClick(layer, group)"></i>
                        </span>
                        <!-- (click)="toggleGroup(group, layer);toggleSiteGroup(group, layer)"
                        [ngClass]="
                          checkEyeGroupVisible(group, layer)
                            ? 'eye-visible'
                            : 'eye-lash'
                        " -->
                        <!-- ;toggleSiteGroup(group?.groupName, layer.layerName) -->
                        <i *ngIf="isEditLayer"
                          class="group-{{ group.groupName }} inner-group feature-group {{ group?.groupName?.replace(' ', '') }} feature-group-margin"
                          attr.data-name="{{ group.groupName }}"
                          (click)="testToggleFeatureGroup(group, layer)"
                          [ngClass]="
                            group.isEyeVisible
                              ? 'eye-visible'
                              : 'eye-lash'
                          "></i>
                        <i *ngIf="!isEditLayer"
                          class="map-delete-icon"
                          (click)="deleteFeatureGroup(layer, group)"
                        ></i>
                        <!-- (click)="deleteFeatureGroup(layer,group)" -->
                      </span>
                    </h2>
                    <div
                      [id]="getFinalGrp(group.groupName) + '-content' + layer.layerName"
                      class="accordion-collapse collapse"
                      [attr.aria-labelledby]="getFinalGrp(group.groupName)"
                    >
                    <!-- #region when feature type available -->
                    <!-- *ngIf="fetchFeatureTypeArray(
                      group,
                      layer
                    ) && fetchFeatureTypeArray(
                      group,
                      layer
                    ).length > 0 && fetchFeatureTypeArray(
                      group,
                      layer
                    )[0]; else onlyFeature"> -->
                    <!-- *ngFor="
                    let featureType of fetchFeatureTypeArray(
                      group,
                      layer
                    );
                    let j = index
                  " -->
                      <div class="accordion-body custom-accordion-body" *ngIf="group.isGroupExpanded && group.types.length && group.groupName != 'PHOTO'; else onlyFeature">

                        <div
                          class="accordion-item custom-accordion-item feature-type-container"
                          *ngFor="
                          let featureType of group.types;
                            let j = index
                          "
                          [ngClass]="removeAllSpaceAndSpecialChar(featureType.typeName)"
                        >
                          <h2
                            class="accordion-header"
                            id="{{ featureType.typeName }}-{{ j }}"
                          >
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              [id]="'btn'+featureType.typeName"
                              data-bs-toggle="collapse"
                              [attr.data-bs-target]="
                                '#collapse-' + j + '-content' + layer.layerName + '-' + getFinalGrp(featureType.typeName)
                              "
                              aria-expanded="false"
                              [attr.aria-controls]="getFinalGrp(featureType.typeName) + '-' + j + '-content' + layer.layerName"
                              (click)="featureType.isTypeExpanded = !featureType.isTypeExpanded"
                            >
                              <span [id]="'spn'+getFinalGrp(featureType.typeName)"
                              [ngClass]="{'text-primary':!isEditLayer && editSelectedFeature?.properties?.featureType === featureType.typeName}">
                                {{ featureType.typeName }}
                              </span>
                            </button>
                            <span
                              class="sub-page-menu-action">

                              <span class="history-icon" *ngIf="isEditLayer && layer.layerName !== 'SITE'">
                                <i *ngIf="loginUserRole.toLowerCase() === 'client' && layer.layerName === 'CLIENT' " class="fa fa-share-square"
                                (click)="featureTypeHistoryClick(layer, group, featureType)"></i>
                                <i *ngIf="loginUserRole.toLowerCase() !== 'client' && layer.layerName === 'GPRS' " class="fa fa-share-square"
                                (click)="featureTypeHistoryClick(layer, group, featureType)"></i>
                              </span>
                              <!-- [ngClass]="
                              checkEyeFeatureTypeVisible(
                                layer,
                                group,
                                featureType
                              )
                                ? 'eye-visible'
                                : 'eye-lash'
                            "
                            (click)="
                              toggleFeatureType(group, featureType, layer); toggleSiteType(group,featureType,layer)
                            " -->
                              <i *ngIf="isEditLayer"
                                class="group-{{ featureType.typeName }} inner-group feature-type {{ featureType.typeName }} feature-group-margin"
                                attr.data-name="{{ featureType.typeName }}"
                                (click)="testToggleFeatureType(featureType, layer, group)"
                                [ngClass]="
                                featureType.isEyeVisible
                                  ? 'eye-visible'
                                  : 'eye-lash'
                              "
                              ></i>
                              <i *ngIf="!isEditLayer && editSelectedFeature?.properties?.featureType != featureType.typeName"
                                class="map-delete-icon"
                                (click)="
                                  deleteFeatureType(layer, group, featureType)
                                "
                              ></i>
                            </span>
                            <!-- hiding it as need to be discuss how to map feature group with jobid -->
                            <a
                              class="savefeaturebtn"
                              [ngClass]="{disabled: (firstSecondLineFeatureFrMergedArr && firstSecondLineFeatureFrMergedArr.length > 0) || (!isFirstEditDetected && editSelectedFeature), active: (firstSecondLineFeatureFrMergedArr && firstSecondLineFeatureFrMergedArr.length == 0) || (isFirstEditDetected && editSelectedFeature)}"
                              *ngIf="!isEditLayer && featureTypeForBtnVisible === featureType.typeName && editSelectedFeature?.properties?.featureType === featureType.typeName"
                              (click)="saveFeaturesByFeatureType(group.groupName, featureType.typeName, layer.layerName)"
                              data-bs-toggle="modal" data-bs-target="#mapJobIdModal"
                              >Save</a
                            >

                          </h2>
                          <div
                            id="collapse-{{j}}-content{{layer.layerName}}-{{getFinalGrp(featureType.typeName)}}"
                            class="accordion-collapse collapse"
                            [attr.aria-labelledby]="'featureType-' + j"
                          >
                          <!-- *ngIf="
                                fetchFeaturesArray(group, featureType, layer)[0][
                                  'featureName'
                                ]
                              " -->
                              <!-- *ngFor="
                              let feature of fetchFeaturesArray(
                                group,
                                featureType,
                                layer
                              )
                            " -->
                            <div
                              class="accordion-body custom-accordion-body"
                              *ngIf="featureType.isTypeExpanded && featureType.features.length > 0"
                            >
                              <ul [id]="'featureType-' + j">
                                <li
                                *ngFor="
                                let feature of featureType.features"
                                  class="position-relative"
                                >
                                  <img
                                    [src]="
                                      '../../assets/images/GPRS_PNG/' +
                                      featureType.typeName +
                                      '.png'
                                    "
                                    onerror="this.src='../../assets/images/GPRS_PNG/GENERIC_BRIAN.png';"
                                    *ngIf="feature?.type?.toLowerCase() == 'point'"
                                    [alt]="featureType"
                                  />
                                  <span
                                    [ngStyle]="{
                                      'background-color': fetchFeatureTypeColor(
                                        featureType?.typeId
                                      )
                                    }"
                                    style="
                                      height: 4px;
                                      float: left;
                                      width: 20px;
                                      margin-top: 10px;
                                      margin-right: 5px;
                                    "
                                    *ngIf="
                                      feature?.type?.toLowerCase() == 'linestring' || feature?.type?.toLowerCase() == 'line'
                                    "
                                  ></span>
                                  <i class="feature-style" [title]="feature.featureName"
                                  [ngClass]="{'text-primary':!isEditLayer && editSelectedFeature?.properties?.featureName === feature.featureName}">
                                  {{ feature.featureName }} </i>
                                  <span class="sub-page-menu-action">

                                  <span class="history-icon" *ngIf="isEditLayer && layer.layerName !== 'SITE'">
                                    <i *ngIf="loginUserRole.toLowerCase() === 'client' && layer.layerName === 'CLIENT' " class="fa fa-share-square"
                                    (click)="featureHistoryClick(layer, group, featureType, feature)"></i>
                                    <i *ngIf="loginUserRole.toLowerCase() !== 'client' && layer.layerName === 'GPRS' " class="fa fa-share-square"
                                    (click)="featureHistoryClick(layer, group, featureType, feature)"></i>
                                  </span>
                                  <!-- (click)="
                                  toggleFeature(
                                    group,
                                    featureType,
                                    feature.featureName,
                                    layer
                                  )
                                "
                                [ngClass]="
                                  checkEyeFeatureNameVisible(
                                    layer,
                                    group,
                                    featureType,
                                    feature.featureName
                                  )
                                    ? 'eye-visible'
                                    : 'eye-lash'
                                " -->
                                    <i *ngIf="isEditLayer"
                                      class="group-{{
                                        feature.featureName
                                      }} inner-group feature-name {{
                                        feature.featureName
                                      }} feature-group-margin"
                                        attr.data-name="{{ feature.featureName }}"
                                        attr.data-job-id="{{ feature.mapJobId }}"

                                        (click)="testToggleParticularFeature(feature, featureType, group, layer)"
                                        [ngClass]="
                                        feature.isEyeVisible
                                            ? 'eye-visible'
                                            : 'eye-lash'
                                        "
                                    ></i>
                                    <i *ngIf="!isEditLayer && editSelectedFeature?.properties?.featureName != feature.featureName && !feature.featureName?.includes('temp')"
                                      class="map-delete-icon"
                                      (click)="
                                        deleteParticularFeature(layer, group, featureType, feature)"
                                    ></i>
                                  </span>
                                  <span
                                    class="sub-page-menu-action-save"
                                    *ngIf="!isEditLayer && editSelectedFeature?.properties?.featureName === feature.featureName"
                                  >
                                    <i
                                    class="h6 d-inline-block align-middle" title="Undo" *ngIf="!isUndoDisabled(feature)" [ngClass]="{'disabled' : isUndoDisabled(feature)}"
                                    (click)="handleUndo(layer.layerName, feature)"
                                    style="width: 20px;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff">
                                      <path d="M0,0H24V24H0Z" fill="none"/>
                                      <path d="M8.311,11.943a.662.662,0,1,1,0-1.324H14.48a1.958,1.958,0,0,0,1.955-1.956V7.128A1.958,1.958,0,0,0,14.48,5.17H2.228L5.061,7.937a.662.662,0,0,1-.924.947L.317,5.156a.665.665,0,0,1-.229-.9A.661.661,0,0,1,.2,4.113.668.668,0,0,1,.256,4.06L4.022.2a.662.662,0,1,1,.948.924L2.313,3.846H14.48a3.284,3.284,0,0,1,3.28,3.282V8.662a3.284,3.284,0,0,1-3.28,3.281Z" transform="translate(2.984 6.068)"/>
                                    </svg>
                                    </i>
                                    <i
                                    class="h6 d-inline-block align-middle ms-1" title="Redo" *ngIf="!isRedoDisabled(feature)" [ngClass]="{'disabled' : isRedoDisabled(feature)}"
                                    (click)="handleRedo(layer.layerName, feature)"
                                    style="width: 24px;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff">
                                      <path d="M0,0H24V24H0Z" fill="none"/>
                                      <path d="M3.281,11.944A3.284,3.284,0,0,1,0,8.664V7.128A3.285,3.285,0,0,1,3.281,3.847h12.17L12.794,1.124A.662.662,0,1,1,13.741.2l3.765,3.859a.668.668,0,0,1,.059.054.666.666,0,0,1-.132,1.053L13.624,8.883a.662.662,0,1,1-.924-.948L15.533,5.17H3.281A1.96,1.96,0,0,0,1.324,7.128V8.664a1.959,1.959,0,0,0,1.957,1.957h6.17a.662.662,0,1,1,0,1.323Z" transform="translate(2.85 5.931)"/>
                                    </svg>
                                    </i>
                                    <i
                                      class="map-save-icon h6 ms-1"
                                      [ngClass]="(!isUndoDisabled(feature) || feature.featureName?.includes('temp')) ? '' : 'disabled'"
                                      *appPermission="permissionEnumList.CAN_EDIT_JOB_ON_MAP;elementShowHide:false;"
                                      (click)="saveSingleFeature(layer.layerName, feature)"
                                      data-bs-toggle="modal" data-bs-target="#mapJobIdModal"
                                    ></i>
                                    <i
                                      class="map-close-icon h6"
                                      (click)="cancelFeature(layer, group, featureType, feature)"
                                    ></i>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    <!--#end region-->
                    <!-- #region when feature type not available but feature available -->
                    <!-- *ngFor="
                            let feature of fetchFeaturesArray(
                              group.groupName,
                              null,
                              layer.layerName
                            )
                          "  -->
                        <ng-template #onlyFeature>
                          <div *ngIf="layer.layerName !== 'IMPORTED' && group?.isGroupExpanded && (group?.features?.length || group?.types?.length)" class="accordion-body custom-accordion-body">
                            <ul *ngIf="group.groupName != 'PHOTO'">
                          <li *ngFor="let feature of group.features" class="position-relative">

                            <img [src]="
                              '../../assets/images/GPRS_PNG/' +
                              group?.groupName?.replace(' ','') +
                              '.png'
                            " onerror="this.src='../../assets/images/GPRS_PNG/GENERIC_BRIAN.png';"
                            *ngIf="feature?.type?.toLowerCase() == 'point'" />
                              <!-- featureType.typeId  Doubt Rishabh in Backgrount color-->
                            <span [ngStyle]="{
                              'background-color': fetchFeatureTypeColor(
                                1
                              )
                            }" style="
                              height: 4px;
                              float: left;
                              width: 20px;
                              margin-top: 10px;
                              margin-right: 5px;
                            " *ngIf="
                              feature?.type?.toLowerCase() == 'linestring' || feature?.type?.toLowerCase() == 'line'
                            "></span>
                            <i class="feature-style"
                              [ngClass]="{'text-primary':!isEditLayer && editSelectedFeature?.properties?.featureName === feature.featureName}">{{
                              feature.featureName }}</i>
                              <!-- (click)="_testToggleParticularFeature(feature, 'null', group, layer)" -->
                            <span class="sub-page-menu-action">
                              <i *ngIf="isEditLayer" class="group-{{
                                feature.featureName
                              }} inner-group feature-name feature-group-margin"
                              attr.data-name="{{ feature.featureName }}"
                              attr.data-job-id="{{ feature.mapJobId }}"
                              (click)="testToggleParticularFeatureExternal(feature, group, layer)"
                              [ngClass]="
                              feature.isEyeVisible
                                  ? 'eye-visible'
                                  : 'eye-lash'
                              "></i>
                              <!-- (click)="
                                toggleFeature(
                                  group.groupName,
                                  null,
                                  feature.featureName,
                                  layer.layerName
                                )
                              " [ngClass]="
                                checkEyeFeatureNameVisible(
                                  layer.layerName,
                                  group.groupName,
                                  null,
                                  feature.featureName
                                )
                                  ? 'eye-visible'
                                  : 'eye-lash'
                              " -->
                              <i *ngIf="isEditingModeIsOn && editSelectedFeature?.properties?.featureName !== feature.featureName" class="map-info-icon" (click)="saveExternalLayerEditInfo(layer.layerName, feature)"></i>

                              <i *ngIf="isEditingModeIsOn && editSelectedFeature?.properties?.featureName !== feature.featureName" class="map-delete-icon" (click)="
                                deleteParticularFeature(layer, group, null, feature)"></i>
                            </span>
                            <span class="sub-page-menu-action-save"
                              *ngIf="!isEditLayer && editSelectedFeature?.properties?.featureName === feature.featureName">
                              <i class="map-save-icon h6" (click)="saveExternalLayerEdit(layer.layerName, feature)"></i>
                              <i class="map-close-icon h6" (click)="cancelFeature(layer, group, null, feature)"></i>
                            </span>
                          </li>
                        </ul>

                        <!-- PAW: PHOTOS do not have a feature list like other features.  it is contained within the types array. -->
                        <ul *ngIf="group.groupName == 'PHOTO'">
                          <li *ngFor="let feature of group.types[0].features" class="position-relative">

                            <img [src]="
                              '../../assets/images/GPRS_PNG/' +
                              group?.groupName?.replace(' ','') +
                              '.png'
                            " onerror="this.src='../../assets/images/GPRS_PNG/GENERIC_BRIAN.png';"
                            *ngIf="feature?.type?.toLowerCase() == 'point'" />
                              <!-- featureType.typeId  Doubt Rishabh in Backgrount color-->
                            <span [ngStyle]="{
                              'background-color': fetchFeatureTypeColor(
                                1
                              )
                            }" style="
                              height: 4px;
                              float: left;
                              width: 20px;
                              margin-top: 10px;
                              margin-right: 5px;
                            " *ngIf="
                              feature?.type?.toLowerCase() == 'linestring' || feature?.type?.toLowerCase() == 'line'
                            "></span>
                            <i class="feature-style"
                              [ngClass]="{'text-primary':!isEditLayer && editSelectedFeature?.properties?.featureName === feature.featureName}">{{
                              feature.featureName }}</i>
                              <!-- (click)="_testToggleParticularFeature(feature, 'null', group, layer)" -->
                            <span class="sub-page-menu-action">
                              <i *ngIf="isEditLayer" class="group-{{
                                feature.featureName
                              }} inner-group feature-name feature-group-margin"
                              attr.data-name="{{ feature.featureName }}"
                              attr.data-job-id="{{ feature.mapJobId }}"
                              (click)="photoToggleParticularFeature(feature, group, layer)"

                              [ngClass]="
                              feature.isEyeVisible
                                  ? 'eye-visible'
                                  : 'eye-lash'
                              "></i> <!-- (click)="testToggleParticularFeatureExternal(feature, group, layer)" -->
                              <!-- (click)="
                                toggleFeature(
                                  group.groupName,
                                  null,
                                  feature.featureName,
                                  layer.layerName
                                )
                              " [ngClass]="
                                checkEyeFeatureNameVisible(
                                  layer.layerName,
                                  group.groupName,
                                  null,
                                  feature.featureName
                                )
                                  ? 'eye-visible'
                                  : 'eye-lash'
                              " -->
                              <i *ngIf="isEditingModeIsOn && editSelectedFeature?.properties?.featureName !== feature.featureName" class="map-info-icon" (click)="saveExternalLayerEditInfo(layer.layerName, feature)"></i>

                              <i *ngIf="isEditingModeIsOn && editSelectedFeature?.properties?.featureName !== feature.featureName" class="map-delete-icon" (click)="
                                deleteParticularFeature(layer, group, null, feature)"></i>
                            </span>
                            <span class="sub-page-menu-action-save"
                              *ngIf="!isEditLayer && editSelectedFeature?.properties?.featureName === feature.featureName">
                              <i class="map-save-icon h6" (click)="saveExternalLayerEdit(layer.layerName, feature)"></i>
                              <i class="map-close-icon h6" (click)="cancelFeature(layer, group, null, feature)"></i>
                            </span>
                          </li>
                        </ul>

                      </div>
                    </ng-template>
                    <!-- #endregion -->
                    </div>
                  </ng-container>
                </div>
              </ng-container>

              <app-feature-tree
                *ngIf="layer.layerName === 'IMPORTED'"
                [layer]="layer"
                [featureTypeStyles]="featureTypeStyleMap"
                [rasterImages]="geoReferenceGISImagesData"
                [events]="layerEvents"
                [edit]="isEditingModeIsOn && editLayerName == layer.layerName"
                (onDeleteFile)="onDeleteFile(layer, $event)"
                (onDeleteGroup)="onDeleteGroup(layer, $event)"
                (onDeleteType)="onDeleteType(layer, $event)"
                (onDeleteFeature)="onDeleteFeature(layer, $event)"
                (onToggleVisibility)="onToggleLayerGroup(layer.layerId, $event)"
                (onToggleRasterImage)="toggleGeoReferenceLayerImportedFeature($event)"
                (onRasterImageOpacityChange)="visibilityReferenceImages($event)"
                (onEditRasterImage)="editGeoReferenceImageLayer($event)"
                (onDeleteRasterImage)="deleteRasterItem($event)"
                ></app-feature-tree>

                <ng-template #onlyReference>
                  <div class="accordion-body custom-accordion-body" *ngIf="referenceGISTilesData.length > 0 && layer.layerName === 'REFERENCE' ">
                   <app-map-layer-menu-item *ngFor="let item of referenceLayerItems" [itemData]="item"
                   (onToggleItem)="toggleReferenceLayerItem($event)"
                   (onDeleteItem)="deleteReferenceLayerItem($event)"
                   [editMode]="isEditingModeIsOn && editLayerName === 'REFERENCE'"
                   ></app-map-layer-menu-item>
                </div>
              </ng-template>
            </div>
          </ng-container>
        </div>
      </ng-container>

    </div>
  </div>
</div>
<ng-template #noJobInLayer>
  <div *ngIf="isDataLoaded">
    <p class="documents-msg">No Job Associated with this site</p>
  </div>
</ng-template>
<!-- Slide Menu Ends -->


<!-- save feature ==> map jobid to feature dialog code start -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#mapJobIdModal" hidden id="mapJobAndWOContent" aria-labelledby="mapJobAndWOContent"></button>
<div class="modal fade " id="mapJobIdModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="mapJobIdModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add/Edit Feature</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="feature-job-select" class="col-form-label">Select Job<span class="custom-form-label-star">*</span></label>
              <select id="feature-job-select" class="form-select" aria-label="search job" (change)="onSelectJobChange($event, 1)">
                <option hidden value="default" disabled selected>Select Job</option>
                <option *ngFor="let job of jobDetailsForGPRS" [value]="job.jobId">
                  {{ job.jobName }}
                </option>
              </select>
            </div>
             <!-- workorder no -->
          <div class="mb-3" class="visibleWANUmber">
            <label for="associated-wo-external" class="col-form-label">Associated WO Number</label>
            <input disabled type="text" class="form-control" id="associated-wo-feature" [value]="selectedJob?.workOrderNumber">
          </div>
            <!-- <div class="col-lg-12" *ngIf="!importJobModalEnable"> -->
            <div *ngIf="!isFromCommitChange" class="col-lg-12" >
              <h5> {{ temporaryAddedFeature?.featureName }}</h5>
              <div>Time : {{temporaryAddedFeature?.time}}</div>
              <div>Latitude : <span *ngIf="newFeaturesLat">{{newFeaturesLat }} &deg; </span> </div>
              <div>Longitude : <span *ngIf="newFeaturesLng">{{newFeaturesLng }} &deg; </span>  </div>
              <div>Feature Type: : {{temporaryAddedFeature?.featureType}}</div>
              <div>New Point ID : {{temporaryAddedFeature?.featureId}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"  (click)="onCancelFeatureSave()">Cancel</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" [disabled]="!selectedJob?.jobId" (click)="onSaveFeatureClick()">Save</button>
      </div>
    </div>
  </div>
</div>
<!-- save feature ==> map jobid to feature dialog code end -->


<!-- Imported Modal -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#importFile" hidden id="btnModalImport" aria-labelledby="btnModalImportt"></button>
<div
  class="modal fade"
  id="importFile"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="importFileLabel"
  aria-hidden="true"
>

 <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <!-- <app-map-import [importJobId]="navigatedJobId"></app-map-import> -->
      <div class="modal-header">
        <h5 class="modal-title" id="importFileLabel">Import File</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="importMajorCancel()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="input-group mb-3">
              <input #myImportFile
                type="file" (change)="onFileSelected('importfile',$event)"
                class="form-control form-control-sm"
                id="inputGroupFile02"
                [accept]="fileExtensions"
              />
              <label class="input-group-text" for="inputGroupFile02" hidden
                >Upload</label
              >
            </div>
          </div>
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="fileImportType" class="form-label fs-12"
                >File Type
                </label>
              <input
              type="text" disabled
              class="form-control form-control-sm"
              id="fileImportType" value="{{importedFileType}}"
              placeholder="File Type"
            />
            </div>
          </div>
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="importFileName" class="form-label fs-12">File Name</label>
              <input
                type="text" disabled
                class="form-control form-control-sm"
                id="importFileName"
                placeholder="File name" value="{{importedFileName}}"
              />
            </div>
          </div>
          <div *ngIf="isImportingReferenceLayer" class="col-lg-12">
            <div class="mb-3">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="importedIsPublic"
                  id="light-mode" [value]="false" [(ngModel)]="importedIsPublic" >
                <label class="form-check-label" for="light-mode">GPRS Only
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="importedIsPublic"
                  id="dark-mode" [value]="true" [(ngModel)]="importedIsPublic">
                <label class="form-check-label" for="dark-mode">Public</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal" id="cancelImport">
          Cancel
        </button>

        <button type="button" class="btn btn-sm btn-primary" [disabled]="!importSubmitted" (click)="checkImportFileType()">Next</button>
      </div>
    </div>
  </div>
</div>
<!-- <button type="button" class="btn btn-sm btn-primary" [disabled]="!importSubmitted" (click)="publishUploadFile()">Import</button>  -->

<!--Add external Content Modal -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#externalContentModal" hidden id="btnExternalContent" aria-labelledby="btnExternalContent"></button>
<div class="modal fade" id="externalContentModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="externalContentModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">{{externalLayerAddEdit}} {{modalExternalAddHeader}}</h1>
        <button type="button" class="btn-close" id="closeExtModal" (click)="onExternalContentCancel()" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="externalContentForm" (ngSubmit)="onExternalContentSave(externalContentForm,'fromModal')">
          <!-- Add link -->
          <div *ngIf="((modalExternalAddHeader | lowercase) != 'pointcloud'  && (modalExternalAddHeader | lowercase) != 'virtual tour')">
            <div class="mb-3">
              <label for="external-link" class="col-form-label">Add Link <span *ngIf="((modalExternalAddHeader | lowercase) != 'pointcloud'  && (modalExternalAddHeader | lowercase) != 'virtual tour')"
                  class="custom-form-label-star">*</span></label>
              <input type="text" class="form-control" id="external-link" formControlName="linkExternal">
            </div>
            <div class="text-danger"
              *ngIf="externalContentForm.get('linkExternal')?.invalid && (externalContentForm.get('linkExternal')?.dirty || externalContentForm.get('linkExternal')?.touched) && externalContentForm.get('linkExternal')?.errors?.required">
              *Please provide a link.
            </div>
            <div class="text-danger" *ngIf="
                          (externalContentForm.get('linkExternal')?.dirty ||
                            externalContentForm.get('linkExternal')?.touched) &&
                          externalContentForm.get('linkExternal')?.errors?.pattern
                        ">
              *Please provide a valid link .
            </div>
          </div>
          <!-- display name -->
          <div class="mb-3">
            <label for="display-name" class="col-form-label">Display Name<span class="custom-form-label-star">*</span></label>
            <input type="text" class="form-control" id="display-name" formControlName="displayName" >
          </div>
          <div class="text-danger" *ngIf="externalContentForm.get('displayName')?.invalid && (externalContentForm.get('displayName')?.dirty || externalContentForm.get('displayName')?.touched) && externalContentForm.get('displayName')?.errors?.required">
            *Please provide a display name.
          </div>
          <div class="text-danger" *ngIf="externalContentForm.get('displayName')?.invalid && (externalContentForm.get('displayName')?.dirty || externalContentForm.get('displayName')?.touched)
          && externalContentForm.get('displayName')?.errors?.maxlength">
            *Display name should be of maximum 30 Characters .
          </div>

          <!-- Add multiple links for matterport (virtual tour)-->
            <div *ngIf="(modalExternalAddHeader | lowercase) == 'virtual tour'" class="modal-footer">
              <!-- show VT LINKS  -->            
              <app-common-table   [tableHeader]="matterportTableHeader"
                [tableData]="matterportLinkArray"
                (buttonClickEmitter)="btnDeleteVTLinkUrl($event)"
                idColumnName="linkURL"
                tblClass="table-striped table-colored-virtual-tour table-bordered custom-table table-colored">
              </app-common-table>
              <button type="button" class="btn btn-primary" (click)="addVTRow()">Add Row</button>
            </div>

          <!-- select Job -->
          <div class="mb-3">
            <label for="select-job" class="col-form-label">Select Job<span class="custom-form-label-star">*</span></label>
            <select id="select-job" (change)="onSelectJobChange($event, 1)"
            formControlName="job"  class="form-select" aria-label="search job">
              <option hidden value="" disabled selected>Select Job</option>
              <option *ngFor="let job of jobDetailsForGPRS" [ngValue]="job">
                {{ job.jobName }}
              </option>
            </select>
          </div>
          <div class="text-danger" *ngIf="externalContentForm.get('job')?.invalid && (externalContentForm.get('job')?.dirty || externalContentForm.get('job')?.touched) && externalContentForm.get('job')?.errors?.required">
            *Please select a job name.
          </div>

          <!-- workorder no -->
          <div class="visibleWANUmber">
          <div class="mb-3">
            <label for="associated-wo-external" class="col-form-label">Associated WO Number</label>
            <input type="text" class="form-control" id="associated-wo-external" formControlName="WANumber">
          </div>
          <div class="text-danger" *ngIf="externalContentForm.get('WANumber')?.invalid && (externalContentForm.get('WANumber')?.dirty || externalContentForm.get('WANumber')?.touched)
          && externalContentForm.get('WANumber')?.errors?.maxlength">
            *WO Number should be of maximum 30 Characters .
          </div>
          <div class="text-danger" *ngIf="(externalContentForm.get('WANumber')?.dirty || externalContentForm.get('WANumber')?.touched)
          && externalContentForm.get('WANumber')?.errors?.pattern">
            *WO Number contains only alphanumeric characters .
          </div>
          </div>
          <div class="mb-3" *ngIf="(modalExternalAddHeader | lowercase) == 'pointcloud'">
            <label for="external-file" class="col-form-label">Add File</label>
            <input type="file" class="form-control" id="external-file" (change)="onFileSelected('pointcloud',$event)" formControlName="fileExternal" >
          </div>
          <div class="modal-footer">
            <button type="button submit" class="btn btn-primary" [disabled]="externalContentForm.invalid">Save</button>
            <button type="button" class="btn btn-primary" (click)="onExternalContentCancel()" data-bs-dismiss="modal">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>



<!--External Link Modal -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#featureExternalViewer" hidden id="btnModalExtenal" aria-labelledby="btnModalExternal"></button>
<div class="modal fade matterport-video" id="featureExternalViewer" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="featureExternalViewerLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl custom-modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="featureExternalViewerLabel" ><span>{{modalHeader}}</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ng-container>
          <div class="embed-responsive embed-responsive-16by9 matterport-frame">
            <iframe *ngIf="iframeExternalContentLink"
            class="embed-responsive-item"
            id="externalContentLink"
          ></iframe>
          <p *ngIf="!iframeExternalContentLink" class="noPreviewText">No Preview link available</p>

          </div>
           <!-- <iframe width='100%' height='100%' id="externalContentLink" frameborder='0' allowfullscreen allow='xr-spatial-tracking'>{{externalContentTemplateData.featureName}}</iframe> -->
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!-- Imported Points & Lines Modal -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#importPointsLines" hidden id="btnimportPointsLines" aria-labelledby="btnimportPointsLines"></button>
<div class="modal fade" id="importPointsLines" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="importPointsLinesLabel" aria-hidden="true">
  <form [formGroup]="importGISForm">
    <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="importPointsLinesLabel" *ngIf="!checkImportFileTypeRaster">Import Points & Lines
          </h5>
          <h5 class="modal-title" id="importPointsLinesLabel" *ngIf="checkImportFileTypeRaster">Import Imagery</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="importMajorCancel()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <p class="fs-12" *ngIf="!checkImportFileTypeRaster">Are you importing GIS job-related data that you
                exported from SiteMap, or custom job-related data? Or would you like to import reference GIS data, e.g.
                boundaries or points of interest, etc?</p>
              <p class="fs-12" *ngIf="checkImportFileTypeRaster">Are you importing geo-referenced imagery - this is
                imagery that has spatial information and can be overlayed onto a map? Or are you importing
                un-georeferenced imagery that you want to manually geo-reference now?</p>
            </div>
            <div class="col-lg-12">
              <div *ngIf="!checkImportFileTypeRaster">
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="importSelectFormControl"
                    value="importDataGIS" (change)="changeImportRadio($event)">
                  <label class="form-check-label fs-12" for="GISDataExportedfromSiteMap">
                    Import GIS job-related data that was exported from SiteMap
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="importSelectFormControl"
                    value="customGIS" (change)="changeImportRadio($event)">
                  <label class="form-check-label fs-12" for="customGISDataRadio">
                    Import Custom GIS job-related data
                  </label>
                </div>
                <!-- <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="importSelectFormControl"
                    value="referenceGIS" (change)="changeImportRadio($event)">
                  <label class="form-check-label fs-12" for="customGISDataRadio">
                    Import reference GIS data (boundaries, points of interest, etc.)
                  </label>
                </div> -->
              </div>
              <div *ngIf="checkImportFileTypeRaster">
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="importSelectFormControl"
                    value="importGeoReferenceImage" (change)="changeImportRadio($event)">
                  <label class="form-check-label fs-12" for="GISDataExportedfromSiteMap">
                    Geo-referenced imagery
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="importSelectFormControl"
                    value="importGeoReferenceImageManually" (change)="changeImportRadio($event)">
                  <label class="form-check-label fs-12" for="customGISDataRadio">
                    Imagery that needs to be manually geo-referenced
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal" id="cancelImportGIS">
            Cancel
          </button>
          <button type="button" class="btn btn-sm btn-primary" *ngIf="isSubmitButtonVisible"
            (click)="publishUploadFile('withoutCustom')">Submit</button>
          <button type="button" class="btn btn-sm btn-primary" *ngIf="isSubmitButtonVisibleGISReference"
            (click)="publishUploadFile('withoutCustomGISReference')">Submit</button>
          <button type="button" class="btn btn-sm btn-primary" *ngIf="isSelectButtonVisible" data-bs-toggle="modal"
            (click)="publishUploadFile('Custom')">Select</button>
          <button type="button" class="btn btn-sm btn-primary" *ngIf="isSubmitNonGeoReferenceImage"
            (click)="loadingNonGeoReferenceImage()">Submit</button>
          <!-- data-bs-target="#customGISData" -->
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Custom GIS Data Modal -->
<div class="modal fade" id="customGISData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="customGISDataLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="customGISDataLabel">Custom GIS Data</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <label for="ctfi" class="form-label fs-12">Choose the type of feature you'd like to import</label>
            <select class="form-select form-select-sm mb-3" aria-label=".form-select-sm example" id="ctfi">
              <option value="1">Regular Point</option>
              <option value="2">Annotation Point</option>
              <option value="3">Regular Line</option>
              <option value="4">Annotation Line</option>
            </select>
          </div>
          <div class="col-lg-12">
            <label for="ctcfg" class="form-label fs-12">Choose the column in your that corresponds to the
              <strong>Feature Group</strong> column <br />(based on industry standards).</label>
            <select class="form-select form-select-sm mb-3" aria-label=".form-select-sm example" id="ctcfg"
              [(ngModel)]="selectImportFeatureGroup">
              <option *ngFor="let item of customImportFeaturesGroup" [value]="item">
                {{item}}
              </option>
            </select>
          </div>

          <div class="col-lg-12">
            <label for="ctcftc" class="form-label fs-12">Choose the column in your that corresponds to the
              <strong>Feature Type</strong> column <br />(based on industry standards).</label>
            <select class="form-select form-select-sm" aria-label=".form-select-sm example" id="ctcftc"
              [(ngModel)]="selectImportFeatureType">
              <option *ngFor="let item of customImportFeaturesType" [value]="item">
                {{item}}
              </option>
            </select>
          </div>

          <div class="col-lg-12">
            <hr>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <p class="fs-12 mb-2">Map value for <strong>Feature Group</strong> from your data to industry standard
              values</p>
          </div>
          <div class="col-lg-6">
            <label for="fgsv" class="visually-hidden">fgsv</label>
            <input class="form-control form-control-sm" type="text" placeholder="..."
              aria-label=".form-control-sm example" id="fgsv">
          </div>
          <div class="col-lg-6">
            <label for="ftsv" class="visually-hidden">ftsv</label>
            <select class="form-select form-select-sm mb-3" aria-label=".form-select-sm example" id="ftsv">
              <option value="1">COMMUNICATION</option>
              <option value="2">ELECTRICAL</option>
              <option value="3">FIREWA</option>
              <option value="4">GAS</option>
              <option value="5">IRRIGATION</option>
              <option value="6">MISC</option>
              <option value="7">PHOTO</option>
              <option value="8">PRODUCT</option>
              <option value="9">SANITARY</option>
              <option value="10">SCAN INFO</option>
              <option value="11">STROM</option>
              <option value="12">TRAFFIC</option>
              <option value="13">UNKNOWN</option>
              <option value="14">WATER</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <p class="fs-12 mb-2">Map value for <strong>Feature Type</strong> from your data to industry standard values
            </p>
          </div>
          <div class="col-lg-6">
            <label for="fttsv" class="visually-hidden">fttsv</label>
            <input class="form-control form-control-sm" type="text" placeholder="..."
              aria-label=".form-control-sm example" id="fttsv">
          </div>
          <div class="col-lg-6">
            <label for="fttysv" class="visually-hidden">fttysv</label>
            <select class="form-select form-select-sm mb-3" aria-label=".form-select-sm example" id="fttysv">
              <option value="1">COMM_BOX</option>
              <option value="2">COMM_CAMERA</option>
              <option value="3">COMM_CONTINUES</option>
              <option value="4">COMM_EOI</option>
              <option value="5">COMM_MANHOLE</option>
              <option value="6">COMM_MISC</option>
              <option value="7">COMM_PEDESTAL</option>
              <option value="8">COMM_POLE</option>
              <option value="9">COMM_SNAPPOINT</option>
              <option value="10">COMM_VAULT</option>
              <option value="11">COMMUNICATION LINE</option>
              <option value="12">COMMUNICATION LINE DUCT</option>
              <option value="13">COMMUNICATION STRUCTURE OUTLINE</option>
            </select>
          </div>
        </div>


      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button type="button" class="btn btn-sm btn-primary" (click)="importCustomFormData()">Submit</button>
      </div>
    </div>
  </div>
</div>

<!-- Custom CAD Data Modal -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#customCADData" hidden id="btnCustomCADData"
  aria-labelledby="btnCustomCADData"></button>
<div class="modal fade" id="customCADData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="customCADDataLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="customGISDataLabel">Import CAD Data</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="cadCustomForm" (ngSubmit)="onCADCustomSubmit()" class='mr-4'>

          <!-- ///////////////////////////////// -->
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <p class="custom-text-small mb-0">
                Find information about your CAD data being imported below.
                Please, select the correct coordinate system that the data is drawn in.
                Data that is not tied to a coordinate system will not be imported to the correct location
                and may require manual georeferencing in CAD prior to import. Also included is
                information about the layers in your data that we could not map directly to our
                National CAD Standard Layer or GPRS Standard Layer.
                Please, manually select the correct group and layer
                as the items will need to be mapped to a layer in order for the file to import.
              </p>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <hr />
            </div>
            <div class="col-lg-6 col-md-10 col-sm-10">
              <div class="mb-3">
                <label for="coordinateRS"
                  class="form-label custom-form-label-small position-relative width-full">Coordinate Reference System
                  <div class="customToolTip">
                    <div class="customToolTipContent">
                      Coordinate Reference System
                    </div>
                  </div>
                </label>
                <select class="form-select form-select-sm mb-3" aria-label=".form-select-sm example" id="coordinateCRS"
                  formControlName="coordinateCRSName">
                  <option *ngFor="let itemCRS of crsDropDownData" [ngValue]="itemCRS">
                    {{itemCRS.description}} ({{itemCRS.auth_name}} : {{itemCRS.auth_id}} )
                  </option>
                </select>

                <!-- <span class="map-info-icon set-info"></span> -->
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <p class="custom-text-small mb-0">
                <strong>
                  Your layers to Feature Groups and Feature Types
                </strong>
              </p>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <hr />
            </div>
          </div>
          <div class="row" formArrayName="cadFeatures">
            <div class="col-lg-12 col-md-12 col-sm-12"
              *ngFor="let cadFeature of cadFeatures().controls; let cadIndex = index">
              <div class="row label-hide" [formGroupName]="cadIndex">
                <div class="col-lg-6 col-md-4 col-sm-4">
                  <div class="mb-3">
                    <!-- {{cadIndex}} -->
                    <label for="'staticEmail'+cadIndex" class="form-label custom-form-label-small"
                      [ngClass]="cadIndex == 0 ? 'show' : 'hide'">
                      Original Layer Name
                    </label>
                    <input type="text" class="form-control form-control-bold" id="'staticEmail'+cadIndex"
                      formControlName="featureName">
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-4 featureGroupNotRepeat">
                  <div class="mb-3">
                    <label for="'featureGroupModal'+cadIndex" class="form-label custom-form-label-small"
                      [ngClass]="cadIndex == 0 ? 'show' : 'hide'">
                      <div class="customToolTip feature-group-hint">
                        <div class="customToolTipContent feature-group-hint">
                          <em>INSTRUCTIONS</em><br>
                          Begin typing a value to map a layer to a "Feature Group." After typing two letters, a list will appear. Choose the correct value from the list. For example, to map a layer as ELECTRICAL , type "EL" and then choose the value from the list. To map a layer to C-BLDG, type "C-" and then choose the value from the list.<br><br>
                          <em>Common GPRS Feature Groups</em><br>
                          GAS<br>
                          ELECTRICAL<br>
                          WATER<br>
                          <br>
                          <em>Common CAD Standard Feature Groups</em><br>
                          A-DOOR<br>
                          C-BLDG<br>
                          E-SITE<br>
                        </div>
                      </div>
                      Feature Group
                    </label>
                    <input type="text" class="form-control form-control-sm" id="'featureGroupModal'+cadIndex"
                      placeholder="Feature Group" [attr.list]="'datalistOptions' + cadIndex"
                      formControlName="featureGroup" (keyup)="searchFeatureGroup(cadIndex)">
                  </div>
                  <div *ngIf="importFeatureGroupSelectedIndex  === cadIndex">
                    <ul class="list-showing list-showing-absolute">
                      <li *ngFor="let item of filteredOptionsFeatureGroup">
                        <a (click)="onselectFeatureGroupImport(item,cadIndex)">{{item.mapGroupName}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-4 featureGroupNotRepeat">
                  <div class="mb-3">
                    <label for="'cadFeatureType'+cadIndex" class="form-label custom-form-label-small"
                      [ngClass]="cadIndex == 0 ? 'show' : 'hide'">
                      <div class="customToolTip feature-type-hint">
                        <div class="customToolTipContent feature-type-hint">
                          <em>INSTRUCTIONS</em><br>
                          Begin typing a value to map a layer to a "Feature Type" After typing two letters, a list will appear. Choose the
                          correct value from the list. For example, to map a layer as ELEC_BOX , type "EL" and then choose the value from the list. To map a
                          layer to C-BLDG-DECK, type "C-" and then choose the value from the list.<br><br>
                          <em>Common GPRS Feature Types</em><br>
                          GAS_LINE<br>
                          ELEC_BOX<br>
                          WTR_METER<br>
                          <br>
                          <em>Common CAD Standard Feature Types</em><br>
                          A-DOOR-SIGN<br>
                          C-BLDG-DECK<br>
                          E-SITE-POLE<br>
                        </div>
                      </div>
                      Feature Type
                    </label>
                    <input type="text" class="form-control form-control-sm" id="'cadFeatureType'+cadIndex"
                      placeholder="Feature Type" formControlName="featureType" (keyup)="searchFeatureType(cadIndex)">
                  </div>
                  <div *ngIf="importFeatureTypeSelectedIndex === cadIndex">
                    <ul class="list-showing list-showing-absolute">
                      <li *ngFor="let item of filteredOptionsFeatureType">
                        <a (click)="onselectFeatureTypeImport(item,cadIndex)">{{item.mapFeatureTypeName}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ///////////////////////// -->


          <!-- <button type="button" (click)="addCADFeature('test')">Add Feature</button> -->
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal" id="cancelCustomCADForm">
          Cancel
        </button>
        <button type="button" class="btn btn-sm btn-primary" (click)="onCADCustomSubmit()">Submit</button>
      </div>
    </div>
  </div>
</div>

<!-- Edit NonGeoReferenceImage  -->
<div *ngIf="nonGeoreferenceImgDivActive" id="nonGeoreference" class="map-drag-box" appFreeDragging>
  <div id="nonGeoreferenceImgDivtest">
    <img src="" alt="" id="nonGeoreferenceImg" class="nonGeoImageDynamic">
  </div>
  <div class="progress-control">
    <div class="custom-progess-bar">
      <input type="range" id="nonGeoRef" name="nonGeoRef" min="0" max="100" [(ngModel)]="rangeSliderGeoReferenceImage"
        (oninput)="visibilityNonGeoReferenceImages($event)" (change)="visibilityNonGeoReferenceImages($event)">
    </div>
    <div class="progress-control-btn">
      <button type="button" class="btn btn-sm btn-secondary"
        (click)="cancelNonGeoReferenceImage(editGeoReferenceImageType,rasterImageFeature)">
        Cancel
      </button>
      <button type="button" style="float: right;" class="btn btn-sm btn-primary"
        (click)="saveNonGeoReferenceImage(editGeoReferenceImageType,rasterImageFeature)">
        Save
      </button>
    </div>
  </div>
</div>

<!-- ***************** HISTORY SIDEBAR *********************************** -->

<!-- <div class="side-main-menu hostory right" style="background: white;" [ngClass]=" isHistoryOpend ? 'open' : 'end'"
  *ngIf="isHistoryBtnClicked">
    <app-revision-history *ngIf="isHistoryBtnClicked" [map]="map" [draw]="draw" [loginUserId]="loginUserId" [jobDetailsForGPRS]="jobDetailsForGPRS"
    [isEditingModeIsOn]="isEditingModeIsOn" [featuresForRevision]="featuresForRevision"
    [historyMetaData]="historyMetaData" [isHistoryFeatureClicked]="isHistoryFeatureClicked" [isHistoryTypeClicked]="isHistoryTypeClicked"
    [isHistoryGroupClicked]="isHistoryGroupClicked" (historyOpend)="onHistorySlideoutClose($event)"
    (revertFeatureToRevision)="onRevertFeatureToRevision($event)"></app-revision-history>
</div> -->
<!-- ***************** HISTORY SIDEBAR END *********************************** -->
