import { ICommonTableHeader } from "src/app/modules/sitemapadmin/constants/common-table-data"

export interface IAccessControl
{
    jobId: number,
    jobName: string,
    workorderNumber: string,
    hierarchy: string,
    latitude: string,
    longitude: string,
    customerName: string,
    objectPermissionTypeId: number,
    objectPermissionTypeName: string,
    permission:any,
    action:any
}


export class AccessControlModel
{
    tblHeader:ICommonTableHeader[] = [
        {
            columnName:"jobName",
            displayName:"Job Name",
            visible:true,
            span: true,
            sort:true
        },
        {
            columnName:"workorderNumber",
            displayName:"WO Number",
            visible:true,
            span: true
        },
        {
            columnName:"hierarchy",
            displayName:"Hierarchy",
            visible:true,
            span: true
        },
        {
            columnName:"latitude",
            displayName:"Latitude",
            visible:true,
            span: true
        },
        {
            columnName:"longitude",
            displayName:"Longitude",
            visible:true,
            span: true
        },
        {
            columnName:"customerName",
            displayName:"Customer Name",
            visible:true,
            span: true
        },
        {
            columnName:"objectPermissionTypeId",
            displayName:"Access Type",
            visible:true,
            dropDown:true
        },
        {
            columnName:"permission",
            displayName:"Permission",
            visible:true,
            link: true
        },
        {
            columnName:"action",
            displayName:"Action",
            visible:true,
            button: true
        }
    ];

    permissionButton = [{
        permission: 'Edit',
        dataBsToggle: 'modal',
        dataBsTarget: '#permissionsEdit'
    }];

    actionButton = [{
        btnName:'Save'
    }];
}