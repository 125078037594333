import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardsRoutingModule } from './dashboards-routing.module';
import { DashboardsComponent } from './dashboards.component';
import { RecentDocumentsComponent } from './components/recent-documents/recent-documents.component';
import { RecentJobsComponent } from './components/recent-jobs/recent-jobs.component';
import { MessageBoardComponent } from './components/message-board/message-board.component';
import { MapIntegrationComponent } from './components/map-integration/map-integration.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TextSlicePipe } from 'src/app/pipe/text-slice.pipe';


@NgModule({
  declarations: [DashboardsComponent, RecentDocumentsComponent, RecentJobsComponent, MessageBoardComponent, MapIntegrationComponent,TextSlicePipe],
  imports: [
    CommonModule,
    DashboardsRoutingModule,
    ReactiveFormsModule
  ],
  exports:[TextSlicePipe]
})
export class DashboardsModule { }
