import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PortalAPI } from 'src/app/constants/api.constant';
import { AccessControlModel, IAccessControl } from '../../constants/access-control-model';
import { ConnectionService } from '../../services/connection-service';


@Component({
  selector: 'app-accesscontrol',
  templateUrl: './accesscontrol.component.html',
  styleUrls: ['./accesscontrol.component.css']
})
export class AccesscontrolComponent implements OnInit {

  pageTable: string = 'table-striped table-bordered custom-table table-colored';
  userName: string = "";
  accessCntrlTblHeader = new AccessControlModel().tblHeader;
  accessCntrlTblData: IAccessControl[] = [];
  userData: any;
  dropDownVal = [
    {
      value: 1,
      name: 'View',
    },
    {
      value: 2,
      name: 'Edit',
    },
    {
      value: 3,
      name: 'No Access',
    },
  ];

  constructor(
    private restService: ConnectionService,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.getDataFromParam();
  }

  //#region getDataFromParam method is used to get the data from the previous page
  getDataFromParam() {
    const stateData = history.state;
    if (stateData && stateData?.user) {
      this.userData = JSON.parse(stateData.user);
      this.userName = this.userData.name;
      this.getAccessControlList(this.userData.userId);
    }
  }
  //#endregion

  //#region getAccessControlList method is use to get all the job list through the GETJOBASSIGNEDTOUSERS api
  getAccessControlList(userId: number) {
    this.spinner.show();
    this.restService.tenantId = '1';
    const restServiceBody = {
      "request": {
        "pageNumber": 1,
        "pageSize": 100
      },
      "userId": userId
    }
    this.restService.post(PortalAPI.GET_JOBS_ASSIGNED_TO_USER, restServiceBody).subscribe(response => {
      if (response && response.jobsAssignedToUser && response.totalRecord > 0) {
        this.accessCntrlTblData = response.jobsAssignedToUser;
        this.accessCntrlTblData.forEach((it) => {
          it['permission'] = new AccessControlModel().permissionButton;
          it['action'] = new AccessControlModel().actionButton;
        });
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      console.log("error access control: ", err)
    })
  }
  //#endregion

  //#region navigatePage method is used for navigate a page from last page
  navigatePage() {
    sessionStorage.setItem('accessControl','user');
    this.router.navigate(['/sitemapadmin']);
  }
  //#endregion

  //#region btnClick method is used to click on any button in the table then function will occure
  btnClick(event: any) {
    if (event) {
    }
  }
  //#endregion

  //#region linkClick method is used to click on any link in the table then function will occure
  linkClick(event: any) {
  }
  //#endregion

}
