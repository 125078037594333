import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubjectService } from 'src/app/services/behaviorsubject.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { JobService } from 'src/app/modules/projects/service/job.service';

@Component({
  selector: 'app-recent-jobs',
  templateUrl: './recent-jobs.component.html',
  styleUrls: ['./recent-jobs.component.css']
})
export class RecentJobsComponent implements OnInit {

  @Input() loginStatus: boolean = false;

  recentJobList: any = {};
  assignedSharedJoblist: any = {};
  sendDataForm = {};
  jobLogData: any = [];
  pager: any = {};
  pagedItems: any = [];
  currentRow: any;
  myPreferencesObj: any = {};
  loginUserId: any;

  constructor(
    private spinner: NgxSpinnerService,
    private dashboardservice: DashboardService,
    private paginationservice: PaginationService,
    private behaviourSubjectService: BehaviorSubjectService,
    private jobService:JobService
  ) {
    //get mypreferences data //
    this.behaviourSubjectService.myPreferencesObservable.subscribe((Obj) => {
      if (Obj?.JOB_DISPLAY_WIDGET)
        this.myPreferencesObj = Obj;
      else
        this.myPreferencesObj.JOB_DISPLAY_WIDGET = 5; //initial value
    });
  }

  ngOnInit(): void {
    if (sessionStorage.getItem("loginUserId")) {
      this.loginUserId = sessionStorage.getItem("loginUserId");
    }
    
    this.getSharedAssignedJobList();
    
  }

  getRecentJobList() {
    this.setPage(1);
  }

  getSharedAssignedJobList() {
    if (sessionStorage.getItem("assignedSharedJobs"))
    {
      // already have a list of assigned and shared jobs.  only need to get the recent job list
      this.getRecentJobList();
    } else {
      let postData = {      
        "request": {
          "pageNumber": 1,
          "pageSize": this.myPreferencesObj.JOB_DISPLAY_WIDGET
        },
        "userId": this.loginUserId
      };

      // this service will always return the full list
      this.jobService.getSharedAndAssignedJobs(postData).subscribe((assignedSharedJobs) => {
          console.log("AssignedSharedJobs: ", assignedSharedJobs);
          this.assignedSharedJoblist = assignedSharedJobs;
          sessionStorage.setItem("assignedSharedJobs", JSON.stringify(this.assignedSharedJoblist));
          this.getRecentJobList();
      });
    }    
  }

  addJobLogData(data: any) {
    this.behaviourSubjectService.changeLogData(data);
  }

  isShared(jobData:any) {
    //console.log("isShared: ", jobData);
    //JSON.parse(sessionStorage.getItem('PermissionObj') || '{}');
    let sessionAssignedShared = JSON.parse(sessionStorage.getItem("assignedSharedJobs") || '{}');
    let sharedJob = sessionAssignedShared?.shared?.find((job:any) => {return job.jobId === jobData.jobId});
    if (sharedJob != undefined) {
      return true;
    } else {
      return false;
    }
  }

  isAssigned(jobData:any) {
    //console.log("isShared: ", jobData);
    //JSON.parse(sessionStorage.getItem('PermissionObj') || '{}');
    let sessionAssignedShared = JSON.parse(sessionStorage.getItem("assignedSharedJobs") || '{}');
    let assignedJob = sessionAssignedShared?.assigned?.find((job:any) => {return job.jobId === jobData.jobId});
    if (assignedJob != undefined) {
      return true;
    } else {
      return false;
    }
  }

  //# get recent jobs list by User ID
  setPage(page: number) {
    
    // get the number of shared jobs
    let sessionAssignedShared = JSON.parse(sessionStorage.getItem("assignedSharedJobs") || '{}');
    let assignedSharedcount = sessionAssignedShared.assigned.length + sessionAssignedShared.shared.length;
    let pagesize = this.myPreferencesObj.JOB_DISPLAY_WIDGET;

    if (page == 1){
      pagesize = (pagesize - assignedSharedcount) >= 0 ? (pagesize - assignedSharedcount) : 0;
    }

    this.sendDataForm = {
      "request": {
        "pageNumber": page,
        // "pageSize": 5
        "pageSize": pagesize
      },
      "userId": this.loginUserId,
      "securableObjectTypeId": 1, // 1 for jobs
    }
    this.spinner.show();
    this.dashboardservice.getRecentJobList(this.sendDataForm).subscribe((response) => {
     // console.log("test:", response);
      this.recentJobList = response;      
      if (page == 1 && assignedSharedcount > 0){    
        // are any of the recent jobs also in the recent jobs list, need to remove them from the recent job list.
        let differenceShared = this.recentJobList.recentJobs.filter((job:any)=> 
          !(sessionAssignedShared.shared.find((sj:any) => {return sj.jobId === job.jobId})));
        
        let differenceSharedAssigned = differenceShared.filter((job:any)=> 
          !(sessionAssignedShared.assigned.find((sj:any) => {return sj.jobId === job.jobId})));
       
        this.recentJobList.recentJobs = differenceSharedAssigned;

        this.pagedItems = [].concat(sessionAssignedShared.assigned , sessionAssignedShared.shared, this.recentJobList.recentJobs);
      } else {
        this.pagedItems = this.recentJobList.recentJobs;
      }
      
      this.pager = this.paginationservice.getPager(this.recentJobList.totalRecord, page, this.myPreferencesObj.JOB_DISPLAY_WIDGET);

      //console.log(this.pager);
      this.spinner.hide();
    }, (err) => {
      console.log(err);
    });
  }

  shareJobObj(obj: any) {
    // for some reason the isShared property is set to true if user is the owner of the job
   this.behaviourSubjectService.changeJobShared({ jobId: obj.jobId, isShared: !obj.isShared });
 }
  
  shareJobObjOnMap(obj: any) {
    let element = document.querySelectorAll('.mapboxgl-popup');
    if (element.length > 0)
      Array.prototype.forEach.call(element, function (node) {
        node.parentNode.removeChild(node);
      });
    this.behaviourSubjectService.changeJobPopOnMap(obj);
  }

  selectedRow(event: any, obj: any) {
    this.currentRow = obj.name;
  }

}
