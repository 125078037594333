<!-- Slide Site Starts -->
<div class="side-main-menu site right" [ngClass]="{
  open:
    slideMenuOpenJobDescription &&
    !slideMenuOpenSiteAdminDetails &&
    !slideMenuOpenLayerDetails &&
    !slideMenuOpenTools &&
    !slideMenuOpenSiteDetails &&
    !slideMenuOpenFolder,
  'end-0':
    slideMenuOpenSiteAdminDetails ||
    slideMenuOpenTools ||
    slideMenuOpenLayerDetails ||
    slideMenuOpenFolder ||
    slideMenuOpenSiteDetails
  }
">
<div class="menu-first" (click)="ontoggle(false, false, false, false, !slideMenuOpenJobDescription, false);"
  title="Feature Description">
  <img class="right-toggle" src="../../../../../assets/images/ico_description.svg" alt="Description" />
</div>
<div class="flex-shrink-0 p-0 bg-white sub-page-menu">
  <div class="sub-page-menu-body site">
    <div class="sub-page-menu-header" *ngIf="openedFeatureAttributes && editFormFeatureDescriptionView">
      <div class="role">
        <h2>{{ openedFeatureName }}</h2>
        <!-- <h4>Description</h4> -->
        <h4>Description
          <span *ngIf="[2, '2'].includes(currentFeaturesLayer) && loginUserRole?.toLowerCase() === 'client'"
            (click)="editFormFeatureDescriptionOpen()"><i class="fa fa-pencil"></i> </span>
            <span *ngIf="![2, '2'].includes(currentFeaturesLayer) && loginUserRole?.toLowerCase() !== 'client'"
            (click)="editFormFeatureDescriptionOpen()"><i class="fa fa-pencil"></i> </span>
        </h4>
      </div>
      <p></p>
     
     
      <p>
        <strong>Date of Utility Investigation</strong>
        {{ openedFeatureAttributes.dateOfUtilityInvestigation }}
      </p>
      
      <p>
        <strong>Work Order Number</strong>
        {{ openedFeatureAttributes.workOrderNumber }}
      </p>
     
      <p>
        <strong>Locator Name</strong>
        {{ openedFeatureAttributes.locatorName }}
      </p>
      <p>
        <strong>Group Type</strong>
        {{ openedFeatureProperties.featureGroup }}
      </p>
      <p>
        <strong>Feature Type</strong>
        {{ openedFeatureProperties.featureType }}
      </p>
      <p><strong>Last Updated Date</strong> {{ openedFeatureAttributes.lastUpdatedDate }}</p>
      <p><strong>Last Updated By</strong> {{ openedFeatureAttributes.lastUpdatedBy }}</p>
      
      <p><strong>Accuracy</strong> {{ openedFeatureAttributes.accuracy }}</p>
      <p><strong>Coordinates </strong>
        <span>{{openedFeatureAttributes.coordinate.long}},</span>
        <span> {{openedFeatureAttributes.coordinate.lat}}</span>
      </p>
      
      <p>
        <strong>Depth</strong> {{ openedFeatureAttributes.depthToTop }}
      </p>
      <p>
        <strong>Description</strong> {{ openedFeatureAttributes.description }}
      </p>
      <p>
        <strong>Notes</strong> {{ openedFeatureAttributes.notes }}
      </p>
      <p>
        <strong>SiteMap Attribute ID</strong> {{ openedFeatureProperties.featureAttributeId }}
      </p>
     

    </div>

    <!-- EDIT FEATURES DIV -->
    <div class="sub-page-menu-header" *ngIf="openedFeatureAttributes && !editFormFeatureDescriptionView">
      <div class="role">
        <h2>{{ openedFeatureName }}</h2>
        <h4>Description <span (click)="editFormFeatureDescriptionClose()"><i class="fa fa-close"></i> </span> </h4>
      </div>

      <form [formGroup]="updateFeatureDescriptionForm">
     

      <div class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center">
        <label for="feature_dateOfUtilityInvestigation" class="form-label col-sm-6">Date of Utility Investigation</label>
        <div class="col-sm-6">
          <input type="text" [attr.disabled]="true" id="feature_dateOfUtilityInvestigation" class="form-control form-control-sm" [value]="openedFeatureAttributes.dateOfUtilityInvestigation" formControlName="dateOfUtilityInvestigation">
        </div>
      </div>
      <div class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center">
        <label for="feature_workOrderNumber" class="form-label col-sm-6">Work Order Number</label>
        <div class="col-sm-6">
          <input type="text" [attr.disabled]="true" id="feature_workOrderNumber" class="form-control form-control-sm" [value]="openedFeatureAttributes.workOrderNumber" formControlName="workOrderNumber">
        </div>
      </div>


      <div class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center">
        <label for="feature_locatorName" class="form-label col-sm-6"> Locator Name</label>
        <div class="col-sm-6">
          <input type="text" [attr.disabled]="true" id="feature_locatorName" class="form-control form-control-sm" [value]="openedFeatureAttributes.locatorName" formControlName="locatorName">
        </div>
      </div>
      <div
      class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
    >
      <label for="feature_locatorName" class="form-label col-sm-6">
        Group Type</label
      >
      <div class="col-sm-6">
        <input
          type="text"
          id="feature_locatorName"
          class="form-control form-control-sm"
          [value]="openedFeatureProperties.featureGroup"
          
          [attr.disabled]="true"
        />
      </div>
    </div>
    <div
      class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
    >
      <label for="feature_locatorName" class="form-label col-sm-6">
        Feature Type</label
      >
      <div class="col-sm-6">
        <input
          type="text"
          id="feature_locatorName"
          class="form-control form-control-sm"
          [value]="openedFeatureProperties.featureType"
          
          [attr.disabled]="true"
        />
      </div>
    </div>
    <div
    class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
  >
    <label for="feature_locatorName" class="form-label col-sm-6">
      Last Updated Date</label
    >
    <div class="col-sm-6">
      <input
        type="text"
        id="feature_locatorName"
        class="form-control form-control-sm"
        [value]="openedFeatureAttributes.lastUpdatedDate"
        
        [attr.disabled]="true"
      />
    </div>
  </div>
  <div
  class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
>
  <label for="feature_locatorName" class="form-label col-sm-6">
    Last Updated By</label
  >
  <div class="col-sm-6">
    <input
      type="text"
      id="feature_locatorName"
      class="form-control form-control-sm"
      [value]="openedFeatureAttributes.lastUpdatedBy"
      
      [attr.disabled]="true"
    />
  </div>
</div>

      <div class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center">
        <label for="feature_accuracy" class="form-label col-sm-6">Accuracy</label>
        <div class="col-sm-6">
          <input type="text" [attr.disabled]="true" id="feature_accuracy" class="form-control form-control-sm" [value]="openedFeatureAttributes.accuracy" formControlName="accuracy">
        </div>
      </div>
      <div class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center">
        <label class="form-label col-sm-6">Coordinates</label>
        <div class="col-sm-6">
          <input type="text" class="form-control form-control-sm" [value]="openedFeatureAttributes.coordinate.long"
            formControlName="coordinatelongtitude">
        </div>
        <label class="form-label col-sm-6"> </label>
        <div class="col-sm-6">
          <input type="text" class="form-control form-control-sm" [value]="openedFeatureAttributes.coordinate.lat"
            formControlName="coordinatelatitude">
        </div>
      </div>
      

      <div class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center">
        <label for="feature_depthToTop" class="form-label col-sm-6">Depth</label>
        <div class="col-sm-6">
          <input type="text" id="feature_depthToTop" class="form-control form-control-sm" [value]="openedFeatureAttributes.depthToTop" formControlName="depthToTop">
        </div>
      </div>
      <div class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center">
        <label for="feature_description" class="form-label col-sm-6">Description</label>
        <div class="col-sm-6">
          <input type="text" id="feature_description" class="form-control form-control-sm" [value]="openedFeatureAttributes.description" formControlName="description">
        </div>
      </div>
      <div class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center">
        <label for="feature_notes" class="form-label col-sm-6">Notes</label>
        <div class="col-sm-6">
          <input type="text" id="feature_notes" class="form-control form-control-sm" [value]="openedFeatureAttributes.notes" formControlName="notes">
        </div>
      </div>
      <div
      class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
    >
      <label for="feature_locatorName" class="form-label col-sm-6">
        SiteMap Attribute ID</label
      >
      <div class="col-sm-6">
        <input
          type="text"
          id="feature_locatorName"
          class="form-control form-control-sm"
          [value]="openedFeatureProperties.featureId"
          
          [attr.disabled]="true"
        />
      </div>
    </div>

      <div class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center">
        <div class="col-sm-12">
          <button *appPermission="permissionEnumList.CAN_EDIT_FEATURE_ATTRIBUTES;elementShowHide:false;" class="btn btn-primary btn-sm" (click)="updateFeaturesDescition()">Update</button>
          <button style="margin-left: 10px;" class="btn btn-primary btn-sm" (click)="editFormFeatureDescriptionClose()">Cancel</button>
        </div>
      </div>
    </form>

    </div>
    <!-- EDIT FEATURES DIV -->

    <div *ngIf="!openedFeatureAttributes">
      <h2 style="margin-top: 137px; margin-left: 20px">
        Please select a feature
      </h2>
    </div>

    <!-- <div class="sub-page-menu-header">
      <div class="role">
        <h2>Property Description <span class="fa fa-edit"></span></h2>
      </div>
    </div> -->

    <!-- <div class="sub-page-menu-header" *ngIf="propertyToShowInSideBar != null"> -->
    <div class="sub-page-menu-header">
      <!-- <div class="role">
        <h2>Customer Name - {{propertyToShowInSideBar.utiltiy_owner}}</h2>
      </div>
      <p><strong>Time:</strong> {{propertyToShowInSideBar.date_of_installation}}</p>
      <p><strong>Latitude:</strong> {{propertyToShowInSideBar.lat}}</p>
      <p><strong>Longitude:</strong> {{propertyToShowInSideBar.lng}}</p>
      <p><strong>Elevation:</strong> 569.284ft</p> -->
      <div class="img-card">
        <h2>Images</h2>
        <div class="img-card-list">
          <p>Data is not available</p>

          <!-- <img src="../../assets/images/layerImage.jpg" alt="layer img" data-bs-toggle="modal"
            data-bs-target="#imageModal" (click)="onGetImageUrl($event)" />
          <img src="../../assets/images/layerImage.jpg" alt="layer img" data-bs-toggle="modal"
            data-bs-target="#imageModal" (click)="onGetImageUrl($event)" />
          <img src="../../assets/images/layerImage.jpg" alt="layer img" data-bs-toggle="modal"
            data-bs-target="#imageModal" (click)="onGetImageUrl($event)" />
          <img src="../../assets/images/layerImage.jpg" alt="layer img" data-bs-toggle="modal"
            data-bs-target="#imageModal" (click)="onGetImageUrl($event)" />
          <img src="https://github.com/mdo.png" alt="layer img" data-bs-toggle="modal" data-bs-target="#imageModal"
            (click)="onGetImageUrl($event)" />
          <img src="../../assets/images/layerImage.jpg" alt="layer img" data-bs-toggle="modal"
            data-bs-target="#imageModal" (click)="onGetImageUrl($event)" />
          <img src="../../assets/images/layerImage.jpg" alt="layer img" data-bs-toggle="modal"
            data-bs-target="#imageModal" (click)="onGetImageUrl($event)" />
          <img src="../../assets/images/layerImage.jpg" alt="layer img" data-bs-toggle="modal"
            data-bs-target="#imageModal" (click)="onGetImageUrl($event)" /> -->
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<!-- Slide Site Ends -->
