<div class="row">
  <div class="col-lg-12 mt-0 p-0">
    <main class="page-main right">
      <div class="crop-overlay"></div>

      <div id="dragger" tabindex></div>
      <div class="container-fluid page-main-content">
        <div class="row">
          <div class="col-lg-12">
            <div class="map-option">
              <ul>
                <li>
                  <img
                    src="../../assets/images/ico_current_location.svg"
                    alt="Current Location"
                    (click)="navigateToCurrentLocation()"
                    title="Current Location"
                  />
                </li>
                <li>
                  <img
                    src="../../assets/images/ico_background_map.svg"
                    alt="Map Style"
                    title="Map Style"
                  />
                  <form [formGroup]="MapviewerSiteForm">
                    <ul>
                      <li>
                        <label for="satellite-v9">
                          <input
                            id="satellite-v9"
                            type="radio"
                            value="satellite-v9"
                            formControlName="mapType"
                            (change)="changeMaptypeEvent($event)"
                          />
                          <img
                            src="../../assets/images/mapbox-satellite.jpg"
                            alt=""
                            (change)="changeMaptypeEvent($event)"
                          />
                          <span>Satellite</span>
                        </label>
                      </li>

                      <li>
                        <label for="light-v10">
                          <input
                            id="light-v10"
                            type="radio"
                            value="light-v10"
                            formControlName="mapType"
                            (change)="changeMaptypeEvent($event)"
                          />
                          <!-- <img
                            src="../../assets/images/mapbox-light.jpg"
                            alt=""
                            (change)="changeMaptypeEvent($event)"
                          /> -->
                          <img
                            src="../../assets/images/mapbox-dark.jpg"
                            alt=""
                            (change)="changeMaptypeEvent($event)"
                          />
                          <span>Light</span>
                        </label>
                      </li>

                      <li>
                        <label for="dark-v10">
                          <input
                            id="dark-v10"
                            type="radio"
                            value="dark-v10"
                            formControlName="mapType"
                            (change)="changeMaptypeEvent($event)"
                          />
                          <!-- <img
                            src="../../assets/images/mapbox-dark.jpg"
                            alt=""
                            (change)="changeMaptypeEvent($event)"
                          /> -->
                          <img
                            src="../../assets/images/mapbox-light.jpg"
                            alt=""
                            (change)="changeMaptypeEvent($event)"
                          />
                          <span>Dark</span>
                        </label>
                      </li>

                      <li>
                        <label for="streets-v11">
                          <input
                            id="streets-v11"
                            type="radio"
                            value="streets-v11"
                            formControlName="mapType"
                            (change)="changeMaptypeEvent($event)"
                          />
                          <img
                            src="../../assets/images/mapbox-streets.jpg"
                            alt=""
                            (change)="changeMaptypeEvent($event)"
                          />
                          <span>Streets</span>
                        </label>
                      </li>

                      <li>
                        <label for="satellite-streets-v11">
                          <input
                            id="satellite-streets-v11"
                            type="radio"
                            value="satellite-streets-v11"
                            formControlName="mapType"
                            (change)="changeMaptypeEvent($event)"
                          />
                          <img
                            src="../../assets/images/mapbox-satellite-streets.jpg"
                            alt=""
                            (change)="changeMaptypeEvent($event)"
                          />
                          <span>Satellite Streets</span>
                        </label>
                      </li>

                      <li>
                        <label for="outdoors-v11">
                          <input
                            id="outdoors-v11"
                            type="radio"
                            value="outdoors-v11"
                            formControlName="mapType"
                            (change)="changeMaptypeEvent($event)"
                          />
                          <img
                            src="../../assets/images/mapbox-outdoors.jpg"
                            alt=""
                            (change)="changeMaptypeEvent($event)"
                          />
                          <span>Outdoors</span>
                        </label>
                      </li>

                      <li>
                        <label for="navigation-day-v1">
                          <input
                            id="navigation-day-v1"
                            type="radio"
                            value="navigation-day-v1"
                            formControlName="mapType"
                            (change)="changeMaptypeEvent($event)"
                          />
                          <img
                            src="../../assets/images/mapbox-navigation-day.jpg"
                            alt="Hybrid"
                            (change)="changeMaptypeEvent($event)"
                          />
                          <span>Navigation Day</span>
                        </label>
                      </li>

                      <li>
                        <label for="navigation-night-v1">
                          <input
                            formControlName="mapType"
                            id="navigation-night-v1"
                            type="radio"
                            value="navigation-night-v1"
                            (change)="changeMaptypeEvent($event)"
                          />
                          <img
                            src="../../assets/images/mapbox-navigation-night.jpg"
                            alt=""
                            (change)="changeMaptypeEvent($event)"
                          />
                          <span>Navigation Night</span>
                        </label>
                      </li>
                    </ul>
                  </form>
                </li>
                <li *ngIf="!isCredentialessLogin || credentialessLogin.userLoggedIn">
                  <img
                    src="../../assets/images/ico_my_preferences.svg"
                    alt="Choose Default Render Scale"
                    title="Choose Default Scale"
                  />
                  <form [formGroup]="RenderScaleForm">
                        <span class="render-scale">Choose Default Scale to Render Features:</span>
                        <select class="render-scale"
                        id="render-scale-select"
                        (change)="changeRenderScaleEvent($event)"
                        [value]="currentRenderScale">
                          <option value="500">500 ft.</option>
                          <option value="1000">1,000 ft.</option>
                          <option value="2000">2,000 ft.</option>
                          <option value="5000">5,000 ft.</option>
                          <option value="10000">10,000 ft.</option>
                        </select>
                  </form>
                </li>
                <li class="start-crop" *ngIf="!actionCrop">
                  <span class="fa fa-crop"></span>
                </li>
                <li class="stop-crop" *ngIf="!actionCrop">
                  <span class="fa fa-crop"></span>
                </li>
              </ul>
            </div>
            <!-- Page Content Starts -->
            <div
              id="mapviewerjobs"
              class="custom-mapbox"
              [ngClass]="printAction ? 'full-page' : 'crop-page'"
            >
              <ul class="grid-cell">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>

                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>

                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>

                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <!-- Page Content Ends -->
          </div>
        </div>
      </div>
    </main>
  </div>
</div>

<!--- Single Job Share -->
<div
  class="modal fade"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <form aria-required="true">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title custom-modal-title" id="staticBackdropLabel">
            Share Job
          </h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="closeShareObjModal()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div
                class="col-lg-12 shared-content"
                *ngIf="objSharedUserList.length != 0 && isJobOwner"
              >
                <div class="parent-wrapper">
                  <h3>SHARED WITH</h3>
                  <ul>
                    <li
                      *ngFor="
                        let userDetail of objSharedUserList;
                        let i = index
                      "
                    >
                      {{ userDetail.displayName }}
                      ({{ userDetail.emailId }}) - {{ userDetail.accessName }}
                      <span
                        class="map-edit-icon pointer"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        routerLink="/collaboration"
                      ></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="list-showing-parent mb-3">
                <label
                  for="exampleFormControlInput1"
                  class="form-label custom-label"
                  >Email address
                  <span class="custom-form-label-star">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                  [formControl]="emailFormControl"
                  placeholder="name@example.com"
                />
                <ul
                  class="list-showing"
                  [ngClass]="{
                    'fetched-emails-diplay': shouldFetchedEmailsBeDisplayed
                  }"
                >
                  <li
                    *ngFor="let item of fetchedEmails; let i = index"
                    (click)="selectEmailFromFetchedEmails(item)"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-12">
              <p class="custom-label">Access Control</p>
            </div>
            <div class="col-lg-6">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="1"
                  [formControl]="accessFormControl"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  (change)="shareObj(true)"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  View
                </label>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="2"
                  [formControl]="accessFormControl"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  (change)="shareObj(true)"
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  Edit
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            (click)="closeShareObjModal()"
          >
            Cancel
          </button>
          <button
            (click)="shareObj()"
            type="button"
            class="btn btn-success"
            data-bs-dismiss="modal"
            [disabled]="emailFormControl.invalid"
          >
            Share
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<!--- Single Job Share -->

<!-- Slide Menu Starts -->
<div
  class="side-main-menu layer right"
  [ngClass]="{
    open:
      slideMenuOpenLayerDetails &&
      !slideMenuOpenTools &&
      !slideMenuOpenFolder &&
      !slideMenuOpenJobDescription,
    'end-0':
      slideMenuOpenTools || slideMenuOpenJobDescription || slideMenuOpenFolder
  }"
  *ngIf="showToggleSideBar"
>
  <div
    class="menu-first"
    (click)="
      slideMenuOpenTools = false;
      slideMenuOpenFolder = false;
      slideMenuOpenJobDescription = false;
      slideMenuOpenLayerDetails = !slideMenuOpenLayerDetails;
      loadFeature();
      onHistorySlideoutClose(false);
    "
    title="Layer"
  >
    <!-- <span class="fa fa-angle-left"></span> -->
    <img
      class="right-toggle"
      src="../../../../../assets/images/ico_layers.svg"
      alt="Layers"
    />
  </div>
  <div class="flex-shrink-0 p-0 bg-white sub-page-menu">
    <div class="sub-page-menu-header mt-0">
      <div class="role">
        <div class="input-group pb-2" id="jobEdit">
          <input
            type="text"
            class="form-control"
            [value]="navigatedJobName"
            aria-label="Job - Walmart Dunwoody"
            aria-describedby="jobEdit"
          />
          <!-- <span class="input-group-text" id="jobEdit" (click)="gotoMapEditor()"><i class="map-edit-icon" (click)="gotoMapEditor()"></i></span> -->
        </div>
      </div>
    </div>
    <div class="sub-page-menu-body">
      <div
        class="accordion custom-accordion"
        id="accordionPanelsStayOpenExample2"
      >
        <!-- [ngClass]="{ 'disabled': loginUserRole != 'Admin' && loginUserRole != 'Project Manager'}" -->
        <ng-container *ngFor="let layer of fetchLayerArray()">
          <div
            [ngClass]="{ 'd-none': !checkEditLayerVisible(layer) }"
            attr.data-name="{{ layer }}"
            class="map-layer-container {{ layer }}"
          >
            <h3>
              {{ layer }} {{ layer === 'SITE' ? 'DETAILS' : 'LAYER'}}
              <ng-container *ngIf="layer == 'GPRS' && isGprsLayerCanEdit && editCheck">
              <i
                class="map-edit-icon"
                [ngClass]="!isEditLayer ? 'disabled' : ''"
                (click)="gotoMapEditor(layer)"
                *appPermission="permissionEnumList.CAN_EDIT_GPRS_LAYER_ON_MAP;elementShowHide:false;"
              ></i>
              </ng-container>
              <ng-container *ngIf="layer == 'CLIENT' && isClientLayerCanEdit">
              <i
                class="map-edit-icon"
                [ngClass]="!isEditLayer ? 'disabled' : ''"
                (click)="gotoMapEditor(layer)"
                *appPermission="permissionEnumList.CAN_EDIT_CLIENT_LAYER_ON_MAP ;elementShowHide:false;"
              ></i>
              </ng-container>
              <ng-container *ngIf="layer == 'IMPORTED' && isImportedLayerCanEdit">
              <i
                class="map-edit-icon"
                [ngClass]="!isEditLayer ? 'disabled' : ''"
                (click)="gotoMapEditor(layer)"
              ></i>
            </ng-container>
              <!-- Comment for the time being for Imported layer demo without any check/Validation -->
              <!-- &&
              isImportedLayerCanEdit &&
              isEyeViewVisibleForEmpty(layer) -->
              <ng-container *ngIf="layer == 'EXTERNAL' && isExternalLayerCanEdit">
              <i
                class="map-edit-icon"
                [ngClass]="!isEditLayer ? 'disabled' : ''"
                (click)="gotoMapEditor(layer)"
              ></i>
              </ng-container>
              <ng-container  *ngIf="
              layer == 'REFERENCE' &&
              isReferenceLayerCanEdit &&
              referenceGISTilesData.length > 0
            ">
              <i
                class="map-edit-icon"
                [ngClass]="!isEditLayer ? 'disabled' : ''"
                (click)="gotoMapEditor(layer)"
              ></i>
              <i
                type="button"
                class="map-import-icon"
               (click)="importReferenceLayer()"
                style="margin-left: 10px; float: none"
              >
              </i>
              </ng-container>
              <ng-container *ngIf="layer == 'IMPORTED'  && isImportedLayerCanEdit">

              <i
                type="button"
                class="map-import-icon"
               (click)="importConditionForPersonal()"
                style="margin-left: 10px; float: none"
              >
              </i>
             </ng-container>
              <i
                [class.d-none]="!isEditLayer || !featureGroupMap.has(layer)"
                (click)="toggleGPRSLayer(layer)"
                id="main{{ layer }}Layer"
                class="map-layer-id {{ layer }} layer-eye-width"
                attr.data-name="{{ layer }}"
                [ngClass]="
                  checkEyeLayerVisible(layer) ? 'eye-visible' : 'eye-lash'
                "
                style="margin-right: 5px"
              ></i>
              <i
                *ngIf="
                  referenceGISTilesData.length > 0 &&
                  layer === 'REFERENCE' &&
                  isEditLayer
                "
                (click)="toggleReferenceLayer(layer)"
                id="main{{ layer }}Layer"
                class="map-layer-id {{ layer }} layer-eye-width"
                attr.data-name="{{ layer }}"
                [ngClass]="
                  checkEyeLayerVisible(layer) ? 'eye-visible' : 'eye-lash'
                "
                style="margin-right: 5px"
              ></i>
            </h3>
            <div
              class="pt-1 pb-1 mb-4"
              *ngIf="
                (layer == 'GPRS' ||
                  layer === 'CLIENT' ||
                  layer === 'IMPORTED' ||
                  layer === 'REFERENCE') &&
                (isGprsLayerCanEdit ||
                  isClientLayerCanEdit ||
                  isImportedLayerCanEdit ||
                  isReferenceLayerCanEdit) &&
                isEditingModeIsOn &&
                showAddFeatureButton
              "
            >
              <button
                class="btn btn-secondary me-1 btn-sm addfeaturebtn"
                (click)="cancelContainerFrEditor(layer)"
              >
                Exit
              </button>
              <button
                *ngIf="layer != 'REFERENCE' && layer != 'IMPORTED' && checkImportedData(layer)"
                class="btn btn-primary me-1 btn-sm addfeaturebtn"
                (click)="addFeatureContainerFrEditor(layer)"
              >
                Add Feature
              </button>
            </div>
            <ng-container
              *ngIf="
                !isEditLayer &&
                editLayerName === layer &&
                showAddFeatureContainerFrMapEditor && checkImportedData(layer)
              "
            >
              <div id="dropdownGroup{{ layer }}Layer">
                <h2>Select Group</h2>
                <select
                  class="form-select form-select-sm"
                  id="grpDropDown"
                  name="grpDropDown"
                  [(ngModel)]="groupToSelect"
                  (change)="onGroupChange($event.target)"
                >
                  <option
                    *ngFor="let title of groupDropDownData"
                    [value]="title.featureGroupId"
                  >
                    {{ title.featureGroup }}
                  </option>
                </select>
              </div>
              <div id="dropdownGroup{{ layer }}Layer">
                <h2>Features Type</h2>
                <div class="row">
                  <div class="col-10 pe-0">
                    <select
                      class="form-select form-select-sm"
                      id="featureTypDropDown"
                      name="featureTypDropDown"
                      [(ngModel)]="featureTypeToSelect"
                      (change)="onFeatureTypeChange($event.target)"
                    >
                      <option
                        *ngFor="let title of featureTypeDropDownData"
                        [value]="title.featureTypeId"
                      >
                        {{ title.featureType }}
                      </option>
                    </select>
                  </div>
                  <div class="col-2">
                    <img
                      *ngIf="featureImg"
                      [src]="featureImg"
                      style="width: 80%"
                    />
                    <span
                      [ngStyle]="{
                        'background-color':
                          fetchFeatureTypeColor(featureTypeToSelect)
                      }"
                      style="
                        height: 4px;
                        float: left;
                        width: 20px;
                        margin-top: 10px;
                        margin-right: 5px;
                      "
                      *ngIf="!featureImg"
                    ></span>
                  </div>
                </div>
              </div>
              <div class="pt-2 pb-2">
                <button *appPermission="permissionEnumList.CAN_CREATE_POINT_ON_MAP;elementShowHide:false;"
                  class="btn btn-primary me-1 btn-sm"
                  (click)="addGroupFeatureType(layer)"
                >
                  Add
                </button>
                <button
                  class="btn btn-secondary btn-sm"
                  (click)="cancelContainerFrEditor(layer)"
                >
                  Exit
                </button>
              </div>
            </ng-container>

            <ng-container
              *ngIf="
                !isEditLayer &&
                editLayerName === 'EXTERNAL'
              "
            >

              <div id="dropdownExtCont">
                <h2>Select external content to add</h2>
                <div class="row">
                  <div class="col-10 pe-0">
                    <select
                      class="form-select form-select-sm"
                      id="extContDropDown"
                      name="extContDropDown"
                      [(ngModel)]="selectOptionsExternal"
                      (change)="getSelectedExternalContentType()"
                    >
                      <option
                        *ngFor="let title of externalContentDropdownData"
                        [value]="title.exernalComponentName"
                      >
                        {{ title.alias }}
                      </option>
                    </select>
                  </div>

                  <div class="col-2">
                    <img
                      *ngIf="externalContentIcon"
                      [src]="externalContentIcon"
                      style="width: 80%"
                    />
                  </div>
                </div>
              </div>
              <div class="pt-2 pb-2">
                <button
                  class="btn btn-primary me-1 btn-sm"
                  data-bs-toggle="modal"
                  (click)="addExtenalContent(layer)"
                >
                  Add
                </button>
                <button
                  class="btn btn-secondary btn-sm"
                  (click)="cancelContainerFrEditor(layer)"
                >
                  Exit
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="layer != 'IMPORTED'">
              <ng-container
              *ngIf="
                isGroupMapCompleted && fetchFeatureGroupArray(layer).length > 0;
                else onlyReference
              "
            >
              <div
                class="accordion-item custom-accordion-item feature-group-container {{
                  group.replace(' ', '')
                }}"
                *ngFor="
                  let group of fetchFeatureGroupArray(layer);
                  let i = index
                "
              >
                <!-- // For Imported layer file name Open ImportR-->
                <div *ngIf="layer == 'IMPORTED' && i == 0 && importedLayerFileId" class="mt-4">
                  <!-- //Region Start Uncomment for editing part layer name -->
                  <div
                    class="row nopadding fileInputCl"
                    *ngIf="isEditingModeIsOn"
                  >
                    <div class="col-9 nopadding">
                      <input
                        class="fileInputCl"
                        type="text"
                        [(ngModel)]="importedLayerFileName"
                        (ngModelChange)="importedFileChange($event)"
                        id="importedLayerFileNameId"
                        name="importedLayerFileNameId"
                      />
                    </div>
                    <div
                      class="col-3 nopadding"
                      *ngIf="importedFileNameChangeCondition"
                    >
                      <button
                        class="btn btn-secondary me-1 addfeaturebtn"
                        (click)="cancelImportedLayerName()"
                      >
                        Exit
                      </button>
                      <button
                        class="btn btn-primary me-1 addfeaturebtn"
                        (click)="saveImportedLayerName()"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <!-- // Region End -->
                  <p class="pImport" *ngIf="!isEditingModeIsOn">
                    {{ importedLayerFileNameStore }}
                  </p>
                </div>
                <!-- // For Imported layer file name  Closed ImportR-->

                <ng-container *ngIf="isGroupData(layer, group)">
                  <h2 class="accordion-header" [id]="getFinalGrp(group)">
                    <button
                      class="accordion-button collapsed"
                      [id]="'btn' + getFinalGrp(group)"
                      type="button"
                      data-bs-toggle="collapse"
                      [attr.data-bs-target]="
                        '#' + getFinalGrp(group) + '-content' + layer
                      "
                      aria-expanded="false"
                      [attr.aria-controls]="
                        getFinalGrp(group) + '-content' + layer
                      "
                    >
                      <span *ngIf="group !== 'MATTERPORT'"
                        [ngClass]="{
                          'text-primary':
                            !isEditLayer &&
                            editSelectedFeature?.properties?.featureGroup ===
                              group
                        }"
                      >
                        {{ group }}
                      </span>
                      <span *ngIf="group === 'MATTERPORT'"
                        [ngClass]="{
                          'text-primary':
                            !isEditLayer &&
                            editSelectedFeature?.properties?.featureGroup ===
                              group
                        }"
                      >
                        VIRTUAL TOUR
                      </span>
                      
                    </button>
                    <span class="sub-page-menu-action">

                      <span class="history-icon" *ngIf="isEditLayer && layer !== 'SITE'">
                        <i *ngIf="loginUserRole?.toLowerCase() === 'client' && layer === 'CLIENT' " class="fa fa-share-square"
                          (click)="featureGroupHistoryClick(layer, group)"></i>
                        <i *ngIf="loginUserRole?.toLowerCase() !== 'client' && layer === 'GPRS' " class="fa fa-share-square"
                          (click)="featureGroupHistoryClick(layer, group)"></i>
                      </span>

                      <i
                        *ngIf="isEditLayer"
                        class="group-{{ group }} inner-group feature-group {{
                          group.replace(' ', '')
                        }} feature-group-margin"
                        attr.data-name="{{ group }}"
                        (click)="
                          toggleGroup(group, layer);
                          toggleSiteGroup(group, layer)
                        "
                        [ngClass]="
                          checkEyeGroupVisible(group, layer)
                            ? 'eye-visible'
                            : 'eye-lash'
                        "
                      ></i>
                      <i
                        *ngIf="!isEditLayer"
                        class="map-delete-icon"
                        (click)="deleteFeatureGroup(layer, group)"
                      ></i>
                    </span>
                  </h2>
                  <div
                    [id]="getFinalGrp(group) + '-content' + layer"
                    class="accordion-collapse collapse"
                    [attr.aria-labelledby]="getFinalGrp(group)"
                  >
                    <!-- #region when feature type available -->
                    <div
                      class="accordion-body custom-accordion-body"
                      *ngIf="
                        fetchFeatureTypeArray(group, layer) &&
                          fetchFeatureTypeArray(group, layer).length > 0 &&
                          fetchFeatureTypeArray(group, layer)[0];
                        else onlyFeature
                      "
                    >
                      <div
                        class="accordion-item custom-accordion-item feature-type-container {{
                          featureType
                        }}"
                        *ngFor="
                          let featureType of fetchFeatureTypeArray(
                            group,
                            layer
                          );
                          let j = index
                        "
                      >
                        <h2
                          class="accordion-header"
                          id="{{ featureType }}-{{ j }}"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            [id]="'btn' + featureType"
                            data-bs-toggle="collapse"
                            [attr.data-bs-target]="
                              '#collapse-' +
                              j +
                              '-content' +
                              layer +
                              '-' +
                              getFinalGrp(featureType)
                            "
                            aria-expanded="false"
                            [attr.aria-controls]="
                              getFinalGrp(featureType) +
                              '-' +
                              j +
                              '-content' +
                              layer
                            "
                          >
                            <span
                              [id]="'spn' + getFinalGrp(featureType)"
                              [ngClass]="{
                                'text-primary':
                                  !isEditLayer &&
                                  editSelectedFeature?.properties
                                    ?.featureType === featureType
                              }"
                            >
                              {{ featureType }}
                            </span>
                          </button>
                          <span class="sub-page-menu-action">

                            <span class="history-icon" *ngIf="isEditLayer && layer !== 'SITE'">
                              <i *ngIf="loginUserRole?.toLowerCase() === 'client' && layer === 'CLIENT' " class="fa fa-share-square"
                              (click)="featureTypeHistoryClick(layer, group, featureType)"></i>
                              <i *ngIf="loginUserRole?.toLowerCase() !== 'client' && layer === 'GPRS' " class="fa fa-share-square"
                              (click)="featureTypeHistoryClick(layer, group, featureType)"></i>
                            </span>

                            <i
                              *ngIf="isEditLayer"
                              class="group-{{
                                featureType
                              }} inner-group feature-type {{ featureType }} feature-group-margin"
                              attr.data-name="{{ featureType }}"
                              [ngClass]="
                                checkEyeFeatureTypeVisible(
                                  layer,
                                  group,
                                  featureType
                                )
                                  ? 'eye-visible'
                                  : 'eye-lash'
                              "
                              (click)="
                                toggleFeatureType(group, featureType, layer);
                                toggleSiteType(group, featureType, layer)
                              "
                            ></i>
                            <i
                              *ngIf="
                                !isEditLayer &&
                                editSelectedFeature?.properties?.featureType !=
                                  featureType
                              "
                              class="map-delete-icon"
                              (click)="
                                deleteFeatureType(layer, group, featureType)
                              "
                            ></i>
                            <!-- (click)="deleteFeatureType(layer,group,featureType)" -->
                          </span>
                          <a
                            class="savefeaturebtn"
                            [ngClass]="{disabled: (firstSecondLineFeatureFrMergedArr.length > 0) || (!isFirstEditDetected && editSelectedFeature), active: (firstSecondLineFeatureFrMergedArr.length == 0) || (isFirstEditDetected && editSelectedFeature)}"
                             *ngIf="
                              !isEditLayer &&
                              featureTypeForBtnVisible === featureType &&
                              editSelectedFeature?.properties?.featureType ===
                                featureType
                            "
                            (click)="
                              saveFeaturesByFeatureType(
                                group,
                                featureType,
                                layer
                              )
                            "
                            >Save</a
                          >
                        </h2>
                        <div
                          id="collapse-{{ j }}-content{{ layer }}-{{
                            getFinalGrp(featureType)
                          }}"
                          class="accordion-collapse collapse"
                          [attr.aria-labelledby]="'featureType-' + j"
                        >
                          <div
                            class="accordion-body custom-accordion-body"
                            *ngIf="
                              fetchFeaturesArray(group, featureType, layer)[0][
                                'featureName'
                              ]
                            "
                          >
                            <ul [id]="'featureType-' + j">
                              <li
                                *ngFor="
                                  let feature of fetchFeaturesArray(
                                    group,
                                    featureType,
                                    layer
                                  )
                                "
                                class="position-relative"
                              >
                                <img
                                  [src]="
                                    '../../assets/images/GPRS_PNG/' +
                                    featureType +
                                    '.png'
                                  "
                                  onerror="this.src='../../assets/images/GPRS_PNG/GENERIC_BRIAN.png';"
                                  *ngIf="
                                    feature.featureGeometryType.toLowerCase() ==
                                    'point'
                                  "
                                  [alt]="featureType"
                                />
                                <span
                                  [ngStyle]="{
                                    'background-color': fetchFeatureTypeColor(
                                      feature.featureTypeId
                                    )
                                  }"
                                  style="
                                    height: 4px;
                                    float: left;
                                    width: 20px;
                                    margin-top: 10px;
                                    margin-right: 5px;
                                  "
                                  *ngIf="
                                    feature.featureGeometryType.toLowerCase() ==
                                      'linestring' ||
                                    feature.featureGeometryType.toLowerCase() ==
                                      'line'
                                  "
                                ></span>
                                <i
                                  class="feature-style" [title]="feature.featureName"
                                  [ngClass]="{
                                    'text-primary':
                                      !isEditLayer &&
                                      editSelectedFeature?.properties
                                        ?.featureName === feature.featureName
                                  }"
                                  >{{ feature.featureName }}</i
                                >
                                <span class="sub-page-menu-action">

                                  <span class="history-icon" *ngIf="isEditLayer && layer !== 'SITE'">
                                    <i *ngIf="loginUserRole?.toLowerCase() === 'client' && layer === 'CLIENT' " class="fa fa-share-square"
                                    (click)="featureHistoryClick(layer, group, featureType, feature)"></i>
                                    <i *ngIf="loginUserRole?.toLowerCase() !== 'client' && layer === 'GPRS' " class="fa fa-share-square"
                                    (click)="featureHistoryClick(layer, group, featureType, feature)"></i>
                                  </span>

                                  <i
                                    *ngIf="isEditLayer"
                                    class="group-{{
                                      feature.featureName
                                    }} inner-group feature-name {{
                                      feature.featureName
                                    }} feature-group-margin"
                                    attr.data-name="{{ feature.featureName }}"
                                    attr.data-job-id="{{ feature.mapJobId }}"
                                    (click)="
                                      toggleFeature(
                                        group,
                                        featureType,
                                        feature.featureName,
                                        layer
                                      )
                                    "
                                    [ngClass]="
                                      checkEyeFeatureNameVisible(
                                        layer,
                                        group,
                                        featureType,
                                        feature.featureName
                                      )
                                        ? 'eye-visible'
                                        : 'eye-lash'
                                    "
                                  ></i>
                                  <i
                                    *ngIf="
                                      !isEditLayer &&
                                      editSelectedFeature?.properties
                                        ?.featureName != feature.featureName &&
                                      !feature.featureName.includes('temp')
                                    "
                                    class="map-delete-icon"
                                    (click)="
                                      deleteParticularFeature(
                                        layer,
                                        group,
                                        featureType,
                                        feature
                                      )
                                    "
                                  ></i>
                                </span>

                                <span
                                  class="sub-page-menu-action-save"
                                  *ngIf="
                                    !isEditLayer &&
                                    editSelectedFeature?.properties
                                      ?.featureName === feature.featureName
                                  "
                                >
                                  <i
                                  class="h6 d-inline-block align-middle" title="Undo" *ngIf="!isUndoDisabled(feature)" [ngClass]="{'disabled' : isUndoDisabled(feature)}"
                                  (click)="handleUndo(layer, feature)"
                                  style="width: 20px;">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff">
                                    <path d="M0,0H24V24H0Z" fill="none"/>
                                    <path d="M8.311,11.943a.662.662,0,1,1,0-1.324H14.48a1.958,1.958,0,0,0,1.955-1.956V7.128A1.958,1.958,0,0,0,14.48,5.17H2.228L5.061,7.937a.662.662,0,0,1-.924.947L.317,5.156a.665.665,0,0,1-.229-.9A.661.661,0,0,1,.2,4.113.668.668,0,0,1,.256,4.06L4.022.2a.662.662,0,1,1,.948.924L2.313,3.846H14.48a3.284,3.284,0,0,1,3.28,3.282V8.662a3.284,3.284,0,0,1-3.28,3.281Z" transform="translate(2.984 6.068)"/>
                                  </svg>
                                  </i>
                                  <i
                                  class="h6 d-inline-block align-middle ms-1" title="Redo" *ngIf="!isRedoDisabled(feature)" [ngClass]="{'disabled' : isRedoDisabled(feature)}"
                                  (click)="handleRedo(layer, feature)"
                                  style="width: 24px;">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff">
                                    <path d="M0,0H24V24H0Z" fill="none"/>
                                    <path d="M3.281,11.944A3.284,3.284,0,0,1,0,8.664V7.128A3.285,3.285,0,0,1,3.281,3.847h12.17L12.794,1.124A.662.662,0,1,1,13.741.2l3.765,3.859a.668.668,0,0,1,.059.054.666.666,0,0,1-.132,1.053L13.624,8.883a.662.662,0,1,1-.924-.948L15.533,5.17H3.281A1.96,1.96,0,0,0,1.324,7.128V8.664a1.959,1.959,0,0,0,1.957,1.957h6.17a.662.662,0,1,1,0,1.323Z" transform="translate(2.85 5.931)"/>
                                  </svg>
                                  </i>
                                  <i
                                    class="map-save-icon h6 ms-1"
                                    [ngClass]="(!isUndoDisabled(feature) || feature.featureName?.includes('temp')) ? '' : 'disabled'"
                                    *appPermission="permissionEnumList.CAN_EDIT_JOB_ON_MAP;elementShowHide:false;"
                                    (click)="saveSingleFeature(layer, feature)"
                                  ></i>
                                  <i
                                    class="map-close-icon h6"
                                    (click)="cancelFeature(layer, feature)"
                                  ></i>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--#end region-->
                    <!-- #region when feature type not available but feature available -->
                    <ng-template #onlyFeature>
                      <div class="accordion-body custom-accordion-body">
                        <ul>
                          <li
                            *ngFor="
                              let feature of fetchFeaturesArray(
                                group,
                                null,
                                layer
                              )
                            "
                            class="position-relative"
                          >
                            <img
                              [src]="
                                '../../assets/images/GPRS_PNG/' +
                                group.replace(' ', '') +
                                '.png'
                              "
                              onerror="this.src='../../assets/images/GPRS_PNG/GENERIC_BRIAN.png';"
                              *ngIf="
                                feature.featureGeometryType.toLowerCase() ==
                                'point'
                              "
                            />
                            <span
                              [ngStyle]="{
                                'background-color': fetchFeatureTypeColor(
                                  feature.featureId
                                )
                              }"
                              style="
                                height: 4px;
                                float: left;
                                width: 20px;
                                margin-top: 10px;
                                margin-right: 5px;
                              "
                              *ngIf="
                                feature.featureGeometryType.toLowerCase() ==
                                  'linestring' ||
                                feature.featureGeometryType.toLowerCase() ==
                                  'line'
                              "
                            ></span>
                            <i
                              class="feature-style"
                              [ngClass]="{
                                'text-primary':
                                  !isEditLayer &&
                                  editSelectedFeature?.properties
                                    ?.featureName === feature.featureName
                              }"
                              >{{ feature.featureName }}</i
                            >
                            <span class="sub-page-menu-action">
                              <!-- PAW Special Case for Photos.  Adding the class of is-photo so the visibility for each photo can be turned off-->
                              <i *ngIf="group == 'PHOTO' && isEditLayer"
                              class="group-{{
                                feature.featureName
                              }} inner-group feature-name feature-group-margin is-photo"
                              attr.data-name="{{ feature.featureName }}"
                              attr.data-job-id="{{ feature.mapJobId }}"
                              (click)="
                                toggleFeature(
                                  group,
                                  null,
                                  feature.featureName,
                                  layer
                                )
                              "
                              [ngClass]="
                                checkEyeFeatureNameVisible(
                                  layer,
                                  group,
                                  null,
                                  feature.featureName
                                )
                                  ? 'eye-visible'
                                  : 'eye-lash'
                              ">
                              </i>
                              <i
                                *ngIf="isEditLayer && group != 'PHOTO'"
                                class="group-{{
                                  feature.featureName
                                }} inner-group feature-name feature-group-margin "
                                attr.data-name="{{ feature.featureName }}"
                                attr.data-job-id="{{ feature.mapJobId }}"
                                (click)="
                                  toggleFeature(
                                    group,
                                    null,
                                    feature.featureName,
                                    layer
                                  )
                                "
                                [ngClass]="
                                  checkEyeFeatureNameVisible(
                                    layer,
                                    group,
                                    null,
                                    feature.featureName
                                  )
                                    ? 'eye-visible'
                                    : 'eye-lash'
                                "
                              ></i>
                              <i
                                *ngIf="
                                  isEditingModeIsOn &&
                                  editSelectedFeature?.properties
                                    ?.featureName !== feature.featureName
                                "
                                class="map-info-icon"
                                (click)="
                                  saveExternalLayerEditInfo(layer, feature)
                                "
                              ></i>

                              <i
                                *ngIf="
                                  isEditingModeIsOn &&
                                  editSelectedFeature?.properties
                                    ?.featureName !== feature.featureName
                                "
                                class="map-delete-icon"
                                (click)="
                                  deleteParticularFeature(
                                    layer,
                                    group,
                                    null,
                                    feature
                                  )
                                "
                              ></i>
                            </span>
                            <span
                              class="sub-page-menu-action-save"
                              *ngIf="
                                !isEditLayer &&
                                editSelectedFeature?.properties?.featureName ===
                                  feature.featureName
                              "
                            >
                              <i
                                class="map-save-icon h6"
                                (click)="saveExternalLayerEdit(layer, feature)"
                              ></i>
                              <i
                                class="map-close-icon h6"
                                (click)="cancelFeature(layer, feature)"
                              ></i>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </ng-template>
                    <!-- #endregion -->
                  </div>
                </ng-container>
              </div>
            </ng-container>
            </ng-container>

            <app-feature-tree
              *ngIf="isGroupMapCompleted && layer === 'IMPORTED' && importedLayerInfo "
              [layer]="importedLayerInfo"
              [events]="layerEvents"
              [featureTypeStyles]="featureTypeStyleMap"
              [rasterImages]="geoReferenceGISImagesData"
              [edit]="isEditingModeIsOn && editLayerName == layer"
              (onDeleteFile)="onDeleteFile(layer, $event)"
              (onDeleteGroup)="onDeleteGroup(layer, $event)"
              (onDeleteType)="onDeleteType(layer, $event)"
              (onDeleteFeature)="onDeleteFeature(layer, $event)"
              (onToggleVisibility)="onToggleLayerGroup(layer, $event)"
              (onToggleRasterImage)="toggleRasterItem($event)"
              (onRasterImageOpacityChange)="changeRasterItemOpacity($event)"
              (onEditRasterImage)="editGeoReferenceImageLayer($event)"
              (onDeleteRasterImage)="deleteRasterItem($event)"
              ></app-feature-tree>
            <ng-template #onlyReference>
              <div
                class="accordion-body custom-accordion-body"
                *ngIf="
                  referenceGISTilesData.length > 0 && layer === 'REFERENCE'
                "
              >
              <app-map-layer-menu-item *ngFor="let item of referenceLayerItems" [itemData]="item"
                   (onToggleItem)="toggleReferenceLayerItem($event)"
                   (onDeleteItem)="deleteReferenceLayerItem($event)"
                   [editMode]="isEditingModeIsOn && editLayerName === 'REFERENCE'"
                   ></app-map-layer-menu-item>

              </div>
            </ng-template>
          </div>
        </ng-container>
        <h2
          class="accordion-header"
          *ngIf="zoomedJobIds?.length === 1 && zoomedJobIds[0] === 27042"
        >
          <span> MapPlan_OrthomosaicFriOct21 </span>
          <span class="sub-page-menu-action">
            <i
              (click)="showHideImageInImportLayer('MPO-layer')"
              [ngClass]="
                isImportLayerImageVisible['MPO-layer']
                  ? 'eye-visible'
                  : 'eye-lash'
              "
            ></i>
            <i class="map-delete-icon"></i>
            <!-- (click)="deleteFeatureGroup(layer,group)" -->
          </span>
        </h2>
        <h2
          class="accordion-header"
          *ngIf="zoomedJobIds?.length === 1 && zoomedJobIds[0] === 68852"
        >
          <span> JennieStuartMedCenter </span>
          <span class="sub-page-menu-action">
            <i
              (click)="showHideImageInImportLayer('JSMC-layer')"
              [ngClass]="
                isImportLayerImageVisible['JSMC-layer']
                  ? 'eye-visible'
                  : 'eye-lash'
              "
            ></i>
            <i class="map-delete-icon"></i>
            <!-- (click)="deleteFeatureGroup(layer,group)" -->
          </span>
        </h2>
        <!-- <h3>
          IMPORTED LAYER -->
        <!-- <i (click)="toggleClientLayer()" [ngClass]="isGPRSLayerVisible ? 'eye-visible' : 'eye-lash'"></i> -->
        <!-- </h3> -->
        <!-- [ngClass]="{ 'disabled': loginUserRole != 'Client'}" -->
        <!-- <h3>
          CLIENT LAYER -->
        <!-- <i class="eye-lash"></i> -->
        <!-- </h3> -->
      </div>
    </div>
  </div>
</div>
<!-- Slide Menu Ends -->

<!-- Slide Tools Starts -->
<div
  class="side-main-menu tools right"
  [ngClass]="{
    open:
      slideMenuOpenTools &&
      !slideMenuOpenLayerDetails &&
      !slideMenuOpenFolder &&
      !slideMenuOpenJobDescription,
    'end-0':
      slideMenuOpenLayerDetails ||
      slideMenuOpenJobDescription ||
      slideMenuOpenFolder ||
      slideMenuOpenTools
  }"
>
  <div
    class="menu-first"
    (click)="
      slideMenuOpenJobDescription = false;
      slideMenuOpenLayerDetails = false;
      slideMenuOpenFolder = false;
      slideMenuOpenTools = !slideMenuOpenTools;
      onHistorySlideoutClose(false);
    "
    title="Tools"
  >
    <!-- <span class="fa fa-angle-left"></span> -->
    <img
      class="right-toggle"
      src="../../../../../assets/images/ico_tools.svg"
      alt="Tools"
    />
  </div>
  <div class="flex-shrink-0 p-0 bg-white sub-page-menu">
    <div class="sub-page-menu-header">
      <div class="role">
        <h2>TOOLS</h2>
      </div>
    </div>
    <div class="sub-page-menu-body" id="divTools">
      <ul class="list-unstyled tools ps-0">
        <li class="mb-1">
          <a href="javascript:void(0)" (click)="addActiveClass($event);handleSelect()"
            ><img
              src="../../assets/images/ico_selection.svg"
              alt="Selection"
            />Selection</a
          >
        </li>
        <!-- <li class="mb-1">
          <a (click)="measureLength()" style="cursor: pointer" id="measureLengthId"
            ><img
              src="../../assets/images/ico_measure_length.svg"
              alt="Measure Length"
            />Measure Length</a
          >
        </li>
        <li class="mb-1">
          <a (click)="measureArea()" style="cursor: pointer" id="measureAreaId"
            ><img
              src="../../assets/images/ico_measure_area.svg"
              alt="Measure Area"
            />Measure Area</a
          >
        </li> -->
        <!-- <li class="mb-1">
          <a href="javascript:void(0)"
            ><img
              src="../../assets/images/ico_create_site.svg"
              alt="Create Site"
            />Create Site</a
          >
        </li> -->
        <div class="sub-page-menu-header">
          <div class="role">
            <h2>Editing Tools</h2>
          </div>
        </div>
        <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="addActiveClass($event);handleMoveVertex()"
            [ngClass]="{disabled: !isEditingModeIsOn, active: isEditingModeIsOn && this.editingState.editingModes.isMovingVertex}"
          >
            <img
              src="../../assets/images/ico_move_vertex.svg"
              alt="Move Vertex"
            />Move Vertex</a
          >
        </li>
        <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="addActiveClass($event);handleAddVertex()"
            [ngClass]="{disabled: !isEditingModeIsOn, active: isEditingModeIsOn && this.editingState.editingModes.isAddingVertex}"
          >
            <img
              src="../../assets/images/ico_add_vertex.svg"
              alt="Add Vertex"
            />Add Vertex</a
          >
        </li>
        <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="addActiveClass($event);handleDeleteVertex()"
            [ngClass]="{disabled: !isEditingModeIsOn, active: isEditingModeIsOn && this.editingState.editingModes.isDeletingVertex}"
          >
            <img
              src="../../assets/images/ico_delete.svg"
              alt="Delete Vertex"
            />Delete Vertex</a
          >
        </li>
        <!-- <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="handleUpdatePoint()"
            [ngClass]="!isEditingModeIsOn ? 'disabled' : ''"
          >
            <img
              src="../../assets/images/ico_edit_vertex.svg"
              alt="Edit Vertex"
            />Edit Point</a
          >
        </li> -->
        <!-- <li class="mb-1">
          <a href="javascript:void(0)" (click)="handleDeletePoint()">
            <img src="../../assets/images/ico_delete_vertex.svg" alt="Delete Vertex" />Delete
            Point</a>
        </li> -->
        <!-- <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="handleAddLine()"
            [ngClass]="!isEditingModeIsOn ? 'disabled' : ''"
          >
            <img
              src="../../assets/images/ico_add_vertex.svg"
              alt="Add Vertex"
            />Add Line</a
          >
        </li> -->

        <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="addActiveClass($event);handleEndLine()"
            [ngClass]="!isEditingModeIsOn ? 'disabled' : ''"
          >
            <img src="../../assets/images/ico_end_line.svg" alt="End Line" />End
            Line</a
          >
        </li>
        <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="addActiveClass($event);handleMergeLine()"
            [ngClass]="{disabled: !isEditingModeIsOn, active: isEditingModeIsOn && this.editingState.editingModes.isMergingLine}"
          >
            <img
              src="../../assets/images/ico_merge_line.svg"
              alt="Merge Line"
            />Merge Line</a
          >
        </li>
        <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="addActiveClass($event);handleSnappingToVertex($event)"
            [ngClass]="{disabled: !isEditingModeIsOn, active: isEditingModeIsOn && isSnappingToVertex}"
          >
            <img
              src="../../assets/images/ico_snap_to_vertex.svg"
              alt="Snap To Vertex"
            />Snap To Vertex</a
          >
        </li>
        <li class="mb-1">
          <a
            href="javascript:void(0)"
            (click)="addActiveClass($event);handleSnappingToLine()"
            [ngClass]="{disabled: !isEditingModeIsOn, active: isEditingModeIsOn && isSnappingToLine}"
          >
            <img src="../../assets/images/ico_snap_to_line.svg" alt="Snap To Line" />Snap
            To Line</a
          >
        </li>
        <!-- <li class="mb-1" (click)="handleDeleteLine()">
          <a href="javascript:void(0)"><img src="../../assets/images/ico_delete_vertex.svg" alt="Delete Vertex" />Delete
            Line</a>
        </li> -->
        <!-- <li class="mb-1">
          <a href="javascript:void(0)" [ngClass]="!isEditingModeIsOn ? 'disabled' : ''"
            ><img
              src="../../assets/images/ico_freehand_polygon.svg"
              alt="Freehand Polyline"
            />Freehand Polyline</a
          >
        </li> -->
        <!-- <li class="mb-1">
          <a href="javascript:void(0)" (click)="handleUndo()" [ngClass]="!isEditingModeIsOn ? 'disabled' : ''"
            ><img
              src="../../assets/images/ico_draw_circle.svg"
              alt="Draw Circle"
            />Undo</a
          >
        </li> -->
        <!-- <li class="mb-1">
          <a href="javascript:void(0)" (click)="handleRedo()" [ngClass]="!isEditingModeIsOn ? 'disabled' : ''"
            ><img
              src="../../assets/images/ico_add_vertex.svg"
              alt="Split Line"
            />Redo</a
          >
        </li> -->
      </ul>
    </div>
  </div>
</div>
<!-- Slide Tools Ends -->

<!-- Slide Site Starts -->
<div
  class="side-main-menu site right"
  [ngClass]="{
    open:
      slideMenuOpenJobDescription &&
      !slideMenuOpenLayerDetails &&
      !slideMenuOpenFolder &&
      !slideMenuOpenTools,
    'end-0':
      slideMenuOpenTools || slideMenuOpenLayerDetails || slideMenuOpenFolder
  }"
>
  <div
    class="menu-first"
    (click)="
      slideMenuOpenTools = false;
      slideMenuOpenFolder = false;
      slideMenuOpenLayerDetails = false;
      slideMenuOpenJobDescription = !slideMenuOpenJobDescription;
      onHistorySlideoutClose(false);
    "
    title="Feature Description"
  >
    <img class="right-toggle" src="../../../../../assets/images/ico_description.svg" alt="Description" />
  </div>
  <div class="flex-shrink-0 p-0 bg-white sub-page-menu">
    <div class="sub-page-menu-body site">
      <div
        class="sub-page-menu-header"
        *ngIf="openedFeatureAttributes && editFormFeatureDescriptionView"
      >
        <div class="role">
          <h2>{{ openedFeatureName }}</h2>
          <h4>
            Description
            <span *ngIf="[2, '2'].includes(currentFeaturesLayer) && loginUserRole?.toLowerCase() === 'client'"
            (click)="editFormFeatureDescriptionOpen()"><i class="fa fa-pencil"></i> </span>
            <span *ngIf="![2, '2'].includes(currentFeaturesLayer) && loginUserRole?.toLowerCase() !== 'client'"
            (click)="editFormFeatureDescriptionOpen()"><i class="fa fa-pencil"></i> </span>
          </h4>
        </div>
        <p></p>
         <p>
          <strong>Date of Utility Investigation</strong>
          {{ openedFeatureAttributes.dateOfUtilityInvestigation }}
        </p>

        <p>
          <strong>Work Order Number</strong>
          {{ openedFeatureAttributes.workOrderNumber }}
        </p>

        <p>
          <strong>Locator Name</strong>
          {{ openedFeatureAttributes.locatorName }}
        </p>
        <p>
          <strong>Group Type</strong>
          {{ openedFeatureProperties.featureGroup }}
        </p>
        <p>
          <strong>Feature Type</strong>
          {{ openedFeatureProperties.featureType }}
        </p>
        <p><strong>Last Updated Date</strong> {{ openedFeatureAttributes.lastUpdatedDate }}</p>
        <p><strong>Last Updated By</strong> {{ openedFeatureAttributes.lastUpdatedBy }}</p>


        <p><strong>Accuracy</strong> {{ openedFeatureAttributes.accuracy }}</p>
        <p>
          <strong>Coordinates </strong>
          <span>{{ openedFeatureAttributes.coordinate.long }},</span>
          <span> {{ openedFeatureAttributes.coordinate.lat }}</span>
        </p>

        <p>
          <strong>Depth</strong> {{ openedFeatureAttributes.depthToTop }}
        </p>
        <p>
          <strong>Description</strong> {{ openedFeatureAttributes.description }}
        </p>
        <p>
          <strong>Notes</strong> {{ openedFeatureAttributes.notes }}
        </p>
        <p>
          <strong>SiteMap Attribute ID</strong> {{ openedFeatureProperties.featureAttributeId }}
        </p>
       <div
          class="linkFont"
          *ngFor="let addlink of openedFeatureAttributesAddedLink"
        >
          <a href="{{ addlink }}" target="_blank">
            {{ addlink }}
          </a>
        </div>
      </div>

      <!-- EDIT FEATURES DIV -->
      <div
        class="sub-page-menu-header"
        *ngIf="openedFeatureAttributes && !editFormFeatureDescriptionView"
      >
        <div class="role">
          <h2>{{ openedFeatureName }}</h2>
          <h4>
            Description
            <span (click)="editFormFeatureDescriptionClose()"
              ><i class="map-close-icon"></i>
            </span>
          </h4>
        </div>

        <form [formGroup]="updateFeatureDescriptionForm">
          <div
            class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
          >
            <label
              for="feature_dateOfUtilityInvestigation"
              class="form-label col-sm-6"
              >Date of Utility Investigation</label
            >
            <div class="col-sm-6">
              <input
                type="text"
                id="feature_dateOfUtilityInvestigation"
                class="form-control form-control-sm"
                [value]="openedFeatureAttributes.dateOfUtilityInvestigation"
                formControlName="dateOfUtilityInvestigation"
                [attr.disabled] = "true"
              />
            </div>
          </div>

           <div
            class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
          >
            <label for="feature_workOrderNumber" class="form-label col-sm-6"
              >Work Order Number</label
            >
            <div class="col-sm-6">
              <input
                type="text"
                id="feature_workOrderNumber"
                class="form-control form-control-sm"
                [value]="openedFeatureAttributes.workOrderNumber"
                formControlName="workOrderNumber"
                [attr.disabled]="true"
              />
            </div>
          </div>

          <div
            class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
          >
            <label for="feature_locatorName" class="form-label col-sm-6">
              Locator Name</label
            >
            <div class="col-sm-6">
              <input
                type="text"
                id="feature_locatorName"
                class="form-control form-control-sm"
                [value]="openedFeatureAttributes.locatorName"
                formControlName="locatorName"
                [attr.disabled]="true"
              />
            </div>
          </div>

          <div
            class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
          >
            <label for="feature_locatorName" class="form-label col-sm-6">
              Group Type</label
            >
            <div class="col-sm-6">
              <input
                type="text"
                id="feature_locatorName"
                class="form-control form-control-sm"
                [value]="openedFeatureProperties.featureGroup"

                [attr.disabled]="true"
              />
            </div>
          </div>
          <div
            class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
          >
            <label for="feature_locatorName" class="form-label col-sm-6">
              Feature Type</label
            >
            <div class="col-sm-6">
              <input
                type="text"
                id="feature_locatorName"
                class="form-control form-control-sm"
                [value]="openedFeatureProperties.featureType"

                [attr.disabled]="true"
              />
            </div>
          </div>
          <div
          class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
        >
          <label for="feature_locatorName" class="form-label col-sm-6">
            Last Updated Date</label
          >
          <div class="col-sm-6">
            <input
              type="text"
              id="feature_locatorName"
              class="form-control form-control-sm"
              [value]="openedFeatureAttributes.lastUpdatedDate"

              [attr.disabled]="true"
            />
          </div>
        </div>
        <div
        class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
      >
        <label for="feature_locatorName" class="form-label col-sm-6">
          Last Updated By</label
        >
        <div class="col-sm-6">
          <input
            type="text"
            id="feature_locatorName"
            class="form-control form-control-sm"
            [value]="openedFeatureAttributes.lastUpdatedBy"

            [attr.disabled]="true"
          />
        </div>
      </div>


          <div
            class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
          >
            <label for="feature_accuracy" class="form-label col-sm-6"
              >Accuracy</label
            >
            <div class="col-sm-6">
              <input
                type="text"
                id="feature_accuracy"
                class="form-control form-control-sm"
                [value]="openedFeatureAttributes.accuracy"
                formControlName="accuracy"
                [attr.disabled]="true"
              />
            </div>
          </div>
          <div
            class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
          >
            <label class="form-label col-sm-6">Coordinates</label>
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control form-control-sm"
                [value]="openedFeatureAttributes.coordinate.long"
                formControlName="coordinatelongtitude"
              />
            </div>
            <label class="form-label col-sm-6"> </label>
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control form-control-sm"
                [value]="openedFeatureAttributes.coordinate.lat"
                formControlName="coordinatelatitude"
              />
            </div>
          </div>




          <div
            class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
          >
            <label for="feature_depthToTop" class="form-label col-sm-6"
              >Depth</label
            >
            <div class="col-sm-6">
              <input
                type="text"
                id="feature_depthToTop"
                class="form-control form-control-sm"
                [value]="openedFeatureAttributes.depthToTop"
                formControlName="depthToTop"
              />
            </div>
          </div>
          <div
          class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
        >
          <label for="feature_description" class="form-label col-sm-6"
            >Description</label
          >
          <div class="col-sm-6">
            <input
              type="text"
              id="feature_description"
              class="form-control form-control-sm"
              [value]="openedFeatureAttributes.description"
              formControlName="description"
            />
          </div>
        </div>
        <div
        class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
      >
        <label for="feature_notes" class="form-label col-sm-6"
          >Notes</label
        >
        <div class="col-sm-6">
          <input
            type="text"
            id="feature_notes"
            class="form-control form-control-sm"
            [value]="openedFeatureAttributes.notes"
            formControlName="notes"
          />
        </div>
      </div>

      <div
      class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center"
    >
      <label for="feature_locatorName" class="form-label col-sm-6">
        SiteMap Attribute ID</label
      >
      <div class="col-sm-6">
        <input
          type="text"
          id="feature_locatorName"
          class="form-control form-control-sm"
          [value]="openedFeatureProperties.featureId"

          [attr.disabled]="true"
        />
      </div>
    </div>




        <div class="row edit-screen mt-2 mb-2 d-flex justify-content-center align-items-center">
          <div class="col-sm-12">
            <button *appPermission="permissionEnumList.CAN_EDIT_FEATURE_ATTRIBUTES;elementShowHide:false;" class="btn btn-primary btn-sm" (click)="updateFeaturesDescition()">Update</button>
            <button style="margin-left: 10px;" class="btn btn-primary btn-sm" (click)="editFormFeatureDescriptionClose()">Cancel</button>
          </div>
        </div>
        </form>
      </div>
      <!-- EDIT FEATURES DIV -->

      <div *ngIf="!openedFeatureAttributes">
        <h2 style="margin-top: 137px; margin-left: 20px">
          Please select a feature
        </h2>
      </div>

      <!-- <div class="sub-page-menu-header">
        <div class="role">
          <h2>Property Description <span class="map-edit-icon"></span></h2>
        </div>
      </div> -->

      <!-- <div class="sub-page-menu-header" *ngIf="propertyToShowInSideBar != null"> -->
      <div class="sub-page-menu-header">
        <!-- <div class="role">
          <h2>Customer Name - {{propertyToShowInSideBar.utiltiy_owner}}</h2>
        </div>
        <p><strong>Time:</strong> {{propertyToShowInSideBar.date_of_installation}}</p>
        <p><strong>Latitude:</strong> {{propertyToShowInSideBar.lat}}</p>
        <p><strong>Longitude:</strong> {{propertyToShowInSideBar.lng}}</p>
        <p><strong>Elevation:</strong> 569.284ft</p> -->
        <div class="img-card">
          <h2>Images</h2>
          <div class="img-card-list">
            <p>Data is not available</p>

            <!-- <img src="../../assets/images/img-01.png" alt="layer img" data-bs-toggle="modal"
              data-bs-target="#imageModal" (click)="onGetImageUrl($event)" />
            <img src="../../assets/images/img-02.png" alt="layer img" data-bs-toggle="modal"
              data-bs-target="#imageModal" (click)="onGetImageUrl($event)" />
            <img src="../../assets/images/img-03.png" alt="layer img" data-bs-toggle="modal"
              data-bs-target="#imageModal" (click)="onGetImageUrl($event)" />
            <img src="../../assets/images/img-04.png" alt="layer img" data-bs-toggle="modal"
              data-bs-target="#imageModal" (click)="onGetImageUrl($event)" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Slide Site Ends -->

<!-- Slide Folder Starts -->
<div
  class="side-main-menu folder right"
  [ngClass]="{
    open:
      slideMenuOpenFolder &&
      !slideMenuOpenJobDescription &&
      !slideMenuOpenLayerDetails &&
      !slideMenuOpenTools,
    'end-0':
      slideMenuOpenTools ||
      slideMenuOpenLayerDetails ||
      slideMenuOpenJobDescription
  }"
  *ngIf="showToggleSideBar"
>
  <div
    class="menu-first"
    (click)="
      slideMenuOpenTools = false;
      slideMenuOpenLayerDetails = false;
      slideMenuOpenJobDescription = false;
      slideMenuOpenFolder = !slideMenuOpenFolder;
      onHistorySlideoutClose(false);
      jobDocumentsListCall();
    "
    title="DPR Menus"
  >
    <!-- <span class="fa fa-angle-left"></span> -->
    <img
      class="right-toggle"
      src="../../../../../assets/images/ico_folder.svg"
      alt="Folder"
    />
  </div>
  <div class="flex-shrink-0 p-0 bg-white sub-page-menu">
    <div class="sub-page-menu-body folder">
      <div class="sub-page-menu-header">Digital Plan Room Content</div>
      <ng-container *ngIf="jobDocumentsListApiLoaded">
        <div class="linkFont" *ngIf="jobDocumentsList?.length != 0">
          <app-common-table
            [tableHeader]="fileTableHeader"
            (buttonClickEmitter)="btnClickdownloadMyFile($event)"
            [tableData]="fileTableData"
            tblClass="table-colored table-striped table-breakAllWord table-dpr tbr-none"
          >
          </app-common-table>
        </div>
        <div class="linkFont" *ngIf="jobDocumentsList?.length == 0">
          No Documents
        </div>
      </ng-container>
    </div>
  </div>
</div>
<!-- Slide Folder Ends -->

<!-- Export Modal -->
<div
  class="modal fade"
  id="printModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="printModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="printModalLabel">
          <span>Export</span>
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <form [formGroup]="exportForm">
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="col-lg-12 pb-0 border-bottom">
                <div class="mb-3 row">
                  <label for="printModalCRC" class="col-sm-3 col-form-label"
                    >File Type<span class="custom-form-label-star"
                      >*</span
                    ></label
                  >
                  <div class="col-sm-9">
                    <select
                      class="form-select form-select-sm"
                      aria-label="printModalCRC"
                      formControlName="filetype"
                    >
                      <option value="1">KML</option>
                      <option value="2">SW MAPS</option>
                      <option value="3">GeoPackage</option>
                      <option value="4">ShapeFile</option>
                      <option value="5">GeoJson</option>
                      <option value="6">DXF</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-2 pb-0 border-bottom">
                <div class="mb-3 row">
                  <label for="printModalScale" class="col-sm-3 col-form-label"
                    >File Name<span class="custom-form-label-star"
                      >*</span
                    ></label
                  >
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="printModalScale"
                      formControlName="filename"
                    />
                    <p
                      class="mb-0 text-danger"
                      *ngIf="
                        exportForm.get('filename')?.invalid &&
                        (exportForm.get('filename')?.dirty ||
                          exportForm.get('filename')?.touched) &&
                        exportForm.get('filename')?.errors?.required
                      "
                    >
                      *Please provide a file name.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button
            [disabled]="exportForm.invalid"
            (click)="generateExportLink()"
            type="button"
            class="btn btn-success"
            data-bs-dismiss="modal"
          >
            Export File
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- Export Modal -->

<!-- Print Modal -->
<div
  class="modal fade custom-print-modal"
  id="printModalNew"
  data-bs-backdrop="static"
  data-bs-keyboard="true"
  tabindex="-1"
  aria-labelledby="printModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-sm modal-dialog-centered1 modal-dialog-scrollable"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="printModalLabel">
          <span>Print Map</span>
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          (click)="printBoxDelete()"
          aria-label="Close"
        ></button>
      </div>
      <form [formGroup]="printForm">
        <div class="modal-body modal-body-print">
          <div class="row">
            <div class="col-lg-12">
              <!-- <div class="col-lg-12 pb-2 mb-2 border-bottom">
                <div class="d-flex justify-content-start align-items-center">
                  <button
                    class="btn btn-sm btn-primary"
                    aria-label="showPrintBox"
                    (click)="onPrintShowPrintBox($event)"
                  >
                    Show Print Box
                  </button>
                </div>
              </div>
              <div class="error-box" *ngIf="counter === 0 && printState">
                <p class="text-danger">Please click - Show Print Box</p>
              </div> -->
              <div class="col-lg-12 pb-2 mb-2 border-bottom">
                <div
                  class="d-flex justify-content-start align-items-center print-label-width"
                >
                  <label for="printModalCRC" class="form-label mt-1">Coordinate System</label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="printModalCRC"
                    formControlName="psCRS"
                  >
                    <option value="UTM" selected>UTM</option>
                    <option value="WGS84">WGS84</option>
                    <option value="SPCS">State Plane</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-12 pb-2 mb-2 border-bottom">
                <div class="input-group d-flex justify-content-start align-items-center print-label-width-scale">
                  <label for="printModalScale" class="form-label mt-1">Scale </label>
                  <input  type="number" class="form-control form-control-sm" pattern="\d+" name="psScale"  formControlName="psScale"  min="0" max="13000" minlength="1" maxlength="5" (change)="onPrintClickScale()" />
                  <span class="input-group-text input-group-text-sm scale-sign" id="printModalScale"></span>
                </div>
                <div *ngIf="printForm.controls.psScale">
                  <span
                    *ngIf="
                      printForm.controls.psScale &&
                      !printForm.controls.psScale.valid
                    "
                  >
                    <p class="text-danger">Enter a valid psScale</p>
                  </span>
                </div>
                <div class="error-box" *ngIf="printForm.value.psScale === null">
                  <p class="text-danger">{{scaleError}}</p>
                </div>
              </div>
              <div class="col-lg-12 pb-2 mb-2 border-bottom">
                <div
                  class="d-flex justify-content-start align-items-center print-label-width"
                >
                  <label for="printModalPsize" class="form-label mt-1"
                    >Paper Size</label
                  >
                  <select
                    class="form-select form-select-sm"
                    aria-label="printModalPsize"
                    formControlName="psSize"
                    (change)="onPrintClickPaperSize()"
                  >
                    <option value="letter" selected>ANSI A (8.5" x 11")</option>
                    <option value="legal">ANSI B (11" x 17")</option>
                    <option value="ansi-c">ANSI C (17" x 22")</option>
                    <option value="ansi-d">ANSI D (22" x 34")</option>
                    <option value="ansi-e">ANSI E (34" x 44")</option>
                    <!-- <option value="3">ARCH D (24" x 36")</option>
                    <option value="3">ARCH E (36" x 42</option>
                    <option value="3">ARCH E1 (30" x 42")</option>-->
                    <option value="a3">A3 (11.7" × 16.5")</option>
                    <option value="a4">A4 (8.3" × 11.7")</option>
                  </select>
                </div>
              </div>
              <div
                class="col-lg-12 pb-2 mb-2 border-bottom d-flex justify-content-start align-items-center"
              >
                <p class="form-label mt-1">Orientation</p>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    formControlName="psOrientation"
                    (change)="onPrintClickOrientation()"
                    name="psOrientation"
                    id="printModalPortrait"
                    value="portrait"
                  />
                  <label class="form-check-label" for="printModalPortrait"
                    >Portrait</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="psOrientation"
                    (change)="onPrintClickOrientation()"
                    id="printModalLandscape"
                    value="landscape"
                    formControlName="psOrientation"
                    checked
                  />
                  <label class="form-check-label" for="printModalLandscape"
                    >Landscape</label
                  >
                </div>
              </div>
              <div
                class="col-lg-12 d-flex justify-content-start align-items-center"
              >
                <p class="form-label mt-1 print-label-width-p">Format</p>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="psFormat"
                    id="printModalPDF"
                    value="PDF"
                    formControlName="psFormat"
                    checked
                  />
                  <label class="form-check-label" for="printModalPDF"
                    >PDF</label
                  >
                </div>
                <div
                  class="form-check form-check-inline d-flex justify-content-center"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="psFormat"
                    id="printModalJPG"
                    value="JPG"
                    formControlName="psFormat"
                  />
                  <label
                    class="form-check-label bug-radio-label"
                    for="printModalJPG"
                    >JPG</label
                  >
                </div>
              </div>
            </div>
            <!-- <div class="col-lg-5">
              <div class="print-preview d-flex justify-content-center align-items-center">
                <p>Preview window if applicable</p>
              </div>
            </div> -->
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="printModalGPrint"
              value="true"
              formControlName="psPrintGrid"
              (click)="onGridPrintClick()"
            />
            <label
              class="form-check-label"
              for="printModalGPrint"
              style="color: #000"
              >Grid Print</label
            >
          </div>
          <button
            type="button"
            class="btn btn-success"
            (click)="onPrintClick()"
          >
            Print
          </button>
          <!-- data-bs-dismiss="modal" -->
        </div>
      </form>
      <!-- {{printForm.value | json}} -->
    </div>
  </div>
</div>
<!-- Print Modal -->

<!-- Image Modal -->
<div
  class="modal fade"
  id="imageModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="imageModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="imageModalLabel">
          <span>Image Name</span>
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div
              class="d-flex justify-content-center align-items-center download-button"
            >
              <img src="{{ imgSrc }}" alt="image name" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-bs-dismiss="modal"
        >
          Close
        </button>
        <a href="{{ imgSrc }}" class="btn btn-success btn-sm" download
          ><img
            src="{{ imgSrc }}"
            alt="image name"
            class="img-fluid"
            style="display: none"
          />Download</a
        >
      </div>
    </div>
  </div>
</div>

<!-- Print HTML NITIN -->
<div id="section-to-print" style="display: block">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 p-0">
        <div class="print-container">
          <div class="print-container_header">
            <div class="side">
              <h1>N</h1>
              <img src="../../assets/images/logo_print.png" alt="Logo" />
            </div>
          </div>
          <div class="side-text">
            <ol type="1">
              <li>
                1. Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make.
              </li>
              <li>
                2. It was popularised in the 1960s with the release of Letraset
                sheets containing Lorem Ipsum passages, and more.
              </li>
              <li>
                3. It was popularised in the 1960s with the release of Letraset
                sheets containing Lorem.
              </li>
              <li>
                4. It was popularised in the 1960s with the release of Letraset
                sheets containing Lorem Ipsum.
              </li>
            </ol>
          </div>

          <div class="side-legend">
            <h6>LEGEND</h6>
            <ul>
              <li *ngFor="let symbology of lineSymbology">
                <div>
                  <span [ngStyle]="{'background-color': symbology.color, 'border': '2px solid ' + symbology.color  }"></span><i>{{ symbology.name }}</i>
                </div>
              </li>
            </ul>
          </div>

          <div class="flex-container right-side" id="legend">
            <div *ngFor="let icon of featureTypeSymbologyMap.point" class="flex-child img">
              <img src="../../assets/images/GPRS_PNG/{{icon}}.png" alt="{{icon}}" />{{formatIconName(icon)}}
            </div>
          </div>



          <!-- <div class="side-legend">
            <h6>LEGEND</h6>
            <ul>
              <li><span class="black"></span><i>SCAN AREA</i></li>
              <li><span class="green"></span><i>NO SCAN</i></li>
              <li><span class="yellow"></span><i>TELECOM</i></li>
              <li><span class="ajeeb"></span><i>GASFUEL</i></li>
              <li><span class="orange"></span><i>ELECTRICAL</i></li>
              <li><span class="pink"></span><i>UNKNOWN</i></li>
              <li><span class="blue"></span><i>WATER</i></li>
              <li><span class="blue-light"></span><i>IRRIGATION</i></li>
              <li><span class="green-light"></span><i>STORM</i></li>
              <li><span class="grey-light"></span><i>MISC LINE</i></li>
              <li><span class="green-light"></span><i>SANITARY</i></li>
              <li><span class="grey-update"></span><i>BY OTHERS</i></li>
            </ul>
          </div> -->

        <!--  <div class="side-icons">
            <ul>
              <li>
                <img
                  src="../../assets/images/GPRS/ELEC_CABINET.svg"
                  alt="ELEC_CABINET"
                /><i>ELEC CABINET</i>
              </li>
              <li>
                <img
                  src="../../assets/images/GPRS/COMM_PEDESTAL.svg"
                  alt="COMM_PEDESTAL"
                /><i>COMM PEDESTAL</i>
              </li>
              <li>
                <img
                  src="../../assets/images/GPRS/ELEC_METER.png"
                  alt="ELEC_METER"
                /><i>ELEC METER</i>
              </li>
              <li>
                <img
                  src="../../assets/images/GPRS/COMM_POLE.svg"
                  alt="COMM_POLE"
                /><i>COMM POLE</i>
              </li>
              <li>
                <img
                  src="../../assets/images/ELEC_PANEL.png"
                  alt="ELEC_PANEL"
                /><i>ELEC PANEL</i>
              </li>
              <li>
                <img
                  src="../../assets/images/COMM_CAMERA.png"
                  alt="COMM_CAMERA"
                /><i>CAMERA</i>
              </li>
              <li>
                <img
                  src="../../assets/images/GPRS/ELEC_EQUIPMENT.svg"
                  alt="ELEC_EQUIPMENT"
                /><i>ELEC EQUIPMENT</i>
              </li>
              <li>
                <img
                  src="../../assets/images/GPRS/GAS_PUMP.svg"
                  alt="GAS_PUMP"
                /><i>GAS PUMP</i>
              </li>

              <li>
                <img
                  src="../../assets/images/GPRS/ELEC_SIGN.svg"
                  alt="ELEC_SIGN"
                /><i>ELEC SIGN</i>
              </li>
              <li>
                <img
                  src="../../assets/images/GPRS/FIRE_HYDRANT.svg"
                  alt="FIRE_HYDRANT"
                /><i>FIRE HYDRANT</i>
              </li> -->
              <!-- ===spelling=== -->
             <!-- <li>
                <img
                  src="../../assets/images/GPRS/ELEC_TRANSFORMER.svg"
                  alt="ELEC_TRANSFORMER"
                /><i>TRANSFORMER</i>
              </li>
              <li>
                <img
                  src="../../assets/images/GPRS/WTR_METER.svg"
                  alt="WTR_METER"
                /><i>WATER METER</i>
              </li>
              <li>
                <img
                  src="../../assets/images/GPRS/ELEC_LANDSCAPELIGHT.svg"
                  alt="ELEC_LANDSCAPELIGHT"
                /><i>ELEC LANDSCAPELIGHT</i>
              </li>
              <li>
                <img
                  src="../../assets/images/GPRS/STRM_CATCHBASINROUND.svg"
                  alt="STRM_CATCHBASINROUND"
                /><i>CATCH BASIN</i>
              </li> -->

              <!-- =====icon missing===== -->
             <!-- <li>
                <img
                  src="../../assets/images/GPRS_PNG/COMM_POLE.png"
                  alt="COMM_POLE"
                /><i>LIGHT POLE</i>
              </li>
              <li>
                <img
                  src="../../assets/images/STRM_CATCHBASINROUND.png"
                  alt="STRM_CATCHBASINROUND"
                /><i>CATCH BASIN</i>
              </li>
              <li>
                <img
                  src="../../assets/images/GPRS/ELEC_POWERPOLE.svg"
                  alt="ELEC_POWERPOLE"
                /><i>POWER POLE</i>
              </li>

              <li>
                <img
                  src="../../assets/images/GPRS/ELEC_POWERPOLE.svg"
                  alt="ELEC_POWERPOLE"
                /><i>CURB INLET</i>
              </li>
              <li>
                <img
                  src="../../assets/images/GPRS/ELEC_UTILITYPOLE.svg"
                  alt="ELEC_UTILITYPOLE"
                /><i>UTILITY POLE</i>
              </li>

              <li>
                <img
                  src="../../assets/images/GPRS/STRM_ENDPIPE.svg"
                  alt="STRM_ENDPIPE"
                /><i>STORM ENDPIPE</i>
              </li>

              <li>
                <img
                  src="../../assets/images/GPRS/SAN_MARKER.svg"
                  alt="SAN_MARKER"
                /><i>SAN MAKER</i>
              </li>

              <li>
                <img
                  src="../../assets/images/GPRS/BUILDING CORNER.png"
                  alt="BUILDING CORNER"
                /><i>BUILDING CORNER</i>
              </li>

              <li>
                <img
                  src="../../assets/images/GPRS/SAN_GREASETRAP.svg"
                  alt="SAN_GREASETRAP"
                /><i>GREASE TRAP</i>
              </li>

              <li>
                <img
                  src="../../assets/images/GPRS/SOIL_BORING.svg"
                  alt="SOIL_BORING"
                /><i>SOIL BORING</i>
              </li>

              <li>
                <img
                  src="../../assets/images/GPRS/AIR_RISER.svg"
                  alt="AIR_RISER"
                /><i>RISER</i>
              </li>

              <li>
                <img
                  src="../../assets/images/GPRS/GAS_AST.svg"
                  alt="GAS_AST"
                /><i>UST / AST</i>
              </li>

              <li>
                <img
                  src="../../assets/images/GPRS/SAN_LIFTSTATION.svg"
                  alt="SAN_LIFTSTATION"
                /><i>LIFT STATION</i>
              </li>

              <li>
                <img
                  src="../../assets/images/GPRS/SAN_LIFTSTATION.svg"
                  alt="SAN_LIFTSTATION"
                /><i>VENT</i>
              </li>

              <li>
                <img
                  src="../../assets/images/GPRS/FIRE_BACKFLOWPREVENTOR.svg"
                  alt="FIRE_BACKFLOWPREVENTOR"
                /><i>BACKFLOW PREV</i>
              </li>

              <li>
                <img
                  src="../../assets/images/GPRS/GAS_VALVE.svg"
                  alt="GAS_VALVE"
                /><i>VALVE</i>
              </li>

              <li>
                <img
                  src="../../assets/images/GPRS/SAN_CLEANOUT.svg"
                  alt="SAN_CLEANOUT"
                /><i>CLEAN OUT</i>
              </li>

              <li>
                <img
                  src="../../assets/images/GPRS/WTR_POSTINDICATORVALVE.svg"
                  alt="WTR_POSTINDICATORVALVE"
                /><i>PST ND VALVE</i>
              </li>

              <li>
                <img
                  src="../../assets/images/GPRS/TRAF_BOX.svg"
                  alt="TRAF_BOX"
                /><i>BOX</i>
              </li>
              <li>
                <img
                  src="../../assets/images/GPRS/MISC_CONTINUES.svg"
                  alt="MISC_CONTINUES"
                /><i>MISCELLANEOUS</i>
              </li>
              <li>
                <img
                  src="../../assets/images/GPRS/GAS_VAULT.svg"
                  alt="VALUT"
                /><i>VALUT</i>
              </li>
              <li>
                <img
                  src="../../assets/images/GPRS/CHEM_AST.svg"
                  alt="GAS_VAULT"
                /><i>END OF INFO</i>
              </li>
              <li>
                <img
                  src="../../assets/images/GPRS/AIR_MANHOLE.svg"
                  alt="AIR_MANHOLE"
                /><i>MANHOLE</i>
              </li>
              <li>
                <img
                  src="../../assets/images/GPRS/COMM_CONTINUES.svg"
                  alt="COMM_CONTINUES"
                /><i>LINE CONTINUES</i>
              </li>
            </ul>
          </div> -->

          <div class="side-footer">
            <img src="../../assets/images/pdfgprsv2.png" alt="" />
            <button>FOR INFORMATION ONLY</button>
            <h2>SITEMAP RECORD EXHIBIT</h2>
            <span>LOCATION</span>
            <i>SiteMap</i>
            <p>Print by: {{ loginUserEmailId }}</p>
            <p>Print date: {{ currentDate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ADD Link -->
<!-- Add New Role Modal -->
<div
  class="modal fade"
  id="addLink"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="addLinkLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="addLinkLabel">
          <span>Add New Link</span>
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="mb-3 link-example">
          <label for="addLinksInput" class="form-label">Add Links</label>
          <input
            class="form-control"
            #addLinksInput
            id="addLinksInput"
            rows="3"
          />
          <!-- (keydown.space)="$event.preventDefault()"  -->
          <!-- <textarea class="form-control" #addLinksInput id="addLinksInput" rows="3"></textarea> -->
          <!-- <p>Example: https://www.example.com/my-link-1, https://www.example.com/my-link-2</p> -->
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-bs-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-success btn-sm"
          [disabled]="!addLinksInput.value?.trim()"
          data-bs-dismiss="modal"
          (click)="matterportLinkAddUpdate(addLinksInput.value)"
        >
          Add Link
        </button>
      </div>
    </div>
  </div>
</div>

<!--Measurements Modal-->
<div
  class="modal fade"
  id="MeasurementsModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="MeasurementModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="MeasurementModalLabel">
          <span>Measurements Tools No Access</span>
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        Measurement tools have been set as off. Please change it in My
        preferences to view the same.
      </div>
    </div>
  </div>
</div>

<!--Map photo Modal -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#featurePhotoViewer" hidden id="btnModalPhoto"
  aria-labelledby="btnModalPhoto"></button>
<div class="modal fade" id="featurePhotoViewer" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="featurePhotoViewerLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="featurePhotoViewerLabel">
          <span>{{ modalHeader }}</span>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="modalHeader == 'PHOTO'; else externalContentTemplate">
          <p>
            <span class="pe-4">Site ID: <span class="ps-1" id="siteId"></span></span>
            <span>WO No.: <span id="woNo" class="ps-1"></span></span>
          </p>
          <hr />
          <img id="imgPhoto" class="img-fluid" alt="Photo" />
        </ng-container>

        <ng-template #externalContentTemplate>
          <p class="external-feature-data" *ngIf="externalContentTemplateData?.siteId != '0'">
            <strong>Site Id: </strong>
            <span>{{ externalContentTemplateData?.siteId }}</span>
          </p>

          <p class="external-feature-data">
            <strong>WO No.: </strong>
            <span>{{ externalContentTemplateData?.woNo }}</span>
          </p>

          <p class="external-feature-data" *ngIf="externalContentTemplateData?.siteId != '0'">
            <strong>Site Name: </strong>
            <span>{{ externalContentTemplateData.siteName }}</span>
          </p>
          <p class="external-feature-data">
            <strong>Feature Name: </strong>
            <span>{{ externalContentTemplateData.featureName }} </span>
          </p>

          <div *ngIf="(modalHeader | lowercase) == 'virtual tour'">
            <!-- show VT LINKS  -->
            <app-common-table   [tableHeader]="matterportTableHeader"
              [tableData]="matterportLinkArray"
              (buttonClickEmitter)="btnShowVT($event)"              
              idColumnName="linkURL"              
              tblClass="table-striped table-colored-virtual-tour table-bordered custom-table table-colored">
            </app-common-table>
          </div>

          <div class="modal-footer">
            <button *ngIf="modalHeader == 'EXTERNAL LINK'" type="button" data-bs-toggle="modal" data-bs-target=""
              class="btn btn-primary btn-sm"
              (click)="gotolink(externalContentTemplateData.featureLink)">
              Open
            </button>
            <button *ngIf="modalHeader != 'EXTERNAL LINK' && modalHeader != 'VIRTUAL TOUR'" type="button" data-bs-toggle="modal" data-bs-target="#featureExternalViewer"
              class="btn btn-primary btn-sm" >
              Open
            </button>
            <!-- <button type="button" class="btn btn-primary btn-sm">Share</button> -->
          </div>
        </ng-template>
      </div>

      <!-- <div class="modal-footer" #externalContentTemplate>
        <button type="button" data-bs-toggle="modal" data-bs-target="#featureExternalViewer" class="btn btn-primary btn-sm">Open</button>

      </div> -->
    </div>
  </div>
</div>

<!--External Link Modal -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#featureExternalViewer" hidden
  id="btnModalExtenal" aria-labelledby="btnModalExternal"></button>
<div class="modal fade matterport-video" id="featureExternalViewer" data-bs-backdrop="static" data-bs-keyboard="false"
  tabindex="-1" aria-labelledby="featureExternalViewerLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl custom-modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="featureExternalViewerLabel">
          <span>{{ modalHeader }}</span>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ng-container>
          <div class="embed-responsive embed-responsive-16by9 matterport-frame">
            <iframe *ngIf="iframeExternalContentLink" class="embed-responsive-item" id="externalContentLink"></iframe>
            <p *ngIf="!iframeExternalContentLink" class="noPreviewText">No Preview link available</p>
          </div>
          <!-- <iframe width='100%' height='100%' id="externalContentLink" frameborder='0' allowfullscreen allow='xr-spatial-tracking'>{{externalContentTemplateData.featureName}}</iframe> -->
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!--Import file Modal-->
<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#importFile" style="position: absolute; top:50px;left: 100px;z-index: 1045;">
  Import file
</button> -->
<!-- Imported Modal -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#importFile" hidden id="btnImportFile"
  aria-labelledby="btnImportFile"></button>
<div class="modal fade" id="importFile" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="importFileLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <!-- <app-map-import [importJobId]="navigatedJobId"></app-map-import> -->
      <div class="modal-header">
        <h5 class="modal-title" id="importFileLabel">Import File</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="importMajorCancel()"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="input-group mb-3">
              <input #myImportFile type="file" (change)="onFileSelected('importfile', $event)"
                class="form-control form-control-sm" id="inputGroupFile02" [accept]="fileExtensions"/>
              <label class="input-group-text" for="inputGroupFile02" hidden>Upload</label>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="fileImportType" class="form-label fs-12">File Type
              </label>
              <input type="text" disabled class="form-control form-control-sm" id="fileImportType"
                value="{{ importedFileType }}" placeholder="File Type" />
            </div>
          </div>
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="importFileName" class="form-label fs-12">File Name</label>
              <input type="text" disabled class="form-control form-control-sm" id="importFileName"
                placeholder="File name" value="{{ importedFileName }}" />
            </div>
          </div>

          <div *ngIf="isImportingReferenceLayer" class="col-lg-12">
            <div class="mb-3">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="importedIsPublic"
                  id="light-mode" [value]="false" [(ngModel)]="importedIsPublic" >
                <label class="form-check-label" for="light-mode">GPRS Only
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="importedIsPublic"
                  id="dark-mode" [value]="true" [(ngModel)]="importedIsPublic">
                <label class="form-check-label" for="dark-mode">Public</label>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal" id="cancelImport">
          Cancel
        </button>

        <button type="button" class="btn btn-sm btn-primary" [disabled]="!importSubmitted" (click)="checkImportFileType()">Next</button>
      </div>
    </div>
  </div>
</div>
<!-- <button type="button" class="btn btn-sm btn-primary" [disabled]="!importSubmitted" (click)="publishUploadFile()">Import</button>  -->
<!-- Imported Points & Lines Modal -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#importPointsLines" hidden id="btnimportPointsLines"
  aria-labelledby="btnimportPointsLines"></button>
<div class="modal fade" id="importPointsLines" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="importPointsLinesLabel" aria-hidden="true">
  <form [formGroup]="importGISForm">
    <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="importPointsLinesLabel" *ngIf="!checkImportFileTypeRaster">
            Import Points & Lines
          </h5>
          <h5 class="modal-title" id="importPointsLinesLabel" *ngIf="checkImportFileTypeRaster">
            Import Imagery
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="importMajorCancel()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <p class="fs-12" *ngIf="!checkImportFileTypeRaster">
                Are you importing GIS job-related data that you exported from
                SiteMap, or custom job-related data? Or would you like to import
                reference GIS data, e.g. boundaries or points of interest, etc?
              </p>
              <p class="fs-12" *ngIf="checkImportFileTypeRaster">
                Are you importing geo-referenced imagery - this is imagery that
                has spatial information and can be overlayed onto a map? Or are
                you importing un-georeferenced imagery that you want to manually
                geo-reference now?
              </p>
            </div>
            <div class="col-lg-12">
              <div *ngIf="!checkImportFileTypeRaster">
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="importSelectFormControl"
                    value="importDataGIS" (change)="changeImportRadio($event)" />
                  <label class="form-check-label fs-12" for="GISDataExportedfromSiteMap">
                    Import GIS job-related data that was exported from SiteMap
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="importSelectFormControl"
                    value="customGIS" (change)="changeImportRadio($event)" />
                  <label class="form-check-label fs-12" for="customGISDataRadio">
                    Import Custom GIS job-related data
                  </label>
                </div>
                <!-- <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="importSelectFormControl"
                    value="referenceGIS" (change)="changeImportRadio($event)" />
                  <label class="form-check-label fs-12" for="customGISDataRadio">
                    Import reference GIS data (boundaries, points of interest,
                    etc.)
                  </label>
                </div> -->
              </div>
              <div *ngIf="checkImportFileTypeRaster">
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="importSelectFormControl"
                    value="importGeoReferenceImage" (change)="changeImportRadio($event)" />
                  <label class="form-check-label fs-12" for="GISDataExportedfromSiteMap">
                    Geo-referenced imagery
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="importSelectFormControl"
                    value="importGeoReferenceImageManually" (change)="changeImportRadio($event)" />
                  <label class="form-check-label fs-12" for="customGISDataRadio">
                    Imagery that needs to be manually geo-referenced
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal" id="cancelImportGIS">
            Cancel
          </button>
          <button type="button" class="btn btn-sm btn-primary" *ngIf="isSubmitButtonVisible"
            (click)="publishUploadFile('withoutCustom')">
            Submit
          </button>
          <button type="button" class="btn btn-sm btn-primary" *ngIf="isSubmitButtonVisibleGISReference"
            (click)="publishUploadFile('withoutCustomGISReference')">
            Submit
          </button>
          <button type="button" class="btn btn-sm btn-primary" *ngIf="isSelectButtonVisible" data-bs-toggle="modal"
            (click)="publishUploadFile('Custom')">
            Select
          </button>
          <button type="button" class="btn btn-sm btn-primary" *ngIf="isSubmitNonGeoReferenceImage"
            (click)="loadingNonGeoReferenceImage()">
            Submit
          </button>
          <!-- data-bs-target="#customGISData" -->
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Custom GIS Data Modal -->
<div class="modal fade" id="customGISData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="customGISDataLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="customGISDataLabel">Custom GIS Data</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <label for="ctfi" class="form-label fs-12">Choose the type of feature you'd like to import</label>
            <select class="form-select form-select-sm mb-3" aria-label=".form-select-sm example" id="ctfi">
              <option value="1">Regular Point</option>
              <option value="2">Annotation Point</option>
              <option value="3">Regular Line</option>
              <option value="4">Annotation Line</option>
            </select>
          </div>
          <div class="col-lg-12">
            <label for="ctcfg" class="form-label fs-12">Choose the column in your that corresponds to the
              <strong>Feature Group</strong> column <br />(based on industry
              standards).</label>
            <select class="form-select form-select-sm mb-3" aria-label=".form-select-sm example" id="ctcfg"
              [(ngModel)]="selectImportFeatureGroup">
              <option *ngFor="let item of customImportFeaturesGroup" [value]="item">
                {{ item }}
              </option>
            </select>
          </div>

          <div class="col-lg-12">
            <label for="ctcftc" class="form-label fs-12">Choose the column in your that corresponds to the
              <strong>Feature Type</strong> column <br />(based on industry
              standards).</label>
            <select class="form-select form-select-sm" aria-label=".form-select-sm example" id="ctcftc"
              [(ngModel)]="selectImportFeatureType">
              <option *ngFor="let item of customImportFeaturesType" [value]="item">
                {{ item }}
              </option>
            </select>
          </div>

          <div class="col-lg-12">
            <hr />
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <p class="fs-12 mb-2">
              Map value for <strong>Feature Group</strong> from your data to
              industry standard values
            </p>
          </div>
          <div class="col-lg-6">
            <label for="fgsv" class="visually-hidden">fgsv</label>
            <input class="form-control form-control-sm" type="text" placeholder="..."
              aria-label=".form-control-sm example" id="fgsv" />
          </div>
          <div class="col-lg-6">
            <label for="ftsv" class="visually-hidden">ftsv</label>
            <select class="form-select form-select-sm mb-3" aria-label=".form-select-sm example" id="ftsv">
              <option value="1">COMMUNICATION</option>
              <option value="2">ELECTRICAL</option>
              <option value="3">FIREWA</option>
              <option value="4">GAS</option>
              <option value="5">IRRIGATION</option>
              <option value="6">MISC</option>
              <option value="7">PHOTO</option>
              <option value="8">PRODUCT</option>
              <option value="9">SANITARY</option>
              <option value="10">SCAN INFO</option>
              <option value="11">STROM</option>
              <option value="12">TRAFFIC</option>
              <option value="13">UNKNOWN</option>
              <option value="14">WATER</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <p class="fs-12 mb-2">
              Map value for <strong>Feature Type</strong> from your data to
              industry standard values
            </p>
          </div>
          <div class="col-lg-6">
            <label for="fttsv" class="visually-hidden">fttsv</label>
            <input class="form-control form-control-sm" type="text" placeholder="..."
              aria-label=".form-control-sm example" id="fttsv" />
          </div>
          <div class="col-lg-6">
            <label for="fttysv" class="visually-hidden">fttysv</label>
            <select class="form-select form-select-sm mb-3" aria-label=".form-select-sm example" id="fttysv">
              <option value="1">COMM_BOX</option>
              <option value="2">COMM_CAMERA</option>
              <option value="3">COMM_CONTINUES</option>
              <option value="4">COMM_EOI</option>
              <option value="5">COMM_MANHOLE</option>
              <option value="6">COMM_MISC</option>
              <option value="7">COMM_PEDESTAL</option>
              <option value="8">COMM_POLE</option>
              <option value="9">COMM_SNAPPOINT</option>
              <option value="10">COMM_VAULT</option>
              <option value="11">COMMUNICATION LINE</option>
              <option value="12">COMMUNICATION LINE DUCT</option>
              <option value="13">COMMUNICATION STRUCTURE OUTLINE</option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button type="button" class="btn btn-sm btn-primary" (click)="importCustomFormData()">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Custom CAD Data Modal -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#customCADData" hidden id="btnCustomCADData"
  aria-labelledby="btnCustomCADData"></button>
<div class="modal fade" id="customCADData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="customCADDataLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="customGISDataLabel">Import CAD Data</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="cadCustomForm" (ngSubmit)="onCADCustomSubmit()" class="mr-4">
          <!-- ///////////////////////////////// -->
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <p class="custom-text-small mb-0">
                Find information about your CAD data being imported below.
                Please, select the correct coordinate system that the data is drawn in.
                Data that is not tied to a coordinate system will not be imported to the correct location
                and may require manual georeferencing in CAD prior to import. Also included is
                information about the layers in your data that we could not map directly to our
                National CAD Standard Layer or GPRS Standard Layer.
                Please, manually select the correct group and layer
                as the items will need to be mapped to a layer in order for the file to import.
              </p>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <hr />
            </div>
            <div class="col-lg-6 col-md-10 col-sm-10">
              <div class="mb-3">
                <label for="coordinateRS"
                  class="form-label custom-form-label-small position-relative width-full">Coordinate Reference System
                  <div class="customToolTip">
                    <div class="customToolTipContent">
                      Coordinate Reference System
                    </div>
                  </div>
                </label>
                <select class="form-select form-select-sm mb-3" aria-label=".form-select-sm example" id="coordinateCRS"
                  formControlName="coordinateCRSName">
                  <option *ngFor="let itemCRS of crsDropDownData" [ngValue]="itemCRS">
                    {{ itemCRS.description }} ({{ itemCRS.auth_name }} :
                    {{ itemCRS.auth_id }} )
                  </option>
                </select>

                <!-- <span class="map-info-icon set-info"></span> -->
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <p class="custom-text-small mb-0">
                <strong>
                  Your layers to Feature Groups and Feature Types
                </strong>
              </p>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <hr />
            </div>
          </div>
          <div class="row" formArrayName="cadFeatures">
            <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="
                let cadFeature of cadFeatures().controls;
                let cadIndex = index
              ">
              <div class="row label-hide" [formGroupName]="cadIndex">
                <div class="col-lg-6 col-md-4 col-sm-4">
                  <div class="mb-3">
                    <!-- {{cadIndex}} -->
                    <label for="'staticEmail'+cadIndex" class="form-label custom-form-label-small"
                      [ngClass]="cadIndex == 0 ? 'show' : 'hide'">
                      Original Layer Name
                    </label>
                    <input type="text" class="form-control form-control-bold" id="'staticEmail'+cadIndex"
                      formControlName="featureName" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-4 featureGroupNotRepeat">
                  <div class="mb-3">
                    <label for="'featureGroupModal'+cadIndex" class="form-label custom-form-label-small"
                      [ngClass]="cadIndex == 0 ? 'show' : 'hide'">
                      Feature Group
                      <div class="customToolTip feature-group-hint">
                        <div class="customToolTipContent feature-group-hint">
                          <em>INSTRUCTIONS</em><br>
                          Begin typing a value to map a layer to a "Feature Group." After typing two letters, a list will appear. Choose the correct value from the list. For example, to map a layer as ELECTRICAL , type "EL" and then choose the value from the list. To map a layer to C-BLDG, type "C-" and then choose the value from the list.<br><br>
                          <em>Common GPRS Feature Groups</em><br>
                          GAS<br>
                          ELECTRICAL<br>
                          WATER<br>
                          <br>
                          <em>Common CAD Standard Feature Groups</em><br>
                          A-DOOR<br>
                          C-BLDG<br>
                          E-SITE<br>
                        </div>
                      </div>
                    </label>
                    <input type="text" class="form-control form-control-sm" id="'featureGroupModal'+cadIndex"
                      placeholder="Feature Group" [attr.list]="'datalistOptions' + cadIndex"
                      formControlName="featureGroup" (keyup)="searchFeatureGroup(cadIndex)" />
                  </div>
                  <div *ngIf="importFeatureGroupSelectedIndex === cadIndex">
                    <ul class="list-showing list-showing-absolute">
                      <li *ngFor="let item of filteredOptionsFeatureGroup">
                        <a (click)="onselectFeatureGroupImport(item, cadIndex)">{{ item.mapGroupName }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-4 featureGroupNotRepeat">
                  <div class="mb-3">
                    <label for="'cadFeatureType'+cadIndex" class="form-label custom-form-label-small"
                      [ngClass]="cadIndex == 0 ? 'show' : 'hide'">
                      <div class="customToolTip feature-type-hint">
                        <div class="customToolTipContent feature-type-hint">
                          <em>INSTRUCTIONS</em><br>
                          Begin typing a value to map a layer to a "Feature Type" After typing two letters, a list will appear. Choose the
                          correct value from the list. For example, to map a layer as ELEC_BOX , type "EL" and then choose the value from the list. To map a
                          layer to C-BLDG-DECK, type "C-" and then choose the value from the list.<br><br>
                          <em>Common GPRS Feature Types</em><br>
                          GAS_LINE<br>
                          ELEC_BOX<br>
                          WTR_METER<br>
                          <br>
                          <em>Common CAD Standard Feature Types</em><br>
                          A-DOOR-SIGN<br>
                          C-BLDG-DECK<br>
                          E-SITE-POLE<br>
                        </div>
                      </div>
                      Feature Type
                    </label>
                    <input type="text" class="form-control form-control-sm" id="'cadFeatureType'+cadIndex"
                      placeholder="Feature Type" formControlName="featureType" (keyup)="searchFeatureType(cadIndex)" />
                  </div>
                  <div *ngIf="importFeatureTypeSelectedIndex === cadIndex">
                    <ul class="list-showing list-showing-absolute">
                      <li *ngFor="let item of filteredOptionsFeatureType">
                        <a (click)="onselectFeatureTypeImport(item, cadIndex)">{{ item.mapFeatureTypeName }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ///////////////////////// -->

          <!-- <button type="button" (click)="addCADFeature('test')">Add Feature</button> -->
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal" id="cancelCustomCADForm">
          Cancel
        </button>
        <button type="button" class="btn btn-sm btn-primary" (click)="onCADCustomSubmit()">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

<div id="map-wrapper" class="letter landscape">
  <div id="crs-div">
    CRS:NAD83 / State Plane Ohio North (3401), Units: US Survey Feet
  </div>
  <div class="map-row flex-container">
    <div class="print-img-container flex-child">
      <img id="print-img" src="" alt="" />
      <!-- <img id="print-img"  src="../../assets//images/GPRSReportLetterLandscape.png" alt=""> -->
    </div>
    <div id="info-panel" class="flex-child">
      <div class="right-side" id="logo">
        <h1>N</h1>
        <span><img src="../../assets/images/logo.png" height="35" alt="" /></span>
        <span>Powered by GPRS</span>
      </div>
      <div class="right-side" id="disclaimer">
        <p>DISCLAIMER</p>
        <ul>
          <li>
            THIS EXHIBIT IS INTENDED TO ILLUSTRATE RECORDS OF FEATURES, AND
            UTILITY MARKINGS COLLECTED ON SITE. THE HISTORIC RECORDS SHOWN ARE A
            COMBINATION OF GPRS COLLECTED DATA AS WELL AS ANY ADJUSTMENTS BY
            OTHER SITEMAP USERS WITH ACCESS TO MAKE CHANGES TO THIS DATA. GPRS
            CANNOT GUARANTEE IT WILL BE ABLE TO LOCATE ALL UTILITIES OR OTHER
            SUBSURFACE FEATURES ON SITE NOR CAN IT CERTIFY THAT THE ITEMS SHOWN
            ON THIS EXHIBIT ARE CURRENT BEYOND THE OFFICIAL SUBMITTAL DATE OF A
            GPRS CONTRACT LOCATE DELIVERABLE. ALL FINDINGS ARE SUBJECT TO THE
            TERMS AND CONDITIONS THAT CAN BE VIEWED WITH THE LINK:
            HTTPS://M.GP-RADAR.COM/TERMS-CONDITIONS
          </li>
          <li>
            GPRS DOES NOT PROVIDE GEOPHYSICAL, GEOLOGICAL, LAND SURVEYING, OR
            ENGINEERING SERVICES.
          </li>
          <li>
            FOR ADDITIONAL INFORMATION REGARDING FINDINGS AND EQUIPMENT USED,
            SEE THE "JOB SUMMARY REPORT" THAT WAS SUBMITTED VIA EMAIL FOLLOWING
            COMPLETION OF FIELD WORK.
          </li>
          <li>
            PRIVATE UTILITY LOCATING IS NEVER A REPLACEMENT FOR ONE CALL/811
            SERVICES. STATE LAW REQUIRES 811 TO BE CALLED PRIOR TO ANY AND ALL
            EXCAVATION ACTIVITIES.
          </li>
        </ul>
      </div>

      <div  style="background-color: #f0f0f0"  class="side-legend">
        <h6>LEGEND</h6>
        <ul>
          <li *ngFor="let symbology of lineSymbology">
            <div>
              <span [ngStyle]="{'background-color': symbology.color, 'border': '2px solid ' + symbology.color  }"></span><i>{{ symbology.name }}</i>
            </div>
          </li>
        </ul>
      </div>

      <div  style="background-color: #f0f0f0" class="flex-container right-side" id="legend">
        <div *ngFor="let icon of pointSymbology" class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/{{icon}}.png" alt="{{icon}}" />{{formatIconName(icon)}}
        </div>
      </div>

     <!-- <div class="side-legend">
        <h6>LEGEND</h6>
        <ul>
          <li>
            <div><span class="black box"></span><i>SCAN AREA</i></div>
          </li>
          <li>
            <div><span class="green box"></span><i>NO SCAN</i></div>
          </li>
          <li>
            <div><span class="yellow"></span><i>TELECOM</i></div>
          </li>
          <li>
            <div><span class="ajeeb"></span><i>GASFUEL</i></div>
          </li>
          <li>
            <div><span class="orange"></span><i>ELECTRICAL</i></div>
          </li>
          <li>
            <div><span class="pink"></span><i>UNKNOWN</i></div>
          </li>
          <li>
            <div><span class="blue"></span><i>WATER</i></div>
          </li>
          <li>
            <div><span class="blue-light"></span><i>IRRIGATION</i></div>
          </li>
          <li>
            <div><span class="green-light"></span><i>STORM</i></div>
          </li>
          <li>
            <div><span class="grey-light"></span><i>MISC LINE</i></div>
          </li>
          <li>
            <div><span class="green-light"></span><i>SANITARY</i></div>
          </li>
          <li>
            <div><span class="grey-update"></span><i>BY OTHERS</i></div>
          </li>
        </ul>
      </div>

      <div class="flex-container right-side" id="legend">
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_CABINET.png" alt="ELEC_CABINET" />ELEC CABINET
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/COMM_PEDESTAL.png" alt="COMM_PEDESTAL" />COMM PEDESTAL
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_METER.png" alt="ELEC_METER" />ELEC METER
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/COMM_POLE.png" alt="COMM_POLE" />COMM POLE
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_PANEL.png" alt="ELEC_PANEL" />ELEC PANEL
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/COMM_CAMERA.png" alt="COMM_CAMERA" />CAMERA
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_EQUIPMENT.png" alt="ELEC_EQUIPMENT" />ELEC EQUIPMENT
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GAS_PUMP.png" alt="GAS_PUMP" />GAS PUMP
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_SIGN.png" alt="ELEC_SIGN" />ELEC SIGN
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/FIRE_HYDRANT.png" alt="FIRE_HYDRANT" />FIRE HYDRANT
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_TRANSFORMER.png" alt="ELEC_TRANSFORMER" />TRANSFORMER
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/WTR_METER.png" alt="WTR_METER" />WATER METER
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_LANDSCAPELIGHT.png" alt="ELEC_LANDSCAPELIGHT" />LANDSCAPE LIGHT
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/STRM_CATCHBASINROUND.png" alt="STRM_CATCHBASINROUND"/>CATCH BASIN
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_SITELIGHT.png" alt="ELEC_SITELIGHT" />LIGHT POLE
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/STRM_CATCHBASINSQUARE.png" alt="STRM_CATCHBASINSQUARE" />CATCH BASIN
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_POWERPOLE.png" alt="ELEC_POWERPOLE" />POWER POLE
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/STRM_CURBINLET.png" alt="STRM_CURBINLET" />CURB INLET
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/ELEC_UTILITYPOLE.png" alt="ELEC_UTILITYPOLE" />UTILITY POLE
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/STRM_ENDPIPE.png" alt="STRM_ENDPIPE" />STORM ENDPIPE
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/SAN_MARKER.png" alt="SAN_MARKER" />SAN MARKER
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/BUILDING_CORNER.png" alt="BUILDING CORNER" />BUILDING CORNER
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/SAN_GREASETRAP.png" alt="SAN_GREASETRAP" />GREASE TRAP
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/SOIL_BORING.png" alt="SOIL_BORING" />SOIL BORING
        </div>
        <div style="font-weight: 600;">GENERIC SYMBOLS - COLOR DENOTES UTILITY</div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_RISER.png" alt="GENERIC_RISER" /><i>RISER</i>
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_UST_AST.png" alt="GENERIC_UST_AST" />UST / AST
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_LIFT_STATION.png" alt="GENERIC_LIFT_STATION" />LIFT STATION
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_VENT.png" alt="GENERIC_VENT" />VENT
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_BLACKFLOW_PREV.png" alt="GENERIC_BLACKFLOW_PREV" />BACKFLOW PREV
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_VALVE.png" alt="GENERIC_VALVE" />VALVE
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_CLEAN_OUT.png" alt="GENERIC_CLEAN_OUT" />CLEAN OUT
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_PST_IND_VALVE.png" alt="GENERIC_PST_IND_VALVE" />PST IND VALVE
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_BOX.png" alt="GENERIC_BOX" />BOX
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_MISCELLANEOUS.png" alt="MISC_CONTINUES" />MISCELLANEOUS
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_VAULT.png" alt="VALUT" />VALUT
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_END_OF_INFO.png" alt="GENERIC_END_OF_INFO" />END OF INFO
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_MANHOLE.png" alt="GENERIC_MANHOLE" />MANHOLE
        </div>
        <div class="flex-child img">
          <img src="../../assets/images/GPRS_PNG/GENERIC_LINE_CONTINUES.png" alt="GENERIC_LINE_CONTINUES" />LINE CONTINUES
        </div>
      </div> -->

      <div class="scale-bar-container">
        <div class="first-bar scale-bar-item"></div>
        <span id="first-bar-label" >0'</span>
        <div class="second-bar scale-bar-item"></div>
        <span id="second-bar-label">0'</span>
        <div class="third-bar scale-bar-item"></div>
        <span id="third-bar-label">0'</span>
        <div class="fourth-bar scale-bar-item"></div>
        <span id="fourth-bar-label" >0'</span>
        <span id="fifth-bar-label">0'</span>
        <!-- <div id="scale-bar-label">Scale 1" = 100'</div> -->
      </div>

      <div class="side-footer">
        <img src="../../assets/images/pdfgprsv3.png" alt="" />
        <button>FOR INFORMATION ONLY</button>
        <h2>SITEMAP RECORD EXHIBIT</h2>
        <span>LOCATION</span>
        <i id="project-name">This is a Project Name</i>
        <i id="project-address">3556 North Tindle Blvd Apt 1</i>
        <i id="project-city">Flagstaff</i>
        <!-- <p>Print by: name</p>
        <p>Print date: 28-08-2022</p> -->
      </div>

      <!-- <div class="right-side" id="scale"></div> -->
      <!-- NITIN <div class="right-side" id="legal">
        <p>Class molestie! Placeat. Purus? Dolore primis quis debitis. Laboris, netus sapiente irure morbi suscipit maecenas habitant erat cillum culpa voluptate fugit similique repudiandae! Deserunt, aperiam praesent molestie praesentium netus egestas, expedita semper adipisci possimus torquent. Commodi, quidem laboriosam aute. Non labore ultrices optio facere excepteur quaerat rutrum rem, quibusdam augue atque? Penatibus. Aliquam tristique provident necessitatibus maiores earum, sollicitudin impedit iusto exercitation elit etiam voluptate pulvinar, dolorum voluptatum animi blanditiis, facere wisi! Aliquip felis sint occaecati ipsa curae, adipiscing varius. Potenti maxime congue inceptos pariatur lobortis minus fermentum? Aperiam magna laoreet, orci, aliquid vehicula interdum irure! Quidem porta aptent, repellat.</p>
      </div>
      <div class="right-side" id="location">
        <p>Class molestie! Placeat. Purus? Dolore primis quis debitis. Laboris, netus sapiente irure morbi suscipit maecenas habitant erat cillum culpa voluptate fugit similique repudiandae! Deserunt, aperiam praesent molestie praesentium netus egestas, expedita semper adipisci possimus torquent. Commodi, quidem laboriosam aute. Non labore ultrices optio facere excepteur quaerat rutrum rem, quibusdam augue atque? Penatibus. Aliquam tristique provident necessitatibus maiores earum, sollicitudin impedit iusto exercitation elit etiam voluptate pulvinar, dolorum voluptatum animi blanditiis, facere wisi! Aliquip felis sint occaecati ipsa curae, adipiscing varius. Potenti maxime congue inceptos pariatur lobortis minus fermentum? Aperiam magna laoreet, orci, aliquid vehicula interdum irure! Quidem porta aptent, repellat.Class molestie! Placeat. Purus? Dolore primis quis debitis. Laboris, netus sapiente irure morbi suscipit maecenas habitant erat cillum culpa voluptate fugit similique repudiandae! Deserunt, aperiam praesent molestie praesentium netus egestas, expedita semper adipisci possimus torquent. Commodi, quidem laboriosam aute. Non labore ultrices optio facere excepteur quaerat rutrum rem, quibusdam augue atque? Penatibus. Aliquam tristique provident necessitatibus maiores earum, sollicitudin impedit iusto exercitation elit etiam voluptate pulvinar, dolorum voluptatum animi blanditiis, facere wisi! Aliquip felis sint occaecati ipsa curae, adipiscing varius. Potenti maxime congue inceptos pariatur lobortis minus fermentum? Aperiam magna laoreet, orci, aliquid vehicula interdum irure! Quidem porta aptent, repellat.Aperiam magna laoreet, orci, aliquid vehicula interdum irure! Quidem porta aptent, repellat.Class molestie! Placeat. Purus? Dolore primis quis debitis. Laboris, netus sapiente irure morbi suscipit maecenas habitant erat cillum culpa voluptate fugit similique repudiandae! Class molestie! Placeat.</p>
      </div> -->
      <!-- <div class="current-info">
        <div class="right-side" id="printed-by">Print by:</div>
        <div class="right-side" id="printed-date">print_chris_12345678@website.com</div>
      </div>
      <div class="current-info">
        <div class="right-side" id="printed-by">Print date:</div>
        <div class="right-side" id="printed-date">01 December 2022</div>
      </div> -->

      <div class="print-information">
        <div class="print-information-data">Printed by:</div>
        <div class="print-information-data" id="printed-by">
          print@website.com
        </div>
      </div>
      <div class="print-information second">
        <div class="print-information-data">Print Date:</div>
        <div class="print-information-data" id="printed-date">
          01 December 2022
        </div>
      </div>
    </div>
  </div>
</div>
<!--Add external Content Modal -->
<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#externalContentModal" hidden
  id="btnExternalContent" aria-labelledby="btnExternalContent"></button>
<div class="modal fade" id="externalContentModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="externalContentModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">{{externalLayerAddEdit}} {{modalExternalAddHeader}}</h1>
        <button type="button" class="btn-close" id="closeExtModal" (click)="onExternalContentCancel()"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="externalContentForm">
          <div *ngIf="(modalExternalAddHeader | lowercase) != 'pointcloud' && (modalExternalAddHeader | lowercase) != 'virtual tour'">
            <div class="mb-3">
              <label for="external-link" class="col-form-label">Add Link <span
                  *ngIf="(modalExternalAddHeader | lowercase) != 'Pointcloud' && (modalExternalAddHeader | lowercase) != 'virtual tour'"
                  class="custom-form-label-star">*</span></label>
              <input type="text" class="form-control" id="external-link" formControlName="linkExternal">
            </div>
            <div class="text-danger"
              *ngIf="externalContentForm.get('linkExternal')?.invalid && (externalContentForm.get('linkExternal')?.dirty || externalContentForm.get('linkExternal')?.touched) && externalContentForm.get('linkExternal')?.errors?.required">
              *Please provide a link.
            </div>
            <div class="text-danger" *ngIf="
                          (externalContentForm.get('linkExternal')?.dirty ||
                            externalContentForm.get('linkExternal')?.touched) &&
                          externalContentForm.get('linkExternal')?.errors?.pattern
                        ">
              *Please provide a valid link .
            </div>
          </div>
          <div class="mb-3">
            <label for="display-name" class="col-form-label">Display Name<span
                class="custom-form-label-star">*</span></label>
            <input type="text" class="form-control" id="display-name" formControlName="displayName">
          </div>
          <div class="text-danger"
            *ngIf="externalContentForm.get('displayName')?.invalid && (externalContentForm.get('displayName')?.dirty || externalContentForm.get('displayName')?.touched) && externalContentForm.get('displayName')?.errors?.required">
            *Please provide a display name.
          </div>
          <div class="text-danger" *ngIf="
              externalContentForm.get('displayName')?.invalid &&
              (externalContentForm.get('displayName')?.dirty ||
                externalContentForm.get('displayName')?.touched) &&
              externalContentForm.get('displayName')?.errors?.maxlength
            ">
            *Display name should be of maximum 30 Characters .
          </div>

          <!-- Add multiple links for matterport (virtual tour)-->          
          <div *ngIf="(modalExternalAddHeader | lowercase) == 'virtual tour'" class="modal-footer">
            <!-- show VT LINKS  -->              
            <app-common-table   [tableHeader]="matterportTableHeader"
              [tableData]="matterportLinkArray"
              (buttonClickEmitter)="btnDeleteVTLinkUrl($event)"
              idColumnName="linkURL"
              tblClass="table-striped table-colored-virtual-tour table-bordered custom-table table-colored">
            </app-common-table>
            <button type="button" class="btn btn-primary" (click)="addVTRow()">Add Row</button>
          </div>         
          
          <div class="mb-3">
            <label for="associated-wo-external" class="col-form-label">Associate WO Number</label>
            <input type="text" class="form-control" id="associated-wo-external" formControlName="WANumber" />
          </div>
          <div class="text-danger" *ngIf="
              externalContentForm.get('WANumber')?.invalid &&
              (externalContentForm.get('WANumber')?.dirty ||
                externalContentForm.get('WANumber')?.touched) &&
              externalContentForm.get('WANumber')?.errors?.maxlength
            ">
            *WO Number should be of maximum 30 Characters .
          </div>
          <div class="text-danger" *ngIf="
              (externalContentForm.get('WANumber')?.dirty ||
                externalContentForm.get('WANumber')?.touched) &&
              externalContentForm.get('WANumber')?.errors?.pattern
            ">
            *WO Number contains only alphanumeric characters .
          </div>
          <div class="mb-3" *ngIf="(modalExternalAddHeader | lowercase) == 'pointcloud'">
            <label for="external-file" class="col-form-label">Add File</label>
            <input type="file" class="form-control" id="external-file" (change)="onFileSelected('pointcloud',$event)"
              formControlName="fileExternal">
          </div>
          <div class="modal-footer">
            <button type="button submit"
              class="btn btn-primary" [disabled]="externalContentForm.invalid"
              (click)="onExternalContentSave(externalContentForm, 'fromModal')">Save</button>
            <button type="button" class="btn btn-secondary" (click)="onExternalContentCancel()"
              data-bs-dismiss="modal">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- COMMIT CHANGES MODAL -->
<div
  class="modal fade custom-commit-changes-modal"
  id="commitChanges"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="commitChangesLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-sm modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title custom-modal-title" id="commitChangesLabel">
          Commit Changes
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <ul class="commit-changes-list">
          <li>
            <div class="commit-changes-list-item">
              <p><strong>Layer:</strong> <span>Client_01</span></p>
              <button class="btn btn-primary btn-sm">Save All</button>
              <button class="btn btn-primary btn-sm">Revert All</button>
            </div>
            <ul>
              <li>
                <div class="commit-changes-list-item">
                  <p><strong>Group:</strong> <span>Electrical</span></p>
                  <button class="btn btn-primary btn-sm">Save All</button>
                  <button class="btn btn-primary btn-sm">Revert All</button>
                </div>
                <ul>
                  <li>
                    <div class="commit-changes-list-item">
                      <p>
                        <strong>Feature Type:</strong>
                        <span>Electrical Line 01</span>
                      </p>
                      <button class="btn btn-primary btn-sm">Save</button>
                      <button class="btn btn-primary btn-sm">Revert</button>
                    </div>
                    <ul>
                      <li>
                        <div class="commit-changes-list-item">
                          <p>Point Addded 01</p>
                          <button class="btn btn-primary btn-sm">Save</button>
                          <button class="btn btn-primary btn-sm">Revert</button>
                        </div>
                      </li>
                      <li>
                        <div class="commit-changes-list-item">
                          <p>Point Addded 02</p>
                          <button class="btn btn-primary btn-sm">Save</button>
                          <button class="btn btn-primary btn-sm">Revert</button>
                        </div>
                      </li>
                      <li>
                        <div class="commit-changes-list-item">
                          <p>Point Addded 03</p>
                          <button class="btn btn-primary btn-sm">Save</button>
                          <button class="btn btn-primary btn-sm">Revert</button>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <div class="commit-changes-list-item">
                  <p><strong>Group:</strong> <span>Electrical</span></p>
                  <button class="btn btn-primary btn-sm">Save All</button>
                  <button class="btn btn-primary btn-sm">Revert All</button>
                </div>
                <ul>
                  <li>
                    <div class="commit-changes-list-item">
                      <p>
                        <strong>Feature Type:</strong>
                        <span>Electrical Line 01</span>
                      </p>
                      <button class="btn btn-primary btn-sm">Save</button>
                      <button class="btn btn-primary btn-sm">Revert</button>
                    </div>
                    <ul>
                      <li>
                        <div class="commit-changes-list-item">
                          <p>Point Addded 01</p>
                          <button class="btn btn-primary btn-sm">Save</button>
                          <button class="btn btn-primary btn-sm">Revert</button>
                        </div>
                      </li>
                      <li>
                        <div class="commit-changes-list-item">
                          <p>Point Addded 02</p>
                          <button class="btn btn-primary btn-sm">Save</button>
                          <button class="btn btn-primary btn-sm">Revert</button>
                        </div>
                      </li>
                      <li>
                        <div class="commit-changes-list-item">
                          <p>Point Addded 03</p>
                          <button class="btn btn-primary btn-sm">Save</button>
                          <button class="btn btn-primary btn-sm">Revert</button>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                <div class="commit-changes-list-item">
                  <p><strong>Group:</strong> <span>Electrical</span></p>
                  <button class="btn btn-primary btn-sm">Save All</button>
                  <button class="btn btn-primary btn-sm">Revert All</button>
                </div>
                <ul>
                  <li>
                    <div class="commit-changes-list-item">
                      <p>
                        <strong>Feature Type:</strong>
                        <span>Electrical Line 01</span>
                      </p>
                      <button class="btn btn-primary btn-sm">Save</button>
                      <button class="btn btn-primary btn-sm">Revert</button>
                    </div>
                    <ul>
                      <li>
                        <div class="commit-changes-list-item">
                          <p>Point Addded 01</p>
                          <button class="btn btn-primary btn-sm">Save</button>
                          <button class="btn btn-primary btn-sm">Revert</button>
                        </div>
                      </li>
                      <li>
                        <div class="commit-changes-list-item">
                          <p>Point Addded 02</p>
                          <button class="btn btn-primary btn-sm">Save</button>
                          <button class="btn btn-primary btn-sm">Revert</button>
                        </div>
                      </li>
                      <li>
                        <div class="commit-changes-list-item">
                          <p>Point Addded 03</p>
                          <button class="btn btn-primary btn-sm">Save</button>
                          <button class="btn btn-primary btn-sm">Revert</button>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- Edit NonGeoReferenceImage  -->
<div *ngIf="nonGeoreferenceImgDivActive" id="nonGeoreference" class="map-drag-box" appFreeDragging>
  <div id="nonGeoreferenceImgDivtest">
    <img src="" alt="" id="nonGeoreferenceImg" class="nonGeoImageDynamic">
  </div>
  <div class="progress-control">
    <div class="custom-progess-bar">
      <input
        type="range"
        id="nonGeoRef"
        name="nonGeoRef"
        min="0"
        max="100"
        [(ngModel)]="rangeSliderGeoReferenceImage"
        (oninput)="visibilityNonGeoReferenceImages($event)"
        (change)="visibilityNonGeoReferenceImages($event)"
      />
    </div>
    <div class="progress-control-btn">
      <button type="button" class="btn btn-sm btn-secondary"
        (click)="cancelNonGeoReferenceImage(editGeoReferenceImageType,rasterImageFeature)">
        Cancel
      </button>
      <button
        type="button"
        style="float: right"
        class="btn btn-sm btn-primary"
        (click)="
          saveNonGeoReferenceImage(
            editGeoReferenceImageType,
            rasterImageFeature
          )
        "
      >
        Save
      </button>
    </div>
  </div>
</div>

<!-- Client No Acess for Job Modal Start -->
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#noAccessModal" hidden id="btnModalNoAccess" aria-labelledby="btnModalNoAccess" ></button>
<div class="modal fade" id="noAccessModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="noAccessModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="noAccessModalLabel"><span>No Access</span></h5>
        <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <span> You don't have access for this job</span>
      </div>
    </div>
  </div>
</div>
<!-- Client No Acess for Job Modal End -->



<!-- ***************** HISTORY SIDEBAR *********************************** -->

<div class="side-main-menu hostory right" style="background: white;" [ngClass]=" isHistoryOpend ? 'open' : 'end'"
  *ngIf="isHistoryBtnClicked">
    <app-revision-history *ngIf="isHistoryBtnClicked" [map]="map" [draw]="draw" [loginUserId]="loginUserId" [jobDetailsForGPRS]="jobDetailsForGPRS"
    [isEditingModeIsOn]="isEditingModeIsOn" [featuresForRevision]="featuresForRevision"
    [historyMetaData]="historyMetaData" [isHistoryFeatureClicked]="isHistoryFeatureClicked" [isHistoryTypeClicked]="isHistoryTypeClicked"
    [isHistoryGroupClicked]="isHistoryGroupClicked" (historyOpend)="onHistorySlideoutClose($event)"
    (revertFeatureToRevision)="onRevertFeatureToRevision($event)"></app-revision-history>
</div>
<!-- ***************** HISTORY SIDEBAR END *********************************** -->
