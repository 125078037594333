import { Feature } from "src/app/constants/common-model";
import { FeatureItem, FileGroup, LayerInfo } from "../feature-tree.component.types";
import toFeatureItem from "./to-feature-item.function";

export default function reGroupByFile(layer: LayerInfo, fetureStyles?: Map<number | string, { color: string }>): FileGroup[] {
  const files: FileGroup[] = [];

  for (const group of layer.groups) {
    if (!group.types || !group.types.length) {

      if (group.features && group.features.length) {
        for (const feature of group.features) {
          const featureItem = toFeatureItem(feature, group.groupName, fetureStyles?.get(1)?.color);
          const file = files.find(f => f.fileId === (feature.fileId || 0));
          if (file) {
            file.features.push(featureItem);
          } else {
            files.push({
              fileId: parseInt(feature.fileId as string) || undefined,
              fileName: feature.fileName,
              isEyeVisible: true,
              features: [featureItem],
              groups: [],
            });
          }
        }
      }

      continue;
    }

    for (const type of group.types) {
      if (!type.features || !type.features.length) {
        continue;
      }

      for (const feature of type.features) {

        const isGroupVisible = group.isEyeVisible === undefined ? true : group.isEyeVisible;
        const isTypeVisible = type.isEyeVisible === undefined ? true : type.isEyeVisible;
        const fileId = parseInt(feature.fileId as string) || 0;
        const groupClassName = toClassName(group.groupName);
        const headerElementId = toElementId(`_${layer.layerName}_${fileId}_${group.groupId}_header`);
        const buttonElementId = toElementId(`_${layer.layerName}_${fileId}_${group.groupId}_button`);
        const contentElementId = toElementId(`_${layer.layerName}_${fileId}_${group.groupId}_content`);
        const buttonTarget = '#' + contentElementId;

        const typeClassName = toClassName(type.typeName);
        const typeButtonElementId = toElementId(`_${layer.layerName}_${fileId}_${group.groupId}_${type.typeId}_button`);
        const typeContentElementId = (suffix?: number) => toElementId(`collapse-${suffix	|| 0}_${layer.layerName}_${fileId}_${group.groupId}_${type.typeId}-content-${type.typeName}`);
        const typeButtonTarget = (suffix?: number) => '#' + typeContentElementId(suffix);
        const typeHeaderId = (suffix?: number) => toElementId(`_${layer.layerName}_${fileId}_${group.groupId}_${type.typeId}`) + '-' + (suffix || 0);
        const typeNameElementId = toElementId(`_${layer.layerName}_${fileId}_${group.groupId}_${type.typeId}_spn`);
        const typeLabelElementId = (suffix?: number) => toElementId('featureType-' + (suffix || 0));

        const color = type.typeId ? fetureStyles?.get(type.typeId)?.color : undefined;
        const featureItem = toFeatureItem(feature, type.typeName, color);
        const file = files.find(f => f.fileId === fileId);

        if (file) {
          const fileGroup = file.groups.find(x => x.groupId === group.groupId);
          if (fileGroup) {
            const fileType = fileGroup.types.find(x => x.typeId === type.typeId);
            if (fileType) {
              fileType.features.push(featureItem);
            } else {
              const typesCount = fileGroup.types.length;
              fileGroup.types.push({
                ...type,
                isEyeVisible: isTypeVisible,
                typeClassName,
                typeHeaderElementId: typeHeaderId(typesCount),
                typeButtonElementId,
                typeButtonTarget: typeButtonTarget(typesCount),
                typeContentElementId: typeContentElementId(typesCount),
                typeNameElementId,
                typeLabelElementId: typeLabelElementId(typesCount),
                features: [featureItem],
              });
            }
          }
          else {
            file.groups.push({
              ...group,
              isEyeVisible: isGroupVisible,
              groupClassName,
              headerElementId,
              buttonElementId,
              buttonTarget,
              contentElementId,
              features: [],
              types: [{
                ...type,
                isEyeVisible: isTypeVisible,
                typeClassName,
                typeHeaderElementId: typeHeaderId(),
                typeButtonElementId,
                typeButtonTarget: typeButtonTarget(),
                typeContentElementId: typeContentElementId(),
                typeNameElementId,
                typeLabelElementId: typeLabelElementId(),
                features: [featureItem],
              }],
            });
          }
        } else {
          files.push({
            fileId: fileId,
            fileName: feature.fileName,
            isEyeVisible: true,
            features: [],
            groups: [{
              ...group,
              isEyeVisible: isGroupVisible,
              features: [],
              groupClassName,
              headerElementId,
              buttonElementId,
              buttonTarget,
              contentElementId,
              types: [{
                ...type,
                isEyeVisible: isTypeVisible,
                typeClassName,
                typeHeaderElementId: typeHeaderId(),
                typeButtonElementId,
                typeButtonTarget: typeButtonTarget(),
                typeContentElementId: typeContentElementId(),
                typeNameElementId,
                typeLabelElementId: typeLabelElementId(),
                features: [featureItem],
              }],
            }],
          });
        }
      }
    }

  }

  return files;
}

function toClassName(str?: string): string {
  return str?.replace(/[^a-zA-Z0-9_-]/g, '') || '';
}

function toElementId(str?: string) {
  return str?.replace(/[\s.&'#]/g, '-') || '';
}
