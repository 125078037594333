<div class="container-fluid p-0">
  <div class="row">
    <div class="col-lg-2">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12 border-bottom mb-3">
              <h4>List of Roles</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="data-list-menu-checkbox">
                <ng-container *ngIf="roleList.length > 0; else noData">
                  <ul *ngFor="let role of roleList">
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" [id]="role.name" 
                          name="roleCheck"
                          [(ngModel)]="role.isChecked"
                          (change)="onRoleChange(role)">
                        <label class="form-check-label" [for]="role.name">
                          {{role.name}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </ng-container>
                <ng-template #noData>
                  <p class="text-center text-danger">No Data Found</p>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Permission Component-->
    <div class="col-lg-10" *ngIf="roleCheckedData.length > 0 && isCheckedRoles">
      <div class="row">
        <div class="col-lg-5 border-bottom mb-3">
          <h4>Permissions
            <div class="form-check form-check-inline custom-margin">
              <input class="form-check-input" type="checkbox" id="Permissions_select_all" [ngModelOptions]="{standalone: true}"
              [(ngModel)]="permissionSelectAll" (change)="selectPermissionAll(permissionSelectAll)">
              <label class="form-check-label" for="Permissions_select_all">
                Select all
              </label>
            </div>
          </h4>
        </div>
        <div class="col-lg-7 border-bottom mb-3 d-flex justify-content-end">
          <form class="row g-3">
            <div class="col-auto">
              <label for="assignRoleToUser" class="mt-1">Assign Role to a User</label>
            </div>

            <!-- UI -->
            <div class="col-auto">
              <div class="custom-dropdown">
                <input type="text" class="form-control form-control-sm" 
                  id="multiSelect" placeholder="Select" 
                  (click)="userDrpDwnOpen = !userDrpDwnOpen"/>
                <span><img src="../../../../../assets/images/ico_chaveron_down.svg" alt="Down" id="imgmultiSelect"></span>
                <ul *ngIf="userDrpDwnOpen">
                  <li *ngFor="let user of activeUserArr" [value]="user.userId">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" 
                        value="" [id]="user.userId + '_multiSelect'"
                        [(ngModel)]="user.checkBox"
                        (change)="userChange(user)"
                        [ngModelOptions]="{standalone: true}">
                      <label class="form-check-label" [for]="user.userId + '_multiSelect'">
                        <!--{{user.name}}-->
                        {{user.email}}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- UI -->
            <div class="col-auto">
              <button type="submit" class="btn btn-primary btn-sm" (click)="onAssignRoleClick()">Assign</button>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <app-permission-right [systemPermissionTypeData]="systemPermissionTypeData"
          [systemAllPermissionData]="systemAllPermissionData"
          (permissionTypeCheckedChangeEmitter)="getCheckedUncheckedPermissions($event)"
          (permissionSubTypeCheckedChangeEmitter)="getCheckedUncheckedPermissions($event)"
          (permissionCheckedChangeEmitter)="getCheckedUncheckedPermissions($event)">
        </app-permission-right>
        <hr />
        <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-secondary me-2" (click)="cancelPermission()">Cancel</button>
          <button type="button" class="btn btn-success" (click)="savePermission()">Save</button>
        </div>
      </div>
    </div>
    <!--End Permission Component-->

  </div>
</div>