import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SitemapadminComponent } from 'src/app/modules/sitemapadmin/sitemapadmin.component';
import { AccesscontrolComponent } from './component/accesscontrol/accesscontrol.component';

const routes: Routes = [{ path: '', component: SitemapadminComponent },
{
  path: 'accesscontrol',
  component: AccesscontrolComponent, data: {}
},];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SitemapadminRoutingModule { }
