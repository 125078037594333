import { Component, HostListener, OnInit } from '@angular/core';
import { BehaviorSubjectService } from "../../../../services/behaviorsubject.service";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as mapboxgl from 'mapbox-gl';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { MapViewerService } from 'src/app/modules/map/services/mapviewer.service';
import { NgxSpinnerService } from "ngx-spinner";
import { MapViewerJobFeaturesService } from 'src/app/services/mapviewerJobFeatures/map-viewer-job-features.service';
import { Subscription } from 'rxjs';
import { JobService } from 'src/app/modules/projects/service/job.service';
import { ToastrService } from 'ngx-toastr';
import { FeatureTypeStyleMapping } from '../../../../constants/featureTypeIdStylingMapping';
import { CommonFunctionService } from 'src/app/services/common-function-service';
import { CommonMapService } from 'src/app/common-component/common-map-service';
import { PortalAPI } from 'src/app/constants/api.constant';

@Component({
  selector: 'app-map-integration',
  templateUrl: './map-integration.component.html',
  styleUrls: ['./map-integration.component.css']
})
export class MapIntegrationComponent implements OnInit {

  map!: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/satellite-streets-v11';
  lat = 41.9008; //Latitude
  lng = -90.7515; //Longitude
  firstcordinate: any = [];
  sharedJobObj: any = {};
  jobDynamicData: any;
  markupData: any;
  // elementRef: any;
  apiAllJobResponse: any = [];
  jobLatLongAllPoints: any = [];
  loginUserId: any;
  loginUserRole: any;
  geoJsonFromResponse: any;
  clickedJobItemCoordinates: any | null = null;
  clickedJobItemId: number | null = null;
  clickedJobName: string | null = null;
  currentZoomLevel: any = null;
  previousZoomLevel: any = null;
  featureTypeStyleMap: any;
  zoomedJobIdLayerIdMap: any = new Map();
  searchMarker: any;
  searchInputSubscription: Subscription | null = null;
  searchTypeSubscription: Subscription | null = null;
  headerSearchSubscrition: Subscription | null = null;
  searchStringInput: string = "";
  latitudeSearhStringInput: string = "";
  longitudeSearhStringInput: string = "";
  selectedSearchType = 0;
  isHeaderSearch: boolean = false;
  searchJobList: any = {};
  isJobOwner: boolean = true;
  dashboardSubscribe: Subscription | null = null;
  mapViewerSubscribe: Subscription | null = null;
  mapViewerJobFeatureSubscribe: Subscription | null = null;
  pointFeaturesNameArray: string[] = [];
  pointFeaturesIconsAvailableSet: any;
  zoomedLayerIdArray: string[] = [];
  adminBlobUrl: any;
  allcoordinates: any;
  searchedJobGeoJson: any;
  pointFeaturesImagesNotPresent: any = [];
  searchJobSubscription: Subscription | null = null;
  isSearchAppliedOnJobData: boolean = false;
  searchJobIds: any = [];
  jobIdsForClientRole: any = [];
  objSharedUserList: any=[];
  loginUserEmailId: any;

  constructor(
    private behaviourSubjectService: BehaviorSubjectService,
    private router: Router,
    private apiService: ApiService,
    private dashboardservice: DashboardService,
    private mapViewerService: MapViewerService,
    private spinner: NgxSpinnerService,
    private mapViewerJobFeatureService: MapViewerJobFeaturesService,
    private jobservice: JobService,
    private toastr: ToastrService,
    private commonService: CommonFunctionService,
    private commonMapService: CommonMapService
  ) {
    this.firstcordinate = [this.lng, this.lat];
    this.featureTypeStyleMap = new Map(JSON.parse(sessionStorage.featureTypeStyleMap));
    this.pointFeaturesNameArray = JSON.parse(sessionStorage.pointFeaturesNameArray);
    this.pointFeaturesIconsAvailableSet = new Set(this.pointFeaturesNameArray);
  }
  ngOnInit() {
    if (sessionStorage.getItem("loginUserId")) {
      this.loginUserId = sessionStorage.getItem("loginUserId");
    }
    if (sessionStorage.getItem("loginUserRole")) {  //Project Manager,Admin
      this.loginUserRole = sessionStorage.getItem("loginUserRole");
    }
    if (sessionStorage.getItem('loginUserEmailId')) {
      this.loginUserEmailId = sessionStorage.getItem('loginUserEmailId');
    }
    /****API CALL for role CLient ****/
    let shareUser = {
      sharedWithEmailId:  this.loginUserEmailId
    }
 this.dashboardservice.getShareJobAtLogin(shareUser).subscribe((response) => {
 
    if (this.loginUserRole != 'Admin' && this.loginUserRole != 'Project Manager') {
      this.getAllJobListBasedOnRole();

    }
    /****** ADMIN ROLE GEOSJON */
    else {
      this.mapViewerJobFeatureService.adminGeoJsonUpload(this.loginUserId).subscribe((response) => {
        this.adminBlobUrl = response;
      }, (err) => {
        console.log(err);
      });

      this.createMap();
      this.mapLoadOnPageLoad();
    }
  }, (err) => {
  });

    this.searchInputSubscription = this.behaviourSubjectService.headerSearchStringObservable.subscribe((searchString) => {
      this.searchStringInput = searchString;
    });
    this.searchTypeSubscription = this.behaviourSubjectService.headerSearchTypeObservable.subscribe((searchType) => {
      this.selectedSearchType = searchType;
    });
    this.headerSearchSubscrition = this.behaviourSubjectService.isHeaderSearchObservable.subscribe((result) => {
      this.isHeaderSearch = result;
      const latlong = this.searchStringInput.split(',');
      this.latitudeSearhStringInput = latlong[0];
      this.longitudeSearhStringInput = latlong[1];
      if (this.isHeaderSearch) {  //if search is true
        if (this.selectedSearchType == 4) {
          if (this.searchMarker) {
            this.searchMarker.remove();
          }
            if (this.commonService.checkRangeValueForLatLong(this.latitudeSearhStringInput, 'lat') 
                && this.commonService.checkRangeValueForLatLong(this.longitudeSearhStringInput, 'long')) {
            // this.pointLatLongFrmGlobalSearch(this.longitudeSearhStringInput, this.latitudeSearhStringInput);
            if (this.searchMarker) {
              this.searchMarker.remove();
            }
            this.searchJobsData();
          } else {
            alert("Latitude in range between -90 to 90 & Longitude in range between -180 to 180");
          }

        } else {
          if (this.searchMarker) {
            this.searchMarker.remove();
          }
          this.searchJobsData();
        }
      }

    });

    //get job data & add popup on job click//
    this.behaviourSubjectService.jobSharedOnMapObservable.subscribe((jobObj) => {
      if (jobObj) {
        this.sharedJobObj = jobObj;
        if (Object.keys(this.sharedJobObj).length !== 0) {
          this.popUpOnJobClick(this.sharedJobObj);
        }
      }
    });
   
  }
  /***** ngOnit END *******/

  createMap() {
    /**** Map Create on Page Load*******/
    (mapboxgl as any).accessToken = environment.mapbox.accessToken;
    const tileServerUrl = environment.TILES_MVT_BASE_URL;
    const accessToken = sessionStorage.getItem("token");
    
    this.map = new mapboxgl.Map({

      container: 'map',
      style: this.style,
      center: [-103.5917, 40.6699],
      zoom: 3,
      renderWorldCopies: false,
      transformRequest: (url: string, resourceType: string) => {
        if ((resourceType === 'Tile' || resourceType === 'Image') && url.indexOf(tileServerUrl) > -1) {
          // console.log("transformRequest If block");
          return {
            url: url,
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
          };
        } else {
          // console.log("transformRequest else block");
          return {
            url: url
          }
        }
      }
      // center: this.firstcordinate
    });
    this.map.addControl(new mapboxgl.NavigationControl());
    const scale = new mapboxgl.ScaleControl({
      unit: 'imperial'
    });
    this.map.addControl(scale);
    scale.setUnit('imperial');
    // this.map.addControl(scale, "bottom-left");
    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disableRotation();
    this.map.keyboard.disable();
  }

  //# fitbound
  fitBound(jobsFeature: any, mapMaxZoom:number = 10) {
    const bounds = new mapboxgl.LngLatBounds();
    jobsFeature.features?.forEach((feature: any) => {
      //bounds.extend(JSON.parse(feature.geometry.coordinates));
      bounds.extend(feature.geometry.coordinates);
    });
    if(jobsFeature.features.length > 0)
    this.map.fitBounds(bounds, {
      padding: 100,
      maxZoom: mapMaxZoom
    });
  }

  //# redirect to map viewer by job id
  @HostListener('click', ['$event'])
  onClick(event: any) {
    if (event.target.id == 'viewJobBtn') {
      this.router.navigate([`/mapviewerjobs/${this.clickedJobItemId}`]);
    } if (event.target.id == 'shareJobBtn') {
      this.behaviourSubjectService.changeJobShared({ jobId: this.clickedJobItemId, isShared: this.isJobOwner }); // static obj
    }
  }

  //# map load on page load with all job points
  mapLoadOnPageLoad() {
    this.map.on('load', () => {
      if (this.loginUserRole != 'Admin' && this.loginUserRole != 'Project Manager') {
        this.map.addSource('allJobs', {
          type: 'geojson',
          data: this.geoJsonFromResponse,
        });
        this.addVectorTilesLayersAndSourceNew();
        this.addAllIconsInMap();
        this.setFilterForClient();

      } else {
        this.addSourceInMap();
      }
      this.addLayerInMap();
      this.mapClickInputFunction();
      this.mouseEnterOnMap();
      this.mouseLeaveOnMap();
      this.zoomOnMap();
    });


  }

  //# source data by geojson
  addSourceInMap() {   
      this.map.addSource('allJobs', {
        type: 'geojson',      
        data: PortalAPI.DATA_SOURCE,
      });   
    this.addVectorTilesLayersAndSourceNew();
    this.addAllIconsInMap();
  }

  // addVectorsTilesLayerAndSource(){
  //   if (this.isQAenv === 'qa') {
  //     this.map.addSource('pgtiles-custom-lines', {
  //       type: 'vector',
  //       tiles: ['https://py.gprssitemap.com:8443/api/v1/mvttileserver/custom_lines/{z}/{x}/{y}.mvt'],
  //       promoteId: 'featureId',
  //     });
  
  //     // Add the source for all point features
  //     // Set the "map_job_id" as the "feature id"
  //     this.map.addSource('pgtiles-custom-points', {
  //       type: 'vector',
  //       tiles: ['https://py.gprssitemap.com:8443/api/v1/mvttileserver/custom_points/{z}/{x}/{y}.mvt'],
  //       promoteId: 'featureId',
  //     });
  
  //     this.map.addSource('pgtiles-custom-annotation-points', {
  //       type: 'vector',
  //       tiles: [
  //         'https://py.gprssitemap.com:8443/api/v1/mvttileserver/custom_annotation_points/{z}/{x}/{y}.mvt',
  //       ],
  //       promoteId: 'featureid',
  //     });
  
  //     this.map.addSource('pgtiles-custom-annotation-lines', {
  //       type: 'vector',
  //       tiles: [
  //         'https://py.gprssitemap.com:8443/api/v1/mvttileserver/custom_annotation_lines/{z}/{x}/{y}.mvt',
  //       ],
  //       promoteId: 'featureid',
  //     });
  //   }else{
  //     this.map.addSource('pgtiles-custom-lines', {
  //       type: 'vector',
  //       tiles: ['https://py.gprssitemap.com:9443/api/v1/mvttileserver/custom_lines/{z}/{x}/{y}.mvt'],
  //       promoteId: 'featureId',
  //     });
  
  //     // Add the source for all point features
  //     // Set the "map_job_id" as the "feature id"
  //     this.map.addSource('pgtiles-custom-points', {
  //       type: 'vector',
  //       tiles: ['https://py.gprssitemap.com:9443/api/v1/mvttileserver/custom_points/{z}/{x}/{y}.mvt'],
  //       promoteId: 'featureId',
  //     });
  
  //     this.map.addSource('pgtiles-custom-annotation-points', {
  //       type: 'vector',
  //       tiles: [
  //         'https://py.gprssitemap.com:9443/api/v1/mvttileserver/custom_annotation_points/{z}/{x}/{y}.mvt',
  //       ],
  //       promoteId: 'featureid',
  //     });
  
  //     this.map.addSource('pgtiles-custom-annotation-lines', {
  //       type: 'vector',
  //       tiles: [
  //         'https://py.gprssitemap.com:9443/api/v1/mvttileserver/custom_annotation_lines/{z}/{x}/{y}.mvt',
  //       ],
  //       promoteId: 'featureid',
  //     });
  //   }
    
  //   this.map.addLayer({
  //     id: 'pgtiles-custom-lines-layer',
  //     type: 'line',
  //     source: 'pgtiles-custom-lines',
  //     'source-layer': 'default',
  //     minzoom: 13.25,
  //     maxzoom: 23,
  //     layout: {
  //       visibility: 'visible',
  //       'line-join': 'round',
  //       'line-cap': 'round',
  //     },
  //     paint: this.getLinePaint(),
  //   });

  //   this.map.addLayer({
  //     id: 'pgtiles-custom-points-layer',
  //     type: 'symbol',
  //     source: 'pgtiles-custom-points',
  //     'source-layer': 'default',
  //     minzoom: 13.25,
  //     maxzoom: 23,
  //     layout: {
  //       'icon-allow-overlap': true,
  //       visibility: 'visible',
  //       'icon-image': [
  //         'coalesce',
  //         ['get', 'featuretype'],
  //         'GENERIC_BRIAN',
  //       ], // expects "featuretype" to have same name as the loaded icon
  //       'icon-size': 0.3,
  //     },
  //   });

  //   this.map.addLayer({
  //     id: 'pgtiles-custom-annotation-lines-layer',
  //     type: 'line',
  //     source: 'pgtiles-custom-annotation-lines',
  //     'source-layer': 'default',
  //     minzoom: 13.25,
  //     maxzoom: 23,
  //     layout: {
  //       visibility: 'visible',
  //       'line-join': 'round',
  //       'line-cap': 'round',
  //     },
  //     paint: {
  //       "line-color":"#00FF00",
  //       "line-width":2,
  //     },
  //   });

  //   this.map.addLayer({
  //     id: 'pgtiles-custom-annotation-points-layer',
  //     type: 'circle',
  //     source: 'pgtiles-custom-annotation-points',
  //     'source-layer': 'default',
  //     minzoom: 13.25,
  //     maxzoom: 23,
  //     paint:{
  //       "circle-color":"#00FF00",
  //       "circle-radius": 4,
  //     }
  //   });
  // }

  addVectorTilesLayersAndSourceNew(){ 
    for (const appLayerId of [1, 2, 3]) {
      for (const tileType of ['line', 'point']) {
        console.log(`${appLayerId} -- ${tileType}`)
        this.loadMVTSource(appLayerId, tileType);
      }
    }
    for (const tileType of ['photo', 'matterport', 'pointcloud', 'virtualtour','externallink']) {
      console.log(`4 -- ${tileType}`)
      this.loadMVTSource(4, tileType);
    }
  };

  loadMVTSource(appLayerId: number, tileType: string) {
    let currentSourceId = null;
    currentSourceId = `${appLayerId}-${tileType}`;
    this.commonMapService.setSourceForVectorTilesV2(this.map, currentSourceId, appLayerId, tileType);
  }

  addSourceForVectorTiles(sourceId: any, appLayerId: any, geomType: any) {
    let pointTiles, lineTiles = null;
  	pointTiles = PortalAPI.POINT_TILES + appLayerId + PortalAPI.TILES;
    lineTiles = PortalAPI.LINE_TILES + appLayerId + PortalAPI.TILES;

    if (geomType === 'point') {
      this.map.addSource(sourceId, {
        type: 'vector',
        tiles: [pointTiles],
        promoteId: 'featureid',
      });
    } else {
      this.map.addSource(sourceId, {
        type: 'vector',
        tiles: [lineTiles],
        promoteId: 'featureid',
      });
    }       
  }

  addLayerForNewSources(currentSourceId:any, appLayerId:any, geomType:any){
    if (geomType === 'point') {
      let currentPointLayerId = `${appLayerId}-point`;
      this.map.addLayer({
        id: currentPointLayerId,
        type: 'symbol',
        source: currentSourceId,
        'source-layer': 'default',
        minzoom: 13.25,
        maxzoom: 23,
        layout: {
          'icon-allow-overlap': true,
          visibility: 'visible',
          'icon-image': [
            'coalesce',
            ['get', 'featuretype'],
            'GENERIC_BRIAN',
          ], // expects "featuretype" to have same name as the loaded icon
          'icon-size': 0.3,
        },
      });
  
     
    }
    else if (geomType === 'external') {
      let currentPointLayerId = `${appLayerId}-external`;
      this.map.addLayer({
        id: currentPointLayerId,
        type: 'symbol',
        source: currentSourceId,
        'source-layer': 'default',
        minzoom: 13.25,
        maxzoom: 23,
        layout: {
          'icon-allow-overlap': true,
          visibility: 'visible',
          'icon-image': this.commonMapService.externalContentIconImage['photo'], // expects "featuretype" to have same name as the loaded icon
          'icon-size': 0.3,
        },
      });
    }
    else{
      let currentLineLayerId = `${appLayerId}-line`;
      
      this.map.addLayer({
        id: currentLineLayerId,
        type: 'line',
        source: currentSourceId,
        'source-layer': 'default',
        minzoom: 13.25,
        maxzoom: 23,
        layout: {
          visibility: 'visible',
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: this.getLinePaint(),
      });
    }

   

   
  }




  /* 
Generate the "paint" style required for LINE based on "feature_type_id"
Returns the entire "paint" style object
"case" syntax: 
  ["case",
    condition: boolean, output: OutputType,
    condition: boolean, output: OutputType,
    ...,
    fallback: OutputType
  ]
See: https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#case
*/
  getLinePaint() {
    // This is the return style object
    // "line-color" and "line-width" will be set using Mapbox expressions with "case" operator
    let linePaint: any = {
      'line-color': ['case'],
      'line-width': ['case'],
    };

    // Iterate over each LINE style in the style schema
    // and push "condition" and "output" to "case" expression
    // for each style based on "feature_type_name"
    FeatureTypeStyleMapping.featureTypeStyleJson.filter(feature => feature.geometry_type == "LINE")
      .forEach((featureStyle: any) => {
        linePaint['line-color'].push([
          '==',
          ['get', 'featuretype'],
          featureStyle.feature_type_name,
        ]);
        linePaint['line-color'].push(featureStyle.color);
        linePaint['line-width'].push([
          '==',
          ['get', 'featuretype'],
          featureStyle.feature_type_name,
        ]);
        linePaint['line-width'].push(featureStyle.line_width);
      });

    // "case" requires a default/fallback value
    // and they are added at the end of the expression
    linePaint['line-color'].push('rgb(255,153,255)');
    linePaint['line-width'].push(2);
    // console.log(linePaint);
    // console.log(JSON.stringify(linePaint));
    return linePaint;
  };

  setFilterForClient(){
    // this.map.setFilter('pgtiles-custom-lines-layer',this.getMapFilterExpressionForClient());
    // this.map.setFilter('pgtiles-custom-points-layer', this.getMapFilterExpressionForClient());
   
    for (const appLayerId of [1, 2, 3]) {
      let currentPointLayerId = `${appLayerId}-point`;
      let currentLineLayerId = `${appLayerId}-line`;
      this.map.setFilter(currentLineLayerId,this.getMapFilterExpressionForClient());
      this.map.setFilter(currentPointLayerId, this.getMapFilterExpressionForClient());
    }
   


  }

  setMapFilterForSearchedJobs(){
    // this.map.setFilter('pgtiles-custom-lines-layer',this.getMapFilterExpressionForSearchedJOb());
    // this.map.setFilter('pgtiles-custom-points-layer', this.getMapFilterExpressionForSearchedJOb());

    for (const appLayerId of [1, 2, 3]) {
      let currentPointLayerId = `${appLayerId}-point`;
      let currentLineLayerId = `${appLayerId}-line`;
      this.map.setFilter(currentLineLayerId,this.getMapFilterExpressionForSearchedJOb());
      this.map.setFilter(currentPointLayerId, this.getMapFilterExpressionForSearchedJOb());
    }
  }

  getMapFilterExpressionForSearchedJOb(){
    const expression = ['all', ["in", ['get', 'map_job_id'], ["literal", this.searchJobIds]]];
    return expression;    
  }

  getMapFilterExpressionForClient(){
    const expression = ['all', ["in", ['get', 'map_job_id'], ["literal", this.jobIdsForClientRole]]];
    return expression;    
  }


  //# add layer data by source data
  addLayerInMap() {
    this.map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'allJobs',
      // filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#11b4da',
        'circle-radius': 4,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff'
      },
      layout: {
        'visibility': 'visible'
      },
    });
  }

  addAllIconsInMap() {
    for (let i = 0; i < this.pointFeaturesNameArray.length; i++) {
      this.map.loadImage(`../../../../../../assets/images/GPRS_PNG/${this.pointFeaturesNameArray[i]}.png`, (error, image: any) => {
        this.map.addImage(this.pointFeaturesNameArray[i], image);
      });
    }
  }

  //# map click function on map
  mapClickInputFunction() {
    this.map.on('click', 'unclustered-point', (e: any) => {
      var errCoordinate: any[2] = [];
      //const coordinates = e.features[0].geometry.coordinates.slice();
      this.clickedJobItemId = +e.features[0].properties.jobId;
      this.dashboardservice.fetchSharedWithUserList(this.clickedJobItemId, 1).subscribe((data) => {
        this.objSharedUserList = data;
       });
      //this.clickedJobItemCoordinates = e.features[0].geometry.coordinates.slice();
      this.spinner.show();
      this.mapViewerSubscribe = this.mapViewerService.fetchJobDetails(this.clickedJobItemId!, this.loginUserId).subscribe((jobDetailData: any) => {
        // const jobName = jobDetailData?.data?.jobName;
        // const jobName = jobDetailData?.data?.groups?.features[0]?.attributes?.workorderNumber + " - " + jobDetailData?.data?.groups?.features[0]?.attributes?.customerName;
        const jobName = jobDetailData?.features[0]?.attributes?.workorderNumber + " - " + jobDetailData?.features[0]?.attributes?.customerName;

        this.clickedJobName = jobName;
        var siteContact = "";
        var email = ""
        if (jobDetailData?.features[0] != null) {
          siteContact = jobDetailData?.features[0]?.attributes.siteContact;
          email = jobDetailData?.features[0]?.attributes.email;
          this.isJobOwner = !(jobDetailData?.features[0]?.attributes.isShared.toString() == "true");
          // if (this.isJobOwner) {  // not assinged (not owner)
          //   this.isJobOwner = false;
          // }else{
          //   this.isJobOwner =  true;
          // }
       
          if (this.loginUserRole == 'Admin' || this.loginUserRole =='Project Manager') {
            this.isJobOwner = true;
          }
        }
        const lat = jobDetailData?.features[0]?.attributes.latitude;
        const long = jobDetailData?.features[0]?.attributes.longitude;
        const netsuiteJobId = jobDetailData?.features[0]?.attributes.netsuiteJobId || '';
        const netsuitePoNumber = jobDetailData?.features[0]?.attributes.netsuitePoNumber || '';

        const coordinates: any[2] = [long, lat];
        errCoordinate = [long, lat];
        this.clickedJobItemCoordinates = coordinates;


        const description = `
  <div class="custom-map-header">
    <h2>${jobName}</h2>
  </div>
  <div class="custom-map-tooltip">
    <div class="table-responsive">
      <table class="table table-bordered table-striped custom-table">
        <!-- <tr>
          <td>Location</td>
          <td>${coordinates[1]},${coordinates[0]}</td>
        </tr> -->
        <tr>
          <td>Site Contact</td>
          <td>${siteContact}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>${email}</td>
        </tr>
        <tr>
          <td>Job ID</td>
          <td>${netsuiteJobId}</td>
        </tr>
        <tr>
          <td>PO Number</td>
          <td>${netsuitePoNumber}</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="custom-map-footer">
    <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
    <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
  </div>
`

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        this.spinner.hide();

       // this.flyMap(coordinates[0], coordinates[1] - 8); // fly map to lat long.  Adjusting the latitude by a factor of 8 so the popup is fully visible on the map
        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(description)          
          .addTo(this.map);
      },
        (error) => {
          const description = `
    <div class="custom-map-header">
      <h2>Error occurred while fetching data for this job</h2>
    </div>
    <div class="custom-map-tooltip">
    </div>
    <div class="custom-map-footer">
      <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
      <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
    </div>
  `
          new mapboxgl.Popup()
            .setLngLat(errCoordinate)
            .setHTML(description)
            .addTo(this.map);
          this.spinner.hide();
        });
    });
  }

  //# mouse enter function on map 
  mouseEnterOnMap() {
    this.map.on('mouseenter', 'unclustered-point', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
  }

  //# mouse leave function  Change it back to a pointer when it leaves
  mouseLeaveOnMap() {
    this.map.on('mouseleave', 'unclustered-point', () => {
      this.map.getCanvas().style.cursor = '';
    });
  }

  mouseEnterOnMapAfterSearch() {
    this.map.on('mouseenter', 'search-unclustered-point', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
  }

  //# mouse leave function  Change it back to a pointer when it leaves
  mouseLeaveOnMapAfterSearch() {
    this.map.on('mouseleave', 'search-unclustered-point', () => {
      this.map.getCanvas().style.cursor = '';
    });
  }

  //# zoom in zoom out in map
  zoomOnMap() {

    this.map.on('zoom', (e) => {
      if (this.map.getZoom() >= 13.25) {
        if (this.isSearchAppliedOnJobData) {
          const visibility = this.map.getLayoutProperty('search-unclustered-point', 'visibility');
          if (visibility === 'visible') {
            this.map.setLayoutProperty('search-unclustered-point', 'visibility', 'none');
          }
        } else {
          const visibility = this.map.getLayoutProperty('unclustered-point', 'visibility');
          if (visibility === 'visible') {
            this.map.setLayoutProperty('unclustered-point', 'visibility', 'none');
          }
        }
      } else {
        if (this.isSearchAppliedOnJobData) {
          const visibility = this.map.getLayoutProperty('search-unclustered-point', 'visibility');
          if (visibility === 'none') {
            this.map.setLayoutProperty('search-unclustered-point', 'visibility', 'visible');
          }
        } else {
          const visibility = this.map.getLayoutProperty('unclustered-point', 'visibility');
          if (visibility === 'none') {
            this.map.setLayoutProperty('unclustered-point', 'visibility', 'visible');
          }
        }
      }
    });
    /******** new map json end  ADMIN *********/
  }

  shareJobObj(obj: any) {
    this.behaviourSubjectService.changeJobShared(obj);
  }

  //# map popup on recent job click
  popUpOnJobClick(e: any) {
    //const latlong: any = JSON.parse(e.geometry);
    if (e.latitude && e.longitude) {
      const coordinates: any[2] = [e.longitude, e.latitude]
      //const coordinates = latlong.coordinates;  
      this.clickedJobItemId = e.jobId;
      this.clickedJobItemCoordinates = coordinates;
      const shareJobObj = { jobId: this.clickedJobItemId, isShared: this.isJobOwner };
      this.shareJobObj(shareJobObj);
      this.spinner.show();
      this.mapViewerSubscribe = this.mapViewerService.fetchJobDetails(e.jobId, this.loginUserId).subscribe((jobDetailData: any) => {
        // this.mapViewerService.fetchJobDetails(e.jobId).subscribe((jobDetailData: any) => {
        this.spinner.hide();
        // const jobName = jobDetailData.data?.jobName;
        const jobName = jobDetailData?.features[0]?.attributes?.workorderNumber + " - " + jobDetailData?.features[0]?.attributes?.customerName;

        this.clickedJobName = jobName;
        const siteContact = jobDetailData?.features[0]?.attributes?.siteContact;
        const email = jobDetailData?.features[0]?.attributes?.email;
        const netsuiteJobId = jobDetailData?.features[0]?.attributes.netsuiteJobId || '';
        const netsuitePoNumber = jobDetailData?.features[0]?.attributes.netsuitePoNumber || '';
 
        this.isJobOwner = jobDetailData?.features[0]?.attributes?.isShared;
        if (this.loginUserRole == 'Admin' || 'Project Manager') {
          this.isJobOwner = true;
        }
        const description = `
    <div class="custom-map-header">
      <h2>${jobName}</h2>
    </div>
    <div class="custom-map-tooltip">
      <div class="table-responsive">
        <table class="table table-bordered table-striped custom-table">
          <!-- <tr>
            <td>Location</td>
            <td>${coordinates[1]},${coordinates[0]}</td>
          </tr> -->
          <tr>
            <td>Site Contact</td>
            <td>${siteContact}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>${email}</td>
          </tr>
          <tr>
            <td>Job ID</td>
            <td>${netsuiteJobId}</td>
          </tr>
          <tr>
            <td>PO Number</td>
            <td>${netsuitePoNumber}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="custom-map-footer">
      <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
      <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
    </div>
  `
     // this.flyMap(coordinates[0], coordinates[1] -8 ); // fly map to lat long.  Adjusting the latitude by a factor of 8 so the popup is fully visible on the map
        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(this.map);
      });
    }
    else {
      this.toastr.error("No geomatry for the job, please check with backend team.", "", { timeOut: 3000 })
    }
  }

  //# fly map to particular coordinates
  flyMap(long: any, lat: any) {
    this.map.flyTo({
      // center: [12.65147, 55.608166],
      center: [long, lat]
    });
  }

  //# get all jobs based in user Id and role
  getAllJobListBasedOnRole() {
    let userInfo = { userId: this.loginUserId, lastJobId: 0, Count: 1000 };

    this.dashboardSubscribe = this.dashboardservice.getAllJobLatLong(userInfo).subscribe((response) => {
      if(response) {
        this.apiAllJobResponse = response;
        for (var i = 0; i < this.apiAllJobResponse.features.length; i++) {
          this.jobIdsForClientRole.push(this.apiAllJobResponse.features[i].properties['jobId']);                    
        }
        this.geoJsonFromResponse = {
          "type": "FeatureCollection",
          "features": this.apiAllJobResponse.features
        }
        this.createMap();
        this.mapLoadOnPageLoad();
        this.fitBound(this.apiAllJobResponse);
      }
    }, (err) => {      
      console.log(err);
    });
  }


  //# search job details by search bar

  searchJobsMarker = new mapboxgl.Marker();

  searchJobsData() {
    this.searchJobsMarker.remove();
    this.removeExtraPopup(".mapboxgl-popup-anchor-top");
    if(this.map.getLayer('search-unclustered-point') !=undefined){
      this.map.removeLayer('search-unclustered-point');
    }
    if(this.map.getSource('searchJobs')!=undefined){
      this.map.removeSource('searchJobs');
    }
    let searchPayload = {
      "request": {
        "pageNumber": 1,
        "pageSize": 0
      },
      "searchCategoryID": this.selectedSearchType,
      //"searchValue": this.searchStringInput,
      "searchValue": this.selectedSearchType == 4 ? (this.latitudeSearhStringInput + ',' + this.longitudeSearhStringInput) : this.searchStringInput,
      "userId": this.loginUserId,
      "securableObjectTypeId": 1,
    }
    this.spinner.show();

    //Job search by address
    if(this.selectedSearchType == 6){
      const searchStringInput = JSON.parse(this.searchStringInput);
      const searchStringArray = Object.values(searchStringInput).filter((x:any)=> x != null && x != "" );
      this.jobservice.getnewmap(searchStringArray.toString()).subscribe((res: any) => {
        if(res && res?.features.length > 0){
          res.features = [res.features[0]];
          this.searchJobsMarker.setLngLat(res.features[0]?.geometry?.coordinates).addTo(this.map);
          this.map.addLayer({
            id: 'search-unclustered-point',
            type: 'circle',
            source: 'allJobs',
            filter: ['!', ['has', 'point_count']],
            paint: {
              'circle-color': '#b40219',
              'circle-radius': 4,
              'circle-stroke-width': 1,
              'circle-stroke-color': '#fff'
            },
            'layout': {
              'visibility': 'visible'
            },
          });
          this.fitBound(res, 15);
        }
      })
      this.spinner.hide();
    }

    //api call for searching relevant jobs
    if(this.selectedSearchType != 6){
    this.searchJobSubscription = this.jobservice.searchJobs(searchPayload).subscribe((response) => {
      this.searchJobList = response;
      this.searchJobIds=[];

      if (this.searchJobList.allJobs.length === 0) {
        this.spinner.hide();
        this.toastr.error("No matching results found.");
      } else {
        this.searchedJobGeoJson = {
          "type": "FeatureCollection",
          "FeatureCollectionId": null,
          "Name": null,
          "features": []
        };
        const searchedJobData = this.searchJobList.allJobs;
        for (let i = 0; i < searchedJobData.length; i++) {
          this.searchJobIds.push(searchedJobData[i].jobId);
          //this.popUpOnJobSearch(this.jobList.allJobs[i]);
          const cordinate = JSON.parse(searchedJobData[i].geometry);
          const jobCoordinates = cordinate.coordinates;
          // this.allcoordinates = JSON.parse(data[i].geometry.coordinates);
          // this.flyMap(this.allcoordinates[0], this.allcoordinates[1]);

          this.searchedJobGeoJson.features.push({
            "type": "Feature",
            "properties": {
              "jobId": searchedJobData[i].jobId
            },
            "attributes": null,
            "geometry": {
              "type": "Point",
              "coordinates": jobCoordinates
            }
          });
        }
        this.setMapFilterForSearchedJobs();
        this.map.addSource('searchJobs', {
          type: 'geojson',
          data: this.searchedJobGeoJson
        });

        this.map.setZoom(3);
        this.map.setLayoutProperty('unclustered-point', 'visibility', 'none');
        let visibility: any = 'visible';
        if (this.map.getZoom() >= 13.25) {
          visibility = 'none'
        }
        this.map.addLayer({
          id: 'search-unclustered-point',
          type: 'circle',
          source: 'searchJobs',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#b40219',
            'circle-radius': 4,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff'
          },
          'layout': {
            'visibility': visibility
          },
        });
        this.isSearchAppliedOnJobData = true;
        if (this.searchJobList.totalRecord === 1) {
          this.popUpOnJobClick(this.searchJobList.allJobs[0]);
        }
        this.fitBound(this.searchedJobGeoJson);
        this.mapClickInputFunctionAfterSearch();
        this.mouseEnterOnMapAfterSearch();
        this.mouseLeaveOnMapAfterSearch();
      }
      this.spinner.hide();
    }, (err) => {
      console.log(err);
    });
  }
  }

  mapClickInputFunctionAfterSearch() {
    this.map.on('click', 'search-unclustered-point', (e: any) => {
      console.log(e)
      var errCoordinate: any[2] = [];
      this.clickedJobItemId = e.features[0].properties.jobId;
      this.spinner.show();
      this.mapViewerSubscribe = this.mapViewerService.fetchJobDetails(this.clickedJobItemId!,this.loginUserId).subscribe((jobDetailData: any) => {
      // this.mapViewerService.fetchJobDetails(this.clickedJobItemId!).subscribe((jobDetailData: any) => {
        // console.log(jobDetailData);
        // const jobName = jobDetailData.data?.jobName;
        // const jobName = jobDetailData?.data?.groups?.features[0]?.attributes?.workorderNumber + " - " + jobDetailData?.data?.groups?.features[0]?.attributes?.customerName;
        const jobName = jobDetailData?.features[0]?.attributes?.workorderNumber + " - " + jobDetailData?.features[0]?.attributes?.customerName;

        this.clickedJobName = jobName;
        var siteContact = "";
        var email = ""
        if (jobDetailData?.features[0] != null) {
          siteContact = jobDetailData?.features[0]?.attributes.siteContact;
          email = jobDetailData?.features[0]?.attributes.email;          
          this.isJobOwner = !(jobDetailData?.features[0]?.attributes.isShared.toString() == "true");
          if (this.loginUserRole == 'Admin' || this.loginUserRole == 'Project Manager') {
            this.isJobOwner = true;
          }
        }
        const lat = jobDetailData?.features[0].attributes.latitude;
        const long = jobDetailData?.features[0].attributes.longitude;
        const netsuiteJobId = jobDetailData?.features[0]?.attributes.netsuiteJobId || '';
        const netsuitePoNumber = jobDetailData?.features[0]?.attributes.netsuitePoNumber || '';

        const coordinates: any[2] = [long, lat];
        errCoordinate = [long, lat];
        this.clickedJobItemCoordinates = coordinates;
        const description = `
        <div class="custom-map-header">
          <h2>${jobName}</h2>
        </div>
        <div class="custom-map-tooltip">
          <div class="table-responsive">
            <table class="table table-bordered table-striped custom-table">
              <!-- <tr>
                <td>Location</td>
                <td>${coordinates[1]},${coordinates[0]}</td>
              </tr> -->
              <tr>
                <td>Site Contact</td>
                <td>${siteContact}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>${email}</td>
              </tr>
              <tr>
                <td>Job ID</td>
                <td>${netsuiteJobId}</td>
              </tr>
              <tr>
                <td>PO Number</td>
                <td>${netsuitePoNumber}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="custom-map-footer">
          <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
          <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
        </div>
      `
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        
        //this.flyMap(coordinates[0], coordinates[1] -8 ); // fly map to lat long.  Adjusting the latitude by a factor of 8 so the popup is fully visible on the map
        this.spinner.hide();
        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(this.map);
      },
        (error) => {
          const description = `
    <div class="custom-map-header">
      <h2>Error occurred while fetching data for this job</h2>
    </div>
    <div class="custom-map-tooltip">
    </div>
    <div class="custom-map-footer">
      <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
      <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
    </div>
  `
          new mapboxgl.Popup()
            .setLngLat(errCoordinate)
            .setHTML(description)
            .addTo(this.map);
          this.spinner.hide();
        });
    });
  }

  expandToMapViewer(){
    const zoomLevel = this.map.getZoom();
    const mapCentre =  this.map.getCenter();
    const mapIntegrationCenterAndZoomLevelData = {
      "centre": mapCentre,
      "zoomLevel": zoomLevel
    };
    // console.log(mapIntegrationCenterAndZoomLevelData.centre.lat);
    // console.log(mapIntegrationCenterAndZoomLevelData.centre.lng);
    // console.log(mapIntegrationCenterAndZoomLevelData);
    this.behaviourSubjectService.updateMapIntegrationCenterAndZoomLevelData(mapIntegrationCenterAndZoomLevelData);
    console.log(mapCentre);
    this.router.navigate([`/mapviewer/${mapIntegrationCenterAndZoomLevelData.centre.lng}/${mapIntegrationCenterAndZoomLevelData.centre.lat}/${zoomLevel}`]);
  }

  ngOnDestroy() {
    this.searchTypeSubscription?.unsubscribe();
    this.searchInputSubscription?.unsubscribe();
    this.headerSearchSubscrition?.unsubscribe();
    this.behaviourSubjectService.changeIsHeaderSearch(false);
    this.behaviourSubjectService.changeJobPopOnMap(null);
    this.mapViewerJobFeatureSubscribe?.unsubscribe();
    this.mapViewerSubscribe?.unsubscribe();
    this.dashboardSubscribe?.unsubscribe();
    this.searchJobSubscription?.unsubscribe();
  }

    //#region removeExtraPopup method use to remove the extra open coordinate popup
    removeExtraPopup(className:any) {
      setTimeout(() => {
        let ele = document.querySelectorAll(className);
        if (ele?.length > 0) {
          if(ele?.length === 1) {
            ele[0].remove();
          }
          else {
            for (let i = 0; i < ele.length - 1; i++) {
              ele[i].remove();
            }
          }
        }
      }, 0);
    }
    //#endregion

}

