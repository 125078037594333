<div class="container-fluid p-0">
  <div class="row">
    <div class="col-lg-3">
      <div class="row">
        <div class="col-lg-12 border-bottom mb-3">
          <h4>Name of the Jobs/WO No.</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="data-list-menu">
            <app-common-table [tableHeader]="jobListTblHeader" [tableData]="jobListData"
              idColumnName="jobId"
              tblClass="table-bordered custom-table custom-table-job table-colored tbr-none table-radius-none" (tblBodyRowEmitter)="listClick($event)"
              (checkBoxEmitter)="onCheckBoxClick($event)">
            </app-common-table>
          </div>
        </div>
      </div>

    </div>
    <ng-container *ngIf="isDivVisible">
      <!--Detail form div-->
      <div class="col-lg-2">
        <div class="row">
          <div class="col-lg-12 border-bottom mb-3">
            <h4>Details <span class="fa fa-edit float-end mt-1 pointer"></span></h4>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <form class="g-3 detail-form" name="form" #f="ngForm" (ngSubmit)="f.form.valid && onSubmitDetailForm()"
              (keydown.enter)="$event.preventDefault()" novalidate>
              <fieldset [disabled]="formDisable">
                <div class="mb-3">
                  <label for="txtName" class="form-label">Customer Name</label>
                  <input type="text" class="form-control" name="txtName" maxlength="30"
                    [(ngModel)]="detailForm.customerName" id="txtName" />
                </div>
                <div class="mb-3">
                  <label for="txtSiteName" class="form-label">Site Name</label>
                  <input type="text" class="form-control" name="txtSiteName" maxlength="50"
                    [(ngModel)]="detailForm.siteName" id="txtSiteName" />
                </div>
                <div class="mb-3">
                  <label for="txtContactPerson" class="form-label"> Contact Person</label>
                  <input type="text" class="form-control" name="txtContactPerson" maxlength="30"
                    [(ngModel)]="detailForm.jobContact" id="txtContactPerson" />
                </div>
                <div class="mb-3">
                  <label for="txtEmail" class="form-label">Email</label>
                  <input type="email" class="form-control" name="email" [(ngModel)]="detailForm.email" email
                    #email="ngModel" maxlength="30" [ngClass]="{ 'is-invalid': f.submitted && email.errors }" id="txtEmail" />
                  <div *ngIf="f.submitted && email.errors" class="invalid-feedback">
                    <div *ngIf="email.errors['email']">Email is invalid</div>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="txtPhone" class="form-label">Phone No. </label>
                  <input type="tel" class="form-control" name="txtPhone" placeholder="1234" maxlength="10"
                    [(ngModel)]="detailForm.phone" id="txtPhone" />
                </div>
                <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="detailForm.CAD" name="cad"
                      id="cadrequired">
                    <label class="form-check-label" for="cadrequired">
                      CAD Required?
                    </label>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="txtdescription" class="form-label">Description </label>
                  <input type="text" class="form-control" name="txtDescription" maxlength="100"
                    [(ngModel)]="detailForm.description" id="txtdescription" />
                </div>
                <div class="mb-3 d-flex justify-content-end">
                  <button type="button" class="btn btn-secondary btn-sm me-3"
                    (click)="btnCancelDetails()">Cancel</button>
                  <button type="submit" class="btn btn-success btn-sm">Save</button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <!--End Detail form div-->

      <!--Assign User div-->
      <div class="col-lg-7">
        <div class="row">
          <div class="col-lg-6 border-bottom mb-3">
            <h4>Assigned Users</h4>
          </div>
          <div class="col-lg-6 border-bottom mb-3 d-flex justify-content-end">
            <div class="col-auto">
              <button class="btn btn-primary btn-sm" id="btnAssignUser" data-bs-toggle="modal"
                data-bs-target="#assignUser" (click)="btnAssignUserClick()" [disabled]="formDisable">ASSIGN
                USER</button>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-lg-12">
            <app-common-table [tableHeader]="assignedTblHeader" [tblClass]="pageTable" [tableData]="assignedUserList"
              (buttonClickEmitter)="tblBtnClick($event)">
            </app-common-table>
          </div>
        </div>


      </div>
      <!--End Assign User div-->
      <!-- Assign User Modal -->
      <div class="modal fade" id="assignUser" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="assignUserLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title custom-modal-title" id="assignUserLabel"><span>Assign User</span></h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form [formGroup]="AssignUserForm">
                <div class="row">

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="assignUserModalEmail" class="form-label">Email
                        <span class="custom-form-label-star">*</span></label>
                      <input type="email" formControlName="email" class="form-control" id="assignUserModalEmail"
                        placeholder="Enter email address">
                    </div>
                  </div>
                  <div class="col-lg-6">

                    <div class="mb-3">
                      <label for="assignUserModalAccessSelect" class="form-label">Access Type</label>
                      <select class="form-select" formControlName="accessFormControl"
                        aria-label="Action" id="assignUserModalAccessSelect">
                        <option value="1" selected>View</option>
                        <option value="2">Edit</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-success" data-bs-dismiss="modal" [disabled]="AssignUserForm.invalid"
                (click)="AssignUserToJob()">Assign</button>
            </div>
          </div>
        </div>
      </div>

    </ng-container>
  </div>
</div>