import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PortalAPI } from 'src/app/constants/api.constant';
import { ConnectionService } from 'src/app/modules/sitemapadmin/services/connection-service';
import { BehaviorSubjectService } from 'src/app/services/behaviorsubject.service';
import { IAllMessage } from '../../constant/message-model';
import { DashboardService } from '../../services/dashboard/dashboard.service';

@Component({
  selector: 'app-message-board',
  templateUrl: './message-board.component.html',
  styleUrls: ['./message-board.component.css']
})
export class MessageBoardComponent implements OnInit {

  messageList: IAllMessage[] = [];
  userDataObj: any;
  loginUserRole:any;
  messageListToDisplay:any[]=[];
  loginUserEmailId: any="";

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private restService:ConnectionService,
    private dasboardService:DashboardService,
    private behaviourSubjectService: BehaviorSubjectService,
  ) { }

  ngOnInit(): void {
    this.getAllMessages()
  }

  //#region getJobList method is use to get all the job list through the GetAllJobs api
    getAllMessages()
    {
      this.spinner.show();
      this.loginUserRole=sessionStorage.getItem('loginUserRole');
      this.loginUserEmailId=sessionStorage.getItem('loginUserEmailId');
      this.messageList = [];
      this.restService.tenantId = '1';
      const restServiceBody={
        "request": {
          "pageNumber": 1,
          "pageSize": 100
        }
      }
      this.restService.post(PortalAPI.GET_ALL_Messages,restServiceBody).subscribe(response=>{
        if(response && response.messageEntities && response.totalRecords > 0)
        {
            this.messageList = response.messageEntities;
            console.log(this.messageList)
            let isForNonGprsUser = this.messageList.filter(function(item)
            {
                  return item.isExternal==true;
                  //return item.isForNonGprsUser==true;
            });
            let isForGprsUser = this.messageList.filter(function(item)
            {
                  return item.isExternal==false;
                  //return item.isForGprsUser==true;
            });
          let emailDomain =  this.loginUserEmailId.split('@');
          if(emailDomain[1] ==='gprsinc.com' || this.loginUserRole==='Admin'){
              this.messageListToDisplay = isForGprsUser;
            }else{
              this.messageListToDisplay = isForNonGprsUser;
            }
            this.spinner.hide();
        }else{
          this.spinner.hide();
        }

      }) 
    }
  }
  //#endregion

