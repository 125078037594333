import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { PortalAPI } from '../../constants/api.constant';
import { Observable, Subscription } from 'rxjs';
import { BehaviorSubjectService } from 'src/app/services/behaviorsubject.service';
import { point } from '@turf/turf';
import { environment } from 'src/environments/environment';
import { AzureBlobStorageServiceVideo } from '../azure-blob-storage.service';
import { CredentialessLogin } from 'src/app/common-component/credentialess-login/credentialess-login';

@Injectable({
  providedIn: 'root',
})
export class MapViewerJobFeaturesService {
  public options: any = {};
  tilesUrl = '';
  isCredentialessLogin:boolean=false;
  credentialessLogin:CredentialessLogin | null =null;

  constructor(
    private http: HttpClient,
    private behaviourSubjectService: BehaviorSubjectService,
    private blobService: AzureBlobStorageServiceVideo
  ) {
    let reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    reqHeader.append('Access-Control-Allow-Origin', '*');
    reqHeader.append('accept', 'text/plain');
    reqHeader.append(
      'Access-Control-Allow-Headers',
      'Origin, X-Requested-With, Content-Type, Accept'
    );
    reqHeader.append(
      'Access-Control-Allow-Methods',
      'GET,POST,OPTIONS,DELETE,PUT'
    );
    this.options = { headers: reqHeader };

    this.behaviourSubjectService.credentialessLoginObservable.subscribe((credentialessLogin:CredentialessLogin) => {
      this.credentialessLogin = credentialessLogin;

        if (this.credentialessLogin?.guid) {
          this.isCredentialessLogin = true;
        } else {
          this.isCredentialessLogin=false;
        }
    });

    this.tilesUrl = PortalAPI.TILES_MVT_BASE_URL;
  }

  fetchFeaturesByJobId(mapid: number, UserId: any) {
    if ((this.isCredentialessLogin && !this.credentialessLogin?.userLoggedIn) || (this.isCredentialessLogin && !this.credentialessLogin?.userHasAccessToJob)) {
      return this.http.get(PortalAPI.FETCH_FEATURES_JOB_GUID + `/${mapid}` + `/${this.credentialessLogin?.guid}`, this.options);
    }
    else {
      return this.http.get(PortalAPI.FETCH_FEATURES_JOB + `/${mapid}` + `/${UserId}`, this.options);
    }
  }

  fetchLineFeatureDetail(lineURL: any, lineId: number) {
    if ((this.isCredentialessLogin && !this.credentialessLogin?.userLoggedIn) || (this.isCredentialessLogin && !this.credentialessLogin?.userHasAccessToJob)) {
      return this.http.get(PortalAPI.FETCH_LINE_FEATURE_DETAIL + `/${lineURL}` + `/${lineId}/${this.credentialessLogin?.guid}`, this.options);
    } else {
      return this.http.get(PortalAPI.FETCH_LINE_FEATURE_DETAIL + `/${lineURL}` + `/${lineId}`, this.options);
    }
  }

  fetchPointFeatureDetail(pointURL: any, pointId: number) {
    if ((this.isCredentialessLogin && !this.credentialessLogin?.userLoggedIn) || (this.isCredentialessLogin && !this.credentialessLogin?.userHasAccessToJob)) {
      return this.http.get(PortalAPI.FETCH_POINT_FEATURE_DETAIL + `/${pointURL}` + `/${pointId}` + `/${this.credentialessLogin?.guid}`, this.options);
    } else {
      return this.http.get(PortalAPI.FETCH_POINT_FEATURE_DETAIL + `/${pointURL}` + `/${pointId}`, this.options);
    }
  }

  matterportLinkAdd(data: any) {
      return this.http.post(PortalAPI.MATTERPORT_LINK_ADD, data, this.options);
  }

  adminGeoJsonUpload(userId: number) {

    return this.http.get(PortalAPI.GET_ADMIN_ALLJOBS_BLOBFILE_URL + `/${userId}`, this.options);
  }

  generateExportLink(data: any) {
     return this.http.post(PortalAPI.GENERATE_EXPORT_LINK, data, this.options);
  }

  getExportLink(userId: any) {
     return this.http.get(PortalAPI.GET_EXPORT_LINK + `/${userId}`, this.options);
  }

  deleteFeaturesByFeatureId(featureId: any) {
     return this.http.delete(PortalAPI.DELETE_FEATURES + `/${featureId}`, this.options);
  }

  deleteMultipleFeaturesByFeatureId(
    featureURL: any,
    featureId: any,
    jobId: any,
    userId: any,
    featureTypeRequest: any
  ) {
    let data: any = {
      userId: userId,
      jobId: jobId,
      // featureTypeRequest: jobId
    };
    data[featureTypeRequest] = featureId;
    this.options.body = data;

     return this.http.delete(PortalAPI.MULTIPLE_DELETE_FEATURES + `/${featureURL}`, this.options);
  }

  deleteMultipleFeaturesByFeatureIds(
    featureURL: any,
    featureId: any,
    jobIds: number[] | string[],
    userId: any,
    featureTypeRequest: any
  ) {
    let data: any = {
      userId: userId,
      jobIds: jobIds,
    };
    data[featureTypeRequest] = featureId;
    this.options.body = data;

     return this.http.delete(PortalAPI.MULTIPLE_DELETE_FEATURES + `/${featureURL}`, this.options);
  }

  deleteRasterTileByTileId(
    tileId: any,
    jobId: any,
    userId: any,
  ) {
    let data: any = {
      userId: userId,
      jobId: jobId,
      RasterTileId: tileId
    };
    this.options.body = data;

     return this.http.delete(PortalAPI.DELETE_RASTER_TILE_BY_TILE_ID, this.options);

  }

  deleteFeatureTypeByJobId(
    featureTypeId: number,
    jobId: number,
    layerId: number,
    groupId: number,
    userId: number,
    fileId?: number
  ) {
    let data = {
      userId,
      jobId,
      featureTypeId,
      layerId,
      groupId,
      fileId
    };
    this.options.body = data;

     return this.http.delete(PortalAPI.FEATURETYPE_DELETE_JOBID, this.options);
  }

  deleteFeatureGroupByJobId(
    groupId: number,
    jobId: number,
    layerId: number,
    userId: number,
    fileId?: number
  ) {
    let data = {
      userId,
      jobId,
      layerId,
      groupId,
      fileId
    };
    this.options.body = data;

     return this.http.delete(PortalAPI.FEATUREGROUP_DELETE_JOBID, this.options);
  }

  deleteFeatureFileByJobId(
    jobId: number,
    layerId: number,
    userId: number,
    fileId: number
  ) {
    let data = {
      userId,
      jobId,
      layerId,
      fileId
    };
    this.options.body = data;

     return this.http.delete(PortalAPI.FEATUREFILE_DELETE_JOBID, this.options);
  }

  savePointFeatureDetailFrMapEditor(data: any) {
     return this.http.post(PortalAPI.SAVE_UPDATE_POINT_FEATURES, data, this.options);
  }
  saveLineFeatureDetailFrMapEditor(data: any) {
     return this.http.post(PortalAPI.SAVE_UPDATE_LINE_FEATURES, data, this.options);
  }
  // ?objectId=20&newName=1221file_ExportKML_20220929_R_112_72_R&ObjectTypeId=2&userId=99
  saveImportedFileNameByJobId(
    fileId: any,
    fileName: any,
    objectTypeId: any,
    userId: any
  ) {
     return this.http.put(PortalAPI.RENAME_FILE_NAME + `?objectId=${fileId}` + `&newName=${fileName}` +
        `&ObjectTypeId=${objectTypeId}` + `&userId=${userId}`, this.options);
  }


  async getFetchData(fileName: any,importedFileTypeURL:any) {
    let url: any = '';

    let apiEndUrl = importedFileTypeURL+'?gisFileName=' + fileName;
    url = environment.TILES_IMPORT_API + apiEndUrl;


    return await fetch(url,{
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain',
        }})
  }

  deleteMultipleExternalLayer(
    featureURL: any,
    jobId: any,
    userId: any
  ) {
    let data: any = {
      userId: userId,
      jobId: jobId,
    };

    this.options.body = data;

    return this.http.delete(PortalAPI.MULTIPLE_DELETE_FEATURES + `/${featureURL}`, this.options);
  }

  importPointCloudAsyncApi(url: any): Observable<any> {
    url = `${environment.TILES_IMPORT_API}/${url}` ;
    return this.http.get(
      url,
      this.options
    );
  }

  retrieveVectorTiles(userId: any) {
    let data: any = {
      userId: userId
    };
    return this.http.post(PortalAPI.VECTOR_TILES_BY_USER, data, this.options);
  }

  retrieveRasterTiles(userId: any,jobId: any) {
    let data: any = {
      request: {
        pageNumber: 1,
        pageSize: 10
      },
      userId: userId,
      jobId: jobId,
    };
    return this.http.post(PortalAPI.RASTER_TILES_BY_JOB_ID, data, this.options);
  }

  retrieveGeoReferenceImageforMultipleJobs(userId: any, jobids: any):Observable<any> {
    let data: any = {
      userId: userId,
      jobId: jobids,
    };

     return this.http.post(PortalAPI.RASTER_IMAGES_ALL_MULTIPLEJOBS, data, this.options);
  }

  retrieveRasterTileForMultipleJobs(jobids: number[]):Observable<any> {
    let data: any = {
      jobId: jobids,
    };

     return this.http.post(PortalAPI.RASTER_TILES_ALL_MULTIPLE_JOBS, data, this.options);
  }

  generateImportLog(data:any) {
      data.azureFileLink = PortalAPI.AZURE_URL_CONTAINER  + this.blobService.importedContainer + '/' + data.fileName;
      return this.http.post(PortalAPI.GENERATE_IMPORT_LOG, data, this.options);
  }

  getFetchDataImport(fileName: any, importedFileTypeURL: any, isGeoReferencedRaster: any, rasterImageId: any, rasterImageCorrdinateObj: any, isEditRaster: any, opacity: any, fileId?: number, isPublic?: boolean): Observable<any> {
    let url: any = '';
    let opacityCalc = 1;
    if (opacity) {
      opacityCalc = opacity / 100;
    }
    let apiEndUrl =  importedFileTypeURL + '?gisFileName=' + fileName;
    if (isGeoReferencedRaster) {
      apiEndUrl =  importedFileTypeURL + '?gisFileName=' + fileName + '&isGeoreferenced=' + isGeoReferencedRaster;
    }
    if (!isGeoReferencedRaster && importedFileTypeURL === 'ImportRaster') {
      apiEndUrl =  importedFileTypeURL + '?gisFileName=' + fileName + '&isGeoreferenced=' + isGeoReferencedRaster + '&isEdit=' + isEditRaster + '&rasterImageId=' + rasterImageId + '&opacity=' + opacityCalc + '&xMin=' + rasterImageCorrdinateObj.xMin + '&xMax=' + rasterImageCorrdinateObj.xMax + '&yMin=' + rasterImageCorrdinateObj.yMin + '&yMax=' + rasterImageCorrdinateObj.yMax
    }

    url = `${environment.TILES_IMPORT_API}/${apiEndUrl}`;
    if(fileId) {
      url += `&fileId=${fileId}`;
    }
    if(typeof isPublic === "boolean") {
      url += `&isPublic=${isPublic}`;
    }
    //  return await fetch(url,{
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'text/plain',
    //     }})
    return this.http.get(
      url,
      this.options
    );

  }

  completeImportLogAPICall(completeImportLog :any){
    this.completeImportLog(completeImportLog).subscribe((responseCompleteImportLog :any) => {
      // console.log("responseCompleteImportLog",responseCompleteImportLog);
    });
  }

  completeImportLog(data:any) {
     data.azureFileLink = PortalAPI.AZURE_URL_CONTAINER + this.blobService.importedContainer + '/' + data.fileName;
      return this.http.post(PortalAPI.COMPLETE_IMPORT_LOG, data, this.options);
  }

  searchFeatureTypeApi(
    searchFeatureGroupId: any,
  ) {
    let data: any = {
      searchFeatureGroupId: searchFeatureGroupId
    };

     return this.http.get(PortalAPI.SEARCH_FEATURE_TYPE + `/${searchFeatureGroupId}`, this.options);
  }

  submitCustomCADData(fileName: any, importedFileTypeURL: any, cadCustomRequest: any, cadStatus: boolean, fileId: number): Observable<any> {
    let url: any = '';
    let apiEndUrl =  importedFileTypeURL + '?gisFileName=' + fileName + '&isCAD=' + cadStatus;

    url = `${environment.TILES_IMPORT_API}/${apiEndUrl}`;
    if(fileId) {
      url += `&fileId=${fileId}`;
    }
    return this.http.post(
      url, cadCustomRequest,
      this.options
    );

  }

  editRasterImage(data: any) {
     return this.http.post(PortalAPI.UPSERT_RASTER_IMAGE, data, this.options);
  }

  searchFeatureGroupApi(
    searchText: any,
  ) {
    let data: any = {
      searchText: searchText
    };

     return this.http.get(PortalAPI.SEARCH_FEATURE_GROUP + `/${searchText}`, this.options);
  }

  retrieveGeoReferenceImage(userId: any, jobid: any):Observable<any> {
    let data: any = {
      userId: userId,
      jobId: jobid,
      request: {
        pageNumber: 1,
        pageSize: 30
      }
    };

     return this.http.post(PortalAPI.RASTER_IMAGES_ALL, data, this.options);
  }
}
