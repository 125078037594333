import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
import constrainFeatureMovement from '@mapbox/mapbox-gl-draw/src/lib/constrain_feature_movement';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import DirectSelect from '@mapbox/mapbox-gl-draw/src/modes/direct_select';

import showSnappingFeature, { snapFeatureLayerId, getSiteMapMVTLayerIdsById } from './mode_utils';

function moveVertexMode() {
  console.log('DirectSelect', DirectSelect)
  const MoveVertexMode = { ...DirectSelect }

  MoveVertexMode.onSetup = function(opts) {
    const featureId = opts.featureId;
    const feature = this.getFeature(featureId);

    this.mapLayerId = opts.mapLayerId;
    this.isSnappingToLine = opts.isSnappingToLine;
    this.isSnappingToVertex = opts.isSnappingToVertex;
    this.isSnapping = this.isSnappingToLine || this.isSnappingToVertex;
    this.navigatedFeatures = opts.navigatedFeatures;

    if (this.mapLayerId !== undefined) {
      this.siteMapMVTLayerIds = getSiteMapMVTLayerIdsById(this.map, this.mapLayerId);
    }

    if (!feature) {
      throw new Error('You must provide a featureId to enter direct_select mode');
    }

    if (feature.type === Constants.geojsonTypes.POINT) {
      throw new TypeError('direct_select mode doesn\'t handle point features');
    }

    const state = {
      featureId,
      feature,
      dragMoveLocation: opts.startPos || null,
      dragMoving: false,
      canDragMove: false,
      selectedCoordPaths: opts.coordPath ? [opts.coordPath] : []
    };

    this.setSelectedCoordinates(this.pathsToCoordinates(featureId, state.selectedCoordPaths));
    this.setSelected(featureId);
    doubleClickZoom.disable(this);

    this.setActionableState({
      trash: true
    });

    return state;
  };

  MoveVertexMode.clickAnywhere = function (state, e) {
    console.log('clickAnywhere', 'state', state, 'e', e);
  };

  MoveVertexMode.onClick = function(state, e) {
    console.log('onClick', 'state', state, 'e', e);
    if (CommonSelectors.noTarget(e)) return this.clickNoTarget(state, e);
    if (CommonSelectors.isActiveFeature(e)) return this.clickActiveFeature(state, e);
    if (CommonSelectors.isInactiveFeature(e)) return this.clickInactive(state, e);
    this.stopDragging(state);
  };

  MoveVertexMode.onTap = function(state, e) {
    if (CommonSelectors.noTarget(e)) return this.clickNoTarget(state, e);
    if (CommonSelectors.isActiveFeature(e)) return this.clickActiveFeature(state, e);
    if (CommonSelectors.isInactiveFeature(e)) return this.clickInactive(state, e);
  };

  MoveVertexMode.dragFeature = function (state, e, delta) {
    const geojson = state.feature.toGeoJSON();
    // Remove snapping features
    if (this.getFeature(snapFeatureLayerId) !== undefined) {
      this.deleteFeature(snapFeatureLayerId);
    }
  };

  // Disable changing the start or end point of the line.
  MoveVertexMode.dragVertex = function(state, e, delta) {
    // console.log('state', state, 'e', e, 'delta', delta);

    // Remove snapping features
    if (this.getFeature(snapFeatureLayerId) !== undefined) {
      this.deleteFeature(snapFeatureLayerId);
    }

    // Mouse coords
    let lat = e.lngLat.lat;
    let lng = e.lngLat.lng;

    if (this.isSnapping) {
      let selectedFeature = this.getFeature(state.featureId);


      // These are the "subject" lng/lat coordinates, e.g. the moving mouse cursor
      let geographicalCoordinates = [lng, lat];

      let movingFeature = {
        id: 'move_vertex_feature',
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: geographicalCoordinates
        }
      }

      showSnappingFeature.call(
        this,
        geographicalCoordinates,
        movingFeature,
        selectedFeature.id,
        'move_vertex');
    }


    /* Uncomment following lines to disable moving a start or end point...
    let firstCoordIdx = 0;
    let lastCoordIdx = state.feature.coordinates.length - 1;
    if (state.selectedCoordPaths.indexOf(firstCoordIdx.toString()) >= 0 || state.selectedCoordPaths.indexOf(lastCoordIdx.toString()) >= 0) {
      return;
    }
    */
    const selectedCoords = state.selectedCoordPaths.map(coord_path => state.feature.getCoordinate(coord_path));
    const selectedCoordPoints = selectedCoords.map(coords => ({
      type: Constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: Constants.geojsonTypes.POINT,
        coordinates: coords
      }
    }));

    const constrainedDelta = constrainFeatureMovement(selectedCoordPoints, delta);
    for (let i = 0; i < selectedCoords.length; i++) {
      const coord = selectedCoords[i];
      state.feature.updateCoordinate(state.selectedCoordPaths[i], coord[0] + constrainedDelta.lng, coord[1] + constrainedDelta.lat);
    }
  };

  MoveVertexMode.onMouseMove = function (state, e) {
    // call parent
    const result = DirectSelect.onMouseMove.call(this, state, e);

    const geojson = state.feature.toGeoJSON();

    // show pointer cursor on inactive features, move cursor on active feature vertices
    const isFeature = CommonSelectors.isInactiveFeature(e);
    const onVertex = CommonSelectors.isOfMetaType(Constants.meta.VERTEX)(e);
    const onMidpoint = CommonSelectors.isOfMetaType(Constants.meta.MIDPOINT)(e);
    if (isFeature || onMidpoint) this.updateUIClasses({ mouse: Constants.cursors.POINTER });
    else if (onVertex) this.updateUIClasses({ mouse: Constants.cursors.MOVE });
    else this.updateUIClasses({ mouse: Constants.cursors.NONE });

    return result;
  };

  MoveVertexMode.onTouchEnd = MoveVertexMode.onMouseUp = function(state, e) {
    console.log('onMouseUp', 'state', state, 'e', e);
    if (this.isSnapping) {
      // Get the feeature of the Vertex being dragged
      let movingFeature = state.feature;
      let movingVertexIndex = parseInt(state.selectedCoordPaths[0]);
      // Get the snap feature from the map, or undefined if it does not exist
      let snapFeature = this.getFeature(snapFeatureLayerId);
      if (snapFeature !== undefined) {
        this.deleteFeature(snapFeatureLayerId);
        movingFeature.coordinates[movingVertexIndex] = snapFeature.coordinates;
        this.addFeature(movingFeature);
      }
    }

    if (state.dragMoving) {
      this.fireUpdate();
    }
    this.stopDragging(state);
  };

  MoveVertexMode.onStop = function() {
    // this.changeMode('sitemap_simple_select');
    doubleClickZoom.enable(this);
    this.clearSelectedCoordinates();

    if (this.getFeature(snapFeatureLayerId) !== undefined) {
      this.deleteFeature(snapFeatureLayerId);
    }
  };

  // Hide midpoints
  MoveVertexMode.toDisplayFeatures = function (state, geojson, push) {
    if (state.featureId === geojson.properties.id) {
      geojson.properties.active = Constants.activeStates.ACTIVE;
      push(geojson);
      createSupplementaryPoints(geojson, {
        map: this.map,
        midpoints: true, // Set to false to hide midpoints
        selectedPaths: state.selectedCoordPaths
      }).forEach(push);
    } else {
      geojson.properties.active = Constants.activeStates.INACTIVE;
      push(geojson);
    }
    this.fireActionable(state);
  };

  return MoveVertexMode;
}


export default moveVertexMode;
