import { Injectable } from '@angular/core';
import { HttpRequest,HttpResponse,HttpHeaders,HttpClient } from '@angular/common/http';
import { PortalAPI } from '../../../constants/api.constant';
import { Subscription } from 'rxjs';
import { BehaviorSubjectService } from 'src/app/services/behaviorsubject.service';
import { CredentialessLogin } from 'src/app/common-component/credentialess-login/credentialess-login';

@Injectable({
  providedIn: 'root'
})
export class MapViewerService {

  public options :any =  {};
  isCredentialessLogin:boolean=false;
  credentialessLogin:CredentialessLogin | null =null;

  constructor(
    private http:HttpClient,
    private behaviourSubjectService: BehaviorSubjectService
  ) { 
    let reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    reqHeader.append('Access-Control-Allow-Origin', '*');
    reqHeader.append('accept', 'text/plain');
    reqHeader.append('Access-Control-Allow-Headers','Origin, X-Requested-With, Content-Type, Accept');
    reqHeader.append('Access-Control-Allow-Methods','GET,POST,OPTIONS,DELETE,PUT');
    this.options = { headers: reqHeader };

    this.behaviourSubjectService.credentialessLoginObservable.subscribe((credentialessLogin:CredentialessLogin) => {
      this.credentialessLogin = credentialessLogin;
      
        if (this.credentialessLogin?.guid) {
          this.isCredentialessLogin = true;
        } else {
          this.isCredentialessLogin=false;
        }
    }); 
  }

  fetchJobDetails(mapid: number,userId:number){
  	return this.http.get(PortalAPI.FETCH_JOB_DETAILS + `/${mapid}` + `/${userId}`, this.options);
  }

  fetchJobDocumentsDetails(data: any){
    if (this.isCredentialessLogin && !this.credentialessLogin?.userLoggedIn){
      return this.http.get(PortalAPI.GET_DOCUMENTS_FOR_JOB_GUID + `/${data.jobId}/${data.guid}`, this.options);
    } else {
      return this.http.post(PortalAPI.GET_DOCUMENTS_FOR_JOB, data, this.options);
    }
  }

  multipleJobShare(data: any){
    return this.http.post(PortalAPI.MULTIPLE_JOB_SHARE, data, this.options);
    }

  //#region  Anshu Pathak
  
  getExternalContentTypes(){
    return this.http.get(PortalAPI.GET_EXTERNAL_CONTENT_TYPES, this.options);
  }

  
 // upsert external Content
  upsertExternalContent(externalContentUrl: any, data: any) {
    return this.http.post(PortalAPI.UPSERT_EXTERNAL_CONTENT + `/${externalContentUrl}`, data, this.options);

  }

  getExternalComponentsByJobId(jobId: any,userId:number){
    if ((this.isCredentialessLogin && !this.credentialessLogin?.userLoggedIn) || (this.isCredentialessLogin && !this.credentialessLogin?.userHasAccessToJob)) {      
      return this.http.get(PortalAPI.RETRIEVE_EXTERNAL_COMPONENTS_BY_JOBID_GUID + `/${jobId}` + `/${this.credentialessLogin?.guid}`, this.options);
    } else {
      return this.http.get(PortalAPI.RETRIEVE_EXTERNAL_COMPONENTS_BY_JOBID + `/${jobId}` + `/${userId}`, this.options);
    }
  }

  fetchMultipleJobDocumentsDetails(data: any) {       
    return this.http.post(PortalAPI.GET_MULTIPLE_DOCUMENTS_FOR_JOB, data, this.options);
  }
  
  //#endregion
  getHistoryData(type: any, data: any) {
    const url = type?.toLowerCase() === "point" ? 'GetPointFeatureHistory' : 'GetLineFeatureHistory'
    return this._historyData(url, data);
  }

  setHistory(type: any, data: any) {
    const url = type?.toLowerCase() === "point" ? 'SavePointToHistory' : 'SaveLineToHistory'
    return this._historyData(url, data);
  }

  private _historyData(url: string, data: any) { 
    return this.http.post(PortalAPI.HISTORY + `/${url}`, data, this.options);
  }
}
