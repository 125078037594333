<!-- Slide folder Starts -->
<div
  class="side-main-menu folder right"
  [ngClass]="{
    open:
      slideMenuOpenFolder &&
      !slideMenuOpenSiteAdminDetails &&
      !slideMenuOpenJobDescription &&
      !slideMenuOpenLayerDetails &&
      !slideMenuOpenSiteDetails &&
      !slideMenuOpenTools,
    'end-0':
      slideMenuOpenSiteAdminDetails ||
      slideMenuOpenTools ||
      slideMenuOpenLayerDetails ||
      slideMenuOpenJobDescription ||
      slideMenuOpenSiteDetails
  }" *ngIf="showToggleSideBar"
>
  <div class="menu-first"  (click)="ontoggle(false, false, false, false, false, !slideMenuOpenFolder);
        loadDocuments()" title="DPR Menus">
    <img class="right-toggle" src="../../../../../assets/images/ico_folder.svg" alt="Folder" />
  </div>
  <div class="flex-shrink-0 p-0 bg-white sub-page-menu">
    <div class="sub-page-menu-body folder">
      <div class="sub-page-menu-header">Digital Plan Room Content</div>
      <div class="linkFont" *ngIf="jobDocumentsList?.length > 0">
        <div *ngFor="let job of jobDocumentsList">
          <div *ngIf="job.jobFiles?.length > 0">
            <div class="flex-shrink-0 p-0 bg-white">
              <div class="sub-page-menu-body site">
                <div class="sub-page-menu-header">
                  <table class="table table-colored table-striped table-breakAllWord table-dpr table-hover tbr-none">
                    <thead>
                      <tr>
                        <th colspan="2">
                          <h2>{{job.jobName}}
                            <button type="button" class="btn btn-md me-2 dpr-eye"
                            [ngClass]="job.isEyeVisible ? 'eye-visible' : 'eye-lash'" 
                            aria-label="Action" (click)="toggleJobDocuemnt(job)">
                          </button>
                          </h2>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of job.jobFiles" class="linkFont">
                        <td>
                          <span [ngClass]="clickedDocFeatures?.length>0 && clickedDocFeatures[0].properties.documentName == item.name ? 'text-primary' : ''">
                            {{item.name}}
                          </span>
                        </td>
                        <td> 
                          <button type="button" class="btn btn-md me-2 dpr-download map-download-icon"
                            aria-label="Action" (click)="btnClickDownloadDPRFile(item)">
                            <!-- <i title="Download" class="fa" class="fa fa-download" aria-hidden="true"></i> -->
                          </button>
                        </td>
                        <td>
                          <button type="button" class="btn btn-md me-2 dpr-eye"
                            [ngClass]="item.isEyeVisible ? 'eye-visible' : 'eye-lash'" 
                            aria-label="Action" (click)="toggleDocuemnt(item, job)">
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </div>
      <div class="linkFont" *ngIf="jobDocumentsList?.length == 0 || !isAllJobContainsFiles">
        <p class="slide-error-msg">No Documents</p>
      </div>
    </div>
  </div>
</div>
<!-- Slide folder Ends -->
