import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
// @ts-ignore
import { bringLineLayersToTop, bringPointLayersToTop, deselectPointsForVertexEditing, getSelectedLineFeature, setSiteMapDrawMode } from '../../../../../assets/js/sitemap_draw_modes/editing_utils';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['../mapalljoblist/mapalljoblist.component.css']
})
export class ToolsComponent implements  OnInit, OnChanges {

  @Input() slideMenuOpenTools: boolean = false;
	@Input() slideMenuOpenLayerDetails: boolean = false;
	@Input() slideMenuOpenJobDescription: boolean = false;
	@Input() slideMenuOpenSiteDetails: boolean = false;
	@Input() slideMenuOpenFolder: boolean = false;
  @Input() slideMenuOpenSiteAdminDetails: boolean = false;
  @Input() isEditingModeIsOn: boolean = false
  @Input() map: any;
  @Input() draw: any;
  @Input() navigatedFeatures: any;
  @Input() toggleEditingStateObj: any;
  @Input() mergeParentOrChild: any;
  @Output() ontoggleClicked = new EventEmitter<any>();
  @Output() globalDrawCreate = new EventEmitter<any>();
  @Output() addGroupFeatureType = new EventEmitter<any>();
  @Output() setDrawing = new EventEmitter<any>();
  @Output() setDisablePointInteractions = new EventEmitter<any>();
  @Output() setAddVertexLineId = new EventEmitter<any>();
  @Output() setIsSnappingToVertex = new EventEmitter<any>();
  @Output() setIsSnappingToLine = new EventEmitter<any>();
  @Output() setEditingState = new EventEmitter<any>();
  @Output() setMergeParentLineId = new EventEmitter<any>();
  @Output() setMergeChildLineId = new EventEmitter<any>();
  @Output() setfirstSecondLineFeatureObjFrMerge = new EventEmitter<any>();



  // Editing State
  editingState: any = {};
  disablePointInteractions: boolean = false;
  isSnapping: boolean = false;
  isSnappingToVertex: boolean = false;
  isSnappingToLine: boolean = false;
  snapFeatureLayerId: string = 'snap-feature';
  isDrawing: boolean = false;
  mergeParentLineId: string = '';
  mergeChildLineId: string = '';
  addVertexLineId: string = '';
  currentDrawingState: any = 'static';

  // Editing LayerIds
  siteMapMVTPointLayerIds: any;
  siteMapMVTLineLayerIds: any;
  siteMapDrawPointIds: any;
  siteMapDrawLineIds: any;
  snapPointLayerIds: any;
  siteMapDrawVertexIds: any;
  siteMapDrawMidpointIds: any;
  siteMapDrawPointHighlightIds: any;
  siteMapDrawLineHighlightIds: any;

  currentEditinglayerId: any;



  constructor(
    private toastr: ToastrService,
    ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.toggleEditingStateObj?.currentValue) {
      this.toggleEditingState(this.toggleEditingStateObj.editState, this.toggleEditingStateObj.layer)
    }

    // if(changes?.mergeParentOrChild?.currentValue) {
    //   if(this.mergeParentOrChild.hasOwnProperty('child')){
    //     this.mergeChildLineId = this.mergeParentOrChild.child
    //   }else {
    //     if(this.mergeParentOrChild.hasOwnProperty('parent')){
    //       this.mergeChildLineId = this.mergeParentOrChild.parent
    //     }
    //   }
    // }
    if(changes?.isEditingModeIsOn?.currentValue != changes?.isEditingModeIsOn?.previousValue) {
      if (document.querySelector('#divTools ul li a.active') !== null) {
        document.querySelector('#divTools ul li a.active')?.classList.remove('active');
      }
    }
  }

  ngOnInit(): void {
  }


  ontoggle(
    slideMenuOpenSiteAdminDetails: boolean,
    slideMenuOpenSiteDetails: boolean,
    slideMenuOpenLayerDetails: boolean,
    slideMenuOpenTools: boolean,
    slideMenuOpenJobDescription: boolean,
    slideMenuOpenFolder: boolean
  ) {
    this.slideMenuOpenSiteAdminDetails = slideMenuOpenSiteAdminDetails;
    this.slideMenuOpenSiteDetails = slideMenuOpenSiteDetails
    this.slideMenuOpenLayerDetails = slideMenuOpenLayerDetails
    this.slideMenuOpenTools = slideMenuOpenTools
    this.slideMenuOpenJobDescription = slideMenuOpenJobDescription
    this.slideMenuOpenFolder = slideMenuOpenFolder

    this.ontoggleClicked.emit({
      slideMenuOpenSiteAdminDetails,
      slideMenuOpenSiteDetails,
      slideMenuOpenLayerDetails,
      slideMenuOpenTools,
      slideMenuOpenJobDescription,
      slideMenuOpenFolder,
    });
  }

  addActiveClass(e:any) {
    // if (document.querySelector('#divTools ul li a.active') !== null) {
    //   document.querySelector('#divTools ul li a.active')?.classList.remove('active');
    // }

    // if (e) {
    //   if(e.target.tagName === 'IMG') {
    //     if (e.target.parentElement.className == '') {
    //       e.target.parentElement.className = "active";
    //     } else {
    //       e.target.parentElement.className = "";

    //     }
    //   } else {
    //     if (e.target.className == '') {
    //       e.target.className = "active";

    //     } else {
    //       e.target.className = "";

    //     }
    //   }
    // }

    // else {
    //   if (document.querySelector('#divTools ul li a.active') !== null) {
    //     document.querySelector('#divTools ul li a.active')?.classList.remove('active');
    //   }
    // }
  }

    // Edit toolbar functions
    handleSelect() {
      // Right now this tool is being used as a simple deselect tool.
      // This is useful in "Add Vertex" and "Merge Line" modes - allows user to select a new line and continue working in respective mode.
      this.draw.changeMode('sitemap_simple_select');
      this.map.getCanvasContainer().style.cursor = '';
      this.editingState.firstMergeLineId = undefined;
      this.editingState.secondMergeLineId = undefined;
      this.setEditingState.emit({editingState:this.editingState});

    }

    handleMoveVertex() {
      let isMovingVertex = !this.editingState.editingModes.isMovingVertex;
      if (!isMovingVertex) {
        console.log('Stopping "Move Vertex".');
        // Turn off this editing mode
        this.editingState.editingModes.isMovingVertex = isMovingVertex;
        this.setEditingState.emit({editingState:this.editingState});
        // Turn off any other running editing modes
        this.resetEditingModes();
        // Reenable Point interactions
        this.disablePointInteractions = false;
        this.setDisablePointInteractions.emit({disablePointInteractions:this.disablePointInteractions});
        // Change Draw mode to "simple_select"
        this.draw.changeMode('sitemap_simple_select');
      } else {
        console.log('Starting "Move Vertex".');
        // Turn off any other editing modes
        this.resetEditingModes();
        // Turn on this edtiing mode
        this.editingState.editingModes.isMovingVertex = isMovingVertex;
        this.setEditingState.emit({editingState:this.editingState});
        // Deselect any selected points and disable interactions
        deselectPointsForVertexEditing(this.draw);
        this.disablePointInteractions = true;
        this.setDisablePointInteractions.emit({disablePointInteractions:this.disablePointInteractions});
        // Move all line layers to the top of the map
        bringLineLayersToTop(this.map, this.siteMapMVTLineLayerIds);
        // If there is a single line selected, enter "move_vertex" Draw mode
        let selectedLineFeature = getSelectedLineFeature(this.draw);
        if (selectedLineFeature !== undefined) {
          this.draw.changeMode('move_vertex', {
            featureId: selectedLineFeature.id,
            mapLayerId: this.currentEditinglayerId,
            navigatedFeatures: this.navigatedFeatures,
            isSnappingToVertex: this.isSnappingToVertex,
            isSnappingToLine: this.isSnappingToLine});
        } else {
          // Otherwise enter custom simple select mode
          this.draw.changeMode('sitemap_simple_select');
        }
      }
    }

    handleAddVertex() {
      // Set state
      let isAddingVertex = !this.editingState.editingModes.isAddingVertex;
      if (!isAddingVertex) {
        console.log('Stopping "Add Vertex".');
        // Turn off this editing mode
        this.editingState.editingModes.isAddingVertex = isAddingVertex;
        this.setEditingState.emit({editingState:this.editingState});
        // Turn off any other running editing modes
        this.resetEditingModes();
        // Reenable Point interactions
        this.disablePointInteractions = false;
        this.setDisablePointInteractions.emit({disablePointInteractions:this.disablePointInteractions});
        // Reset
        this.addVertexLineId = '';
        this.setAddVertexLineId.emit(this.addVertexLineId);
        // Change Draw mode to "simple_select"
        this.draw.changeMode('sitemap_simple_select');
        // Make sure to reset cursor
        this.map.getCanvasContainer().style.cursor = '';
      } else {
        console.log('Starting "Add Vertex".');
        // Turn off any other editing modes
        this.resetEditingModes();
        // Turn on this editing mode
        this.editingState.editingModes.isAddingVertex = isAddingVertex;
        this.setEditingState.emit({editingState:this.editingState});
        // Deselect any selected points and disable interactions
        deselectPointsForVertexEditing(this.draw);
        this.disablePointInteractions = true;
        this.setDisablePointInteractions.emit({disablePointInteractions:this.disablePointInteractions});
        // Move all line layers to the top of the map
        bringLineLayersToTop(this.map, this.siteMapMVTLineLayerIds);
        // Check for a selected Line and update currentlySelectedLine
        let selectedLineFeature = getSelectedLineFeature(this.draw);
        if (selectedLineFeature !== undefined) {
          // Keep track of the Line selected for the "Add Vertex" mode
          this.addVertexLineId = selectedLineFeature.id;
          this.setAddVertexLineId.emit(this.addVertexLineId);
          // Change to custom Draw mode for the "Add Vertex" tool
          this.draw.changeMode('add_vertex', {
            featureIds: [selectedLineFeature.id],
            mapLayerId: this.currentEditinglayerId,
            navigatedFeatures: this.navigatedFeatures,
            isSnappingToVertex: this.isSnappingToVertex,
            isSnappingToLine: this.isSnappingToLine});
        } else {
            this.setAddVertexLineId.emit(this.addVertexLineId);
          }
      }
    }

    handleDeleteVertex() {
      // When this mode is activated, the user can click on vertices of lines to delete them.
      // There will be no prompt to confirm.
      // Undo/Redo should be enabled.
      // The action is handle in mouseup handler.

      // Set the mode for this tool and reset modes for all tools
      let isDeletingVertex = !this.editingState.editingModes.isDeletingVertex;
      if (!isDeletingVertex) {
        console.log('Stopping "Delete Vertex".');
        // Turn off this editing mode
        this.editingState.editingModes.isDeletingVertex = isDeletingVertex;
        this.setEditingState.emit({editingState:this.editingState});
        // Turn off any other running editing modes
        this.resetEditingModes();
        // Reenable Point interactions
        this.disablePointInteractions = false;
        this.setDisablePointInteractions.emit({disablePointInteractions:this.disablePointInteractions});
        // Change Draw mode to "simple_select"
        this.draw.changeMode('sitemap_simple_select');
      } else {
        console.log('Starting "Delete Vertex".');
        // Turn off any other editing modes
        this.resetEditingModes();
        // Turn on this edtiing mode
        this.editingState.editingModes.isDeletingVertex = isDeletingVertex;
        this.setEditingState.emit({editingState:this.editingState});
        // Deselect any selected points and disable interactions
        deselectPointsForVertexEditing(this.draw)
        this.disablePointInteractions = true;
        this.setDisablePointInteractions.emit({disablePointInteractions:this.disablePointInteractions});
        // First move all line layers to the top of the map
        bringLineLayersToTop(this.map, this.siteMapMVTLineLayerIds);
        // If there is a line selected, enter "delete_vertex" Draw mode
        let selectedLineFeature = getSelectedLineFeature(this.draw);
        if (selectedLineFeature !== undefined) {
          this.draw.changeMode('delete_vertex', { featureId: selectedLineFeature.id });
        }
      }
    }

    handleEndLine() {
      this.draw.changeMode('sitemap_simple_select');
      this.editingState.isDrawing = false;
      this.setEditingState.emit({editingState:this.editingState});
    }

    handleMergeLine() {
      // Set state
      let isMergingLine = !this.editingState.editingModes.isMergingLine;
      if (!isMergingLine) {
        console.log('Stopping MergeLine.');
        // Turn off this editing mode
        this.editingState.editingModes.isMergingLine = isMergingLine;
        this.setEditingState.emit({editingState:this.editingState});
        // Turn off any other running editing modes
        this.resetEditingModes();
        // Reenable Point interactions
        this.disablePointInteractions = false;
        this.setDisablePointInteractions.emit({disablePointInteractions:this.disablePointInteractions});
        // Make sure to reset cursor
        this.map.getCanvasContainer().style.cursor = '';
        // Reset Merge Line Ids
        this.mergeParentLineId = '';
        this.setMergeParentLineId.emit({mergeParentLineId:this.mergeParentLineId})
        this.mergeChildLineId = '';
        this.setMergeChildLineId.emit({mergeChildLineId:this.mergeChildLineId})
      } else {
        console.log('Starting MergeLine.');
        // Turn off any other editing modes
        this.resetEditingModes();
        // Turn on this edtiing mode
        this.editingState.editingModes.isMergingLine = isMergingLine;
        this.setEditingState.emit({editingState:this.editingState});
        // Deselect any selected points and disable interactions
        deselectPointsForVertexEditing(this.draw)
        this.disablePointInteractions = true;
        this.setDisablePointInteractions.emit({disablePointInteractions:this.disablePointInteractions});
        // Check for a selected Line and update mergeParentLineId and mergeChildLineId
        let selectedLine = getSelectedLineFeature(this.draw);
        if (selectedLine !== undefined) {
          this.mergeParentLineId = selectedLine.id;
          this.setMergeParentLineId.emit({mergeParentLineId:this.mergeParentLineId})
        } else {
          this.mergeParentLineId = '';
          this.setMergeParentLineId.emit({mergeParentLineId:this.mergeParentLineId})
        }
        this.mergeChildLineId = '';
        this.setMergeChildLineId.emit({mergeChildLineId:this.mergeChildLineId})
      }
    }

    handleSnappingToVertex(e: any) {
      // Clicking a snapping tool while drawing causes a draw.create event.
      // This disupts Line creation, especially. So turn if off temporarily.
      if (this.isDrawing) {
        this.map.off('draw.create', this.onGlobalDrawCreate);
      }
      this.isSnappingToVertex = !this.isSnappingToVertex;
      this.setIsSnappingToVertex.emit({isSnappingToVertex: this.isSnappingToVertex});

      if (this.isSnappingToVertex) {
        console.log('Starting Snap to Vertex');
        this.handleSnapping(undefined);
      } else {
        console.log('Stopping Snap to Vertex');
        this.handleSnapping(undefined);
      }
      // Turn this event handler back on.
      if (this.isDrawing) {
        this.map.on('draw.create', this.onGlobalDrawCreate);
      }

    }

    onGlobalDrawCreate = (e: any) => {
      this.globalDrawCreate.emit(e);
    }

    handleSnappingToLine() {
      // Clicking a snapping tool while drawing causes a draw.create event.
      // This disupts Line creation, especially. So turn if off temporari
      if (this.isDrawing) {
        this.map.off('draw.create', this.onGlobalDrawCreate);
      }
      this.isSnappingToLine = !this.isSnappingToLine;
      this.setIsSnappingToLine.emit({isSnappingToLine:this.isSnappingToLine});

      if (this.isSnappingToLine) {
        console.log('Starting Snap to Line');
        this.handleSnapping(undefined);
      } else {
        console.log('Stopping Snap to Line');
        this.handleSnapping(undefined);
      }
      // Turn this event handler back on.
      if (this.isDrawing) {
        this.map.on('draw.create', this.onGlobalDrawCreate);
      }
    }

      // Reset all editing modes to false when a user deselects a tool or activates a new one
  resetEditingModes() {
    // Reset the mouse pointer
    this.map.getCanvasContainer().style.cursor = '';
    this.disablePointInteractions = false;
    this.setDisablePointInteractions.emit({disablePointInteractions:this.disablePointInteractions});
    Object.keys(this.editingState.editingModes).forEach(mode => {
      if (this.editingState.editingModes[mode]) {
        switch (mode) {
          case 'isAddingVertex':
            this.handleAddVertex();
            break;
          case 'isDeletingVertex':
            this.handleDeleteVertex()
            break;
          case 'isMovingVertex':
            this.handleMoveVertex();
            break;
          case 'isMergingLine':
            this.handleMergeLine()
            break;
          default:
            break;
        }
      }
    })
  }

  handleSnapping(e: any) {
    // Need to re/enable Vertex/Line snapping
    setSiteMapDrawMode(e, this.draw, this.editingState, this.currentEditinglayerId, this.navigatedFeatures, this.isSnappingToVertex, this.isSnappingToLine);
  }

    // Deleting a Line
  // The featureId and featureClass are in the data
  // Confirm deletion and then send POST request to API with featureId
  handleDeleteLine() {
    // Set state
    this.draw.changeMode('sitemap_simple_select');
    this.currentDrawingState = 'deleting_line';
  }

    // Adds or removes functionality required by editing
    toggleEditingState(bool: boolean, layerId: any) {
      let mapLayerId = layerId;
      this.currentEditinglayerId = layerId;
      if (bool) {
        // Set the editing state to keep track of editing variables
        this.editingState = {
          editingModes: {
            isAddingVertex: false,
            isMovingVertex: false,
            isDeletingVertex: false,
            isMergingLine: false
          },
          firstMergeLineId: undefined,
          secondMergeLineId: undefined
        }
        this.setEditingState.emit({editingState:this.editingState});

        this.siteMapMVTLineLayerIds = this.map.getStyle().layers.filter((layer: any) => layer.id.startsWith(`${mapLayerId}-`) && layer.id.endsWith('line')).map((layer: any) => layer.id);
        this.siteMapMVTPointLayerIds = this.map.getStyle().layers.filter((layer: any) => layer.id.startsWith(`${mapLayerId}-`) && layer.id.endsWith('point')).map((layer: any) => layer.id);

        // Start editing-realted event listeners
        // Keypresses bound to "body"
        // document.getElementsByTagName('body')[0].addEventListener('keydown', this.keyPressEditingMode);
        // document.getElementsByTagName('body')[0].addEventListener('keyup', this.keyPressEditingMode);
        // Mouse events bounds to Map
        this.map.on('mousedown', this.mouseDownEditingMode);
        this.map.on('mouseup', this.mouseUpEditingMode);
        this.map.on('mousemove', this.mouseMoveEditingMode);
        // Draw event to keep track of Draw modes
        this.map.on('draw.modechange', this.drawModeChangeEditingMode);
        this.map.on('draw.selectionchange', this.drawSelectionChangeEditingMode);
        this.map.on('draw.update', this.drawUpdateEditingMode);
        this.map.on('draw.create', this.drawCreateEditingMode);
        this.map.on('draw.delete', this.drawDeleteEditingMode);
      } else {
        this.addActiveClass("");
        // Quit all editing modes
        this.resetEditingModes();
        // Enter select mode
        this.draw.changeMode('sitemap_simple_select');
        // Turn off all editing modes and snapping
        this.isSnappingToLine = false;
        this.setIsSnappingToLine.emit({isSnappingToLine:this.isSnappingToLine});
        this.isSnappingToVertex = false;
        this.setIsSnappingToVertex.emit({isSnappingToVertex: this.isSnappingToVertex});
        this.mergeParentLineId = '';
        this.mergeChildLineId = '';
        this.setMergeChildLineId.emit({mergeChildLineId:this.mergeChildLineId})
        this.disablePointInteractions = false;
        this.setDisablePointInteractions.emit({disablePointInteractions:this.disablePointInteractions});
        // Turn off all editing-related event listeners
        // Keypresses bound to "body"
        // document.getElementsByTagName('body')[0].removeEventListener('keydown', this.keyPressEditingMode);
        // document.getElementsByTagName('body')[0].removeEventListener('keyup', this.keyPressEditingMode);
        // Mouse events bounds to Map
        this.map.off('mousedown', this.mouseDownEditingMode);
        this.map.off('mouseup', this.mouseUpEditingMode);
        this.map.off('mousemove', this.mouseMoveEditingMode);
        // Draw event to keep track of Draw modes
        this.map.off('draw.modechange', this.drawModeChangeEditingMode);
        this.map.off('draw.selectionchange', this.drawSelectionChangeEditingMode);
        this.map.off('draw.update', this.drawUpdateEditingMode);
        this.map.off('draw.create', this.drawCreateEditingMode);
        this.map.off('draw.delete', this.drawDeleteEditingMode);
      }
    }

      // Set mousedown state - in editing mode mousedown + a feature selected = moving/dragging feature
  mouseDownEditingMode = (e: any) => {

  }
  // Capture mouseup for two actions:
  // Snap "subject" and "object" features when snapping is enabled
  // Perform edit functions, such as "Add Vertex", etc.
  mouseUpEditingMode = (e: any) => {

  }
  mousePreClickEditingMode = (e: any) => {
    // console.log('Mouse Preclick triggered on', e);
    // console.log('Mouse Preclick triggered on', e);
  }
  //Not using mouse click right now ...
  mouseClickEditingMode = (e: any) => {

  }
  // Track mouse movement over map for snapping purposes
  // Throttle this event to occur no more than mouseMoveEventsPerSecond
  mouseMoveEventsPerSecond: number = 4;
  mouseMoveWait: boolean = false;
  mouseMoveEditingMode = (e: any) => {
    // Don't handle events when one has just occurred
    if (!this.mouseMoveWait) {

      // fire the event
      this.mouseMoveEditingModeWait(e);
      // stop any further events
      this.mouseMoveWait = true;
      // after a fraction of a second, allow events again
      setTimeout(() => {
        this.mouseMoveWait = false;
      }, 1000 / this.mouseMoveEventsPerSecond);
    }
  }
  // Use mousemove for snapping
  mouseMoveEditingModeWait(e: any) {
    let currentDrawMode = this.draw.getMode();
    if (currentDrawMode === 'draw_point' || currentDrawMode === 'sitemap_draw_point' || currentDrawMode === 'draw_line_string' || currentDrawMode === 'sitemap_draw_line_string') {
      this.isDrawing = true;
      this.setDrawing.emit({drawing:this.isDrawing});
    } else {
      this.isDrawing = false;
      this.setDrawing.emit({drawing:this.isDrawing});
    }
  }
  // Track Draw mode in editing mode
  drawModeChangeEditingMode = (e: any) => {
    console.warn(`draw.modechange`, e)
    this.editingState.previousDrawMode = this.editingState.currentDrawMode;
    this.editingState.currentDrawMode = e.mode;
    this.setEditingState.emit({editingState:this.editingState});
  }
  drawSelectionChangeEditingMode = (e: any) => {
    console.warn(`draw.selectionchange`, e);
    if(e?.features?.length){
    if (this.editingState.editingModes.isMergingLine) {
      // Clicking on an MVT: handleGetFeatureDetails is sometimes called (first time??) before drawSelectionChangeEditingMode.
      // If clicking on MVT Line, then selected Line will be a feature and this.mergeParentLineId will have a value set from handleGetFeatureDetails.
      // Clicking on Draw Line, the selected Line will feature clicked on. this.mergeParentLineId not set by handleGetFeatureDetails.
      // If clicking on Map with a Draw Line selected, selectedLine is undefined.
      let selectedLine = getSelectedLineFeature(this.draw);
      if (this.mergeParentLineId === '') {
        // Keep track of selected Line - useful for Merge
        if (selectedLine !== undefined) {
          this.mergeParentLineId = selectedLine.id;
          this.setMergeParentLineId.emit({mergeParentLineId:this.mergeParentLineId})
        }
      } else {
        if (selectedLine !== undefined) {
          if (this.mergeParentLineId !== selectedLine.id) {
            this.mergeChildLineId = selectedLine.id;
            this.setMergeChildLineId.emit({mergeChildLineId:this.mergeChildLineId})
          }
        }
        if (this.mergeChildLineId !== '') {
          let parentLine = this.draw.get(this.mergeParentLineId);
          let childLine = this.draw.get(this.mergeChildLineId)
          if((parentLine.properties.mapLineId > 0 && childLine.properties.mapLineId > 0) && parentLine.properties.mapLineId != childLine.properties.mapLineId) {
            this.draw.changeMode('merge_line', {
              featureIds: [this.mergeParentLineId, this.mergeChildLineId],
              mapLayerId: this.currentEditinglayerId,
              navigatedFeatures: this.navigatedFeatures,
              isSnappingToVertex: this.isSnappingToVertex,
              isSnappingToLine: this.isSnappingToLine
            });
          } else {
            this.toastr.error("Please save the features first before merge");
            this.resetEditingModes();
          }
        }
        else if (selectedLine === undefined) {
          this.draw.changeMode('sitemap_simple_select', { featureIds: [this.mergeParentLineId] });
        }
      }
    } else {
      if (this.editingState.editingModes.isAddingVertex) {
        if (this.addVertexLineId === '') {
          let selectedLine = getSelectedLineFeature(this.draw);
          if (selectedLine !== undefined) {
            this.addVertexLineId = selectedLine.id;
            this.setAddVertexLineId.emit(this.addVertexLineId);
          } else {
            this.setAddVertexLineId.emit(this.addVertexLineId);
          }
        }
      }
    }
  }

    // Get current Draw mode
    let currentDrawMode = this.draw.getMode();
    // Get the selected feature(s) and brind either Point or Line layers to top
    let features = e.features;
    if (features.length > 0) {
      let feature = features[0];
      let geomType = feature.geometry.type;
      if (geomType === 'Point') {
        // Whenever a Point is selected, bring Point layers to the top
        bringPointLayersToTop(this.map, this.siteMapMVTPointLayerIds);
        if (this.editingState.editingModes.isAddingVertex || this.editingState.editingModes.isMovingVertex || this.editingState.editingModes.isDeletingVertex || this.editingState.editingModes.isMergingLine) {
          // Disable Point selection when adding a vertex
          this.draw.changeMode('sitemap_simple_select');
        }
      } else if (geomType === 'LineString' || geomType === 'MultiLineString') {
        // Whenever a Line is selected, bring Line layers to the top
        bringLineLayersToTop(this.map, this.siteMapMVTLineLayerIds);
      }
    }

    // if(this.editingState.editingModes.isSnapping) {
    //   setSiteMapDrawMode(e, this.draw, this.editingState, this.currentEditinglayerId, this.navigatedFeatures, this.isSnappingToVertex, this.isSnappingToLine);
    // }
    // setSiteMapDrawMode(e, this.draw, this.editingState, '1', this.navigatedFeatures, this.isSnappingToVertex, this.isSnappingToLine);
    setSiteMapDrawMode(e, this.draw, this.editingState, this.currentEditinglayerId, this.navigatedFeatures, this.isSnappingToVertex, this.isSnappingToLine);

  }
  drawUpdateEditingMode = (e: any) => {
    console.warn(`draw.update`, e);
    if (this.editingState.editingModes.isMergingLine) {
      let status = e.status;
      if (status !== undefined) {
        let message = e.message;
        if (!status) {
          setTimeout(() => {
            this.draw.changeMode('sitemap_simple_select', { featureIds: [this.mergeParentLineId] });
          }, 500);
          this.mergeChildLineId = '';
          this.setMergeChildLineId.emit({mergeChildLineId:this.mergeChildLineId})
          this.toastr.error(message);
        } else {
          let mergedLine = e.features[0];
          // setTimeout(() => {
          //   this.draw.changeMode('sitemap_simple_select', { featureIds: [mergedLine.id] });
          // }, 500);
          let firstsecondlineArr = e.mergedlines;
          let firstSecondLineFeatureFrMergedArr = [];
          // if((firstsecondlineArr[0].properties.mapLineId > 0 && firstsecondlineArr[1].properties.mapLineId > 0) || (firstsecondlineArr[0].properties.mapLineId != firstsecondlineArr[1].properties.mapLineId)){
          firstSecondLineFeatureFrMergedArr.push(firstsecondlineArr[0]);
          firstSecondLineFeatureFrMergedArr.push(firstsecondlineArr[1]);
          this.addActiveClass("");
          this.addGroupFeatureType.emit({layerId:mergedLine.properties.mapLayerId, mergedLine})
          //this.addGroupFeatureType(this.getLayerName(mergedLine.properties.mapLayerId),mergedLine);
          setTimeout(() => {
            this.draw.changeMode('sitemap_simple_select', { featureIds: [mergedLine.id] });
          }, 500);
          this.mergeParentLineId = mergedLine.id;
          this.setMergeParentLineId.emit({mergeParentLineId:this.mergeParentLineId})
          this.mergeChildLineId = '';
          this.setMergeChildLineId.emit({mergeChildLineId:this.mergeChildLineId});
          this.setfirstSecondLineFeatureObjFrMerge.emit({firstSecondLineFeatureObj:firstSecondLineFeatureFrMergedArr});
          this.toastr.success(message);
          // }else{
          //   this.toastr.error("Please save the features first before merge");
          // }          
        }
      }
    }
  }
  drawCreateEditingMode = (e: any) => {
    console.warn(`draw.create`, e);
  }
  drawDeleteEditingMode = (e: any) => {
    console.warn(`draw.delete`, e);
  }
 

}
