import { FileGroup } from "../feature-tree.component.types";
import getGroupIndexFromFiles from "./get-group-index-from-files.function";

export default function deleteGroupFromFiles(files: FileGroup[], groupId: number) {

  const {fileIndex, groupIndex} = getGroupIndexFromFiles(files, groupId);

  if (groupIndex === -1 || fileIndex === -1) {
    return;
  }

  const file = files[fileIndex];
  file.groups.splice(groupIndex, 1);

  if (file.groups.length === 0) {
    files.splice(fileIndex, 1);
  }
}
