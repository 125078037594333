<!-- Help Page -->

<div class="row mb-3">
  <div class="col-lg-12 border-bottom">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-12">
        <div class="page-title pb-2">
          <h1>Help Center</h1>
        </div>
      </div>
      <div class="col-lg-9 col-md-8 col-sm-12" *ngIf="searchBoxStatus">
        <form class="row g-2 d-flex justify-content-end">
          <div class="col-auto">
            <label for="txtSearch" class="visually-hidden">Search</label>
            <input
              type="search"
              maxlength="30"
              #txtSearch
              class="form-control"
              id="txtSearch"
              placeholder="Search"
            />
          </div>
          <div class="col-auto">
            <button
              type="submit"
              class="btn btn-primary me-2"
              (click)="onSearchChange(txtSearch.value)"
            >
              Search
            </button>
            <button
            id="reset-help"
              type="submit"
              class="btn btn-primary"
              (click)="resetSearch()"
            >
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-3 col-md-3 col-sm-3">
    <div class="flex-shrink-0 bg-white1 help-side-menu">
      <ul class="list-unstyled ps-0 mb-0">
        <li class="mb-1">
          <button class="btn rounded collapsed custom-text-left">
            Select Topics
          </button>
          <!-- <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
            data-bs-target="#topics-collapse" aria-expanded="false">
            Select Topics
          </button> -->
          <div class="collapse show" id="topics-collapse">
            <ul
              class="nav flex-column btn-toggle-nav list-unstyled fw-normal pb-1 small"
            >
              
              <li>
                <a
                  class="nav-link active"
                  id="how-it-works-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#how-it-works"
                  role="tab"
                  aria-controls="how-it-works"
                  aria-selected="false"
                  (click)="searchShowHide(false)"
                  onmouseover="this.style.fontWeight = 'bold'"
                  onmouseout="this.style.fontWeight = 'normal'"
                  ><i class="fa fa-minus"></i> Getting Started
                  <span class="fa fa-arrow-right"></span
                ></a>
              </li>
              <li>
                <a
                  class="nav-link"
                  id="what-is-sitemap-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#what-is-sitemap"
                  role="tab"
                  aria-controls="what-is-sitemap"
                  aria-selected="true"
                  (click)="selectedTabOnclick('faq');searchShowHide(true)"
                  onmouseover="this.style.fontWeight = 'bold'"
                  onmouseout="this.style.fontWeight = 'normal'"
                  ><i class="fa fa-minus"></i> FAQ
                  <span class="fa fa-arrow-right"></span
                ></a>
              </li>
              <li>
                <a
                  class="nav-link"
                  id="key-features-and-function-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#key-features-and-function"
                  role="tab"
                  aria-controls="key-features-and-function"
                  aria-selected="false"
                  (click)="searchShowHide(false)"
                  onmouseover="this.style.fontWeight = 'bold'"
                  onmouseout="this.style.fontWeight = 'normal'"
                  ><i class="fa fa-minus"></i> Troubleshoot
                  <span class="fa fa-arrow-right"></span
                ></a>
              </li>
              <!-- <li>
                <a
                  class="nav-link"
                  id="key-roles-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#key-roles"
                  role="tab"
                  aria-controls="key-roles"
                  aria-selected="false"
                  (click)="searchShowHide(false)"
                  ><i class="fa fa-minus"></i> Technical Specifications
                  <span class="fa fa-arrow-right"></span
                ></a>
              </li> -->
              <li>
                <a
                  class="nav-link"
                  id="video-list-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#video-list"
                  type="button"
                  role="tab"
                  aria-controls="video-list"
                  aria-selected="false"
                  (click)="selectedTabOnclick('videos'); searchShowHide(true)"
                  onmouseover="this.style.fontWeight = 'bold'"
                  onmouseout="this.style.fontWeight = 'normal'"
                  ><i class="fa fa-minus"></i> Training Videos
                  <span class="fa fa-arrow-right"></span>
                </a>
              </li>
              <li>
                <a
                  class="nav-link"
                  id="document-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#document"
                  type="button"
                  role="tab"
                  aria-controls="document"
                  aria-selected="false"
                  (click)="selectedTabOnclick('document'); searchShowHide(true)"
                  onmouseover="this.style.fontWeight = 'bold'"
                  onmouseout="this.style.fontWeight =  'normal'" 
                  ><i class="fa fa-minus"></i> Download Forms / Documents
                  <span class="fa fa-arrow-right"></span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-lg-9 col-md-9 col-sm-9">
    <div class="tab-content" id="v-pills-tabContent">
      <div
        class="tab-pane fade"
        id="what-is-sitemap"
        role="tabpanel"
        aria-labelledby="what-is-sitemap-tab"
      >
        <app-faq></app-faq>
      </div>
      <div
        class="tab-pane fade show active"
        id="how-it-works"
        role="tabpanel"
        aria-labelledby="how-it-works-tab"
      >
        <app-gettingstarted></app-gettingstarted>
      </div>
      <div
        class="tab-pane fade"
        id="key-features-and-function"
        role="tabpanel"
        aria-labelledby="key-features-and-function-tab"
      >
        <app-trobleshoot></app-trobleshoot>
      </div>
      <div
        class="tab-pane fade"
        id="key-roles"
        role="tabpanel"
      >
        <!-- <app-technical> aria-labelledby="key-roles-tab" </app-technical> -->
      </div>
      <div
        class="tab-pane fade"
        id="video-list"
        role="tabpanel"
        aria-labelledby="video-list-tab"
      >
        <app-trainingvideos
          *ngIf="selectedTab === 'videos'"
        ></app-trainingvideos>
        <!-- *ngIf="selectedTab === 'videos'" -->
      </div>
      <div
        class="tab-pane fade"
        id="document"
        role="tabpanel"
        aria-labelledby="document-tab"
      >
        <div class="container-flud">
          <div class="row">
            <div class="col-lg-12">
              <div class="card colored-card">
                <h2 class="card-header">Download Forms / Documents</h2>
                <div class="card-body pt-2">
                  <div class="table-responsive">
                    <table
                      class="table table-hover table-colored align-middle custom-table-download"
                    >
                      <thead>
                        <tr>
                          <th>File Name</th>
                          <th class="custom-text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="display: none">
                          <td colspan="3">
                            <label for="fileUploadWcag" class="visually-hidden">Upload</label>
                            <input
                              type="file"
                              (change)="imageSelected($event)"
                              id="fileUploadWcag"
                            />
                          </td>
                        </tr>
                        <ng-container *ngIf="showDefaultFileArrList">
                          <tr *ngFor="let obj of fileArrList">
                            <td>{{ obj.name }}</td>
                            <td class="custom-text-right">
                              <button
                                class="btn btn-danger btn-sm"
                                (click)="deleteImage(obj.name)"
                                style="display: none"
                              >
                                <img
                                  src="../../assets/images/ico_delete.svg"
                                  alt="Delete"
                                />
                              </button>
                              <button
                                class="btn btn-primary btn-sm"
                                (click)="downloadImage(obj.name)"
                                download="pic"
                              >
                                <img
                                  src="../../assets/images/ico_download.svg"
                                  alt="Download"
                                />
                              </button>
                            </td>
                          </tr>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            showSearchFileArrList &&
                            searchFileArrList &&
                            searchFileArrList.length > 0
                          "
                        >
                          <tr *ngFor="let obj of searchFileArrList">
                            <td>{{ obj.name }}</td>
                            <td class="custom-text-right">
                              <button
                                class="btn btn-danger btn-sm"
                                (click)="deleteImage(obj.name)"
                                style="display: none"
                              >
                                <img
                                  src="../../assets/images/ico_delete.svg"
                                  alt="Delete"
                                />
                              </button>
                              <button
                                class="btn btn-primary btn-sm"
                                (click)="downloadImage(obj.name)"
                                download="pic"
                              >
                                <img
                                  src="../../assets/images/ico_download.svg"
                                  alt="Download"
                                />
                              </button>
                            </td>
                          </tr>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            !showDefaultFileArrList &&
                            searchFileArrList &&
                            searchFileArrList.length === 0
                          "
                        >
                          <div>
                            <p class="text-center text-danger">No Data Found</p>
                          </div>
                        </ng-container>
                        <tr style="display: none">
                          <td colspan="3">
                            <!-- <h4>Downloaded</h4> -->
                            <img
                              *ngFor="let blob of picturesDownloaded"
                              src="{{ blob }}"
                              height="100px"
                              alt="File Name"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
