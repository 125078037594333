import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PortalAPI } from 'src/app/constants/api.constant';
import { CommonFunctionService } from 'src/app/services/common-function-service';
import { IAllPermission, IPersmissionType } from '../../constants/manage-roles-data';
import { ConnectionService } from '../../services/connection-service';

@Component({
  selector: 'app-permission-right',
  templateUrl: './permission-right.component.html',
  styleUrls: ['./permission-right.component.css']
})
export class PermissionRightComponent implements OnInit {

  @Input() systemPermissionTypeData:IPersmissionType[] = [];
  @Input() systemAllPermissionData:IAllPermission[] = [];
  @Output() permissionSubTypeCheckedChangeEmitter = new EventEmitter();
  @Output() permissionTypeCheckedChangeEmitter = new EventEmitter();
  @Output() permissionCheckedChangeEmitter = new EventEmitter()
  isPermission: boolean = false;
  tenantId: string = "";

  constructor(        
    private spinner:NgxSpinnerService,
    private restService:ConnectionService,
    private commonService:CommonFunctionService
    ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes:SimpleChanges){
  }

  //#region getTenantID method is used to get azure details from MsalService
  getTenantID()
  {
    const accountDetail = this.commonService.getAccountDetail()[0];
    if(accountDetail.tenantId)
    {
      this.tenantId = accountDetail.tenantId;
    }
    else
    {
      this.tenantId = '1';
    }
  }
  //#endregion

  //#region isSubTypeVisible method is use to show only once if there is some recursion in the data
  isSubTypeVisible(permission:any,mainType:any)
  {
    if(permission && permission.permissionSubTypeName)
    {
      const index = this.systemAllPermissionData.findIndex(it=>it.permissionId === permission.permissionId)
      const minusOneIndex = index - 1;
      if(minusOneIndex > -1)
      {
        if(this.systemAllPermissionData[minusOneIndex].permissionSubTypeName === permission.permissionSubTypeName)
        {
          // && (this.systemAllPermissionData[minusOneIndex].permissionTypeId === permission.permissionTypeId)
          // console.log("this.systemAllPermissionData[minusOneIndex].permissionSubTypeName === permission.permissionSubTypeName", this.systemAllPermissionData[minusOneIndex].permissionSubTypeName ,permission.permissionSubTypeName);

          this.isPermission = false;
        }
        else{
          this.isPermission = true;
        }
      }
      else{
        this.isPermission = true;
      }
    }
    else{
      this.isPermission = false;
    }
    return this.isPermission;
  }
  //#endregion

  //#region permissionTypeCheckedChange method is use for checked main heading checked change like view or edit
  permissionTypeCheckedChange(data:any,isChecked:boolean = false)
  {
    const subTypeData = this.checkUncheckType(data,isChecked);
    this.permissionTypeCheckedChangeEmitter.emit(subTypeData)
  }
  //#endregion

  //#region permissionSubTypeCheckedChange method is use for checked main heading checked change like Digital or Shared
  permissionSubTypeCheckedChange(data:any,isChecked:boolean = false, mainTypeData:any)
  {
    const subTypeData = this.checkUncheckSubType(data,isChecked,mainTypeData);
    this.permissionSubTypeCheckedChangeEmitter.emit(subTypeData);
  }
  //#endregion

  //#region permissionCheckedChange method is use for checked main heading checked change like File type or subtype
  permissionCheckedChange(data:any,mainTypeData:any)
  {
    const subTypeData = this.checkUncheckSingleType(data,data.permissionChecked,mainTypeData);
    this.permissionCheckedChangeEmitter.emit(subTypeData);
  }
  //#endregion

  //#region checkUncheck method is use for type checkbox to check and uncheck
  checkUncheckType(data:any,isChecked:boolean = false)
  {
    let subType = this.systemAllPermissionData.filter(it => it.permissionTypeId === data.permissionTypeId);
    subType.forEach((item:any)=>{
      item.permissionTypeChecked = isChecked;
      item.permissionSubTypeChecked = isChecked;
      item.permissionChecked = isChecked;
    });

    return subType;
  }
  //#endregion

  //#region checkUncheck method is use for Sub type checkbox to check and uncheck
  checkUncheckSubType(data:any,isChecked:boolean = false,mainTypeData:any)
  {
    let subType = this.systemAllPermissionData.filter(it => 
      it.permissionTypeId === data.permissionTypeId && it.permissionSubTypeId === data.permissionSubTypeId
    );
    subType.forEach((item:any)=>{
      if(mainTypeData?.checked && !isChecked)
      {
        mainTypeData.checked = false;
      }
      item.permissionSubTypeChecked = isChecked;
      item.permissionChecked = isChecked;
    });

    const tempArr = this.systemAllPermissionData.filter((it:any) => it.permissionTypeId === data.permissionTypeId);
    const length = this.systemAllPermissionData.filter((it:any) => it.permissionTypeId === data.permissionTypeId && it.permissionSubTypeChecked);
    if((tempArr.length === length.length) && isChecked) {
      mainTypeData.checked = true;
    }

    return subType;
  }
  //#endregion

  //#region checkUncheckSingleType method is use for single checkbox to check and uncheck
  checkUncheckSingleType(data:any,isChecked:boolean = false,mainTypeData:any)
  {
    let subType = this.systemAllPermissionData.filter((it:any) => {
      if(it.permissionTypeId === data.permissionTypeId && it.permissionSubTypeId === data.permissionSubTypeId)
      {
        return it;
      }
    });
    subType.forEach((item:any)=>{
      if(data?.permissionSubTypeChecked && !isChecked)
      {
        item.permissionSubTypeChecked = false;
      }
      else {
        const length = this.systemAllPermissionData.filter((it:any) => it.permissionTypeId === data.permissionTypeId && it.permissionSubTypeId === data.permissionSubTypeId && it.permissionChecked);
        if(subType.length === length.length && isChecked) {
          item.permissionSubTypeChecked = true;
        }
      }
    });
    if(mainTypeData?.checked && !isChecked)
    {
      mainTypeData.checked = false;
    }
    else {
      const tempArr = this.systemAllPermissionData.filter((it:any) => it.permissionTypeId === data.permissionTypeId);
      const length = this.systemAllPermissionData.filter((it:any) => it.permissionTypeId === data.permissionTypeId && it.permissionSubTypeChecked);
      if((tempArr.length === length.length) && isChecked) {
        mainTypeData.checked = true;
      }
    }
    data.permissionChecked = isChecked;
    
    return subType;
  }
  //#endregion
  
}
