
import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { PortalAPI } from 'src/app/constants/api.constant';
import { Subscription } from 'rxjs';
import { BehaviorSubjectService } from 'src/app/services/behaviorsubject.service';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class JobService {
  maptoken=environment.mapbox.accessToken 
  public options: any = {};
  constructor(
    private http: HttpClient,
    private behaviourSubjectService: BehaviorSubjectService
  ) {
    let reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    reqHeader.append('Access-Control-Allow-Origin', '*');
    reqHeader.append('accept', 'text/plain');
    reqHeader.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    reqHeader.append('Access-Control-Allow-Methods', 'GET,POST,OPTIONS,DELETE,PUT');
    this.options = { headers: reqHeader };

  }



  getAllJobList(data: any) {
    return this.http.post(PortalAPI.JOBS_LIST, data, this.options);
  }

  searchJobs(data: any) {
    return this.http.post(PortalAPI.SEARCH_JOBS, data, this.options);
  }

	getnewmap(data:any){
	let encodeddata=encodeURIComponent(data)
	  
	  return this.http.get(environment.NEW_MAP + encodeddata+'.json'+"?"+"access_token="+this.maptoken);
	}
	
  getSharedAndAssignedJobs(data:any) {
    return this.http.post(PortalAPI.ASSIGNED_SHARED_JOBS, data, this.options);
  }
  
  ngOnDestroy() {
  }
}

